export default function SaveModal({
  PreviewPopupIcon,
  setSavePopup,
  saveProjectBtn,
  t,
}) {
  return (
    <div className="preview-popup">
      <img src={PreviewPopupIcon} alt="" className="preview-icon" />
      <h3>{t('프로젝트를 저장하시겠습니까?')}</h3>
      <div className="popup-btn-cont">
        <button
          className="popupBtnLeft opacity-1"
          onClick={() => setSavePopup(false)}>
          {t('취소')}
        </button>

        <button className="popupBtnRight opacity-1" onClick={saveProjectBtn}>
          {t('확인')}
        </button>
      </div>
    </div>
  );
}
