import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../locale/i18n';
import DuplicateIcon from '../svg/CopyIcon';
import HighIndexIcon from '../svg/HighIndexIcon';
import HighestIndexIcon from '../svg/HighestIndexIcon';
import LowIndexIcon from '../svg/LowIndexIcon';
import LowestIndexIcon from '../svg/LowestIndexIcon';
import TrashBinIcon from '../svg/TrashBinIcon';
import ZIndexIcon from '../svg/ZIndexIcon';

type props = {
  setEventName: (string) => void;
};
const ClipMenu = ({ setEventName }: props) => {
  const { t } = useTranslation();
  const [dropMenu, setDropMenu] = useState(false);
  return (
    <div className="clipMenu-cont">
      <div className="clipMenu active">
        <ul>
          <li>
            <button
              className="clip-down-btn"
              onClick={() => {
                setEventName('duplicate');
              }}>
              <DuplicateIcon />
              <div className="clipMenu__tool" style={{ left: '-13px' }}>
                <span>{t('복사')}</span>
              </div>
            </button>
          </li>
          <li className="select-arrow">
            <button
              className="clip-down-btn"
              onClick={() => setDropMenu(pre => !pre)}>
              <ZIndexIcon />
              <div className="clipMenu__tool" style={{ left: '16px' }}>
                <span>{t('개체순서')}</span>
              </div>
              <svg
                className="arrow-down"
                width="4"
                height="4"
                viewBox="0 0 4 4"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.24946 1.33114L1.99996 2.54914L0.750458 1.33114C0.728134 1.30933 0.698165 1.29712 0.666958 1.29712C0.635751 1.29712 0.605782 1.30933 0.583458 1.33114C0.572649 1.34173 0.564061 1.35437 0.558199 1.36832C0.552336 1.38227 0.549316 1.39725 0.549316 1.41239C0.549316 1.42752 0.552336 1.4425 0.558199 1.45645C0.564061 1.47041 0.572649 1.48305 0.583458 1.49364L1.91271 2.78964C1.93605 2.81239 1.96736 2.82513 1.99996 2.82513C2.03256 2.82513 2.06386 2.81239 2.08721 2.78964L3.41646 1.49389C3.42734 1.48329 3.436 1.47062 3.4419 1.45662C3.44781 1.44262 3.45086 1.42758 3.45086 1.41239C3.45086 1.39719 3.44781 1.38216 3.4419 1.36816C3.436 1.35416 3.42734 1.34149 3.41646 1.33089C3.39413 1.30908 3.36416 1.29688 3.33296 1.29688C3.30175 1.29687 3.27178 1.30908 3.24946 1.33089V1.33114Z"
                  fill="white"
                />
              </svg>
            </button>
          </li>
          <li>
            <button
              className="clip-down-btn"
              onClick={() => setEventName('delete')}>
              <TrashBinIcon />
              <div className="clipMenu__tool" style={{ left: '48px' }}>
                <span>{t('삭제')}</span>
              </div>
            </button>
          </li>
        </ul>
        {/* 하위 세로 메뉴 */}
        {dropMenu ? (
          <div className="clip-down-cont">
            <ul>
              <li>
                <button
                  className="clip-down-btn"
                  onClick={() => setEventName('back')}>
                  <LowIndexIcon />
                  <div className="ballon__tool w-20">
                    <span>{t('뒤로')}</span>
                  </div>
                </button>
              </li>
              <li>
                <button
                  className="clip-down-btn"
                  onClick={() => setEventName('forword')}>
                  <HighIndexIcon />
                  <div className="ballon__tool w-20">
                    <span>{t('앞으로')}</span>
                  </div>
                </button>
              </li>
              <li>
                <button
                  className="clip-down-btn"
                  onClick={() => setEventName('last')}>
                  <LowestIndexIcon />
                  <div className="ballon__tool w-20">
                    <span>{t('맨뒤로')}</span>
                  </div>
                </button>
              </li>
              <li>
                <button
                  className="clip-down-btn"
                  onClick={() => setEventName('first')}>
                  <HighestIndexIcon />
                  <div className="ballon__tool w-20">
                    <span>{t('맨앞으로')}</span>
                  </div>
                </button>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ClipMenu;
