import { useEffect, useState } from 'react';
import SubpageTitle from '../../components/main/landing/subpage-title';
import BackgroundSection from '../../components/main/store/background-section';
import ClipartSection from '../../components/main/store/clipart-section';
import MetahumanSection from '../../components/main/store/metahuman-section';
import SubpageContainer from '../../components/main/subpage-container';
import { getLeftCredit } from '../../networks/paysystem.service';

const TITLE = '실감나는 다양한 메타휴먼과\n크리에이티브한 효과를 만나보세요.';

export default function StorePage() {
  const [payCreditLeft, setPayCreditLeft] = useState(0);

  const getCreditLeftWithResData = (result: any) => {
    let totalCredit = 0;
    result?.map((item: any) => {
      totalCredit += item.credit;
    });
    return totalCredit;
  };

  const getPayCreditLeft = () => {
    getLeftCredit()
      .then(res => {
        if (res.status === 200) {
          setPayCreditLeft(getCreditLeftWithResData(res?.data?.resultValue));
        }
      })
      .catch(console.error);
  };

  useEffect(() => getPayCreditLeft(), []);

  return (
    <SubpageContainer>
      <div className="pb-20 md:pb-28 lg:pb-36 xl:pb-40 2xl:pb-52">
        <SubpageTitle badge="스토어" title={TITLE} />
        <div className="flex flex-col space-y-16 lg:space-y-20 2xl:space-y-28 mt-14 lg:mt-16 xl:mt-20">
          <MetahumanSection creditleft={payCreditLeft} />
          <BackgroundSection creditleft={payCreditLeft} />
          <ClipartSection creditleft={payCreditLeft} />
        </div>
      </div>
    </SubpageContainer>
  );
}
