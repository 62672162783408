import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import TemplatePublishForm from '../console/modal/template-publish-form';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function TemplatePublishModal({ isOpen, closeModal }: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full sm:max-w-xl flex flex-col items-start p-6 overflow-hidden max-sm:p-4 max-sm:absolute max-sm:bottom-0 rounded-2xl max-sm:rounded-b-none bg-white shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="w-full flex justify-between items-center text-consoleDark font-medium">
                  <h3 className="text-xl 2xl:text-2xl font-semibold text-black">
                    영상 템플릿 배포 신청
                  </h3>
                  <button onClick={closeModal}>
                    <XMarkIcon className="w-5 h-5" />
                  </button>
                </Dialog.Title>
                <TemplatePublishForm />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
