/* eslint-disable max-len */
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect } from 'react';

import { FacebookShareButton } from 'react-share';
import { useRecoilValue } from 'recoil';
import { useModalAtom } from '../../recoil/atoms/store';
import { MapType } from '../../store/block/types';
import shareKakao from '../../utils/shareKakaoLink';
import useScript from '../../utils/useScript';
import FaceBookIcon from '../svg/sns/FacebookIcon';
import KakaoIcon from '../svg/sns/KakaoIcon';
import LineIcon from '../svg/sns/LineIcon';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

const iconMap: MapType = {
  kakaotalk: <KakaoIcon />,
  facebook: <FaceBookIcon />,
};

export default function ShareModal({ isOpen, closeModal }: Props) {
  const { data } = useRecoilValue(useModalAtom);

  const snsVideoUrl = encodeURI(data?.downloadLink);
  const snsThumbnailUrl = encodeURI(
    data?.completedThumbnail || data?.thumbnail
  );
  const snsTitle = data?.projectName;
  const status = useScript('https://developers.kakao.com/sdk/js/kakao.js');

  const lineUrl = () => {
    const videoURL = encodeURI(data?.downloadLink);
    const text = encodeURIComponent(videoURL);
    const imageUrl = encodeURIComponent(data?.thumbnail);
    const url = `https://social-plugins.line.me/lineit/share?url=&text=${text}`;
    window.open(url, '공유하기', 'width=760px,height=726px');
  };

  useEffect(() => {
    if (status === 'ready') {
      console.log('Kakao loaded.....');
    }
  }, [status]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => closeModal()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-sm 2xl:max-w-md flex flex-col items-center px-5 py-7 md:p-8 space-y-5 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="w-24 h-24 rounded-md bg-cover bg-top bg-gradient-to-br from-[#9D9BA0] to-[#BFBFBF]">
                  <div
                    className="bg-cover bg-top w-24 h-24"
                    style={{
                      backgroundImage: `url(${data?.thumbnail})`,
                    }}
                  />
                </div>
                <p className="text-center text-md text-[#5b5b5b]">
                  {data?.projectName}
                </p>
                <p className="text-center text-lg">이 파일 공유하기</p>
                <div className="w-full flex justify-center space-x-3">
                  <div className="cursor-pointer hover:-translate-y-1 hover:scale-105 duration-150 ease-out">
                    <FacebookShareButton url={snsVideoUrl}>
                      {iconMap.facebook}
                    </FacebookShareButton>
                  </div>

                  <button
                    className="cursor-pointer hover:-translate-y-1 hover:scale-105 duration-150 ease-out"
                    onClick={() =>
                      shareKakao(
                        snsVideoUrl,
                        snsThumbnailUrl,
                        snsTitle,
                        data.content || ''
                      )
                    }>
                    <KakaoIcon />
                  </button>
                  <button
                    className="cursor-pointer hover:-translate-y-1 hover:scale-105 duration-150 ease-out"
                    onClick={lineUrl}>
                    <LineIcon />
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
