import { useTranslation } from 'react-i18next';
import '../../locale/i18n';

export default function ServiceTerms() {
  const { t } = useTranslation();
  return (
    <>
      <div>
        <div className="pop-bg show"></div>
        <div className="terms-pop-wrap2 show">
          <div className="pop-close-btn">
            <img src="/main/img/icon-close-btn.svg" alt="" />
          </div>
          <div className="scroll-box pop-body">
            <p className="text-black">
              <strong>{t('제1장 총칙')}</strong> <br />
              <br />
              <strong>{t('제1조(목적)')}</strong> <br />
              <dl>
                {t(
                  '이 약관은 주식회사 플루닛(이하 “회사”)이 제공하는 플루닛 스튜디오 서비스(이하 “서비스”)를 이용함에 있어 개인 또는 법인(이하 “가입자”)과 회사 간의 권리, 의무 및 책임사항, 서비스 이용조건 등 기본적인 사항을 규정하는 것을 목적으로 합니다.'
                )}
              </dl>
              <br />
              <strong>{t('제2조(용어의 정의)')}</strong>
              <br />
              <dl className="no-margin">
                {t('이 약관에서 사용하는 용어의 정의는 다음과 같습니다.')}{' '}
              </dl>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '플루닛 스튜디오: AI 가상 인간과 성우를 이용한 동영상 제작 SaaS 서비스'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '아이디(ID): 가입자의 식별과 서비스 이용을 위하여 가입자가 정하고 회사가 승인하는 문자와 숫자의 조합'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '비밀번호: 가입자가 부여받은 아이디와 일치하는 가입자임을 확인하고 비밀 보호를 위해 정한 문자 또는 숫자 등의 조합'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '유료서비스 : 회사가 제공하는 서비스 중 가입자가  회사에 일정 금액을 지불하는 경우에 이용할 수 있는 회사의 서비스를 의미'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '유료가입자 : 유료서비스를 이용 중인 가입자를 의미합니다. 회사는 가입자와 유료가입자에게 제공하는 서비스에 차별을 두어 제공할 수 있음'
                  )}
                </dd>
                <dt>6.</dt>
                <dd>
                  {t(
                    '결제 : 회사가 제공하는 유료서비스를 이용하기 위하여 각종 지불수단을 통하여 일정 금액을 회사 또는 회사가 정한 제3자에 지불하는 것을 의미하는 것'
                  )}
                </dd>
                <dt>7.</dt>
                <dd>
                  {t(
                    '서비스계약: 서비스를 제공받기 위하여 회사와 가입자 간에 체결되는 계약'
                  )}
                </dd>
                <dt>8.</dt>
                <dd>
                  {t(
                    '해지: 회사와 가입자 간 체결되어 있는 서비스 계약을 해약하는 것'
                  )}
                </dd>
                <dt>9.</dt>
                <dd>
                  {t(
                    '데이터: 서비스 솔루션을 통해 게시 또는 전송되는 글, 사진, 영상, 파일, 기타 일체의 정보 혹은 콘텐츠'
                  )}
                </dd>
                <dt>10.</dt>
                <dd>
                  {t(
                    '콘텐츠: 정보통신망 이용촉진 및 정보보호 등에 관한 법률 (이하 “정보통신망법”) 제 2조 1항의 규정에 따라 정보통신망에서 사용되는 부호, 문자, 음성, 음향, 이미지 또는 영상 등으로 표현된 자료 또는 정보로서 제작, 처리된 것'
                  )}
                </dd>
                <dt>11.</dt>
                <dd>
                  {t(
                    '코인 : 가입자가 회사가 제공하는 서비스를 통해 영상 콘텐츠 저작물을 만들때 사용하는 결제 수단'
                  )}
                </dd>
                <dt>12.</dt>
                <dd>
                  {t(
                    '잔여시간 : 가입자와 회사가 제공하는 서비’를 통해 콘텐츠 저작물을 만들고 다운로드할 수 있는 영상 콘텐츠 길이의 총 시간'
                  )}
                </dd>
                <dt>13.</dt>
                <dd>
                  {t(
                    '배포 : 다운로드 가능한 영상 콘텐츠 형태로 만들기 위해 편집을 종료하고 최종적으로 마무리를 짓는 단계를 의미하며, 이후 편집은 불가하고 잔여시간 및 코인이 차감됨'
                  )}
                </dd>
                <dt>14.</dt>
                <dd>
                  {t(
                    '웹페이지: 플루닛 홈페이지, 브랜드 페이지, 및 서비스 페이지를 포함한 플루닛 관련 웹페이지'
                  )}
                </dd>
                <dt>15.</dt>
                <dd>
                  {t(
                    '가입자 생성 콘텐츠: 가입자가 서비스를 이용하여 생성하고 다운로드한 콘텐츠 및 파생 콘텐츠'
                  )}
                </dd>
              </dl>
              <strong>{t('제3조(약관의 효력 및 변경)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 본 약관의 내용을 웹페이지에 게시하여 공지하며, 본 약관에 동의한 모든 가입자에게 효력이 발생합니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 약관의 규제에 관한 법률, 정보통신망법, 전자상거래 등에서의 소비자보호에 관한 법률, 콘텐츠산업 진흥법, 문화체육관광부 장관이 정하는 콘텐츠이용자 보호지침 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 제1항의 방식에 따라 개정약관의 적용일자 7일 전부터 웹페이지에 공지하거나, 가입자가 등록한 이메일 등을 이용하여 통지합니다. 다만, 가입자에게 불리하게 약관의 내용을 변경하는 경우에는 최소한 30일 이상의 유예기간을 두고 공지합니다.'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '회사가 전항에 따라 개정약관을 공지 또는 통지하면서 가입자에게 약관 변경 적용일까지 거부의사를 표시하지 않으면 동의한 것으로 본다는 뜻을 명확하게 공지하였음에도 가입자가 명시적으로 거부의 의사표시를 하지 아니한 경우, 가입자가 개정약관에 동의한 것으로 봅니다.'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '가입자가 개정약관의 적용에 동의하지 않을 경우 가입자는 서비스계약을 해지할 수 있습니다.'
                  )}
                </dd>
                <dt>6.</dt>
                <dd>
                  {t(
                    '가입자는 약관의 변경에 대하여 주의의무를 다하여야 하며 변경된 약관으로 인한 가입자의 피해는 회사가 책임지지 않습니다.'
                  )}
                </dd>
                <dt>7.</dt>
                <dd>
                  {t(
                    '약관의 적용 기간은 가입자의 가입일부터 해지일까지로 규정합니다.'
                  )}
                </dd>
                <dt>8.</dt>
                <dd>
                  {t(
                    '이 약관에 명시되지 않은 사항에 대해서는 관계법령 및 회사가 제공하는 부가서비스에 관한 별도의 약관, 이용규정 등을 따릅니다.'
                  )}
                </dd>
                <dt>9.</dt>
                <dd>
                  {t(
                    '서비스에 대해서는 원칙적으로 플루닛 회원 약관(통합 플루닛 계정 서비스 약관, 이하 플루닛 회원 약관)이 적용됩니다.'
                  )}
                </dd>
                <dt>10.</dt>
                <dd>
                  {t(
                    '본 약관과 플루닛 회원 약관의 내용 중 충돌하는 부분이 있는 경우 본 약관의 내용이 우선합니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제2장 서비스 및 가입신청')}</strong>
              <br />
              <strong>{t('제4조 (서비스종류 및 내용)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t('회사는 가입자에게 아래와 같은 서비스를 제공합니다.')}
                  <dl className="no-margin">
                    <dt>1.</dt>
                    <dd>{t('메타휴먼과 음성을 이용한 동영상 제작')}</dd>
                    <dt>2.</dt>
                    <dd>{t('맞춤형 메타휴먼생성을 위한 촬영/녹음 제작')}</dd>
                    <dt>3.</dt>
                    <dd>{t('스크립트 자동 자막 변환')}</dd>
                    <dt>4.</dt>
                    <dd>{t('스크립트 번역, 검수')}</dd>
                    <dt>5.</dt>
                    <dd>
                      {t(
                        '기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 가입자에게 제공하는 일체의 서비스'
                      )}
                    </dd>
                  </dl>
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며 정기점검시간은 웹페이지에 공지한 바에 따릅니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제5조(서비스의 변경)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 서비스를 변경할 수 있습니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자 등을 변경 전에 웹페이지에 게시합니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '회사는 가입자가 무료 요금제를 통해 제공받는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며 이에 대하여 관련법에 특별한 규정이 없는 한 가입자에게 별도의 보상을 하지 않습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제6조(서비스계약 체결)')}</strong>
              <dl>
                {t(
                  '서비스계약은 서비스에 가입하고자 하는 가입희망자가 웹페이지의 가입절차에 따라 온라인 양식에 필수입력사항 등을 기재하고 회원약관, 서비스이용약관, 개인정보처리방침, 개인정보수집이용 동의서 및 서비스운영정책에 동의한 후 회사가 가입신청을 승낙함으로써 성립됩니다.'
                )}
              </dl>
              <strong>{t('제7조(가입신청 승낙)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '신청사항이 약관에서 정하는 조건에 적합한 경우 이를 승낙합니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '제1항에 따른 신청에 있어 회사는 가입 신청자의 실명 확인을 위하여 전문기관을 통하여 실명인증을 할 수 있습니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '회사는 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '회사는 가입자가 선택한 요금제에 따라 이용시간, 이용횟수 등을 세분하여 이용에 차등을 둘 수 있습니다.'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '회사는 서비스 고유 개발 등의 특별한 이용에 관한 계약은 별도 계약으로 합니다.'
                  )}
                </dd>
                <dt>6.</dt>
                <dd>
                  {t(
                    '서비스계약은 회사의 승낙이 가입자에게 도달한 시점에 성립됩니다.'
                  )}{' '}
                </dd>
              </dl>
              <strong>{t('제8조(가입신청 불승낙)')}</strong>
              <br />
              <p>
                {t(
                  '회사는 다음 각 호에 해당하는 경우 가입신청에 대하여는 승낙을 하지 않거나 사후에 서비스계약을 해지할 수 있습니다.'
                )}
              </p>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '가입 신청자가 이 약관에 의하여 이전에 이용자격을 상실한 적이 있는 경우'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>{t('실명이 아니거나 타인의 명의를 이용한 경우')}</dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은 경우'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '만 14세 미만의 가입 신청자인 경우. 다만, 회사가 요청하는 소정의 법정대리인(부모 등)의 동의 절차를 거치는 경우는 예외로 함'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '가입 신청자가 서비스의 정상적인 제공을 저해하거나 다른 가입자의 서비스 이용에 지장을 줄 것으로 예상되는 경우'
                  )}
                </dd>
                <dt>6.</dt>
                <dd>
                  {t(
                    '가입 신청자의 귀책사유로 인하여 승인이 불가능하거나 기타 규정한 제반 사항을 위반하며 신청하는 경우'
                  )}
                </dd>
                <dt>7.</dt>
                <dd>
                  {t(
                    '기타 회사가 관련법령 등을 기준으로 하여 명백하게 사회질서 및 미풍양속에 반할 우려가 있음을 인정하는 경우'
                  )}
                </dd>
              </dl>
              <strong>{t('제9조(개인정보 수집 및 위탁)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 적법하고 공정한 수단에 의하여 서비스계약의 성립 및 이행에 필요한 최소한의 개인정보를 수집합니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 개인정보의 수집 시 관련법규에 따라 개인정보 처리 방침에 그 수집범위 및 목적을 사전 고지합니다.'
                  )}
                </dd>
              </dl>
              <strong>
                {t('제10조(아이디 및 비밀번호의 관리에 대한 의무)')}
              </strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '가입자의 아이디와 비밀번호에 관한 관리책임은 가입자에게 있으며 이를 제3자가 이용하도록 하여서는 안 됩니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 가입자의 아이디가 개인정보 유출 우려가 있거나 반사회적 또는 미풍양속에 어긋나거나 회사 및 회사의 운영자로 오인할 우려가 있는 경우 해당 아이디의 이용을 제한할 수 있습니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '가입자는 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '제3항의 경우에 해당 가입자가 회사에 그 사실을 통지하지 않거나 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '서비스 이용을 위해 부여된 번호 및 비밀번호가 가입자의 관리 소홀로 타인에 의해 부정 사용된 경우, 이로 인하여 발생된 문제는 회사가 책임지지 않습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제11조(가입자정보의 변경)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '가입자는 개인정보 관리 화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디 등은 원칙적으로 수정이 불가능하나, 부득이한 사유로 변경하고자 하는 경우에는 회사에 사유를 충분히 전달하여야 합니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '가입자는 회원가입 신청 시 기재한 사항이 변경되었을 경우 웹사이트에 접속하여 변경사항을 수정하여야 합니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '제2항의 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제12조(가입자에 대한 통지)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사가 가입자에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 가입자가 등록한 이메일, 문자 메시지 등으로 통지할 수 있습니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 전체 또는 불특정 다수 가입자에 대한 통지를 하는 경우 7일 이상 웹페이지에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제3장 회사와 가입자의 의무')}</strong>
              <br />
              <strong>{t('제13조(회사의 의무)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 관련법과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 지속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여 노력합니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 가입자가 안전하게 서비스를 이용할 수 있도록 개인정보 보호를 위해 보안시스템을 갖추며 개인정보 처리방침을 공시하고 준수합니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '회사는 서비스 제공과 관련하여 알고 있는 가입자의 개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 다만, 다음 각호에 해당하는 경우에는 그러하지 않습니다.'
                  )}
                  <dl className="no-margin">
                    <dt>1.</dt>
                    <dd>
                      {t(
                        '관계법령에 의한 수사상의 목적으로 관계 기관으로부터 요구받은 경우'
                      )}
                    </dd>
                    <dt>2.</dt>
                    <dd>{t('정보통신윤리위원회의 요청이 있는 경우')}</dd>
                    <dt>3.</dt>
                    <dd>
                      {t(
                        '전기통신기본법, 전기통신사업법, 정보통신망 이용촉진 및 정보보호 등에 관한법률, 금융실명거래 및 비밀보장에 관한 법률 등 관련법령 및 기타 법령에서 허용하는 경우'
                      )}
                    </dd>
                  </dl>
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '회사는 서비스 제공 목적에 맞는 서비스 이용 여부를 확인하기 위하여 상시적으로 모니터링을 실시합니다.'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '회사는 개인정보와 관련하여 가입자의 의견을 수렴하고 불만을 처리하기 위한 절차를 마련합니다.'
                  )}
                </dd>
                <dt>6.</dt>
                <dd>
                  {t(
                    '회사는 전화, 이메일 또는 고객센터를 통하여 가입자의 불만사항을 접수 및 처리합니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제14조(개인정보보호 의무)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 정보통신망법 및 개인정보보호법 등 관계 법령이 정하는 바에 따라 가입자의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보 처리방침이 적용됩니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 가입자가 서비스 화면에서 자신의 개인정보에 대한 수집, 이용 또는 제공에 대한 동의를 철회할 수 있도록 필요한 조치를 취합니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제15조 (가입자의 의무)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t('가입자는 다음 행위를 하여서는 안 됩니다.')}
                  <dl className="no-margin">
                    <dt>1.</dt>
                    <dd>{t('신청 또는 변경 시 허위내용을 등록하는 행위')}</dd>
                    <dt>2.</dt>
                    <dd>{t('타인의 정보를 도용하는 행위')}</dd>
                    <dt>3.</dt>
                    <dd>
                      {t(
                        '타인의 개인정보를 동의 없이 수집, 저장, 공개하는 행위'
                      )}
                    </dd>
                    <dt>4.</dt>
                    <dd>
                      {t(
                        '회사가 게시한 정보를 변경하거나 제3자에게 제공하는 행위'
                      )}
                    </dd>
                    <dt>5.</dt>
                    <dd>
                      {t(
                        '회사와 기타 제3자의 저작권, 지식재산권 등 기타권리를 침해하는 행위'
                      )}
                    </dd>
                    <dt>6.</dt>
                    <dd>
                      {t(
                        '회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위'
                      )}
                    </dd>
                    <dt>7.</dt>
                    <dd>{t('정치적 목적 사용 행위 (글로벌 약관)')}</dd>
                    <dt>8.</dt>
                    <dd>
                      {t(
                        '서비스를 사용하여 음란성, 폭력성, 잔혹성, 혐오성 및 사회질서를 저해하거나 타인의 권리를 침해하는 콘텐츠를 생성하는 행위'
                      )}
                    </dd>
                    <dt>9.</dt>
                    <dd>
                      {t(
                        '외설 또는 폭력적인 메시지, 비속어, 팩스, 음성, 메일 및 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위'
                      )}
                    </dd>
                    <dt>10.</dt>
                    <dd>
                      {t(
                        '회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위'
                      )}
                    </dd>
                    <dt>11.</dt>
                    <dd>
                      {t('범죄행위를 목적으로 하거나 범죄행위를 교사하는 행위')}
                    </dd>
                    <dt>12.</dt>
                    <dd>{t('선량한 풍속 또는 기타 사회질서를 해치는 행위')}</dd>
                    <dt>13.</dt>
                    <dd>
                      {t(
                        '현행 법령, 회사가 제공하는 서비스에 정한 약관, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항, 기타 서비스 이용에 관한 규정을 위반하는 행위'
                      )}
                    </dd>
                    <dt>14.</dt>
                    <dd>
                      {t(
                        '서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위'
                      )}
                    </dd>
                    <dt>15.</dt>
                    <dd>{t('제3자에게 임의로 서비스를 임대하는 행위')}</dd>
                    <dt>16.</dt>
                    <dd>{t('기타 불법적이거나 부당한 행위')}</dd>
                  </dl>
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 가입자가 제1항의 행위를 하는 경우 가입자의 서비스 이용을 정지하고 일방적으로 계약을 해지할 수 있습니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '가입자는 회원가입 시 부정가입 방지를 위해 회사가 제공하는 본인인증방법으로 본인인증을 거친 후 서비스를 이용하여야 합니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제4장 서비스의 이용정지 및 이용 휴지')}</strong>
              <br />
              <strong>{t('제16조 (이용정지의 대상)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '가입자가 다음 각호에 해당하는 경우에는 가입자의 서비스 이용을 정지할 수 있습니다.'
                  )}
                  <dl className="no-margin">
                    <dt>1.</dt>
                    <dd>
                      {t(
                        '가입 신청 또는 서비스 이용 시 허위 내용을 기재한 경우'
                      )}
                    </dd>
                    <dt>2.</dt>
                    <dd>{t('다른 가입자의 서비스 이용을 방해하는 경우')}</dd>
                    <dt>3.</dt>
                    <dd>
                      {t(
                        '회사가 금지한 정보 또는 컴퓨터 프로그램을 사용, 송신, 또는 게시하는 경우'
                      )}
                    </dd>
                    <dt>4.</dt>
                    <dd>
                      {t(
                        '회사의 기술적 보호조치를 회피 또는 무력화하는 행위를 하는 경우'
                      )}
                    </dd>
                    <dt>5.</dt>
                    <dd>
                      {t(
                        '이외에 가입자가 제15조(가입자의 의무)를 위반하는 경우'
                      )}
                    </dd>
                  </dl>
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 전항에도 불구하고 주민등록법을 위반한 명의도용 및 결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법을 위반한 경우에는 즉시 영구이용정지를 할 수 있습니다. 본 항에 따른 서비스 이용정지 시 캐시, 데이터, 혜택 및 권리 등도 모두 소멸되며 회사는 이에 대해 별도로 보상하지 않습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제17조 (이용정지의 절차)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 제16조 (이용정지의 대상)에 의해 이용을 정지하고자 하는 경우 그 사유, 일시, 기간 및 대상 서비스를 가입자에게 통지합니다. 다만, 가입자가 신고한 연락처 등의 변경으로 인하여 통지할 수 없는 경우에는 그러하지 아니합니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '제1항의 규정에 의하여 이용정지의 통지를 받은 사람은 그 이용정지에 대하여 이의신청을 할 수 있습니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '제2항의 규정에 의한 이의신청에 대하여 즉시 확인 처리하며, 그 결과를 7일 이내에 이의 신청인에게 통지합니다.'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '이용정지 사유가 해소된 때에는 즉시 이용이 가능하게 합니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제18조 (이용휴지)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '시설 및 기술상 서비스를 제공할 수 없는 불가피한 사유가 발생한 경우에는 이용휴지 처리하고 그 사유가 해소되면 즉시 이용이 가능하게 합니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '제1항의 규정에 의한 이용휴지의 경우 그 절차에 대해서는 제17조 (이용정지의 절차)규정을 준용합니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '회사는 가입자의 정보가 부당한 목적으로 사용되는 것을 방지하고 보다 원활한 서비스 제공을 위하여 12개월 이상 계속해서 로그인을 포함한 서비스 이용이 없는 아이디를 휴면아이디로 분류하고 서비스 이용을 정지할 수 있습니다.'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '휴면계정으로 전환된 가입자의 데이터는 분리보관 되며, 2년 이상 미이용시에는 개인정보파기, 크레딧 소멸, 및 계정 삭제합니다.'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '휴면아이디로 분류되기 30일 전까지 이메일 등으로 휴면아이디로 분류된다는 사실, 일시, 기간 및 개인정보 항목을 가입자에게 통지합니다.'
                  )}{' '}
                </dd>
                <dt>6.</dt>
                <dd>
                  {t(
                    '가입자는 휴면아이디 보관기간 내에 로그인을 통해 휴면아이디 상태를 해제할 수 있습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제5장 계약의 변경 및 해지')}</strong>
              <br />
              <strong>{t('제19조 (계약사항의 변경)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '가입자는 서비스계약 사항 중 다음 각호에 해당하는 사항을 변경하고자 하는 때에는 웹페이지에서 정보를 변경 제출함으로써 회사에 통지하여야 합니다.'
                  )}
                  <dl className="no-margin">
                    <dt>1.</dt>
                    <dd>{t('가입자의 상호, 성명 또는 주소 등의 변경')}</dd>
                    <dt>2.</dt>
                    <dd>{t('요금납부방식의 변경')}</dd>
                  </dl>
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '가입자가 변경사항에 대하여 신고하지 아니함 또는 지연으로 인하여 가입자에게 발생하는 불이익은 모두 가입자가 부담합니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제20조 (계약의 해제 및 해지)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '가입자는 서비스계약을 해지하고자 할 때 직접 웹페이지를 통하여 해지 신청할 수 있으며 회사는 관련법 등이 정하는 바에 따라 특별한 사유가 없는 한 접수된 해지 신청을 수리하고 이 사실을 가입자에게 통지합니다.'
                  )}{' '}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 가입자가 다음 각 호에 해당할 경우에는 가입자의 동의 없이 서비스계약을 해지할 수 있으며 그 사실을 가입자에게 통지합니다. 다만 회사가 긴급하게 해지할 필요가 있다고 인정하는 경우나 가입자의 귀책사유로 인하여 통지할 수 없는 경우에는 통지를 생략할 수 있습니다.'
                  )}
                  <dl className="no-margin">
                    <dt>1.</dt>
                    <dd>
                      {t(
                        '가입자가 약관을 위반하고 일정 기간 이내에 위반 내용을 해소하지 않는 경우'
                      )}
                    </dd>
                    <dt>2.</dt>
                    <dd>
                      {t(
                        '회사의 서비스 제공목적 외의 용도로 서비스를 이용하거나 제3자에게 임의로 서비스를 임대한 경우'
                      )}
                    </dd>
                    <dt>3.</dt>
                    <dd>{t('제15조(가입자의 의무) 규정을 위반한 경우')}</dd>
                    <dt>4.</dt>
                    <dd>
                      {t(
                        '제16조(이용정지의 대상) 규정에 의하여 이용정지를 당한 이후 1년 이내에 이용정지 사유가 재발한 경우'
                      )}
                    </dd>
                  </dl>
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '가입자 또는 회사가 계약을 해지할 경우 관련법 및 개인정보 처리방침에 따라 회사가 가입자정보를 보유하는 경우를 제외하고는 해지 즉시 가입자의 모든 데이터는 소멸됩니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제21조 (서비스의 중단)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 컴퓨터 등 정보통신설비의 보수점검, 교체, 고장, 통신두절, 천재지변 등의 불가항력적인 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있으며 이 경우 사전공지를 합니다. 다만, 회사가 합리적으로 예측할 수 없는 사유로 인한 서비스 제공 중단의 경우에는 사후에 이를 공지할 수 있습니다. 상기 사유에 따른 서비스 중단과 관련하여 회사는 중대한 과실이 있지 않는 한, 가입자에 대한 손해배상책임을 지지 않습니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 사업적 판단에 따라 서비스 중단을 결정할 수 있으며, 이에 따른 가입자의 서비스 이용에 따른 기대이익에 대한 손실을 보장하지 않습니다. 서비스의 일시 정지나 중단의 경우 회사는 이를 가입자에게 사전에 공지하여 가입자의 불이익을 최소화하기 위해 노력합니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '서비스에 등록된 가입자 생성 콘텐츠에 대해서는 가입자가 스스로 백업하여 서비스 중단에 따른 삭제 시 피해가 없도록 해야 합니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제6장 권리의 귀속 및 저작물의 이용')}</strong>
              <br />
              <strong>{t('제22조(권리의 귀속 및 저작물의 이용)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '가입자 생성 콘텐츠에 대한 저작권은 가입자가 보유하며 가입자 생성 콘텐츠가 타인의 지적 재산권을 침해하여 발생하는 모든 책임 또한 가입자에게 귀속됩니다.'
                  )}{' '}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '가입자가 음란성, 폭력성, 잔혹성, 혐오성 및 사회질서를 저해하거나 타인의 권리를 침해하는 콘텐츠를 생성 및 업로드 하는 것을 금지합니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>{t('정치 관련 컨텐츠 생성 금지 (글로벌 약관)')}</dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '가입자 생성 콘텐츠가 저작권법 등 관련 법령을 위반하거나 명예훼손 등 타인의 권리를 침해한다고 판단되는 경우 해당 가입자 생성 콘텐츠를 삭제하거나 해당 콘테츠에 대한 접근을 차단할 수 있습니다.'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '서비스의 디자인, 텍스트, 스크립트(script), 그래픽, 전송 기능 등 회사가 개발하여 제공하는 서비스에 관련된 모든 상표, 로고 등에 관한 저작권 등 지식재산권은 회사가 갖습니다.'
                  )}
                </dd>
                <dt>6.</dt>
                <dd>
                  {t(
                    '회사가 가입자에 대해 서비스를 제공하는 것은 이 약관에 정한 서비스 목적 하에서 회사가 허용한 방식으로 서비스에 대한 이용권한을 부여하는 것이며, 가입자는 서비스를 소유하거나 서비스에 관한 저작권을 보유하게 되는 것이 아닙니다.'
                  )}
                </dd>
                <dt>7.</dt>
                <dd>
                  {t(
                    '가입자는 회사가 가입자에게 제공하는 서비스 이용권한을 이용하여 제3자에게 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제23조(사용자 생성 영상의 관리)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '사용자 생성 콘텐츠에 대한 책임 및 권리는 콘텐츠를 생성한 사용자에게 있습니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '회사는 가입자 생성 콘텐츠의 신뢰성, 진실성, 정확성 등에 대해서 책임지지 않으며 보증하지 않습니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '가입자가 정보통신법, 저작권법 등의 법률에 위반하는 내용을 포함하는 경우 회사는 관련법에 따라 조치를 취할 수 있습니다.'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '회사는 다음 각 호와 같은 내용의 생성 영상이 배포될 경우 모욕죄 및 명예훼손죄 등 어떠한 법적 책임도 지지 않으며 오직 사용자 본인에게 책임이 있음을 알려드립니다.'
                  )}
                  <dl className="no-margin">
                    <dt>1.</dt>
                    <dd>{t('타인의 사생활이나 명예를 훼손하는 콘텐츠')}</dd>
                    <dt>2.</dt>
                    <dd>{t('음란성 콘텐츠')}</dd>
                    <dt>3.</dt>
                    <dd>{t('폭력성 콘텐츠')}</dd>
                    <dt>4.</dt>
                    <dd>
                      {t(
                        '욕설 및 비속어 등으로 사용으로 타인에게 불쾌감을 주는 콘텐츠'
                      )}
                    </dd>
                    <dt>5.</dt>
                    <dd>{t('공서양속을 해치는 콘텐츠')}</dd>
                    <dt>6.</dt>
                    <dd>
                      {t(
                        '특정 인종, 성별, 집단, 단체, 종교 등을 비하하여 불쾌감을 유발하는 콘텐츠'
                      )}
                    </dd>
                    <dt>7.</dt>
                    <dd>{t('타인의 저작침해하는 콘텐츠')}</dd>
                    <dt>8.</dt>
                    <dd>{t('정치 관련 콘텐츠 (글로벌 약관)')}</dd>
                    <dt>9.</dt>
                    <dd>
                      {t(
                        '기타 가입자의 원활한 서비스 이용에 불건전한 영향을 미칠 수 있다고 판단되는 콘텐츠'
                      )}
                    </dd>
                  </dl>
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '가입자가 비공개로 설정한 가입자 생성 콘텐츠에 대해서는 회사나 제3자가 열람할 수 없습니다. 다만, 회사는 법원, 수사기관이나 기타 행정기관으로부터 정보제공을 요청받은 경우나 기타 법률에 의해 요구되는 경우에는 회사를 포함한 이해관계가 있는 사람으로 하여금 가입자 생성 콘텐츠를 열람하게 할 수 있습니다.'
                  )}
                </dd>
                <dt>6.</dt>
                <dd>
                  {t(
                    '가입자가 서비스 계약을 해지한 경우에는 본인이 작성한 저작물 일체는 삭제될 수 있습니다. 다만, 제3자에 의하여 보관되거나 무단 복제 등을 통하여 복제됨으로써 해당 저작물이 삭제되지 않고 재 게시된 경우에 대하여 회사는 책임을 지지 않습니다.'
                  )}
                </dd>
                <dt>7.</dt>
                <dd>
                  {t(
                    '회사는 본 약관 및 관계 법령을 위반한 가입자의 아이디 및 회원정보를 회사의 개인정보취급방침에 따라 보존할 수 있습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제24조(콘텐츠 이용환경)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 가입자에게 저작물 최종 콘텐츠 파일의 다운로드 및 재생은 MP4를 지원하는 기기에서만 가능하다는 사실을 고지하고, 가입자는 저작물을 MP4 포맷으로 다운로드 받거나 재생할 수 있는 서비스를 결제하기 이전에 작업환경이 MP4를 지원하는지 확인하여야 합니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '가입자는 회사가 제공하는 서비스를 통해 제작된 가입자 생성 콘텐츠를 회사 서비스를 이용하여 다운로드받은 형태 그대로 사용해야 합니다. 다운로드받은 콘텐츠의 합성음 및 영상을 별도로 왜곡, 변조, 편집을 통한 2차 변형을 해서는 안 됩니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '가입자는 서비스를 이용할 때 회사가 제공하는 다운로드 기능을 통해서 만 콘텐츠를 저장해야 합니다. 회사가 제공하는 다운로드 기능을 통하지 않은 방법으로 콘텐츠를 저장, 전송, 복제하는 것을 금합니다.'
                  )}{' '}
                </dd>
              </dl>
              <strong>{t('제7장 손해배상 및 책임제한')}</strong>
              <br />
              <strong>{t('제25조(손해배상)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 회사의 귀책사유로 인하여 가입자가 손해를 입은 경우 약관 및 관계법령이 규정하는 범위 내에서 가입자에게 그 손해를 배상합니다.'
                  )}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '제1항의 손해가 천재지변 또는 불가항력으로 인하여 발생한 때에는 그 손해를 배상하지 아니합니다.'
                  )}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '손해배상의 청구는 회사에 청구사유, 청구금액 및 산출근거를 기재하여 이메일, 전화 등으로 신청하여야 합니다.'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '가입자가 본 약관을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 가입자는 회사에게 그 손해를 배상할 책임이 있습니다.'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '회사 및 타인에게 피해를 주어 피해자의 고발 또는 소송 제기로 인하여 손해배상이 청구된 가입자는 이에 응하여야 합니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제26조(책임제한)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 가입자에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.'
                  )}
                  <dl className="no-margin">
                    <dt>1.</dt>
                    <dd>
                      {t(
                        '천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우'
                      )}
                    </dd>
                    <dt>2.</dt>
                    <dd>
                      {t(
                        '서비스의 효율적인 제공을 위한 시스템 개선, 장비 증설 등 계획된 서비스 중지 일정을 사전에 공지한 경우'
                      )}
                    </dd>
                    <dt>3.</dt>
                    <dd>
                      {t(
                        '서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 방해가 있는 경우'
                      )}
                    </dd>
                    <dt>4.</dt>
                    <dd>
                      {t('가입자의 귀책사유로 서비스 이용에 장애가 있는 경우')}
                    </dd>
                    <dt>5.</dt>
                    <dd>{t('회사의 고의 과실이 없는 사유로 인한 경우')}</dd>
                  </dl>
                </dd>
                <dt>2.</dt>
                <dd>
                  {t('회사는 가입자 생성 콘텐츠에 대해서는 책임을 면합니다.')}{' '}
                </dd>
                <dt>3.</dt>
                <dd>
                  {t(
                    '회사는 가입자가 무료 요금제를 통해 제공받는 서비스의 대하여 회사의 귀책사유로 가입자에게 서비스를 제공하지 못하는 경우 책임을 면합니다.'
                  )}
                </dd>
                <dt>4.</dt>
                <dd>
                  {t(
                    '약관의 적용은 서비스계약을 체결한 가입자에 한하며 제3자로부터의 어떠한 배상, 소송 등에 대하여 회사는 책임을 면합니다.'
                  )}
                </dd>
                <dt>5.</dt>
                <dd>
                  {t(
                    '컴퓨터와 통신 시스템의 오류에 따라 서비스가 일시 중지 또는 중단될 수 있으며, 회사는 이에 따른 서비스의 오류 없음이나 가입자 등록한 계정의 손실이 발생하지 않음을 보장하지 않습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('제27조(준거법 및 재판관할)')}</strong>
              <dl>
                <dt>1.</dt>
                <dd>
                  {t(
                    '본 약관은 한국어를 정본으로 합니다. 본 약관 및 서비스는 대한민국법령에 의하여 규정되고 이행됩니다. 서비스 이용과 관련하여 회사와 가입자 간에 분쟁이 발생하면 분쟁 해결을 위해 성실히 협의할 것입니다.'
                  )}{' '}
                </dd>
                <dt>2.</dt>
                <dd>
                  {t(
                    '분쟁의 처리는 대한민국 민사소송법에서 정한 절차를 따르며 민사소송법상의 관할법원에 소를 제기할 수 있습니다.'
                  )}
                </dd>
              </dl>
              <strong>{t('부 칙')}</strong> <br />
              {t(
                '제 1조(시행일) 이 약관은 2022년 6월 1일부터 적용됩니다.'
              )}{' '}
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
