export default function ExclamationIcon() {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="error"
        d="M4.95 6.05H6.05V2.75H4.95V6.05ZM5.5 8.25C5.65583 8.25 5.78655 8.1972 5.89215 8.0916C5.99738 7.98637 6.05 7.85583 6.05 7.7C6.05 7.54417 5.99738 7.41345 5.89215 7.30785C5.78655 7.20262 5.65583 7.15 5.5 7.15C5.34417 7.15 5.21363 7.20262 5.1084 7.30785C5.0028 7.41345 4.95 7.54417 4.95 7.7C4.95 7.85583 5.0028 7.98637 5.1084 8.0916C5.21363 8.1972 5.34417 8.25 5.5 8.25ZM5.5 11C4.73917 11 4.02417 10.8555 3.355 10.5666C2.68583 10.278 2.10375 9.88625 1.60875 9.39125C1.11375 8.89625 0.721967 8.31417 0.4334 7.645C0.144467 6.97583 0 6.26083 0 5.5C0 4.73917 0.144467 4.02417 0.4334 3.355C0.721967 2.68583 1.11375 2.10375 1.60875 1.60875C2.10375 1.11375 2.68583 0.721783 3.355 0.43285C4.02417 0.144283 4.73917 0 5.5 0C6.26083 0 6.97583 0.144283 7.645 0.43285C8.31417 0.721783 8.89625 1.11375 9.39125 1.60875C9.88625 2.10375 10.278 2.68583 10.5666 3.355C10.8555 4.02417 11 4.73917 11 5.5C11 6.26083 10.8555 6.97583 10.5666 7.645C10.278 8.31417 9.88625 8.89625 9.39125 9.39125C8.89625 9.88625 8.31417 10.278 7.645 10.5666C6.97583 10.8555 6.26083 11 5.5 11ZM5.5 9.9C6.71917 9.9 7.75738 9.47155 8.61465 8.61465C9.47155 7.75738 9.9 6.71917 9.9 5.5C9.9 4.28083 9.47155 3.24262 8.61465 2.38535C7.75738 1.52845 6.71917 1.1 5.5 1.1C4.28083 1.1 3.2428 1.52845 2.3859 2.38535C1.52863 3.24262 1.1 4.28083 1.1 5.5C1.1 6.71917 1.52863 7.75738 2.3859 8.61465C3.2428 9.47155 4.28083 9.9 5.5 9.9Z"
        fill="#9C9C9C"
      />
    </svg>
  );
}
