export default function Title({ title, setTitle, editProjectName, t }) {
  return (
    <div className="col flex alignCenter">
      <div className="title ">
        {title.input ? (
          <>
            <h2>{title.text}</h2>
            <button
              className="studio__pencil__icon"
              onClick={() => {
                setTitle({ ...title, input: !title.input });
                editProjectName();
              }}>
              <div className="ballon__tool">
                <span>{t('수정')}</span>
              </div>
            </button>
          </>
        ) : (
          <>
            <input
              type="text"
              className="studio__title__input width-dynamic proba dva"
              value={title.text}
              onChange={e => setTitle({ ...title, text: e.target.value })}
            />
            <button
              className="save__btn"
              style={{ width: '40px', transition: 'none', height: '32px' }}
              onClick={() => {
                setTitle({ ...title, input: !title.input });
                editProjectName();
              }}>
              <p style={{ margin: 0 }}>{t('저장')}</p>
            </button>
          </>
        )}
      </div>
    </div>
  );
}
