import { Listbox, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { languageArray } from '../../arrays';
import { TranslateInfoType } from '../../types_new/project/types';

type Props = {
  translateInfo?: TranslateInfoType;
  setTranslateInfo?: Dispatch<SetStateAction<TranslateInfoType>>;
};

export default function LanguageListBox({
  translateInfo,
  setTranslateInfo,
}: Props) {
  const { t } = useTranslation();
  const handleOnChange = ({ value }: any) =>
    setTranslateInfo(prev => ({ ...prev, selectedLang: value }));

  const displayLanguage = languageArray.find(
    lang => lang.value === translateInfo.selectedLang
  )?.label;

  return (
    <Listbox value={translateInfo.selectedLang} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative w-full z-10">
          <Listbox.Button className="w-full focus:border-primary flex justify-between items-center px-4 2xl:px-5 py-2 2xl:py-2.5 border border-grayBorder2 rounded-lg text-consoleDark text-sm focus:outline-none ring-offset-0">
            <span>
              {translateInfo.selectedLang ? t(displayLanguage) : t('언어 선택')}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className={`h-5 w-5 text-gray-400 duration-150 ${
                  open && 'rotate-180'
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="ease-in-out duration-150"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4">
            <Listbox.Options className="no-scroll absolute mt-2 max-h-72 w-full shadow-2xl overflow-auto rounded-md p-1 text-base ring-1 ring-[#e9e9e9] focus:outline-none sm:text-sm bg-white">
              {languageArray.map((lang, idx) => {
                const isActive = translateInfo.selectedLang === lang.value;

                return (
                  <Listbox.Option
                    key={`type-${idx}`}
                    className={({ active }) =>
                      `relative w-full flex items-center cursor-pointer select-none px-4 2xl:px-5 py-2.5 2xl:py-3 rounded-md duration-75 ease-out text-black ${
                        active && 'bg-[#F8F7FF]'
                      }`
                    }
                    value={lang}>
                    <div className="w-full flex justify-between items-center">
                      <span
                        className={`flex items-center truncate max-md:text-sm ${
                          isActive
                            ? 'font-medium text-primary'
                            : 'font-normal text-consoleDark'
                        }`}>
                        <img
                          src={lang.flag}
                          alt="country flag"
                          className="countryIcon mr-2 !w-6"
                        />
                        {t(lang.label)}
                      </span>
                    </div>
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
