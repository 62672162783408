import { Dialog, Transition } from '@headlessui/react';
import moment from 'moment';
import { Fragment, useRef } from 'react';
import { NoticeModalType } from '../../../types_new/main/landing/types';
import { findValueByKey } from '../../../utils/common';

type Props = {
  noticeModal: NoticeModalType;
  closeModal: () => void;
};

export default function NoticeModal({ noticeModal, closeModal }: Props) {
  const { isOpen, postTitle, postContent, metaData, registerDate } =
    noticeModal;
  const category = findValueByKey(metaData, 'category');
  const initialRef = useRef(null);

  function displayCategory() {
    switch (category) {
      case 'news':
        return '뉴스피드';
      default:
        return '뉴스피드';
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={initialRef}
        ref={initialRef}
        as="div"
        className="relative z-40"
        onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="z-10 fixed right-1/2 translate-x-1/2 bottom-0 sm:w-3/4 md:w-2/3 xl:w-1/2 max-w-screen-lg h-fit bg-black">
            <div className="fixed bottom-0 w-full h-10 lg:h-12 2xl:h-16 bg-gradient-to-t from-white to-white/10" />
          </div>
        </Transition.Child>
        <div className="fixed w-full h-2/3 sm:h-[90%] inset-auto bottom-0 duration-150 overflow-visible">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0  translate-y-80"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0  translate-y-80">
            <Dialog.Panel className="relative text-left mx-auto w-full sm:w-3/4 md:w-2/3 xl:w-1/2 max-w-screen-lg h-full !overflow-x-visible !overflow-y-auto no-scroll transform rounded-t-2xl lg:rounded-t-lg p-8 lg:p-10 2xl:p-14 bg-white transition-all">
              <span className="text-primary text-xs md:text-sm font-bold">
                {displayCategory()}
              </span>
              <Dialog.Title
                as="h3"
                className="text-2xl md:text-3xl font-bold mt-4 md:mt-5 text-darkSecondary">
                {postTitle}
              </Dialog.Title>
              <span className="block mt-2.5 md:mt-5 text-zinc-500">
                {moment(registerDate).format('YYYY. MM. DD')}
              </span>
              <article className="mt-2">
                <div
                  className="text-[#333A43] text-sm md:text-base leading-6 md:leading-7 prose sm:prose-sm lg:prose-lg xl:prose-xl max-w-none no-heading"
                  dangerouslySetInnerHTML={{ __html: postContent }}
                />
              </article>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
