const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    if (typeof window !== 'undefined') {
      const savedValue = localStorage.getItem(key);
      // setSelf -> Callbacks to set or reset the value of the atom.
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      // onSet -> Subscribe to changes in the atom value.
      onSet((newValue: any, _: any, isReset: boolean) => {
        if (isReset) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, JSON.stringify(newValue));
        }
      });
    }
  };

const sessionStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    if (typeof window !== 'undefined') {
      const savedValue = sessionStorage.getItem(key);

      // setSelf -> Callbacks to set or reset the value of the atom.
      if (savedValue != null) {
        if (savedValue === 'undefined') {
          if (key === 'paySubscribeInfo') {
            const initialPayInfo = {
              fkCompany: 0,
              pkPpCard: 0,
              itemCd: '',
              itemType: '',
              itemName: '',
              credit: 0,
              creditFree: 0,
              creditPolicy: 0,
              creditPolicyFree: 0,
              cost: 0,
              minusYn: '',
              payYn: '',
              custYn: '',
              freeYn: '',
              subscYn: '',
              subscTermUnit: '',
              subscTermCnt: 0,
              preUseLimitRate: 0,
              limitTermUnit: '',
              limitTermCnt: 0,
              limitDtSameYnFrom: '',
              limitDtSameYnTo: '',
              limitDtFrom: '',
              limitDtTo: '',
              payDtFrom: '',
              payDtTo: '',
              nthCnt: 0,
              creditUsedRate: 0,
            };
            console.log('savedvalue === undefined && key === paySubscribeInfo');
            setSelf(initialPayInfo);
          } else if (key === 'userInfo') {
            const initialUserInfo = {
              adminRole: '',
              membershipType: '',
              role: '',
              usage: 0,
              userCountryCode: '',
              userDept: '',
              userEmail: '',
              userId: 0,
              userLogin: '',
              userRegistered: '',
              lastLoginTime: '',
              userImage: '',
              userName: '',
              credit: '',
              userPhone: '',
              volumeTotal: '',
              limitDtTo: '',
              project: [],
            };
            console.log('savedvalue === undefined && key === userInfo');
            setSelf(initialUserInfo);
          }
        } else {
          setSelf(JSON.parse(savedValue));
        }
      }

      // onSet -> Subscribe to changes in the atom value.
      onSet((newValue: any, _: any, isReset: boolean) => {
        if (isReset) {
          sessionStorage.removeItem(key);
        } else {
          sessionStorage.setItem(key, JSON.stringify(newValue));
        }
      });
    }
  };

export { localStorageEffect, sessionStorageEffect };
