import moment from 'moment';
import { PaymentInfoType } from '../../../../types_new/main/resource/types';

const dataList = [
  '항목',
  '결제수단',
  '금액',
  '크레딧 내역',
  '결제 일시',
  '환불신청',
];

type Props = {
  data: PaymentInfoType;
};

export default function MobilePaymentCard({ data }: Props) {
  return (
    <div className="max-sm:flex hidden my-4 rounded-lg bg-white text-darkSecondary py-4 px-5 space-y-4 items-center justify-between">
      <ul className="flex flex-col space-y-3">
        {dataList.map((list, idx) => (
          <li key={`list-${idx}`} className="font-normal text-sm">
            {list}
          </li>
        ))}
      </ul>

      <ul className="flex flex-col text-sm font-medium space-y-3 text-right">
        <li>{data.payName}</li>
        <li>{data.payMethod}</li>
        <li>{data.sumCost}</li>
        <li>{data.totalCredit}</li>
        <li>{moment(data.payDtFrom).format('YYYY-MM-DD')}</li>
        <li>완료</li>
      </ul>
    </div>
  );
}
