import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { changeHost } from '../utils/common';
import MetahumanSelectGrid from './MetahumanSelectGrid';
import PostureSelectGrid from './PostureSelectGrid';
import ScriptTextarea from './ScriptTextarea';
import StyleSelectGrid from './StyleSelectGrid';
import VoiceSelectGrid from './VoiceSelectGrid';

type Props = {
  onClick?: () => void;
  isDisabled: boolean;
  label: string;
  isActive: boolean;
  idx: number;
  getSelectedValue: () => string;
  metahumanImage?: string;
  tabValue: string;
  setPopupInfo: Dispatch<SetStateAction<any>>;
  popupInfo: any;
  scriptValue: string;
  setScriptValue: Dispatch<SetStateAction<string>>;
  tempo: number;
  setTempo: Dispatch<SetStateAction<number>>;
  openGPTModal: () => void;
  openTranslateModal: () => void;
  scriptChange: boolean;
  translatedLang: string;
  selectedHumanLang: string;
};

export default function ListTab({
  onClick,
  isDisabled,
  label,
  isActive,
  idx,
  getSelectedValue,
  metahumanImage,
  tabValue,
  setPopupInfo,
  popupInfo,
  scriptValue,
  setScriptValue,
  tempo,
  setTempo,
  openGPTModal,
  openTranslateModal,
  translatedLang,
  scriptChange,
  selectedHumanLang,
}: Props) {
  const { t } = useTranslation();

  const componentMap = {
    metahuman: (
      <MetahumanSelectGrid setPopupInfo={setPopupInfo} popupInfo={popupInfo} />
    ),
    style: (
      <StyleSelectGrid setPopupInfo={setPopupInfo} popupInfo={popupInfo} />
    ),
    posture: (
      <PostureSelectGrid setPopupInfo={setPopupInfo} popupInfo={popupInfo} />
    ),
    voice: (
      <VoiceSelectGrid setPopupInfo={setPopupInfo} popupInfo={popupInfo} />
    ),
    script: (
      <ScriptTextarea
        setPopupInfo={setPopupInfo}
        popupInfo={popupInfo}
        scriptValue={scriptValue}
        setScriptValue={setScriptValue}
        tempo={tempo}
        setTempo={setTempo}
        openGPTModal={openGPTModal}
        openTranslateModal={openTranslateModal}
        scriptChange={scriptChange}
        translatedLang={translatedLang}
        selectedHumanLang={selectedHumanLang}
      />
    ),
  };

  const value = getSelectedValue();

  return (
    <li className="meta-li">
      <button
        disabled={isDisabled}
        className="w-full accordion-select"
        onClick={onClick}>
        <span className={`${isActive && 'active'}`}>STEP{idx + 1}</span>
        <strong className={`${isActive && 'active'}`}>{t(label)}</strong>
        {tabValue !== 'script' && value && (
          <div className="h-[29px] w-auto px-2 py-3 bg-primary rounded-[20px] flex items-center gap-1">
            {tabValue === 'metahuman' && (
              <div
                className="selectAfter-img"
                style={{
                  backgroundImage: `url(${changeHost(metahumanImage)})`,
                }}
              />
            )}
            <h3 className="font-bold text-white text-center text-xs">
              {value}
            </h3>
          </div>
        )}
        {isActive && <button className="popup__pencil__icon" />}
      </button>
      {popupInfo.popupNum === idx + 1 && componentMap[tabValue]}
    </li>
  );
}
