import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { subscriptionMembershipArray } from '../../../arrays';
import MembershipCard from './membership-card';

export default function SubscribeMembershipList() {
  const [activeIdx, setActiveIdx] = useState(0);
  const { t } = useTranslation();

  return (
    <div>
      <h4 className="max-lg:px-5 pt-6 lg:pt-12 text-xl lg:text-2xl 2xl:text-3xl font-bold">
        {t('정기 구독')}
      </h4>
      <Swiper
        onRealIndexChange={({ realIndex }: { realIndex: number }) =>
          setActiveIdx(realIndex)
        }
        className="overflow-y-visible max-lg:px-5 pt-6 lg:pt-9"
        breakpoints={{
          1024: {
            freeMode: true,
            slidesPerView: 3,
            centeredSlides: false,
          },
          768: {
            slidesPerView: 2.2,
            centeredSlides: false,
          },
        }}
        centeredSlides
        spaceBetween={30}
        slidesPerView={1.3}>
        {subscriptionMembershipArray.map((membership, idx) => (
          <SwiperSlide key={`product-${idx}`}>
            <MembershipCard
              isSubscription
              activeIdx={activeIdx}
              idx={idx}
              membership={membership}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="mt-7 flex justify-center space-x-1.5 md:hidden">
        {subscriptionMembershipArray.map((_, idx) => (
          <div
            key={`showcaseIdx-${idx}`}
            className={`w-1.5 h-1.5 rounded-full duration-200 ${
              idx === activeIdx ? 'bg-primary' : 'bg-primary/40'
            }`}
          />
        ))}
      </div>
    </div>
  );
}
