/* eslint-disable */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';
import '../../css/reset.css';
import '../../css/style.css';
import { msToTime } from '../../lib/common';
import '../../locale/i18n';
import { block } from '../../store/block';
import {
  marker,
  setSegmentsInfo,
  pages as storedPages,
  segmentsItem as storedSegmentsItem,
} from '../../store/pages';
import { changeHost } from '../../utils/common';

export default function Page({ idx, metaVal }: any) {
  const [pages, setPages] = useRecoilState(storedPages);
  const setMarkerX = useSetRecoilState<any>(marker);
  const [selectBlock, setSelectBlock] = useRecoilState(block);
  const setSegmentsItem = useSetRecoilState(storedSegmentsItem);
  const [overlappedBackground, setOverlappedBackground] = useState(null);
  const { t } = useTranslation();

  const MetaHuman5 = {
    backgroundImage: `url(${changeHost(metaVal.source)})`,
  };

  const getInsertTime = (railIndex: number) => {
    let insertT = 0;
    const tmpSegment = pages[0]?.segments[0]?.segment?.filter(
      item => item.mediaType === 'metahuman' && item.railIndex === railIndex
    );

    let maxInsertTime = -1;
    let maxInsertTimeId = -1;
    tmpSegment.map(item => {
      if (maxInsertTime <= item.insertTime) {
        maxInsertTime = item.insertTime;
        maxInsertTimeId = item.segmentId || item.imageId || item.titleId;
      }
    });
    tmpSegment.map(item => {
      if (
        item.segmentId === maxInsertTimeId ||
        item.imageId === maxInsertTimeId ||
        item.titleId === maxInsertTimeId
      ) {
        insertT = item.insertTime + item.duration;
      }
    });
    return insertT;
  };
  const targetIntegratedId =
    pages[0]?.segments[0]?.segment?.filter(
      item => item.segmentId === selectBlock.groupIndex
    )[0]?.metaData?.integratedId || null;

  const findPPTbackground = () => {
    const objectVal = Object.assign({}, metaVal);
    const tmpSegment = pages[0]?.segments[0]?.segment.filter(
      (element: any) => element.segmentId !== objectVal.segmentId
    );

    //PPT 또는 PDF 업로드 한 배경만 삭제. 빈화면 추가한 장면의 배경은 삭제 연동 안됨.
    const tmpBackground = objectVal?.metaData?.pptId
      ? pages[0]?.segments[0]?.background.filter(
          (element: any) => element.indexKey !== objectVal.indexKey
        )
      : JSON.parse(JSON.stringify(pages[0]?.segments[0]?.background));

    return [
      {
        segments: [
          {
            ...pages[0]?.segments[0],
            segment: tmpSegment,
            background: tmpBackground,
          },
        ],
      },
    ];
  };

  const removeTimelineEmpty = page => {
    if (page) {
      const tmpPage = JSON.parse(JSON.stringify(page));
      const { segment } = pages[0].segments[0];
      const targetMH = segment?.filter(
        item => item.segmentId === Number(metaVal.segmentId)
      )[0];

      tmpPage[0]?.segments[0]?.segment?.map(item => {
        if (item?.insertTime > targetMH.insertTime && item.railIndex === 0) {
          item.insertTime = item?.insertTime - targetMH?.duration;
        }
        return item;
      });
      tmpPage[0]?.segments[0]?.background?.map(item => {
        if (item?.insertTime > targetMH.insertTime && item.railIndex === 0) {
          item.insertTime = item?.insertTime - targetMH?.duration;
        }
        return item;
      });

      setPages(tmpPage);
    }
  };

  // 백그라운드의 범위가 메타휴먼의 모든 범위를 포함하는 경우에만 Scene에 표시.
  useEffect(() => {
    const backgrounds = pages[0]?.segments[0]?.background;
    const metahumans = pages[0]?.segments[0]?.segment.filter(
      seg => seg.mediaType === 'metahuman'
    );
    const relevantHuman = metahumans[idx];
    if (!relevantHuman) return;
    if (backgrounds && backgrounds.length > 0) {
      const overlappedBackground = backgrounds.find(
        bg =>
          bg.insertTime <= relevantHuman.insertTime &&
          getEndTime(bg) >= getEndTime(relevantHuman)
      );

      setOverlappedBackground(overlappedBackground);
    }

    function getEndTime(seg: any) {
      return seg.insertTime + seg.duration;
    }
  }, [pages]);

  return (
    <li
      className="state__not_transition"
      onClick={() => {
        setMarkerX({ cs: metaVal.insertTime, from: 'page' });
        setSelectBlock({ groupIndex: idx, segmentsGroup: 'segment' });
        setSegmentsItem({ id: metaVal.segmentId, type: 'metahuman' });
      }}>
      <div
        style={{
          backgroundImage: `url(${changeHost(
            overlappedBackground?.previewImage
          )})`,
          backgroundColor: `#${overlappedBackground?.color || '222222'}`,
        }}
        className={`card ddd ${
          selectBlock.groupIndex === idx
            ? 'active'
            : metaVal?.metaData?.integratedId === targetIntegratedId
            ? 'active'
            : null
        }`}>
        <div className="bg" style={MetaHuman5} />

        <div className="card__content">
          <div className="page__num">
            <i className="pages__white__icon" />
            <span>{idx + 1}</span>
          </div>
          <p className="timer">{msToTime(metaVal.duration, 'second')}</p>
        </div>
        <div className="hov">
          <div className="other">
            <div className="other__btn__list">
              <button
                className="copy__btn"
                onClick={() => {
                  const objectVal = { ...metaVal };
                  objectVal.insertTime = getInsertTime(metaVal.railIndex);
                  objectVal.segmentId = Date.now();
                  objectVal.indexKey = objectVal.segmentId;
                  const type = {
                    pagesIndex: 0,
                    segment: {
                      segmentIndex: pages[0]?.segments[0]?.segment.length,
                      segment: objectVal,
                    },
                  };

                  setSegmentsInfo(pages, setPages, type);
                }}>
                <i className="copy__icon" />
                <span>{t('복사하기')}</span>
              </button>

              <button
                className="remove__btn"
                onClick={() => {
                  if (metaVal?.metaData?.pptId) {
                    if (
                      confirm(
                        '메타휴먼 변경 시 하위의 ppt 및 pdf 배경 블록의 길이만 맞춰집니다.'
                      )
                    ) {
                      removeTimelineEmpty(findPPTbackground());
                    }
                  } else {
                    removeTimelineEmpty(findPPTbackground());
                  }
                }}>
                <i className="remove__icon" />
                <span>{t('삭제하기')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}
