export default function StrikeThroughIcon() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.375 6.0939H7.29462C7.11424 6.0454 6.93302 6.00003 6.75106 5.95781C5.61031 5.68806 4.96519 5.49062 4.96519 4.56721C4.95592 4.40781 4.97953 4.2482 5.03452 4.0983C5.08952 3.94839 5.17475 3.8114 5.28491 3.69581C5.62997 3.41204 6.06219 3.25568 6.50894 3.25299C7.65862 3.22456 8.18878 3.61456 8.62225 4.20768L9.27834 3.72831C8.97123 3.28726 8.55254 2.93565 8.06505 2.70939C7.57756 2.48314 7.03875 2.39034 6.50366 2.44049C5.84635 2.44469 5.21226 2.68406 4.71616 3.11528C4.52812 3.30601 4.38116 3.53324 4.28432 3.78295C4.18748 4.03267 4.14282 4.29957 4.15309 4.56721C4.13528 4.86007 4.19029 5.15278 4.31322 5.41919C4.43614 5.6856 4.62315 5.91741 4.85753 6.0939H1.625V6.9064H7.17113C7.97022 7.13796 8.44797 7.4394 8.46016 8.27059C8.47336 8.44814 8.449 8.62648 8.38866 8.79398C8.32832 8.96149 8.23336 9.1144 8.10997 9.24274C7.68008 9.5816 7.146 9.76092 6.59872 9.75015C6.19024 9.73832 5.7898 9.63375 5.42769 9.44435C5.06558 9.25496 4.75128 8.98569 4.50856 8.65693L3.88578 9.17856C4.20133 9.59907 4.60846 9.94224 5.07632 10.182C5.54418 10.4219 6.06049 10.552 6.58612 10.5626H6.62675C7.37701 10.5713 8.10518 10.3089 8.6775 9.82368C8.88062 9.61888 9.03854 9.37376 9.14105 9.10415C9.24356 8.83453 9.2884 8.54642 9.27266 8.2584C9.28782 7.76572 9.12157 7.28461 8.80547 6.9064H11.375V6.0939Z"
        fill="white"
      />
      <rect x="1.625" y="5.9585" width="9.75" height="1.08333" fill="#9A9A9C" />
    </svg>
  );
}
