import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Pagination from '../../components/common/Pagination';
import BreadCrumb from '../../components/console/my-media/bread-crumb';
import ConsoleMediaBanner from '../../components/console/my-media/media-banner';
import MediaFilter from '../../components/console/my-media/media-filter';
import SourceContainer from '../../components/console/my-media/source-container';
import SourceDetailGrid from '../../components/console/my-media/source-detail.grid';
import { doGetResourceList } from '../../networks/source.service';
import { lang } from '../../recoil/atoms/user';
import { SourceType } from '../../types_new/console/resource/types';
import { GetResourcesReqType } from '../../types_new/main/service/types';
import { MediaParamType } from '../../types_new/project/types';

const groupArray = ['background', 'clip', 'etc'];

export default function ConsoleMediaPage() {
  const { group } = useParams();
  const [loading, setLoading] = useState(false);
  const [sources, setSources] = useState<SourceType[]>([]);
  const [type, setType] = useState<GetResourcesReqType['type']>('');
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const language = useRecoilValue(lang);
  const [params, setParams] = useState<MediaParamType>({
    isMine: '1',
    isPurchased: '1',
  });

  useEffect(() => {
    setLoading(true);
    getResourceList();
  }, [group, type, paged, params]);

  const getResourceList = () => {
    doGetResourceList({
      group: group as GetResourcesReqType['group'],
      type,
      lang: language,
      postsPerPage: 15,
      paged,
      ...params,
    })
      .then(({ sources, total }) => {
        setSources(sources);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const onPageChange = (page: number) => {
    setPaged(page);
    setParentPage(page);
  };

  return (
    <section className="w-full">
      <ConsoleMediaBanner />
      <BreadCrumb />
      {group && (
        <MediaFilter
          loading={loading}
          setType={setType}
          type={type}
          getResourceList={getResourceList}
          setParams={setParams}
          params={params}
        />
      )}
      {!group ? (
        <>
          {groupArray.map((group, idx) => (
            <SourceContainer
              key={`${group}-${idx}`}
              group={group as 'background' | 'clip' | 'etc'}
            />
          ))}
        </>
      ) : (
        <>
          <SourceDetailGrid
            sources={sources}
            loading={loading}
            getResourceList={getResourceList}
          />
          <Pagination
            onPageChange={onPageChange}
            totalItems={total}
            currentPage={paged}
            parentPage={parentPage}
            itemsPerPage={15}
          />
        </>
      )}
    </section>
  );
}
