/* eslint-disable */
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import '../../css/reset.css';
import '../../css/style.css';
import { block } from '../../store/block';
import { PROJECT_INFO } from '../../store/common/projectInfo';
import {
  autoScript as autoScriptArray,
  centiSecond,
  setSegmentsInfo,
  pages as storedPages,
  segmentsItem as storedSegmentItem,
} from '../../store/pages';
import { IImage, IPages, ISegment, ITitle } from '../../store/pages/types';
import { changeHost } from '../../utils/common';
import ClipMenu from './ClipMenu';
import Rectangle from './Rectangle';
import TitleLayer from './TitleLayer';

type props = {
  imageSource: IImage[];
  metaSource: ISegment[];
  textSource: ITitle[];
  videoSource: ISegment[];
  settingMarker?: number;
  setEventCount?: any;
  eventCount?: number;
  canvasWidth: any;
};

const ImageLayer = ({
  metaSource,
  imageSource,
  textSource,
  videoSource,
  canvasWidth,
}: props) => {
  const [pages, setPages] = useRecoilState(storedPages);
  const [eventName, setEventName] = useState<string>();
  const [itemId, setItemId] = useState<number>();
  const [type, setType] = useState<string>();
  const [selectedItem, setSelectItem] = useState<ISegment | IImage>();
  const [clipMenuInOut, setClipMenuInOut] = useState<boolean>(false);
  const [metaPos, setMetaPos] = useState({});
  const [rectangles, setRectangles] = useState([]);
  const [initXY, setInitXY] = useState({ width: 1024, height: 576 });
  const [initSize, setInitSize] = useState({ width: 1080, height: 1920 });
  const projectInfo = useRecoilValue(PROJECT_INFO);
  const [textSelected, setTextSelected] = useState(0);
  const [segmentsItems, setSegmentsItems] = useRecoilState(storedSegmentItem);
  const cs = useRecoilValue(centiSecond);
  const [autoScript, setAutoScript] = useRecoilState(autoScriptArray);
  const setSelectBlock = useSetRecoilState(block);

  const defaultRate =
    projectInfo?.canvasSize === '9:16' || projectInfo?.canvasSize === '10:16'
      ? 576 / 1920
      : projectInfo?.canvasSize === '16:10'
      ? (576 / 1080) * 0.9
      : 576 / 1080;

  useEffect(() => {
    setInitXY({
      width: canvasWidth,
      height: 576,
    });
  }, [canvasWidth]);

  useEffect(() => {
    const initialRectangles = [];
    metaSource?.map(item => {
      initialRectangles.push({
        x: item?.posX && '' ? 400 : (initXY.width * item?.posX) / 100,
        y: item?.posY && '' ? 300 : (initXY.height * item?.posY) / 100,
        width:
          item?.width && ''
            ? 100
            : initSize?.width * defaultRate * (item?.width / 100),
        height:
          item?.height && ''
            ? 100
            : initSize?.height * defaultRate * (item?.height / 100),
        id: 'meta_image',
        zIndex: item?.zIndex,
        uniqueId: item?.segmentId,
        img: item?.source,
      });
    });
    const imageData = pages[0]?.segments[0].image[0];
    // console.log('[ImageLayer] [effect] [source] imageData:' + JSON.stringify(imageData));
    // console.log('[ImageLayer] [effect] [source] image.pos(x,y): (' + imageData?.posX + ', ' + imageData?.posY + ')');
    // console.log('[ImageLayer] [effect] [source] image.size(width,height): (' + imageData?.width + ', ' + imageData?.height + ')');
    imageSource?.map(item => {
      // console.log('[ImageLayer] [effect] [source] imageSource - item:' + JSON.stringify(item));
      initialRectangles.push({
        x: item?.posX && '' ? 33 : (initXY.width * item?.posX) / 100,
        y: item?.posY && '' ? 33 : (initXY.height * item?.posY) / 100,
        width:
          item?.metaData?.width && ''
            ? 100
            : item?.metaData?.width * defaultRate * (item?.width / 100),
        height: item?.metaData?.height * defaultRate * (item?.height / 100),
        id: 'image',
        zIndex: item?.zIndex,
        uniqueId: item?.imageId,
        rotation: item?.rotate,
        img: item?.imageUrl,
      });
    });
    videoSource?.map(item => {
      initialRectangles.push({
        x: item?.posX && '' ? 33 : (initXY.width * item?.posX) / 100,
        y: item?.posY && '' ? 33 : (initXY.height * item?.posY) / 100,
        width:
          item?.metaData?.width && ''
            ? 100
            : item?.metaData?.width * defaultRate * (item?.width / 100),
        height: item?.metaData?.height * defaultRate * (item?.height / 100),
        id: 'video',
        zIndex: item?.zIndex,
        uniqueId: item?.segmentId,
        img: item?.previewImage,
      });
    });
    textSource?.map(item => {
      initialRectangles.push({ ...item, id: 'text' });
    });
    initialRectangles.sort((a: any, b: any) => {
      let aaa = a.zIndex;
      let bbb = b.zIndex;
      return aaa - bbb;
    });

    const autoSubtitletArray = initialRectangles.filter(
      item => item?.metaData?.script
    );
    const notAutoSubtitletArray = initialRectangles.filter(
      item => !item?.metaData?.script
    );
    const tmpArray = [...notAutoSubtitletArray, ...autoSubtitletArray];

    tmpArray.map(item => (item.zIndex = 0));
    setRectangles(tmpArray);
  }, [metaSource, imageSource, videoSource, textSource]);

  const [selectedId, selectShape] = useState(null);
  const checkDeselect = (e: any) => {
    const clickedOnEmpty = e.target === e.target.getStage();
    if (clickedOnEmpty) {
      selectShape(null);
      setSelectItem(undefined);
      setTextSelected(0);
      setSegmentsItems({});
    }
  };
  const [bbox, setBbox] = useState<DOMRect>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (containerRef.current) {
      const box = containerRef.current.getBoundingClientRect();
      setBbox(box);
    }
  }, [containerRef.current?.clientWidth]);

  useEffect(() => {
    console.log('[ImageLayer] [effect] [event] pages:' + JSON.stringify(pages));
    clipMenuEvent(
      type,
      eventName,
      setEventName,
      itemId,
      pages,
      setPages,
      autoScript,
      setAutoScript,
      cs
    );
  }, [eventName]);

  return (
    <div
      className="content"
      ref={containerRef}
      onClick={() => setClipMenuInOut(true)}>
      {bbox && (
        <Stage
          width={bbox.width}
          height={bbox.height}
          onMouseDown={checkDeselect}
          onTouchStart={checkDeselect}>
          <Layer>
            {rectangles?.map((rect: any, idx: number) => {
              if (rect.id !== 'text') {
                return (
                  <Rectangle
                    key={`rect-${idx}`}
                    setType={setType}
                    initXY={initXY}
                    setEventName={setEventName}
                    selectedItem={selectedItem}
                    clipMenuInOut={clipMenuInOut}
                    setSelectItem={setSelectItem}
                    image={changeHost(rect.img)}
                    shapeProps={rect}
                    isSelected={rect.uniqueId === segmentsItems.id}
                    imageSource={imageSource}
                    videoSource={videoSource}
                    itemType={rect.id}
                    metaSource={metaSource}
                    setItemId={setItemId}
                    metaPos={metaPos}
                    onSelect={() => {
                      setSegmentsItems({ id: rect.uniqueId });
                      selectShape(rect.id);
                      setTextSelected(0);
                    }}
                    onChange={(newAttrs: any) => {
                      if (rect.id === 'image') {
                        const imageIndex =
                          pages[0]?.segments[0]?.image?.findIndex(
                            (element: any) => element?.imageId === rect.uniqueId
                          );
                        const image = pages[0]?.segments[0]?.image?.find(
                          (element: any) => element?.imageId === rect.uniqueId
                        );
                        const imageArray = { ...image };
                        imageArray.posX =
                          Math.floor((newAttrs.x / initXY.width) * 100 * 100) /
                          100;
                        imageArray.posY =
                          Math.floor((newAttrs.y / initXY.height) * 100 * 100) /
                          100;
                        imageArray.width =
                          (newAttrs.width /
                            defaultRate /
                            image?.metaData?.width) *
                          100;
                        imageArray.height =
                          (newAttrs.height /
                            defaultRate /
                            image?.metaData?.height) *
                          100;
                        imageArray.rotate =
                          newAttrs.rotation < 0
                            ? 360 + newAttrs.rotation
                            : newAttrs.rotation;
                        const type = {
                          pagesIndex: 0,
                          image: {
                            imageIndex: imageIndex,
                            image: imageArray,
                          },
                        };
                        setSegmentsInfo(pages, setPages, type);
                        setSelectBlock({
                          segmentsGroup: 'image',
                          groupIndex: imageIndex,
                        });
                      } else if (rect.id === 'meta_image') {
                        const metaIndex =
                          pages[0]?.segments[0]?.segment?.findIndex(
                            (element: any) =>
                              element?.segmentId === rect.uniqueId
                          );
                        const metaArray = {
                          ...pages[0]?.segments[0]?.segment[metaIndex],
                        };
                        metaArray.posX =
                          Math.floor(
                            ((newAttrs.x * 100) / initXY.width) * 100
                          ) / 100;
                        metaArray.posY =
                          Math.floor(
                            ((newAttrs.y * 100) / initXY.height) * 100
                          ) / 100;
                        metaArray.width =
                          (newAttrs.width / defaultRate / initSize.width) * 100;

                        metaArray.height =
                          (newAttrs.height / defaultRate / initSize.height) *
                          100;
                        const type = {
                          pagesIndex: 0,
                          segment: {
                            segmentIndex: metaIndex,
                            segment: metaArray,
                          },
                        };
                        // 메타휴먼 좌표값이 업데이트가 안되서 useState로 갱신해서 넘겨줌
                        setMetaPos(type.segment.segment);
                        setSegmentsInfo(pages, setPages, type);
                        setSelectBlock({
                          segmentsGroup: 'segment',
                          groupIndex: metaIndex,
                        });
                      } else if (rect.id === 'video') {
                        const videoIndex =
                          pages[0]?.segments[0]?.segment?.findIndex(
                            (element: any) =>
                              element?.segmentId === rect.uniqueId
                          );
                        const videoArray = {
                          ...pages[0]?.segments[0]?.segment[videoIndex],
                        };
                        const video = pages[0]?.segments[0]?.segment?.find(
                          (element: any) => element?.segmentId === rect.uniqueId
                        );
                        videoArray.posX =
                          Math.floor(
                            ((newAttrs.x * 100) / initXY.width) * 100
                          ) / 100;
                        videoArray.posY =
                          Math.floor(
                            ((newAttrs.y * 100) / initXY.height) * 100
                          ) / 100;
                        videoArray.width =
                          (newAttrs.width /
                            defaultRate /
                            video?.metaData?.width) *
                          100;
                        videoArray.height =
                          (newAttrs.height /
                            defaultRate /
                            video?.metaData?.height) *
                          100;

                        const type = {
                          pagesIndex: 0,
                          segment: {
                            segmentIndex: videoIndex,
                            segment: videoArray,
                          },
                        };
                        setSegmentsInfo(pages, setPages, type);
                        setSelectBlock({
                          segmentsGroup: 'segment',
                          groupIndex: videoIndex,
                        });
                      }
                      const rects = rectangles.slice();
                      rects[idx] = newAttrs;
                      setRectangles(rects);
                    }}
                  />
                );
              } else {
                return (
                  <>
                    <TitleLayer
                      i={idx}
                      rect={rect}
                      textSelected={textSelected}
                      setTextSelected={setTextSelected}
                      setBlocks={setSelectBlock}
                      setSegmentsItems={setSegmentsItems}
                      setType={setType}
                      setItemId={setItemId}
                      pages={pages}
                      initXY={initXY}
                      setSegmentsInfo={setSegmentsInfo}
                      setPages={setPages}
                      textSource={textSource}
                      segmentsItems={segmentsItems}
                      selectShape={selectShape}
                    />
                  </>
                );
              }
            })}
          </Layer>
        </Stage>
      )}
      {clipMenuInOut &&
      textSource?.findIndex(item => item.titleId === segmentsItems?.id) !==
        -1 &&
      segmentsItems !== undefined &&
      textSource !== undefined ? (
        <div
          className="1"
          style={{
            position: 'absolute',
            zIndex: '100000000',
            top: `${
              (initXY.height *
                textSource?.find(item => item.titleId === segmentsItems?.id)
                  ?.posY) /
                100 -
              50
            }px`,
            left: `${
              (initXY.width *
                textSource?.find(item => item.titleId === segmentsItems?.id)
                  ?.posX) /
              100
            }px`,
          }}>
          <ClipMenu setEventName={setEventName} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ImageLayer;

function clipMenuEvent(
  type: string,
  eventName: string,
  setEventName: Dispatch<SetStateAction<string>>,
  itemId: number,
  pages: IPages[],
  setPages: SetterOrUpdater<IPages[]>,
  autoScript: any,
  setAutoScript: any,
  cs?: any,
  metaSource?: ISegment,
  imageSource?: IImage,
  textSource?: ITitle,
  videoSource?: ISegment
) {
  if (eventName === 'duplicate') {
    if (type === 'image') {
      const image = {
        ...pages[0].segments[0].image.find(img => img.imageId === itemId),
      };
      const imageLength = pages[0].segments[0].image.length;
      const insert = pages[0].segments[0].image;
      const insertT = Math.max(
        ...insert.map(val => val.insertTime + val.duration),
        0
      );
      image.insertTime = insertT;
      image.imageId = Date.now();
      const imagetype = {
        pagesIndex: 0,
        image: {
          imageIndex: imageLength,
          image,
        },
      };
      setSegmentsInfo(pages, setPages, imagetype);
    } else if (type === 'metahuman' || type === 'video') {
      const segment = {
        ...pages[0].segments[0].segment.find(seg => seg.segmentId === itemId),
      };
      const segmentLength = pages[0].segments[0].segment.length;
      const insert = pages[0].segments[0].segment.filter(
        val => val.mediaType === 'metahuman' || type === 'video'
      );
      const insertT = Math.max(
        ...insert.map(val => val.insertTime + val.duration),
        0
      );
      segment.insertTime = insertT;
      segment.segmentId = Date.now();
      const segmenttype = {
        pagesIndex: 0,
        segment: {
          segmentIndex: segmentLength,
          segment,
        },
      };
      setSegmentsInfo(pages, setPages, segmenttype);
    } else if (type === 'title') {
      const title = {
        ...pages[0].segments[0].title.find(seg => seg.titleId === itemId),
      };
      const titleLength = pages[0].segments[0].title.length;
      const insert = pages[0].segments[0].title;
      const insertT = Math.max(
        ...insert.map(val => val.insertTime + val.duration),
        0
      );
      title.insertTime = insertT;
      title.titleId = Date.now();
      const titleType = {
        pagesIndex: 0,
        title: {
          titleIndex: titleLength,
          title,
        },
      };
      setSegmentsInfo(pages, setPages, titleType);
    }
  }

  if (eventName === 'delete') {
    if (type === 'image') {
      const image = pages[0].segments[0].image.filter(
        img => img.imageId !== itemId
      );
      const imagetype = {
        pagesIndex: 0,
        image: {
          imageArray: image,
        },
      };
      setSegmentsInfo(pages, setPages, imagetype);
    } else if (type === 'metahuman' || type === 'video') {
      const segment = pages[0].segments[0].segment.filter(
        seg => seg.segmentId !== itemId
      );
      const segmenttype = {
        pagesIndex: 0,
        segment: {
          segmentArray: segment,
        },
      };
      setSegmentsInfo(pages, setPages, segmenttype);
    } else if (type === 'title') {
      const title = pages[0].segments[0].title.filter(
        seg => seg.titleId !== itemId
      );
      const titleType = {
        pagesIndex: 0,
        title: {
          titleArray: title,
        },
      };
      setSegmentsInfo(pages, setPages, titleType);
    }
  }

  if (
    eventName === 'back' ||
    eventName === 'forword' ||
    eventName === 'first' ||
    eventName === 'last'
  ) {
    const segments = pages[0].segments[0];
    const tmpArray = [];
    let changedObject: any = {};
    let changedObjectIndex = -1;

    Object.values(segments).map((array: any) => {
      array.map((item: any) => {
        if (item.insertTime <= cs && item.insertTime + item.duration >= cs) {
          tmpArray.push(item);
        }
      });
    });

    Object.values(segments).map((array: any) => {
      array.map((item: any, idx) => {
        if (
          item?.segmentId === itemId ||
          item.titleId === itemId ||
          item.imageId === itemId
        ) {
          changedObject = { ...item };
          changedObjectIndex = idx;
        }
      });
    });

    if (eventName === 'forword') {
      let maxZIndex = 0;
      tmpArray.map((item: any) => {
        if (maxZIndex < item.zIndex) {
          maxZIndex = item.zIndex;
        }
      });

      if (maxZIndex % 1000 === 0) {
        changedObject.zIndex = 1501;
      } else if (maxZIndex % 1000 !== 0) {
        changedObject.zIndex =
          Math.floor(maxZIndex / 1000) * 1000 + 1000 + (maxZIndex % 1000) + 1;
      }
    }

    if (eventName === 'back') {
      let maxZIndex = 0;
      tmpArray.map((item: any) => {
        if (maxZIndex > item.zIndex) {
          maxZIndex = item.zIndex;
        }
      });

      if (maxZIndex % 1000 === 0) {
        changedObject.zIndex = -1499;
      } else if (maxZIndex % 1000 !== 0) {
        changedObject.zIndex =
          Math.floor(maxZIndex / 1000) * 1000 - 1000 - (maxZIndex % 1000) - 1;
      }
    }

    if (eventName === 'first') {
      let maxZIndex = 0;
      tmpArray.map((item: any) => {
        if (maxZIndex < item.zIndex) {
          maxZIndex = item.zIndex;
        }
      });

      if (maxZIndex % 1000 === 0) {
        changedObject.zIndex = 1501;
      } else if (maxZIndex % 1000 !== 0) {
        if (maxZIndex % 1000 > 500) {
          changedObject.zIndex = maxZIndex + 1000 + 1;
        } else {
          changedObject.zIndex = maxZIndex + 1501;
        }
      }
    }

    if (eventName === 'last') {
      let maxZIndex = 0;
      tmpArray.map((item: any) => {
        if (maxZIndex > item.zIndex) {
          maxZIndex = item.zIndex;
        }
      });

      if (maxZIndex % 1000 === 0) {
        changedObject.zIndex = -1499;
      } else if (maxZIndex % 1000 !== 0) {
        if (maxZIndex % 1000 < 500) {
          changedObject.zIndex = maxZIndex - 1000 - 1;
        } else {
          changedObject.zIndex = maxZIndex - (maxZIndex % 1000) - 1000 + 499;
        }
      }
    }

    if (type === 'metahuman' || type === 'video') {
      const metaType = {
        pagesIndex: 0,
        segment: {
          segmentIndex: changedObjectIndex,
          segment: changedObject,
        },
      };
      setSegmentsInfo(pages, setPages, metaType);
    } else if (type === 'title') {
      const titleType = {
        pagesIndex: 0,
        title: {
          titleIndex: changedObjectIndex,
          title: changedObject,
        },
      };
      setSegmentsInfo(pages, setPages, titleType);
    } else if (type === 'image') {
      const imageType = {
        pagesIndex: 0,
        image: {
          imageIndex: changedObjectIndex,
          image: changedObject,
        },
      };
      setSegmentsInfo(pages, setPages, imageType);
    }
  }
  setEventName('');
}
