export default function RightArrowIcon() {
  return (
    <svg
      className="w-4 h-4 lg:w-5 lg:h-5"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.76257 7.2943C1.21029 7.2943 0.762573 7.74201 0.762573 8.2943C0.762573 8.84658 1.21029 9.2943 1.76257 9.2943V7.2943ZM14.9904 9.0014C15.381 8.61088 15.381 7.97771 14.9904 7.58719L8.62647 1.22323C8.23595 0.832704 7.60278 0.832704 7.21226 1.22323C6.82173 1.61375 6.82173 2.24692 7.21226 2.63744L12.8691 8.2943L7.21226 13.9512C6.82173 14.3417 6.82173 14.9748 7.21226 15.3654C7.60278 15.7559 8.23595 15.7559 8.62647 15.3654L14.9904 9.0014ZM1.76257 9.2943H14.2833V7.2943H1.76257V9.2943Z"
        fill="white"
      />
    </svg>
  );
}
