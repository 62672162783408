import { Dispatch, SetStateAction } from 'react';
import { changeHost } from '../utils/common';

type Props = {
  popupInfo: any;
  setPopupInfo: Dispatch<SetStateAction<any>>;
};

export default function PostureSelectGrid({ popupInfo, setPopupInfo }: Props) {
  return (
    <div className="accordin-menu style-scroll secondary-scroll">
      <ul className="accordin-menu-style secondary-scroll">
        {popupInfo.poseArray.map((item: any, idx: number) => {
          if (item.styleId === popupInfo.styleValue) {
            return (
              <li
                className="style-li"
                key={idx}
                onClick={() => {
                  setPopupInfo(prev => ({
                    ...prev,
                    poseId: item.poseId,
                    poseName: item.name,
                    preview: item.preview,
                    popupNum: 4,
                  }));
                }}>
                <div
                  className={`style-box ${
                    popupInfo.poseId === item.poseId && 'active'
                  }`}
                  style={{
                    backgroundImage: `url(${changeHost(item.thumbnail)})`,
                  }}
                />
                <h3>{item.name}</h3>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}
