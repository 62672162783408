import { Dispatch, MouseEvent, SetStateAction } from 'react';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import {
  MetahumanListType,
  MetahumanTabType,
} from '../../../types_new/main/resource/types';
import { changeHost } from '../../../utils/common';

type Props = {
  setTab: Dispatch<SetStateAction<MetahumanTabType>>;
  metahuman: MetahumanListType;
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  projectInfo: NewProjectInfoType;
};

export default function MetahumanCard({
  setTab,
  metahuman: { thumbnail, name, desc, ID: modelId, postId },
  setProjectInfo,
  projectInfo: { defaultMetahuman },
}: Props) {
  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (defaultMetahuman.modelId !== modelId) {
      setTab('style');
      resetDefaultMetahuman();
    }
    setProjectInfo(prev => ({
      ...prev,
      defaultMetahuman: {
        ...prev.defaultMetahuman,
        modelId,
        postId,
      },
    }));

    function resetDefaultMetahuman() {
      setProjectInfo(prev => ({
        ...prev,
        defaultMetahuman: {
          ...prev.defaultMetahuman,
          styleId: '',
          poseId: '',
          voiceId: '',
        },
      }));
    }
  };

  const isActive = defaultMetahuman.modelId === modelId;

  return (
    <div className="flex flex-col space-y-2 2xl:space-y-2.5">
      <div
        onClick={handleOnClick}
        style={{ backgroundImage: `url(${changeHost(thumbnail)})` }}
        className={`w-full h-32 2xl:h-36 bg-cover bg-center rounded-[10px] ${
          isActive ? 'ring ring-primary' : 'hover:opacity-70 cursor-pointer'
        } duration-100`}
      />
      <div className="flex flex-col space-y-0.5 text-base text-consoleDark">
        <span className="max-2xl:text-sm font-medium">{name}</span>
        <span className="text-xs text-[#ACAFC0]">{desc}</span>
      </div>
    </div>
  );
}
