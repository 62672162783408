export default function PPTIcon() {
  return (
    <svg
      width="16"
      height="26"
      viewBox="0 0 16 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.24018C0 2.38083 0.341376 1.55668 0.949027 0.949027C1.55668 0.341376 2.38083 0 3.24018 0H7.08951C9.45272 0 11.7191 0.938781 13.3902 2.60982C15.0612 4.28086 16 6.54728 16 8.91049C16 11.2737 15.0612 13.5401 13.3902 15.2112C11.7191 16.8822 9.45272 17.821 7.08951 17.821H3.24018V24.3013C3.24018 24.731 3.06949 25.1431 2.76566 25.4469C2.46184 25.7507 2.04976 25.9214 1.62009 25.9214C1.19041 25.9214 0.778339 25.7507 0.474513 25.4469C0.170688 25.1431 0 24.731 0 24.3013V3.24018ZM3.24018 14.5808H7.08951C8.59337 14.5808 10.0356 13.9834 11.099 12.92C12.1624 11.8566 12.7598 10.4144 12.7598 8.91049C12.7598 7.40663 12.1624 5.96437 11.099 4.90098C10.0356 3.83759 8.59337 3.24018 7.08951 3.24018H3.24018V14.5808Z"
        fill="#F96B73"
      />
    </svg>
  );
}
