import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { ChevronDown } from 'heroicons-react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductionInquiryFormType } from '../../../types_new/main/landing/types';

const typeArray = [
  { name: '제품광고' },
  { name: '기업/브랜드홍보' },
  { name: '인터뷰' },
  { name: '기업행사' },
  { name: '교육' },
  { name: '튜토리얼' },
  { name: '유튜브' },
  { name: '기타' },
];
type Props = {
  form: ProductionInquiryFormType;
  setForm: Dispatch<SetStateAction<ProductionInquiryFormType>>;
};
export default function ProductionInquirySelectBox({ setForm, form }: Props) {
  const { t } = useTranslation();

  return (
    <Listbox
      value={form}
      onChange={({ name }) => setForm(prev => ({ ...prev, type: name }))}>
      <div className="relative mt-1 w-full">
        <Listbox.Button className="relative w-full cursor-pointer rounded-md py-2.5 sm:py-3 2xl:py-4 px-4 2xl:px-5 pl-5 pr-6 text-left focus:outline-none border border-brayBorder text-[#d5d5d5]">
          <span className="block truncate 2xl:leading-7 text-sm 2xl:text-base font-normal">
            {t(form.type) || t('영상 유형 선택')}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="ease-in-out duration-150"
          enterFrom="opacity-0 -translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in-out duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-4">
          <Listbox.Options className="absolute mt-2 max-h-72 w-full shadow-2xl overflow-auto rounded-md py-1 text-base ring-1 ring-grayBorder focus:outline-none sm:text-sm bg-[#18233F]">
            {typeArray.map((type, idx) => (
              <Listbox.Option
                key={`type-${idx}`}
                className={({ active }) =>
                  `relative flex items-center cursor-pointer select-none py-2.5 sm:py-3 2xl:py-4 px-4 2xl:px-5 duration-75 ease-out ${
                    active ? 'bg-ploonetPurple/80 text-white' : 'text-[#d5d5d5]'
                  }`
                }
                value={type}>
                <span
                  className={`block truncate max-md:text-sm ${
                    type.name === form.type ? 'font-bold' : 'font-normal'
                  }`}>
                  {t(type.name)}
                </span>
                {type.name === form.type && (
                  <span className="flex items-center text-primary ml-3">
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
