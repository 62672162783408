export default function NewProjectIcon() {
  return (
    <svg
      className="w-10 h-10"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="21.4089" cy="21" r="21" fill="#E9FAFF" />
      <circle cx="21.4089" cy="21" r="8" fill="#02A3D3" />
      <path
        d="M21.0818 17.4844H21.6635C21.7152 17.4844 21.7411 17.5104 21.7411 17.5625V24.4375C21.7411 24.4896 21.7152 24.5156 21.6635 24.5156H21.0818C21.0301 24.5156 21.0043 24.4896 21.0043 24.4375V17.5625C21.0043 17.5104 21.0301 17.4844 21.0818 17.4844Z"
        fill="#E9FAFF"
      />
      <path
        d="M18.1153 20.6289H24.6302C24.6819 20.6289 24.7078 20.6549 24.7078 20.707V21.293C24.7078 21.3451 24.6819 21.3711 24.6302 21.3711H18.1153C18.0636 21.3711 18.0377 21.3451 18.0377 21.293V20.707C18.0377 20.6549 18.0636 20.6289 18.1153 20.6289Z"
        fill="#E9FAFF"
      />
    </svg>
  );
}
