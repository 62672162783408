import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import i18n from '../../locale/i18n';
import APIService from '../../networks/APIService';
import { getMyLoginInfo } from '../../networks/member.service';
import {
  isSignedAtom,
  loginInfoAtom,
  userInfoAtom,
} from '../../networks/store/member';
import { lang } from '../../recoil/atoms/user';
import ModalProvider from '../modals/modal-provider';

export default function TotalLayout() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isSignedAtom);
  const { userToken } = useRecoilValue(loginInfoAtom);
  const [language, setLanguage] = useRecoilState(lang);
  const setUserInfo = useSetRecoilState(userInfoAtom);

  useEffect(() => {
    i18n.changeLanguage(language);
    if (!userToken) {
      setIsLoggedIn(false);
      return;
    }

    APIService.setToken(userToken);
    getMyLoginInfo().then(setUserInfo).catch(console.error);
  }, [isLoggedIn, userToken]);

  useEffect(() => {
    setDefaultLanguageValue();

    function setDefaultLanguageValue() {
      setTimeout(() => {
        const { pathname } = window.location;
        const isInEnglish = pathname.includes('en');
        if (isInEnglish) {
          setLanguage('en');
          i18n.changeLanguage('en');
        } else {
          setLanguage('ko');
          i18n.changeLanguage('ko');
        }
      }, 10);
    }
  }, []);

  return (
    <>
      <ModalProvider />
      <Outlet />
    </>
  );
}
