type Props = {
  className?: string;
};

export default function UncheckedBox({ className }: Props) {
  return (
    <svg
      className={className || 'w-5 h-5'}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="1" width="13" height="13" rx="2.5" stroke="#9C9C9C" />
    </svg>
  );
}
