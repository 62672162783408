import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConsoleHomeBanner from '../../components/console/home/console-home-banner';
import ProjectsTable from '../../components/console/my-projects/projects-table';
import SearchFilter from '../../components/console/my-projects/search-filter';
import {
  doGetProjectList,
  getProjectList,
} from '../../networks/project.service';
import { ProjectInfoType } from '../../types_new/console/resource/types';
import { ProjectReqType } from '../../types_new/console/service/types';
import { formatDate } from '../../utils/common';

export default function ConsoleProjectsPage() {
  const [params, setParams] = useState<ProjectReqType>({
    status: '',
    paged: 1,
    postsPerPage: 5,
    order: 'DESC',
  });
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [projects, setProjects] = useState<ProjectInfoType[]>([]);
  const [total, setTotal] = useState(0);
  const bottomBoundaryRef = useRef<HTMLDivElement>(null);
  const [checkEncoding, setCheckEncoding] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setCheckEncoding(
      !!projects?.find(project => project.producing === 'proceed')
    );
  }, [projects]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!checkEncoding) return;
      getProjectList({
        order: params.order,
        status: '',
      })
        .then(({ data }) => {
          const projects = data.body.items;
          const isEncodingProjectExist = !!projects?.find(
            (project: ProjectInfoType) => project.producing === 'proceed'
          );
          if (!isEncodingProjectExist) setProjects(projects);
        })
        .catch(console.error);
    }, 10000);

    return () => clearInterval(interval);
  }, [checkEncoding]);

  useEffect(() => {
    const handleObserver = (entries: any) => {
      const target = entries[0];
      if (target.isIntersecting) {
        if (loading) return;

        setParams(prev => ({
          ...prev,
          paged: prev.paged + 1,
        }));
      }
    };

    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    });

    if (bottomBoundaryRef.current) observer.observe(bottomBoundaryRef.current);

    return () => observer.disconnect();
  }, [bottomBoundaryRef, loading]);

  useEffect(() => {
    if (projects.length && total === projects.length) {
      return;
    }
    setLoadingMore(true);

    getProjectList(params)
      .then(res => {
        if (res.status === 200) {
          const projects = res.data.body.items;
          const total = res?.data.body.total;
          if (Array.isArray(projects)) {
            setTotal(total);
            setProjects(prev => [...prev, ...projects]);
          }
        }
      })
      .catch(console.log)
      .finally(() => {
        if (loading) setLoading(false);
        setLoadingMore(false);
      });
  }, [params.paged]);

  useEffect(() => {
    setProjects([]);
    setParams(prev => ({
      ...prev,
      paged: 1,
    }));
  }, [params.status, params.order]);

  const filteredProjects = useMemo(() => {
    const sortedProjects = [...projects].sort((a, b) => {
      const dateA = formatDate(a.registerDate);
      const dateB = formatDate(b.registerDate);
      return params.order === 'ASC' ? dateA - dateB : dateB - dateA;
    });

    return params.status
      ? sortedProjects.filter(project => project.status === params.status)
      : sortedProjects;
  }, [params.order, params.status, projects]);

  const updateDuplicatedProject = async () => {
    await doGetProjectList({
      status: '',
      paged: 1,
      postsPerPage: 5,
      order: 'DESC',
    }).then(({ projects }) => {
      const duplicatedProject = projects[0];
      setProjects(prev => [duplicatedProject, ...prev]);
    });
  };

  return (
    <section className="w-full">
      <ConsoleHomeBanner />
      <h4 className="text-xl 2xl:text-2xl mt-14">{t('내 프로젝트')}</h4>
      <SearchFilter params={params} setParams={setParams} loading={loading} />
      <ProjectsTable
        updateDuplicatedProject={updateDuplicatedProject}
        setProjects={setProjects}
        projects={filteredProjects}
        loading={loading}
        loadingMore={loadingMore}
        bottomBoundaryRef={bottomBoundaryRef}
      />
    </section>
  );
}
