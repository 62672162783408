import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { isSignedAtom } from '../../../networks/store/member';
import { newProjectModal } from '../../../recoil/atoms/store';
import NewPlusIcon from '../../svg/new-plus-icon';
import ConsoleSideBar from '../core/console-side-bar';
import ProjectModal from '../modal/project-modal';

export default function ConsoleLayout() {
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const navigate = useNavigate();
  const [projectModal, setProjectModal] = useRecoilState(newProjectModal);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoggedIn) navigate('/login');
  }, [isLoggedIn]);

  return (
    <>
      <ProjectModal
        isOpen={projectModal}
        closeModal={() => setProjectModal(false)}
      />
      <main className="min-w-[720px] w-full flex h-full bg-white text-consoleDark">
        <ConsoleSideBar />
        <div className="max-w-screen-2xl w-full h-full mx-auto flex flex-col space-y-6 grow py-5 2xl:py-7 px-5 lg:px-8 xl:px-10 2xl:px-[50px]">
          <div className="flex justify-end">
            <button
              onClick={() => setProjectModal(true)}
              className="flex items-center space-x-1 rounded-[10px] bg-[#44505F] px-4 py-2.5 hover:bg-opacity-90 duration-150">
              <NewPlusIcon />
              <span className="text-white text-[13px]">
                {t('새 프로젝트 만들기')}
              </span>
            </button>
          </div>
          <Outlet />
        </div>
      </main>
    </>
  );
}
