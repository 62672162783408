/* eslint-disable */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SetterOrUpdater, useRecoilState, useSetRecoilState } from 'recoil';
import '../../css/reset.css';
import '../../css/style.css';
import useEntireDuration from '../../hooks/use-entire-duration';
import '../../locale/i18n';
import { block } from '../../store/block';
import {
  centiSecond,
  markerPos,
  setSegmentsInfo,
  cutBgAudio as sotredCutBgAudio,
  cutBgVideo as sotredCutBgVideo,
  pages as sotredPages,
  cutAudioItem as storedCutAudioItem,
  cutBgColorImage as storedCutBgColorImage,
  cutImage as storedCutImageItem,
  cutTitle as storedCutTitleItem,
  cutvideoItem as storedCutVideoItem,
  segmentsItem as storedItem,
  ratio as storedRatio,
  timelinePercentage,
} from '../../store/pages';
import {
  IBackground,
  IImage,
  IPages,
  IPreviewElement,
  ISegment,
  ITitle,
} from '../../store/pages/types';
import ToolTip from '../common/tool-tip';
import BinIcon from '../svg/bin-icon';
import CopyIcon from '../svg/copy-icon';
import CutIcon from '../svg/cut-icon';
import { filterMedias, updateRecoilState } from './Strip';
import UndoRedoButtons from './undo-redo-buttons';

const BUTTON_STYLE = 'w-5 h-5 group disabled:cursor-not-allowed';

const getSliderStyle = (percentage: number) =>
  `linear-gradient(to right, #B635F5 0%, #6DD1F0 ${percentage}%, #E9E9E9 ${percentage}%, #E9E9E9 100%)`;

const Toolbar = () => {
  const entireDuration = useEntireDuration();
  const { t } = useTranslation();
  const [pages, setPages] = useRecoilState(sotredPages);
  const [segmentItem, setSegmentItem] = useRecoilState<any>(storedItem);
  const [cutAudioItem, setCutAudioItem] = useRecoilState(storedCutAudioItem);
  const [cutVideoItem, setCutVideoItem] = useRecoilState(storedCutVideoItem);
  const [cutBgVideoItem, setCutBgVideoItem] = useRecoilState(sotredCutBgVideo);
  const [cutBgAudioItem, setCutBgAudioItem] = useRecoilState(sotredCutBgAudio);
  const [cutTitleItem, setCutTitleItem] = useRecoilState(storedCutTitleItem);
  const setMarkerX = useSetRecoilState(markerPos);
  const [cutImageItem, setCutImageItem] = useRecoilState(storedCutImageItem);
  const [cutBgColorImageItem, setCutBgColorImageItem] = useRecoilState(
    storedCutBgColorImage
  );
  const [cs, setCs] = useRecoilState(centiSecond);
  const [ratio, setRatio] = useRecoilState(storedRatio);
  const [initialRatio, setInitialRatio] = useState(3);
  const [percentage, setPercentage] = useRecoilState(timelinePercentage);
  const [selectedBlock, setSelectedBlock] = useRecoilState(block);
  const segments: ISegment[] = pages[0]?.segments[0]?.segment;
  const backgroundSegments: ISegment[] = pages[0]?.segments[0]?.background;
  const imageSegments: ISegment[] = pages[0]?.segments[0]?.image;
  const titleSegments: ISegment[] = pages[0]?.segments[0]?.title;

  const rawMedias: IPreviewElement[] = filterMedias(
    segmentItem.type,
    segments,
    imageSegments,
    titleSegments,
    backgroundSegments
  );

  useEffect(
    () => setRatio((initialRatio * 21) / 10 - (percentage * initialRatio) / 50),
    [percentage]
  );

  useEffect(() => {
    if (initialRatio > 0) return;
    if (ratio > 0) setInitialRatio(ratio);
  }, [ratio]);

  // TODO: remove as group only when meta item is clicked
  const removTimelineEmpty = page => {
    if (page) {
      const tmpPage = page;

      if (segmentItem.type === 'metahuman') {
        const { segment, background, image } = pages[0].segments[0];
        const targetMH = segment?.filter(
          item => item.segmentId === Number(segmentItem.id)
        )[0];

        const targetBG = background.filter(
          item => item?.metaData?.pptId === targetMH?.metaData?.pptId
        );

        if (targetMH?.metaData?.pptId && targetBG.length === 0) {
          tmpPage[0]?.segments[0]?.segment?.map(item => {
            if (
              item?.insertTime > targetMH.insertTime &&
              item.railIndex === 0
            ) {
              item.insertTime = item?.insertTime - targetMH?.duration;
            }

            return item;
          });
          tmpPage[0]?.segments[0]?.background?.map(item => {
            if (
              item?.insertTime > targetMH.insertTime &&
              item.railIndex === 0
            ) {
              item.insertTime = item?.insertTime - targetMH?.duration;
            }

            return item;
          });
        }
        // FIXME: ??
        setPages(tmpPage);
      } else if (segmentItem.type === 'bgColorImage') {
        const { segment, background } = pages[0].segments[0];
        const targetBG = background?.filter(
          item => Number(item.backgroundId) === Number(segmentItem.id)
        )[0];
        const targetMH = segment?.filter(
          item => item?.metaData?.pptId === targetBG?.metaData?.pptId
        );

        if (targetBG?.metaData?.pptId && targetMH.length === 0) {
          tmpPage[0]?.segments[0]?.segment?.map(item => {
            if (
              item?.insertTime > targetBG?.metaData?.segmentIsertT &&
              item.railIndex === 0
            ) {
              item.insertTime =
                item?.insertTime - targetBG?.metaData?.segmentDuration;
            }
            return item;
          });
          tmpPage[0]?.segments[0]?.background?.map(item => {
            if (
              item?.insertTime > targetBG?.metaData?.segmentIsertT &&
              item.railIndex === 0
            ) {
              item.insertTime =
                item?.insertTime - targetBG?.metaData?.segmentDuration;
            }
            return item;
          });
        }
        // FIXME: ??
        setPages(tmpPage);
      }
    }
  };

  useEffect(() => {
    let markerPosition;
    for (let i = 0; i < 101; i++) {
      switch (percentage) {
        case i:
          markerPosition = cs / (6.3 - i * 0.06);
      }
    }
    setMarkerX(markerPosition);
  }, [percentage]);

  return (
    <div className="studio__tool">
      <div className="flex space-x-4 items-center">
        <UndoRedoButtons />
        <ToolTip title={t('잘라내기')}>
          <button
            disabled={!selectedBlock.segmentsGroup}
            className={BUTTON_STYLE}
            onClick={() => {
              cutItem(
                cutAudioItem,
                cutVideoItem,
                cutBgVideoItem,
                cutBgAudioItem,
                cutTitleItem,
                cutImageItem,
                cutBgColorImageItem,
                pages,
                setPages,
                cs,
                segmentItem
              );
            }}>
            <CutIcon />
          </button>
        </ToolTip>
        <ToolTip title={t('삭제')}>
          <button
            disabled={!selectedBlock.segmentsGroup}
            className={BUTTON_STYLE}
            onClick={() => {
              const notDeleteItem = rawMedias.filter(
                val => val.id !== segmentItem.id
              );
              const { segment } = pages[0]?.segments[0];
              const targetMH = segment?.filter(
                item => item?.metaData?.pptId === segmentItem?.id
              )[0];

              setSelectedBlock({});
              if (targetMH?.metaData?.pptId) {
                if (
                  confirm(
                    '메타휴먼 변경 시 하위의 ppt 및 pdf 배경 블록의 길이만 맞춰집니다.'
                  )
                ) {
                  removTimelineEmpty(
                    updateRecoilState(
                      pages,
                      setPages,
                      segmentItem.type,
                      notDeleteItem,
                      segmentItem.id
                    )
                  );
                }
              } else {
                removTimelineEmpty(
                  updateRecoilState(
                    pages,
                    setPages,
                    segmentItem.type,
                    notDeleteItem,
                    segmentItem.id
                  )
                );
              }
            }}>
            <BinIcon />
          </button>
        </ToolTip>
        <ToolTip title={t('복사')}>
          <button
            disabled={!selectedBlock.segmentsGroup}
            className={BUTTON_STYLE}
            onClick={() => {
              updateCopy(segmentItem, pages, setPages, setSegmentItem);
            }}>
            <CopyIcon />
          </button>
        </ToolTip>
      </div>

      <div className="tool__time__box">
        <div className="tool__time">
          <span>{timeCal(cs)}</span>
        </div>
        <p>/</p>
        <div className="tool__time">
          <span>{timeCal(entireDuration)}</span>
        </div>
      </div>

      <div className="flex col alignCenter">
        <div className="scale__box">
          <button
            className="zoomOut__btn timeline__zoomOut__icon"
            onClick={() => {
              setPercentage(pre => (pre > 0 ? pre - 5 : 0));
            }}
          />

          <input
            type="range"
            value={percentage}
            min={0}
            max={80}
            onChange={e => {
              setPercentage(Number(e.target.value));
            }}
            style={{
              background: getSliderStyle(percentage),
            }}
          />

          <button
            className="zoomIn__btn timeline__zoomIn__icon"
            onClick={() => {
              setPercentage(pre => (pre < 80 ? pre + 5 : 80));
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Toolbar;

type StripType =
  | 'metahuman'
  | 'video'
  | 'audio'
  | 'title'
  | 'image'
  | 'bgColorImage'
  | 'bgVideo'
  | 'bgMusic'
  | 'bgaudio';

function updateCopy(
  segmentItem: IPreviewElement,
  pages: IPages[],
  setPages: SetterOrUpdater<IPages[]>,
  setSegmentItem
) {
  const { segment, title, background, image } = pages[0].segments[0];
  if (segmentItem.type === 'image') {
    const imageLength = pages[0].segments[0].image.length;
    const image = [
      {
        ...pages[0].segments[0].image.find(
          image => image.imageId === segmentItem.id
        ),
      },
    ][0];
    const insert = pages[0].segments[0].image;
    const insertT = Math.max(
      ...insert.map(val => val.insertTime + val.duration),
      0
    );
    image.insertTime = insertT;
    image.imageId = Date.now();
    const type = {
      pagesIndex: 0,
      image: {
        imageIndex: imageLength,
        image,
      },
    };
    setSegmentsInfo(pages, setPages, type);
    setSegmentItem({});
  }

  if (segmentItem.type === 'title') {
    const titleLength = pages[0].segments[0].title.length;
    const title = [
      {
        ...pages[0].segments[0].title.find(
          title => title.titleId === segmentItem.id
        ),
      },
    ][0];
    title.titleId = Date.now();
    const insert = pages[0].segments[0].title;
    const insertT = Math.max(
      ...insert.map(val => val.insertTime + val.duration),
      0
    );
    title.insertTime = insertT;
    const type = {
      pagesIndex: 0,
      title: {
        titleIndex: titleLength,
        title,
      },
    };
    setSegmentsInfo(pages, setPages, type);
    setSegmentItem({});
  }

  if (['bgColorImage', 'bgVideo', 'bgMusic'].includes(segmentItem.type)) {
    const backgroundLength = pages[0].segments[0].background.length;
    const background = [
      {
        ...pages[0].segments[0].background.find(
          bg => bg.backgroundId === segmentItem.id
        ),
      },
    ][0];
    if (segmentItem.type === 'bgColorImage') {
      const insert = pages[0].segments[0].background.filter(
        val => val.type === 'image' || val.type === 'color'
      );
      const insertT = Math.max(
        ...insert.map(val => val.insertTime + val.duration),
        0
      );
      background.insertTime = insertT;
    } else if (segmentItem.type === 'bgVideo') {
      const insert = pages[0].segments[0].background.filter(
        val => val.type === 'video'
      );
      const insertT = Math.max(
        ...insert.map(val => val.insertTime + val.duration),
        0
      );
      background.insertTime = insertT;
    } else if (segmentItem.type === 'bgMusic') {
      const insert = pages[0].segments[0].background.filter(
        val => val.type === 'audio'
      );
      const insertT = Math.max(
        ...insert.map(val => val.insertTime + val.duration),
        0
      );
      background.insertTime = insertT;
    }
    background.backgroundId = Date.now();
    const type = {
      pagesIndex: 0,
      background: {
        backgroundIndex: backgroundLength,
        background,
      },
    };
    setSegmentsInfo(pages, setPages, type);
    setSegmentItem({});
  }

  if (['video', 'audio', 'metahuman'].includes(segmentItem.type)) {
    const segmentLength = pages[0].segments[0].segment.length;
    const segment = [
      {
        ...pages[0].segments[0].segment.find(
          sg => sg.segmentId === segmentItem.id
        ),
      },
    ][0];

    // gets copied even this part is erased. its only for
    // defining insertTime when clicked copy button
    if (segmentItem.type === 'metahuman') {
      const insert = pages[0].segments[0].segment.filter(
        val => val.mediaType === 'metahuman'
      );
      const insertT = Math.max(
        ...insert.map(val => val.insertTime + val.duration),
        0
      );
      segment.insertTime = insertT;
    } else if (segmentItem.type === 'video') {
      const insert = pages[0].segments[0].segment.filter(
        val => val.mediaType === 'video'
      );
      const insertT = Math.max(
        ...insert.map(val => val.insertTime + val.duration),
        0
      );
      segment.insertTime = insertT;
    } else if (segmentItem.type === 'audio') {
      const insert = pages[0].segments[0].segment.filter(
        val => val.mediaType === 'audio'
      );
      const insertT = Math.max(
        ...insert.map(val => val.insertTime + val.duration),
        0
      );
      segment.insertTime = insertT;
    }
    segment.segmentId = Date.now();
    const type = {
      pagesIndex: 0,
      segment: {
        segmentIndex: segmentLength,
        segment,
      },
    };

    setSegmentsInfo(pages, setPages, type);
    setSegmentItem({});
  }
}

function cutItem(
  cutAudioItem: ISegment[],
  cutVideoItem: ISegment[],
  cutBgVideoItem: IBackground[],
  cutBgAudioItem: ISegment[],
  cutTitleItem: ITitle[],
  cutImageItem: IImage[],
  cutBgColorImageItem: IBackground[],
  pages: IPages[],
  setPages: SetterOrUpdater<IPages[]>,
  cs: number,
  segmentItem: IPreviewElement
) {
  if (cs === 0) return;
  if (segmentItem.type === 'video') {
    const index = cutVideoItem.findIndex(
      item => item.segmentId === segmentItem.id
    );
    if (index !== -1) {
      const originalItem = {
        ...cutVideoItem.find(seg => seg.segmentId === segmentItem.id),
      };
      const copyItem = {
        ...cutVideoItem.find(seg => seg.segmentId === segmentItem.id),
      };
      const copyDuration = originalItem.duration;
      copyItem.segmentId = Date.now();
      originalItem.duration = cs - originalItem.insertTime;
      // originalItem.endTime = cs - originalItem.insertTime;
      const newDuration = copyDuration - originalItem.duration;
      copyItem.insertTime = originalItem.insertTime + originalItem.duration;
      copyItem.duration = newDuration;
      copyItem.startTime = Math.floor(cs - originalItem.insertTime);
      // endtime 설정 추가
      copyItem.endTime = copyItem.insertTime + copyItem.duration;
      const newArr = [...pages[0]?.segments[0]?.segment];
      const index = [...pages[0]?.segments[0]?.segment].findIndex(
        item => item.segmentId === originalItem.segmentId
      );

      newArr.splice(index, 1, { ...originalItem }, { ...copyItem });

      const copyType = {
        pagesIndex: 0,
        segment: {
          segmentArray: newArr,
        },
      };
      setSegmentsInfo(pages, setPages, copyType);
    }
  }

  if (segmentItem.type === 'audio') {
    const index = cutAudioItem.findIndex(
      item => item.segmentId === segmentItem.id
    );

    if (index !== -1) {
      const originalItem = {
        ...cutAudioItem.find(seg => seg.segmentId === segmentItem.id),
      };
      const copyItem = {
        ...cutAudioItem.find(seg => seg.segmentId === segmentItem.id),
      };
      const copyDuration = originalItem.duration;
      copyItem.segmentId = Date.now();
      originalItem.duration = cs - originalItem.insertTime;
      // originalItem.endTime = cs - originalItem.insertTime;
      const newDuration = copyDuration - originalItem.duration;
      copyItem.insertTime = originalItem.insertTime + originalItem.duration;
      copyItem.duration = newDuration;
      copyItem.startTime = Math.floor(cs - originalItem.insertTime);
      // endtime 설정 추가
      copyItem.endTime = copyItem.insertTime + copyItem.duration;

      const newArr = [...pages[0]?.segments[0]?.segment];
      const index = [...pages[0]?.segments[0]?.segment].findIndex(
        item => item.segmentId === originalItem.segmentId
      );

      newArr.splice(index, 1, { ...originalItem }, { ...copyItem });
      const copyType = {
        pagesIndex: 0,
        segment: {
          segmentArray: newArr,
        },
      };
      setSegmentsInfo(pages, setPages, copyType);
    }
  }

  if (segmentItem.type === 'bgaudio') {
    const index = cutBgAudioItem.findIndex(
      item => item.segmentId === segmentItem.id
    );
    if (index !== -1) {
      const originalItem = {
        ...cutBgAudioItem.find(bg => bg.segmentId === segmentItem.id),
      };
      const copyItem = {
        ...cutBgAudioItem.find(bg => bg.segmentId === segmentItem.id),
      };
      const copyDuration = originalItem.duration;
      copyItem.segmentId = Date.now();
      originalItem.duration = cs - originalItem.insertTime;
      // originalItem.endTime = cs - originalItem.insertTime;
      const newDuration = copyDuration - originalItem.duration;
      copyItem.insertTime = originalItem.insertTime + originalItem.duration;
      copyItem.startTime = Math.floor(cs - originalItem.insertTime);
      copyItem.duration = newDuration;
      // endtime 설정 추가
      copyItem.endTime = copyItem.insertTime + copyItem.duration;

      const newArr = [...pages[0]?.segments[0]?.segment];
      const index = [...pages[0]?.segments[0]?.segment].findIndex(
        item => item.segmentId === originalItem.segmentId
      );

      newArr.splice(index, 1, { ...originalItem }, { ...copyItem });
      const copyType = {
        pagesIndex: 0,
        segment: {
          segmentArray: newArr,
        },
      };
      setSegmentsInfo(pages, setPages, copyType);
    }
  }

  if (segmentItem.type === 'bgVideo') {
    const index = cutBgVideoItem.findIndex(
      item => item.backgroundId === segmentItem.id
    );
    if (index !== -1) {
      const originalItem = {
        ...cutBgVideoItem.find(bg => bg.backgroundId === segmentItem.id),
      };
      const copyItem = {
        ...cutBgVideoItem.find(bg => bg.backgroundId === segmentItem.id),
      };
      const copyDuration = originalItem.duration;
      copyItem.backgroundId = Date.now();
      originalItem.duration = cs - originalItem.insertTime;
      // originalItem.endTime = cs - originalItem.insertTime;
      const newDuration = copyDuration - originalItem.duration;
      copyItem.insertTime = originalItem.insertTime + originalItem.duration;
      copyItem.duration = newDuration;
      copyItem.startTime = Math.floor(cs - originalItem.insertTime);
      // endtime 설정 추가
      copyItem.endTime = copyItem.insertTime + copyItem.duration;

      const newArr = [...pages[0]?.segments[0]?.background];
      const index = [...pages[0]?.segments[0]?.background].findIndex(
        item => item.backgroundId === originalItem.backgroundId
      );

      newArr.splice(index, 1, { ...originalItem }, { ...copyItem });
      const copyType = {
        pagesIndex: 0,
        background: {
          backgroundArray: newArr,
        },
      };
      setSegmentsInfo(pages, setPages, copyType);
    }
  }

  if (segmentItem.type === 'title') {
    const index = cutTitleItem.findIndex(
      item => item.titleId === segmentItem.id
    );
    if (index !== -1) {
      const originalItem = {
        ...cutTitleItem.find(bg => bg.titleId === segmentItem.id),
      };
      const copyItem = {
        ...cutTitleItem.find(bg => bg.titleId === segmentItem.id),
      };
      const copyDuration = originalItem.duration;
      copyItem.titleId = Date.now();
      originalItem.duration = cs - originalItem.insertTime;
      const newDuration = copyDuration - originalItem.duration;
      copyItem.insertTime = originalItem.insertTime + originalItem.duration;
      copyItem.duration = newDuration;

      const newArr = [...pages[0]?.segments[0]?.title];
      const index = [...pages[0]?.segments[0]?.title].findIndex(
        item => item.titleId === originalItem.titleId
      );

      newArr.splice(index, 1, { ...originalItem }, { ...copyItem });
      const copyType = {
        pagesIndex: 0,
        title: {
          titleArray: newArr,
        },
      };
      setSegmentsInfo(pages, setPages, copyType);
    }
  }

  if (segmentItem.type === 'image') {
    const index = cutImageItem.findIndex(
      item => item.imageId === segmentItem.id
    );
    if (index !== -1) {
      const originalItem = {
        ...pages[0].segments[0].image.find(
          img => img.imageId === segmentItem.id
        ),
      };
      const copyItem = {
        ...pages[0].segments[0].image.find(
          img => img.imageId === segmentItem.id
        ),
      };
      const copyDuration = originalItem.duration;
      copyItem.imageId = Date.now();
      originalItem.duration = cs - originalItem.insertTime;
      const newDuration = copyDuration - originalItem.duration;
      copyItem.insertTime = originalItem.insertTime + originalItem.duration;
      copyItem.duration = newDuration;
      // endtime 설정 추가
      copyItem.endTime = copyItem.insertTime + copyItem.duration;

      const newArr = [...pages[0]?.segments[0]?.image];
      const index = [...pages[0]?.segments[0]?.image].findIndex(
        item => item.imageId === originalItem.imageId
      );

      newArr.splice(index, 1, { ...originalItem }, { ...copyItem });
      const copyType = {
        pagesIndex: 0,
        image: {
          imageArray: newArr,
        },
      };
      setSegmentsInfo(pages, setPages, copyType);
    }
  }
  if (segmentItem.type === 'bgColorImage') {
    const index = cutBgColorImageItem.findIndex(
      item => item.backgroundId === segmentItem.id
    );
    if (index !== -1) {
      const originalItem = {
        ...cutBgColorImageItem.find(bg => bg.backgroundId === segmentItem.id),
      };
      const copyItem = {
        ...cutBgColorImageItem.find(bg => bg.backgroundId === segmentItem.id),
      };
      const copyDuration = originalItem.duration;
      copyItem.backgroundId = Date.now();
      originalItem.duration = cs - originalItem.insertTime;
      const newDuration = copyDuration - originalItem.duration;
      copyItem.insertTime = originalItem.insertTime + originalItem.duration;
      copyItem.duration = newDuration;
      // endtime 설정 추가
      copyItem.endTime = copyItem.insertTime + copyItem.duration;

      const newArr = [...pages[0]?.segments[0]?.background];
      const index = [...pages[0]?.segments[0]?.background].findIndex(
        item => item.backgroundId === originalItem.backgroundId
      );

      newArr.splice(index, 1, { ...originalItem }, { ...copyItem });
      const copyType = {
        pagesIndex: 0,
        background: {
          backgroundArray: newArr,
        },
      };

      setSegmentsInfo(pages, setPages, copyType);
    }
  }
}

function timeCal(cs: number) {
  const minute = ((cs || 0) / (100 * 60)) % 60;
  const second = ((cs || 0) / 100) % 60;
  const t = String(Math.ceil(cs || 0));
  const minuteCut = `0${Math.floor(minute)}`.slice(-2);
  const secondCut = `0${Math.floor(second)}`.slice(-2);
  return `${minuteCut}:${secondCut}.${t.padStart(2, '0').slice(-2)}`;
}
