type Props = {
  isProjectPage?: boolean;
};

export default function ProjectIcon({ isProjectPage }: Props) {
  return (
    <svg
      className="w-6 h-6"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`${
          isProjectPage && 'fill-white group-hover:fill-slate-300 duration-150'
        }`}
        d="M12 2V8C12 8.53043 12.2107 9.03914 12.5858 9.41421C12.9609 9.78929 13.4696 10 14 10H20V20C20 20.5304 19.7893 21.0391 19.4142 21.4142C19.0391 21.7893 18.5304 22 18 22H6C5.46957 22 4.96086 21.7893 4.58579 21.4142C4.21071 21.0391 4 20.5304 4 20V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2H12ZM13.5 2.5V8C13.5 8.13261 13.5527 8.25979 13.6464 8.35355C13.7402 8.44732 13.8674 8.5 14 8.5H19.5L13.5 2.5Z"
        fill="#44505F"
      />
    </svg>
  );
}
