import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const lang = atom({
  key: 'userlang',
  default: process.env.REACT_APP_LANGUAGE,
  effects_UNSTABLE: [persistAtom],
});
