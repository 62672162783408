import { PostType } from '../../../types_new/main/resource/types';
import FAQCard from './faq-card';

type Props = {
  filteredList: PostType[];
  paged: number;
};
export default function FAQSection({ filteredList, paged }: Props) {
  const result = filteredList.slice((paged - 1) * 10, paged * 10);

  return (
    <ul className="flex flex-col space-y-1 md:space-y-2 xl:space-y-3 mt-5 sm:mt-7 lg:mt-10 xl:mt-12 2xl:mt-16">
      {result.map((faq, idx) => (
        <li className="sm:hover:shadow-md duration-300" key={`faq-${idx}`}>
          <FAQCard faq={faq} />
        </li>
      ))}
    </ul>
  );
}
