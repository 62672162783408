/* eslint-disable */
import '../../css/reset.css';
import '../../css/style.css';

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import '../../locale/i18n';
import { block } from '../../store/block';
import { pages, selectTransition, setSegmentsInfo } from '../../store/pages';

const TransformModal = ({ active, setActive }: any) => {
  const { t } = useTranslation();
  // const [blocks, setBlocks] = useRecoilState(block);
  const [page, setPage] = useRecoilState(pages) as any;
  const [buttonActive, setButtonActive] = useState({
    value: '',
  });
  const [transition, setTransition] = useRecoilState(selectTransition);

  useEffect(() => {
    if (transition.type === 'metahuman') {
      const element = {
        ...page[0].segments[0].segment.find(
          seg => seg.segmentId === transition.id
        ),
      };
      setActive(5);
      setButtonActive({ value: element.transitionType });
    }
  }, [transition]);

  const changeTransform = (code: string) => {
    const element = {
      ...page[0].segments[0].segment.find(
        seg => seg.segmentId === transition.id
      ),
    };
    const index = [...page[0]?.segments[0]?.segment].findIndex(
      e => e.segmentId === transition.id
    );

    if (code === element.transitionType) element.transitionType = '';
    else element.transitionType = code;
    const type = {
      pagesIndex: 0,
      segment: {
        segmentIndex: index,
        segment: element,
      },
    };
    setSegmentsInfo(page, setPage, type);
  };

  const transformList = [
    { korean: t('페이드'), code: 'fade' },
    { korean: t('블랙으로 페이드'), code: 'fadeblack' },
    { korean: t('화이트로 페이드'), code: 'fadewhite' },
    { korean: t('서서히 전환'), code: 'distance' },
    { korean: t('왼쪽으로 지우기'), code: 'wipeleft' },
    { korean: t('오른쪽으로 지우기'), code: 'wiperight' },
    { korean: t('위로 지우기'), code: 'wipeup' },
    { korean: t('아래로 지우기'), code: 'wipedown' },
    { korean: t('왼쪽으로 밀기'), code: 'slideleft' },
    { korean: t('오른쪽으로 밀기'), code: 'slideright' },
    { korean: t('위로 밀기'), code: 'slideup' },
    { korean: t('아래로 밀기'), code: 'slidedown' },
    { korean: `${t('왼쪽으로 지우기')} (smooth)`, code: 'smoothleft' },
    { korean: `${t('오른쪽으로 지우기')} (smooth)`, code: 'smoothright' },
    { korean: `${t('위로 지우기')} (smooth)`, code: 'smoothup' },
    { korean: `${t('아래로 지우기')} (smooth)`, code: 'smoothdown' },
    { korean: t('사각 자르기'), code: 'rectcrop' },
    { korean: t('원형 자르기'), code: 'circlecrop' },
    { korean: t('원형 닫기'), code: 'circleclose' },
    { korean: t('원형 열기'), code: 'circleopen' },
    { korean: t('수평 닫기'), code: 'horzclose' },
    { korean: t('수평 열기'), code: 'horzopen' },
    { korean: t('수직 닫기'), code: 'vertclose' },
    { korean: t('수직 열기'), code: 'vertopen' },
    { korean: t('왼쪽/아래로 서서히 지우기'), code: 'diagbl' },
    { korean: t('오른쪽/아래로 서서히 지우기'), code: 'diagbr ' },
    { korean: t('왼쪽/위로 서서히 지우기'), code: 'diagtl' },
    { korean: t('오른쪽/위로 서서히 지우기'), code: 'diagtr' },
    { korean: t('왼쪽 세로 조각 전환'), code: 'hlslice' },
    { korean: t('오른쪽 세로 조각 전환'), code: 'hrslice' },
    { korean: t('위로 가로 조각 전환'), code: 'vuslice' },
    { korean: t('아래로 가로 조각 전환'), code: 'vdslice' },
    { korean: t('디졸브'), code: 'dissolve' },
    { korean: t('타일'), code: 'pixelize' },
    { korean: t('회전'), code: 'radial' },
    { korean: t('크로스 블러'), code: 'hblur' },
    { korean: t('왼쪽/위로 지우기'), code: 'wipetl' },
    { korean: t('오른쪽/위로 지우기'), code: 'wipetr' },
    { korean: t('왼쪽/아래로 지우기'), code: 'wipebl' },
    { korean: t('오른쪽/아래로 지우기'), code: 'wipebr' },
    { korean: t('회색으로 페이드'), code: 'fadegrays' },
  ];

  return (
    <li>
      {active === 5 && (
        <>
          <button
            className={active === 5 ? 'active' : undefined}
            // onClick={() => {
            //   setActive(5);
            // }}
          >
            <i className="style__transition__icon" />
            <span>{t('전환')}</span>
          </button>
          <div className="transition h-full max-h-[600px] overflow-y-auto overflow-x-hidden">
            <div className="title">
              <h2>{t('장면 전환효과')}</h2>
              <button
                className="close__btn close__gray__icon"
                onClick={() => {
                  setActive(0);
                }}
              />
            </div>
            <div className="content">
              <div className="transition__content show">
                <div className="transition__list list default show">
                  <ul>
                    {transformList.map((item: any, idx: number) => (
                      <li key={idx}>
                        <button
                          className={
                            buttonActive.value === item.code
                              ? 'active'
                              : undefined
                          }
                          onClick={() => {
                            setButtonActive({
                              value:
                                buttonActive.value === item.code
                                  ? ''
                                  : item.code,
                            });
                            changeTransform(item.code);
                          }}>
                          <div className="text_wrap">
                            <p className="img__text">{item.korean}</p>
                          </div>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </li>
  );
};

export default TransformModal;
