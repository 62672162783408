import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { guidePhraseArray } from '../../../arrays';
import { isSignedAtom } from '../../../networks/store/member';
import { getMainDir } from '../../../util/common';
import RightArrowIcon from '../../svg/right-arrow-icon';
import GuidePhraseCard from './guide-phrase-card';
import LandingTitle from './landing-title';

const BUTTON_PHRASES = ['프로젝트 생성하기', '편집하기', '영상 내보내기'];

export default function UserGuideSection() {
  const [activeIdx, setActiveIdx] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();

  SwiperCore.use(['Autoplay']);

  return (
    <section className="bg-black w-full py-12 lg:py-40">
      <div className="max-w-screen-xl 2xl:max-w-screen-2xl w-full mx-auto px-7 lg:px-5">
        <LandingTitle
          badge="사용자 가이드"
          title="플루닛 스튜디오 어떻게 만들어질까요?"
          description="단순하고 직관적이며 영상 제작에 대한 사전 지식이 필요하지 않는 간단한 3가지 단계로 누구나 전문가가 될 수 있어요."
          link="/tutorial"
        />
        <div className="flex max-lg:flex-col max-lg:space-y-20 lg:space-x-14 2xl:space-x-32 items-center mt-7 lg:mt-16">
          <StepImage activeIdx={activeIdx} />
          <div className="flex flex-col max-w-full lg:max-w-[600px] w-full">
            <GuideSwiperController swiper={swiper} activeIdx={activeIdx} />
            <Swiper
              onSwiper={setSwiper}
              onRealIndexChange={({ realIndex }: { realIndex: number }) =>
                setActiveIdx(realIndex)
              }
              speed={500}
              className="w-full max-w-full"
              autoplay={{ delay: 4000, disableOnInteraction: false }}
              loop
              slidesPerView={1}>
              {guidePhraseArray.map((phrases, idx) => (
                <SwiperSlide key={`guide-${idx}`}>
                  <GuidePhraseCard idx={idx + 1} phrases={phrases} />
                </SwiperSlide>
              ))}
            </Swiper>
            <ProgressBar activeIdx={activeIdx} />
            <button
              onClick={() => navigate(isLoggedIn ? '/console' : '/login')}
              className="w-fit mt-10 lg:mt-14 flex items-center space-x-2 group">
              <span className="text-sm lg:text-lg 2xl:text-xl font-bold">
                {t('스튜디오 시작하기')}
              </span>
              <i className="group-hover:translate-x-1 duration-150">
                <RightArrowIcon />
              </i>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

function StepImage({ activeIdx }: { activeIdx: number }) {
  return (
    <div className="relative max-h-full h-full w-full max-w-[84%] md:max-w-[500px] lg:max-w-[640px]">
      <div
        style={{
          // eslint-disable-next-line no-template-curly-in-string
          backgroundImage: `url(${getMainDir(
            `/img/user-guide/${activeIdx}.png`
          )})`,
        }}
        className="w-[90%] mx-auto h-fit bg-cover duration-200 rounded-xl relative z-10">
        <img
          draggable={false}
          className="invisible"
          src={getMainDir('/img/user-guide/1.png')}
          alt="project steps"
        />
      </div>
      <img
        draggable={false}
        className="absolute -bottom-10 lg:-bottom-[20%]"
        src={getMainDir('/svg/stage.svg')}
        alt="stage"
      />
      <div className="w-[90%] translate-x-1/2 right-1/2 translate-y-1.5 h-12 lg:h-32 bg-gradient-to-b from-[#484747] to-[#484747]/0 rounded-t-xl absolute" />
    </div>
  );
}

function GuideSwiperController({
  activeIdx,
  swiper,
}: {
  activeIdx: number;
  swiper: SwiperCore | undefined;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex space-x-1 lg:space-x-3 2xl:space-x-5 mb-8 lg:mb-12">
      {BUTTON_PHRASES.map((phrase, idx) => (
        <button
          key={phrase}
          className={`border rounded-full px-4 lg:px-5 2xl:px-7 py-2 lg:py-2.5 max-lg:text-xs duration-300 ${
            idx === activeIdx
              ? 'bg-primary border-primary font-bold'
              : 'border-white hover:bg-white/20'
          }`}
          onClick={() => swiper?.slideTo(idx + 1)}>
          {t(phrase)}
        </button>
      ))}
    </div>
  );
}

function ProgressBar({ activeIdx }: { activeIdx: number }) {
  return (
    <div className="relative bg-tertiary rounded-2xl w-full lg:w-[85%] h-0.5 mt-8">
      <div
        style={{
          width: `${((activeIdx + 1) / 3) * 100}%`,
        }}
        className="absolute h-full bg-primary rounded-2xl duration-300"
      />
    </div>
  );
}
