import { useTranslation } from 'react-i18next';
import ConsoleMediaBanner from '../../components/console/my-media/media-banner';
import MyMetahumansGrid from '../../components/console/my-metahumans/my-metahumans-grid';

export default function ConsoleMetahumanPage() {
  const { t } = useTranslation();

  return (
    <section className="w-full">
      <ConsoleMediaBanner />
      <div className="mt-14">
        <h1 className="text-xl 2xl:text-2xl">{t('내 메타휴먼')}</h1>
        <MyMetahumansGrid />
      </div>
    </section>
  );
}
