import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { ChevronDown } from 'heroicons-react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { inquiryCategoryArray } from '../../../arrays';
import { InquiryReqType } from '../../../types_new/main/service/types';

type Props = {
  form: InquiryReqType;
  setForm: Dispatch<SetStateAction<InquiryReqType>>;
};

export default function InquiryCategoryListBox({ setForm, form }: Props) {
  return (
    <Listbox
      value={form.contactCategory}
      onChange={category =>
        setForm(prev => ({ ...prev, contactCategory: category }))
      }>
      {({ open }) => (
        <div className="relative mt-1 w-full">
          <Listbox.Button className="relative w-full cursor-pointer rounded-md py-2.5 sm:py-3 2xl:py-4 px-4 2xl:px-5 pl-5 pr-6 text-left focus:outline-none border border-brayBorder text-[#d5d5d5]">
            <span
              className={`block truncate 2xl:leading-7 text-sm 2xl:text-base font-normal ${
                form.contactCategory ? 'text-black' : 'text-[#666]'
              }`}>
              {form.contactCategory || '문의 유형을 선택해주세요.'}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDown
                className={`h-5 w-5 text-gray-400 duration-150 ${
                  open && 'rotate-180'
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="ease-in-out duration-150"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4">
            <Listbox.Options className="absolute z-10 mt-2 max-h-72 w-full shadow-2xl overflow-auto rounded-md p-1 text-base ring-1 ring-[#e9e9e9] focus:outline-none sm:text-sm bg-white">
              {inquiryCategoryArray.map((category, idx) => (
                <Listbox.Option
                  key={`type-${idx}`}
                  className={({ active }) =>
                    `relative flex items-center cursor-pointer select-none py-2.5 sm:py-3 2xl:py-4 px-4 2xl:px-5 rounded-md duration-75 ease-out text-black ${
                      active && 'bg-[#F8F7FF]'
                    }`
                  }
                  value={category}>
                  <span
                    className={`block truncate max-md:text-sm ${
                      category === form.contactCategory
                        ? 'font-medium'
                        : 'font-normal'
                    }`}>
                    {category}
                  </span>
                  {category === form.contactCategory && (
                    <span className="flex items-center text-primary ml-3">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
