type Props = {
  isProjectPage?: boolean;
};

export default function FolderIcon({ isProjectPage }: Props) {
  return (
    <svg
      className="w-[22px] h-[22px]"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`${
          isProjectPage && 'fill-white group-hover:fill-slate-300 duration-150'
        }`}
        d="M13.107 5.30769H17.6953C18.0155 5.30766 18.3322 5.37446 18.6252 5.50384C18.9182 5.63321 19.1811 5.82232 19.3971 6.0591C19.613 6.29588 19.7774 6.57513 19.8795 6.87905C19.9817 7.18296 20.0195 7.50487 19.9905 7.82423L19.2566 15.9012C19.2045 16.4745 18.9404 17.0077 18.5159 17.396C18.0914 17.7843 17.5374 17.9998 16.9624 18H5.03776C4.46283 17.9998 3.90875 17.7843 3.48429 17.396C3.05984 17.0077 2.79566 16.4745 2.74363 15.9012L2.00965 7.82423C1.96054 7.28975 2.09997 6.75493 2.40372 6.31269L2.35878 5.30769C2.35878 4.69565 2.60157 4.10868 3.03375 3.67591C3.46593 3.24313 4.05209 3 4.66328 3H8.89435C9.5055 3.00013 10.0916 3.24335 10.5236 3.67615L11.4777 4.63154C11.9098 5.06434 12.4958 5.30756 13.107 5.30769ZM3.51794 5.44615C3.76453 5.35615 4.02954 5.30769 4.30608 5.30769H10.5236L9.709 4.49192C9.49296 4.27552 9.19992 4.15391 8.89435 4.15385H4.66328C4.36144 4.15379 4.07163 4.27234 3.85616 4.48401C3.64069 4.69568 3.51677 4.98357 3.51103 5.28577L3.51794 5.44615Z"
        fill="#44505F"
      />
    </svg>
  );
}
