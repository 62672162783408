export default function PreviewSelectModal({
  previewPopupToggle,
  PreviewPopupIcon,
  setPage,
  page,
  PopupSelectBtn,
  t,
  segment,
  tmpTalkSortedArray,
  msToTime,
  requestPreview,
  previewPopup,
  previewNum,
}) {
  const tMaxCount = process.env.REACT_APP_PREVIEW_MAX;
  return (
    <>
      {previewNum === tMaxCount ? (
        <div className="preview-popup">
          <button
            className="close__btn close__gray__icon popupClose"
            onClick={previewPopupToggle}
          />
          <h3>
            {t('미리보기는 {{maxcount}}회까지 가능합니다.', {
              maxcount: tMaxCount.toLocaleString(),
            })}
          </h3>
          <div className="popup-btn-cont justify-center">
            <button className="popupBtnLeft" onClick={previewPopupToggle}>
              {t('취소')}
            </button>
          </div>
        </div>
      ) : (
        <div className="preview-popup">
          <button
            className="close__btn close__gray__icon popupClose"
            onClick={previewPopupToggle}
          />
          <img src={PreviewPopupIcon} alt="" className="preview-icon" />
          <h3>{t('미리보기할 장면을 선택하세요.')}</h3>
          {/* <p>{t('미리보기는 한 프로젝트당 최대 5회 가능합니다.')}</p> */}
          <p>
            {t('미리보기는 한 프로젝트당 최대 {{maxcount}}회 가능합니다.', {
              maxcount: tMaxCount.toLocaleString(),
            })}
          </p>
          <div className="popup-select-cont">
            <button onClick={() => setPage({ ...page, select: !page.select })}>
              {page.value === '' ? t('장면 선택') : page.value}
              <img
                src={PopupSelectBtn}
                alt=""
                className="popup-arrow-icon"
                style={{ transform: page.select && 'rotate(180deg)' }}
              />
            </button>
            {page.select && (
              <div className="popup-select-box">
                <ul>
                  {segment && segment.length
                    ? tmpTalkSortedArray.map((item: any, key: any) => {
                        if (item.mediaType === 'metahuman') {
                          return (
                            <li
                              key={key}
                              onClick={() => {
                                setPage({
                                  select: false,
                                  value:
                                    tmpTalkSortedArray
                                      .filter(
                                        item => item.mediaType === 'metahuman'
                                      )
                                      .findIndex(
                                        element =>
                                          element.segmentId === item.segmentId
                                      ) + 1,
                                  segmentId: item.segmentId,
                                });
                              }}>
                              <span>
                                {tmpTalkSortedArray
                                  .filter(
                                    item => item.mediaType === 'metahuman'
                                  )
                                  .findIndex(
                                    element =>
                                      element.segmentId === item.segmentId
                                  ) + 1}
                              </span>
                              <div>
                                <div className="pageTime">
                                  {`${msToTime(
                                    item?.insertTime,
                                    'ms'
                                  )} ~ ${msToTime(
                                    item?.insertTime + item?.duration,
                                    'ms'
                                  )}`}
                                </div>
                              </div>
                            </li>
                          );
                        }
                      })
                    : ''}
                </ul>
              </div>
            )}
          </div>
          <div className="popup-btn-cont">
            <button
              className="popupBtnLeft bg-[#bebebe] hover:bg-[#cecece] text-black"
              onClick={previewPopupToggle}>
              {t('취소')}
            </button>
            <button
              className={`popupBtnRight ${
                page.value ? 'opacity-100' : 'cursor-not-allowed'
              } hover:!bg-[#917bff]`}
              disabled={!page.value}
              onClick={requestPreview}>
              {t('확인')}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
