export default function DetailIcon() {
  return (
    <svg
      className="w-5 h-5 group"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className="fill-white group-hover:fill-slate-200 duration-150"
        width="20"
        height="20"
        rx="5"
      />
      <circle cx="10" cy="6" r="1" fill="#ADADAD" />
      <circle cx="10" cy="10" r="1" fill="#ADADAD" />
      <circle cx="10" cy="14" r="1" fill="#ADADAD" />
    </svg>
  );
}
