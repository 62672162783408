import { Dispatch, MouseEvent, SetStateAction } from 'react';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import { MetahumanListType } from '../../../types_new/main/resource/types';
import AudioPauseIcon from '../../svg/AudioPauseIcon';
import AudioPlayIcon from '../../svg/AudioPlayIcon';

type Props = {
  voice: MetahumanListType['voices'][number];
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  projectInfo: NewProjectInfoType;
  isPlaying: boolean;
  selectedAudioId: string;
  handleAudioClick: (audioId: string) => void;
};

export default function VoiceCard({
  voice: { voiceId, name },
  setProjectInfo,
  projectInfo: { defaultMetahuman },
  isPlaying,
  selectedAudioId,
  handleAudioClick,
}: Props) {
  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setProjectInfo(prev => ({
      ...prev,
      defaultMetahuman: { ...prev.defaultMetahuman, voiceId },
    }));
  };

  const isActive = String(defaultMetahuman.voiceId) === String(voiceId);

  return (
    <div className="text-center space-y-2">
      <div
        className={`cursor-pointer text-black flex items-center space-x-4 p-2 px-3 lg:p-3 border border-gray-300 hover:bg-gray-50 rounded-lg focus:outline-none duration-150 ${
          isActive && 'ring-2 ring-primary'
        }`}
        onClick={handleOnClick}>
        <span className="block text-[#5E6479] font-normal text-sm">{name}</span>
        <button
          onClick={e => {
            e.stopPropagation();
            handleAudioClick(String(voiceId));
          }}
          className="cursor-pointer p-4 overflow-visible rounded-full bg-[#F2F3F5] hover:bg-primary/10 duration-100 ease-out relative">
          <i className="absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2">
            {isPlaying && String(selectedAudioId) === String(voiceId) ? (
              <AudioPauseIcon />
            ) : (
              <AudioPlayIcon />
            )}
          </i>
        </button>
      </div>
    </div>
  );
}
