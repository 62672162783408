import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
// import { doGetProjectList } from '../../../networks/project.service';
import { getProjectList } from '../../../networks/project.service';
import { newProjectModal } from '../../../recoil/atoms/store';
import { ProjectInfoType } from '../../../types_new/console/resource/types';
import { ProjectReqType } from '../../../types_new/console/service/types';
import Pagination from '../../common/Pagination';
import NewProjectIcon from '../../svg/new-project-icon';
import ProjectCard from './project-card';
import ProjectCardSkeleton from './project-card-skeleton';
import RecentProjectsGrid from './recent-projects-grid';

const POSTS_PER_PAGE = 7;

export default function EditingProjectsGrid() {
  const { t } = useTranslation();
  const setProjectModal = useSetRecoilState(newProjectModal);
  const [loading, setLoading] = useState(false);
  const [editingProjects, setEditingProjects] = useState<ProjectInfoType[]>([]);
  const [total, setTotal] = useState(0);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);

  const getProjects = async () => {
    const params: ProjectReqType = {
      status: 'pending',
      paged,
      postsPerPage: POSTS_PER_PAGE,
      order: 'DESC',
    };

    setLoading(true);
    await getProjectList(params)
      .then(res => {
        const projects = res.data.body.items;
        const total = res?.data.body.total;
        setEditingProjects(projects);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getProjects();
  }, [paged]);

  const onPageChange = (page: number) => {
    setPaged(page);
    setParentPage(page);
  };

  return (
    <>
      <RecentProjectsGrid getProjects={getProjects} />
      <div className="w-full flex flex-col space-y-6 mt-6 xl:mt-8 2xl:mt-12">
        <h4 className="text-consoleDark text-lg">{t('편집중인 프로젝트')}</h4>
        <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-3 xl:gap-x-4 2xl:gap-x-[18px] gap-y-5 xl:gap-y-8 2xl:gap-y-10">
          <button
            onClick={() => setProjectModal(true)}
            className="flex flex-col space-y-2 xl:space-y-2.5 justify-center items-center h-36 xl:h-40 2xl:h-44 rounded-[10px] border border-dashed border-[#ADADAD] hover:bg-[#b7d4dd]/10 duration-100">
            <NewProjectIcon />
            <span className="text-consoleDark text-sm 2xl:text-[15px]">
              {t('새 프로젝트 만들기')}
            </span>
          </button>
          {loading ? (
            <Skeleton />
          ) : (
            <>
              {editingProjects?.map((project, idx) => (
                <li key={`project-${idx}`}>
                  <ProjectCard
                    updateAfterDelete={getProjects}
                    updateDuplicatedProject={getProjects}
                    setProjects={setEditingProjects}
                    project={project}
                    isEditing
                  />
                </li>
              ))}
            </>
          )}
        </ul>
        <Pagination
          onPageChange={onPageChange}
          totalItems={total}
          currentPage={paged}
          parentPage={parentPage}
          itemsPerPage={POSTS_PER_PAGE}
        />
      </div>
    </>
  );
}

function Skeleton() {
  const skeletonArr = [1, 2, 3, 4, 5, 6, 7];
  return (
    <>
      {skeletonArr.map((_, idx) => (
        <li key={`skeleton-${idx}`}>
          <ProjectCardSkeleton isEditing />
        </li>
      ))}
    </>
  );
}
