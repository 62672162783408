export default function IntersectCircle() {
  return (
    <svg
      className="w-full lg:w-[60%] fixed right-0"
      width="1139"
      viewBox="0 0 1139 1142"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.2"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1132.12 472.455C1122.02 414.376 1103.14 359.309 1076.9 308.671L1070.46 311.182C1035.43 240.949 988.753 180.419 934.028 131.401C835.149 49.3376 708.134 0 569.596 0C419.344 0 282.646 58.0341 180.695 152.91C62.6442 271.087 -3.79323 439.278 0.167554 613.681C21.9974 909.102 268.599 1142 569.596 1142C864.011 1142 1106.38 919.178 1137.27 632.973C1139.93 580.791 1136.35 527.678 1126.01 474.838L1132.12 472.455ZM187.812 747.936C264.4 973.66 497.236 1089.94 707.853 1007.86C864.967 946.552 965.267 791.978 974.235 623.613C976.453 606.389 977.596 588.828 977.596 571C977.596 345.668 794.929 163 569.596 163C344.264 163 161.596 345.668 161.596 571C161.596 583.873 162.193 596.606 163.359 609.175C164.35 655.344 172.27 702.13 187.812 747.936Z"
        fill="url(#paint0_linear_2948_139245)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2948_139245"
          x1="43.8881"
          y1="321.355"
          x2="1092.19"
          y2="808.987"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
