import { ArrowRight } from 'heroicons-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { doGetResourceList } from '../../../networks/source.service';
import { SourceType } from '../../../types_new/console/resource/types';
import MediaSourceGrid from './media-source-grid';

type Props = {
  group: 'background' | 'clip' | 'etc';
};
export default function SourceContainer({ group }: Props) {
  const [loading, setLoading] = useState(false);
  const [sources, setSources] = useState<SourceType[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getResourceList();
  }, [group]);

  const getResourceList = () => {
    setLoading(true);
    doGetResourceList({
      group,
      isMine: '1',
      isPurchased: '1',
      lang: process.env.REACT_APP_LANGUAGE || 'ko',
      postsPerPage: 12,
    })
      .then(({ sources }) => setSources(sources))
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const displayName = () => {
    switch (group) {
      case 'background':
        return '배경';
      case 'clip':
        return '꾸미기';
      case 'etc':
        return '기타';
      default:
        return '';
    }
  };

  return (
    <div className="w-full">
      <div className="w-full flex items-center justify-between border-b border-grayBorder2 mt-5 xl:mt-10 2xl:mt-12 pb-3">
        <h5>{t(displayName())}</h5>
        <button
          onClick={() => navigate(`/media/${group}`)}
          className="flex items-center space-x-2 group">
          <span className="text-sm">{t('더 보러가기')}</span>
          <ArrowRight className="w-3 h-3 group-hover:translate-x-0.5 -translate-y-0.5 duration-200 ease-out" />
        </button>
      </div>
      {loading ? (
        <SourceSkeleton />
      ) : (
        <>
          {sources?.length ? (
            <MediaSourceGrid
              sources={sources}
              getResourceList={getResourceList}
            />
          ) : null}
        </>
      )}
    </div>
  );
}

function SourceSkeleton() {
  const array = [1, 2, 3, 4, 5, 6];
  return (
    <ul className="grid grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-3 xl:gap-4 2xl:gap-5 mt-5">
      {array?.map((_, idx) => (
        <li
          key={`sourceSkeleton-${idx}`}
          className="flex flex-col items-center">
          <div className="w-28 xl:w-32 2xl:w-36 h-20 xl:h-24 bg-primary/10 animate-pulse flex items-center justify-center mb-3 rounded-lg overflow-hidden group">
            <svg
              className="w-8 h-8 text-gray-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18">
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
          </div>
          <div className="flex flex-col space-y-1 items-center">
            <div className="w-20 h-1.5 bg-gray-400 rounded-full animate-pulse" />
            <div className="w-16 h-1 bg-gray-300 rounded-full animate-pulse" />
          </div>
        </li>
      ))}
    </ul>
  );
}
