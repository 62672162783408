import { useTranslation } from 'react-i18next';
import { getMainDir } from '../../../util/common';

type Props = {
  idx: number;
  title: string;
  phrase: string;
  iconPath: string;
};
export default function StepCard({ idx, title, phrase, iconPath }: Props) {
  const { t } = useTranslation();

  return (
    <div className="border border-[#987eff] pt-6 md:pt-14 pb-8 md:pb-[72px] rounded-[10px] bg-gradient-to-b from-[#0c111b] to-[#0e1624]">
      <span className="text-base md:text-lg">
        Step <strong className="font-bold text-xl md:text-2xl">0{idx}.</strong>
      </span>
      <div className="mb-4 md:mb-10 mt-4 md:mt-8 w-20 h-20 mx-auto">
        <img
          className="w-full h-full"
          src={getMainDir(`/img/production-inquiry/${iconPath}`)}
          alt="step1"
        />
      </div>
      <h1 className="md:text-lg xl:text-xl 2xl:text-2xl font-bold whitespace-pre-wrap">
        {t(title)}
      </h1>
      <p className="text-white/50 mt-3 max-md:text-sm whitespace-pre-wrap">
        {t(phrase)}
      </p>
    </div>
  );
}
