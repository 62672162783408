type Props = {
  isSmall?: boolean;
};

export default function CoinIcon({ isSmall }: Props) {
  return (
    <svg
      className={`${isSmall ? 'w-4 h-4' : 'w-[18px] h-[18px]'}`}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.11353 12.8179C3.01327 12.8179 0.500014 10.1724 0.500014 6.90897C0.500014 3.64553 3.01327 1 6.11353 1C9.21379 1 11.7271 3.64553 11.7271 6.90897C11.7271 10.1724 9.21379 12.8179 6.11353 12.8179Z"
        stroke="#9379FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.59079 5.01813C7.21471 4.71316 6.74546 4.54632 6.26127 4.54541C5.03753 4.54541 4.04541 5.60311 4.04541 6.909C4.04541 8.21488 5.03753 9.27258 6.26127 9.27258C6.75999 9.27258 7.2203 9.0965 7.59079 8.79987"
        stroke="#9379FF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.81885 1C8.3792 1 13.5005 1.5909 13.5005 6.90897C13.5005 12.227 8.3792 12.8179 5.81885 12.8179"
        stroke="#9379FF"
      />
    </svg>
  );
}
