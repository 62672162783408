import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { useTemplate } from '../../../../networks/template.service';
import { useModalAtom } from '../../../../recoil/atoms/store';
import { TemplateBuyType } from '../../../../types_new/main/resource/types';
import { changeHost, getValueByKey } from '../../../../utils/common';

type Props = {
  data: TemplateBuyType;
};

export default function TemplateCard({ data }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const setUseModal = useSetRecoilState(useModalAtom);
  const { registerDate, template, metaData } = data;
  const templateBuyId = getValueByKey(metaData, 'templateBuyId');

  const handleOnClick = () => {
    setUseModal({ type: 'loader', isOpen: true });
    useTemplate({
      templateBuyId,
    })
      .then(({ projectId }) => navigate(`/project/${projectId}`))
      .catch(console.log)
      .finally(() => setUseModal(prev => ({ ...prev, isOpen: false })));
  };

  return (
    <li className="w-full flex flex-col space-y-2.5">
      {template.thumbnail ? (
        <div
          onClick={handleOnClick}
          className="relative h-36 xl:h-40 2xl:h-44 rounded-[10px] bg-gradient-to-br from-[#9D9BA0] to-[#BFBFBF] group cursor-pointer overflow-hidden">
          <img
            draggable={false}
            alt="project thumbnail"
            src={`${changeHost(template.thumbnail)}`}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-primary/20 opacity-0 group-hover:opacity-100 flex items-center justify-center duration-150 ease-out">
            <span className="text-xs text-white font-semibold">
              {t('사용하기')}
            </span>
          </div>
        </div>
      ) : (
        <DefaultTemplate />
      )}

      <div className="flex justify-between items-end">
        <span className="text-[15px] line-clamp-1 text-consoleDark">
          {template.templateNm}
        </span>
        <span className="text-[#aaa] text-sm">
          {moment(registerDate).format('YYYY-MM-DD')}
        </span>
      </div>
    </li>
  );
}

function DefaultTemplate() {
  return (
    <div className="relative h-36 xl:h-40 2xl:h-44 rounded-[10px] bg-gradient-to-br from-[#9D9BA0] to-[#BFBFBF] group cursor-pointer overflow-hidden">
      <div className="w-full h-full flex justify-center items-center">
        <svg
          className="w-16 h-16 text-gray-200"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20">
          <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
        </svg>
      </div>
    </div>
  );
}
