import { ChangeEvent, useState } from 'react';
import { useRecoilState } from 'recoil';
import useLanguage from '../../../hooks/use-lang';
import {
  TemplatePublishBodyType,
  requestTemplatePublish,
} from '../../../networks/template.service';
import { useModalAtom } from '../../../recoil/atoms/store';
import { changeHost } from '../../../utils/common';
import VideoModal from '../../VideoModal';
import Loader from '../../common/loader';
import InformModal from '../../modals/inform-modal';
import TemplatePublishInput from '../common/input-form';

export default function TemplatePublishForm() {
  const [useModal, setUseModal] = useRecoilState(useModalAtom);
  const [loading, setLoading] = useState(false);
  const language = useLanguage();
  const [videoModal, setVideoModal] = useState({
    isOpen: false,
    videoURL: '',
  });
  const [informModal, setInformModal] = useState({
    isOpen: false,
    title: '',
    message: '',
  });
  const {
    projectId,
    projectName: projectNm,
    downloadLink,
    duration: playTime,
    completedThumbnail,
  } = useModal.data;

  const [form, setForm] = useState<TemplatePublishBodyType>({
    language,
    projectDistNm: '',
    projectId,
    projectNm,
    thumbnail: completedThumbnail,
    downloadLink,
    playTime,
    description: '',
    credit: 0,
  });

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const openVideoModal = () =>
    setVideoModal({
      isOpen: true,
      videoURL: useModal.data.downloadLink,
    });

  const handleOnClick = e => {
    setLoading(true);
    e.preventDefault();
    requestTemplatePublish(form)
      .then(({ code }) => {
        if (code === '200') {
          handleSuccess();
        } else {
          handleFail();
        }
      })
      .catch(console.log)
      .finally(() => setLoading(false));

    function handleSuccess() {
      setInformModal({
        isOpen: true,
        title: '영상 템플릿 신청 완료',
        message:
          '영상 템플릿 배포 신청하였습니다. 승인까지는 1주일 정도 소요 예정입니다. 승인 여부는 마이페이지에서 확인하실 수 있습니다.',
      });
    }

    function handleFail() {
      setInformModal({
        isOpen: true,
        title: '등록 실패',
        message: '입력 필수값을 다시 확인해주세요.',
      });
    }
  };

  const closeInformModal = () => {
    if (informModal.title === '영상 템플릿 신청 완료') {
      setInformModal(prev => ({ ...prev, isOpen: false }));
      setUseModal(prev => ({ ...prev, isOpen: false }));
    } else {
      setInformModal(prev => ({ ...prev, isOpen: false }));
    }
  };

  return (
    <>
      <InformModal
        isOpen={informModal.isOpen}
        title={informModal.title}
        message={informModal.message}
        closeModal={closeInformModal}
      />
      <VideoModal
        isOpen={videoModal.isOpen}
        videoURL={videoModal.videoURL}
        closeModal={() => setVideoModal(prev => ({ ...prev, isOpen: false }))}
      />
      <form action="submit" className="w-full mt-6">
        <TemplatePublishInput
          label="템플릿 제목"
          name="projectDistNm"
          placeholder="사용자 영상 배포명"
          maxLength={20}
          value={form.projectDistNm}
          onChange={handleOnChange}
        />
        <div>
          <div className="flex items-start space-x-1 mt-2">
            <span className="text-left p-2 pl-0 text-[#353333] text-sm">
              템플릿 설명
            </span>
            <div className="w-1 h-1 rounded-full bg-primary translate-y-2 -translate-x-2" />
          </div>
          <div className="relative">
            <textarea
              spellCheck={false}
              maxLength={80}
              value={form.description}
              onChange={e =>
                setForm(prev => ({ ...prev, description: e.target.value }))
              }
              className="border rounded-md text-sm w-[100%] py-3 px-4 sm:py-3.5 2xl:py-4 outline-none resize-none "></textarea>
            <span className="absolute bottom-2 right-2 text-xs text-gray-400">
              {form.description.length}/80자
            </span>
          </div>
        </div>
        <TemplatePublishInput
          label="프로젝트 제목"
          name="projectNm"
          placeholder="선택된 프로젝트명"
          value={form.projectNm}
          onChange={handleOnChange}
          disabled
        />
        <div>
          <div className="flex items-start space-x-1 mt-2">
            <div className="text-left p-2 pl-0 text-[#353333] text-sm">
              영상 미리보기
            </div>
            <div className="w-1 h-1 rounded-full bg-primary translate-y-2 -translate-x-2" />
          </div>
          <div>
            <div
              onClick={openVideoModal}
              style={{
                backgroundImage: `url(${changeHost(completedThumbnail || '')})`,
              }}
              className="relative w-20 h-20 bg-cover bg-center cursor-pointer group border rounded-md">
              <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center opacity-0 group-hover:opacity-100 bg-primary/30 duration-150 ease-out">
                <span className="text-white text-xs">미리보기</span>
              </div>
            </div>
          </div>
        </div>
        <TemplatePublishInput
          label="재생시간"
          name="playTime"
          value={form.playTime}
          onChange={handleOnChange}
          disabled
          className="!w-[35%]"
        />
        <TemplatePublishInput
          type="number"
          label="템플릿 크레딧"
          name="credit"
          disabled
          value={form.credit}
          onChange={handleOnChange}
          className="!w-[35%]"
        />
        <button
          onClick={handleOnClick}
          disabled={!form.projectDistNm || loading || !form.description}
          className="bg-primary flex justify-center items-center disabled:bg-primary/50 px-20 py-3 sm:py-3.5 2xl:py-4 rounded-lg mt-5 text-white duration-150 ease-out w-full"
          type="submit">
          {loading ? <Loader /> : '등록'}
        </button>
      </form>
    </>
  );
}
