export default function AddToTimeLine() {
  return (
    <>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="19" cy="19" r="19" fill="#7B61FF" />
        <path d="M19 13V25" stroke="white" strokeWidth="2" />
        <path d="M25 19H13" stroke="white" strokeWidth="2" />
      </svg>
    </>
  );
}
