/* eslint-disable */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { tempoArray } from '../../array/tempoArray';
import iconArrowScript from '../../images/icons/studio-side/icon-arrow-script.svg';
import iconHoverDelet from '../../images/icons/studio-side/icon-hover-delet.svg';
import iconSpeed from '../../images/icons/studio-side/icon-speed.svg';
import iconArrowWhite from '../../images/metahuman/icon-arrow-white.svg';
import '../../locale/i18n';
import { createScript } from '../../services/project';
// import { translateScene } from '../../services/translate';
import { createTranslateWav } from '../../networks/translate.service';
import { TOGGLE_LOADING } from '../../store/common/loading';
import {
  projectLang,
  rails,
  setSegmentsInfo,
  pages as storedPages,
} from '../../store/pages';
import { IPages, ISegments } from '../../store/pages/types';
import { changeHost } from '../../utils/common';
import ScriptTranslateModal from '../ScriptTranslateModal';
import TalkTranslateModal from '../TalkTranslateModal';
import Loader from '../lottie/Loader';
import { getAudioFilePath, isScriptKorean } from '../ts/common';
import GptLogo from '../vector/GptLogo';
import PlayIcon from '../vector/PlayIcon';
import StopIcon from '../vector/StopIcon';
import TranslateIcon from '../vector/TranslateIcon';
import TalkGPTModal from './TalkGPTModal';

const MetahumanTalkPopup = ({
  setTalkPopup,
  setActive,
  talkHumanNum,
  metahumanListArray,
  createTalkScript,
  projectId,
  widthCenter,
  setTalkHumanNum,
  defaultHeight,
  defaultWidth,
  changeTalk,
  setChangeTalk,
}: any) => {
  const { t } = useTranslation();
  const [translateLoading, setTranslateLoading] = useState(false);
  const [scriptValue, setScriptValue] = useState<any>({ text: '' });
  const [scriptArray, setScriptArray] = useState([]);
  const [metahumanSelect, setMetahumanSelect] = useState(false);
  const [scriptSpeedSelect, setScriptSpeedSelect] = useState(false);
  const [scriptSpeedValue, setScriptSpeedValue] = useState<any>({});
  const [dialoguePopupInfo, setDialoguePopupInfo] = useState<any>({});
  const [selectedMetahumans, setSelectedMetahumans] = useState<any>([]);
  const [metahumanInfoArray, setMetahumanInfoArray] = useState<any>([]);
  const [accordin, setAccordin] = useState(1);
  const [audioActive, setAudioActive] = useState<any>('');
  const [step, setStep] = useState(1);
  const [metahumanList, setMetahumanList] = useState(metahumanListArray);
  const [railArray, setRailArray] = useRecoilState(rails);
  const [pages, setPages] = useRecoilState(storedPages);
  const [previewLoading, setPreviewLoading] = useState(false);
  const loading = useSetRecoilState(TOGGLE_LOADING);
  const [talkAudioFile, setTalkAudioFile] = useState<any>();
  const [isTalkAudioPlaying, setIsTalkAudioPlaying] = useState<any>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [translatedLang, setTranslatedLang] = useState('');
  const projectLanguage = useRecoilValue(projectLang);
  const [translateModal, setTranslateModal] = useState(false);
  const [scriptTranslateModal, setScriptTranslateModal] = useState(false);
  const [chatGPTModal, setChatGPTModal] = useState(false);
  const [metaInfo, setMetaInfo] = useState({
    metaName: '',
    voiceId: '',
    scriptValue: '',
    currentLang: '',
    idx: 0,
    modelId: '',
  });
  const [voices, setVoices] = useState<
    { audio: HTMLAudioElement; id: string }[]
  >([]);

  const isAllHumansScripted = scriptArray.length === selectedMetahumans.length;

  const handleAccordin = (num: number) => {
    if (num === accordin) {
      setAccordin(0);
    } else {
      setAccordin(num);
    }
  };

  useEffect(() => {
    if (dialoguePopupInfo.voicesArray === undefined) return;

    const voiceArr = dialoguePopupInfo.voicesArray.map(voice => ({
      audio: new Audio(voice.sourceURL),
      id: String(voice.voiceId),
    }));
    setVoices(voiceArr);
  }, [dialoguePopupInfo]);

  const getIndexKey = () => {
    return Math.random().toString().slice(-6);
  };

  const handleAudioClick = useCallback(
    (audioId: string) => {
      const audio = voices.filter(voice => voice.id === audioId)[0].audio;

      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
        return;
      }

      audio.play();
      setIsPlaying(true);
      setAudioActive(audioId);
      audio.onended = () => {
        setIsPlaying(false);
        setAudioActive('');
      };
    },
    [isPlaying, voices]
  );

  const addMetahumanArray = () => {
    const newArray = metahumanList.filter(item => {
      if (item.ID === dialoguePopupInfo.metaHumanId) {
        let tmpselectedMetahumans = [...selectedMetahumans];
        tmpselectedMetahumans.push(item);
        setSelectedMetahumans(tmpselectedMetahumans);
      }
      return item.ID !== dialoguePopupInfo.metaHumanId;
    });
    setMetahumanList(newArray);
    setMetahumanInfoArray([...metahumanInfoArray, dialoguePopupInfo]);
    setDialoguePopupInfo({});
    setStep(step + 1);
    setAccordin(1);
  };

  const PreviousButton = () => {
    if (step === 1) {
      setActive(1);
      setTalkPopup(false);
      setChangeTalk(0);
    } else {
      setStep(step - 1);
      const newArray = metahumanInfoArray.pop();
      const tmpMetahumanInfoArray = [...metahumanInfoArray];
      tmpMetahumanInfoArray.pop();
      setMetahumanInfoArray(tmpMetahumanInfoArray);
      setDialoguePopupInfo(newArray);
      const tmpselectedMetahumans = [...selectedMetahumans];
      const tmpMetahumanList = [...metahumanList];
      tmpMetahumanList.push(
        tmpselectedMetahumans[tmpselectedMetahumans.length - 1]
      );
      tmpselectedMetahumans.pop();
      setSelectedMetahumans(tmpselectedMetahumans);
      tmpMetahumanList.sort((a, b) => a.ID - b.ID);
      setMetahumanList(tmpMetahumanList);
    }
  };

  const addTalkScript = () => {
    console.log('addTalkScript ---------------- ');

    loading({
      isLoading: true,
      text: t('스크립트 추가 중입니다.'),
      time: scriptValue?.text?.length + textLength || 5,
    });

    const tmpArray = [];
    scriptArray.map(item => {
      const tmpObject = {
        metahuman: {
          postId: item?.dialoguePopupInfo?.postId,
          modelId: item.dialoguePopupInfo.metaHumanId,
          styleId: item.dialoguePopupInfo.styleValue,
          poseId: item.dialoguePopupInfo.poseId,
          voiceId: String(item.dialoguePopupInfo.voiceId),
        },
        text: item.text,
        tempo: item?.speed.speed || 1.0,
        targetLang: item.currentLang,
      };
      tmpArray.push(tmpObject);
    });

    createTalkScript({
      projectId,
      segments: tmpArray,
    })
      .then(res => {
        let insertT = 0;
        const tmpSegment = pages[0]?.segments[0]?.segment?.filter(
          item => item.mediaType === 'metahuman'
        );
        let maxInsertTime = -1;
        let maxInsertTimeId = -1;
        tmpSegment?.map(item => {
          if (maxInsertTime <= item.insertTime) {
            maxInsertTime = item.insertTime;
            maxInsertTimeId = item.segmentId || item.imageId || item.titleId;
          }
        });
        tmpSegment?.map(item => {
          if (
            item.segmentId === maxInsertTimeId ||
            item.imageId === maxInsertTimeId ||
            item.titleId === maxInsertTimeId
          ) {
            insertT = item.insertTime + item.duration;
          }
        });
        const data = res.data.body;
        const railLength = railArray.filter(
          item => item.type === 'metahuman'
        ).length;
        const tmpPage = JSON.parse(JSON.stringify(pages));
        const tmpRailArray = [];
        const tmpArray = [];
        const integratedId = Date.now();
        data.wavs.map((item, idx) => {
          if (changeTalk) {
            const preview = scriptArray.filter(
              element => element.metahumanId === item.metahuman.modelId
            )[0].dialoguePopupInfo.preview;

            const insertTime = tmpPage[0]?.segments[0]?.segment.filter(
              item => item?.metaData?.integratedId === changeTalk
            )[0].insertTime;
            let railIndex = 1;
            tmpPage[0]?.segments[0]?.segment
              .filter(item => item?.metaData?.integratedId === changeTalk)
              .map((item, idx) => {
                if (idx === 0) {
                  railIndex = item.railIndex;
                } else if (railIndex > item.railIndex) {
                  railIndex = item.railIndex;
                }
              });

            tmpArray.push({
              segmentId: Math.ceil(Math.random() * 9999999999999),
              mediaType: 'metahuman',
              contentId: item.metahuman.poseId,
              fileId: '',
              fileName: '',
              filePath: '',
              wavFilePath: item.wavFilePath,
              source: preview,
              indexKey: getIndexKey(),
              title: item.text,
              currentLang: translatedLang || projectLanguage,
              videoSize: '',
              rank: 0,
              insertTime: insertTime,
              duration: Math.floor(data.duration * 100),
              startTime: 0,
              endTime: insertTime + Math.floor(data.duration * 100),
              metaData: {
                ...item.metahuman,
                sync: item.sync,
                end_sc_time: item.end_sc_time,
                front_sc_time: item.front_sc_time,
                duration: item.duration,
                integratedId: integratedId,
                // speed: scriptSpeedValue.speed || 1.0,
              },
              fadeIn: 0,
              fadeOut: 0,
              width: defaultWidth,
              height: defaultHeight,
              posX: widthCenter,
              posY: 0,
              chromakey: '',
              mute: 'N',
              volume: 1.0,
              transitionType: '',
              transitionTime: 100,
              zIndex: 0,
              railIndex: railIndex + idx,
            });
          } else {
            if (railLength - 1 < idx + 1) {
              tmpRailArray.push({ type: 'metahuman' });
            }
            const preview = scriptArray.filter(
              element => element.metahumanId === item.metahuman.modelId
            )[0].dialoguePopupInfo.preview;

            tmpArray.push({
              segmentId: Math.ceil(Math.random() * 9999999999999),
              mediaType: 'metahuman',
              contentId: item.metahuman.poseId,
              fileId: '',
              fileName: '',
              filePath: '',
              wavFilePath: item.wavFilePath,
              currentLang: translatedLang || projectLanguage,
              source: preview,
              indexKey: getIndexKey(),
              title: item.text,
              videoSize: '',
              rank: 0,
              insertTime: Math.floor(insertT),
              duration: Math.floor(data.duration * 100),
              startTime: 0,
              endTime: Math.floor(insertT) + Math.floor(data.duration * 100),
              metaData: {
                ...item.metahuman,
                sync: item.sync,
                end_sc_time: item.end_sc_time,
                front_sc_time: item.front_sc_time,
                duration: item.duration,
                integratedId: integratedId,
                // speed: scriptSpeedValue.speed || 1.0,
                // indexKey:
              },
              fadeIn: 0,
              fadeOut: 0,
              width: defaultWidth,
              height: defaultHeight,
              posX: widthCenter,
              posY: 0,
              chromakey: '',
              mute: 'N',
              volume: 1.0,
              transitionType: '',
              transitionTime: 100,
              zIndex: 0,
              railIndex: idx + 1,
            });
          }
        });
        setRailArray([...railArray, ...tmpRailArray]);
        if (pages.length) {
          if (changeTalk) {
            setSegmentsInfo(pages, setPages, {
              pagesIndex: 0,
              segment: {
                segmentArray: [
                  ...tmpPage[0]?.segments[0].segment.filter(
                    item => item?.metaData?.integratedId !== changeTalk
                  ),
                  ...tmpArray.sort((a, b) => a.railIndex - b.railIndex),
                ],
              },
            });
          } else {
            setSegmentsInfo(pages, setPages, {
              pagesIndex: 0,
              segment: {
                segmentArray: [
                  ...tmpPage[0]?.segments[0].segment,
                  ...tmpArray.sort((a, b) => a.railIndex - b.railIndex),
                ],
              },
            });
          }
        } else {
          const segmentObj = {
            segment: tmpArray,
            title: [],
            background: [],
            image: [],
          };
          const segments: ISegments[] = [segmentObj];
          const page: IPages = { segments };
          const pages: IPages[] = [page];
          setPages(pages);
        }
        setTalkPopup(false);
        loading({ isLoading: false, text: '', time: 0 });
        setTalkHumanNum(0);
        setChangeTalk(0);
      })
      .catch(console.log);
  };

  const deleteScript = (idx: number) => {
    const tmpArray = [...scriptArray];
    const id = tmpArray[idx].metahumanId;

    metahumanListArray.map(item => {
      if (item.ID === id) {
        setMetahumanInfoArray([
          ...metahumanInfoArray,
          {
            metaHumanId: tmpArray[idx].metahumanId,
            metaHumanName: tmpArray[idx].metahumanName,
          },
        ]);
      }
    });
    tmpArray.splice(idx, 1);
    setScriptArray(tmpArray);
  };

  const [textLength, setTextLength] = useState(0);
  useEffect(() => {
    let length = 0;
    scriptArray?.map(item => {
      length += item?.text?.length;
    });
    setTextLength(length);
  }, [scriptValue]);

  const ref = useRef<any>();
  useEffect(() => {
    ref?.current?.scrollTo({
      top: ref.current.offsetTop,
      behavior: 'smooth',
    });
  });

  const playAudio = () => {
    talkAudioFile.play();
    talkAudioFile.onended = () => {
      setIsTalkAudioPlaying(false);
    };
  };

  const handlePreviewClick = () => {
    if (!scriptValue.text) {
      return;
    }

    if (isTalkAudioPlaying && talkAudioFile !== null) {
      talkAudioFile.pause();
      setIsTalkAudioPlaying(false);
      return;
    }
    setPreviewLoading(true);
    if (isScriptKorean(scriptValue.text)) {
      if (isTalkAudioPlaying) {
        setPreviewLoading(false);
        talkAudioFile.pause();
        setIsTalkAudioPlaying(false);
      } else {
        if (scriptValue.text.length) {
          createScript({
            projectId,
            modelId: scriptValue.metahumanId,
            voiceId: String(scriptValue.voiceId),
            text: scriptValue.text,
            tempo: scriptSpeedValue?.speed || 1.0,
          })
            .then(res => {
              setTalkAudioFile(new Audio(getAudioFilePath(res)));
              setIsTalkAudioPlaying(true);
            })
            .catch(console.log)
            .finally(() => setPreviewLoading(false));
        }
      }
    } else {
      if (isTalkAudioPlaying) {
        talkAudioFile.pause();
        setIsTalkAudioPlaying(false);
      } else {
        if (scriptValue.text.length) {
          createTranslateWav({
            text: scriptValue.text,
            modelId: scriptValue.metahumanId,
            voiceId: String(scriptValue.voiceId),
            projectId: +projectId,
            targetLang: scriptValue.currentLang || projectLanguage,
            postId: scriptValue?.postId,
          })
            .then(res => {
              setTalkAudioFile(new Audio(getAudioFilePath(res)));
              setIsTalkAudioPlaying(true);
            })
            .catch(console.log)
            .finally(() => setPreviewLoading(false));
        }
      }
    }
  };

  useEffect(() => {
    if (isTalkAudioPlaying) {
      playAudio();
    }
  }, [talkAudioFile]);
  const getScriptValue = (script: string, targetLang: string) => {
    scriptArray[metaInfo.idx].text = script;
    scriptArray[metaInfo.idx].currentLang = targetLang;
  };

  useEffect(() => console.log(scriptValue), [scriptValue]);

  const addMetahuman = () => {
    const isEven = scriptArray.length % 2 === 0;
    const relevantMetahuman = metahumanInfoArray.find(
      item => item.metaHumanId === scriptValue.metahumanId
    );
    const remainingMetahumans = metahumanInfoArray.filter(
      item => item.metaHumanId !== scriptValue.metahumanId
    );

    setScriptArray(prev => [
      ...prev,
      {
        ...scriptValue,
        currentLang: scriptValue.currentLang,
        direction: !isEven ? 'right' : 'left',
        speed: {
          name: scriptSpeedValue?.name || '보통',
          speed: scriptSpeedValue?.speed || 1.0,
        },
        dialoguePopupInfo: relevantMetahuman,
      },
    ]);
    setForNextHuman();

    function setForNextHuman() {
      setMetahumanInfoArray(remainingMetahumans);
      setScriptValue({ text: '' });
      setScriptSpeedValue({});
      setTranslatedLang('');
    }
  };

  return (
    <>
      {chatGPTModal && (
        <TalkGPTModal
          show={chatGPTModal}
          close={() => setChatGPTModal(false)}
          setScriptValue={setScriptValue}
        />
      )}
      {translateModal && (
        <TalkTranslateModal
          modelId={metaInfo.modelId}
          metaInfo={metaInfo}
          scriptValue={metaInfo.scriptValue}
          closeModal={() => setTranslateModal(false)}
          getScriptValue={getScriptValue}
          voiceId={String(metaInfo.voiceId)}
          selectedHumanLang={scriptArray[metaInfo.idx].currentLang}
          projectLang={projectLanguage}
          notSetLang={scriptArray[metaInfo.idx].currentLang}
        />
      )}
      {scriptTranslateModal && (
        <ScriptTranslateModal
          setScriptValue={setScriptValue}
          popupInfo={scriptValue}
          closeModal={() => setScriptTranslateModal(false)}
          selectedHumanLang={projectLanguage}
          projectLang={projectLanguage}
          notSetLang={scriptValue.currentLang}
        />
      )}
      <div>
        {step < talkHumanNum + 1 && (
          <section className="meta-popupCont">
            <div className="popup-top">
              <div>
                <span>{t('메타휴먼')}</span>
                <span
                  style={{
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '17px',
                    color: '#ACAFC0',
                    marginLeft: '10px',
                  }}>{`${step}/${talkHumanNum}`}</span>
              </div>
              <button
                className="close__btn close__gray__icon popupBtn"
                onClick={() => {
                  setTalkPopup(false);
                  setDialoguePopupInfo({});
                  setMetahumanInfoArray([]);
                  setChangeTalk(0);
                }}
              />
            </div>

            <div className="meta-popup-inner">
              <ul className="meta-ul">
                <li className="meta-li">
                  <div
                    className="accordion-select"
                    onClick={() => handleAccordin(1)}>
                    <span
                      className={`${
                        dialoguePopupInfo?.metaHumanId !== '' && 'active'
                      }`}>
                      STEP
                    </span>
                    <strong
                      className={`${
                        dialoguePopupInfo?.metaHumanId !== '' && 'active'
                      }`}>
                      {t('메타휴먼 선택')}
                    </strong>

                    {dialoguePopupInfo?.metaHumanImg && (
                      <div className="selectAfter-icon">
                        <div
                          className="selectAfter-img"
                          style={{
                            backgroundImage: `url(${dialoguePopupInfo?.metaHumanImg})`,
                          }}
                        />
                        <h3>{dialoguePopupInfo?.metaHumanName || ''}</h3>
                      </div>
                    )}
                    {dialoguePopupInfo?.metaHumanId !== '' && (
                      <button className="popup__pencil__icon" />
                    )}
                  </div>

                  {accordin === 1 && (
                    <div className="accordin-menu menu-human secondary-scroll">
                      <ul className="accordin-menu-human secondary-scroll">
                        {metahumanList?.map((item: any, idx: number) => (
                          <li
                            className="human-box"
                            key={idx}
                            onClick={() => {
                              item?.lock
                                ? setDialoguePopupInfo({
                                    ...dialoguePopupInfo,
                                    lockPopup: true,
                                  })
                                : setDialoguePopupInfo({
                                    ...dialoguePopupInfo,
                                    metaHumanId: item.ID,
                                    metaHumanName: item.name,
                                    metaHumanImg: item.thumbnail,
                                    styleArray: item.styles,
                                    poseArray: item.poses,
                                    voicesArray: item.voices,
                                    postId: item?.postId,
                                    poseId: '',
                                    poseName: '',
                                    styleValue: '',
                                    styleName: '',
                                    voiceName: '',
                                    voiceId: '',
                                  });
                              setAccordin(2);
                            }}>
                            <div
                              className={`humanImg ${
                                item.name ===
                                  dialoguePopupInfo?.metaHumanName && 'active'
                              }`}
                              style={{
                                backgroundImage: `url(${changeHost(
                                  item.thumbnail
                                )})`,
                              }}>
                              <div className="badge">
                                {item.isFree && (
                                  <div className="badgeFree">Free</div>
                                )}
                                {item.isReal && (
                                  <div className="badgeReal">Real</div>
                                )}
                              </div>
                              {item.lock && (
                                <button className="popup__lock__icon" />
                              )}
                            </div>
                            <h3>{item.name}</h3>
                            <p>{item.desc}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>

                <li className="meta-li">
                  <div
                    className="accordion-select"
                    onClick={() =>
                      dialoguePopupInfo?.metaHumanId !== '' && handleAccordin(2)
                    }>
                    <span
                      className={`${
                        dialoguePopupInfo?.styleValue !== '' && 'active'
                      }`}>
                      STEP2
                    </span>
                    <strong
                      className={`${
                        dialoguePopupInfo?.styleValue !== '' && 'active'
                      }`}>
                      {t('스타일 선택')}
                    </strong>

                    {/* 선택한 항목(없다가 선택하면 생김) */}
                    {dialoguePopupInfo?.styleName && (
                      <div className="selectAfter-icon">
                        <h3>{dialoguePopupInfo?.styleName}</h3>
                      </div>
                    )}
                    {dialoguePopupInfo?.styleValue && (
                      <button className="popup__pencil__icon" />
                    )}
                  </div>
                  {/* 스타일 선택 */}
                  {accordin === 2 && (
                    <div className="accordin-menu style-scroll secondary-scroll">
                      <ul className="accordin-menu-style secondary-scroll">
                        {dialoguePopupInfo?.styleArray.map(
                          (item: any, idx: number) => (
                            <li
                              className="style-li"
                              key={idx}
                              onClick={() => {
                                setDialoguePopupInfo({
                                  ...dialoguePopupInfo,
                                  styleValue: item.styleId,
                                  styleName: item.name,
                                  poseId: '',
                                  poseName: '',
                                  popupNum: 3,
                                });
                                setAccordin(3);
                              }}>
                              {/* .style-box.active 검색 */}
                              <div
                                className={`style-box ${
                                  dialoguePopupInfo?.styleValue ===
                                    item.styleId && 'active'
                                }`}
                                style={{
                                  backgroundImage: `url(${changeHost(
                                    item.thumbnail
                                  )})`,
                                }}
                              />
                              <h3>{item.name}</h3>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  {/* 스타일 선택 */}
                </li>
                <li className="meta-li">
                  <div
                    className="accordion-select"
                    onClick={() =>
                      dialoguePopupInfo?.styleValue !== '' && handleAccordin(3)
                    }>
                    {/* 선택 완료시 opacity: 0.6;으로 바뀜 (.meta-li strong.active / .meta-li span.active ) */}
                    <span
                      className={`${
                        dialoguePopupInfo?.poseId !== '' && 'active'
                      }`}>
                      STEP3
                    </span>
                    <strong
                      className={`${
                        dialoguePopupInfo?.poseId !== '' && 'active'
                      }`}>
                      {t('자세 선택')}
                    </strong>

                    {/* 선택한 항목(없다가 선택하면 생김) */}
                    {dialoguePopupInfo?.poseName && (
                      <div className="selectAfter-icon">
                        <h3>{dialoguePopupInfo?.poseName}</h3>
                      </div>
                    )}
                    {dialoguePopupInfo?.poseId && (
                      <button className="popup__pencil__icon" />
                    )}
                  </div>
                  {/* 자세 선택 */}
                  {accordin === 3 && (
                    <div className="accordin-menu style-scroll secondary-scroll">
                      <ul className="accordin-menu-style secondary-scroll">
                        {dialoguePopupInfo?.poseArray.map(
                          (item: any, idx: number) => {
                            if (
                              item.styleId === dialoguePopupInfo?.styleValue
                            ) {
                              return (
                                <li
                                  className="style-li"
                                  key={idx}
                                  onClick={() => {
                                    setDialoguePopupInfo({
                                      ...dialoguePopupInfo,
                                      poseId: item.poseId,
                                      poseName: item.name,
                                      preview: item.preview,
                                      popupNum: 4,
                                    });
                                    setAccordin(4);
                                  }}>
                                  {/* .style-box.active 검색 */}
                                  <div
                                    className={`style-box ${
                                      dialoguePopupInfo?.poseId ===
                                        item.poseId && 'active'
                                    }`}
                                    style={{
                                      backgroundImage: `url(${changeHost(
                                        item.thumbnail
                                      )})`,
                                    }}
                                  />
                                  <h3>{item.name}</h3>
                                </li>
                              );
                            }
                          }
                        )}
                      </ul>
                    </div>
                  )}
                  {/* 자세 선택 */}
                </li>
                <li className="meta-li">
                  <div
                    className="accordion-select"
                    onClick={() =>
                      dialoguePopupInfo?.poseId !== '' && handleAccordin(4)
                    }>
                    {/* 선택 완료시 opacity: 0.6;으로 바뀜 (.meta-li strong.active / .meta-li span.active ) */}
                    <span
                      className={`${
                        String(dialoguePopupInfo?.voiceId) !== '' && 'active'
                      }`}>
                      STEP4
                    </span>
                    <strong
                      className={`${
                        String(dialoguePopupInfo?.voiceId) !== '' && 'active'
                      }`}>
                      {t('보이스 선택')}
                    </strong>

                    {/* 선택한 항목(없다가 선택하면 생김) */}
                    {dialoguePopupInfo?.voiceName && (
                      <div className="selectAfter-icon">
                        <h3>{dialoguePopupInfo?.voiceName}</h3>
                      </div>
                    )}
                    {String(dialoguePopupInfo?.voiceId) !== '' && (
                      <button className="popup__pencil__icon" />
                    )}
                  </div>
                  {/* 보이스 선택 */}
                  {accordin === 4 && (
                    <div className="accordin-menu style-scroll secondary-scroll">
                      <ul className="accordin-menu-voice">
                        {dialoguePopupInfo?.voicesArray?.map(
                          (item: any, idx: number) => (
                            <li
                              className={`voice-li ${
                                String(dialoguePopupInfo?.voiceId) ===
                                  String(item.voiceId) && 'active'
                              }`}
                              key={idx}
                              onClick={() => {
                                setDialoguePopupInfo({
                                  ...dialoguePopupInfo,
                                  voiceId: String(item.voiceId),
                                  voiceName: item.name,
                                  popupNum: 5,
                                });
                              }}>
                              <h3>{item.name}</h3>
                              {isPlaying ? (
                                <button
                                  className={
                                    String(audioActive) ===
                                      String(item.voiceId) && 'active'
                                  }
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleAudioClick(String(item.voiceId));
                                  }}
                                  disabled={
                                    isPlaying &&
                                    String(audioActive) !== String(item.voiceId)
                                  }
                                />
                              ) : (
                                <button
                                  onClick={e => {
                                    e.stopPropagation();
                                    handleAudioClick(String(item.voiceId));
                                  }}
                                  disabled={
                                    isPlaying &&
                                    String(audioActive) !== String(item.voiceId)
                                  }
                                />
                              )}
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  {/* 보이스 선택 */}
                </li>
              </ul>
              {/* 선택 완료시 opacity: 1;로 바뀜 (.meta-popup-complete.active) */}
              <div className="meta-popup-btn">
                {String(dialoguePopupInfo?.voiceId) !== '' ? (
                  <>
                    <button
                      className="meta-popup-complete meta-popup-prev"
                      style={{ right: '160px' }}
                      onClick={() => {
                        PreviousButton();
                      }}>
                      {t('이전')}
                    </button>
                    <button
                      className="meta-popup-complete active"
                      onClick={() => {
                        addMetahumanArray();
                      }}>
                      {step !== talkHumanNum
                        ? `${t('메타휴먼')}${step + 1} ${t('입력')}`
                        : t('스크립트 입력')}
                      <img src={iconArrowWhite} style={{ marginLeft: '4px' }} />
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="meta-popup-complete meta-popup-prev"
                      style={{ right: '160px' }}
                      onClick={() => {
                        PreviousButton();
                      }}>
                      이전
                    </button>
                    <button className="meta-popup-complete">
                      {step !== talkHumanNum
                        ? `${t('메타휴먼')}${step + 1} ${t('입력')}`
                        : t('스크립트 입력')}
                      <img src={iconArrowWhite} />
                    </button>
                  </>
                )}
              </div>
            </div>

            {/* 메타휴먼 선택 중 자물쇠 버튼 클릭시 팝업 */}
            {/* active */}
            {dialoguePopupInfo?.lockPopup && (
              <div className="lock-popup active">
                <button
                  className="close__btn close__gray__icon lockBtn"
                  onClick={() => {
                    setDialoguePopupInfo({
                      ...dialoguePopupInfo,
                      lockPopup: false,
                    });
                    setChangeTalk(0);
                  }}
                />
                <span>{t('선택하신 메타휴먼은 유료 메타휴먼입니다.')}</span>
                <p>{t('구매를 위해 스토어로 이동하시겠습니까?')}</p>
                <button className="storeGo-btn">{t('스토어 바로가기')}</button>
              </div>
            )}
            {/* 메타휴먼 선택 중 자물쇠 버튼 클릭시 팝업 */}
          </section>
        )}
        {step === talkHumanNum + 1 && (
          <section className={`meta-popupCont ${chatGPTModal && 'hidden'}`}>
            <div className="popup-top">
              <span>{t('스크립트 작성')}</span>
              <button
                className="close__btn close__gray__icon popupBtn"
                onClick={() => {
                  setTalkPopup(false);
                  setChangeTalk(0);
                }}
              />
            </div>
            <div className="meta-popup-inner">
              <div className="meta-area-script" ref={ref}>
                {scriptArray?.map((item, idx) => {
                  return (
                    <div
                      className={`script-box ${
                        item.direction !== 'left' && 'type-right'
                      }`}
                      key={idx}>
                      <div className="script-profile">
                        <span
                          className={`profile-name 
                          ${idx === 0 && 'bg-blue-300'} 
                          ${idx === 1 && 'bg-orange-300'} 
                          ${idx === 2 && 'bg-rose-500'} 
                          ${idx === 3 && 'bg-amber-600'} 
                          ${idx === 4 && 'bg-green-500'} 
                          ${idx === 5 && 'bg-zinc-600'} 
                          ${idx === 6 && 'bg-red-400'} 
                          ${idx === 7 && 'bg-amber-800'} 
                          ${idx === 8 && 'bg-yellow-400'} 
                          ${idx === 9 && 'bg-black'}`}>
                          <span className="txt-name">{item.metahumanName}</span>
                        </span>
                        <button
                          type="button"
                          className="delet-script"
                          onClick={() => deleteScript(idx)}>
                          <img
                            src={iconHoverDelet}
                            alt=""
                            width="12"
                            height="12"
                          />
                        </button>
                      </div>
                      <div className="area-script">{item.text}</div>
                      <button
                        onClick={() => {
                          if (!item.text) {
                            return;
                          }
                          setMetaInfo({
                            ...metaInfo,
                            modelId: scriptArray[idx].metahumanId,
                            metaName: scriptArray[idx].metahumanName,
                            voiceId: String(scriptArray[idx].voiceId),
                            scriptValue: item.text,
                            currentLang: projectLanguage,
                            idx: idx,
                          });
                          setTranslateModal(true);
                        }}
                        className="rounded-xl bg-[#8a73ff] p-1 mx-1 hover:bg-[#a795ff] duration-100 ease-out">
                        <TranslateIcon />
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="meta-area-wright">
                <form>
                  <textarea
                    className={`${isAllHumansScripted && 'border-zinc-200'}`}
                    disabled={isAllHumansScripted}
                    spellCheck="false"
                    maxLength={350 * talkHumanNum - textLength}
                    name=""
                    id=""
                    style={{
                      resize: 'none',
                      border: '1px solid #7b61ff',
                      borderRadius: '8px',
                      padding: '5px',
                    }}
                    placeholder={
                      isAllHumansScripted
                        ? t('모든 메타휴먼의 스크립트가 작성되어 있습니다.')
                        : t('스크립트를 입력해주세요.')
                    }
                    value={scriptValue.text}
                    onChange={e =>
                      setScriptValue({ ...scriptValue, text: e.target.value })
                    }
                  />

                  <div className="text-byte">
                    <p>{scriptValue?.text?.length + textLength || 0}</p>
                    {`/${talkHumanNum * 350}${t('자')}`}
                  </div>
                </form>
              </div>

              <section className="script-select-cont type-add-btn flex justify-between">
                {/* <!-- 추가 버튼 있을 시 type-add-btn 클래스 추가 --> */}
                <div className="flex items-center space-x-1">
                  <div
                    aria-disabled={isAllHumansScripted}
                    className={`script-select select-154 ${
                      metahumanSelect && 'active'
                    } aria-disabled:opacity-40 aria-disabled:cursor-not-allowed`}
                    onClick={() => {
                      if (isAllHumansScripted) return;
                      setMetahumanSelect(!metahumanSelect);
                    }}>
                    <p>
                      {scriptValue?.metahumanName ||
                        metahumanInfoArray[0]?.metaHumanName}
                    </p>
                    <img src={iconArrowScript} alt="" className="scriptArrow" />
                    <div className="script-wrap">
                      <ul>
                        {metahumanInfoArray?.map((item, idx) => {
                          if (
                            idx === 0 &&
                            scriptValue.metahumanName === undefined &&
                            scriptValue.metahumanId === undefined
                          ) {
                            setScriptValue({
                              ...scriptValue,
                              metahumanName: item?.metaHumanName,
                              metahumanId: item?.metaHumanId,
                              voiceId: String(item?.voiceId),
                              currentLang: projectLanguage,
                              postId: item?.postId,
                            });
                          }
                          return (
                            <li
                              onClick={() => {
                                setScriptValue({
                                  ...scriptValue,
                                  metahumanName: item?.metaHumanName,
                                  metahumanId: item?.metaHumanId,
                                  voiceId: String(item?.voiceId),
                                  currentLang: projectLanguage,
                                  postId: item?.postId,
                                });
                              }}
                              key={idx}>
                              <button type="button">
                                {item?.metaHumanName}
                              </button>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>

                  <div
                    aria-disabled={isAllHumansScripted}
                    className={`script-select select-154 ${
                      scriptSpeedSelect && 'active'
                    } aria-disabled:opacity-40 aria-disabled:cursor-not-allowed`}
                    onClick={() => {
                      if (isAllHumansScripted) return;
                      setScriptSpeedSelect(!scriptSpeedSelect);
                    }}>
                    <img src={iconSpeed} alt="" />
                    <p>{scriptSpeedValue?.name || t('속도 선택')}</p>
                    <img src={iconArrowScript} alt="" className="scriptArrow" />
                    <div className="script-wrap">
                      <ul>
                        {tempoArray.map(({ speed, tempo }, idx) => (
                          <li
                            key={`tempo-${idx}`}
                            onClick={() => {
                              setScriptSpeedValue({
                                speed: speed,
                                name: t(tempo),
                              });
                            }}>
                            <button type="button">{t(tempo)}</button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {isTalkAudioPlaying ? (
                    <button
                      className="p-2 rounded-full bg-[#7b61ff] hover:opacity-50 duration-100 ease-out"
                      onClick={handlePreviewClick}>
                      <StopIcon />
                    </button>
                  ) : (
                    <button
                      disabled={!scriptValue.text}
                      className={`p-2 rounded-full ${
                        previewLoading
                          ? 'bg-none'
                          : 'bg-[#ededed] hover:opacity-50 duration-100 ease-out disabled:opacity-40 disabled:cursor-not-allowed'
                      }`}
                      onClick={previewLoading ? null : handlePreviewClick}>
                      {previewLoading ? (
                        <Loader width={16} height={50} />
                      ) : (
                        <PlayIcon />
                      )}
                    </button>
                  )}
                  <button
                    disabled={isAllHumansScripted || !scriptValue.text}
                    type="button"
                    className="select-add-btn disabled:bg-grayBg/40 disabled:cursor-not-allowed disabled:text-gray-300 duration-150 ease-out"
                    onClick={addMetahuman}>
                    {!translateLoading ? t('추가하기') : t('번역 중...')}
                  </button>
                </div>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => setChatGPTModal(true)}
                    className="flex bg-white ring-[1.4px] ring-[#8d77fc] space-x-2 px-4 py-3 rounded-xl tracking-widest font-semibold text-white text-xs text- hover:bg-[#9f8cfd] duration-100 ease-out group">
                    <GptLogo className="w-4 h-4" />
                    <span className="group-hover:text-white text-[#8d77fc] font-semibold">
                      ChatGPT
                    </span>
                  </button>
                  <button
                    onClick={() => {
                      scriptValue.text && setScriptTranslateModal(true);
                    }}
                    className="flex px-4 py-3 bg-[#8d77fc] ring-[1.4px] ring-[#8d77fc] rounded-xl tracking-widest font-semibold text-white text-xs hover:bg-[#9f8cfd] duration-100 ease-out">
                    <i>
                      <TranslateIcon />
                    </i>
                    {t('번역')}
                  </button>
                </div>
              </section>
              <div className="meta-popup-btn type-more-btn">
                {/* <!-- 팝업 하단 버튼 2개 이상일시 type-more-btn 클래스 추가 --> */}
                <button
                  className="meta-popup-complete meta-popup-prev"
                  onClick={() => {
                    setTalkPopup(false);
                    setActive(1);
                  }}>
                  {t('이전')}
                </button>
                <button
                  disabled={!isAllHumansScripted}
                  className="meta-popup-complete active disabled:cursor-not-allowed disabled:bg-primary/50 duration-150 ease-out"
                  onClick={addTalkScript}>
                  {t('완료')}
                </button>
              </div>
            </div>
          </section>
        )}
      </div>
    </>
  );
};

export default MetahumanTalkPopup;
