import axios from 'axios';
import { ENV, isProd } from '../env';

// const PRODUCTION_URL = 'https://studio.ploonet.com';
// const DEVELOP_URL = 'http://175.45.201.80';
// export const DEVELOP_URL = 'http://220.76.67.205:11080';

export const enLink = ENV.REACT_APP_LANGUAGE === 'en' ? '/en' : '';
export const baseURL = isProd
  ? ENV.REACT_APP_RELSERVER_URL
  : ENV.REACT_APP_DEVSERVER_URL;
export const nameSpace = '/wp-json/ep/v1/console';

export default function initAxios() {
  const userInfo = JSON.parse(localStorage.getItem('loginInfo'));
  if (!userInfo) return;

  const localToken = userInfo.userToken;
  if (localToken) {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${localToken}`,
    };
  }
  return axios.create({ baseURL });
}
