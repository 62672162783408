export default function BinIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 8.125C7.5 7.77982 7.77982 7.5 8.125 7.5C8.47018 7.5 8.75 7.77982 8.75 8.125V14.375C8.75 14.7202 8.47018 15 8.125 15C7.77982 15 7.5 14.7202 7.5 14.375V8.125ZM11.25 8.125C11.25 7.77982 11.5298 7.5 11.875 7.5C12.2202 7.5 12.5 7.77982 12.5 8.125V14.375C12.5 14.7202 12.2202 15 11.875 15C11.5298 15 11.25 14.7202 11.25 14.375V8.125Z"
        className="fill-[#363D59] group-disabled:fill-[#363D59]/30"
      />
      <path
        d="M3.125 3.75C2.77982 3.75 2.5 4.02982 2.5 4.375C2.5 4.72018 2.77982 5 3.125 5H3.75V17.5C3.75 17.8315 3.8817 18.1495 4.11612 18.3839C4.35054 18.6183 4.66848 18.75 5 18.75H15C15.3315 18.75 15.6495 18.6183 15.8839 18.3839C16.1183 18.1495 16.25 17.8315 16.25 17.5V5H16.875C17.2202 5 17.5 4.72018 17.5 4.375C17.5 4.02982 17.2202 3.75 16.875 3.75H3.125ZM6 17.5C5.44772 17.5 5 17.0523 5 16.5V5H15V16.5C15 17.0523 14.5523 17.5 14 17.5H6ZM7.5 1.875C7.5 1.52982 7.77982 1.25 8.125 1.25H11.875C12.2202 1.25 12.5 1.52982 12.5 1.875C12.5 2.22018 12.2202 2.5 11.875 2.5H8.125C7.77982 2.5 7.5 2.22018 7.5 1.875Z"
        className="fill-[#363D59] group-disabled:fill-[#363D59]/30"
      />
    </svg>
  );
}
