import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectReqType } from '../../../types_new/console/service/types';
import OrderArrow from '../../svg/console/order-arrow';
import ProjectStatusListBox from './project-status-list-box';

type Props = {
  params: ProjectReqType;
  setParams: Dispatch<SetStateAction<ProjectReqType>>;
  loading: boolean;
  hasBar?: boolean;
};

export default function SearchFilter({
  params: { order, status },
  setParams,
  loading,
  hasBar,
}: Props) {
  const isFromRecent = order === 'DESC';
  const { t } = useTranslation();

  const changeOrder = () => {
    setParams(prev => ({
      ...prev,
      order: isFromRecent ? 'ASC' : 'DESC',
    }));
  };

  return (
    <div className="w-full flex justify-end items-center mr-2">
      <button
        className={`flex items-center space-x-2 p-2.5 mr-2 ${
          hasBar ? 'border border-gray-300 rounded-md' : ''
        }`}
        disabled={loading}
        onClick={changeOrder}>
        <span className="text-[13px] 2xl:text-sm">
          {t(isFromRecent ? '최근 작업순' : '예전 작업순')}
        </span>
        <i className="flex space-x-0.5">
          <OrderArrow isActive={isFromRecent} />
          <OrderArrow isSecond isActive={!isFromRecent} />
        </i>
      </button>
      {!hasBar && <div className="h-3 w-0.5 bg-grayBorder2 ml-6 mr-3" />}

      <ProjectStatusListBox
        status={status}
        setParams={setParams}
        loading={loading}
        className={`${hasBar && 'border rounded-md text-white'}`}
      />
    </div>
  );
}
