import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Rnd } from 'react-rnd';
import { useRecoilState } from 'recoil';
import '../../css/style.css';
import '../../locale/i18n';
import {
  additionalTarget,
  centiSecond,
  marker,
  markerPos,
  rails,
  pages as storedPages,
  ratio as storedRatio,
} from '../../store/pages';
import Strip from './Strip';

export const nameColumnWidth = 150;
export const stripHeight = 40;

export const PsuedoTimeline = () => {
  const { t } = useTranslation();
  const [cs, setCs] = useRecoilState(centiSecond);
  const [markerXPage, setMarkerXPage] = useRecoilState<any>(marker);
  const containerRef = useRef<HTMLDivElement>(null);
  const [TLW, setTLW] = useState(0);
  const [seconds, setSeconds] = useState(30);
  const [multiplier, setMultiplier] = useState(10);
  const [ratio, setRatio] = useRecoilState(storedRatio);
  const [brect, setBrect] = useState<DOMRect>();
  const [markerX, setMarkerX] = useRecoilState(markerPos);
  const centiSeconds = seconds * 100; // 30sec * 100 = 3000cs
  const csToPixelRatio = centiSeconds / TLW; // 3000 / 1900
  const pixelToCs = (pixel: number) => pixel * csToPixelRatio; // convert pixet to cs
  const [timelineHeight, setTimelineHeight] = useState(-1);
  const [railArray, setRailArray] = useRecoilState(rails);
  const [addTarget, setAddTarget] = useRecoilState(additionalTarget);
  const [pages, setPages] = useRecoilState(storedPages);

  const expandTimeline = useCallback(() => {
    if (containerRef.current.scrollLeft / TLW / (multiplier - 1) > 0.9) {
      setMultiplier(m => m + 1);
    }
  }, [TLW, multiplier]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (containerRef.current?.clientWidth) {
        const rect = containerRef.current.getBoundingClientRect();
        setBrect(() => rect);
      }
    };
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, [containerRef?.current?.clientWidth]);

  useEffect(() => {
    const elem = containerRef.current;
    if (!elem) return;
    elem.addEventListener('scroll', expandTimeline);
    return () => elem.removeEventListener('scroll', expandTimeline);
  }, [expandTimeline]);

  useEffect(() => {
    if (ratio === 0) return;
    setSeconds(Math.ceil((ratio / 100) * TLW));
  }, [TLW, ratio]);

  useEffect(() => {
    if (TLW > 0) {
      setRatio(r => {
        if (r > 0) return r;
        return centiSeconds / TLW;
      });
    }
  }, [TLW, centiSeconds]);

  useEffect(() => {
    if (containerRef.current?.clientWidth) {
      const rect = containerRef.current.getBoundingClientRect();
      setTLW(rect.width - nameColumnWidth);
      setBrect(rect);
    }
  }, [containerRef.current?.clientWidth]);

  const studioCanvas = document.querySelector<HTMLElement>('.studio__canvas');
  const studioHeader = document.querySelector<HTMLElement>('.studio__header');
  const studioTool = document.querySelector<HTMLElement>('.studio__tool');
  const timeBox = document.querySelector<HTMLElement>('.time__box');
  const root = document.querySelector<HTMLElement>('#root');

  if (timeBox) {
    const height =
      root.offsetHeight -
      (studioCanvas.offsetHeight +
        studioHeader.offsetHeight +
        studioTool.offsetHeight +
        timeBox.offsetHeight) +
      40;
    if (height !== timelineHeight) {
      setTimelineHeight(height);
    }
  }
  //! height 수정 필요

  const addRail = (type: string) => {
    if (type === 'metahuman') {
      setRailArray([...railArray, { type: 'metahuman' }]);
    } else if (type === 'clip') {
      setRailArray([...railArray, { type: 'clip' }]);
    } else {
      setRailArray([...railArray, { type: 'background' }]);
    }
  };

  const removeRail = (type: string, idx: number) => {
    const tmpPage = pages;
    const index = railArray.findIndex(item => item.type === type);
    const array = [...railArray];
    array.splice(index, 1);
    setRailArray(array);

    if (type === 'metahuman' && tmpPage.length !== 0) {
      const metahumanArray = [...tmpPage[0]?.segments[0]?.segment]?.filter(
        item => !(item.mediaType === 'metahuman' && item.railIndex === idx)
      );
      metahumanArray?.map(item => {
        if (item.railIndex > idx && item.mediaType === 'metahuman') {
          item.railIndex -= 1;
        }
      });
      setPages([
        {
          segments: [
            {
              segment: metahumanArray,
              title: tmpPage[0]?.segments[0]?.title,
              background: tmpPage[0]?.segments[0]?.background,
              image: tmpPage[0]?.segments[0]?.image,
            },
          ],
        },
      ]);
    } else if (type === 'clip' && tmpPage.length !== 0) {
      const segmentArray = [...tmpPage[0]?.segments[0]?.segment].filter(
        item =>
          !(
            (item.mediaType === 'audio' || item.mediaType === 'video') &&
            item.railIndex === idx
          )
      );
      const titleArray = [...tmpPage[0]?.segments[0]?.title].filter(
        item => item.railIndex !== idx
      );
      const imageArray = [...tmpPage[0]?.segments[0]?.image].filter(
        item => item.railIndex !== idx
      );
      segmentArray.map(item => {
        if (
          item.railIndex > idx &&
          (item.mediaType === 'audio' || item.mediaType === 'video')
        ) {
          item.railIndex -= 1;
        }
      });
      titleArray.map(item => {
        if (item.railIndex > idx) {
          item.railIndex -= 1;
        }
      });
      imageArray.map(item => {
        if (item.railIndex > idx) {
          item.railIndex -= 1;
        }
      });
      setPages([
        {
          segments: [
            {
              segment: segmentArray,
              title: titleArray,
              background: tmpPage[0]?.segments[0]?.background,
              image: imageArray,
            },
          ],
        },
      ]);
    } else if (type === 'background' && tmpPage.length !== 0) {
      const bgaudioArray = [...tmpPage[0]?.segments[0]?.segment].filter(
        item => !(item.mediaType === 'bgaudio' && item.railIndex === idx)
      );
      bgaudioArray.map(item => {
        if (item.railIndex > idx && item.mediaType === 'bgaudio') {
          item.railIndex -= 1;
        }
      });
      const backgroundArray = [...tmpPage[0]?.segments[0]?.background].filter(
        item => item.railIndex !== idx
      );
      backgroundArray.map(item => {
        if (item.railIndex > idx) {
          item.railIndex -= 1;
        }
      });
      setPages([
        {
          segments: [
            {
              segment: bgaudioArray,
              title: tmpPage[0]?.segments[0]?.title,
              background: backgroundArray,
              image: tmpPage[0]?.segments[0]?.image,
            },
          ],
        },
      ]);
    }
  };

  const [markervisible, setMarkervisible] = useState(true);

  const handleMarkervisible = e => {
    if (e.target.scrollLeft === 0) {
      setMarkervisible(true);
    } else if (e.target.scrollLeft > markerX) {
      setMarkervisible(false);
    } else if (e.target.scrollLeft < markerX) {
      setMarkervisible(true);
    }
  };

  const [markerView, setMarkerView] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMarkerView(true);
    }, 400);
  }, []);

  useEffect(() => {
    if (markerXPage?.from === 'page') {
      setMarkerX(Math.floor(markerXPage.cs / ratio));
      containerRef.current.scrollLeft = markerXPage.cs / ratio - 500;
      setCs(pixelToCs(markerXPage.cs / ratio));
      setAddTarget({ insertT: markerXPage.cs, name: 'marker' });
      setTimeout(() => {
        setMarkervisible(true);
      }, 100);
    }
  }, [markerXPage]);

  return (
    <div
      className="w-full z-10 timeline-area"
      ref={containerRef}
      onScroll={handleMarkervisible}>
      <div
        className="top-0 flex"
        style={{
          zIndex: 100000,
          width: TLW * multiplier + nameColumnWidth,
          position: 'sticky',
        }}>
        <div
          className="sticky left-0 z-20 text-black bg-white"
          style={{
            width: '136px',
            height: stripHeight,
            borderTop: '1px solid #e9e9e9',
            borderBottom: '1px solid #e9e9e9',
          }}>
          <div className="time">
            <span>{t('타임라인')}</span>
          </div>
        </div>

        <div
          className="flex justify-between flex-1 h-10 text-center text-black"
          style={{
            borderTop: '1px solid #e9e9e9',
            borderBottom: '1px solid #e9e9e9',
            backgroundColor: '#efefef',
          }}
          onMouseUp={e => {
            setMarkerX(e.nativeEvent.offsetX);
            setCs(pixelToCs(e.nativeEvent.offsetX));
            handleMarkervisible(e);
            setAddTarget({
              insertT: pixelToCs(e.nativeEvent.offsetX),
              name: 'marker',
            });
          }}>
          {markerView && (
            <Rnd
              dragAxis="x"
              enableResizing={false}
              onDrag={(e, { x }) => {
                setCs(pixelToCs(x));
                handleMarkervisible(e);
              }}
              onDragStop={(e, { x }) => {
                e.stopPropagation();
                setMarkerX(() => x);

                handleMarkervisible(e);
                setAddTarget({
                  insertT: pixelToCs(x),
                  name: 'marker',
                });
              }}
              className="marker absolute bg-[#7b61ff] hover:cursor-ew-resize"
              style={{
                zIndex: 0,
                visibility: markervisible ? 'visible' : 'hidden',
              }}
              bounds="parent"
              default={{
                width: 2,
                height: containerRef.current.clientHeight,
                x: 0,
                y: 40,
              }}
              position={{
                x: markerX,
                y: 40,
              }}
              size={{
                width: '2px',
                height: containerRef.current.clientHeight,
              }}>
              <div className="marker__time">{timeCal(cs)}</div>
            </Rnd>
          )}

          {[...Array(seconds * multiplier).keys()].map(i => {
            return (
              <span
                className="pointer-events-none"
                key={i}
                style={{
                  width: '2px',
                  height: '10px',
                  backgroundColor: '#d1d1d1',
                  display: 'block',
                  position: 'relative',
                }}>
                {i % 5 === 0 && i !== 0 && (
                  <div className="time__progress">
                    <span>
                      <em>{timeProgress(i)}</em>
                    </span>
                  </div>
                )}
              </span>
            );
          })}
        </div>
      </div>

      <Strip
        type="metahuman"
        title={t('메타휴먼')}
        TLW={TLW}
        multiplier={multiplier}
        nameColumnWidth={nameColumnWidth}
        iconName="metahuman"
        railArray={railArray}
        addRail={addRail}
        removeRail={removeRail}
      />
      <Strip
        type="clip"
        title={t('꾸미기')}
        TLW={TLW}
        multiplier={multiplier}
        nameColumnWidth={nameColumnWidth}
        iconName="clip"
        railArray={railArray}
        addRail={addRail}
        removeRail={removeRail}
      />
      <Strip
        type="background"
        title={t('배경')}
        TLW={TLW}
        multiplier={multiplier}
        nameColumnWidth={nameColumnWidth}
        iconName="background"
        railArray={railArray}
        addRail={addRail}
        removeRail={removeRail}
      />
    </div>
  );
};

function timeCal(cs: number) {
  const minute = (cs / (100 * 60)) % 60;
  const second = (cs / 100) % 60;
  const t = String(Math.ceil(cs));
  const minuteCut = `0${Math.floor(minute)}`.slice(-2);
  const secondCut = `0${Math.floor(second)}`.slice(-2);
  return `${minuteCut}:${secondCut}.${t.padStart(2, '0').slice(-2)}`;
}
function timeProgress(cs: number) {
  const time = cs * 100;
  const minute = (time / (100 * 60)) % 60;
  const second = (time / 100) % 60;
  const minuteCut = `${Math.floor(minute)}`.slice(-2);
  const secondCut = `0${Math.floor(second)}`.slice(-2);
  return `${minuteCut}:${secondCut}`;
}
