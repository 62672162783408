import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { deleteTemplate } from '../../../../networks/template.service';
import { useModalAtom } from '../../../../recoil/atoms/store';
import { TemplateDataType } from '../../../../types_new/main/resource/types';
import { getValueByKey } from '../../../../utils/common';
import ReasonPop from './reason-pop';

const tableHeadList = [
  '번호',
  '영상 템플릿 제목',
  '크레딧',
  '신청일시',
  '승인일시',
  '상태',
  '다운로드',
  '취소/삭제',
];
const SKELETON_TD_CLASS = 'h-2 rounded-full bg-gray-400 mx-auto animate-pulse';

type Props = {
  templatePublishData: TemplateDataType[];
  loading: boolean;
  loadData: () => void;
};

export default function TemplatePublishingTable({
  templatePublishData,
  loading,
  loadData,
}: Props) {
  const displayStatus = (status: TemplateDataType['postStatus']) => {
    switch (status) {
      case 'apply':
        return t('진행중');
      case 'reject':
        return t('반려');
      case 'agree':
        return t('승인');
      case 'applyCancel':
        return t('취소됨');
      case 'deleted':
        return t('삭제됨');
      default:
        return '';
    }
  };

  const setUseModal = useSetRecoilState(useModalAtom);
  const { t } = useTranslation();

  const openCancelModal = (postId: string) => {
    setUseModal({
      isOpen: true,
      type: 'confirm',
      data: {
        title: '영상 배포를 취소 신청하시겠습니까?',
        message: '취소 후, 복원할 수 없습니다.',
        handleOnClick: () => requestDelete(postId),
      },
    });
  };

  const requestDelete = (postId: string) => {
    setUseModal(prev => ({
      ...prev,
      data: { ...prev.data, loading: true },
    }));

    deleteTemplate(postId).then(handleSuccess).catch(alertUser);

    function handleSuccess() {
      setUseModal(prev => ({
        ...prev,
        isOpen: false,
      }));
      loadData();
    }

    function alertUser() {
      setUseModal(prev => ({
        ...prev,
        type: 'inform',
        data: { title: '삭제 실패', message: '잠시 후 다시 시도해주세요.' },
      }));
    }
  };

  return (
    <table className="w-full text-left mt-12 max-sm:hidden">
      <thead className="text-center bg-[#07102c]/5">
        <tr>
          {tableHeadList.map((tableHead, idx) => (
            <th
              key={`tableHead-${idx}`}
              scope="col"
              className="py-4 whitespace-nowrap font-bold text-sm text-[#0B1733]">
              {t(`${tableHead}`)}
            </th>
          ))}
        </tr>
      </thead>
      {loading ? (
        <Skeleton />
      ) : (
        <tbody className="text-center">
          {templatePublishData.map(
            ({ postTitle, metaData, registerDate, postId, template }, idx) => {
              const status = getValueByKey(metaData, 'applyStatus');
              const rejectReason = getValueByKey(metaData, 'rejectReason');
              const credit = getValueByKey(metaData, 'credit');
              const replyDate = getValueByKey(metaData, 'replyDate');
              return (
                <tr
                  key={postId}
                  className="border-b border-[#D4DEEA] font-medium">
                  <td className="py-6">{idx + 1}</td>
                  <td className="py-6">{postTitle}</td>
                  <td className="py-6">{credit}</td>
                  <td className="py-6">
                    {moment(registerDate).format('YYYY-MM-DD  HH:mm')}
                  </td>
                  <td className="py-6">
                    {moment(replyDate).format('YYYY-MM-DD  HH:mm')}
                  </td>
                  <td className="text-sm py-6 font-semibold flex justify-center items-center space-x-1 mt-1">
                    <span
                      className={
                        status === 'agree'
                          ? 'text-primary'
                          : status === 'apply'
                          ? 'text-[#ED7C30]'
                          : 'text-black'
                      }>
                      {displayStatus(status)}
                    </span>
                    {status === 'reject' && <ReasonPop reason={rejectReason} />}
                  </td>

                  <td className="py-6">
                    {status === 'agree' && `${template?.buyCnt || '0'}`}
                  </td>
                  <td className="py-6">
                    {status === 'deleted' ? (
                      ''
                    ) : (
                      <>
                        {status === 'reject' ? null : (
                          <button
                            onClick={() => openCancelModal(postId)}
                            className={`bg-[#222] hover:bg-opacity-90 duration-150 ${
                              status === 'applyCancel' && 'hidden'
                            } text-white px-4 py-1 text-sm rounded-md cursor-pointer`}>
                            {status === 'agree' ? '삭제' : '취소'}
                          </button>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      )}
    </table>
  );
}

function Skeleton() {
  const array = [1, 2, 3, 4, 5];

  return (
    <tbody className="text-center">
      {array.map(item => (
        <tr
          key={`template-publish-skeleton-${item}`}
          className="border-b border-[#D4DEEA] font-medium">
          <td className="py-6">{item}</td>
          <td className="py-6">
            <div className={`w-24 ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="py-6">
            <div className={`w-14 ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="py-6">
            <div className={`w-18 ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="text-sm py-6 font-semibold">
            <div className={`w-8  ${SKELETON_TD_CLASS}`} />
          </td>
        </tr>
      ))}
    </tbody>
  );
}
