import DetailIcon from '../../svg/console/detail-icon';

const TD_CLASS = 'text-[#1A2032] text-center w-[12%]';

const arr = [1, 2, 3, 4, 5, 6];

export default function TableSkeleton() {
  return (
    <>
      {arr.map((num) => (
        <div
          key={num}
          className="flex items-center border-b border-grayBorder2"
        >
          <div className="py-5 flex w-1/3">
            <div className="flex items-center space-x-2.5">
              <div className="flex items-center">
                <div className="w-[70px] h-[70px] flex justify-center items-center rounded-[10px] bg-gradient-to-br from-[#9D9BA0] to-[#BFBFBF]">
                  <svg
                    className="w-9 h-9 text-gray-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
                  </svg>
                </div>
              </div>
              <div className="flex flex-col space-y-4">
                <div className="h-2 w-40 rounded-md bg-gray-500 animate-pulse" />
                <div className="h-1.5 w-20 rounded-md bg-gray-300 animate-pulse" />
              </div>
            </div>
          </div>
          <div className={`${TD_CLASS} font-medium`}>
            <div className="h-1.5 w-10 rounded-md bg-gray-400 mx-auto animate-pulse" />
          </div>
          <div className={`${TD_CLASS} font-medium`}>
            <div className="h-1.5 w-6 rounded-md bg-gray-400 mx-auto animate-pulse" />
          </div>
          <div className={`${TD_CLASS} font-medium`}>
            <div className="h-1.5 w-20 rounded-md bg-gray-400 mx-auto animate-pulse" />
          </div>
          <div className={`${TD_CLASS} font-medium`}>-</div>
          <div className={TD_CLASS}>
            <div className="h-1.5 w-10 rounded-md bg-gray-400 mx-auto animate-pulse" />
          </div>
          <div className={`${TD_CLASS} relative`}>
            <button
              disabled
              className="focus:outline-none ring-offset-0 ring-0 border border-[#E9E9E9] rounded-md"
            >
              <DetailIcon />
            </button>
          </div>
        </div>
      ))}
    </>
  );
}
