export default function DownloadIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00004 10V2M8.00004 10L5.33337 7.33333M8.00004 10L10.6667 7.33333M1.33337 11.3333L1.74737 12.99C1.81948 13.2785 1.98592 13.5345 2.22026 13.7175C2.4546 13.9005 2.74338 14 3.04071 14H12.9594C13.2567 14 13.5455 13.9005 13.7798 13.7175C14.0142 13.5345 14.1806 13.2785 14.2527 12.99L14.6667 11.3333"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
