import { useEffect, useState } from 'react';
import { serviceUsageFilterArray } from '../../../../arrays';
import { getTranslationReqList } from '../../../../networks/translate.service';
import Pagination from '../../../common/Pagination';
import InfoFilterSection from '../info-filter-section';
import NoListCard from '../no-list-card';
import MobileServiceUsageCard from './mobile-service-usage-card';
import ServiceUsageTable from './service-usage-table';

export default function ServiceUsageInfo() {
  const [filter, setFilter] = useState(serviceUsageFilterArray[0]);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const [usageData, setUsageData] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getTranslationReqList({ paged, postsPerPage: 20 })
      .then(({ body: { total, posts } }) => {
        setTotal(total);
        setUsageData(posts);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [paged]);

  const onPageChange = (page: number) => {
    setPaged(page);
    setParentPage(page);
  };

  return (
    <section className="text-darkSecondary max-w-[1278px] w-full px-5">
      <InfoFilterSection
        total={total}
        filterArray={serviceUsageFilterArray}
        setFilter={setFilter}
        filter={filter}
      />
      {loading ? (
        <MobileCardSkeleton />
      ) : (
        usageData?.map((data, idx) => (
          <MobileServiceUsageCard
            key={`mobileUsage-${idx}`}
            data={data}
            loading={loading}
          />
        ))
      )}

      <ServiceUsageTable serviceUsageData={usageData} loading={loading} />
      {usageData.length ? (
        <Pagination
          itemsPerPage={20}
          currentPage={paged}
          totalItems={total}
          onPageChange={onPageChange}
          parentPage={parentPage}
        />
      ) : (
        !loading && <NoListCard text="서비스 사용" />
      )}
    </section>
  );
}

function MobileCardSkeleton() {
  const dataList = [
    '서비스명',
    '프로젝트명',
    '크레딧 내역',
    '요청일시',
    '사용일시',
    '진행상태',
  ];

  return (
    <div className="max-sm:flex hidden my-4 rounded-lg bg-white text-darkSecondary py-4 px-5 space-y-4 items-center justify-between">
      <ul className="flex flex-col text-sm space-y-3">
        {dataList.map((list, idx) => (
          <li key={`list-${idx}`} className="font-normal text-sm">
            {list}
          </li>
        ))}
      </ul>
      <ul className="h-full flex flex-col space-y-6 text-right">
        <li>
          <div className="h-2 w-8 rounded-full bg-gray-400 animate-pulse" />
        </li>
        <li>
          <div className="h-2 w-8 rounded-full bg-gray-400 animate-pulse" />
        </li>
        <li>
          <div className="h-2 w-8 rounded-full bg-gray-400 animate-pulse" />
        </li>
        <li>
          <div className="h-2 w-8 rounded-full bg-gray-400 animate-pulse" />
        </li>
        <li>
          <div className="h-2 w-8 rounded-full bg-gray-400 animate-pulse" />
        </li>
        <li>
          <div className="h-2 w-8 rounded-full bg-gray-400 animate-pulse" />
        </li>
      </ul>
    </div>
  );
}
