const qualityArray = [
  {
    quality: '720p',
    definition: 'HD',
  },
  {
    quality: '1080p',
    definition: 'Full HD',
  },
];

export default function QualityPopup({
  t,
  setExtensionShow,
  setExportPopup,
  exportPopup,
  PopupSelectBtn,
  setPopup,
}) {
  return (
    <div className="preview-popup">
      <strong>{t('화질 선택')}</strong>
      <button
        className="close__btn close__gray__icon popupClose"
        onClick={() => {
          setPopup(0);
          setExportPopup({ select: false, extension: '', quality: '' });
          setExtensionShow(false);
        }}
      />
      <div className="popup-select-cont">
        <button
          onClick={() =>
            setExportPopup({ ...exportPopup, select: !exportPopup.select })
          }>
          {exportPopup.quality || t('화질 선택')}
          <img
            src={PopupSelectBtn}
            alt=""
            className="popup-arrow-icon"
            style={{ transform: exportPopup.select && 'rotate(180deg)' }}
          />
        </button>
        {exportPopup.select && (
          <div className="popup-select-box">
            <ul>
              {qualityArray.map(({ quality, definition }, idx) => (
                <li
                  key={`list-${idx}`}
                  onClick={() => {
                    setExportPopup({
                      ...exportPopup,
                      quality,
                      select: !exportPopup.select,
                    });
                  }}>
                  <span>{quality}</span>
                  <div className="selectHD">{definition}</div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <div className="popup-btn-cont">
        <button
          className="popupBtnLeft bg-[#bebebe] hover:bg-[#cecece] text-black"
          onClick={() => setPopup(4)}>
          {t('이전')}
        </button>
        <button
          className={`popupBtnRight ${
            exportPopup.quality ? 'opacity-100' : '!cursor-not-allowed'
          } hover:!bg-[#917bff]`}
          disabled={!exportPopup.quality}
          onClick={() => {
            setPopup(6);
          }}>
          {t('다음')}
        </button>
      </div>
    </div>
  );
}
