import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { numberRegex } from '../../../constants/regex';
import { ProductionInquiryFormType as FormType } from '../../../types_new/main/landing/types';
import CheckedBox from '../../svg/checked-box';
import UncheckedBox from '../../svg/unchecked-box';
import ProductionInquirySelectBox from './production-inquiry-select-box';

const INPUT_SYTLE =
  '!bg-transparent block truncate w-full rounded-md py-2.5 sm:py-3 2xl:py-4 px-4 2xl:px-5 text-left border border-grayBorder text-[#d5d5d5] placeholder:text-[#d5d5d5] focus:outline-0 text-sm 2xl:text-base 2xl:leading-7';

type Props = {
  setForm: Dispatch<SetStateAction<FormType>>;
  form: FormType;
  handleOnChange: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  setAgree: Dispatch<SetStateAction<boolean>>;
  setModal: Dispatch<SetStateAction<boolean>>;
  agree: boolean;
  loading: boolean;
  onSubmit: (e: any) => void;
};

export default function ProductionInquiryForm({
  setForm,
  form,
  handleOnChange,
  setAgree,
  agree,
  loading,
  onSubmit,
  setModal,
}: Props) {
  const { t } = useTranslation();
  const isDisabled = () => {
    const { name, email, mobile, type, content } = form;
    return !!(
      !name ||
      !email ||
      !mobile ||
      !type ||
      !content ||
      !agree ||
      loading
    );
  };

  return (
    <div className="mt-5 sm:mt-8 md:mt-10 xl:mt-14 2xl:mt-16 flex max-md:flex-col md:space-x-4 lg:space-x-20 xl:space-x-32 2xl:space-x-44">
      <div className="flex flex-col space-y-4 md:space-y-8 xl:space-y-10 max-md:text-center">
        <div className="flex flex-col space-y-1 md:space-y-2">
          <span className="text-sm sm:text-base font-bold text-white/50">
            E-MAIL
          </span>
          <span className="text-sm sm:text-base 2xl:text-lg">
            henry.lim@saltlux.com
          </span>
        </div>
        <div className="flex flex-col space-y-1 md:space-y-2">
          <span className="text-sm sm:text-base font-bold text-white/50">
            CONTACT
          </span>
          <span className="text-sm sm:text-base 2xl:text-lg">1533-6116</span>
        </div>
      </div>
      <form
        className="w-full px-5 max-md:mt-5 md:w-[540px] lg:w-[620px] xl:w-[680px] 2xl:w-[720px]"
        action="submit">
        <ProductionInquirySelectBox setForm={setForm} form={form} />
        <input
          maxLength={20}
          className={`${INPUT_SYTLE} mt-5 sm:mt-6 2xl:mt-8`}
          value={form.name}
          spellCheck={false}
          type="text"
          autoComplete="off"
          name="name"
          onChange={handleOnChange}
          placeholder={t('의뢰하시는 담당자 성함')}
        />
        <span className="block mt-1 mb-2 text-xs 2xl:text-sm text-white/50">
          {t('저희가 연락드릴 담당자의 성함을 기재해주세요.')}
        </span>
        <input
          maxLength={60}
          className={INPUT_SYTLE}
          value={form.email}
          spellCheck={false}
          type="email"
          name="email"
          onChange={handleOnChange}
          autoComplete="off"
          placeholder={t('이메일')}
        />
        <input
          maxLength={20}
          className={`${INPUT_SYTLE} mt-2`}
          spellCheck={false}
          type="tel"
          name="mobile"
          onChange={e => {
            if (!numberRegex.test(e.target.value)) return;
            handleOnChange(e);
          }}
          autoComplete="off"
          value={form.mobile}
          placeholder={t('휴대폰 번호')}
        />
        <textarea
          name="content"
          maxLength={1000}
          spellCheck={false}
          value={form.content}
          onChange={handleOnChange}
          placeholder={t(
            '의뢰하실 영상내용\n아래 예시를 참고하셔서 이 중 편하신 내용을 기재해주세요.\n고객님의 비즈니스 유형: 관공서/일반기업/소상공인 등\n영상 장르: 홍보,프로모션/IR/프레젠테이션 등\n내용: 제작하시고자 하는 영상의 내용을 간단히 기재해주세요.'
          )}
          className="border border-grayBorder rounded-md w-full leading-6 2xl:leading-7 text-sm 2xl:text-base resize-none mt-6 lg:mt-8 2xl:mt-10 bg-transparent h-52 lg:h-56 2xl:h-72 py-2.5 sm:py-3 2xl:py-4 px-4 2xl:px-5 placeholder:text-[#d5d5d5] focus:outline-none"></textarea>
        <input
          maxLength={200}
          className={INPUT_SYTLE}
          value={form.linkurl}
          name="linkurl"
          autoComplete="off"
          onChange={handleOnChange}
          spellCheck={false}
          type="text"
          placeholder={t('참고 영상')}
        />
        <span className="mt-1 mb-2 text-xs 2xl:text-sm text-white/50">
          {t(
            '참고할만한 영상이나 원하시는 영상과 유사한 영상의 링크 주소를 적어주시면 더 정확히 안내해드리겠습니다.'
          )}
        </span>
        <div className="w-full flex max-md:flex-col max-md:space-y-8 items-center justify-between mt-8">
          <div className="flex items-center max-md:w-full max-md:justify-between md:space-x-2">
            <button
              onClick={e => {
                e.preventDefault();
                setAgree(prev => !prev);
              }}
              className="flex items-center border-0">
              <i className="mr-[10px]">
                {agree ? <CheckedBox /> : <UncheckedBox />}
              </i>
              <span className="max-md:text-sm font-bold text-white whitespace-nowrap">
                {t('개인정보수집/이용동의')}
              </span>
            </button>
            <span
              onClick={() => setModal(true)}
              className="underline cursor-pointer border-0 w-fit text-sm text-white whitespace-nowrap font-normal">
              {t('상세보기')}
            </span>
          </div>

          <button
            onClick={onSubmit}
            disabled={isDisabled()}
            className={`w-fit bg-[#7663f6] py-3 px-6 2xl:py-4 2xl:px-8 font-semibold 2xl:text-lg rounded-md hover:bg-opacity-80 duration-150 ease-out ${
              isDisabled() &&
              'bg-gray-400 border-gray-400 cursor-not-allowed hover:bg-opacity-100'
            }`}>
            {t('문의보내기')}
          </button>
        </div>
      </form>
    </div>
  );
}
