import { useRecoilValue } from 'recoil';
import { pages as storedPages } from '../store/pages';

export default function useEntireDuration() {
  const pages = useRecoilValue(storedPages);
  const commonGroup = pages[0]?.segments[0];
  const segments: any = commonGroup?.segment || [];
  const backgroundSegments: any = commonGroup?.background || [];
  const imageSegments: any = commonGroup?.image || [];
  const titleSegments: any = commonGroup?.title || [];

  function getEndTime(seg: any) {
    if (!seg) return;
    return seg.insertTime + seg.duration;
  }

  const allSegments = [
    ...segments,
    ...titleSegments,
    ...backgroundSegments,
    ...imageSegments,
  ];

  const lastSegment = allSegments.reduce((acc, curr) => {
    const accEndTime = getEndTime(acc);
    const currEndTime = getEndTime(curr);

    return currEndTime > accEndTime ? curr : acc;
  }, allSegments[0]);

  return getEndTime(lastSegment);
}
