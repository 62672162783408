import { validatePostOpenAIChatCompletionRequest } from '../types/lib/api/openAI';
import initAxios, { nameSpace } from './defaultClient';

/*
  NOTE: Backend (PHP) does not throw Exception/Error and send status code in string
  even when it's an error.
*/
type PostGptChatCompletionResponse = {
  data: {
    code: string;
    body: {
      result: string;
    };
  };
};

export const getGptResponse = async (prompt: string) => {
  const validatedPrompt = await validatePostOpenAIChatCompletionRequest(prompt);
  const axios = initAxios();

  const { data } = (await axios.post(`${nameSpace}/projects/chatgpt`, {
    content: validatedPrompt,
  })) as PostGptChatCompletionResponse;

  const resMessage = data.body.result;

  // FIXME: Throw error properly from backend
  if (data.code !== '200' || resMessage.length > 350) {
    throw new Error('처리 중 오류가 발생했습니다.');
  }

  return resMessage;
};
