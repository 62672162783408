import moment from 'moment';
import { findValueByKey } from '../../../../utils/common';

const dataList = [
  '서비스명',
  '프로젝트명',
  '크레딧 내역',
  '요청일시',
  '사용일시',
  '진행상태',
];

type Props = {
  data: any;
  loading: boolean;
};

export default function MobileServiceUsageCard({ data, loading }: Props) {
  const getTextColor = (status: string) => {
    switch (status) {
      case 'complete':
        return 'text-darkSecondary';
      case 'progress':
        return 'text-[#FF9B70]';
      case 'request':
        return 'text-primary';
      default:
        return 'text-darkSecondary';
    }
  };

  const displayStatus = (status: 'complete' | 'progress' | 'request') => {
    switch (status) {
      case 'complete':
        return '완료';
      case 'progress':
        return '진행중';
      case 'request':
        return '요청';
      default:
        return '요청';
    }
  };

  return (
    <>
      <div className="max-sm:flex hidden my-4 rounded-lg bg-white text-darkSecondary py-4 px-5 space-y-4 items-center justify-between">
        <ul className="flex flex-col text-sm space-y-3">
          {dataList.map((list, idx) => (
            <li key={`list-${idx}`} className="font-normal text-sm">
              {list}
            </li>
          ))}
        </ul>
        {loading ? (
          <Skeleton />
        ) : (
          <ul className="flex flex-col text-sm font-medium space-y-3 text-right">
            <li>{data.serviceName}</li>
            <li>{findValueByKey(data.metaData, 'projectName')}</li>
            <li>{`-${findValueByKey(data.metaData, 'credit')}CR`}</li>
            <li>{moment(data.requestDate).format('YYYY-MM-DD HH:mm')}</li>
            <li>{moment(data.translationDate).format('YYYY-MM-DD HH:mm')}</li>
            <li
              className={`${getTextColor(
                findValueByKey(data.metaData, 'translationStatus')
              )}`}>
              {displayStatus(
                findValueByKey(data.metaData, 'translationStatus')
              )}
            </li>
          </ul>
        )}
      </div>
    </>
  );
}

function Skeleton() {
  return (
    <ul className="flex flex-col text-sm font-medium space-y-3 text-right">
      <li>
        <div className="h-1 w-8 rounded-full bg-gray-400 animate-pulse" />
      </li>
      <li>
        <div className="h-1 w-8 rounded-full bg-gray-400 animate-pulse" />
      </li>
      <li>
        <div className="h-1 w-8 rounded-full bg-gray-400 animate-pulse" />
      </li>
      <li>
        <div className="h-1 w-8 rounded-full bg-gray-400 animate-pulse" />
      </li>
      <li>
        <div className="h-1 w-8 rounded-full bg-gray-400 animate-pulse" />
      </li>
      <li>
        <div className="h-1 w-8 rounded-full bg-gray-400 animate-pulse" />
      </li>
    </ul>
  );
}
