/* eslint-disable */
import { useState } from 'react';
import Controls from '../components/controls/Controls';
import Pages from '../components/pages/Pages';
import Preview from '../components/preview/Preview';
import { PsuedoTimeline } from '../components/timeline/PsuedoTimeline';
import Toolbar from '../components/timeline/Toolbar';
import '../css/reset.css';
import '../css/style.css';

export default function MainContainer({ controls, setControls }) {
  const [metahuman, setMetahuman] = useState(false);
  const [transform, setTransform] = useState(false);

  return (
    <main className="flex">
      <Pages setMetahuman={setMetahuman} setTransform={setTransform} />
      <div className="studio__main">
        <Preview />
        <Toolbar />
        <PsuedoTimeline />
      </div>
      <aside className="style__tab">
        <Controls
          metahuman={metahuman}
          setMetahuman={setMetahuman}
          transform={transform}
          setTransform={setTransform}
          controls={controls}
          setControls={setControls}
        />
      </aside>
    </main>
  );
}
