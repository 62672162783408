/* eslint-disable */

type props = { targetElements: IPreviewElement[] };
import StudioCanvasDefalt from '../../images/studio-canvas-size/studio_canvas_default.png';
import { IPreviewElement } from '../../store/pages/types';

import '../../css/reset.css';
import '../../css/style.css';
import { changeHost } from '../../utils/common';

const BackgroundLayer = ({ backgroundSource, canvasWidth }: any) => {
  return (
    <div
      className="canvas__size"
      style={{
        width: `${canvasWidth}px`,
        height: '576px',
        margin: '0 auto',
      }}>
      {!backgroundSource && (
        <img
          src={StudioCanvasDefalt}
          alt=""
          style={{
            width: `${canvasWidth}px`,
            height: '576px',
            margin: '0 auto',
          }}
        />
      )}
      {backgroundSource && backgroundSource.type === 'image' && (
        <div
          className="preview__background_image"
          style={{
            backgroundImage: `url(${changeHost(
              backgroundSource.previewImage
            )})`,
            backgroundSize: 'contain',
            backgroundColor: '#222',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}
      {backgroundSource && backgroundSource.type === 'color' && (
        <div
          className=""
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: `#${backgroundSource.color}`,

            // calc(70vh * 16 / 9)
          }}
        />
      )}
      {backgroundSource && backgroundSource.type === 'video' && (
        <div
          className="preview__background_image"
          style={{
            backgroundImage: `url(${changeHost(
              backgroundSource.previewImage
            )})`,
            backgroundSize: 'contain',
            backgroundColor: '#222',
            backgroundRepeat: 'no-repeat',
          }}
        />
      )}
    </div>
  );
};

export default BackgroundLayer;
