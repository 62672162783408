export default function LowIndexIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none">
      <rect
        x="2.25"
        y="2.25"
        width="9.5"
        height="9.5"
        rx="1.75"
        stroke="white"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V8C0 9.10457 0.89543 10 2 10H2.24058C2.08632 9.67527 2 9.312 2 8.92857V4.5C2 3.11929 3.11929 2 4.5 2H8.92857C9.312 2 9.67527 2.08632 10 2.24058V2C10 0.895431 9.10457 0 8 0H2Z"
        fill="white"
      />
    </svg>
  );
}
