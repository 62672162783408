/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import '../../css/reset.css';
import '../../css/style.css';
import IconCoin from '../../images/icons/studio-side/icon-coin.svg';
import IconTranslate from '../../images/icons/studio-side/icon-translate.png';
import IconDownload from '../../images/icons/studio-side/icon_download.svg';
import EditChange from '../../images/metahuman/icon-change.svg';
import translatePrice from '../../json/translatePrice.json';
import '../../locale/i18n';
import { ICreditMinusItem } from '../../networks/dto/IPaySystemDto';
import { IAddPayUsageReq } from '../../networks/dto/IProjectDto';
import {
  getLeftCredit,
  payMinusCredit,
} from '../../networks/paysystem.service';
import { addPayUsage } from '../../networks/project.service';
import {
  createTranslate,
  createTranslateWav,
  requestExpertTranslate,
} from '../../networks/translate.service';
import { useModalAtom } from '../../recoil/atoms/store';
import { userFields } from '../../store/auth';
import { pages as storedPages } from '../../store/pages';
import { TranslateInfoType } from '../../types_new/project/types';
import Loader from '../common/loader';
import CircleLoader from '../console/common/circle-loader';
import LanguageListBox from '../modals/language-list-box';
import { UseModalType } from '../modals/modal-provider';
interface TransPriceType {
  unit: '단어' | '문자' | string;
  from: 'zh-cn' | 'ja' | 'en' | 'ko' | 'vi' | 'fr' | 'es' | string;
  to: 'zh-cn' | 'ja' | 'en' | 'ko' | 'vi' | 'fr' | 'es' | string;
}
const getTransPrice = ({ unit, from, to }: TransPriceType) => {
  if (unit === '단어') {
    if (from === 'vi') {
      if (to === 'ko') {
        return translatePrice.단어.베트남어.한국어;
      }
    } else if (from === 'fr') {
      if (to === 'ko') {
        return translatePrice.단어.프랑스어.한국어;
      }
    } else if (from === 'es') {
      if (to === 'ko') {
        return translatePrice.단어.스페인어.한국어;
      }
    } else if (from === 'en') {
      if (to === 'ko') {
        return translatePrice.단어.영어.한국어;
      }
      if (to === 'zh-cn') {
        return translatePrice.단어.영어.중국어;
      }
      if (to === 'ja') {
        return translatePrice.단어.영어.일본어;
      }
      if (to === 'vi') {
        return translatePrice.단어.영어.베트남어;
      }
      if (to === 'fr') {
        return translatePrice.단어.영어.프랑스어;
      }
      if (to === 'es') {
        return translatePrice.단어.영어.스페인어;
      }
    }
  } else if (unit === '문자') {
    if (from === 'ko') {
      if (to === 'en') {
        return translatePrice.문자.한국어.영어;
      }
      if (to === 'zh-cn') {
        return translatePrice.문자.한국어.중국어;
      }
      if (to === 'ja') {
        return translatePrice.문자.한국어.일본어;
      }
      if (to === 'vi') {
        return translatePrice.문자.한국어.베트남어;
      }
      if (to === 'fr') {
        return translatePrice.문자.한국어.프랑스어;
      }
      if (to === 'es') {
        return translatePrice.문자.한국어.스페인어;
      }
    } else if (from === 'zh-cn') {
      if (to === 'ko') {
        return translatePrice.문자.중국어.한국어;
      }
    } else if (from === 'ja') {
      if (to === 'ko') {
        return translatePrice.문자.일본어.한국어;
      }
    }
  }
  return null;
};

interface RequestTypeProps {
  postTitle: string;
  userName: string;
  userEmail: string;
  projectName: string;
  credit: number;
  originLanguage: string;
  originContent: string;
  translationLanguage: string;
}

const INITIAL_TRANSLATE_INFO = {
  sceneIndex: null,
  selectedLang: '',
};

const TranslateModal = ({ active, setActive, projectInfo, disabled }: any) => {
  const [useModal, setUseModal] = useRecoilState<UseModalType>(useModalAtom);
  const { t } = useTranslation();
  const [pages, setPages] = useRecoilState(storedPages);
  const [popup, setPopup] = useState({
    translate: 0,
    page: false,
    language: false,
    explanation: false,
  });
  const [translateInfo, setTranslateInfo] = useState<TranslateInfoType>(
    INITIAL_TRANSLATE_INFO
  );
  const [translateText, setTranslateText] = useState('');
  const [loading, setLoading] = useState(false);
  const userInfo = useRecoilValue(userFields);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [requestInfo, setRequestInfo] = useState<RequestTypeProps>({
    postTitle: '전문번역요청',
    userName: userInfo?.userName,
    userEmail: userInfo?.userEmail,
    projectName: projectInfo?.projectName,
    credit: 0,
    originLanguage: '',
    originContent: '',
    translationLanguage: '',
  });
  const segment = pages[0]?.segments[0]?.segment.filter(
    seg => seg.mediaType === 'metahuman'
  );
  const selectedIdx = +translateInfo?.sceneIndex;
  const totalTitleLength = useMemo(() => {
    if (!segment) {
      return;
    } else {
      if (segment[selectedIdx]?.currentLang === 'ko') {
        switch (translateInfo.sceneIndex) {
          case 'all':
            let totalLength = 0;
            for (let i = 0; i < segment.length; i++) {
              totalLength = totalLength + segment[i].title.length;
            }
            return totalLength;
          default:
            return segment[translateInfo.sceneIndex]?.title.length;
        }
      } else if (segment[selectedIdx]?.currentLang === 'zh-cn') {
        switch (translateInfo.sceneIndex) {
          case 'all':
            let totalLength = 0;
            for (let i = 0; i < segment.length; i++) {
              totalLength = totalLength + segment[i].title.length;
            }
            return totalLength;
          default:
            return segment[translateInfo.sceneIndex]?.title.length;
        }
      } else if (segment[selectedIdx]?.currentLang === 'ja') {
        switch (translateInfo.sceneIndex) {
          case 'all':
            let totalLength = 0;
            for (let i = 0; i < segment.length; i++) {
              totalLength = totalLength + segment[i].title.length;
            }
            return totalLength;
          default:
            return segment[translateInfo.sceneIndex]?.title.length;
        }
      } else {
        switch (translateInfo.sceneIndex) {
          case 'all':
            let totalLength = 0;
            for (let i = 0; i < segment.length; i++) {
              if (!segment[i]) return;
              totalLength = totalLength + segment[i].title.split(' ').length;
            }
            return totalLength;
          default:
            if (!segment) return;
            return segment[translateInfo.sceneIndex]?.title.split(' ').length;
        }
      }
    }
  }, [translateInfo]);
  const content = useMemo(() => {
    if (!segment) {
      return;
    } else {
      switch (translateInfo.sceneIndex) {
        case 'all':
          let content = '';
          for (let i = 0; i < segment.length; i++) {
            content = content + segment[i].title;
          }
          return content;
        default:
          return segment[translateInfo.sceneIndex]?.title;
      }
    }
  }, [translateInfo]);
  const [currentCredit, setCurrentCredit] = useState(0);

  const resetTranslateInfo = () => setTranslateInfo(INITIAL_TRANSLATE_INFO);

  const translateByScene = async () => {
    let tmpPage = pages.map(page => ({
      ...page,
      segments: page.segments.map(segment => ({
        ...segment,
        segment: segment.segment.map(seg => ({ ...seg })),
      })),
    }));
    // FIXME: handle metahuman only
    const segment = pages[0]?.segments[0]?.segment;

    setLoading(true);
    await createTranslate({
      projectId: projectInfo?.projectId,
      voiceId: String(segment[selectedIdx]?.metaData.voiceId),
      text: segment[selectedIdx]?.title,
      currentLang: segment[selectedIdx]?.currentLang,
      targetLang: translateInfo?.selectedLang,
    })
      .then(({ text }) => {
        setTranslateText(text);
        getWavFile(text);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });

    function getWavFile(text: string) {
      createTranslateWav({
        targetLang: translateInfo?.selectedLang,
        voiceId: String(projectInfo.metaData.voiceId),
        projectId: +projectInfo.projectId,
        text,
        tempo: 1.0,
        postId: projectInfo?.metaData?.postId,
      })
        .then(res => {
          tmpPage[0].segments[0].segment[selectedIdx] = {
            ...tmpPage[0].segments[0].segment[selectedIdx],
            title: text,
            duration: res.data.body.duration,
            endTime:
              tmpPage[0].segments[0].segment[selectedIdx].insertTime +
              res.data.body.duration,
            currentLang: translateInfo?.selectedLang,
            wavFilePath: res.data.body.wavFilePath,
          };
          setPages(tmpPage);
        })
        .catch(console.log)
        .finally(() => setLoading(false));
    }
  };

  const translateAllScenes = async () => {
    setLoading(true);
    let tmpPage = pages.map(page => ({
      ...page,
      segments: page.segments.map(segment => ({
        ...segment,
        segment: segment.segment.map(seg => ({ ...seg })),
      })),
    }));

    for (let i = 0; i < tmpPage[0]?.segments[0]?.segment?.length; i++) {
      const relevantSegment = tmpPage[0]?.segments[0]?.segment?.filter(
        seg => seg.mediaType === 'metahuman'
      )[i];
      if (!relevantSegment.title) {
        setLoading(false);
        setUseModal({
          isOpen: true,
          type: 'inform',
          data: {
            title: '번역 실패',
            message: '스크립트가 없는 메타휴먼이 있습니다.',
          },
        });
        break;
      }
      if (relevantSegment.mediaType === 'metahuman') {
        await createTranslate({
          projectId: projectInfo?.projectId,
          voiceId: String(relevantSegment?.metaData.voiceId),
          text: relevantSegment.title || 'm',
          currentLang: relevantSegment?.currentLang,
          targetLang: translateInfo.selectedLang!,
        })
          .then(({ text }) => {
            if (relevantSegment) {
              tmpPage[0].segments[0].segment[i] = {
                ...relevantSegment,
                title: text,
                currentLang: translateInfo?.selectedLang,
              };
              applyWavFilePath(text, i);
            }
          })
          .catch(console.log);
      }
    }
    async function applyWavFilePath(text: string, idx: number) {
      await createTranslateWav({
        projectId: +projectInfo.projectId,
        targetLang: translateInfo?.selectedLang,
        voiceId: String(tmpPage[0]?.segments[0]?.segment[idx].metaData.voiceId),
        text: text || 'm',
        postId: tmpPage[0]?.segments[0]?.segment[idx].metaData.postId,
      })
        .then(({ data: { body } }) => {
          if (tmpPage[0]?.segments[0]?.segment[idx]) {
            tmpPage[0].segments[0].segment[idx] = {
              ...tmpPage[0]?.segments[0]?.segment[idx],
              wavFilePath: body.wavFilePath,
              duration: body.duration,
              endTime:
                tmpPage[0].segments[0].segment[idx].insertTime + body.duration,
            };
          }
        })
        .catch(console.log);

      const metahumanLength = tmpPage[0]?.segments[0]?.segment.filter(
        item => item.mediaType === 'metahuman'
      ).length;

      if (idx > metahumanLength - 2) {
        setPages(tmpPage);
        setLoading(false);
      }
    }
  };

  const requestExpert = () => {
    if (totalPrice === 0) {
      alert('해당 언어로의 번역은 현재 지원되지 않습니다.');
      return;
    }
    requestExpertTranslate(requestInfo)
      .then(res => {
        console.log(res);
      })
      .catch(err => alert(err))
      .finally(() => setPopup({ ...popup, translate: 3 }));
    onClickCredit();
  };

  const setPrice = (from: string, to: string) => {
    const price = Number(
      getTransPrice({
        unit:
          from === 'zh-cn'
            ? '문자'
            : from === 'ko'
            ? '문자'
            : from === 'ja'
            ? '문자'
            : '단어',
        from: from,
        to: to,
      }) *
        (from === 'zh-cn'
          ? content.length
          : from === 'ja'
          ? content.length
          : from === 'ko'
          ? content.length
          : content.split(' ').length)
    );
    setTotalPrice(price);
  };

  const onClickCredit = () => {
    const creditPrice: number = requestInfo.credit;
    const payBody: ICreditMinusItem = {
      itemCd: 'expert translation',
      itemName: requestInfo.projectName,
      itemCnt: 1,
      credit: Number(creditPrice),
    };
    payMinusCredit(payBody)
      .then(res => {
        const resultStatus = res.data?.resultStatus;
        if (resultStatus === 200) {
          const body: IAddPayUsageReq = {
            credit: creditPrice.toString(),
            category: 'expert translation',
            postId: 11111,
            type: 'translation',
          };
          addPayUsage(body)
            .then(res => {
              console.log(res);
            })
            .catch(err => console.log(err));
        } else {
          // setAlertModal(false);
          alert(t('크레딧 차감 실패'));
        }
      })
      .catch(console.error);
  };

  const getSceneLabel = () => {
    if (translateInfo.sceneIndex === null) {
      return t('장면 선택');
    } else if (translateInfo.sceneIndex === 'all') {
      return t('전체 일괄번역');
    } else {
      return `Scene ${(translateInfo.sceneIndex as number) + 1}`;
    }
  };

  useEffect(() => {
    setRequestInfo(prev => ({
      ...prev,
      credit: totalPrice,
    }));
    getLeftCredit()
      .then(res => {
        if (res.status === 200) {
          const result = res.data.resultValue;
          let creditLeft = 0;
          result.map((value: any) => {
            creditLeft += value.credit;
          });
          setCurrentCredit(creditLeft);
        }
      })
      .catch(console.error);
  }, [totalPrice]);

  return (
    <>
      <CircleLoader isOpen={loading} />
      <li>
        <button
          disabled={disabled}
          className={active == 6 ? 'active' : 'disabled:cursor-not-allowed'}
          onClick={() => {
            setActive(6);
          }}>
          <i className="style__translate__icon" />
          <span>{t('번역')}</span>
        </button>
        {active === 6 && (
          <>
            <div
              className={`translate ${
                popup.translate === 0 && 'active'
              } h-full max-h-[600px] overflow-y-auto overflow-x-hidden`}>
              <div className="title">
                <h2>{t('번역')}</h2>
                <button
                  className="close__btn close__gray__icon"
                  onClick={() => {
                    setActive(0);
                    setTranslateInfo({
                      sceneIndex: '',
                      selectedLang: '',
                    });
                  }}
                />
              </div>
              <div className="content">
                <div className="translate__content show">
                  <div className="translate__list list default show">
                    {/* 번역 요청 - 장면 선택/언어 선택 */}
                    <div className="translate-select-cont w-full">
                      <div className="translate-select-box">
                        <div
                          className="translate-select-button"
                          onClick={() => {
                            setPopup({
                              ...popup,
                              page: !popup.page,
                              language: false,
                            });
                          }}>
                          <span>{getSceneLabel()}</span>
                          <i
                            className="select__black__icon translate-arrow"
                            style={{
                              transform: `${
                                popup.page ? 'rotate(-180deg)' : ''
                              }`,
                            }}
                          />
                        </div>
                        {popup.page && (
                          <div className="translate-select-inner secondary-scroll">
                            <ul>
                              <li
                                onClick={() => {
                                  setTranslateInfo(prev => ({
                                    ...prev,
                                    sceneIndex: 'all',
                                  }));
                                  setPopup({ ...popup, page: !popup.page });
                                }}>
                                {t('전체 일괄번역')}
                              </li>
                              {pages[0]?.segments[0]?.segment.map(
                                (item: any, idx: number) => {
                                  if (item.mediaType === 'metahuman') {
                                    return (
                                      <li
                                        key={`item-${idx}`}
                                        className="px-2"
                                        onClick={() => {
                                          setTranslateInfo({
                                            ...translateInfo,
                                            sceneIndex: idx,
                                          });
                                          setPopup({
                                            ...popup,
                                            page: !popup.page,
                                          });
                                        }}>
                                        <span>{`Scene ${idx + 1}`}</span>
                                        <p className="w-28">{item.title}</p>
                                      </li>
                                    );
                                  }
                                }
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                      <LanguageListBox
                        translateInfo={translateInfo}
                        setTranslateInfo={setTranslateInfo}
                      />
                    </div>
                    <button
                      className="edit-change"
                      onClick={() => {
                        translateInfo.sceneIndex && translateInfo.selectedLang
                          ? setPopup({ ...popup, translate: 1 })
                          : alert(t('장면과 언어를 선택해주세요.'));
                      }}>
                      {t('전문가 번역 요청하기')}
                      <img
                        src={EditChange}
                        alt=""
                        onClick={e => {
                          e.stopPropagation();
                          setPopup({
                            ...popup,
                            explanation: !popup.explanation,
                          });
                        }}
                      />
                      {popup.explanation && (
                        <div className="tranalate-select-desc">
                          {t(
                            '이제 전문가에게 내 스크립트 검수를 요청해보세요!'
                          )}
                        </div>
                      )}
                    </button>
                    <button
                      onClick={() => {
                        translateInfo?.sceneIndex === 'all'
                          ? translateAllScenes()
                          : translateByScene();
                        setActive(0);
                        resetTranslateInfo();
                      }}
                      disabled={
                        translateInfo.sceneIndex === null ||
                        !translateInfo.selectedLang ||
                        loading
                      }
                      className="nextBtn flex items-center justify-center disabled:bg-primary/50 disabled:cursor-not-allowed">
                      {loading ? <Loader /> : t('확인')}
                    </button>
                    {/* 번역 요청 - 장면 선택/언어 선택 */}
                  </div>
                </div>
              </div>
            </div>
            {popup.translate > 0 && (
              <div className="translate-expert-popup">
                <div className="translate-expert-cont">
                  <div className="title">
                    <h2>전문가 번역 요청하기</h2>
                    <button
                      className="translate-btn close__gray__icon"
                      onClick={() => {
                        setActive(0);
                        setPopup({ ...popup, translate: 0 });
                        resetTranslateInfo();
                      }}
                    />
                  </div>
                  {/* 번역 요청 - 파일 첨부/프로젝트명/요청사항 */}
                  {popup.translate === 1 && (
                    <>
                      <div className="col">
                        <span>스크립트 파일</span>
                        <div>
                          <input
                            type="text"
                            placeholder="플루닛 프로젝트.script"
                            disabled
                          />
                          <a className="icon-download">
                            <img src={IconDownload} alt="" />
                          </a>
                        </div>
                      </div>
                      <div className="col">
                        <span>프로젝트명</span>
                        <input
                          type="text"
                          disabled
                          placeholder={projectInfo?.projectName ?? ''}
                        />
                      </div>
                      <div className="col">
                        <span>요청 내용</span>
                        <textarea
                          className="border border-slate-300 py-3 px-4 rounded-lg resize-none h-32 overflow-y-auto placeholder:text-gray-600 text-sm"
                          readOnly
                          placeholder={content}
                        />
                      </div>
                      <div className="expert-btn-cont">
                        <button
                          className="gray-cancel"
                          onClick={() => {
                            setActive(0);
                            setPopup({ ...popup, translate: 0 });
                            setTranslateInfo({
                              sceneIndex: '',
                              selectedLang: '',
                            });
                          }}>
                          {t('최소')}
                        </button>
                        <button
                          className="purple-next"
                          onClick={() => {
                            setPrice(
                              segment[translateInfo.sceneIndex]?.currentLang,
                              translateInfo.selectedLang
                            );
                            setPopup({ ...popup, translate: 2 });
                            setRequestInfo(prev => ({
                              ...prev,
                              projectName: projectInfo.projectName,
                              originLanguage:
                                segment[translateInfo.sceneIndex]?.currentLang,
                              originContent: content,
                              translationLanguage: translateInfo.selectedLang,
                            }));
                          }}>
                          {t('다음')}
                        </button>
                      </div>
                    </>
                  )}
                  {/* 번역 요청 - 파일 첨부/프로젝트명/요청사항 */}
                  {/* 번역 요청 - 크레딧 */}
                  {popup.translate === 2 && (
                    <>
                      <p>
                        스크립트 총<strong>{totalTitleLength}</strong>
                        {segment[selectedIdx]?.currentLang === 'ko'
                          ? '글자'
                          : segment[selectedIdx]?.currentLang === 'zh-cn'
                          ? '글자'
                          : segment[selectedIdx]?.currentLang === 'ja'
                          ? '글자'
                          : '단어'}
                      </p>
                      <div className="expert-coin">
                        <img src={IconCoin} alt="" />
                        {totalPrice}
                      </div>
                      <div className="translate-coin-cont">
                        <div className="coin-cont">
                          <span>현재 보유 크레딧</span>
                          <div className="coin">
                            <strong>{currentCredit.toLocaleString()}</strong>
                            {' 크레딧'}
                          </div>
                        </div>
                        <div className="coin-cont">
                          <span>차감 크레딧</span>
                          <div className="coin">
                            <strong>- {totalPrice.toLocaleString()}</strong>
                            {' 크레딧'}
                          </div>
                        </div>
                        <div className="coin-cont">
                          <span>남은 보유 크레딧</span>
                          <div className="coin">
                            <strong>
                              {(currentCredit - totalPrice).toLocaleString()}
                            </strong>
                            {' 크레딧'}
                          </div>
                        </div>
                      </div>
                      <div className="expert-btn-cont">
                        <button
                          className="gray-cancel"
                          onClick={() => {
                            setActive(0);
                            setPopup({ ...popup, translate: 0 });
                            setTranslateInfo({
                              sceneIndex: '',
                              selectedLang: '',
                            });
                          }}>
                          최소
                        </button>
                        <button className="purple-next" onClick={requestExpert}>
                          요청하기
                        </button>
                      </div>
                    </>
                  )}
                  {/* 번역 요청 - 크레딧 */}
                </div>
                {/* 번역요청 완료 */}
                {popup.translate === 3 && (
                  <div className="translate-request">
                    <img
                      src={IconTranslate}
                      alt=""
                      className="translate-icon"
                    />
                    <h3>마이페이지에 번역요청이 등록되었습니다.</h3>
                    <p> 검수 확인은 마이페이지에서 하실 수 있습니다.</p>
                    <button
                      className="project-move-btn"
                      onClick={() => {
                        setActive(0);
                        setPopup({ ...popup, translate: 0 });
                        resetTranslateInfo();
                      }}>
                      {t('확인')}
                    </button>
                  </div>
                )}
                {/* 번역요청 완료 */}
              </div>
            )}
          </>
        )}
      </li>
    </>
  );
};

export default TranslateModal;
