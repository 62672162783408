import { useTranslation } from 'react-i18next';
import CoinIcon from '../../../svg/coin-icon';

type Props = {
  credit: string;
};
export default function CreditCard({ credit }: Props) {
  const { t } = useTranslation();
  return (
    <div className="w-full flex justify-center bg-white text-darkSecondary py-6 md:py-8 rounded-md">
      <div className="flex max-md:flex-col max-md:space-y-2 md:space-x-3 items-center md:items-end">
        <div className="flex space-x-1 items-start">
          <CoinIcon isSmall />
          <span className="text-3xl lg:text-4xl font-semibold">{credit}</span>
        </div>
        <span className="text-sm md:text-base xl:text-lg font-medium">
          {t('내 보유 크레딧')}{' '}
        </span>
      </div>
    </div>
  );
}
