/* eslint-disable */
import { useTranslation } from 'react-i18next';
import '../../css/reset.css';
import '../../css/style.css';
import '../../locale/i18n';

const PptUploadModal = ({ uploadPPT, pptFormHandler }: any) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="ppt__btn">
        <input
          id="pptUpload"
          type="file"
          name="pptForm"
          accept=".pptx, .ppt"
          onChange={uploadPPT}
        />
        <label
          onClick={() =>
            alert(
              t(
                'PPT 업로드 시, 사용자가 적용한 글자 폰트가 적용이 안될 수 있습니다. 기본 시스템 폰트로 바뀌어 적용이 됩니다.'
              )
            )
          }
          htmlFor="pptUpload">
          {t('PPT 업로드')}
        </label>
      </div>
      {/* <button>PPT 업로드</button> */}
    </>
  );
};

export default PptUploadModal;
