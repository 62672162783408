type Props = {
  isDark?: boolean;
};
export default function CloseButtonIcon({ isDark }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke={isDark ? '#555555' : 'currentColor'}
      className="w-10 h-10 md:w-12 md:h-12 hover:stroke-primary duration-150">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
}
