export default function PreviewIcon() {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1847 8.08363L1.97784 13.8259C1.11163 14.3128 0 13.7233 0 12.7419V1.25725C0 0.277391 1.11003 -0.313637 1.97784 0.174739L12.1847 5.91705C12.3817 6.02611 12.5455 6.18376 12.6594 6.37402C12.7733 6.56427 12.8333 6.78034 12.8333 7.00034C12.8333 7.22033 12.7733 7.43641 12.6594 7.62666C12.5455 7.81691 12.3817 7.97456 12.1847 8.08363Z"
        fill="white"
      />
    </svg>
  );
}
