import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ICreditMinusItem } from '../../../networks/dto/IPaySystemDto';
import { IAddPayUsageReq } from '../../../networks/dto/IProjectDto';
import {
  getLeftCredit,
  payMinusCredit,
} from '../../../networks/paysystem.service';
import { addPayUsage } from '../../../networks/project.service';
import { isSignedAtom } from '../../../networks/store/member';
import { useModalAtom } from '../../../recoil/atoms/store';
import { PostPayType } from '../../../types_new/main/resource/types';
import {
  changeHost,
  findValueByKey,
  getItemCode,
  getValueByKey,
} from '../../../utils/common';
import PreviewIcon from '../../svg/PreviewIcon';
import PreviewPauseIcon from '../../svg/PreviewPauseIcon';
import CoinIcon from '../../svg/coin-icon';
import MetahumanBadge from './metahuman-badge';

type Props = {
  isAudioPlaying: boolean;
  audio: string;
  item: PostPayType;
  category: 'metahuman' | 'clip' | 'background';
  creditleft: number;
  refreshCB: () => void;
  handleOnClick: (
    itemType: 'image' | 'video' | 'metahuman' | 'audio',
    metaData: PostPayType['metaData']
  ) => void;
};
export default function ItemCard({
  isAudioPlaying,
  audio,
  item: { postId, postTitle, metaData, payDate },
  category,
  creditleft,
  refreshCB,
  handleOnClick,
}: Props) {
  const setUseModal = useSetRecoilState(useModalAtom);
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const thumbnail = findValueByKey(
    metaData,
    category === 'metahuman' ? 'thumbnail' : 'imgUrl'
  );
  const price = Number(findValueByKey(metaData, 'price'));
  const isFree = findValueByKey(metaData, 'isFree') === '1';
  const mediaType =
    category !== 'metahuman' ? findValueByKey(metaData, 'mediaType') : '';
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentCredit, setCurrentCredit] = useState(creditleft);
  const [buyStatus, setBuyStatus] = useState(0);
  const isPaid = !!payDate;

  useEffect(() => {
    if (buyStatus) {
      getLeftCredit()
        .then(res => {
          if (res.status === 200) {
            const result = res.data.resultValue;
            let creditLeft = 0;
            result.map((value: any) => {
              creditLeft += value.credit;
            });
            setCurrentCredit(creditLeft);
          }
        })
        .catch(console.error)
        .finally(refreshCB);
    }
  }, [buyStatus]);

  const handleOnUse = () => {
    console.log('============> price :', price);
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }
    if (!+price) {
      openInformModal(
        '무료 콘텐츠',
        '스튜디오에서 바로 사용이 가능한 무료 콘텐츠 입니다.'
      );
      return;
    }
    if (currentCredit < +price) {
      openInformModal('구매 실패', '크레딧이 부족합니다.');
      return;
    }

    openConfirmModal();
  };

  const openConfirmModal = () =>
    setUseModal({
      isOpen: true,
      type: 'confirm',
      data: {
        title: postTitle,
        message: '이 아이템을 구매하시겠습니까?',
        handleOnClick: buyResource,
      },
    });

  const openInformModal = (title, message) =>
    setUseModal({
      isOpen: true,
      type: 'inform',
      data: {
        title,
        message,
      },
    });

  const buyResource = () => {
    const itemCd = getItemCode();
    const body: ICreditMinusItem = {
      itemCd,
      itemName: postTitle,
      itemCnt: 1,
      credit: +price,
    };

    payMinusCredit(body)
      .then(res => {
        const resultStatus = res.data?.resultStatus;
        if (resultStatus === 200) {
          const body: IAddPayUsageReq = {
            credit: price.toString(),
            category,
            postId,
            type: mediaType,
          };
          addPayUsage(body)
            .then(console.log)
            .catch(console.log)
            .finally(() => setBuyStatus(buyStatus + 1));
        } else {
          openInformModal('구매 실패', '크레딧 차감에 실패했습니다.');
        }
      })
      .catch(console.log)
      .finally(() => setUseModal(prev => ({ ...prev, isOpen: false })));
  };

  return (
    <div className="h-52 sm:h-56 lg:h-60 2xl:h-64">
      <div
        onClick={() =>
          handleOnClick(
            category === 'metahuman'
              ? 'metahuman'
              : mediaType === 'video'
              ? 'video'
              : mediaType === 'image'
              ? 'image'
              : 'audio',
            metaData
          )
        }
        className="relative w-full h-1/2 sm:h-3/4 bg-center bg-cover rounded-t-[10px] group cursor-pointer"
        style={{ backgroundImage: `url(${changeHost(thumbnail)})` }}>
        {category === 'metahuman' && <MetahumanBadge isFree={isFree} />}
        <div
          className={`absolute w-full h-full rounded-t-[10px] flex justify-center items-center ${
            mediaType !== 'audio' &&
            'opacity-0 group-hover:opacity-100 bg-primary/40'
          } duration-300`}>
          {mediaType === 'audio' ? (
            <button className="w-20 h-20 bg-[#d9d9d9] bg-opacity-30 rounded-3xl shadow-md border-none group-hover:bg-[#7b61ff] group-hover:bg-opacity-80 duration-200 ease-in-out">
              <i className="h-full flex justify-center items-center">
                {audio === getValueByKey(metaData, 'file') && isAudioPlaying ? (
                  <PreviewPauseIcon />
                ) : (
                  <PreviewIcon />
                )}
              </i>
            </button>
          ) : (
            <span className="text-sm underline underline-offset-2">
              {category === 'metahuman' ? '메타휴먼 체험하기' : '미리보기'}
            </span>
          )}
        </div>
      </div>
      <div className="flex max-lg:flex-col justify-between items-start lg:items-center bg-white p-4 rounded-b-[10px] text-black">
        <h5 className="font-medium">{postTitle}</h5>
        <div className="max-lg:w-full flex max-sm:flex-col max-sm:space-y-2 max-sm:items-start max-lg:justify-between items-center sm:space-x-1.5">
          <div className="flex items-center space-x-1">
            <CoinIcon />
            <span className="text-[#9379ff] font-bold">
              {price.toLocaleString()}
            </span>
          </div>
          <button
            onClick={handleOnUse}
            disabled={isPaid}
            className="disabled:bg-primary/40 bg-black max-sm:w-full text-white text-xs leading-6 py-0.5 px-4 rounded-md hover:bg-black/70 duration-100 ease-out">
            {isPaid ? t('구매중') : t('사용')}
          </button>
        </div>
      </div>
    </div>
  );
}
