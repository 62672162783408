import { useTranslation } from 'react-i18next';

export default function PersonalTerms() {
  const { t } = useTranslation();

  return (
    <div className="terms-wrapper overflow-y-auto no-scroll">
      <div className="text-black text-sm">
        {t(
          '주식회사 플루닛은 가입자의 서비스 이용에 관한 통계와 서비스 개선 등을 위해 개인정보를 수집 및 이용합니다. 회사에서 수집하는 항목과 목적은 다음과 같습니다. 아래에 동의하시면 통합 플루닛계정을 통해 플루닛에서 제공하는 다양한 개별 서비스들을 편리하게 이용하실 수 있습니다.'
        )}
        <br />
        <table>
          <tr>
            <th>{t('수집목적')}</th>
            <th>{t('항목')}</th>
            <th>{t('보관기간')}</th>
          </tr>
          <tr>
            <td>{t('회원 가입, 식별 및 관리')}</td>
            <td>{t('아이디, 비밀번호')}</td>
            <td rowSpan={4}>{t('회원 탈퇴 시 즉시 파기')}</td>
          </tr>
          <tr>
            <td>
              {t('가입자 본인 및 연령 확인, 맞춤형 컨텐츠 및 서비스 제공')}
            </td>
            <td>
              {t(
                '이름, 성별, 생년월일, 휴대폰번호, 내/외국인 여부, 아이핀 번호(아이핀 이용 시), 암호화된 가입자 확인 값(CI), 중복가입확인정보(DI), 상호명, 사업자등록번호, 법인명, 법인등록번호'
              )}
            </td>
          </tr>
          <tr>
            <td>
              {t(
                '아이디, 비밀번호 찾기 시 본인확인, 서비스 관련 변경사항 및 중요사항 안내'
              )}
            </td>
            <td>{t('이메일, 휴대폰번호, 유선전화번호, 사업장소재지')}</td>
          </tr>
          <tr>
            <td>
              {t('맞춤형 서비스 제공, 신규 서비스 개발, 기존 서비스 개선')}
            </td>
            <td>{t('서비스 이용 내역, 서비스 내 구매 및 결제 내역')}</td>
          </tr>
        </table>
        <strong>
          *{' '}
          {t(
            '가입자의 개인정보는 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 다만, 회사 내부 방침 또는 관계법령에 의한 정보보유 사유가 있을 시 규정된 기간 동안 안전하게 보존합니다.'
          )}{' '}
        </strong>{' '}
        <br /> <br />
        {t(
          '회원가입 신청자는 위와 같은 개인정보 수집 및 이용에 대해 동의를 거부할 권리가 있습니다. 다만, 동의를 거부하실 경우 회원가입이 제한됩니다.'
        )}
        <br /> <br />
        {t(
          '플루닛의 개인정보 처리에 관한 더 자세한 사항은 플루닛 홈페이지에 공개하고 있는 ‘개인정보 처리방침’을 통해 확인하실 수 있습니다.'
        )}
      </div>
    </div>
  );
}
