export default function FooterInstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none">
      <g opacity="0.7">
        <rect
          width="30"
          height="30"
          rx="15"
          fill="url(#paint0_radial_4689_1148)"
        />
        <rect
          width="30"
          height="30"
          rx="15"
          fill="url(#paint1_radial_4689_1148)"
        />
        <rect width="30" height="30" rx="15" fill="white" />
        <path
          d="M21.8014 10.1554C21.8014 10.9273 21.1757 11.553 20.4039 11.553C19.6321 11.553 19.0064 10.9273 19.0064 10.1554C19.0064 9.38361 19.6321 8.75792 20.4039 8.75792C21.1757 8.75792 21.8014 9.38361 21.8014 10.1554Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.2797 19.938C17.8524 19.938 19.938 17.8524 19.938 15.2797C19.938 12.7069 17.8524 10.6213 15.2797 10.6213C12.7069 10.6213 10.6213 12.7069 10.6213 15.2797C10.6213 17.8524 12.7069 19.938 15.2797 19.938ZM15.2797 18.0747C16.8233 18.0747 18.0747 16.8233 18.0747 15.2797C18.0747 13.736 16.8233 12.4846 15.2797 12.4846C13.736 12.4846 12.4846 13.736 12.4846 15.2797C12.4846 16.8233 13.736 18.0747 15.2797 18.0747Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.96289 14.907C5.96289 11.7763 5.96289 10.2109 6.57217 9.01512C7.10811 7.96328 7.96328 7.10811 9.01512 6.57217C10.2109 5.96289 11.7763 5.96289 14.907 5.96289H15.6523C18.7831 5.96289 20.3484 5.96289 21.5442 6.57217C22.596 7.10811 23.4512 7.96328 23.9872 9.01512C24.5964 10.2109 24.5964 11.7763 24.5964 14.907V15.6523C24.5964 18.7831 24.5964 20.3484 23.9872 21.5442C23.4512 22.596 22.596 23.4512 21.5442 23.9872C20.3484 24.5964 18.7831 24.5964 15.6523 24.5964H14.907C11.7763 24.5964 10.2109 24.5964 9.01512 23.9872C7.96328 23.4512 7.10811 22.596 6.57217 21.5442C5.96289 20.3484 5.96289 18.7831 5.96289 15.6523V14.907ZM14.907 7.82624H15.6523C17.2484 7.82624 18.3335 7.82769 19.1721 7.89622C19.9891 7.96296 20.4068 8.08394 20.6983 8.23243C21.3995 8.58972 21.9696 9.15984 22.3269 9.86106C22.4754 10.1525 22.5964 10.5703 22.6631 11.3872C22.7316 12.2259 22.7331 13.3109 22.7331 14.907V15.6523C22.7331 17.2484 22.7316 18.3335 22.6631 19.1721C22.5964 19.9891 22.4754 20.4068 22.3269 20.6983C21.9696 21.3995 21.3995 21.9696 20.6983 22.3269C20.4068 22.4754 19.9891 22.5964 19.1721 22.6631C18.3335 22.7316 17.2484 22.7331 15.6523 22.7331H14.907C13.3109 22.7331 12.2259 22.7316 11.3872 22.6631C10.5703 22.5964 10.1525 22.4754 9.86106 22.3269C9.15984 21.9696 8.58972 21.3995 8.23243 20.6983C8.08394 20.4068 7.96296 19.9891 7.89622 19.1721C7.82769 18.3335 7.82624 17.2484 7.82624 15.6523V14.907C7.82624 13.3109 7.82769 12.2259 7.89622 11.3872C7.96296 10.5703 8.08394 10.1525 8.23243 9.86106C8.58972 9.15984 9.15984 8.58972 9.86106 8.23243C10.1525 8.08394 10.5703 7.96296 11.3872 7.89622C12.2259 7.82769 13.3109 7.82624 14.907 7.82624Z"
          fill="black"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_4689_1148"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.7143 22.5) rotate(-55.3758) scale(27.3424)">
          <stop stopColor="#B13589" />
          <stop offset="0.79309" stopColor="#C62F94" />
          <stop offset="1" stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_4689_1148"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.64286 31.0714) rotate(-65.1363) scale(24.2081)">
          <stop stopColor="#E0E8B7" />
          <stop offset="0.444662" stopColor="#FB8A2E" />
          <stop offset="0.71474" stopColor="#E2425C" />
          <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
}
