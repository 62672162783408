import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TemplateType } from '../../../../types_new/main/resource/types';
import { getValueByKey } from '../../../../utils/common';

const tableHeadList = ['순서', '템플릿명', '크레딧', '일시', '상태'];
const SKELETON_TD_CLASS = 'h-2 rounded-full bg-gray-400 mx-auto animate-pulse';

type Props = {
  templateData: TemplateType[];
  loading: boolean;
};

const displayStatus = (tranType: TemplateType['tranType']) => {
  switch (tranType) {
    case 'buy':
      return '구매';
    case 'sell':
      return '판매';
    default:
      return '';
  }
};

export default function TemplateTable({ templateData, loading }: Props) {
  const { t } = useTranslation();
  return (
    <table className="w-full text-left mt-12 max-sm:hidden">
      <thead className="text-center bg-[#07102c]/5">
        <tr>
          {tableHeadList.map((tableHead, idx) => (
            <th
              key={`tableHead-${idx}`}
              scope="col"
              className="py-4 whitespace-nowrap font-normal text-sm text-[#0B1733]">
              {t(`${tableHead}`)}
            </th>
          ))}
        </tr>
      </thead>
      {loading ? (
        <Skeleton />
      ) : (
        <tbody className="text-center">
          {templateData.map(
            ({ postTitle, registerDate, metaData, tranType }, idx) => {
              const price = getValueByKey(metaData, 'credit');
              return (
                <tr
                  key={`payInfo-${idx}`}
                  className="border-b border-[#D4DEEA] font-medium">
                  <td className="py-6">{idx + 1}</td>
                  <td className="py-6">{postTitle}</td>
                  <td className="py-6">{price}</td>
                  <td className="py-6">
                    {moment(registerDate).format('YYYY-MM-DD')}
                  </td>
                  <td className="text-sm py-6 font-semibold">
                    {displayStatus(tranType)}
                  </td>
                </tr>
              );
            }
          )}
        </tbody>
      )}
    </table>
  );
}

function Skeleton() {
  const array = [1, 2, 3, 4, 5];

  return (
    <tbody className="text-center">
      {array.map(item => (
        <tr
          key={`template-publish-skeleton-${item}`}
          className="border-b border-[#D4DEEA] font-medium">
          <td className="py-6">{item}</td>
          <td className="py-6">
            <div className={`w-24 ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="py-6">
            <div className={`w-14 ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="py-6">
            <div className={`w-18 ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="text-sm py-6 font-semibold">
            <div className={`w-8  ${SKELETON_TD_CLASS}`} />
          </td>
        </tr>
      ))}
    </tbody>
  );
}
