import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isOpen: boolean;
  isSignupPage?: boolean;
  closeModal: () => void;
  onClick?: () => void;
  terms: any;
  title: string;
};

export default function TermsModal({
  isOpen,
  closeModal,
  onClick,
  terms,
  title,
  isSignupPage = true,
}: Props) {
  const { t } = useTranslation();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[200]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>

        <div className="fixed w-full h-auto max-sm:bottom-0 inset-auto sm:inset-0 flex overflow-y-hidden items-center justify-center sm:p-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 scale-100 max-sm:translate-y-0"
            leaveTo="opacity-0 sm:scale-95 max-sm:translate-y-72">
            <Dialog.Panel
              className={`w-full md:max-w-[560px] h-auto flex flex-col justify-between transform overflow-hidden rounded-t-lg sm:rounded-lg bg-[#f8f7ff] p-5 md:py-6 md:px-10 ${
                !isSignupPage && '!pb-0 md:!pb-0'
              } text-left align-middle shadow-xl transition-all`}>
              <section className="relative ">
                <Dialog.Title
                  as="h3"
                  className="text-black xl:text-lg py-2.5 xl:py-3 border-b border-gray-400">
                  {t(title)}
                </Dialog.Title>
                <div className="h-80 xl:h-96 2xl:h-[450px] overflow-y-auto no-scroll pt-3 pb-12 terms-wrapper">
                  {terms}
                </div>
                <div
                  className={`fixed  left-0 h-14 w-full bg-gradient-to-t from-[#f8f7ff] to-[#f8f7ff]/0 z-50 ${
                    isSignupPage
                      ? ' bottom-[66px] md:bottom-[78px] xl:bottom-[74px]'
                      : 'md:bottom-[0px] xl:bottom-[0px] bottom-[0]'
                  }`}
                />
              </section>
              {isSignupPage && (
                <div className="w-full flex space-x-4">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-[#7663f6] py-3 md:py-3.5 xl:py-[13px] text-sm md:text-base xl:leading-[22px] text-[#7663f6] hover:bg-[#7663f6]/10 focus:outline-none duration-150 ease-out"
                    onClick={closeModal}>
                    {t('닫기')}
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-[#7663f6] bg-[#7663f6] py-3 md:py-3.5 xl:py-[13px] text-sm md:text-base xl:leading-[22px] text-white hover:bg-[#7663f6]/90 focus:outline-none duration-150 ease-out"
                    onClick={onClick}>
                    {t('동의하기')}
                  </button>
                </div>
              )}
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
