import { useEffect, useState } from 'react';
import { getMetahumanList } from '../../../networks/source.service';
import { MetahumanListType } from '../../../types_new/main/resource/types';
import { changeHost } from '../../../utils/common';
import VideoModal from '../../VideoModal';
import Pagination from '../../common/Pagination';
import MetahumanBadge from '../../main/store/metahuman-badge';

const POSTS_PER_PAGE = 10;

export default function MyMetahumansGrid() {
  const [paged, setPaged] = useState(1);
  const [metahumans, setMetahumans] = useState<MetahumanListType[]>([]);
  const [total, setTotal] = useState(0);
  const [parentPage, setParentPage] = useState(1);
  const [videoModal, setVideoModal] = useState({
    isOpen: false,
    src: '',
  });

  useEffect(() => {
    getMetahumanList({
      postsPerPage: POSTS_PER_PAGE,
      paged,
      lang: 'ko',
    })
      .then(({ data }) => {
        setTotal(data.body.total);
        setMetahumans(data.body.metahumans);
      })
      .catch(console.log);
  }, [paged]);

  const onPageChange = (paged: number) => {
    setPaged(paged);
    setParentPage(paged);
  };

  return (
    <>
      <VideoModal
        isOpen={videoModal.isOpen}
        videoURL={videoModal.src}
        closeModal={() => setVideoModal(prev => ({ ...prev, isOpen: false }))}
      />
      <div className="w-full mt-5">
        <ul className="grid grid-cols-5 gap-5">
          {metahumans.map(metahuman => (
            <li
              key={`metahuman-${metahuman.postId}`}
              onClick={() =>
                setVideoModal({ isOpen: true, src: metahuman.sampleMp4 })
              }
              className="flex flex-col w-full h-40 overflow-hidden">
              <div className="relative w-full h-full rounded-xl overflow-hidden group cursor-pointer">
                <MetahumanBadge isFree={metahuman.isFree} />
                <div
                  style={{
                    backgroundImage: `url(${changeHost(metahuman.thumbnail)})`,
                  }}
                  className="w-full h-full bg-cover bg-center"
                />
                <div className="absolute left-0 top-0 w-full h-full flex items-center justify-center bg-primary/30 opacity-0 group-hover:opacity-100 duration-150 ease-out">
                  <span className="text-white text-sm">미리보기</span>
                </div>
              </div>
              <span className="text-sm mt-1">{metahuman.name}</span>
            </li>
          ))}
        </ul>
        <Pagination
          onPageChange={onPageChange}
          totalItems={total}
          currentPage={paged}
          parentPage={parentPage}
          itemsPerPage={10}
        />
      </div>
    </>
  );
}
