type Props = {
  isLeft?: boolean;
};

export default function ChevronNavIcon({ isLeft }: Props) {
  return (
    <svg
      className={`${isLeft && 'rotate-180'}`}
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="fill-gray-500 duration-200"
        d="M7.76794 1.99998L7 2.73719L12.4844 7.99998L7 13.2628L7.76794 14L13.6342 8.36858L14 7.99998L13.6336 7.63138L7.76794 1.99998Z"
      />
    </svg>
  );
}
