import { useNavigate } from 'react-router-dom';
import LinkArrowIcon from '../svg/link-arrow-icon';

type Props = {
  link: string;
};

export default function WatchMoreButton({ link }: Props) {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(link)}
      className="border border-white rounded-md flex items-center space-x-1 lg:space-x-2 py-2 lg:py-[10px] px-3 lg:px-5 font-medium group hover:bg-white/20 duration-150 mt-8">
      <span>Watch More</span>
      <i className="group-hover:translate-x-0.5 group-hover:-translate-y-0.5 duration-150">
        <LinkArrowIcon />
      </i>
    </button>
  );
}
