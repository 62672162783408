import 'swiper/swiper.min.css';
import EnterpriseInfo from '../membership/enterprise-info';
import ProductMembershipList from '../membership/product-membership-list';
import SubscribeMembershipList from '../membership/subscribe-membership-list';
import LandingTitle from './landing-title';

export default function MembershipSection() {
  return (
    <section className="w-full pb-12 lg:pb-40 pt-12 lg:pt-10">
      <div className="max-w-screen-xl w-full mx-auto lg:px-5">
        <div className="px-5">
          <LandingTitle
            badge="요금"
            title="당신에게 알맞는 플루닛 플랜으로 서비스 비용을 최적화하세요."
            description="결제금액에 따라 더 많이 쌓이는 플루닛 크레딧으로 원하시는 단품 또는 정기구독 결제 방법을 선택하세요."
            link="/membership"
          />
        </div>
        <ProductMembershipList />
        <div className="flex flex-col-reverse lg:flex-col">
          <EnterpriseInfo />
          <SubscribeMembershipList />
        </div>
      </div>
    </section>
  );
}
