import { Disclosure } from '@headlessui/react';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { InquiryDataType } from '../../../../types_new/main/landing/types';
import { findValueByKey } from '../../../../utils/common';
import ExpandMoreIcon from '../../../svg/expand-more-icon';

type Props = {
  data: InquiryDataType;
  setPostId: Dispatch<SetStateAction<number>>;
  setConfirmModal: Dispatch<SetStateAction<boolean>>;
};
export default function InquiryCard({
  data,
  setPostId,
  setConfirmModal,
}: Props) {
  const status = findValueByKey(data.metaData, 'contactStatus');
  const answer = findValueByKey(data.metaData, 'contactAnswer');
  const attachment = findValueByKey(data.metaData, 'contactAnswerFile1');

  return (
    <div className="w-full rounded-b-sm bg-white">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between rounded-t-sm px-5 py-5 2xl:px-10 2xl:py-7">
              <div className="flex space-x-5 md:space-x-7 lg:space-x-8 xl:space-x-10 2xl:space-x-12 items-center w-full">
                <div
                  className={`${
                    status === 'request' ? 'bg-[#FF9B70]' : 'bg-primary'
                  } py-3 rounded-[10px] min-w-[52px] sm:min-w-[66px] xl:w-[6%] text-white font-medium text-xs sm:text-sm xl:text-base`}>
                  {status === 'request' ? '진행중' : '완료'}
                </div>
                <div className="flex max-sm:flex-col sm:justify-between w-full items-start sm:items-center pr-8">
                  <span className="text-darkSecondary whitespace-nowrap text-sm lg:text-base xl:text-lg font-semibold">
                    {data.postTitle}
                  </span>
                  <span className="text-[#666] text-[13px] sm:text-sm font-semibold">
                    {moment(data.registerDate).format('YYYY.MM.DD')}
                  </span>
                </div>
              </div>
              <ExpandMoreIcon open={open} />
            </Disclosure.Button>
            <Disclosure.Panel className="w-full flex flex-col items-end space-y-5 px-5 2xl:px-10 py-6 lg:py-8 bg-[#f6f6f6]">
              <div className="w-full bg-white rounded-md p-4 sm:p-5 flex flex-col space-y-3">
                <h5 className="text-sm 2xl:text-base font-semibold text-[#FF9B70]">
                  문의 내용
                </h5>
                <div
                  className="text-darkSecondary w-full text-sm md:text-base prose sm:prose-sm md:prose-base max-w-none no-heading whitespace-pre-wrap"
                  dangerouslySetInnerHTML={{ __html: data.postContent }}
                />
              </div>
              {answer && (
                <div className="w-full bg-white rounded-md p-5 flex flex-col items-start space-y-3">
                  <h5 className="text-sm 2xl:text-base font-semibold text-primary">
                    답변 내용
                  </h5>
                  <div
                    className="text-darkSecondary w-full text-sm md:text-base prose sm:prose-sm md:prose-base max-w-none no-heading whitespace-pre-wrap"
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                  {attachment && (
                    <a
                      href={attachment}
                      download
                      target="_blank"
                      rel="noreferrer"
                      className="hover:underline underline-offset-2 text-primary font-medium text-sm">
                      첨부파일
                    </a>
                  )}
                </div>
              )}
              <button
                onClick={() => {
                  setPostId(data.postId);
                  setConfirmModal(true);
                }}
                className="text-sm font-medium underline underline-offset-2 max-sm:text-rose-500 sm:hover:text-rose-500 duration-150">
                문의삭제
              </button>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
