import { useEffect, useState } from 'react';
import { inquiryFilterArray } from '../../../../arrays';
import {
  delContact,
  getContactList,
} from '../../../../networks/public.service';
import { InquiryDataType } from '../../../../types_new/main/landing/types';
import Pagination from '../../../common/Pagination';
import ConfirmModal from '../../../modals/confirm-modal';
import InformModal from '../../../modals/inform-modal';
import InfoFilterSection from '../info-filter-section';
import NoListCard from '../no-list-card';
import InquiryCardSection from './inquiry-card-section';
import InquirySkeleton from './inquiry-skeleton';

export default function InquiryInfo() {
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [filter, setFilter] = useState(inquiryFilterArray[0]);
  const [paged, setPaged] = useState(1);
  const [inquiryHistory, setInquiryHistory] = useState<InquiryDataType[]>([]);
  const [total, setTotal] = useState(0);
  const [postId, setPostId] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [informModal, setInformModal] = useState(false);
  const [parentPage, setParentPage] = useState(1);

  const deleteInquiry = () => {
    setDeleteLoading(true);
    delContact(postId)
      .then(handleSuccess)
      .catch(handleFail)
      .finally(() => setDeleteLoading(false));

    function handleSuccess() {
      loadInquiryData();
      setConfirmModal(false);
    }

    function handleFail() {
      setConfirmModal(false);
      setInformModal(true);
    }
  };

  const loadInquiryData = () => {
    setLoading(true);
    getContactList({
      postsPerPage: 10,
      paged,
      contactStatus: filter.value,
    })
      .then(({ data: { body } }) => {
        setTotal(body.total);
        setInquiryHistory(body.posts);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const onPageChange = (paged: number) => {
    setPaged(paged);
    setParentPage(paged);
  };

  useEffect(() => {
    loadInquiryData();
  }, [filter, paged]);

  return (
    <>
      <InformModal
        isOpen={informModal}
        title="삭제 실패"
        message="잠시 후 다시 시도해주세요."
        closeModal={() => setInformModal(false)}
      />
      <ConfirmModal
        isOpen={confirmModal}
        title="문의 삭제"
        message={'문의 내용을 삭제하시겠습니까?\n해당 내용은 복구 불가합니다.'}
        closeModal={() => setConfirmModal(false)}
        handleOnClick={deleteInquiry}
        loading={deleteLoading}
      />
      <section className="text-darkSecondary max-w-[1278px] w-full px-5">
        <InfoFilterSection
          total={total}
          filterArray={inquiryFilterArray}
          setFilter={setFilter}
          filter={filter}
        />
        {loading ? (
          <InquirySkeleton />
        ) : inquiryHistory.length ? (
          <>
            <InquiryCardSection
              list={inquiryHistory}
              setPostId={setPostId}
              setConfirmModal={setConfirmModal}
            />
            <Pagination
              onPageChange={onPageChange}
              totalItems={total}
              currentPage={paged}
              parentPage={parentPage}
              itemsPerPage={10}
            />
          </>
        ) : (
          <NoListCard text="1:1 문의" />
        )}
      </section>
    </>
  );
}
