/* eslint-disable */
import { atom } from 'recoil';
import {
  IBackground,
  IImage,
  IPages,
  IPreviewElement,
  ISegment,
  ISegmentsSend,
  ITitle,
} from './types';

export const currentCredit = atom({
  key: 'currentCredit',
  default: 0,
});

export const timelinePercentage = atom({
  key: 'timelinePercentage',
  default: 20,
});

export const translateLoading = atom({
  key: 'translateLoading',
  default: false,
});

export const projectLang = atom({
  key: 'projectLang',
  default: 'ko',
});

export const dialogue = atom({
  key: 'dialogue',
  default: false,
});

export const metaGroup = atom({
  key: 'group',
  default: [] as any,
});

export const metaItemPos = atom({
  key: 'metaItem',
  default: 0,
});

export const overlapped = atom({
  key: 'overlappedItems',
  default: {} as any,
});

export const segments = atom({
  key: 'allSegments',
  default: {} as any,
});

export const markerPos = atom({
  key: 'markerX',
  default: 0,
});

export const previewBgSource = atom({
  key: 'backgroundSource',
  default: '',
});

export const pages = atom<IPages[]>({
  key: 'pages',
  default: [] as any,
});
export const pageHistory = atom<IPages[]>({
  key: 'pageHistory',
  default: [] as any,
});
export const segmentsItem = atom<IPreviewElement>({
  key: 'segmentsItem',
  default: [] as any,
});
export const setPagesInfo = (setPage: any, value: IPages[]) => {
  setPage(value);
};

export const centiSecond = atom<number>({
  key: 'centiSecond',
  default: 0,
});

export const marker = atom<number>({
  key: 'marker',
  default: {} as any,
});

export const additionalTarget = atom<any>({
  key: 'additionalTarget',
  default: { insertT: 0, name: 'marker' } as any,
});

export const seletItem = atom<IImage | ISegment>({
  key: 'seletItem',
  default: [] as any,
});

export const cutAudioItem = atom<ISegment[]>({
  key: 'cutAudioItem',
  default: [] as any,
});
export const cutvideoItem = atom<ISegment[]>({
  key: 'cutvideoItem',
  default: [] as any,
});
export const cutBgVideo = atom<IBackground[]>({
  key: 'cutBgVideo',
  default: [] as any,
});
export const cutBgAudio = atom<IBackground[]>({
  key: 'cutBgAudio',
  default: [] as any,
});
export const cutTitle = atom<ITitle[]>({
  key: 'cutTitle',
  default: [] as any,
});
export const cutImage = atom<IImage[]>({
  key: 'cutImage',
  default: [] as any,
});
export const cutBgColorImage = atom<IBackground[]>({
  key: 'cutBgColorImage',
  default: [] as any,
});

export const selectTransition = atom({
  key: 'selectTransition',
  default: [] as any,
});

export const selectActive = atom({
  default: false,
  key: 'selectActive',
});

export const ratio = atom({
  default: 0,
  key: 'ratio',
});
export const rails = atom({
  key: 'rails',
  default: [] as any,
});
export const autoScript = atom({
  key: 'autoScript',
  default: [] as any,
});

export const setSegmentsInfo = (
  page: IPages[],
  setPage: any,
  type: ISegmentsSend
) => {
  const { pagesIndex } = type;

  const tmpPage = JSON.parse(JSON.stringify(page));

  if (type.segments) {
    const { segmentsArray, segments } = type.segments;
    if (segmentsArray) {
      tmpPage[pagesIndex].segments[0] = segmentsArray;
    }
    if (segments) {
      tmpPage[pagesIndex].segments[0] = segments;
    }
  }

  if (type.segment) {
    const {
      segmentArray,
      segmentIndex,
      segment,
      imageArray,
      imageIndex,
      image,
    } = type.segment;
    if (segmentArray) {
      tmpPage[pagesIndex].segments[0].segment = segmentArray;
    }
    if (segmentIndex !== undefined && segment) {
      tmpPage[pagesIndex].segments[0].segment[segmentIndex] = segment;
    }
  }

  if (type.image) {
    const { imageArray, imageIndex, image } = type.image;
    if (imageArray) {
      tmpPage[pagesIndex].segments[0].image = imageArray;
    }
    if (imageIndex !== undefined && image) {
      tmpPage[pagesIndex].segments[0].image[imageIndex] = image;
    }
  }

  if (type.title) {
    const { titleArray, titleIndex, title } = type.title;
    if (titleArray) {
      tmpPage[pagesIndex].segments[0].title = titleArray;
    }
    if (titleIndex !== undefined && title) {
      tmpPage[pagesIndex].segments[0].title[titleIndex] = title;
    }
  }

  if (type.background) {
    const { backgroundArray, backgroundIndex, background } = type.background;
    if (backgroundArray) {
      tmpPage[pagesIndex].segments[0].background = backgroundArray;
    }
    if (backgroundIndex !== undefined && background) {
      tmpPage[pagesIndex].segments[0].background[backgroundIndex] = background;
    }
  }

  // This is the one that makes copy available
  setPage(tmpPage);

  return tmpPage;
};

export const getPagesInfo = (
  page: IPages[],
  pagesKey?: number,
  pagesGroup?: 'fadeIn' | 'fadeOut' | 'segments',
  segmentsKey?: number,
  segmentsGroup?: 'segment' | 'title' | 'background' | 'image',
  key?: number
) => {
  if (
    pagesKey === undefined &&
    pagesGroup === undefined &&
    segmentsKey === undefined &&
    segmentsGroup === undefined &&
    key === undefined
  ) {
    return page;
  }
  if (
    pagesKey !== undefined &&
    pagesGroup === undefined &&
    segmentsKey === undefined &&
    segmentsGroup === undefined &&
    key === undefined
  ) {
    return page[pagesKey];
  }
  if (
    pagesKey !== undefined &&
    pagesGroup &&
    segmentsKey === undefined &&
    segmentsGroup === undefined &&
    key === undefined
  ) {
    return page[pagesKey][pagesGroup];
  }
  if (
    pagesKey !== undefined &&
    pagesGroup &&
    segmentsKey !== undefined &&
    segmentsGroup === undefined &&
    key === undefined
  ) {
    const tmpValue = JSON.parse(JSON.stringify(page[pagesKey][pagesGroup]));
    return tmpValue[segmentsKey];
  }
  if (
    pagesKey !== undefined &&
    pagesGroup &&
    segmentsKey !== undefined &&
    segmentsGroup &&
    key === undefined
  ) {
    const tmpValue = JSON.parse(JSON.stringify(page[pagesKey][pagesGroup]));

    return tmpValue[segmentsKey][segmentsGroup];
  }
  if (
    pagesKey !== undefined &&
    pagesGroup &&
    segmentsKey !== undefined &&
    segmentsGroup &&
    key !== undefined
  ) {
    const tmpValue = JSON.parse(JSON.stringify(page[pagesKey][pagesGroup]));
    return tmpValue[segmentsKey][segmentsGroup][key];
  }
};

// example
export const pageInfomation = [
  {
    fadeIn: 0,
    fadeOut: 0,
    segments: [
      {
        segment: [
          {
            segmentId: 'string',
            mediaType: 'string',
            contentId: 'string',
            fileId: 'string',
            fileName: 'string',
            filePath: 'string',
            source: 'string',
            title: 'string',
            videoSize: 'string',
            rank: 1,
            insertTime: 1,
            duration: 1,
            startTime: 1,
            endTime: 1,
            fadeIn: {
              type: 'string',
              speed: 1,
            },
            fadeOut: {
              type: 'string',
              speed: 1,
            },
            width: 1,
            height: 1,
            posX: 1,
            posY: 1,
            chromakey: 'string',
          },
        ],
        title: [
          {
            titleId: 'string',
            text: 'string',
            insertTime: 1,
            duration: 1,
            rank: 1,
            style: {
              backgroundColor: 'string',
              color: 'string',
              fontFamily: 'string',
              fontSize: 'string',
              fontWeight: 'string',
              fontStyle: 'string',
              textAlign: 'string',
              textDecoration: 'string',
              letterSpacing: 'string',
            },
            posX: 1,
            posY: 1,
          },
        ],
        background: [
          {
            backgroundId: 'string',
            type: 'string',
            contentId: 'string',
            color: 'string',
            sourceURL: 'string',
            sourcePath: 'string',
            rank: 1,
            insertTime: 1,
            duration: 1,
            startTime: 1,
            endTime: 1,
            fadeIn: {
              type: 'string',
              speed: 1,
            },
            fadeOut: {
              type: 'string',
              speed: 1,
            },
          },
        ],
        image: [
          {
            imageId: 'string',
            imageUrl: 'string',
            imagePath: 'string',
            rank: 1,
            posX: 1,
            posY: 1,
            width: 1,
            height: 1,
            insertTime: 1,
            duration: 1,
          },
        ],
      },
    ],
  },
];
