import axios from 'axios';
import { getStorageToken } from './store/member';

class APIService {
  constructor() {
    this.token = getStorageToken();
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_SERVER_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    /**
     * 아래 배열에 소속된 url의 경우는 token을 넣지 않는다.
     */
    const SKIP_PAGES = ['public/auth/sign', 'public/auth/isaccount'];
    const isSkipPages = url =>
      SKIP_PAGES.some(page => url.indexOf(page) !== -1);
    this.instance.interceptors.request.use(
      config => {
        if (isSkipPages(config?.url)) {
          console.log('[api] request, skip token');
          return config;
        }
        if (config.token) {
          config.headers.Authorization = 'Bearer ' + config.token; // for Spring Boot back-end
        } else if (this.token) {
          config.headers.Authorization = 'Bearer ' + this.token; // for Spring Boot back-end
        } else {
          console.error(
            '%c[api] token not found!!!!',
            'color: tomato; font-weight: bold;'
          );
        }
        return config;
      },
      error => {
        console.log('[interceptors request] error:' + JSON.stringify(error));
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      res => {
        return res;
      },
      async err => {
        return Promise.reject(err);
      }
    );
  }

  setToken(tToken) {
    this.token = tToken;
  }

  getToken() {
    return this.token;
  }

  get(...args) {
    return this.instance.get(...args);
  }

  post(...args) {
    return this.instance.post(...args);
  }

  put(...args) {
    return this.instance.put(...args);
  }

  patch(...args) {
    return this.instance.patch(...args);
  }

  delete(...args) {
    return this.instance.delete(...args);
  }
}

export default new APIService();
