import axios from 'axios';
import apiConfig from './apiConfig.json';
import {
  getStoragePaysystemMemberId,
  getStoragePaysystemToken,
  updateStoragePaysystemToken,
} from './store/paysystem';

const initInstance = () => {
  const authToken = getStoragePaysystemToken();
  console.log('[apiPaySystem] authToken:' + authToken);
  if (authToken) {
    axios.defaults.headers.common = {
      'auth-token': authToken,
    };
  }

  const defaultAxios = axios.create({
    url: process.env.REACT_APP_PAY_SERVER_URL,
    auth: {
      username: process.env.REACT_APP_PAYSYS_AUTH_USER,
      password: process.env.REACT_APP_PAYSYS_AUTH_PW,
    },
  });
  defaultAxios.defaults.baseURL = process.env.REACT_APP_PAY_SERVER_URL;

  return defaultAxios;
};
const instance = initInstance();

instance.interceptors.request.use(
  config => {
    // const isAuthPageRequested = isAuthPages(config?.url);
    // if (isAuthPageRequested) return config;

    const token = getStoragePaysystemToken();
    if (config.token) {
      config.headers.Authorization = 'Bearer ' + config.token; // for Spring Boot back-end
      // config.headers["x-access-token"] = token; // for Node.js Express back-end
    } else if (token) {
      config.headers.Authorization = 'Bearer ' + token; // for Spring Boot back-end
    } else {
      console.error('[api] token not found!!!!');
    }
    return config;
  },
  error => {
    console.log('[interceptors request] error:' + JSON.stringify(error));
    return Promise.reject(error);
  }
);

const AUTH_PAGES = ['/api/bo/sign'];
const isAuthPages = url => AUTH_PAGES.some(page => page.startsWith(url));

instance.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const { config } = err;
    const originalConfig = config;
    console.log('[interceptors res][error] err:' + JSON.stringify(err));
    const isAuthPageRequested = isAuthPages(config?.url);

    if (!isAuthPageRequested && err.response?.status === 401) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const memberid = getStoragePaysystemMemberId();
          console.log('[interceptors error] memberid:' + memberid);
          if (!memberid) {
            return Promise.reject(err);
          }

          const userStr = 'anonymous_' + memberid;
          const base64Userid = window.btoa(userStr);
          console.log('[interceptors] userStr:' + userStr);
          console.log('[interceptors] base64Userid:' + base64Userid);

          const signinUrl = apiConfig.paysystem.signIn + base64Userid;

          const res = await instance.get(signinUrl);
          res.then((res) => {
            if (res.status === 200 && updateStoragePaysystemToken(res.data)) {
              console.log('paysystem token update complete!!!!');
              return instance(originalConfig);
            }
            return Promise.reject(new Error('토큰 업데이트 오류 발생.'));
          })
        } catch (_error) {
          return Promise.reject(err);
        }
      }
    }
    return Promise.reject(err);
  });

export default instance;
