import { Dispatch, SetStateAction } from 'react';
import { InquiryDataType } from '../../../../types_new/main/landing/types';
import InquiryCard from './inquiry-card';

type Props = {
  list: InquiryDataType[];
  setPostId: Dispatch<SetStateAction<number>>;
  setConfirmModal: Dispatch<SetStateAction<boolean>>;
};

export default function InquiryCardSection({
  list,
  setPostId,
  setConfirmModal,
}: Props) {
  return (
    <ul className="flex flex-col space-y-1 md:space-y-2 xl:space-y-3 mt-5 sm:mt-7 lg:mt-10 xl:mt-12 2xl:mt-16">
      {list.map((data, idx) => (
        <li className="sm:hover:shadow-md duration-300" key={`faq-${idx}`}>
          <InquiryCard
            data={data}
            setPostId={setPostId}
            setConfirmModal={setConfirmModal}
          />
        </li>
      ))}
    </ul>
  );
}
