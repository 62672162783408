export default function PencilIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.88867 10.6987L11.0467 2.54003C11.369 2.23161 11.7992 2.06163 12.2453 2.06651C12.6913 2.07138 13.1178 2.25072 13.4333 2.56611C13.7487 2.8815 13.9282 3.30788 13.9332 3.75395C13.9382 4.20002 13.7683 4.63031 13.46 4.9527L5.30067 13.1114C5.11453 13.2975 4.87746 13.4244 4.61933 13.476L2 14L2.524 11.38C2.57563 11.1219 2.70252 10.8848 2.88867 10.6987V10.6987Z"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.66663 4.33301L11.6666 6.33301" stroke="#666666" />
    </svg>
  );
}
