import { Dispatch, SetStateAction } from 'react';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import {
  MetahumanListType,
  MetahumanTabType,
} from '../../../types_new/main/resource/types';
import MetahumanCard from './metahuman-card';
import MetahumanSkeleton from './metahuman-skeleton';

type Props = {
  loading: boolean;
  setTab: Dispatch<SetStateAction<MetahumanTabType>>;
  metahumanList: MetahumanListType[];
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  projectInfo: NewProjectInfoType;
};
export default function MetahumanGrid({
  loading,
  setTab,
  metahumanList,
  setProjectInfo,
  projectInfo,
}: Props) {
  return (
    <div className="relative">
      {loading ? (
        <MetahumanSkeleton />
      ) : (
        <ul className="pr-5 2xl:pr-6 grid grid-cols-3 gap-y-8 gap-x-7 max-h-72 2xl:max-h-80 h-full overflow-y-auto pt-1.5 pb-4">
          {metahumanList?.map((item, idx) => (
            <li key={`metahuman-${idx}`}>
              <MetahumanCard
                setTab={setTab}
                setProjectInfo={setProjectInfo}
                projectInfo={projectInfo}
                metahuman={item}
              />
            </li>
          ))}
        </ul>
      )}
      <div className="absolute bottom-0 w-full h-5 bg-gradient-to-t from-white/90 to-white/0" />
    </div>
  );
}
