import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateGalleryParamsType } from '../../../networks/gallery.service';
import { getLabelByValue } from '../../../utils/common';

const typeArray = [
  { label: '전체', value: '' },
  {
    label: '유료',
    value: 'N',
  },
  {
    label: '무료',
    value: 'Y',
  },
];

type Props = {
  setParams: Dispatch<SetStateAction<TemplateGalleryParamsType>>;
  params: TemplateGalleryParamsType;
};

export default function PayTypeSelectBox({ setParams, params }: Props) {
  const { t } = useTranslation();

  const handleOnChange = (e: any) => {
    if (e.value === params.freeYn) return;
    setParams((prev: any) => ({
      ...prev,
      freeYn: e.value,
    }));
  };

  return (
    <Listbox value={params.freeYn} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative w-40 text-darkSecondary">
          <Listbox.Button className="relative w-full flex items-center justify-between cursor-pointer rounded-md border border-[#D4DEEA] bg-white py-2 px-3 text-left">
            <span className="block truncate text-sm">
              {t(getLabelByValue(params.freeYn, typeArray))}
            </span>
            <i className={`${open && 'rotate-180'} duration-300`}>
              <FilterChevron />
            </i>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="ease-in-out duration-100"
            enterFrom="opacity-0 -translate-y-2"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-100"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-2">
            <Listbox.Options className="absolute mt-1 z-10 max-h-72 w-full overflow-auto rounded-md bg-white py-1 shadow-lg focus:outline-none">
              {typeArray.map((list, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `text-sm md:text-base relative select-none py-2 px-4 cursor-pointer ${
                      active && 'bg-primary/10 text-primary font-medium'
                    }`
                  }
                  value={list}>
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate text-sm ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}>
                        {t(list.label)}
                      </span>
                      {selected && (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-2 text-primary">
                          <CheckIcon className="h-4 w-4" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

function FilterChevron() {
  return (
    <svg
      width="6"
      height="4"
      viewBox="0 0 6 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.72482 3.69988L0.326824 0.958883C0.0438238 0.636383 0.273824 0.129883 0.703324 0.129883H5.49932C5.59545 0.129801 5.68955 0.157427 5.77038 0.209453C5.85121 0.26148 5.91532 0.335701 5.95505 0.423229C5.99478 0.510757 6.00843 0.607882 5.99438 0.702972C5.98033 0.798061 5.93917 0.887085 5.87582 0.959383L3.47782 3.69938C3.43089 3.75309 3.37302 3.79613 3.30808 3.82562C3.24314 3.85511 3.17264 3.87037 3.10132 3.87037C3.03 3.87037 2.95951 3.85511 2.89457 3.82562C2.82963 3.79613 2.77175 3.75309 2.72482 3.69938V3.69988Z"
        fill="#17233F"
      />
    </svg>
  );
}
