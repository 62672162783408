type Props = { isSmall?: boolean };

export default function TemplateBadge({ isSmall }: Props) {
  return (
    <svg
      className={`absolute ${
        isSmall ? 'w-7 h-7 left-0.5 top-0.5' : 'w-10 h-10 left-1 top-1'
      }`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      fill="none">
      <g filter="url(#filter0_d_4662_822)">
        <rect x="4" y="4" width="24" height="24" rx="6" fill="#7B61FF" />
        <path
          d="M14.6096 22V13.728H11.3296V10.8H21.6656V13.728H18.3856V22H14.6096Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4662_822"
          x="0"
          y="0"
          width="40"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="4" dy="4" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4662_822"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4662_822"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
