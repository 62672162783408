import { useEffect, useState } from 'react';
import { templateApplyStatusArray } from '../../../../arrays';
import { getTemplateRequestHistory } from '../../../../networks/template.service';
import { TemplateDataType } from '../../../../types_new/main/resource/types';
import { TemplateApplyStatusType } from '../../../../types_new/main/service/types';
import Pagination from '../../../common/Pagination';
import InfoFilterSection from '../info-filter-section';
import NoListCard from '../no-list-card';
import MobileTemplatePublishing from './mobile-template-publishing-table';
import TemplatePublishingTable from './template-publishing-table';

const POSTS_PER_PAGE = 20;

export default function TemplatePublishingInfo() {
  const [title, setTitle] = useState('');
  const [filter, setFilter] = useState<{
    label: string;
    value: TemplateApplyStatusType | any;
  }>(templateApplyStatusArray[0]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const [templatePublishHistory, setTemplatePublishHistory] = useState<
    TemplateDataType[]
  >([]);
  const isNoResult = !loading && !total;

  useEffect(() => {
    loadData();
  }, [filter, paged, title]);

  const loadData = () => {
    if (!loading) setLoading(true);
    getTemplateRequestHistory({
      paged,
      postsPerPage: POSTS_PER_PAGE,
      applyStatus: filter.value,
      title,
    })
      .then(({ posts, total }) => {
        setTemplatePublishHistory(posts);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const handleOnPageChange = (page: number) => {
    setPaged(page);
    setParentPage(page);
  };

  return (
    <section className="text-darkSecondary max-w-[1278px] w-full px-5 ">
      <InfoFilterSection
        total={templatePublishHistory.length}
        filterArray={templateApplyStatusArray}
        setFilter={setFilter}
        filter={filter}
        hasInput
        setTitle={setTitle}
      />

      {templatePublishHistory.map(data => (
        <MobileTemplatePublishing key={data.postId} data={data} />
      ))}

      {isNoResult ? (
        <NoListCard text="영상배포 신청" />
      ) : (
        <TemplatePublishingTable
          templatePublishData={templatePublishHistory}
          loading={loading}
          loadData={loadData}
        />
      )}
      <Pagination
        itemsPerPage={POSTS_PER_PAGE}
        currentPage={paged}
        totalItems={total}
        onPageChange={handleOnPageChange}
        parentPage={parentPage}
      />
    </section>
  );
}
