import { useTranslation } from 'react-i18next';
import '../../locale/i18n';

export default function OperationTerms() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="pop-bg show"></div>
      <div className="terms-pop-wrap2 show">
        <div className="pop-close-btn">
          <img src="/main/img/icon-close-btn.svg" alt="" />
        </div>
        <div className="scroll-box pop-body">
          <p className="text-black">
            <dl>
              {t(
                '본 운영정책은 서비스 운영기준과 가입자가 지켜야 할 사항들을 규정합니다. 운영정책이 변경되는 경우 플루닛 웹페이지를 통해 최소 7일 전에 공지됩니다.'
              )}
            </dl>
            <strong>1. {t('서비스 시간')}</strong>
            <dl>
              {t(
                '회사의 서비스는 연중무휴, 이십사 시간 가능한 것을 원칙으로 합니다. 서비스 중단이 필요한 경우 회사는 가입자에게 사전 고지 후 지정된 기간 동안 서비스를 중단할 수 있습니다.'
              )}
            </dl>
            <strong>2. {t('개인정보 처리')}</strong>
            <dl>
              {t(
                '회사는 개인정보처리방침을 통해 가입자에게 개인정보 처리에 관한 절차 및 기준을 안내합니다.'
              )}{' '}
            </dl>
            <strong>3. {t('금지 행위')}</strong>
            <p>
              {t(
                '가입자가 다음의 내용을 서비스를 이용하여 생성 또는 전송하는 것을 금지합니다.'
              )}
            </p>
            <p>1. {t('위법 행위')}</p>
            <dl>
              <dt>①</dt>
              <dd>{t('타인의 개인정보를 동의 없이 탈취 및 공개하는 행위')}</dd>
              <dt>②</dt>
              <dd>
                {t(
                  '타인을 기망하거나 위협하여 재산상의 이익을 갈취 또는 편취하는 행위'
                )}
              </dd>
              <dt>③</dt>
              <dd>{t('허위사실을 유포하는 행위')}</dd>
              <dt>④</dt>
              <dd>{t('불법적인 도박 사이트를 홍보하는 행위')}</dd>
              <dt>⑤</dt>
              <dd>{t('불법 제품을 판매 및 홍보하는 행위')}</dd>
              <dt>⑥</dt>
              <dd>{t('법령에 위반되는 행위를 조장하는 행위')}</dd>
            </dl>
            <p>2. {t('저작권 침해')}</p>
            <dl>
              <dt>①</dt>
              <dd>{t('저작권 보호 기간이 만료되지 않은 저작물 침해 행위')}</dd>
              <dt>②</dt>
              <dd>{t('저작권자의 동의 없이 저작물을 이용하는 행위')}</dd>
              <dt>③</dt>
              <dd>{t('타인의 상표권, 의장권 등을 침해하는 행위')}</dd>
              <dt>④</dt>
              <dd>
                {t(
                  '저작권자로부터 권리침해 신고가 접수된 저작물에 대한 침해 행위'
                )}
              </dd>
            </dl>
            <p>3. {t('성 관련 콘텐츠')}</p>
            <dl>
              <dt>①</dt>
              <dd>{t('과도한 신체 노출이나 성적 행위를 묘사하는 행위')}</dd>
              <dt>②</dt>
              <dd>{t('성적 수치심 또는 불쾌감을 유발하는 행위')}</dd>
              <dt>③</dt>
              <dd>{t('타인의 성을 착취하거나 이용하려는 행위')}</dd>
              <dt>④</dt>
              <dd>
                {t('동의 없는 성행위를 묘사, 미화, 요청, 일상화하는 행위')}
              </dd>
              <dt>⑤</dt>
              <dd>{t('성매매 관련 정보를 공유하는 행위')}</dd>
            </dl>
            <p>4. {t('청소년 유해 콘텐츠')}</p>
            <dl>
              <dt>①</dt>
              <dd>
                {t(
                  '과도한 욕설, 폭력적인 언행 등 청소년의 정서를 저해하는 행위'
                )}
              </dd>
              <dt>②</dt>
              <dd>
                {t('담배, 술, 전자담배 등 청소년 유해 물건을 홍보하는 행위')}
              </dd>
            </dl>
            <p>5. {t('미풍양속 위배')}</p>
            <dl>
              <dt>①</dt>
              <dd>{t('과도한 폭력 행위')}</dd>
              <dt>②</dt>
              <dd>{t('선정적이고 저속한 표현을 사용하는 행위')}</dd>
              <dt>③</dt>
              <dd>{t('자살, 자해 등의 신체적 훼손을 방조하는 행위')}</dd>
              <dt>④</dt>
              <dd>{t('과도한 욕설, 은어, 비속어를 사용하는 행위')}</dd>
            </dl>
            <p>6. {t('타인비하 및 증오행동')}</p>
            <dl>
              <dt>①</dt>
              <dd>
                {t(
                  '나이, 국적, 인종, 민족, 출신, 언어, 종교, 사회경제적 지위, 성별, 성적 정체성, 성적 지향, 질병 유무, 또는 기타 정체성요인 등을 이유로 차별을 조장하거나 폭력을 요청 또는 정당화하는 행위'
                )}
              </dd>
            </dl>
            <p>7. {t('서비스 저해')}</p>
            <dl>
              <dt>①</dt>
              <dd>
                {t(
                  '바이러스, 악성코드, 웜 등을 이용하거나 배포하여 서비스 이용을 저해하는 행위'
                )}
              </dd>
              <dt>②</dt>
              <dd>
                {t(
                  '플루닛 관련 네트워크를 해킹하거나 보안 조치를 우회하는 행위'
                )}
              </dd>
              <dt>③</dt>
              <dd>
                {t(
                  '동일하거나 유사한 내용의 정보를 지속적으로 보내 가입자들의 서비스 이용을 방해하는 행위'
                )}
              </dd>
            </dl>
            <strong>4. {t('제재 조치')}</strong>
            <dl>
              {t(
                '위 사항을 위반할 경우 회사는 가입자의 서비스 및 계정 이용을 제한할 수 있습니다. “금지 행위”에 명시되지 않은 사항이더라도 가입자들의 서비스 이용 또는 회사의 서비스 운영에 지장을 줄 것으로 판단되는 경우 가입자의 서비스 및 계정 이용을 제한할 수 있습니다.'
              )}{' '}
            </dl>
            <p>
              {t(
                '회사는 약관 및 운영정책 위반 횟수에 따라 일시적 제한에서 영구적 제한으로 단계적인 대응을 원칙으로 합니다. 하지만 아래와 같이 신속한 피해 차단이 필요한 경우 위반 누적 횟수와 관계없이 즉시 영구 이용정지 처리할 수 있습니다.'
              )}
            </p>
            <dl>
              <dt>1.</dt>
              <dd>{t('아동 청소년을 학대, 착취 또는 위험에 빠드리는 행위')}</dd>
              <dt>2.</dt>
              <dd>
                {t(
                  '아동 청소년을 대상으로 한 성범죄, 이를 조장하는 행위, 또는 이를 이용하려는 의사 표현을 하는 행위'
                )}
              </dd>
              <dt>3.</dt>
              <dd>{t('음란한 내용을 게시, 생성 또는 전송하는 행위')}</dd>
              <dt>4.</dt>
              <dd>
                {t(
                  '플루닛 통합계정 서비스 및 개별 서비스운영을 심각하게 방해하는 행위'
                )}
              </dd>
              <dt>5.</dt>
              <dd>{t('명백한 불법적인 행위')}</dd>
            </dl>
            <strong>5. {t('제재 이의 신청')}</strong>
            <dl>
              {t(
                '제재에 대해 이의가 있을 경우 고객센터를 통해 이의 신청을 할 수 있습니다. 신청 내용 검토 후 조치 결과에 대해 안내해 드립니다.'
              )}
            </dl>
            <strong>6. {t('문의사항')}</strong>
            <dl>
              {t('운영정책 관련 사항은 고객센터를 통해 문의 가능합니다.')}
            </dl>
            <strong>7. {t('시행일')}</strong>
            <dl>{t('운영정책은 서비스가 시작되는 시점에서 적용됩니다.')}</dl>
          </p>
        </div>
      </div>
    </div>
  );
}
