import axios from 'axios';
import moment from 'moment-timezone';
import { atom } from 'recoil';
import { IPaySystemSubscribeData } from '../dto/IPaySystemDto';
import { localStorageEffect, sessionStorageEffect } from './atomUtil';

const PAY_MEMBERINFO = 'paySystemMember';
const PAY_SUBSCRIBEINFO = 'paySubscribeInfo';

interface IPaySystemMemberInfo {
  paySystemMemberId: string;
  paySystemMemberSeq: string;
  paySystemToken: string;
  paySystemCompanySeq: string;
  paySystemExpiredDate: string;
}

const dummyPayInfo: IPaySystemMemberInfo = {
  paySystemCompanySeq: '',
  paySystemMemberId: '',
  paySystemExpiredDate: '',
  paySystemToken: '',
  paySystemMemberSeq: ''
}

const paySystemMemberAtom = atom({
  key: PAY_MEMBERINFO,
  default: {} as IPaySystemMemberInfo,
  effects: [localStorageEffect(PAY_MEMBERINFO)],
});

const paySubscribeInfoAtom = atom<IPaySystemSubscribeData>({
  key: PAY_SUBSCRIBEINFO,
  default: {} as IPaySystemSubscribeData,
  effects: [sessionStorageEffect(PAY_SUBSCRIBEINFO)],
});

/**
 * storage에 저장된 paysystem의 token을 가져온다.
 * axios api에서 사용할 수 있도록 제공
 * @returns
 */
const getStoragePaysystemToken = () => {
  const storageStr = localStorage.getItem(PAY_MEMBERINFO);
  if (!storageStr) {
    console.error('[paysystem store] localstorage paySystemMember not found');
    return null;
  }
  const paySystemMember = JSON.parse(storageStr);
  return paySystemMember?.paySystemToken;
};

/**
 * storage에 저장된 paysystem의 token을 업데이트 한다.
 * axios api에서 사용할 수 있도록 제공
 * @returns
 */
const updateStoragePaysystemToken = (args: IPaySystemMemberInfo) => {
  const storageStr = localStorage.getItem(PAY_MEMBERINFO);
  if (!storageStr) {
    console.error('[paysystem store] localstorage paySystemMember not found');
    return null;
  }
  const paySystemMember = JSON.parse(storageStr);
  console.log('[updateStoragePaysystemToken] storage token 업데이트 -------');
  console.log(
    '[updateStoragePaysystemToken] 기존 token: paySystemMember.paySystemToken'
  );
  paySystemMember.paySystemToken = args.paySystemToken;
  console.log(
    '[updateStoragePaysystemToken] 새 token: paySystemMember.paySystemToken'
  );
  localStorage.setItem(PAY_MEMBERINFO, JSON.stringify(paySystemMember));
};

/**
 * storage에 저장된 paysystem의 memberId을 가져온다.
 * axios api에서 사용할 수 있도록 제공
 * @returns
 */
const getStoragePaysystemMemberId = () => {
  const storageStr = localStorage.getItem(PAY_MEMBERINFO);
  if (!storageStr) {
    console.error('[paysystem store] localstorage paySystemMember not found');
    return null;
  }
  const paySystemMember = JSON.parse(storageStr);
  return paySystemMember?.paySystemMemberId;
};

/**
 * storage에 저장된 paysystem의 companySeq을 가져온다.
 * axios api에서 사용할 수 있도록 제공
 * @returns
 */
const getStoragePaysystemCompanySeq = () => {
  const storageStr = localStorage.getItem(PAY_MEMBERINFO);
  if (!storageStr) {
    console.error('[paysystem store] localstorage paySystemMember not found');
    return null;
  }
  const paySystemMember = JSON.parse(storageStr);
  return paySystemMember?.paySystemCompanySeq;
};

export {
  getStoragePaysystemCompanySeq,
  dummyPayInfo,
  getStoragePaysystemMemberId,
  getStoragePaysystemToken,
  paySubscribeInfoAtom,
  paySystemMemberAtom,
  updateStoragePaysystemToken,
};

export type { IPaySystemMemberInfo };
