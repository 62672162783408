type Props = {
  className?: string;
};
export default function SelectBoxArrowIcon({ className }: Props) {
  return (
    <svg
      className={className}
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.52932 6.96152L1.53182 3.53527C1.17807 3.13215 1.46557 2.49902 2.00245 2.49902H7.99745C8.1176 2.49892 8.23523 2.53345 8.33627 2.59849C8.4373 2.66352 8.51744 2.7563 8.5671 2.86571C8.61676 2.97512 8.63383 3.09652 8.61627 3.21538C8.5987 3.33425 8.54725 3.44553 8.46807 3.5359L5.47057 6.9609C5.41191 7.02803 5.33956 7.08183 5.25839 7.1187C5.17722 7.15556 5.0891 7.17464 4.99995 7.17464C4.91079 7.17464 4.82267 7.15556 4.7415 7.1187C4.66033 7.08183 4.58798 7.02803 4.52932 6.9609V6.96152Z"
        fill="white"
      />
    </svg>
  );
}
