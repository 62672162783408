import { isEmptyStr } from '../utils/common';
import apiConfig from './apiConfig.json';
import apiPaySystem from './apiPaySystem';
import {
  ICreditMinusItem,
  IPaySystemChangeMobileReq,
  IPaySystemMinusCreditReq,
  IPaySystemSignUp,
  IPaySystemSignUpReq,
  IPaymentUsageReqData,
} from './dto/IPaySystemDto';
import { IPaySystemMemberInfo, getStoragePaysystemCompanySeq } from './store/paysystem';

// paysystem base64 encode
const getPaysystemBase64 = (userid: string) => {
  return window.btoa('anonymous_' + userid);
};

// 결제시스템 로그인
const paySignIn = async (userid: string) => {
  return await apiPaySystem.get(apiConfig.paysystem.signIn + getPaysystemBase64(userid));
};

/**
 * 범용 전용 refresh token
 * @param userId : 사용자 아이디
 * @param func : 콜백함수
 * @returns 
 */
const refreshPaySignIn 
  = async (userId:string, func:(data:any) => void) => {
  if (isEmptyStr(userId)) {
    return func(null);
  }
  await paySignIn(userId).then((res) => {
    if (res.status === 200 && res.data.result === 1) {
      const payInfo: IPaySystemMemberInfo = {
        paySystemCompanySeq: res.data.companySeq
          ? res.data.companySeq?.toString()
          : '',
        paySystemMemberId: res.data.memberId
          ? res.data.memberId?.toString()
          : '',
        paySystemExpiredDate: res.data.tokenExpiredDate
          ? res.data.tokenExpiredDate?.toString()
          : '',
        paySystemToken: res.data.authToken ? res.data.authToken : '',
        paySystemMemberSeq: res.data.memberSeq
          ? res.data.memberSeq?.toString()
          : '',
      };
      console.log('payInfo:' + JSON.stringify(payInfo));
      return func(payInfo);
    } 
    console.error('결제시스템 인증 오류 발생.');
    return func(null);
  });
};

/**
 * 요금 페이지 전용 refresh token
 * @param userId : 사용자 아이디
 * @param type : 요금제 타입
 * @param func : 콜백함수
 * @returns 
 */
const refreshPaySignInWithFee 
  = async (userId:string, type:string, func:(type:string, data:any) => void) => {
  if (isEmptyStr(userId) || isEmptyStr(type)) {
    return func(null, null);
  }
  await paySignIn(userId).then((res) => {
    if (res.status === 200 && res.data.result === 1) {
      const payInfo: IPaySystemMemberInfo = {
        paySystemCompanySeq: res.data.companySeq
          ? res.data.companySeq?.toString()
          : '',
        paySystemMemberId: res.data.memberId
          ? res.data.memberId?.toString()
          : '',
        paySystemExpiredDate: res.data.tokenExpiredDate
          ? res.data.tokenExpiredDate?.toString()
          : '',
        paySystemToken: res.data.authToken ? res.data.authToken : '',
        paySystemMemberSeq: res.data.memberSeq
          ? res.data.memberSeq?.toString()
          : '',
      };
      console.log('payInfo:' + JSON.stringify(payInfo));
      return func(type, payInfo);
    } 
    console.error('결제시스템 인증 오류 발생.');
    return func(type, null);
  });
};

// 토큰 검증, 미사용
const validateToken = async () => {
  return await apiPaySystem.post(apiConfig.paysystem.validateToken);
};

// 회원가입
const paySingUp = async (args: IPaySystemSignUp) => {
  const reqBody: IPaySystemSignUpReq = args;
  reqBody.signUpPathCode = 'A3022';
  return await apiPaySystem.post(apiConfig.paysystem.singUp, reqBody);
};

// 모바일 전화번호 변경, 미사용
const changeMobile = async (args: IPaySystemChangeMobileReq) => {
  return await apiPaySystem.put(
    apiConfig.paysystem.changeMobile + getPaysystemBase64(args.memberId),
    args
  );
};

// 회원 상태 변경, 미사용
const changeUserStatus = async (userid: string) => {
  return await apiPaySystem.put(
    apiConfig.paysystem.changeUserStatus1 +
      getPaysystemBase64(userid) +
      apiConfig.paysystem.changeUserStatus2
  );
};

// 회원 구독 목록, 미사용
const getSubscribeList = async () => {
  // paySignIn().then(res => {});
  const url = apiConfig.paysystem.getSubscribeList + getStoragePaysystemCompanySeq();
  return await apiPaySystem.get(url);
};

// 결재 내역 조회, 미사용
const getPaymentUsageList = async (args: IPaymentUsageReqData) => {
  const url = apiConfig.paysystem.getPaymentUsageList + getStoragePaysystemCompanySeq();
  return await apiPaySystem.get(url, {
    params: {
      ...args,
      'solution-type': 'B13',
    },
  });
};

// 크레딧 잔액 조회
const getLeftCredit = async () => {
  return await apiPaySystem.get(
    apiConfig.paysystem.getLeftCredit + getStoragePaysystemCompanySeq()
  );
};

// 크레딧 차감, 미사용
const payMinusCredit = async (args: ICreditMinusItem) => {
  const reqBody: IPaySystemMinusCreditReq = {
    solutionType: 'B13',
    fkCompany: getStoragePaysystemCompanySeq(),
    items: [args],
  };
  return await apiPaySystem.post(apiConfig.paysystem.minusCredit, reqBody);
};

// 크레딧 추가
const payPlusCredit = async (args: ICreditMinusItem) => {
  const reqBody: IPaySystemMinusCreditReq = {
    solutionType: 'B13',
    fkCompany: getStoragePaysystemCompanySeq(),
    items: [args],
  };
  return await apiPaySystem.post(apiConfig.paysystem.plusCredit, reqBody);
};

export {
  changeMobile,
  changeUserStatus,
  getLeftCredit,
  getPaymentUsageList,
  getSubscribeList,
  payMinusCredit,
  payPlusCredit,
  paySignIn, 
  paySingUp, 
  refreshPaySignIn, 
  refreshPaySignInWithFee,
  validateToken
};
