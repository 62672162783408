export default function TotalCredit({
  timeCal,
  getPlayTime,
  Coin,
  t,
  setPopup,
  exportBtn,
  ExclamationMark,
  isExported,
  exportVoiceShow
}: any) {
  return (
    <div className="preview-popup">
      <img src={ExclamationMark} alt="" className="exclamationMark-icon" />
      <h3>
        {t('재생시간')} {timeCal}
      </h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          textAlign: 'center',
          marginTop: '6px',
        }}>
        <img src={Coin} alt="" style={{ marginRight: '4.6px' }}></img>
        <p style={{ color: '#7B61FF' }}>
          {Math.floor(getPlayTime() / 100) * 16} {t('크레딧')}
        </p>
        <p>{t('이 차감됩니다.')}</p>
      </div>
      <div className="popup-btn-cont">
        <button
          className="popupBtnLeft bg-[#bebebe] hover:bg-[#cecece] text-black"
          onClick={() => {
            if (exportVoiceShow) {
              setPopup(4);
            } else {
              setPopup(5);
            }
          }
          }>
          {t('이전')}
        </button>

        <button
          disabled={isExported}
          className={`popupBtnRight hover:!bg-[#917bff] ${
            isExported ? '!cursor-not-allowed' : '!cursor-pointer'
          }`}
          style={{ opacity: 1 }}
          onClick={exportBtn}>
          {t('다음')}
        </button>
      </div>
    </div>
  );
}
