import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import { MetahumanListType } from '../../../types_new/main/resource/types';
import VoiceCard from './voice-card';

type Props = {
  metahumanList: MetahumanListType[];
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  projectInfo: NewProjectInfoType;
};
export default function VoiceGrid({
  metahumanList,
  setProjectInfo,
  projectInfo,
}: Props) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { defaultMetahuman } = projectInfo;
  const voiceList = metahumanList.find(
    item => item.ID === defaultMetahuman.modelId
  )?.voices;
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedAudioId, setSelectedAudioId] = useState('');

  const handleAudioClick = useCallback(
    (audioId: string) => {
      const audio = audioRef.current;
      const selectedVoice = voiceList?.find(
        voice => String(voice.voiceId) === String(audioId)
      );

      if (!audio || !selectedVoice) return;

      if (audio.paused || String(selectedAudioId) !== String(audioId)) {
        setIsPlaying(true);
        audio.src = selectedVoice.sourceURL;
        audio.play();
        setSelectedAudioId(String(audioId));
      } else {
        setIsPlaying(false);
        audio.pause();
        setSelectedAudioId('');
      }
    },
    [selectedAudioId, voiceList]
  );

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setSelectedAudioId('');
      };
    }
  }, []);

  return (
    <div className="relative">
      <audio ref={audioRef} />
      <ul className="flex space-x-4 max-h-72 2xl:max-h-80 h-full overflow-y-auto no-scroll px-1 pt-1.5 pb-4">
        {voiceList?.map((item, idx) => (
          <li key={`metahuman-${idx}`}>
            <VoiceCard
              handleAudioClick={handleAudioClick}
              isPlaying={isPlaying}
              selectedAudioId={selectedAudioId}
              setProjectInfo={setProjectInfo}
              projectInfo={projectInfo}
              voice={item}
            />
          </li>
        ))}
      </ul>
      <div className="absolute bottom-0 w-full h-5 bg-gradient-to-t from-white/90 to-white/0" />
    </div>
  );
}
