import { useEffect, useState } from 'react';
import {
  TemplateGalleryParamsType,
  getTemplateList,
} from '../../../networks/gallery.service';
import { getLeftCredit } from '../../../networks/paysystem.service';
import { TemplateType } from '../../../types_new/main/resource/types';
import Pagination from '../../common/Pagination';
import GallerySearchFilter from '../gallery/gallery-search-filter';
import ItemSkeleton from './item-skeleton';
import TemplateCard from './template-card';

export default function TemplateGrid() {
  const [params, setParams] = useState<TemplateGalleryParamsType>({
    paged: 1,
    postsPerPage: 6,
    title: '',
    sort: '',
    freeYn: '',
  });
  const [templateList, setTemplateList] = useState<TemplateType[]>([]);
  const [parentPage, setParentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [credit, setCredit] = useState(0);

  const getCreditLeftWithResData = (result: any) => {
    let totalCredit = 0;
    result?.map((item: any) => {
      totalCredit += item.credit;
    });
    return totalCredit;
  };

  const getPayCreditLeft = () => {
    getLeftCredit()
      .then(res => {
        if (res.status === 200) {
          setCredit(getCreditLeftWithResData(res?.data?.resultValue));
        }
      })
      .catch(console.error);
  };

  useEffect(() => getPayCreditLeft(), []);

  useEffect(() => {
    setLoading(true);

    getTemplateList(params)
      .then(({ total, posts }) => {
        setTemplateList(posts);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [params]);

  const onPageChange = (page: number) => {
    setParams(prev => ({ ...prev, paged: page }));
    setParentPage(page);
  };

  if (!templateList.length && !params.title) {
    return (
      <div className="w-full text-center text-2xl mt-20">
        조회할 템플릿이 없습니다.
      </div>
    );
  }

  return (
    <>
      <div className="flex justify-end mt-5 md:mt-6 xl:mt-8 mb-5">
        <GallerySearchFilter setParams={setParams} params={params} />
      </div>
      {!templateList.length && params.title && (
        <div className="w-full text-center text-2xl mt-20">
          <span className="font-bold"> "{params.title}" </span>
          검색어를 찾을 수 없습니다.
        </div>
      )}
      {loading ? (
        <ItemSkeleton />
      ) : (
        <>
          <ul className="grid grid-cols-2 md:grid-cols-3 gap-y-10 gap-x-3 lg:gap-x-5 xl:gap-y-12 xl:gap-x-7">
            {templateList.map((template, idx) => (
              <TemplateCard
                key={`${template.postTitle}-${idx}`}
                credit={credit}
                setCredit={setCredit}
                template={template}
              />
            ))}
          </ul>
          {/* {!total && (
            <div className="w-full text-center text-2xl mt-20">
              <span className="font-bold"> "{params.title}" </span>
              검색어를 찾을 수 없습니다.
            </div>
          )} */}
        </>
      )}

      <Pagination
        onPageChange={onPageChange}
        totalItems={total}
        currentPage={params.paged}
        parentPage={parentPage}
        itemsPerPage={params.postsPerPage}
      />
    </>
  );
}
