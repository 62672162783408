import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Layout from './components/common/Layout';
import ScrollToTop from './components/common/ScrollToTop';
import ConsoleLayout from './components/console/common/console-layout';
import LandingLayout from './components/layout/landing-layout';
import TotalLayout from './components/layout/total-layout';
import SignupLayout from './components/main/signup-layout';
import './css/reset.css';
import './css/style.css';
import './css/tailwind.css';
import { getMainDir } from './util/common';
import ConsoleHomePage from './view/console/console-home-page';
import ConsoleMediaPage from './view/console/console-media-page';
import ConsoleMetahumanPage from './view/console/console-metahuman-page';
import ConsoleProjectsPage from './view/console/console-projects-page';
import ConsoleTemplatePage from './view/console/console-templates-page';
import GalleryPage from './view/main/gallery-page';
import LoginPage from './view/main/login-page';
import MainPage from './view/main/main-page';
import MembershipPage from './view/main/membership-page';
import MyPage from './view/main/my-page';
import NoticePage from './view/main/notice-page';
import ProductionInquiryPage from './view/main/production-inquiry-page';
import ShowcasePage from './view/main/showcase-page';
import SignupPage from './view/main/signup-page';
import SignupStepsPage from './view/main/signup-steps-page';
import StorePage from './view/main/store-page';
import SupportPage from './view/main/support-page';
import TutorialPage from './view/main/tutorial-page';
import NotFoundPage from './view/not-found-page';

function App() {
  const { t } = useTranslation();
  console.log('getMainDir():' + getMainDir());
  return (
    <>
      <Helmet>
        <title>
          {t(
            '플루닛 스튜디오│국내 최고의 AI 가상인간 영상제작 서비스 플랫폼 No.1'
          )}
        </title>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta
          property="og:title"
          content="플루닛 스튜디오 | 국내 최고의 AI 가상인간 영상제작 서비스 플릿폼 No.1"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="studio.ploonet.com" />
        <meta
          property="og:description"
          content="플루닛 스튜디오는 생성형 인공지능과 대화형 인공지능을 기반으로 가상인간을 생성하고 영상제작을 할 수 있는 통합 플랫폼입니다. 당신의 디지털 DNA/클론 AI 가상인간 제작, AI 가상인간 영상제작은 플루닛 스튜디오"
        />
        <meta
          property="og:image"
          content="http://studio.ploonet.com/img/ploonet_og_image.png"
        />
        <link rel="icon" href={getMainDir('/favicon.ico')} />
      </Helmet>
      <RecoilRoot>
        <BrowserRouter basename={getMainDir()}>
          <ScrollToTop />
          <Routes>
            <Route element={<TotalLayout />}>
              {/* LANDING */}
              <Route element={<LandingLayout />}>
                <Route path="/" element={<MainPage />} />
                <Route path="/showcase" element={<ShowcasePage />} />
                <Route path="/tutorial" element={<TutorialPage />} />
                <Route path="/store" element={<StorePage />} />
                <Route path="/membership" element={<MembershipPage />} />
                <Route
                  path="/production-inquiry"
                  element={<ProductionInquiryPage />}
                />
                <Route path="/notice" element={<NoticePage />} />
                <Route path="/support" element={<SupportPage />} />
                <Route path="/my-page/:info" element={<MyPage />} />
                <Route path="/gallery" element={<GalleryPage />} />
              </Route>
              <Route element={<SignupLayout />}>
                <Route
                  path="/signup/steps/:step"
                  element={<SignupStepsPage />}
                />
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/login" element={<LoginPage />} />
              </Route>
              {/* LANDING */}

              {/* CONSOLE */}
              <Route element={<ConsoleLayout />}>
                <Route path="/console" element={<ConsoleHomePage />} />
                <Route path="/my-projects" element={<ConsoleProjectsPage />} />
                <Route
                  path="/my-metahumans"
                  element={<ConsoleMetahumanPage />}
                />
                <Route path="/my-templates" element={<ConsoleTemplatePage />} />
                <Route path="/media/:group?" element={<ConsoleMediaPage />} />
              </Route>
              {/* CONSOLE */}

              {/* PROJECT */}
              <Route path="/project/:projectId" element={<Layout />} />
              {/* PROJECT */}
              <Route path="/*" element={<NotFoundPage />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </>
  );
}

export default App;
