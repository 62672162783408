import usePath from '../../../hooks/use-path';

export default function NoticeSkeleton() {
  const arr = [0, 1, 2, 3, 4, 5];
  const { isAtMain } = usePath();

  return (
    <>
      <div
        className={`${
          isAtMain ? 'hidden sm:grid' : 'grid grid-cols-1'
        } sm:grid-cols-2 lg:grid-cols-3 gap-7 mt-12`}
      >
        {arr.map((_, idx) => {
          const SECOND_ROW = [2, 5, 8, 11, 14];
          const isAtSecondCol = SECOND_ROW.includes(idx + 1);
          const isEven = idx % 2 === 0;

          return (
            <div
              key={`noticeSkeleton-${idx}`}
              className={`relative bg-gray-700 animate-pulse h-80 xl:h-[390px] 2xl:h-[450px] rounded-[10px] ${
                isAtSecondCol && 'lg:translate-y-28'
              } ${!isAtMain && 'max-sm:w-[80%]'} ${
                isEven ? 'max-sm:ml-auto' : 'max-sm:mr-auto'
              }`}
            >
              <div className="absolute right-1/2 bottom-1/2 trny-1/2 translate-x-1/2">
                <svg
                  className="w-14 h-14 text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
              <div className="absolute w-full h-full flex flex-col justify-between py-5 lg:py-6 px-6 xl:py-7 xl:px-8">
                <div className=" w-1/6 h-1 bg-gray-300 rounded-full animate-pulse" />
                <div className="flex flex-col space-y-3 lg:space-y-5 2xl:space-y-6">
                  <div className="bg-gray-400 w-3/4 h-2 rounded-full animate-pulse" />
                  <div className="w-1/4 h-1 rounded-full bg-gray-300 animate-pulse" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={`sm:hidden mt-12 ${isAtMain ? 'px-5' : 'hidden'}`}>
        <div className="relative bg-gray-700 animate-pulse h-80 xl:h-[390px] 2xl:h-[450px] rounded-[10px]">
          <div className="absolute right-1/2 bottom-1/2 trny-1/2 translate-x-1/2">
            <svg
              className="w-14 h-14 text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
          </div>
          <div className="absolute w-full h-full flex flex-col justify-between py-5 lg:py-6 px-6 xl:py-7 xl:px-8">
            <div className=" w-1/6 h-1 bg-gray-300 rounded-full animate-pulse" />
            <div className="flex flex-col space-y-3 lg:space-y-5 2xl:space-y-6">
              <div className="bg-gray-400 w-3/4 h-2 rounded-full animate-pulse" />
              <div className="w-1/4 h-1 rounded-full bg-gray-300 animate-pulse" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
