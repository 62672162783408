import { useTranslation } from 'react-i18next';

export default function SpamTerms() {
  const { t } = useTranslation();

  return (
    <div className="terms-wrapper overflow-y-auto no-scroll text-sm text-black">
      <div>
        <dl>
          {t(
            '주식회사 플루닛(이하 “회사”)은 다수 이용자의 편리한 서비스 이용과 스팸, 불법 스팸으로 인한 폐해를 방지하기 위해 스팸방지정책을 수립하여 운영하고 있습니다. 아래와 같이 불법 스팸 메시지를 발송한 이용자에 대해서는 이용정지 및 해지, 형사고발 등의 조치를 취하겠습니다.'
          )}
        </dl>
        <strong>1. {t('용어의 정의')}</strong>
        <dl>
          <dt>1)</dt>
          <dd>
            {t(
              '스팸: 정보통신망을 통해 수신자의 명시적인 사전 동의없이 일방적으로 전송되는 영리목적의 광고성 정보를 말합니다.'
            )}
          </dd>
          <dt>2)</dt>
          <dd>
            {t(
              '불법 스팸: 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」(이하“정보통신망법”이라 한다.) 제50조부터 제50조의8의 규정을 위반하여 전송 또는 게시되는 영리목적의 광고성 정보를 말합니다. 불법 스팸은 형사처벌 및 과태료 부과의 대상이 됩니다.'
            )}
          </dd>
        </dl>
        <strong>2. {t('이용고객의 의무')}</strong>
        <dl>
          <dt>1)</dt>
          <dd>
            {t(
              '이용고객은 정보통신망 이용촉진 및 정보보호 등에 관한 법률의 광고성 정보 전송 시 의무사항 및 회사의 이용약관을 준수하여야 합니다.'
            )}
          </dd>
          <dt>2)</dt>
          <dd>
            {t(
              '이용고객은 스팸 또는 불법 스팸을 전송함으로써 발생하는 모든 민형사상의 책임을 부담합니다.'
            )}
          </dd>
        </dl>
        <strong>3. {t('이용의 정지')}</strong>
        <dl>
          <dt>1)</dt>
          <dd>
            {t(
              '회사는 이용고객이 다음 중 하나에 해당하는 경우 지체없이 서비스 이용을 정지할 수 있습니다.'
            )}
            <dl className="no-margin">
              <dt>가.</dt>
              <dd>
                {t(
                  '과학기술정보통신부 또는 한국인터넷진흥원 또는 카카오로부터 불법 스팸 전송사실을 확인하여 이용정지를 요청하는 경우'
                )}
              </dd>
              <dt>나.</dt>
              <dd>
                {t(
                  '대량으로 스팸을 전송하여 시스템 장애를 야기했거나 야기할 우려가 있는 경우'
                )}
              </dd>
              <dt>다.</dt>
              <dd>
                {t(
                  '해당 광고를 수신한 자가 수신거부를 요청하였으나 재전송한 경우'
                )}
              </dd>
              <dt>라.</dt>
              <dd>
                {t(
                  '전송된 메시지가 스팸이라고 판단되어 회사로부터 전송중단 요청을 받았으나 지속 전송한 경우 등'
                )}
              </dd>
            </dl>
          </dd>
          <dt>2)</dt>
          <dd>
            {t(
              '회사는 이용정지의 사유가 해소되고 재발 생할 우려가 없다고 판단될 경우 서비스 이용을 가능하게 할 수 있습니다.'
            )}
          </dd>
        </dl>
        <strong>4. {t('계약의 해지')}</strong>
        <dl>
          <dt>1)</dt>
          <dd>
            {t(
              '회사는 이용고객이 다음 중 하나에 해당하는 경우 지체 없이 계약을 해지할 수 있으며, 그 사실을 이용고객에게 통지합니다.'
            )}
            <br />
            {t(
              '다만 미리 통지하는 것이 곤란한 경우에는 계약해지 후 통지할 수 있습니다.'
            )}
            <dl className="no-margin">
              <dt>가.</dt>
              <dd>
                {t(
                  '이용정지 기간 경과 이후에도 지속적으로 불법 스팸을 전송하여 과학기술정보통신부 또는 한국인터넷진흥원에서 계약해지를 청하는 경우'
                )}
              </dd>
              <dt>나.</dt>
              <dd>
                {t(
                  '2회 이상 이용정지 또는 스팸메시지 전송 중단 요청을 받은 경우'
                )}
              </dd>
            </dl>
          </dd>
        </dl>
        <strong>5. {t('서비스 제공자의 의무')}</strong>
        <dl>
          <dt>1)</dt>
          <dd>
            {t(
              '회사는 서비스 제공목적에 맞는 서비스 이용여부를 확인하기 위하여 상시적으로 모니터링을 실시합니다.'
            )}
          </dd>
          <dt>2)</dt>
          <dd>
            {t(
              '회사는 이용고객이 불법 스팸을 전송한 사실을 확인한 경우, 한국인터넷진흥원 불법 스팸 대응 센터의 요청에 따라 관련 자료를 제공할 수 있습니다.'
            )}
          </dd>
        </dl>
        <strong>6. {t('이용고객 책임에 대한 동의')}</strong>
        <div>
          {t(
            '이용고객은 서비스 이용에 관하여 본인의 관리 소홀 또는 시스템 취약점 등 회사의 귀책사유 없는 사정에 의한 해킹 또는 제3자의 불법 침해로 인해 발생되는 다량의 메시지 발송, 서비스 장애, 서비스 중단, 불법 스팸 발송, 발송요금 등의 피해에 대해서 회사가 어떠한 책임도 부담하지 않음에 동의합니다. 가입하신 이용고객은 모두 아래 약관에 동의한 것입니다.'
          )}
        </div>
        <div>
          <strong>{t('제4장 서비스의 이용정지 및 이용휴지')}</strong>
          <br />
          <strong>{t('제13조 (이용정지의 대상)')}</strong>
          <br />
          <strong>
            {t(
              '가입자가 다음 각호에 해당하는 경우에는 가입자의 서비스 이용을 정지할 수 있습니다.'
            )}
          </strong>
          <dl className="no-margin">
            <dt>1.</dt>
            <dd>{t('플루닛 공통 약관에서 금지하는 행위를 한 경우')}</dd>
            <dt>2.</dt>
            <dd>
              {t(
                '방송통신위원회, 한국인터넷진흥원, 과학기술정보통신부 등 관계기관이 스팸메시지 또는 문자피싱 메시지 등 불법행위의 전송사실을 확인하여 이용정지를 요청하는 경우'
              )}
            </dd>
            <dt>3.</dt>
            <dd>
              {t(
                '가입자가 전송하는 광고로 인하여 회사의 서비스 제공에 장애를 야기하거나 야기할 우려가 있는 경우'
              )}
            </dd>
            <dt>4.</dt>
            <dd>
              {t(
                '가입자가 전송하는 메시지로 인하여 이용자에게 피해가 발생하여 신고가 들어온 경우'
              )}
            </dd>
            <dt>5.</dt>
            <dd>
              {t(
                '가입자에게 제공하는 서비스가 스팸 및 불법정보로 이용되고 있는 경우'
              )}
            </dd>
            <dt>6.</dt>
            <dd>
              {t(
                '가입자가 제16조(가입자의의 의무)을 위반하여 유저식별번호를 변작하는 등 거짓으로 표시한 경우'
              )}
            </dd>
            <dt>7.</dt>
            <dd>
              {t(
                '과학기술정보통신부장관 또는 한국인터넷진흥원 등 관련 기관이 유저식별번호 변작 등을 확인하여 이용 정지를 요청하는 경우'
              )}
            </dd>
          </dl>
        </div>
        <strong>7. {t('전송 시 유의사항')}</strong>
        <div>
          {t(
            '"정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법")" 제50조에 의거 광고성 정보 전송 시 유의사항에 대해 안내 드립니다.'
          )}
        </div>
        <dl className="no-margin">
          <dt>1)</dt>
          <dd>
            {t(
              '영리목적의 광고성 정보는 사전에 수신자의 수신동의를 받아야 합니다.'
            )}
          </dd>
          <dt>2)</dt>
          <dd>
            {t(
              '오후 9시부터 그 다음 날 오전 8시까지의 야간시간에 광고성 정보를 전송하기 위해서는 별도의 수신 동의를 받아야 합니다.'
            )}
          </dd>
          <dt>3)</dt>
          <dd>{t('명시사항 및 명시방법')}</dd>
        </dl>
        <div>
          ( {t('광고')} )<br />
          {t('전송자 명칭')}
          <br />
          {t('전화번호 또는 주소')}
          <br />
          {t('무료 수신거부 또는 수신 동의의 철회 방법')}
          <br />
        </div>
        <strong>8. {t('정보통신망법 스팸 관련 규정')}</strong>
        <div>
          {t(
            '한국인터넷진흥원(KISA)에서 배포한 불법 스팸 방지를 위한 정보통신망법 안내서에 따라 광고성 정보를 전송하실 경우 아래 규정을 준수하여야 합니다.'
          )}
        </div>
        <table>
          <tr>
            <th colSpan={2}>{t('조항')}</th>
            <th>{t('주요내용')}</th>
            <th>{t('비고')}</th>
            <th>{t('벌칙')}</th>
          </tr>
          <tr>
            <td rowSpan={8}>{t('제50조')}</td>
            <td>{t('제1항')}</td>
            <td>
              {t('수신자의 사전동의 없는 광고전송 금지')}
              <br />- {t('예외1. 재화 등 거래관계가 있는 경우')}
              <br />- {t('예외2. 방문판매법에 따른 전화권유')}
            </td>
            <td rowSpan={2}>{t('공통')}</td>
            <td rowSpan={4}>{t('3천만원 이하 과태료')}</td>
          </tr>
          <tr>
            <td>{t('제2항')}</td>
            <td>{t('수신거부 및 사전동의 철회시 광고전송 금지')}</td>
          </tr>
          <tr>
            <td>{t('제3항')}</td>
            <td>{t('“21시~익일 8시” 광고전송 금지')}</td>
            <td>{t('이메일 제외')}</td>
          </tr>
          <tr>
            <td>{t('제4항')}</td>
            <td>{t('광고성 정보 전송시 표기의무 준수')}</td>
            <td rowSpan={5}>{t('공통')}</td>
          </tr>
          <tr>
            <td>{t('제5항')}</td>
            <td>
              {t('광고성 정보 전송시 금지 조치')}
              <br />- {t('수신거부 및 동의철회 회피·방해')}
              <br />- {t('수신자 연락처 자동 생성')}
              <br />- {t('수신자 연락처 자동 등록')}
              <br />- {t('전송자 정보 은폐·위변조')}
              <br />- {t('원링 스팸')}
            </td>
            <td>{t('1년 이하 징역 또는 1천만원 이하 벌금')}</td>
          </tr>
          <tr>
            <td>{t('제6항')}</td>
            <td>{t('무료 수신거부/수신동의 철회 조치')}</td>
            <td>{t('3천만원 이하 과태료')}</td>
          </tr>
          <tr>
            <td>{t('제7항')}</td>
            <td>{t('수신거부 등 처리결과의 통지')}</td>
            <td>{t('1천만원 이하 과태료')}</td>
          </tr>
          <tr>
            <td>{t('제8항')}</td>
            <td>{t('정기적인 수신동의 여부 확인')}</td>
            <td>{t('3천만원 이하 과태료')}</td>
          </tr>
          <tr>
            <td colSpan={2}>{t('제50조의8')}</td>
            <td>{t('불법행위를 위한 광고성 정보 전송금지')}</td>
            <td></td>
            <td>{t('1년 이하 징역 또는 1천만원 이하 벌금')}</td>
          </tr>
        </table>
        <br />
        <div>{t('법률 및 시행령의 주요 내용은 아래와 같습니다.')}</div>
        <br />
        <strong>1) {t('명시적인 사전 동의')}</strong>
        <br />
        <div>
          {t(
            '전자적 전송매체를 이용한 모든 영리목적의 광고성 정보는 사전에 수신자의 수신동의를 받아야 전송할 수 있습니다.'
          )}
        </div>
        <div>
          <strong>{t('제50조(영리목적의 광고성 정보 전송 제한)')}</strong>
          <div>
            ①
            {t(
              '누구든지 전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하려면 그 수신자의 명시적인 사전 동의를 받아야 한다. 다만, 다음 각 호의 어느 하나에 해당하는 경우에는 사전 동의를 받지 아니한다.'
            )}
          </div>
          <dl className="no-margin">
            <dt>1.</dt>
            <dd>
              {t(
                '재화 등의 거래관계를 통하여 수신자로부터 직접 연락처를 수집한 자가 대통령령으로 정한 기간 이내에 자신이 처리하고 수신자와 거래한 것과 동종의 재화 등에 대한 영리목적의 광고성 정보를 전송하려는 경우'
              )}
            </dd>
            <dt>2.</dt>
            <dd>
              {t(
                '「방문판매 등에 관한 법률」에 따른 전화권유판매자가 육성으로 수신자에게 개인정보의 수집 출처를 고지하고 전화권유를 하는 경우'
              )}
            </dd>
          </dl>
        </div>
        <div>
          {t(
            '영리목적의 광고성 정보는 전송자가 경제적 이득을 취할 목적으로 전송하는 ①전송자에 관한 정보, ②전송자가 제공할 재화나 서비스에 관한 정보를 말합니다. 주된 정보가 광고성 정보가 아니더라도 부수적으로 광고성 정보가 포함되어 있으면 전체가 광고성 정보에 해당합니다.'
          )}
        </div>
        <br />
      </div>
      <strong>2) {t('수신거부 및 사전 동의 철회')}</strong>
      <br />
      <div>
        ①
        {t(
          '수신자가 수신거부의사를 표시하거나 ②사전수신동의를 철회한 경우 광고성 정보의 전송이 금지됩니다.'
        )}
      </div>
      <div>
        <strong>{t('제50조(영리목적의 광고성 정보 전송 제한)')}</strong>
        <br />②
        {t(
          '전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하려는 자는 제1항에도 불구하고 수신자가 수신거부의사를 표시하거나 사전 동의를 철회한 경우에는 영리목적의 광고성 정보를 전송하여서는 아니 된다.'
        )}
      </div>
      <strong>3) {t('야간광고 전송제한 및 예외')}</strong>
      <div>
        {t(
          '광고성 정보 전송에 대하여 수신동의를 받았거나 거래관계에 의한 수신동의 예외가 인정되더라도 야간시간에 광고성 정보를 전송하기 위해서는 별도의 수신 동의를 받아야 합니다. 야간시간에 광고성 정보 전송금지 기준은 수신자에게 도달하는 시각을 기준으로 합니다.'
        )}
      </div>
      <div>
        <strong>{t('제50조(영리목적의 광고성 정보 전송 제한)')}</strong>
        <br />②
        {t(
          '전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하려는 자는 제1항에도 불구하고 수신자가 수신거부의사를 표시하거나 사전 동의를 철회한 경우에는 영리목적의 광고성 정보를 전송하여서는 아니 된다.'
        )}
      </div>
      <strong>4) {t('광고성 정보 전송 시 명시사항')}</strong>
      <div>
        -{' '}
        {t(
          '전송자의 명칭: 업체명 또는 서비스명으로 수신자가 용이하게 식별할 수 있어야 합니다.'
        )}
        <br />-{' '}
        {t(
          '전송자의 연락처: 정보를 전송한 자와 직접적으로 연락이 될 수 있는 것이어야 합니다.'
        )}
        <br />-{' '}
        {t(
          '수신거부 또는 수신동의의 철회 방법: 광고성 정보가 끝나는 부분에 명시하여야 합니다.'
        )}
        <br />
      </div>
      <div>
        <strong>{t('제50조(영리목적의 광고성 정보 전송 제한)')}</strong>
        <br />④
        {t(
          '전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 자는 대통령령으로 정하는 바에 따라 다음 각 호의 사항 등을 광고성 정보에 구체적으로 밝혀야 한다.'
        )}
        <dl className="no-margin">
          <dt>1.</dt>
          <dd>{t('전송자의 명칭 및 연락처')}</dd>
          <dt>2.</dt>
          <dd>
            {t(
              '수신의 거부 또는 수신동의의 철회 의사표시를 쉽게 할 수 있는 조치 및 방법에 관한 사항'
            )}
          </dd>
        </dl>
      </div>
      <strong>5) {t('광고성 정보 전송자 금지사항')}</strong>
      <div>
        {t(
          '수신자의 필터링 등 을 회피하기 위하 여 무료수신거부 번호 080-1234-5678 중 ‘080’ 을 ‘O8O’ 이나 ‘공팔공’ 등으로 표시하거나 ‘( 광고 )’ 를 ‘광고’, ‘( 광 고 )’ , ‘( 광 / 고 )’ 등으로 표시하는 것을 말합니다. 휴대전화 문자로 전송하는 광고성 정보를 이미지 형태 (jpg ㆍ gif ㆍ png 파일형식 등) 로 보내어 수신자의 필터링을 회피하는 것도 수신거부를 회피하는 조치에 해당합니다.'
        )}
      </div>
      <div>
        <strong>{t('제50조(영리목적의 광고성 정보 전송 제한)')}</strong>
        <br />⑤
        {t(
          '전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 자는 다음 각 호의 어느 하나에 해당하는 조치를 하여서는 아니 된다.'
        )}
        <dl className="no-margin">
          <dt>1.</dt>
          <dd>
            {t(
              '광고성 정보 수신자의 수신거부 또는 수신동의의 철회를 회피·방해하는 조치'
            )}
          </dd>
          <dt>2.</dt>
          <dd>
            {t(
              '숫자·부호 또는 문자를 조합하여 전화번호·전자우편주소 등 수신자의 연락처를 자동으로 만들어 내는 조치'
            )}
          </dd>
          <dt>3.</dt>
          <dd>
            {t(
              '영리목적의 광고성 정보를 전송할 목적으로 전화번호 또는 전자우편주소를 자동으로 등록하는 조치'
            )}
          </dd>
          <dt>4.</dt>
          <dd>
            {t(
              '광고성 정보 전송자의 신원이나 광고 전송 출처를 감추기 위한 각종 조치'
            )}
          </dd>
          <dt>5.</dt>
          <dd>
            {t(
              '영리목적의 광고성 정보를 전송할 목적으로 수신자를 기망하여 회신을 유도하는 각종 조치'
            )}
          </dd>
        </dl>
      </div>
      <strong>6) {t('수신거부 및 수신동의 철회 시 비용 발생 금지')}</strong>
      <div>
        {t(
          '“무료수신거부” 등 무료임을 안내하는 문구와 수신자 부담 전화번호 등을 수신거부 또는 수신동의 철회용 전화번호로 해당 정보에 기재하여야 합니다.'
        )}
      </div>
      <div>
        <strong>{t('제50조(영리목적의 광고성 정보 전송 제한)')}</strong>
        <br />⑥
        {t(
          '전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하는 자는 수신자가 수신거부나 수신동의의 철회를 할 때 발생하는 전화요금 등의 금전적 비용을 수신자가 부담하지 아니하도록 대통령령으로 정하는 바에 따라 필요한 조치를 하여야 한다.'
        )}
      </div>
      <strong>7) {t('처리결과 통지')}</strong>
      <div>
        {t(
          '수신자가 수신동의, 수신거부 또는 수신동의 철회 의사를 표시한 날부터 14일 이내에 전송자의 명칭, 수신자의 수신동의/거부 또는 수신동의 철회 사실과 해당 의사를 표시한 날짜, 처리 결과를 해당 수신자에게 알려야 합니다.'
        )}
      </div>
      <div>
        <strong>{t('제50조(영리목적의 광고성 정보 전송 제한)')}</strong>
        <br />⑦
        {t(
          '전자적 전송매체를 이용하여 영리목적의 광고성 정보를 전송하려는 자는 수신자가 제1항에 따른 사전 동의, 제2항에 따른 수신거부의사 또는 수신동의 철회 의사를 표시할 때에는 해당 수신자에게 대통령령이 정하는 바에 따라 수신 동의, 수신거부 또는 수신동의 철회에 대한 처리 결과를 알려야 한다.'
        )}
      </div>
      <strong>8) {t('수신동의 여부 확인')}</strong>
      <div>
        {t(
          '수신자의 사전 동의를 받은 자는 그 수신동의를 받은 날부터 2년마다(매 2년이 되는 해의 수신동의를 받은 날과 같은 날 전까지) 해당 수신자의 수신동의 여부를 확인하여야 합니다. 수신동의 여부를 확인하려는 자는 수신자에게 전송자의 명칭, 수신자의 수신동의 사실과 수신에 동의한 날짜, 수신동의에 대한 유지 또는 철회의 의사를 표시하는 방법을 밝혀야 합니다.'
        )}
      </div>
      <div>
        <strong>{t('제50조(영리목적의 광고성 정보 전송 제한)')}</strong>
        <br />⑧
        {t(
          '제1항 또는 제3항에 따라 수신동의를 받은 자는 대통령령으로 정하는 바에 따라 정기적으로 광고성 정보 수신자의 수신동의 여부를 확인하여야 한다.'
        )}
      </div>
      <strong>9) {t('법률에서 금지하는 광고성 정보의 전송 금지')}</strong>
      <div>
        {t(
          '마약, 불법대출, 도박, 성매매, 불법의약품, 청소년유해물 등 법률에서 금지하는 재화 또는 서비스에 대해서는 누구든지 광고성 정보를 전송하여서는 안 됩니다.'
        )}
      </div>
      <div>
        <strong>{t('제50조의8 (불법행위를 위한 광고성 정보 전송금지)')}</strong>
        <br />
        {t(
          '누구든지 정보통신망을 이용하여 이 법 또는 다른 법률에서 금지하는 재화 또는 서비스에 대한 광고성 정보를 전송하여서는 아니 된다.'
        )}
      </div>
    </div>
  );
}
