import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SubpageTitle from '../../components/main/landing/subpage-title';
import SubpageContainer from '../../components/main/subpage-container';
import CategoryList from '../../components/main/support/category-list';
import FAQSection from '../../components/main/support/faq-section';
import FAQSkeleton from '../../components/main/support/faq-skeleton';
import SupportPagination from '../../components/main/support/support-pagination';
import { doGetFAQList } from '../../networks/source.service';
import { isSignedAtom } from '../../networks/store/member';
import { useModalAtom } from '../../recoil/atoms/store';
import { lang } from '../../recoil/atoms/user';
import { PostType } from '../../types_new/main/resource/types';
import { scrollToTop } from '../../utils/common';

const TITLE = '안녕하세요! 도움이 필요하신가요?';
const POSTS_PER_PAGE = 499;

export default function SupportPage() {
  const [loading, setLoading] = useState(true);
  const [FAQList, setFAQList] = useState<PostType[]>([]);
  const [category, setCategory] = useState('');
  const [paged, setPaged] = useState(1);
  const language = useRecoilValue(lang);
  const supportRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const filteredList = useMemo(() => {
    if (!category) return FAQList;
    return FAQList.filter(
      item =>
        item.metaData.find(item => item.key === 'category')?.value === category
    );
  }, [category, FAQList]);

  useEffect(() => {
    const reqBody = {
      lang: language,
      postsPerPage: POSTS_PER_PAGE,
      paged: 1,
    };
    setLoading(true);
    doGetFAQList(reqBody)
      .then(setFAQList)
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [language]);

  const onPageChange = (paged: number) => {
    supportRef.current?.scrollIntoView();
    setPaged(paged);
  };

  return (
    <>
      <ShortcutButtons />
      <SubpageContainer>
        <div
          ref={supportRef}
          className="pb-12 md:pb-24 lg:pb-32 xl:pb-36 2xl:pb-48">
          <SubpageTitle badge="고객지원" title={TITLE} />
          <div className="text-[#353333] text-xs sm:text-sm md:text-base sm:text-center mt-4 lg:mt-6">
            {t('플루닛 스튜디오의 궁금한 점을 친절하게 안내드리겠습니다.')}
          </div>
          <CategoryList
            setPaged={setPaged}
            category={category}
            setCategory={setCategory}
          />
          {loading ? (
            <FAQSkeleton />
          ) : (
            <>
              <div className="xl:text-lg text-black mt-5 lg:mt-8 xl:mt-12 max-sm:hidden">
                {t('총')}{' '}
                <strong className="font-semibold">
                  {filteredList?.length} {t('건')}
                </strong>
              </div>
              <FAQSection filteredList={filteredList} paged={paged} />
            </>
          )}
          <SupportPagination
            list={filteredList}
            paged={paged}
            onPageChange={onPageChange}
          />
        </div>
      </SubpageContainer>
    </>
  );
}

function ShortcutButtons() {
  const { t } = useTranslation();
  const setUseModal = useSetRecoilState(useModalAtom);
  const isLoggedIn = useRecoilValue(isSignedAtom);

  return (
    <div className="flex flex-col -space-y-4 md:-space-y-7 md:hover:-space-y-4 fixed bottom-5 max-md:text-sm md:bottom-10 right-5 md:right-10 z-10">
      {isLoggedIn && (
        <button
          onClick={() => setUseModal({ type: 'inquiry', isOpen: true })}
          className="w-16 h-16 md:w-20 md:h-20 2xl:w-24 2xl:h-24 max-2xl:text-sm rounded-full bg-primary hover:bg-primary/90 duration-150 z-[1] shadow-2xl">
          1:1 {t('문의')}
        </button>
      )}
      <button
        onClick={scrollToTop}
        className="w-16 h-16 md:w-20 md:h-20 2xl:w-24 2xl:h-24 max-2xl:text-sm rounded-full bg-white hover:bg-gray-50 duration-150 text-primary shadow-2xl">
        Top
      </button>
    </div>
  );
}
