export default function CopyIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0476 12.5702V6.47498C11.0476 6.07084 10.8871 5.68325 10.6013 5.39749C10.3156 5.11172 9.92797 4.95117 9.52383 4.95117H3.42859C3.02446 4.95117 2.63687 5.11172 2.3511 5.39749C2.06533 5.68325 1.90479 6.07084 1.90479 6.47498V12.5702C1.90479 12.9744 2.06533 13.3619 2.3511 13.6477C2.63687 13.9335 3.02446 14.094 3.42859 14.094H9.52383C9.92797 14.094 10.3156 13.9335 10.6013 13.6477C10.8871 13.3619 11.0476 12.9744 11.0476 12.5702Z"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0477 11.0468H12.5715C12.9756 11.0468 13.3632 10.8863 13.649 10.6005C13.9348 10.3147 14.0953 9.92715 14.0953 9.52301V3.43006C14.0953 3.02592 13.9348 2.63833 13.649 2.35256C13.3632 2.06679 12.9756 1.90625 12.5715 1.90625H12.5692L6.47398 1.91692C6.07024 1.91752 5.68324 2.07833 5.39796 2.36404C5.11269 2.64974 4.95246 3.03698 4.95246 3.44073V4.95158M6.47627 7.2373V11.8087M8.76198 9.52301H4.19055"
        stroke="#666666"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
