interface TempoTypesProps {
  speed: 0.8 | 0.9 | 1.0 | 1.1 | 1.2;
  tempo: '매우 느림' | '느림' | '보통' | '빠름' | '매우 빠름';
}

export const tempoArray: TempoTypesProps[] = [
  {
    speed: 0.8,
    tempo: '매우 느림',
  },
  {
    speed: 0.9,
    tempo: '느림',
  },
  {
    speed: 1.0,
    tempo: '보통',
  },
  {
    speed: 1.1,
    tempo: '빠름',
  },
  {
    speed: 1.2,
    tempo: '매우 빠름',
  },
];
