import { atom } from 'recoil';
import { UseModalType } from '../../components/modals/modal-provider';
import { SegmentsType } from '../../types_new/console/resource/types';
// import { DEFAULT_PROJECT_STATE } from '../../hooks/use-project';

export const newProjectModal = atom({
  key: 'newProjectModal',
  default: false,
});

export const projectLoader = atom({
  key: 'projectLoader',
  default: false,
});

// export const projectAtom = atom({
//   key: 'projectAtom',
//   default: DEFAULT_PROJECT_STATE,
// });

export const useModalAtom = atom<UseModalType>({
  key: 'useModalAtom',
  default: {
    type: '',
    isOpen: false,
    data: '',
  },
});

export const timeAtom = atom({
  key: 'timeAtom',
  default: 0,
});

export const segmentsAtom = atom<SegmentsType>({
  key: 'segmentsAtom',
  default: {
    segment: [],
    title: [],
    background: [],
    image: [],
  },
});

export const blockAtom = atom({
  key: 'blockAtom',
  default: {
    id: 0,
    type: '',
  },
});

export const magnetAtom = atom({
  key: 'magnetAtom',
  default: true,
});
