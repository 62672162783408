import AmericaIcon from './images/metahuman/icon-america.svg';
import ChinaIcon from './images/metahuman/icon-china.svg';
import CzechIcon from './images/metahuman/icon-czechsvg.svg';
import DenmarkIcon from './images/metahuman/icon-denmark.svg';
import GermanyIcon from './images/metahuman/icon-germany.svg';
import IndiaIcon from './images/metahuman/icon-india.svg';
import IndonesiaIcon from './images/metahuman/icon-indonesia.svg';
import ItalyIcon from './images/metahuman/icon-italy.svg';
import JapanIcon from './images/metahuman/icon-japan.svg';
import KoreaIcon from './images/metahuman/icon-korea.svg';
import NetherlandIcon from './images/metahuman/icon-netherland.svg';
import PhillippineIcon from './images/metahuman/icon-phillippine.svg';
import PolandIcon from './images/metahuman/icon-poland.svg';
import PortugalIcon from './images/metahuman/icon-portugal.svg';
import RussiaIcon from './images/metahuman/icon-russia.svg';
import TurkeyIcon from './images/metahuman/icon-turkey.svg';
import FranceIcon from './images/metahuman/lang_french.png';
import SpainIcon from './images/metahuman/lang_spanish.png';
import VietnamIcon from './images/metahuman/lang_vietnamese.png';
import {
  InfoFilterType,
  PhraseArrayType,
  ProductMembershipType,
  SubscribeMembershipType,
} from './types_new/main/landing/types';
import { MetahumanTabType } from './types_new/main/resource/types';
import {
  GetResourcesReqType,
  TemplateApplyStatusType,
} from './types_new/main/service/types';
import { PriceListType, SourceFilterType } from './types_new/project/types';

export const linkArray = [
  {
    name: '갤러리',
    link: '/gallery',
  },
  {
    name: '쇼케이스',
    link: '/showcase',
  },
  {
    name: '사용자 가이드',
    link: '/tutorial',
  },
  {
    name: '스토어',
    link: '/store',
  },
  {
    name: '요금',
    link: '/membership',
  },
  {
    name: '영상제작문의',
    link: '/production-inquiry',
  },
  {
    name: '공지사항',
    link: '/notice',
  },
  {
    name: '고객지원',
    link: '/support',
  },
];

export const myPageLinkArray = [
  {
    name: '내 정보',
    link: 'my-info',
  },
  {
    name: '결제 내역',
    link: 'payment',
  },
  {
    name: '크레딧 내역',
    link: 'credit',
  },
  // {
  //   name: '템플릿 내역',
  //   link: 'template',
  // },
  {
    name: '영상 템플릿 배포 내역',
    link: 'template-publish',
  },
  {
    name: '서비스 사용내역',
    link: 'service-usage',
  },
  {
    name: '1:1 문의 내역',
    link: 'inquiry',
  },
];

export const familySiteArray: { link: string; name: string }[] = [
  {
    link: 'https://ploonet.com',
    name: '플루닛',
  },
  {
    link: 'https://workcenter.ploonet.com/brand/WorkCenter',
    name: '플루닛 워크센터',
  },
  {
    link: 'https://saltlux.com',
    name: '솔트룩스',
  },
  {
    link: 'https://saltluxinno.com',
    name: '솔트룩스 이노베이션',
  },
  {
    link: 'https://saltlux.vn',
    name: '솔트룩스 테크놀로지',
  },
  {
    link: 'https://deepsignal.ai',
    name: '딥시그널',
  },
];

export const topPhraseArray: PhraseArrayType[] = [
  {
    heading: '가입만해도 매월 2만 크레딧',
    text1: '매월 지급되는 무료 크레딧으로 매월 20분씩 영상 제작',
    text2: '비용 부담없는 나만의 영상으로',
    text3: '메타휴먼 인기 크리에이터가 되어보세요!',
  },
  {
    heading: '제작 시간 단축',
    text1: '모델 섭외, 촬영, 녹음, 편집 등 기존의 어렵고 복잡했던 영상',
    text2: '제작 프로세스.',
    text3: '언제 어디서나 접속해 스크립트만 작성하면 바로 완성!',
  },
  {
    heading: '제작 비용 절약',
    text1: '모델료, 스튜디오 대여비, 장비 렌탈비 등 만만치 않은',
    text2: '영상 제작비. 파격적인 저렴한 상품(요금제)을 통해',
    text3: '나만의 고품질 영상 편집・제작 가능!',
  },
  {
    heading: '누구나 자유롭게 창작',
    text1: '메타휴먼이 쉽고 자유롭게 유투버, 쇼호스트, 광고모델이',
    text2: '될 수 있는 나만의 콘텐츠 창작이 가능.',
    text3: '여러분의 상상이 현실로!',
  },
  {
    heading: '시간·공간·언어 제약 초월',
    text1: '촬영장소, 전문가 장비,도구 사용 없이',
    text2: '인터넷만 연결되어 있다면',
    text3: '언제,어디서라도 영상 편집과 제작 가능!',
  },
];

export const guidePhraseArray: PhraseArrayType[] = [
  {
    heading: '프로젝트 생성하기',
    text1:
      '영상 제작을 위해서 새 프로젝트를 만들거나, 기존 프로젝트 복사하기를',
    text2: '통해 또 다른 프로젝트를 생성할 수 있습니다.',
    text3: '메타휴먼 인기 크리에이터가 되어보세요!',
  },
  {
    heading: '편집하기',
    text1:
      '메타휴먼 스크립트 작성을 시작으로 배경/꾸미기 패널에서 영상 분위기에',
    text2:
      '맞는 이미지 효과음, 텍스트를 추가하고, 타임라인에서 요소별 위치와 길이',
    text3: '조정으로 편집합니다.',
  },
  {
    heading: '영상 내보내기',
    text1: '[미리보기]를 통해 지금까지 작업한 영상을 확인하고, 필요한',
    text2: '영상 확장자와 화질을 선택하여 언제 어디에서나 사용가능한 나만의',
    text3: '영상을 다운로드 합니다.',
  },
];

export const productMembershipArray: ProductMembershipType[] = [
  {
    membership: 'Entry',
    description: '가입만 해도\n매월 2만원 무료 제공',
    pay: 0,
    credit: 20000,
    limit: 20,
  },
  {
    membership: 'Basic',
    description: '초보 크리에이터\n입문용!',
    pay: 9000,
    credit: 40000,
    limit: 40,
  },
  {
    membership: 'Professional',
    description: '전문 크리에이터\n제작용!',
    pay: 19000,
    credit: 100000,
    limit: 100,
  },
  {
    membership: 'Business',
    description: '고품질 영상\n대량 생산용!',
    pay: 39000,
    credit: 250000,
    limit: 250,
  },
];

export const subscriptionMembershipArray: SubscribeMembershipType[] = [
  {
    membership: 'Basic Plus',
    description: '',
    pay: 6750,
    credit: 40000,
    limit: 0,
  },
  {
    membership: 'Professional Plus',
    description: '',
    pay: 14250,
    credit: 100000,
    limit: 0,
  },
  {
    membership: 'Business Plus',
    description: '',
    pay: 29250,
    credit: 250000,
    limit: 0,
  },
];

export const membershipProductCodes = [
  {
    membership: 'Basic',
    productCode: 'CARD_OM_STUDIO_002',
  },
  {
    membership: 'Professional',
    productCode: 'CARD_OM_STUDIO_003',
  },
  {
    membership: 'Business',
    productCode: 'CARD_OM_STUDIO_004',
  },
  {
    membership: 'Basic Plus',
    productCode: 'CARD_SM_STUDIO_012',
  },
  {
    membership: 'Professional Plus',
    productCode: 'CARD_SM_STUDIO_013',
  },
  {
    membership: 'Business Plus',
    productCode: 'CARD_SM_STUDIO_014',
  },
];

export const supportCategoryArray = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '콘텐츠',
    value: 'content',
  },
  {
    label: '메타휴먼',
    value: 'metahuman',
  },
  {
    label: '영상편집',
    value: 'videoEditing',
  },
  {
    label: '번역',
    value: 'translation',
  },
  {
    label: '서비스',
    value: 'service',
  },
];

const VIDEO_PATH = '/videos/production-inquiry/';
const IMAGE_PATH = '/img/production-inquiry/thumbnail/';

export const videoArr1 = [
  {
    imgPath: `${IMAGE_PATH}autumn.png`,
    videoPath: `${VIDEO_PATH}autumn.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}bluelight.png`,
    videoPath: `${VIDEO_PATH}bluelight.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}cleaner.png`,
    videoPath: `${VIDEO_PATH}cleaner.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}eyecare2.png`,
    videoPath: `${VIDEO_PATH}eyecare2.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}deeptouch.png`,
    videoPath: `${VIDEO_PATH}deeptouch.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}autumn.png`,
    videoPath: `${VIDEO_PATH}autumn.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}bluelight.png`,
    videoPath: `${VIDEO_PATH}bluelight.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}cleaner.png`,
    videoPath: `${VIDEO_PATH}cleaner.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}eyecare2.png`,
    videoPath: `${VIDEO_PATH}eyecare2.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}deeptouch.png`,
    videoPath: `${VIDEO_PATH}deeptouch.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}autumn.png`,
    videoPath: `${VIDEO_PATH}autumn.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}bluelight.png`,
    videoPath: `${VIDEO_PATH}bluelight.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}cleaner.png`,
    videoPath: `${VIDEO_PATH}cleaner.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}eyecare2.png`,
    videoPath: `${VIDEO_PATH}eyecare2.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}deeptouch.png`,
    videoPath: `${VIDEO_PATH}deeptouch.mp4`,
  },
];

export const videoArr2 = [
  {
    imgPath: `${IMAGE_PATH}autumn2.png`,
    videoPath: `${VIDEO_PATH}autumn2.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}eyecare.png`,
    videoPath: `${VIDEO_PATH}eyecare.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}coolinggel.png`,
    videoPath: `${VIDEO_PATH}coolinggel.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}travel.png`,
    videoPath: `${VIDEO_PATH}travel.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}ulleungdo.png`,
    videoPath: `${VIDEO_PATH}ulleungdo.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}autumn2.png`,
    videoPath: `${VIDEO_PATH}autumn2.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}eyecare.png`,
    videoPath: `${VIDEO_PATH}eyecare.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}coolinggel.png`,
    videoPath: `${VIDEO_PATH}coolinggel.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}travel.png`,
    videoPath: `${VIDEO_PATH}travel.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}ulleungdo.png`,
    videoPath: `${VIDEO_PATH}ulleungdo.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}autumn2.png`,
    videoPath: `${VIDEO_PATH}autumn2.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}eyecare.png`,
    videoPath: `${VIDEO_PATH}eyecare.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}coolinggel.png`,
    videoPath: `${VIDEO_PATH}coolinggel.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}travel.png`,
    videoPath: `${VIDEO_PATH}travel.mp4`,
  },
  {
    imgPath: `${IMAGE_PATH}ulleungdo.png`,
    videoPath: `${VIDEO_PATH}ulleungdo.mp4`,
  },
];

export const signUpBreadCrumbs = [
  {
    label: '이용 약관 동의',
    params: 'terms',
  },
  {
    label: '회원 정보 입력',
    params: 'user-info',
  },
  {
    label: '회원 가입 완료',
    params: 'complete',
  },
];

export const termsArray = [
  {
    isMendatory: true,
    label: '서비스 이용약관',
    id: 'service',
  },
  {
    isMendatory: true,
    label: '개인정보 수집 및 이용 동의',
    id: 'personal',
  },
  {
    isMendatory: true,
    label: '스팸방지 정책',
    id: 'spam',
  },
  {
    isMendatory: false,
    label: '이벤트 및 혜택 문자 수신',
    id: 'sms',
  },
  {
    isMendatory: false,
    label: '이벤트 및 혜택 카카오톡 수신',
    id: 'kakaoTalk',
  },
  {
    isMendatory: false,
    label: '이벤트 및 혜택 이메일 수신',
    id: 'email',
  },
  {
    isMendatory: false,
    label: '광고성 정보 수신',
    id: 'ad',
  },
];

export const nationArr = [
  { code: 'KOR', number: '82', name: '대한민국' },
  { code: 'USA', number: '1', name: 'USA' },
  { code: 'CHN', number: '86', name: 'China' },
  { code: 'GBR', number: '44', name: 'UK' },
  { code: 'DZA', number: '213', name: 'Algeria' },
  { code: 'AGO', number: '244', name: 'Angola' },
  { code: 'ATG', number: '1268', name: 'Antigua&Barbuda' },
  { code: 'ARG', number: '54', name: 'Argentina' },
  { code: 'ARM', number: '374', name: 'Armenia' },
  { code: 'ABW', number: '297', name: 'Aruba' },
  { code: 'AUS', number: '61', name: 'Australia' },
  { code: 'AUT', number: '43', name: 'Austria' },
  { code: 'AZE', number: '994', name: 'Azerbaijan' },
  { code: 'BHS', number: '1242', name: 'Bahamas' },
  { code: 'BHR', number: '973', name: 'Bahrain' },
  { code: 'BGD', number: '880', name: 'Bangladesh' },
  { code: 'BRB', number: '1246', name: 'Barbados' },
  { code: 'BLR', number: '375', name: 'Belarus' },
  { code: 'BEL', number: '32', name: 'Belgium' },
  { code: 'BLZ', number: '501', name: 'Belize' },
  { code: 'BEN', number: '229', name: 'Benin' },
  { code: 'BMU', number: '1441', name: 'Bermuda' },
  { code: 'BTN', number: '975', name: 'Bhutan' },
  { code: 'BOL', number: '591', name: 'Bolivia' },
  { code: 'BIH', number: '387', name: 'Bosnia Herzegovina' },
  { code: 'BWA', number: '267', name: 'Botswana' },
  { code: 'BRA', number: '55', name: 'Brazil' },
  { code: 'BRN', number: '673', name: 'Brunei' },
  { code: 'BGR', number: '359', name: 'Bulgaria' },
  { code: 'BFA', number: '226', name: 'Burkina Faso' },
  { code: 'BDI', number: '257', name: 'Burundi' },
  { code: 'KHM', number: '855', name: 'Cambodia' },
  { code: 'CMR', number: '237', name: 'Cameroon' },
  { code: 'CAN', number: '1', name: 'Canada' },
  { code: 'CPV', number: '238', name: 'Cape Verde Islands' },
  { code: 'CYM', number: '1345', name: 'Cayman Islands' },
  { code: 'CAF', number: '236', name: 'Central African Republic' },
  { code: 'CHL', number: '56', name: 'Chile' },
  { code: 'COL', number: '57', name: 'Colombia' },
  { code: 'COM', number: '269', name: 'Comoros' },
  { code: 'COG', number: '242', name: 'Congo' },
  { code: 'COK', number: '682', name: 'Cook Islands' },
  { code: 'CRI', number: '506', name: 'Costa Rica' },
  { code: 'HRV', number: '385', name: 'Croatia' },
  { code: 'CUB', number: '53', name: 'Cuba' },
  { code: 'CYP', number: '90392', name: 'Cyprus North' },
  { code: 'CYP', number: '357', name: 'Cyprus South' },
  { code: 'CZE', number: '42', name: 'Czech' },
  { code: 'DNK', number: '45', name: 'Denmark' },
  { code: 'DJI', number: '253', name: 'Djibouti' },
  { code: 'DMA', number: '1809', name: 'Dominica' },
  { code: 'DOM', number: '1809', name: 'Dominican Republic' },
  { code: 'ECU', number: '593', name: 'Ecuador' },
  { code: 'EGY', number: '20', name: 'Egypt' },
  { code: 'SLV', number: '503', name: 'El Salvador' },
  { code: 'GNQ', number: '240', name: 'Equatorial Guinea' },
  { code: 'ERI', number: '291', name: 'Eritrea' },
  { code: 'EST', number: '372', name: 'Estonia' },
  { code: 'ETH', number: '251', name: 'Ethiopia' },
  { code: 'FLK', number: '500', name: 'Falkland Islands' },
  { code: 'FJI', number: '679', name: 'Fiji' },
  { code: 'FIN', number: '358', name: 'Finland' },
  { code: 'FRA', number: '33', name: 'France' },
  { code: 'PYF', number: '689', name: 'French Polynesia' },
  { code: 'GAB', number: '241', name: 'Gabon' },
  { code: 'GMB', number: '220', name: 'Gambia' },
  { code: 'GEO', number: '7880', name: 'Georgia' },
  { code: 'DEU', number: '49', name: 'Germany' },
  { code: 'GHA', number: '233', name: 'Ghana' },
  { code: 'GIB', number: '350', name: 'Gibraltar' },
  { code: 'GRC', number: '30', name: 'Greece' },
  { code: 'GRL', number: '299', name: 'Greenland' },
  { code: 'GRD', number: '1473', name: 'Grenada' },
  { code: 'GTM', number: '502', name: 'Guatemala' },
  { code: 'GIN', number: '224', name: 'Guinea' },
  { code: 'GNB', number: '245', name: 'Guinea  Bissau' },
  { code: 'GUY', number: '592', name: 'Guyana' },
  { code: 'HTI', number: '509', name: 'Haiti' },
  { code: 'HND', number: '504', name: 'Honduras' },
  { code: 'HKG', number: '852', name: 'Hong Kong' },
  { code: 'HUN', number: '36', name: 'Hungary' },
  { code: 'ISL', number: '354', name: 'Iceland' },
  { code: 'IND', number: '91', name: 'India' },
  { code: 'IDN', number: '62', name: 'Indonesia' },
  { code: 'IRN', number: '98', name: 'Iran' },
  { code: 'IRQ', number: '964', name: 'Iraq' },
  { code: 'IRL', number: '353', name: 'Ireland' },
  { code: 'ISR', number: '972', name: 'Israel' },
  { code: 'ITA', number: '39', name: 'Italy' },
  { code: 'JAM', number: '1876', name: 'Jamaica' },
  { code: 'JPN', number: '81', name: 'Japan' },
  { code: 'JOR', number: '962', name: 'Jordan' },
  { code: 'KAZ', number: '7', name: 'Kazakhstan' },
  { code: 'KEN', number: '254', name: 'Kenya' },
  { code: 'KIR', number: '686', name: 'Kiribati' },
  { code: 'PRK', number: '850', name: 'Korea North' },
  { code: 'KWT', number: '965', name: 'Kuwait' },
  { code: 'KGZ', number: '996', name: 'Kyrgyzstan' },
  { code: 'LAO', number: '856', name: 'Laos' },
  { code: 'LVA', number: '371', name: 'Latvia' },
  { code: 'LBN', number: '961', name: 'Lebanon' },
  { code: 'LSO', number: '266', name: 'Lesotho' },
  { code: 'LBR', number: '231', name: 'Liberia' },
  { code: 'LBY', number: '218', name: 'Libya' },
  { code: 'LTU', number: '370', name: 'Lithuania' },
  { code: 'LUX', number: '352', name: 'Luxembourg' },
  { code: 'MAC', number: '853', name: 'Macao' },
  { code: 'MKD', number: '389', name: 'Macedonia' },
  { code: 'MDG', number: '261', name: 'Madagascar' },
  { code: 'MWI', number: '265', name: 'Malawi' },
  { code: 'MYS', number: '60', name: 'Malaysia' },
  { code: 'MDV', number: '960', name: 'Maldives' },
  { code: 'MLI', number: '223', name: 'Mali' },
  { code: 'MLT', number: '356', name: 'Malta' },
  { code: 'MHL', number: '692', name: 'Marshall Islands' },
  { code: 'MRT', number: '222', name: 'Mauritania' },
  { code: 'MYT', number: '269', name: 'Mayotte' },
  { code: 'MEX', number: '52', name: 'Mexico' },
  { code: 'FSM', number: '691', name: 'Micronesia' },
  { code: 'MDA', number: '373', name: 'Moldova' },
  { code: 'MCO', number: '377', name: 'Monaco' },
  { code: 'MNG', number: '976', name: 'Mongolia' },
  { code: 'MSR', number: '1664', name: 'Montserrat' },
  { code: 'MAR', number: '212', name: 'Morocco' },
  { code: 'MOZ', number: '258', name: 'Mazambique' },
  { code: 'MMR', number: '95', name: 'Myanmar' },
  { code: 'NAM', number: '264', name: 'Namibia' },
  { code: 'NRU', number: '674', name: 'Nauru' },
  { code: 'NPL', number: '977', name: 'Nepal' },
  { code: 'NLD', number: '31', name: 'Netherlands' },
  { code: 'NCL', number: '687', name: 'New Caledonia' },
  { code: 'NZL', number: '64', name: 'New Zealand' },
  { code: 'NIC', number: '505', name: 'Nicaragua' },
  { code: 'NER', number: '227', name: 'Niger' },
  { code: 'NGA', number: '234', name: 'Nigeria' },
  { code: 'NIU', number: '683', name: 'Niue' },
  { code: 'MNP', number: '670', name: 'Northern Marianas' },
  { code: 'NOR', number: '47', name: 'Norway' },
  { code: 'OMN', number: '968', name: 'Oman' },
  { code: 'PLW', number: '680', name: 'Palau' },
  { code: 'PAN', number: '507', name: 'Panama' },
  { code: 'PNG', number: '675', name: 'Papua New Guinea' },
  { code: 'PRY', number: '595', name: 'Paraguay' },
  { code: 'PER', number: '51', name: 'Peru' },
  { code: 'PHL', number: '63', name: 'Philippines' },
  { code: 'POL', number: '48', name: 'Poland' },
  { code: 'PRT', number: '351', name: 'Portugal' },
  { code: 'QAT', number: '974', name: 'Qatar' },
  { code: 'ROU', number: '40', name: 'Romania' },
  { code: 'RUS', number: '7', name: 'Russia' },
  { code: 'RWA', number: '250', name: 'Rwanda' },
  { code: 'STP', number: '239', name: 'Sao Tome & Principe' },
  { code: 'SAU', number: '966', name: 'Saudi Arabia' },
  { code: 'SEN', number: '221', name: 'Senegal' },
  { code: 'SRB', number: '381', name: 'Serbia' },
  { code: 'SYC', number: '248', name: 'Seychelles' },
  { code: 'SLE', number: '232', name: 'Sierra Leone' },
  { code: 'SGP', number: '65', name: 'Singapore' },
  { code: 'SVK', number: '421', name: 'Slovak Republic' },
  { code: 'SVN', number: '386', name: 'Slovenia' },
  { code: 'SLB', number: '677', name: 'Solomon Islands' },
  { code: 'SOM', number: '252', name: 'Somalia' },
  { code: 'ZAF', number: '27', name: 'South Africa' },
  { code: 'ESP', number: '34', name: 'Spain' },
  { code: 'LKA', number: '94', name: 'Sri Lanka' },
  { code: 'SHN', number: '290', name: 'St.Helena' },
  { code: 'KNA', number: '1869', name: 'St.Kitts' },
  { code: 'LCA', number: '1758', name: 'St.Lucia' },
  { code: 'SDN', number: '249', name: 'Sudan' },
  { code: 'SUR', number: '597', name: 'Suriname' },
  { code: 'SWE', number: '46', name: 'Sweden' },
  { code: 'SYR', number: '963', name: 'Syria' },
  { code: 'TJK', number: '7', name: 'Tajikstan' },
  { code: 'THA', number: '66', name: 'Thailand' },
  { code: 'TGO', number: '228', name: 'Togo' },
  { code: 'TON', number: '676', name: 'Tonga' },
  { code: 'TTO', number: '1868', name: 'Trinidad  Tobago' },
  { code: 'TUN', number: '216', name: 'Tunisia' },
  { code: 'TUR', number: '90', name: 'Turkey' },
  { code: 'TKM', number: '7', name: 'Turkmenistan' },
  { code: 'TCA', number: '1649', name: 'Turks  Caicos Islands' },
  { code: 'TUV', number: '688', name: 'Tuvalu' },
  { code: 'UGA', number: '256', name: 'Uganda' },
  { code: 'UKR', number: '380', name: 'Ukraine' },
  { code: 'ARE', number: '971', name: 'United Arab Emirates' },
  { code: 'URY', number: '598', name: 'Uruguay' },
  { code: 'UZB', number: '7', name: 'Uzbekistan' },
  { code: 'VUT', number: '678', name: 'Vanuatu' },
  { code: 'VAT', number: '379', name: 'Vatican City' },
  { code: 'VEN', number: '58', name: 'Venezuela' },
  { code: 'VNM', number: '84', name: 'Vietnam' },
  { code: 'VGB', number: '84', name: 'Virgin Islands - British' },
  { code: 'WLF', number: '681', name: 'Wallis  Futuna' },
  { code: 'YEM', number: '969', name: 'Yemen' },
  { code: 'ZMB', number: '260', name: 'Zambia' },
  { code: 'ZWE', number: '263', name: 'Zimbabwe' },
];

export const creditFilterArray: InfoFilterType[] = [
  {
    label: 'ALL',
    value: '',
  },
  {
    label: '멤버십',
    value: 'membership',
  },
  {
    label: '영상생성',
    value: 'export video',
  },
  {
    label: '배경',
    value: 'background',
  },
  {
    label: '꾸미기',
    value: 'clip',
  },
  {
    label: '메타휴먼',
    value: 'metahuman',
  },
  {
    label: '전문번역',
    value: 'translation',
  },
  // FIXME: fix value for templates
  {
    label: '템플릿 다운로드',
    value: 'translation',
  },
  {
    label: '템플릿 판매',
    value: 'translation',
  },
];

export const paymentFilterArray: InfoFilterType[] = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: '요청',
    value: 'B20101',
  },
  {
    label: '완료',
    value: 'B20111',
  },
];

export const templateFilterArray: InfoFilterType[] = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: '구매',
    value: 'buy',
  },
  {
    label: '판매',
    value: 'sell',
  },
];

export const templateApplyStatusArray: {
  label: string;
  value: TemplateApplyStatusType;
}[] = [
  {
    label: 'ALL',
    value: '',
  },
  {
    label: '승인',
    value: 'agree',
  },
  {
    label: '반려',
    value: 'reject',
  },
  {
    label: '삭제',
    value: 'deleted',
  },
  {
    label: '취소',
    value: 'applyCancel',
  },
  { label: '진행중', value: 'apply' },
];

export const serviceUsageFilterArray: InfoFilterType[] = [
  {
    label: 'ALL',
    value: 'all',
  },
  {
    label: '요청',
    value: 'request',
  },
  {
    label: '진행중',
    value: 'request',
  },
  {
    label: '완료',
    value: 'complete',
  },
];

export const inquiryFilterArray: InfoFilterType[] = [
  {
    label: 'ALL',
    value: '',
  },
  {
    label: '진행중',
    value: 'request',
  },
  {
    label: '완료',
    value: 'complete',
  },
];

export const inquiryCategoryArray = [
  '1:1문의',
  '콘텐츠',
  '메타휴먼',
  '결제/환불',
  '버그신고',
  '개선제안',
  '번역',
  '제휴문의',
  '기타',
  '제작문의',
];

export const consoleNavigationArray = [
  {
    label: '홈',
    link: '/console',
  },
  {
    label: '내 프로젝트',
    link: '/my-projects',
  },
  {
    label: '내 메타휴먼',
    link: '/my-metahumans',
  },
  {
    label: '내 템플릿',
    link: '/my-templates',
  },
  {
    label: '내 미디어',
    link: '/media',
  },
];

export const metahumanModalStepArray: {
  tabName: string;
  tabValue: MetahumanTabType;
}[] = [
  { tabName: '메타휴먼 선택', tabValue: 'metahuman' },
  { tabName: '스타일 선택', tabValue: 'style' },
  { tabName: '자세 선택', tabValue: 'posture' },
  { tabName: '보이스 선택', tabValue: 'voice' },
  { tabName: '스크립트 작성', tabValue: 'script' },
];

export const templateOptionArray: { value: string; label: string }[] = [
  {
    value: '16:9',
    label: '기본 사이즈 (16:9)',
  },
  {
    value: '4:3',
    label: '풀스크린 (4:3)',
  },
  {
    value: '1:1',
    label: 'Instagram Posting (1:1)',
  },
  {
    value: '16:10',
    label: 'Smartphone wide (16:10)',
  },
  {
    value: '9:16',
    label: 'Instagram story (9:16)',
  },
  {
    value: '10:16',
    label: 'SmartPhone (10:16)',
  },
];

export const speedOptionArray: { value: number; label: string }[] = [
  {
    value: 0.8,
    label: '매우 느림',
  },
  {
    value: 0.9,
    label: '느림',
  },
  {
    value: 1.0,
    label: '보통',
  },
  {
    value: 1.1,
    label: '빠름',
  },
  {
    value: 1.2,
    label: '매우 빠름',
  },
];

export const languageArray = [
  {
    value: 'en',
    label: '영어 (English)',
    flag: AmericaIcon,
  },
  {
    value: 'ko',
    label: '한국어 (Korean)',
    flag: KoreaIcon,
  },
  {
    value: 'zh-cn',
    label: '중국어 (Chinese)',
    flag: ChinaIcon,
  },
  {
    value: 'ja',
    label: '일본어 (Japanese)',
    flag: JapanIcon,
  },
  {
    value: 'vi',
    label: '베트남어 (Vietnamese)',
    flag: VietnamIcon,
  },
  {
    value: 'fr',
    label: '프랑스어 (French)',
    flag: FranceIcon,
  },
  {
    value: 'es',
    label: '스페인어 (Spanish)',
    flag: SpainIcon,
  },
  {
    value: 'cs',
    label: '체코어 (Czech)',
    flag: CzechIcon,
  },
  {
    value: 'da',
    label: '덴마크어 (Danish)',
    flag: DenmarkIcon,
  },
  {
    value: 'de',
    label: '독일어 (German)',
    flag: GermanyIcon,
  },
  {
    value: 'fil',
    label: '필리핀어 (Phillippines)',
    flag: PhillippineIcon,
  },
  {
    value: 'hi',
    label: '힌디어 (Hindi)',
    flag: IndiaIcon,
  },
  {
    value: 'id',
    label: '인도네시아어 (Indonesian)',
    flag: IndonesiaIcon,
  },
  {
    value: 'it',
    label: '이탈리아어 (Italian)',
    flag: ItalyIcon,
  },
  {
    value: 'nl',
    label: '네덜란드어 (Netherlands)',
    flag: NetherlandIcon,
  },
  {
    value: 'pl',
    label: '폴란드어 (Polish)',
    flag: PolandIcon,
  },
  {
    value: 'pt',
    label: '포르투갈어 (Portuguese)',
    flag: PortugalIcon,
  },
  {
    value: 'ru',
    label: '러시아어 (Russian)',
    flag: RussiaIcon,
  },
  {
    value: 'tr',
    label: '터키어 (Turkish)',
    flag: TurkeyIcon,
  },
];

export const expertLanguageArray = [
  {
    value: 'en',
    label: '영어 (English)',
  },
  {
    value: 'ko',
    label: '한국어 (Korean)',
  },
  {
    value: 'zh-cn',
    label: '중국어 (Chinese)',
  },
  {
    value: 'ja',
    label: '일본어 (Japanese)',
  },
  {
    value: 'vi',
    label: '베트남어 (Vietnamese)',
  },
  {
    value: 'fr',
    label: '프랑스어 (French)',
  },
  {
    value: 'es',
    label: '스페인어 (Spanish)',
  },
];

export const multiplierArray = [
  {
    percentage: 0,
    multiplier: 0.08,
  },
  {
    percentage: 10,
    multiplier: 0.1,
  },
  {
    percentage: 20,
    multiplier: 0.12,
  },
  {
    percentage: 30,
    multiplier: 0.14,
  },
  {
    percentage: 40,
    multiplier: 0.16,
  },
  {
    percentage: 50,
    multiplier: 0.18,
  },
  {
    percentage: 60,
    multiplier: 0.2,
  },
  {
    percentage: 70,
    multiplier: 0.22,
  },
  {
    percentage: 80,
    multiplier: 0.24,
  },
  {
    percentage: 90,
    multiplier: 0.26,
  },
  {
    percentage: 100,
    multiplier: 0.28,
  },
];

export const projectStatusArray = [
  {
    label: '전체 프로젝트',
    value: '',
  },
  {
    label: '배포한 프로젝트',
    value: 'publish',
  },
  {
    label: '편집중인 프로젝트',
    value: 'pending',
  },
  // FIXME: fix value for templates
  {
    label: '템플릿 배포 프로젝트',
    value: 'complete',
  },
];

export const galleryStatusArray = [
  {
    label: '무료',
    value: '',
  },
  {
    label: '유료',
    value: '',
  },
];

export const sourceGroupArray = [
  {
    label: '배경',
    value: 'background',
    countLabel: 'background',
    iconPath: '/img/media-icons/bg-icon.png',
    array: [
      {
        label: '배경 이미지',
        value: 'image',
        countLabel: 'bgImg',
        iconPath: '/img/media-icons/image-icon.png',
      },
      {
        label: '배경 영상',
        value: 'video',
        countLabel: 'bgVideo',
        iconPath: '/img/media-icons/video-icon.png',
      },
      {
        label: '배경 음악',
        value: 'audio',
        countLabel: 'bgAudio',
        iconPath: '/img/media-icons/music-icon.png',
      },
    ],
  },
  {
    label: '꾸미기',
    value: 'clip',
    countLabel: 'clip',
    iconPath: '/img/media-icons/clip-icon.png',
    array: [
      {
        label: '꾸미기 이미지',
        countLabel: 'clipImg',
        value: 'image',
        iconPath: '/img/media-icons/image-icon.png',
      },
      {
        label: '꾸미기 영상',
        countLabel: 'clipVideo',
        value: 'video',
        iconPath: '/img/media-icons/video-icon.png',
      },
      {
        label: '효과음',
        countLabel: 'clipAudio',
        value: 'audio',
        iconPath: '/img/media-icons/sound-icon.png',
      },
    ],
  },
  {
    label: '기타',
    value: 'etc',
    countLabel: 'etc',
    iconPath: '/img/media-icons/etc-icon.png',
    array: [
      {
        label: 'PPT',
        countLabel: 'etcPpt',
        value: 'etcPpt',
        iconPath: '/img/media-icons/ppt-icon.png',
      },
      {
        label: 'PDF',
        countLabel: 'etcPdf',
        value: 'etcPdf',
        iconPath: '/img/media-icons/pdf-icon.png',
      },
    ],
  },
];

export const backgroundCategoryArray: {
  label: string;
  value?: GetResourcesReqType['type'];
}[] = [
  {
    label: '이미지',
    value: 'image',
  },
  {
    label: '영상',
    value: 'video',
  },
  {
    label: '음악',
    value: 'audio',
  },
  {
    label: '컬러',
  },
];

export const clipCategoryArray: {
  label: string;
  value?: GetResourcesReqType['type'];
}[] = [
  {
    label: '이미지',
    value: 'image',
  },
  {
    label: '영상',
    value: 'video',
  },
  {
    label: '효과음',
    value: 'audio',
  },
];

export const sourceFilterArray: { label: string; value: SourceFilterType }[] = [
  {
    label: '기본',
    value: 'default',
  },
  {
    label: '구매',
    value: 'purchased',
  },
  {
    label: '업로드',
    value: 'uploaded',
  },
];

export const mediaTypeArray: {
  label: string;
  value: GetResourcesReqType['type'];
}[] = [
  {
    label: '전체',
    value: '',
  },
  {
    label: '이미지',
    value: 'image',
  },
  {
    label: '영상',
    value: 'video',
  },
  {
    label: '효과음',
    value: 'audio',
  },
];

export const etcTypeArray: {
  label: string;
  value: GetResourcesReqType['type'];
}[] = [
  {
    label: '전체',
    value: '',
  },
  {
    label: 'PPT',
    value: 'ppt',
  },
  {
    label: 'PDF',
    value: 'pdf',
  },
];

export const priceList: PriceListType = {
  character: {
    ko: {
      en: 110,
      'zh-cn': 110,
      ja: 110,
      vi: 160,
      fr: 175,
      es: 215,
    },
    'zh-cn': {
      ko: 110,
    },
    ja: {
      ko: 110,
    },
  },
  word: {
    vi: {
      ko: 350,
    },
    fr: {
      ko: 370,
    },
    es: {
      ko: 350,
    },
    en: {
      ko: 130,
      'zh-cn': 130,
      ja: 310,
      vi: 220,
      fr: 280,
      es: 220,
    },
  },
};

export const textList = [
  'Arial',
  'Bahnschrift',
  'Batang',
  'Calibri',
  'Cambria',
  'Candara',
  'Comic Sans MS',
  'Consolas',
  'Constantia',
  'Corbel',
  'Courier New',
  'Dotum',
  'Ebrima',
  'Franklin Gothic Medium',
  'Gabriola',
  'Gadugi',
  'Georgia',
  'Gulim',
  'Gungsuh',
  'Impact',
  'Ink Free',
  'Lucida Console',
  'Lucida Sans Unicode',
  'Malgun Gothic',
  'Marlett',
  'Nirmala UI',
  'Segoe UI',
  'Tahoma',
  'Times New Roman',
  'Verdana',
  'MaruBuri',
  'NanumSquare',
  'NanumSquareRound',
  'NanumMyeongjo',
  'NanumGothic',
  'NanumBarunpen',
  'NanumBarunGothic',
  'Nanum Brush Script',
  'Nanum Pen Script',
];

export const fontWeightList = [
  'Thin',
  'Light',
  'Regular',
  'Medium',
  'Bold',
  'Black',
];

export const colorPalleteArray = [
  '#ffffff',
  '#ed6c74',
  '#ec7e56',
  '#87cd9d',
  '#4481f7',
  '#6f5df6',
  '#fadf7b',
  '#000000',
];

export const weightListArray = [
  { value: 200, label: 'thin' },
  { value: 300, label: 'light' },
  { value: 400, label: 'regular' },
  { value: 500, label: 'medium' },
  { value: 700, label: 'bold' },
  { value: 900, label: 'black' },
];
