import moment from 'moment';
import { Dispatch, RefObject, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectInfoType } from '../../../types_new/console/resource/types';
import { changeHost } from '../../../utils/common';
import ProjectDetailPop from '../home/project-detail-pop';
import TemplateBadge from '../home/template-badge';
import CardShadow from './card-shadow';
import StatusBadge from './status-badge';
import TableSkeleton from './table-skeleton';

const TH_CLASS = 'font-normal w-[12%] text-center';
const TD_CLASS = 'text-[#1A2032] w-[12%] text-center';
const statusMap: any = {
  pending: {
    proceed: '인코딩중',
    fail: '실패',
    '': '편집중',
  },
  publish: {
    complete: '영상 완료',
  },
};

type Props = {
  updateDuplicatedProject: () => void;
  setProjects: Dispatch<SetStateAction<ProjectInfoType[]>>;
  projects: ProjectInfoType[];
  loading: boolean;
  loadingMore: boolean;
  bottomBoundaryRef: RefObject<HTMLDivElement>;
};

export default function ProjectsTable({
  updateDuplicatedProject,
  setProjects,
  projects,
  loading,
  loadingMore,
  bottomBoundaryRef,
}: Props) {
  const maxcount = process.env.REACT_APP_PREVIEW_MAX;
  const { t } = useTranslation();

  const displayStatus = project => {
    const isExportedAsVoice =
      project.exportVoice === 'Y' && project.producing === 'complete';

    return isExportedAsVoice
      ? '음성 완료'
      : statusMap[project.status][project.producing] || '';
  };

  return (
    <section className="w-full overflow-x-hidden mt-4">
      <div className="w-full">
        <ul className="w-full flex bg-[#F1F4F8] rounded-md text-sm text-[#363D59] py-[13px]">
          <li className="w-[33%] pl-[90px]">{t('제목')}</li>
          <li className={TH_CLASS}>{t('미리보기 횟수')}</li>
          <li className={TH_CLASS}>{t('장면 수')}</li>
          <li className={TH_CLASS}>{t('재생시간')}</li>
          <li className={TH_CLASS}>{t('크레딧 차감')}</li>
          <li className={TH_CLASS}>{t('상태')}</li>
          <li className={`${TH_CLASS} rounded-r-md`}>{t('상세')}</li>
        </ul>
      </div>
      <div
        style={{ height: 'calc(100vh - 450px)' }}
        className="w-full secondary-scroll overflow-y-auto">
        {loading ? (
          <TableSkeleton />
        ) : (
          projects?.map((project, idx) => (
            <ul
              className="w-full flex items-center border-b border-grayBorder2"
              key={`project-${idx}`}>
              <li className="py-5 w-1/3">
                <div className="flex items-center space-x-2.5">
                  <div className="flex items-center">
                    <div className="relative w-[70px] h-[70px] rounded-[10px] bg-gradient-to-br from-[#9D9BA0] to-[#BFBFBF] overflow-hidden">
                      <img
                        src={`${changeHost(project.thumbnail)}`}
                        alt="thumbnail"
                      />
                      {project.templateBaseProjYn === 'Y' && (
                        <TemplateBadge isSmall />
                      )}
                    </div>
                    <CardShadow pageNum={project.pageNum} />
                  </div>
                  <div className="relative flex flex-col space-y-2 w-[80%]">
                    <h5 className="font-medium 2xl:text-lg group line-clamp-1 break-normal">
                      <span className="cursor-pointe">
                        {project.projectName}
                      </span>
                      {project.content && (
                        <div className="absolute opacity-0 group-hover:opacity-100 -bottom-1 left-0 max-w-64 w-full py-1 px-4 text-sm shadow-xl rounded-md bg-white duration-150 ease-out">
                          <span className="text-sm block text-gray-500">
                            프로젝트설명
                          </span>
                          <span className="block break-normal">
                            {project.content}
                          </span>
                        </div>
                      )}
                    </h5>
                    <span className="text-sm text-[#ADADAD]">
                      생성일시
                      {moment(project.registerDate).format('YYYY.MM.DD')}
                    </span>
                  </div>
                </div>
              </li>
              <li className={`${TD_CLASS} font-medium`}>
                {project.previewNum} / {maxcount}
              </li>
              <li className={`${TD_CLASS} font-medium`}>{project.pageNum}</li>
              <li className={`${TD_CLASS} font-medium`}>{project.duration}</li>
              <li className={`${TD_CLASS} font-medium`}>
                {project.credit ? `-${project.credit}CR` : '-'}
              </li>
              <li className={TD_CLASS}>
                <div className="flex justify-center items-center space-x-1.5">
                  <StatusBadge
                    producing={project.producing}
                    status={project.status}
                  />
                  <span>{t(displayStatus(project))}</span>
                </div>
              </li>
              <li className={TD_CLASS}>
                <div className="w-fit mx-auto relative">
                  <ProjectDetailPop
                    updateDuplicatedProject={updateDuplicatedProject}
                    setProjects={setProjects}
                    projectId={project.projectId}
                    status={project.status}
                    duration={project.duration}
                    downloadLink={project.downloadLink}
                    completedThumbnail={project.completedThumbnail}
                    projectName={project.projectName}
                    pageNum={project.pageNum}
                    thumbnail={project.thumbnail}
                    exportVoice={project.exportVoice}
                    projectContent={project.content}
                    registerDate={project.registerDate}
                    producing={project.producing}
                  />
                </div>
              </li>
            </ul>
          ))
        )}
        <div ref={bottomBoundaryRef}></div>
        {loadingMore && <TableSkeleton />}
      </div>
    </section>
  );
}
