import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { IMemberLoginData } from '../../../networks/dto/IMemberDto';
import {
  isSignedAtom,
  loginInfoAtom,
  removeStorageToken,
  removeStorageUserInfo,
  userInfoAtom,
} from '../../../networks/store/member';
import { getMainDir } from '../../../util/common';
import LeaveIcon from '../../svg/leave-icon';
import SettingIcon from '../../svg/setting-icon';

export default function AvatarPop() {
  const setIsLoggedIn = useSetRecoilState(isSignedAtom);
  const setLoginInfo = useSetRecoilState(loginInfoAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const navigate = useNavigate();

  const logOut = () => {
    removeStorageToken();
    removeStorageUserInfo();
    setIsLoggedIn(false);
    setLoginInfo({} as IMemberLoginData);
    navigate('/');
  };

  return (
    <Popover className="relative w-10 h-10">
      <Popover.Button className="focus:outline-none ring-offset-0 ring-0">
        <div
          style={{
            backgroundImage: `url(${getMainDir('/img/default-avatar.png')})`,
          }}
          className="w-10 h-10 rounded-full bg-cover bg-center"
        />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0 scale-90 translate-y-10"
        enterTo="opacity-100 scale-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 scale-100 translate-y-0"
        leaveTo="opacity-0 scale-90 translate-y-10">
        <Popover.Panel className="absolute -top-[200px] z-10 mb-3 px-5 py-6 overflow-hidden rounded-lg shadow-lg bg-white">
          <div className="flex flex-col space-y-1.5">
            <span className="text-black">{userInfo.userName}</span>
            <span className="text-[#363D59] text-[13px]">
              {userInfo.userEmail}
            </span>
          </div>
          <div className="h-px w-full bg-[#E9E9E9] mt-4 mb-5" />
          <div className="w-full flex flex-col items-start space-y-4 text-consoleDark text-xs">
            <Link to="/my-page/my-info">
              <button className="flex items-center space-x-3 group">
                <SettingIcon />
                <span className="group-hover:underline underline-offset-2">
                  마이페이지
                </span>
              </button>
            </Link>
            <button
              onClick={logOut}
              className="flex items-center space-x-3 group">
              <LeaveIcon />
              <span className="group-hover:underline underline-offset-2">
                로그아웃
              </span>
            </button>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
