import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enJson from './langs/all.en.json';
import koJson from './langs/all.ko.json';

const resources = {
  en: {
    translation: enJson,
  },
  ko: {
    translation: koJson,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: process.env.REACT_APP_LANGUAGE,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
