import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

type Props = {
  content: string;
  videoURL: string;
  isOpen: boolean;
  closeModal: () => void;
};

export default function TutorialModal({
  content,
  videoURL,
  isOpen,
  closeModal,
}: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="z-10 fixed right-1/2 translate-x-1/2 bottom-0 w-full lg:w-3/4 xl:w-2/3 max-w-3xl h-fit bg-black">
            <div className="fixed bottom-0 w-full h-10 lg:h-12 2xl:h-16 bg-gradient-to-t from-white to-white/10" />
          </div>
        </Transition.Child>
        <div className="fixed w-full h-2/3 sm:h-5/6 md:h-[90%] inset-auto bottom-0 duration-150 overflow-visible">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0  translate-y-80"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0  translate-y-80"
          >
            <Dialog.Panel className="relative w-full text-left mx-auto lg:w-3/4 xl:w-2/3 max-w-3xl h-full !overflow-x-visible !overflow-y-auto no-scroll transform rounded-t-2xl lg:rounded-t-md p-8 lg:p-10 2xl:p-14 bg-white transition-all">
              <video
                playsInline
                className="rounded-lg max-w-xl w-full mx-auto"
                controls
                controlsList="nodownload"
                autoPlay
                muted
              >
                <track default kind="captions" />
                <source src={videoURL} type="video/mp4" />
              </video>
              <article className="mt-5 lg:mt-10">
                <div
                  className="text-[#333A43] text-sm md:text-base leading-6 md:leading-7 prose lg:prose-lg xl:prose-xl max-w-none"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </article>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
