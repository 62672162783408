import { useLocation } from 'react-router-dom';

const whiteThemePathArray = [
  '/tutorial',
  '/support',
  '/my-page',
  '/my-projects',
  '/console',
  '/media',
  '/project',
  '/my-metahumans',
];
const swiperPathArray = ['/membership'];
const centeredTitlePathArray = ['/notice', '/support', '/production-inquiry'];
const widePathArray = ['/production-inquiry'];

export default function usePath() {
  const { pathname } = useLocation();
  const isAtMain = pathname === '/';
  const isWhiteTheme = whiteThemePathArray.some((path) =>
    pathname.startsWith(path)
  );
  const hasSwiper = swiperPathArray.includes(pathname);
  const isTitleCentered = centeredTitlePathArray.includes(pathname);
  const isWide = widePathArray.includes(pathname);

  return {
    pathname,
    isAtMain,
    isWhiteTheme,
    hasSwiper,
    isTitleCentered,
    isWide,
  };
}
