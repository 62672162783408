import { useState } from 'react';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { SourceType } from '../../../types_new/console/resource/types';
import VideoModal from '../../VideoModal';
import ImageModal from '../../modals/image-modal';
import { LeftArrow, RightArrow } from './arrows';
import MediaSourceCard from './media-source-card';

type Props = {
  sources: SourceType[];
  getResourceList: () => void;
};

export default function MediaSourceGrid({ sources, getResourceList }: Props) {
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    imageURL: '',
  });
  const [videoModal, setVideoModal] = useState({
    isOpen: false,
    videoURL: '',
  });

  return (
    <>
      <ImageModal
        isOpen={imageModal.isOpen}
        imageURL={imageModal.imageURL}
        closeModal={() => setImageModal(prev => ({ ...prev, isOpen: false }))}
      />
      <VideoModal
        isOpen={videoModal.isOpen}
        videoURL={videoModal.videoURL}
        closeModal={() => setVideoModal(prev => ({ ...prev, isOpen: false }))}
      />
      <div className="relative mt-5 !overflow-visible">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {sources?.map((source, idx) => (
            <MediaSourceCard
              setImageModal={setImageModal}
              setVideoModal={setVideoModal}
              key={`source-${idx}`}
              source={source}
              getResourceList={getResourceList}
            />
          ))}
        </ScrollMenu>
      </div>
    </>
  );
}
