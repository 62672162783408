export default function BoldIcon() {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.77676 6.11152C10.2033 5.66719 10.4648 5.0667 10.4648 4.40654V4.27705C10.4648 2.91104 9.34639 1.80273 7.96768 1.80273H4.21113C4.01943 1.80273 3.86328 1.95889 3.86328 2.15059V10.772C3.86328 10.9789 4.03086 11.1465 4.23779 11.1465H8.28379C9.76914 11.1465 10.9727 9.95059 10.9727 8.47412V8.33447C10.9727 7.40771 10.4979 6.59141 9.77676 6.11152ZM5.08203 3.02148H7.93467C8.65957 3.02148 9.24609 3.58516 9.24609 4.28213V4.40273C9.24609 5.09844 8.6583 5.66338 7.93467 5.66338H5.08203V3.02148ZM9.73613 8.47285C9.73613 9.27139 9.07978 9.91885 8.26982 9.91885H5.08203V6.88975H8.26982C9.07978 6.88975 9.73613 7.53721 9.73613 8.33574V8.47285Z"
        fill="#A3AAB8"
      />
    </svg>
  );
}
