import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { familySiteArray } from '../../arrays';
import PlusIcon from '../svg/plus-icon';

export default function FamilySiteListBox() {
  const { t } = useTranslation();

  return (
    <Listbox>
      <div className="relative mt-1">
        <Listbox.Button className="relative flex justify-between items-center !w-[168px] py-2 px-[13px] text-sm leading-6 bg-[#191919] text-white rounded-md">
          <span className="block truncate">Family Site</span>
          <PlusIcon />
        </Listbox.Button>
        <Transition
          as={Fragment}
          enter="ease-in-out duration-150"
          enterFrom="opacity-0 -translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in-out duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-2">
          <Listbox.Options className="absolute z-10 mt-1 max-h-44 w-full overflow-auto py-2 text-sm leading-6 bg-[#191919] text-white rounded-md">
            {familySiteArray.map(({ link, name }) => (
              <Listbox.Option
                key={name}
                className={({ active }) =>
                  `relative select-none py-2 px-[13px] cursor-pointer duration-75 ${
                    active && 'bg-primary'
                  }`
                }
                value={name}
                onClick={() => window.open(link)}>
                <span className="block truncate">{t(name)}</span>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
