export default function TrashBinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none">
      <path
        d="M5.25 5.6875C5.25 5.44588 5.44588 5.25 5.6875 5.25C5.92912 5.25 6.125 5.44588 6.125 5.6875V10.0625C6.125 10.3041 5.92912 10.5 5.6875 10.5C5.44588 10.5 5.25 10.3041 5.25 10.0625V5.6875ZM7.875 5.6875C7.875 5.44588 8.07088 5.25 8.3125 5.25C8.55412 5.25 8.75 5.44588 8.75 5.6875V10.0625C8.75 10.3041 8.55412 10.5 8.3125 10.5C8.07088 10.5 7.875 10.3041 7.875 10.0625V5.6875Z"
        fill="white"
      />
      <path
        d="M2.1875 2.625C1.94588 2.625 1.75 2.82088 1.75 3.0625C1.75 3.30412 1.94588 3.5 2.1875 3.5H2.625V12.25C2.625 12.4821 2.71719 12.7046 2.88128 12.8687C3.04538 13.0328 3.26794 13.125 3.5 13.125H10.5C10.7321 13.125 10.9546 13.0328 11.1187 12.8687C11.2828 12.7046 11.375 12.4821 11.375 12.25V3.5H11.8125C12.0541 3.5 12.25 3.30412 12.25 3.0625C12.25 2.82088 12.0541 2.625 11.8125 2.625H2.1875ZM3.5 12.25V3.5H10.5V12.25H3.5ZM5.25 1.3125C5.25 1.07088 5.44588 0.875 5.6875 0.875H8.3125C8.55412 0.875 8.75 1.07088 8.75 1.3125C8.75 1.55412 8.55412 1.75 8.3125 1.75H5.6875C5.44588 1.75 5.25 1.55412 5.25 1.3125Z"
        fill="white"
      />
    </svg>
  );
}
