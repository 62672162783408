import { Dispatch, SetStateAction, useRef, useState } from 'react';
import AudioPauseIcon from '../../svg/AudioPauseIcon';
import MusicIcon from '../../svg/console/music-icon';

type Props = {
  className: string;
  filePath: string;
  audioActive: string;
  setAudioActive: Dispatch<SetStateAction<string>>;
};

export default function MusicSourceCard({
  className,
  filePath,
  audioActive,
  setAudioActive,
}: Props) {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handleAudioClick = (source: string) => {
    const audio = audioRef.current;
    if (!audio) return;

    if (audio.paused) {
      playAudio(audio, filePath);
    } else {
      setIsPlaying(false);
      audio.pause();

      if (source !== audioActive) {
        playAudio(audio, source);
      }
    }
  };

  function playAudio(audio: HTMLAudioElement | null, source: string) {
    if (!audio) return;

    setIsPlaying(true);
    audio.src = source;
    audio.play();
    audio.volume = 0.5;
  }

  if (audioRef.current) {
    audioRef.current.onended = () => setIsPlaying(false);
  }

  return (
    <div
      onClick={() => {
        handleAudioClick(filePath);
        setAudioActive(filePath);
      }}
      className={`${className} bg-[#F0F2FD] hover:bg-[#e3e8ff]`}
    >
      <audio ref={audioRef} />
      {isPlaying ? <AudioPauseIcon isBig /> : <MusicIcon />}
    </div>
  );
}
