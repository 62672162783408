import React from 'react';

type Props = {
  children: React.ReactNode;
  title: string;
};
export default function ToolTip({ children, title }: Props) {
  return (
    <div className="relative group">
      {children}
      <div className="absolute right-1/2 translate-x-1/2 translate-y-1.5 opacity-0 group-hover:opacity-100 group-hover:translate-y-0 -top-11 px-2.5 py-1 bg-[#333a43] rounded-md text-slate-50 duration-100 ease-out z-[30]">
        <span className="text-xs">{title}</span>
        <div className="absolute right-1/2 translate-x-1/2 -bottom-1 bg-[#333a43] w-2 h-2 rotate-45" />
      </div>
    </div>
  );
}
