type Props = {
  isSecond?: boolean;
  isActive: boolean;
};

export default function OrderArrow({ isSecond, isActive }: Props) {
  return (
    <svg
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`w-2 h-3 ${isSecond && 'rotate-180'}`}
    >
      <path
        className="duration-150"
        d="M3.5 1V11M3.5 1L6 3.22222M3.5 1L1 3.22222"
        stroke={isActive ? '#666666' : '#cacaca'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
