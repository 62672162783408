export default function RedoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none">
      <path
        d="M11.25 11.1211L15 7.37109L11.25 3.62109"
        className="stroke-[#363D59] group-disabled:stroke-[#363D59]/30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 15.6211V10.3711C3 8.71424 4.34315 7.37109 6 7.37109H15"
        className="stroke-[#363D59] group-disabled:stroke-[#363D59]/30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
