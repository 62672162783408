import { useTranslation } from 'react-i18next';
import StepCard from './step-card';

const stepArray = [
  {
    title: '제작 의뢰 접수',
    phrase: '사이트 문의 시 이메일 자동접수',
    iconPath: 'step-one.png',
  },
  {
    title: '제작 상담',
    phrase: '영상제작 전문가 직접 상담 진행',
    iconPath: 'step-two.png',
  },
  {
    title: '견적 제안 전달',
    phrase: '상담 결과 반영 견적 전달/계약',
    iconPath: 'step-three.png',
  },
  {
    title: '제작 및 납품',
    phrase: '납품 완료 확인 후 잔금 대금 전달',
    iconPath: 'step-four.png',
  },
];

type Props = {
  scrollToInquirySection: () => void;
};

export default function HowSection({ scrollToInquirySection }: Props) {
  const { t } = useTranslation();

  return (
    <section className="relative pt-16 sm:pt-20 md:pt-24 lg:pt-28 xl:pt-32 2xl:pt-40 pb-8 md:pb-24 text-center bg-black">
      <div className="absolute -bottom-24 w-full h-32 bg-gradient-to-b from-black to-black/0"></div>
      <h1 className="text-xl md:text-4xl font-bold">
        {t('영상 제작 어떻게 진행되나요?')}
      </h1>
      <p className="mt-2 md:mt-4 text-white/50 md:text-lg">
        {t('영상 제작 어떻게 진행되나요?')}
      </p>
      <ul className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4 md:gap-10 mt-12 md:mt-14 xl:mt-16 2xl:mt-[72px] sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-[1320px] duration-150 w-full mx-auto px-5">
        {stepArray.map(({ title, phrase, iconPath }, idx) => (
          <li
            className="sm:hover:-translate-y-2 duration-150 ease-in-out cursor-pointer group"
            key={`step-${idx}`}>
            <StepCard
              idx={idx + 1}
              title={title}
              phrase={phrase}
              iconPath={iconPath}
            />
          </li>
        ))}
      </ul>
      <button
        onClick={scrollToInquirySection}
        className="mt-6 md:mt-10 w-fit mx-auto bg-[#7663f6] hover:bg-opacity-80 py-3 px-6 2xl:py-4 2xl:px-8 font-semibold rounded-md 2xl:text-lg duration-150 ease-out">
        {t('영상제작문의')}
      </button>
    </section>
  );
}
