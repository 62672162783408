const array = [1, 2, 3, 4, 5, 6, 7, 8];

export default function TutorialSkeleton() {
  return (
    <ul className="grid grid-col-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-7 gap-y-10 mt-8 md:mt-10 lg:mt-14 xl:mt-16 2xl:mt-20">
      {array.map((_, idx) => (
        <li
          key={`tutorialSkeleton-${idx}`}
          className="h-[306px] md:h-80 xl:h-96 2xl:h-[400px] rounded shadow bg-white">
          <div className="h-[60%] p-5 lg:p-8 flex flex-col space-y-3">
            <div className="w-5/6 h-2 bg-gray-400 animate-pulse rounded-full" />
            <div className="w-full h-px bg-[#d8d8d8]" />
            <div className="flex flex-col space-y-2">
              <div className="w-full h-1.5 bg-gray-300 animate-pulse rounded-full" />
              <div className="w-5/6 h-1.5 bg-gray-300 animate-pulse rounded-full" />
              <div className="w-2/3 h-1.5 bg-gray-300 animate-pulse rounded-full" />
            </div>
          </div>
          <div className="h-[40%] bg-cover bg-center rounded-b-md w-full flex justify-center items-center bg-gray-200 animate-pulse">
            <svg
              className="w-10 h-10 text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18">
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
          </div>
        </li>
      ))}
    </ul>
  );
}
