type Props = {
  isFree: boolean;
};
export default function MetahumanBadge({ isFree }: Props) {
  return (
    <div className="absolute flex space-x-1 left-2 sm:left-3 top-2 sm:top-3">
      <div className="w-fit py-1 px-1.5 border border-primary bg-white text-primary rounded-[3px] text-[11px] font-medium leading-3">
        2D
      </div>
      <div
        className={`w-fit py-1 px-1.5 rounded-[3px] text-[11px] font-medium text-white leading-3 ${
          isFree ? 'bg-primary' : 'bg-rose-500'
        }`}
      >
        {isFree ? 'Free' : 'Pay'}
      </div>
    </div>
  );
}
