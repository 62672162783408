import { ResourceReqType } from '../types_new/main/service/types';
import APIService from './APIService';
import apiConfig from './apiConfig.json';
import apiMultipart from './apiMultipart';
import {
  IDelResourceReq,
  IGetAdminMetahumanListReq,
  IGetFAQListReq,
  IGetMetahumanListReq,
  IGetNoticeListReq,
  IGetPopupListReq,
  IGetResourceBGListReq,
  IGetResourceClipListReq,
  IGetResourceListReq,
  IGetShowcaseListReq,
  IGetTutorialListReq,
} from './dto/ISourceDto';

// 메타휴먼 목록 조회
const getMetahumanList = async (args: IGetMetahumanListReq) => {
  return await APIService.get(apiConfig.studio.source.getMetahumanList, {
    params: { ...args },
  });
};

// 리소스 목록 조회
const getResourceList = async (args: IGetResourceListReq) => {
  return await APIService.get(apiConfig.studio.source.getResourceList, {
    params: { ...args },
  });
};

// 리소스 추가
// const addResource_bak = async (args: FormData) => {
//   const { data } = await apiMultipart.post(
//     apiConfig.studio.source.addResource,
//     args
//   );

//   const { code } = data;
//   const { message } = data;
//   const filePath = data.body.file_path;

//   return { code, message, filePath };
// };

// 리소스 추가
const addResource = async (args: FormData) => {
  return await apiMultipart.post(apiConfig.studio.source.addResource, args);
};

// 리소스 삭제
const delResource = async (args: IDelResourceReq) => {
  return await APIService.delete(apiConfig.studio.source.delResource, {
    data: args,
  });
};

// 메타휴먼 목록 중 source 만 전달
const doGetMetahumanList = async (args: IGetMetahumanListReq) => {
  return getMetahumanList(args).then(res => {
    if (res.data.code === '200') {
      const posts = res.data.body.metahumans;
      return posts;
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// 리소스 목록 중 source 만 전달
const doGetResourceList = async (args: IGetResourceListReq) => {
  return getResourceList(args).then(res => {
    if (res.data.code === '200' || res.data.code === '500') {
      const sources = res.data.code === '200' ? res.data.body.resources : null;
      const { total } = res.data.body;
      return { sources, total };
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// 쇼케이스 목록 요청
const getShowcaseList = async (args: IGetShowcaseListReq) => {
  return await APIService.get(apiConfig.studio.source.getShowcases, {
    params: { ...args },
  });
};

// 쇼케이스 목록 요청
const doGetShowcaseList = async (args: ResourceReqType) => {
  const reqBody: IGetShowcaseListReq = {
    postType: 'showcase',
    ...args,
  };
  return getShowcaseList(reqBody).then(res => {
    if (res.data.code === '200') {
      const { posts } = res.data.body;
      const { total } = res.data.body;

      const filteredPosts = posts.sort((a, b) => b.postId - a.postId);

      return { filteredPosts, total };
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// 공지사항 목록 요청
const getNoticeList = async (args: IGetNoticeListReq) => {
  return await APIService.get(apiConfig.studio.source.getShowcases, {
    params: { ...args },
  });
};

// 공지사항 목록 요청
const doGetNoticeList = async (args: ResourceReqType) => {
  const reqBody: IGetNoticeListReq = {
    postType: 'notice',
    ...args,
  };
  return getNoticeList(reqBody).then(res => {
    if (res.data.code === '200') {
      const { posts } = res.data.body;
      const { total } = res.data.body;
      return { posts, total };
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// 튜터리얼 목록 요청
const getTutorialList = async (args: IGetTutorialListReq) => {
  return await APIService.get(apiConfig.studio.source.getShowcases, {
    params: { ...args },
  });
};

// 튜터리얼 목록 요청
const doGetTutorialList = async (args: ResourceReqType) => {
  const reqBody: IGetTutorialListReq = {
    postType: 'tutorial',
    ...args,
  };
  return getTutorialList(reqBody).then(res => {
    if (res.data.code === '200') {
      const { posts } = res.data.body;
      const { total } = res.data.body;
      return { posts, total };
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// FAQ 목록 요청
const getFAQList = async (args: IGetFAQListReq) => {
  return await APIService.get(apiConfig.studio.source.getShowcases, {
    params: { ...args },
  });
};

// FAQ 목록 요청
const doGetFAQList = async (args: ResourceReqType) => {
  const reqBody: IGetFAQListReq = {
    postType: 'faq',
    ...args,
  };
  return getFAQList(reqBody).then(res => {
    if (res.data.code === '200') {
      const { posts } = res.data.body;
      // const total = res.data.body.total;
      return posts;
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// 팝업 목록 요청
const getPopupList = async (args: IGetPopupListReq) => {
  return await APIService.get(apiConfig.studio.source.getShowcases, {
    params: { ...args },
  });
};

// 팝업 목록 요청
const doGetPopupList = async (args: ResourceReqType) => {
  const reqBody: IGetPopupListReq = {
    postType: 'popup',
    ...args,
  };
  return getPopupList(reqBody).then(res => {
    if (res.data.code === '200') {
      const { posts } = res.data.body;
      // const total = res.data.body.total;
      return posts;
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// 어드민 메타휴먼 목록 조회 -----------
const getAdminMetahumanList = async (args: IGetAdminMetahumanListReq) => {
  return await APIService.get(apiConfig.studio.source.getAdminMetahumanList, {
    params: { ...args },
  });
};
const doGetAdminMetahumaList = async (args: ResourceReqType) => {
  const reqBody: IGetAdminMetahumanListReq = {
    postType: 'metahuman_admin',
    ...args,
  };
  return getAdminMetahumanList(reqBody).then(res => {
    if (res.data.code === '200') {
      const { posts } = res.data.body;
      const { total } = res.data.body;
      return { posts, total };
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// 리소스 백그라운드 목록 조회 -----------
const getResourceBGList = async (args: IGetResourceBGListReq) => {
  return await APIService.get(apiConfig.studio.source.getResourceBGList, {
    params: { ...args },
  });
};
const doGetResouceBGList = async (args: ResourceReqType) => {
  const reqBody: IGetResourceBGListReq = {
    postType: 'resource_admin',
    group: 'background',
    ...args,
  };
  return getResourceBGList(reqBody).then(res => {
    if (res.data.code === '200') {
      const { posts } = res.data.body;
      const { total } = res.data.body;
      return { posts, total };
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// 리소스 꾸미기 목록 조회 -----------
const getResourceClipList = async (args: IGetResourceClipListReq) => {
  return await APIService.get(apiConfig.studio.source.getResourceClipList, {
    params: { ...args },
  });
};
const doGetResouceClipList = async (args: ResourceReqType) => {
  const reqBody: IGetResourceClipListReq = {
    postType: 'resource_admin',
    group: 'clip',
    ...args,
  };
  return getResourceClipList(reqBody).then(res => {
    if (res.data.code === '200') {
      const { posts } = res.data.body;
      const { total } = res.data.body;
      return { posts, total };
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

export {
  addResource,
  delResource,
  doGetAdminMetahumaList,
  doGetFAQList,
  doGetMetahumanList,
  doGetNoticeList,
  doGetPopupList, // 어드민 메타휴먼 목록 조회
  doGetResouceBGList, // 리소스 백그라운드 목록 조회
  doGetResouceClipList,
  doGetResourceList,
  doGetShowcaseList,
  doGetTutorialList,
  getMetahumanList,
  getResourceList
};
