import ExpandMoreIcon from '../../../svg/expand-more-icon';

export default function InquirySkeleton() {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className="flex flex-col space-y-1 md:space-y-2 xl:space-y-3 mt-5 sm:mt-7 lg:mt-10 xl:mt-12 2xl:mt-16">
      {arr.map((inquiry, idx) => (
        <div
          key={`inquirySkeleton-${inquiry}`}
          className="flex w-full justify-between rounded-t-sm px-5 py-5 2xl:px-10 2xl:py-7 bg-white"
        >
          <div className="flex items-center space-x-5 md:space-x-10 w-full">
            <div
              className={`${
                idx % 2 === 0 ? 'bg-primary/80' : 'bg-[#FF9B70]/80'
              } py-5 rounded-[10px] text-white font-medium text-sm min-w-[52px] sm:min-w-[66px] xl:w-[6%] animate-pulse`}
            />
            <div className="w-1/3 xl:w-1/3 h-2 xl:h-2.5 bg-gray-400 rounded-full animate-pulse" />
          </div>
          <ExpandMoreIcon />
        </div>
      ))}
    </div>
  );
}
