type Props = {
  isBig?: boolean;
};

export default function CloseIcon({ isBig }: Props) {
  return (
    <svg
      className={`${isBig ? 'w-5 h-5' : 'w-4 h-4'}`}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="8" fill="#F2F3F5" />
      <path
        d="M5.71387 5.71484L10.2853 10.2863M5.71387 10.2863L10.2853 5.71484L5.71387 10.2863Z"
        stroke="#ACAFC0"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
