import Lottie from 'react-lottie';
import animationData from './loader.json';

export default function Loader({
  height,
  width,
}: {
  height: number;
  width: number;
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <Lottie
      options={defaultOptions}
      height={height}
      width={width}
      isClickToPauseDisabled
    />
  );
}
