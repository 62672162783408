import { atom } from 'recoil';

import { IGetUserInfoData, IMemberLoginData } from '../dto/IMemberDto';
import { localStorageEffect, sessionStorageEffect } from './atomUtil';

const TOKEN = 'loginInfo';
const USERINFO = 'userInfo';

const isSignedAtom = atom({
  key: 'isSigned',
  default: false,
  effects: [localStorageEffect('isSigned')],
});

const loginInfoAtom = atom<IMemberLoginData>({
  key: 'loginInfo',
  default: {} as IMemberLoginData,
  effects: [localStorageEffect('loginInfo')],
});

const userInfoAtom = atom<IGetUserInfoData>({
  key: USERINFO,
  default: {} as IGetUserInfoData,
  effects: [sessionStorageEffect(USERINFO)],
});

/**
 * storage에 저장된 token을 가져온다.
 * axios api에서 사용할 수 있도록 제공
 * @returns
 */
const getStorageToken = () => {
  const storageStr = localStorage.getItem(TOKEN);
  if (!storageStr) {
    console.log('[store] localstorage userToken not found');
    return null;
  }

  const memberInfo: IMemberLoginData = JSON.parse(storageStr);

  return memberInfo?.userToken;
};

/**
 * storage에 저장된 token을 업데이트 한다.
 * axios api에서 사용할 수 있도록 제공
 * @returns
 */
const createStorageToken = (loginInfo: IMemberLoginData) => {
  localStorage.setItem(TOKEN, JSON.stringify(loginInfo));
  return true;
};

/**
 * storage에 저장된 token을 업데이트 한다.
 * axios api에서 사용할 수 있도록 제공
 * @returns
 */
const updateStorageToken = (userToken: string) => {
  const storageStr = localStorage.getItem(TOKEN);
  if (!storageStr) {
    console.log('[store] localstorage userToken not found');
    return false;
  }
  const memberInfo: IMemberLoginData = JSON.parse(storageStr);
  memberInfo.userToken = userToken;
  localStorage.setItem(TOKEN, JSON.stringify(memberInfo));
  return true;
};

/**
 * storage에 저장된 token을 업데이트 한다.
 * axios api에서 사용할 수 있도록 제공
 * @returns
 */
const removeStorageToken = () => {
  // const storageStr = localStorage.getItem(TOKEN);
  // if (!storageStr) {
  //   console.error('[store] localstorage userToken not found')
  //   return false;
  // }
  // let memberInfo:IMemberLoginData = JSON.parse(storageStr);
  // memberInfo.userToken = userToken;
  localStorage.removeItem(TOKEN);
  return true;
};

const removeStorageUserInfo = () => {
  sessionStorage.removeItem(USERINFO);
};

const getStorageUserEmail = () => {
  const storageStr = sessionStorage.getItem(USERINFO);
  if (!storageStr) {
    console.log('[store] localstorage userToken not found');
    return null;
  }
  const userInfo: IGetUserInfoData = JSON.parse(storageStr);
  return userInfo?.userEmail;
};

export {
  getStorageToken,
  getStorageUserEmail,
  isSignedAtom,
  loginInfoAtom,
  removeStorageToken,
  removeStorageUserInfo,
  updateStorageToken,
  userInfoAtom,
};
