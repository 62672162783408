/* eslint-disable */
import { useEffect, useRef } from 'react';
import { Image, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import useImage from 'use-image';
import '../../css/reset.css';
import '../../css/style.css';
import ClipMenu from './ClipMenu';

interface Itest {
  isDragging?: boolean;
  x?: number;
  y?: number;
}
interface IParamProps {
  children: React.ReactNode;
}

const Rectangle = ({
  shapeProps,
  isSelected,
  onSelect,
  onChange,
  image,
  setEventName,
  setSelectItem,
  metaSource,
  imageSource,
  videoSource,
  selectedItem,
  itemType,
  setItemId,
  setType,
  clipMenuInOut,
  metaPos,
  initXY,
}) => {
  const shapeRef = useRef<any>();
  const trRef: any = useRef();
  const [img] = useImage(image);

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        onMouseDown={() => {
          const item = shapeProps;
          onSelect();
          setSelectItem(item);
          setItemId(shapeProps.uniqueId);
          setType(
            itemType === 'image'
              ? 'image'
              : itemType === 'meta_image'
              ? 'metahuman'
              : 'video'
          );
        }}
        onClick={() => {
          onSelect();
          const item = shapeProps;
          setItemId(shapeProps.uniqueId);
          setSelectItem(item);
          setType(
            itemType === 'image'
              ? 'image'
              : itemType === 'meta_image'
              ? 'metahuman'
              : 'video'
          );
          const node: any = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            rotation: node.rotation(),
          });
        }}
        onTap={onSelect}
        ref={shapeRef}
        {...shapeProps}
        image={img}
        draggable
        onDragEnd={e => {
          onChange({
            ...shapeProps,
            x: e.target.x(),
            y: e.target.y(),
          });
        }}
        onTransformEnd={e => {
          const node: any = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: parseFloat(node.x()).toFixed(2),
            y: parseFloat(node.y()).toFixed(2),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
            rotation: node.rotation(),
          });
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={itemType !== 'image' ? false : false}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}

      <Html>
        {clipMenuInOut &&
          itemType === 'image' &&
          selectedItem?.uniqueId === shapeProps?.uniqueId &&
          selectedItem !== undefined &&
          imageSource !== undefined && (
            <div
              className="1"
              style={{
                position: 'absolute',
                zIndex: '100000000',
                top: `${shapeProps?.y}px`,
                left: `${shapeProps?.x}px`,
              }}>
              <ClipMenu setEventName={setEventName} />
            </div>
          )}
      </Html>
      <Html>
        {clipMenuInOut &&
        itemType === 'meta_image' &&
        selectedItem?.uniqueId === shapeProps?.uniqueId &&
        selectedItem !== undefined &&
        metaSource !== undefined ? (
          <div
            className="2"
            style={{
              position: 'absolute',
              zIndex: '100000000',
              top: `${(initXY.height * metaPos?.posY) / 100}px`,
              left: `${(initXY.width * metaPos?.posX) / 100}px`,
            }}>
            <ClipMenu setEventName={setEventName} />
          </div>
        ) : (
          <></>
        )}
      </Html>
      <Html>
        {clipMenuInOut &&
        itemType === 'video' &&
        selectedItem?.uniqueId === shapeProps?.uniqueId &&
        selectedItem !== undefined &&
        videoSource !== undefined ? (
          <div
            className="3"
            style={{
              position: 'absolute',
              // zIndex: '10',
              top: `${shapeProps?.y}px`,
              left: `${shapeProps?.x}px`,
            }}>
            <ClipMenu setEventName={setEventName} />
          </div>
        ) : (
          <></>
        )}
      </Html>
    </>
  );
};

export default Rectangle;
