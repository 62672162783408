import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';
import LinkArrowIcon from '../svg/link-arrow-icon';

export interface ButtonTypeProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  color?: 'primary' | 'black';
}

export default function Button({
  children,
  color = 'primary',
  ...props
}: ButtonTypeProps) {
  return (
    <button
      className={clsx(
        'flex items-center space-x-1.5 lg:space-x-3 2xl:space-x-6 border rounded-md px-3 2xl:px-5 py-3 2xl:py-4 text-sm 2xl:text-lg 2xl:font-medium duration-150 group',
        {
          'bg-primary hover:bg-primary/80 border-primary text-white':
            color === 'primary',
          'bg-transparent border-white hover:bg-white/20 text-white':
            color === 'black',
          '!bg-gradient-to-b !from-[#cccccc] !to-[#cccccc] text-white cursor-not-allowed':
            props.disabled,
        }
      )}
      {...props}>
      <span>{children}</span>
      <i className="max-sm:hidden group-hover:translate-x-0.5 group-hover:-translate-y-0.5 duration-150">
        <LinkArrowIcon />
      </i>
    </button>
  );
}
