import { AxiosResponse } from 'axios';
import { baseURL } from '../../services/defaultClient';

const koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
const isScriptKorean = (script: string) => {
  return !!koreanPattern.test(script);
};

const getAudioFilePath = (res: AxiosResponse<any, any>) => {
  const filePath = res.data.body.wavFilePath.substr(13);
  return `${baseURL}${filePath}`;
};

export type LanguageValueType =
  | 'en'
  | 'ko'
  | 'ja'
  | 'zh-cn'
  | 'es'
  | 'vi'
  | 'fr'
  | 'cs'
  | 'da'
  | 'de'
  | 'fil'
  | 'hi'
  | 'id'
  | 'it'
  | 'pt'
  | 'nl'
  | 'pl'
  | 'ru'
  | 'tr';
const getLanguageByValue = (value: LanguageValueType) => {
  switch (value) {
    case 'en':
      return '영어 (English)';
    case 'ko':
      return '한국어 (Korean)';
    case 'ja':
      return '일본어 (Japanese)';
    case 'zh-cn':
      return '중국어 (Chinese)';
    case 'es':
      return '스페인어 (Spanish)';
    case 'vi':
      return '베트남어 (Vietnamese)';
    case 'fr':
      return '프랑스어 (French)';
    case 'cs':
      return '체코어 (Czech)';
    case 'da':
      return '덴마크어 (Danish)';
    case 'de':
      return '독일어 (German)';
    case 'fil':
      return '필리핀어 (Phillippines)';
    case 'hi':
      return '힌디어 (Hindi)';
    case 'id':
      return '인도네시아어 (Indonesian)';
    case 'it':
      return '이탈리아어 (Italy)';
    case 'nl':
      return '네덜란드어 (Netherlands)';
    case 'pl':
      return '폴란드어 (Polish)';
    case 'pt':
      return '포르투갈어 (Portuguese)';
    case 'ru':
      return '러시아어 (Russian)';
    case 'tr':
      return '터키어 (Turkish)';
    default:
      return '';
  }
};

export { getAudioFilePath, getLanguageByValue, isScriptKorean };
