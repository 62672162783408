import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getMainDir } from '../../../util/common';

export default function CompleteView() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <section className="flex flex-col items-center justify-center mt-9">
      <img
        className="w-10 sm:w-12 animate-floatVertically"
        src={getMainDir('/img/rocket.png')}
        alt="rocket"
      />
      <div className="flex flex-col space-y-5 text-sm md:text-base text-center mt-10">
        <span>{t('플루닛 세상에 오신 것을 환영합니다!')}</span>
        <span className="whitespace-pre-wrap">
          {t(
            '플루닛 스튜디오 가입 축하 기념!\n매월 무료 혜택(엔트리 기준)을 드려요!'
          )}
        </span>
        <span className="whitespace-pre-wrap">
          {t('당신에게 딱! 맞는 플루닛 스튜디오\n월 요금제를 찾아보세요!')}
        </span>
      </div>
      <div className="w-full flex space-x-4 justify-between mt-10">
        <button
          onClick={() => navigate('/')}
          className="w-full py-4 border border-primary font-medium text-sm text-primary rounded-md hover:bg-primary/30 duration-150">
          {t('무료로 계속 사용하기')}
        </button>
        <button
          onClick={() => navigate('/membership')}
          className="w-full flex justify-center py-4 font-medium text-sm text-white rounded-md bg-primary hover:bg-primary/70 duration-150">
          {t('월 요금제 알아보기')}
        </button>
      </div>
    </section>
  );
}
