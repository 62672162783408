export default function LeaveIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.25 2.25H1.75V14.25H5.25M10.75 4.75L14.25 8.25M14.25 8.25L10.75 11.75M14.25 8.25H10H5.75"
        stroke="#1A2032"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
