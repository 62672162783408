import React from 'react';
import { useTranslation } from 'react-i18next';
import { supportCategoryArray } from '../../../arrays';

type Props = {
  setCategory: React.Dispatch<React.SetStateAction<string>>;
  setPaged: React.Dispatch<React.SetStateAction<number>>;
  category: string;
};

export default function CategoryList({
  setCategory,
  category,
  setPaged,
}: Props) {
  const { t } = useTranslation();

  const onChangeCategory = (value: string) => {
    setCategory(value);
    setPaged(1);
  };

  return (
    <ul className="flex max-sm:justify-between sm:space-x-10 text-black mt-6 sm:mt-8 lg:mt-12 xl:mt-16">
      {supportCategoryArray.map(({ label, value }, idx) => (
        <li
          key={`category-${idx}`}
          onClick={() => onChangeCategory(value)}
          className={`text-[13px] sm:text-sm md:text-base 2xl:text-lg
           ${
             category === value
               ? 'font-bold cursor-default text-black border-b md:border-b-2 border-black'
               : 'cursor-pointer text-[#808080] font-medium hover:text-black/90 duration-150'
           } 
          `}>
          {t(label)}
        </li>
      ))}
    </ul>
  );
}
