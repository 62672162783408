import CoinIcon from '../../svg/coin-icon';

export default function ItemSkeleton() {
  const arr = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <ul className="grid grid-cols-2 md:grid-cols-4 gap-y-10 gap-x-3 lg:gap-x-5 xl:gap-y-12 xl:gap-x-7 mt-5 md:mt-6 xl:mt-8">
      {arr.map((_, idx) => (
        <li key={`metahumanSkeleton-${idx}`}>
          <div className="h-52 sm:h-56 lg:h-60 2xl:h-64">
            <div className="w-full h-1/2 sm:h-3/4 rounded-t-[10px] bg-gray-600 animate-pulse flex justify-center items-center">
              <svg
                className="w-10 h-10 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 18"
              >
                <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
              </svg>
            </div>
            <div className="flex max-lg:flex-col justify-between items-start lg:items-center bg-gray-200 p-4 rounded-b-[10px] text-black">
              <div className="h-1.5 w-1/3 rounded-full bg-gray-500/70 animate-pulse max-lg:mb-1" />
              <div className="max-lg:w-full flex max-sm:flex-col max-sm:space-y-2 max-sm:items-start max-lg:justify-between items-center sm:space-x-1.5">
                <div className="flex items-center space-x-1">
                  <CoinIcon />
                  <div className="bg-[#9379ff]/60 h-1.5 w-12 rounded-full animate-pulse" />
                </div>
                <div className="max-sm:w-full bg-black text-white text-xs leading-6 py-0.5 px-4 rounded-md text-center">
                  사용
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}
