import { memo, useCallback, useState } from 'react';
import { Rnd } from 'react-rnd';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { block } from '../../store/block';
import {
  additionalTarget,
  setSegmentsInfo,
  pages as sotredPages,
  ratio as storedRatio,
  segmentsItem as storedSegmentsItem,
} from '../../store/pages';
import { IPreviewElement } from '../../store/pages/types';
import { AudioItem } from './blockRows/AudioItem';
import { BackgroundColorImageItem } from './blockRows/BackgroundColorImageItem';
import { BackgroundMusic } from './blockRows/BackgroundMusic';
import { BackgroundVideo } from './blockRows/BackgroundVideo';
import { ImageItem } from './blockRows/ImageItem';
import { MetaItem } from './blockRows/MetaItem';
import { TitleItem } from './blockRows/TitleItem';
import { VideoItem } from './blockRows/VideoItem';

export type MediaProps = {
  item: IPreviewElement;
  updateItem: (
    id: number,
    dx: number,
    dw: number,
    direction?: string,
    dy?: number
  ) => void;
  railHeight: number;
  zIndex: number;
};

function Media({ item, updateItem, railHeight, zIndex }: MediaProps) {
  const [ratio] = useRecoilState(storedRatio);
  const [pages, setPages] = useRecoilState(sotredPages);

  const [segmentsItem, setSegmentsItem] = useRecoilState(storedSegmentsItem);

  const csToPixel = (cs: number) => cs / ratio; // convert ms to pixel
  const setAddTarget = useSetRecoilState(additionalTarget);
  const width = csToPixel(item.duration) || 0;
  const x = csToPixel(item.startTimestamp) || 0;
  const y = item.railIndex * railHeight - 1;
  if (!ratio) return null;
  const [changeSoundeRange, setChangeSoundeRange] = useState(false);
  const selectedBlock = useRecoilValue(block);

  const handleVolume = (item: any, volume: number) => {
    if (['video', 'audio', 'bgaudio'].includes(item.type)) {
      const element = {
        ...pages[0].segments[0].segment.find(seg => seg.segmentId === item.id),
      };
      const index = [...pages[0]?.segments[0]?.segment].findIndex(
        e => e.segmentId === item.id
      );
      element.volume = Number((Number(volume) / 50).toFixed(2));
      const type = {
        pagesIndex: 0,
        segment: {
          segmentIndex: index,
          segment: element,
        },
      };
      setSegmentsInfo(pages, setPages, type);
    } else if (['bgVideo'].includes(item.type)) {
      const element = {
        ...pages[0].segments[0].background.find(
          seg => seg.backgroundId === item.id
        ),
      };
      const index = [...pages[0]?.segments[0]?.background].findIndex(
        e => e.backgroundId === item.id
      );
      element.volume = Number((Number(volume) / 50).toFixed(2));
      const type = {
        pagesIndex: 0,
        background: {
          backgroundIndex: index,
          background: element,
        },
      };
      setSegmentsInfo(pages, setPages, type);
    }
  };

  const handleFade = (item: any, e: any) => {
    if (['video', 'bgaudio', 'audio'].includes(item.type)) {
      const element = {
        ...pages[0].segments[0].segment.find(seg => seg.segmentId === item.id),
      };
      const index = [...pages[0]?.segments[0]?.segment].findIndex(
        e => e.segmentId === item.id
      );
      if (e.target.value === 'fadeIn') {
        if (element) {
          element.fadeIn = e.currentTarget.checked ? 100 : 0;
        }
      }
      if (e.target.value === 'fadeOut') {
        if (element) {
          element.fadeOut = e.currentTarget.checked ? 100 : 0;
        }
      }
      const type = {
        pagesIndex: 0,
        segment: {
          segmentIndex: index,
          segment: element,
        },
      };
      setSegmentsInfo(pages, setPages, type);
    } else {
      const element = {
        ...pages[0].segments[0].background.find(
          seg => seg.backgroundId === item.id
        ),
      };
      const index = [...pages[0]?.segments[0]?.background].findIndex(
        element => element.backgroundId === item.id
      );

      if (e.target.value === 'fadeIn') {
        element.fadeIn = e.currentTarget.checked ? 100 : 0;
      }
      if (e.target.value === 'fadeOut') {
        element.fadeOut = e.currentTarget.checked ? 100 : 0;
      }
      const type = {
        pagesIndex: 0,
        background: {
          backgroundIndex: index,
          background: element,
        },
      };
      setSegmentsInfo(pages, setPages, type);
    }
  };

  const convertPixelToCs = useCallback(
    (pixel: number) => pixel * ratio,
    [ratio]
  );

  const stickToAnother = (_, data) => {
    const xPos = Math.floor(x);
    const yPos = Math.floor(y);
    const updatedXPos = Math.floor(data.x);
    const updatedYPos = Math.floor(data.y);

    if (
      !selectedBlock ||
      updatedXPos === xPos ||
      updatedYPos === yPos ||
      pages[0] === undefined ||
      pages[0].segments[0] === undefined
    ) {
      return;
    }

    const selectedInsertTime = convertPixelToCs(updatedXPos);
    const tmpPage = JSON.parse(JSON.stringify(pages));
    const { segmentsGroup: group, groupIndex: index } = selectedBlock;

    let segmentsGroup;
    switch (group) {
      case 'segment':
        segmentsGroup = tmpPage[0]?.segments[0]?.segment;
        break;
      case 'image':
        segmentsGroup = tmpPage[0]?.segments[0]?.image;
        break;
      case 'background':
        segmentsGroup = tmpPage[0]?.segments[0]?.background;
        break;
      case 'title':
        segmentsGroup = tmpPage[0]?.segments[0]?.title;
        break;
      default:
        return null;
    }

    const selectedEndTime = selectedInsertTime + segmentsGroup[index]?.duration;

    const rightBlockIndex = segmentsGroup.findIndex(
      seg =>
        (selectedEndTime > seg.insertTime - 50 &&
          selectedInsertTime < seg.insertTime + seg.duration + 50 &&
          segmentsGroup[index]?.imageId !== seg.imageId &&
          seg?.insertTime > segmentsGroup[index]?.insertTime &&
          seg?.railIndex === segmentsGroup[index]?.railIndex) ||
        (selectedEndTime > seg.insertTime - 50 &&
          selectedInsertTime < seg.insertTime + seg.duration + 50 &&
          segmentsGroup[index]?.segmentId !== seg.segmentId &&
          seg?.insertTime > segmentsGroup[index]?.insertTime &&
          seg?.railIndex === segmentsGroup[index]?.railIndex) ||
        (selectedEndTime > seg.insertTime - 50 &&
          selectedInsertTime < seg.insertTime + seg.duration + 50 &&
          segmentsGroup[index]?.backgroundId !== seg.backgroundId &&
          seg?.insertTime > segmentsGroup[index]?.insertTime &&
          seg?.railIndex === segmentsGroup[index]?.railIndex) ||
        (selectedEndTime > seg.insertTime - 50 &&
          selectedInsertTime < seg.insertTime + seg.duration + 50 &&
          segmentsGroup[index]?.titleId !== seg.titleId &&
          seg?.insertTime > segmentsGroup[index]?.insertTime &&
          seg?.railIndex === segmentsGroup[index]?.railIndex)
    );
    const leftBlockIndex = segmentsGroup.findIndex(
      seg =>
        (selectedInsertTime < seg?.insertTime + seg?.duration + 50 &&
          selectedEndTime > seg?.insertTime - 50 &&
          segmentsGroup[index]?.imageId !== seg?.imageId &&
          seg?.insertTime < segmentsGroup[index]?.insertTime &&
          seg?.railIndex === segmentsGroup[index]?.railIndex) ||
        (selectedInsertTime < seg?.insertTime + seg?.duration + 50 &&
          selectedEndTime > seg?.insertTime - 50 &&
          segmentsGroup[index]?.segmentId !== seg?.segmentId &&
          seg?.insertTime < segmentsGroup[index]?.insertTime &&
          seg?.railIndex === segmentsGroup[index]?.railIndex) ||
        (selectedInsertTime < seg?.insertTime + seg?.duration + 50 &&
          selectedEndTime > seg?.insertTime - 50 &&
          segmentsGroup[index]?.backgroundId !== seg?.backgroundId &&
          seg?.insertTime < segmentsGroup[index]?.insertTime &&
          seg?.railIndex === segmentsGroup[index]?.railIndex) ||
        (selectedInsertTime < seg?.insertTime + seg?.duration + 50 &&
          selectedEndTime > seg?.insertTime - 50 &&
          segmentsGroup[index]?.titleId !== seg?.titleId &&
          seg?.insertTime < segmentsGroup[index]?.insertTime &&
          seg?.railIndex === segmentsGroup[index]?.railIndex)
    );

    if (rightBlockIndex === -1 && leftBlockIndex === -1) {
      updateItem(item.id, updatedXPos - xPos, 0, '', updatedYPos);
    }
    if (
      (segmentsGroup[index]?.railIndex ===
        segmentsGroup[leftBlockIndex]?.railIndex &&
        selectedInsertTime >
          segmentsGroup[leftBlockIndex]?.insertTime +
            segmentsGroup[leftBlockIndex]?.duration / 2 &&
        segmentsGroup[index]?.railIndex ===
          segmentsGroup[leftBlockIndex]?.railIndex &&
        selectedInsertTime <
          segmentsGroup[leftBlockIndex]?.insertTime +
            segmentsGroup[leftBlockIndex]?.duration +
            50) ||
      // if there's not enough space
      (segmentsGroup[index]?.railIndex ===
        segmentsGroup[leftBlockIndex]?.railIndex &&
        segmentsGroup[leftBlockIndex]?.insertTime - 0 <
          segmentsGroup[index]?.duration)
    ) {
      // moved left LESS than half
      segmentsGroup[index] = {
        ...segmentsGroup[index],
        insertTime:
          segmentsGroup[leftBlockIndex]?.insertTime +
          segmentsGroup[leftBlockIndex]?.duration,
        endTime:
          segmentsGroup[leftBlockIndex]?.insertTime +
          segmentsGroup[leftBlockIndex]?.duration +
          segmentsGroup[index].duration,
      };
      if (
        segmentsGroup[index]?.railIndex ===
          segmentsGroup[leftBlockIndex + 1]?.railIndex &&
        segmentsGroup[index]?.insertTime !==
          segmentsGroup[leftBlockIndex + 1]?.insertTime &&
        segmentsGroup[leftBlockIndex + 1]?.insertTime -
          (segmentsGroup[leftBlockIndex]?.insertTime +
            segmentsGroup[leftBlockIndex]?.duration) <
          segmentsGroup[index]?.duration
      ) {
        return;
      }

      switch (group) {
        case 'segment':
          tmpPage[0].segments[0].segment = segmentsGroup;
          break;
        case 'image':
          tmpPage[0].segments[0].image = segmentsGroup;
          break;
        case 'background':
          tmpPage[0].segments[0].background = segmentsGroup;
          break;
        case 'title':
          tmpPage[0].segments[0].title = segmentsGroup;
          break;
        default:
          return null;
      }
      setPages(tmpPage);
    } else if (
      segmentsGroup[index]?.railIndex ===
        segmentsGroup[leftBlockIndex]?.railIndex &&
      selectedInsertTime <
        segmentsGroup[leftBlockIndex]?.insertTime +
          segmentsGroup[leftBlockIndex]?.duration / 2 &&
      selectedEndTime > segmentsGroup[leftBlockIndex]?.insertTime - 50
    ) {
      // moved left MORE than half
      segmentsGroup[index] = {
        ...segmentsGroup[index],
        insertTime:
          segmentsGroup[leftBlockIndex]?.insertTime -
          segmentsGroup[index]?.duration,
        endTime:
          segmentsGroup[leftBlockIndex]?.insertTime +
          segmentsGroup[leftBlockIndex]?.duration,
      };

      if (
        segmentsGroup[leftBlockIndex]?.railIndex ===
          segmentsGroup[leftBlockIndex - 1]?.railIndex &&
        segmentsGroup[leftBlockIndex]?.insertTime -
          (segmentsGroup[leftBlockIndex - 1]?.insertTime +
            segmentsGroup[leftBlockIndex - 1]?.duration) <
          segmentsGroup[index]?.duration
      ) {
        return;
      }

      switch (group) {
        case 'segment':
          tmpPage[0].segments[0].segment = segmentsGroup;
          break;
        case 'image':
          tmpPage[0].segments[0].image = segmentsGroup;
          break;
        case 'background':
          tmpPage[0].segments[0].background = segmentsGroup;
          break;
        case 'title':
          tmpPage[0].segments[0].title = segmentsGroup;
          break;
        default:
          return null;
      }
      setPages(tmpPage);
    } else if (
      segmentsGroup[index]?.railIndex ===
        segmentsGroup[rightBlockIndex]?.railIndex &&
      selectedEndTime > segmentsGroup[rightBlockIndex]?.insertTime - 50 &&
      selectedEndTime <
        segmentsGroup[rightBlockIndex]?.insertTime +
          segmentsGroup[rightBlockIndex]?.duration / 2
    ) {
      // moved right and LESS than half
      segmentsGroup[index] = {
        ...segmentsGroup[index],
        insertTime:
          segmentsGroup[rightBlockIndex]?.insertTime -
          segmentsGroup[index]?.duration,
        endTime: segmentsGroup[rightBlockIndex]?.insertTime,
      };

      switch (group) {
        case 'segment':
          tmpPage[0].segments[0].segment = segmentsGroup;
          break;
        case 'image':
          tmpPage[0].segments[0].image = segmentsGroup;
          break;
        case 'background':
          tmpPage[0].segments[0].background = segmentsGroup;
          break;
        case 'title':
          tmpPage[0].segments[0].title = segmentsGroup;
          break;
        default:
          return null;
      }
      setPages(tmpPage);
    } else if (
      segmentsGroup[index]?.railIndex ===
        segmentsGroup[rightBlockIndex]?.railIndex &&
      selectedEndTime >
        segmentsGroup[rightBlockIndex]?.insertTime +
          segmentsGroup[rightBlockIndex]?.duration / 2 &&
      selectedInsertTime <
        segmentsGroup[rightBlockIndex]?.insertTime +
          segmentsGroup[rightBlockIndex]?.duration +
          50
    ) {
      // moved right MORE than half
      segmentsGroup[index] = {
        ...segmentsGroup[index],
        insertTime:
          segmentsGroup[rightBlockIndex]?.insertTime +
          segmentsGroup[rightBlockIndex]?.duration,
        endTime:
          segmentsGroup[rightBlockIndex]?.insertTime +
          segmentsGroup[index].duration,
      };
      if (
        segmentsGroup[index]?.railIndex ===
          segmentsGroup[rightBlockIndex + 1]?.railIndex &&
        segmentsGroup[rightBlockIndex + 1]?.insertTime -
          (segmentsGroup[rightBlockIndex]?.insertTime +
            segmentsGroup[rightBlockIndex]?.duration) <
          segmentsGroup[index]?.duration
      ) {
        return;
      }

      switch (group) {
        case 'segment':
          tmpPage[0].segments[0].segment = segmentsGroup;
          break;
        case 'image':
          tmpPage[0].segments[0].image = segmentsGroup;
          break;
        case 'background':
          tmpPage[0].segments[0].background = segmentsGroup;
          break;
        case 'title':
          tmpPage[0].segments[0].title = segmentsGroup;
          break;
        default:
          return null;
      }
      setPages(tmpPage);
    } else if (updatedXPos < 1) {
      segmentsGroup[index] = {
        ...segmentsGroup[index],
        insertTime: 0,
        endTime: 0 + segmentsGroup[index].duration,
      };

      switch (group) {
        case 'segment':
          tmpPage[0].segments[0].segment = segmentsGroup;
          break;
        case 'image':
          tmpPage[0].segments[0].image = segmentsGroup;
          break;
        case 'background':
          tmpPage[0].segments[0].background = segmentsGroup;
          break;
        case 'title':
          tmpPage[0].segments[0].title = segmentsGroup;
          break;
        default:
          return null;
      }
      setPages(tmpPage);
    } else {
      updateItem(item.id, updatedXPos - xPos, 0, '', updatedYPos);
    }
  };

  return (
    <Rnd
      bounds="parent"
      default={{
        width,
        height: 44,
        x,
        y,
      }}
      position={{ x, y }}
      onDragStop={(_, data) => stickToAnother(_, data)}
      disableDragging={changeSoundeRange}
      onResizeStop={(_e, dir, _elementRef, delta) =>
        updateItem(item.id, 0, delta.width, dir)
      }
      dragAxis="both"
      enableResizing={{
        right: item.type !== 'metahuman',
      }}
      onMouseDown={() => {
        console.log(item.type);
        setSegmentsItem({ id: item.id, type: item.type });
      }}
      style={{
        zIndex: `${zIndex}`,
        width,
      }}>
      {item.type === 'title' ? (
        <TitleItem item={item} />
      ) : item.type === 'image' ? (
        <ImageItem item={item} />
      ) : item.type === 'metahuman' ? (
        <MetaItem item={item} segmentsItem={segmentsItem} />
      ) : item.type === 'video' ? (
        <VideoItem
          item={item}
          setChangeSoundeRange={setChangeSoundeRange}
          handleVolume={handleVolume}
          handleFade={handleFade}
        />
      ) : item.type === 'audio' ? (
        <AudioItem
          item={item}
          setChangeSoundeRange={setChangeSoundeRange}
          handleVolume={handleVolume}
          handleFade={handleFade}
        />
      ) : item.type === 'bgColorImage' ? (
        <BackgroundColorImageItem item={item} />
      ) : item.type === 'bgVideo' ? (
        <>
          <BackgroundVideo
            item={item}
            setChangeSoundeRange={setChangeSoundeRange}
            handleVolume={handleVolume}
            handleFade={handleFade}
          />
        </>
      ) : (
        <BackgroundMusic
          item={item}
          setChangeSoundeRange={setChangeSoundeRange}
          handleVolume={handleVolume}
          handleFade={handleFade}
        />
      )}
    </Rnd>
  );
}

export default memo(Media);
