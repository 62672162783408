import { Disclosure } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import { supportCategoryArray } from '../../../arrays';
import { lang } from '../../../recoil/atoms/user';
import { PostType } from '../../../types_new/main/resource/types';
import { findValueByKey } from '../../../utils/common';
import ExpandMoreIcon from '../../svg/expand-more-icon';

type Props = {
  faq: PostType;
};

export default function FAQCard({ faq }: Props) {
  const language = useRecoilValue(lang);

  const displayCategory = () => {
    const category = findValueByKey(faq.metaData, 'category');
    return language === 'ko'
      ? supportCategoryArray.find(item => item.value === category)?.label
      : category;
  };

  return (
    <div className="w-full rounded-b-sm bg-white">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full justify-between rounded-t-sm px-5 py-5 2xl:px-10 2xl:py-7">
              <div className="flex items-center w-full">
                <span className="text-primary font-medium text-xs md:text-sm min-w-[52px] sm:min-w-[66px] xl:w-[6%] text-left">
                  {displayCategory()}
                </span>
                <span className="whitespace-nowrap px-10 text-darkSecondary text-xs sm:text-sm lg:text-base 2xl:text-lg font-semibold">
                  {faq.postTitle}
                </span>
              </div>
              <ExpandMoreIcon open={open} />
            </Disclosure.Button>
            <Disclosure.Panel className="w-full flex px-5 2xl:px-10 py-6 lg:py-8 bg-[#f6f6f6]">
              <div className="max-sm:hidden min-w-[52px] sm:min-w-[66px] xl:w-[6%]" />
              <div
                className="text-darkSecondary w-full ml-10 text-xs sm:text-sm md:text-base prose sm:prose-sm md:prose-base max-w-none no-heading"
                dangerouslySetInnerHTML={{ __html: faq.postContent }}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
