/* eslint-disable */
import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import '../../css/reset.css';
import '../../css/style.css';
import { PROJECT_INFO } from '../../store/common/projectInfo';
import {
  centiSecond,
  cutBgAudio as sotredCutBgAudio,
  cutBgVideo as sotredCutBgVideo,
  cutAudioItem as storedCutAudioItem,
  cutBgColorImage as storedCutBgColorImage,
  cutImage as storedCutImageItem,
  cutTitle as storedCutTitleItem,
  cutvideoItem as storedCutVideoItem,
  pages as storedPages,
} from '../../store/pages';
import {
  IBackground,
  IImage,
  IPages,
  ISegment,
  ITitle,
} from '../../store/pages/types';
import BackgroundLayer from './BackgroundLayer';
import ImageLayer from './ImageLayer';

const isIn = (elem: { insertTime?: number; duration?: number }, cs: number) => {
  return elem.insertTime <= cs && elem.insertTime + elem.duration > cs;
};

export default function Preview() {
  const [cs] = useRecoilState(centiSecond);
  const setCutAudioItem = useSetRecoilState(storedCutAudioItem);
  const setCutVideoItem = useSetRecoilState(storedCutVideoItem);
  const setCutBgVideoItem = useSetRecoilState(sotredCutBgVideo);
  const setCutBgAudioItem = useSetRecoilState(sotredCutBgAudio);
  const setCutTitleItem = useSetRecoilState(storedCutTitleItem);
  const setCutImageItem = useSetRecoilState(storedCutImageItem);
  const setCutBgColorImageItem = useSetRecoilState(storedCutBgColorImage);
  const [pages] = useRecoilState<IPages[]>(storedPages);
  const segments = pages[0]?.segments[0]?.segment as ISegment[];
  const images = pages[0]?.segments[0]?.image as IImage[];
  const titles = pages[0]?.segments[0]?.title as ITitle[];
  const bgs = pages[0]?.segments[0]?.background as IBackground[];

  const catchedMetaSources = segments?.filter(
    seg => isIn(seg, cs) && seg.mediaType === 'metahuman'
  );
  const catchedImageSources = images?.filter(img => isIn(img, cs));
  const catchedTextSources = titles?.filter(title => isIn(title, cs));
  const catchedVideoSources = segments?.filter(
    video => isIn(video, cs) && video.mediaType === 'video'
  );
  const catchedBgSources = bgs?.filter(
    bg =>
      isIn(bg, cs) &&
      (bg.type === 'color' || bg.type === 'image' || bg.type === 'video')
  );
  const backgroundSource: IBackground = catchedBgSources
    ? catchedBgSources[catchedBgSources.length - 1]
    : undefined;

  useEffect(() => {
    const catchedAudioSources = segments?.filter(
      seg => isIn(seg, cs) && seg.mediaType === 'audio'
    );
    const catchedVideoSources = segments?.filter(
      seg => isIn(seg, cs) && seg.mediaType === 'video'
    );
    const catchedBgAudioSources = segments?.filter(
      seg => isIn(seg, cs) && seg.mediaType === 'bgaudio'
    );
    const catchedBgVideoSources = bgs?.filter(
      bgVideo => isIn(bgVideo, cs) && bgVideo.type === 'video'
    );
    const catchedBgColorImageSources = bgs?.filter(
      bgVideo =>
        isIn(bgVideo, cs) &&
        (bgVideo.type === 'image' || bgVideo.type === 'color')
    );
    const catchedTitleSources = titles?.filter(title => isIn(title, cs));
    const catchedImageSources = images?.filter(image => isIn(image, cs));

    setCutAudioItem(catchedAudioSources);
    setCutVideoItem(catchedVideoSources);
    setCutBgAudioItem(catchedBgAudioSources);
    setCutBgVideoItem(catchedBgVideoSources);
    setCutTitleItem(catchedTitleSources);
    setCutImageItem(catchedImageSources);
    setCutBgColorImageItem(catchedBgColorImageSources);
  }, [cs, pages]);

  const projectInfo = useRecoilValue(PROJECT_INFO);
  const canvasWidthRate = Number(projectInfo?.canvasSize?.split(':')[0]);
  const canvasHeightRate = Number(projectInfo?.canvasSize?.split(':')[1]);
  const canvasWidth = (576 / canvasHeightRate) * canvasWidthRate;

  return (
    <div className="studio__canvas h-[60vh]">
      <div
        className="canvas1"
        style={{
          width: `${canvasWidth}px`,
          height: '576px',
        }}>
        <BackgroundLayer
          backgroundSource={backgroundSource}
          canvasWidth={canvasWidth}
        />
        <ImageLayer
          metaSource={catchedMetaSources}
          imageSource={catchedImageSources}
          textSource={catchedTextSources}
          videoSource={catchedVideoSources}
          canvasWidth={canvasWidth}
        />
      </div>
    </div>
  );
}
