import { ProjectInfoType } from '../../../types_new/console/resource/types';

type Props = {
  status: ProjectInfoType['status'];
  producing: ProjectInfoType['producing'];
};

export default function StatusBadge({ status, producing }: Props) {
  const colorMap: any = {
    pending: {
      proceed: '#ff5c00',
      fail: '#dc3545',
      '': '#02A3D3',
    },
    publish: {
      complete: '#7b61ff',
    },
  };

  return (
    <div
      style={{ backgroundColor: `${colorMap[status][producing]}` }}
      className={`w-2.5 h-2.5 rounded-full -translate-y-px ${
        producing === 'proceed' && 'animate-pulse'
      }`}
    />
  );
}
