export default function PlusIcon() {
  return (
    <svg
      className="w-2.5 h-2.5"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 10.4987V6.00035M5.5 6.00035V1.50195M5.5 6.00035H10M5.5 6.00035H1"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
      />
    </svg>
  );
}
