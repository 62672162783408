export default function PencilIcon() {
  return (
    <svg
      className="w-3.5 h-3.5"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.77758 9.36136L9.91583 2.22253C10.1978 1.95265 10.5743 1.80393 10.9646 1.80819C11.3549 1.81246 11.7281 1.96938 12.0041 2.24535C12.2801 2.52132 12.4372 2.8944 12.4415 3.28471C12.4459 3.67502 12.2973 4.05152 12.0275 4.33361L4.88808 11.4724C4.72522 11.6353 4.51778 11.7463 4.29192 11.7915L2 12.25L2.4585 9.95752C2.50368 9.73166 2.6147 9.52422 2.77758 9.36136V9.36136Z"
        className="stroke-[#666666] group-hover:stroke-primary"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.45801 3.79175L10.208 5.54175"
        className="stroke-[#666666] group-hover:stroke-primary"
      />
    </svg>
  );
}
