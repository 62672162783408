export default function PlaneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none">
      <path
        d="M14.8049 1.63688C14.7274 1.56999 14.6332 1.5255 14.5323 1.50821C14.4314 1.49092 14.3277 1.50148 14.2324 1.53876L1.56616 6.49563C1.38665 6.56543 1.23468 6.69166 1.13312 6.85531C1.03155 7.01896 0.985903 7.21118 1.00304 7.40302C1.02017 7.59486 1.09916 7.77594 1.22811 7.919C1.35706 8.06207 1.529 8.15937 1.71804 8.19626L4.99991 8.84063V12.5C4.99927 12.6993 5.0585 12.8943 5.16994 13.0595C5.28138 13.2248 5.43988 13.3528 5.62491 13.4269C5.80966 13.5023 6.01283 13.5204 6.208 13.4789C6.40318 13.4373 6.58133 13.3379 6.71929 13.1938L8.30179 11.5525L10.8124 13.75C10.9935 13.9107 11.2272 13.9996 11.4693 14C11.5754 13.9999 11.6808 13.9833 11.7818 13.9506C11.9468 13.8983 12.0951 13.8036 12.2121 13.6761C12.3291 13.5486 12.4107 13.3926 12.4487 13.2238L14.9855 2.18751C15.0082 2.08798 15.0034 1.98414 14.9716 1.88715C14.9398 1.79015 14.8822 1.70363 14.8049 1.63688ZM10.9837 3.88688L5.38429 7.89688L2.28429 7.28876L10.9837 3.88688ZM5.99991 12.5V9.53251L7.54929 10.8913L5.99991 12.5ZM11.4705 13L6.30304 8.46876L13.7405 3.13813L11.4705 13Z"
        fill="#666666"
      />
    </svg>
  );
}
