import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { linkArray } from '../../arrays';
import {
  isSignedAtom,
  removeStorageToken,
  removeStorageUserInfo,
} from '../../networks/store/member';
import { getMainDir } from '../../util/common';

type Props = {
  isOpen: boolean;
  closeMenu: () => void;
};

export default function MobileMenu({ isOpen, closeMenu }: Props) {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isSignedAtom);
  const navigate = useNavigate();

  const handleClick = () => {
    closeMenu();
    if (isLoggedIn) {
      logOut();
    } else {
      navigate('/login');
    }
  };

  const logOut = () => {
    removeStorageToken();
    removeStorageUserInfo();
    setIsLoggedIn(false);
    navigate('/');
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeMenu}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 backdrop-blur bg-gradient-to-b from-transparent to-darkSecondary" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex w-full h-full items-center justify-center text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-200"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="relative w-full h-full transform overflow-hidden text-left align-middle transition-all">
                <button onClick={closeMenu} className="absolute top-5 right-5">
                  <CloseIcon />
                </button>
                <nav className="absolute top-16 left-12">
                  <ul className="flex flex-col space-y-7">
                    {linkArray.map(({ link, name }, idx) => (
                      <Link key={name} to={link}>
                        <li
                          onClick={closeMenu}
                          className="text-white/50 font-medium">
                          {`0${idx + 1}`}
                          <span className="text-white font-bold text-[22px] pl-2 tracking-[4px]">
                            {name}
                          </span>
                        </li>
                      </Link>
                    ))}
                  </ul>
                </nav>
                <div className="absolute bottom-5 px-5 flex flex-col space-y-4 w-full">
                  <Link to={isLoggedIn ? '/my-page/my-info' : '/signup'}>
                    <button
                      onClick={closeMenu}
                      className="w-full text-xl py-[14px] text-white bg-gradient-to-r from-from to-to rounded-2xl">
                      {isLoggedIn ? 'MY PAGE' : 'SIGN UP'}
                    </button>
                  </Link>
                  <button
                    onClick={handleClick}
                    className="w-full text-xl p-0.5 text-white bg-gradient-to-r from-from to-to rounded-2xl">
                    <div className="w-full h-full py-[14px] bg-darkPrimary rounded-2xl">
                      {isLoggedIn ? 'LOGOUT' : 'LOGIN'}
                    </div>
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function CloseIcon() {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 10L23 24"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23 10L9 24"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}
