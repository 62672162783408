import MainBanner from '../../components/main/landing/main-banner';
import MembershipSection from '../../components/main/landing/membership-section';
import NoticeSection from '../../components/main/landing/notice-section';
import ShowcaseSection from '../../components/main/landing/showcase-section';
import MainTopSection from '../../components/main/landing/top-section';
import UserGuideSection from '../../components/main/landing/user-guide-section';

export default function MainPage() {
  return (
    <div>
      <MainTopSection />
      <MainBanner />
      <UserGuideSection />
      <ShowcaseSection />
      <MembershipSection />
      <NoticeSection />
    </div>
  );
}
