import { useTranslation } from 'react-i18next';
import { getMainDir } from '../../../util/common';

export default function ConsoleHomeBanner() {
  const { t } = useTranslation();

  return (
    <div className="w-full flex items-center justify-between rounded-[10px] py-8 px-12 bg-gradient-to-r from-[#6DD1F0] to-[#B635F5]">
      <span className="text-sm md:text-base 2xl:text-lg text-white font-semibold">
        {t('실존 인물의 외모와 목소리, 정체성을')}
        <br />
        {t('그대로 재현한 것 같은 메타 휴먼, 직접 만들어보세요!')}
      </span>
      <button
        onClick={() => window.open(getMainDir('/tutorial'))}
        className="bg-white rounded-full py-3 px-6 text-[#44505F] text-[13px] whitespace-nowrap hover:bg-gray-100 duration-150">
        {t('튜토리얼 보기')}
      </button>
    </div>
  );
}
