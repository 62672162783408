export default function shareKakao(route, thumbnail, title, description) {
  const kakaoKey = process.env.REACT_APP_SHARE_KAKAO_LINK_KEY;

  if (window.Kakao) {
    const kakao = window.Kakao;

    if (!kakao.isInitialized(kakaoKey)) {
      // eslint-disable-next-line max-len
      kakao.init(kakaoKey);
    }

    kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title,
        description,
        imageUrl: thumbnail,
        link: {
          mobileWebUrl: route,
          webUrl: route,
        },
      },
      buttons: [
        {
          title: '공유 영상 감상하기',
          link: {
            mobileWebUrl: route,
            webUrl: route,
          },
        },
      ],
    });
  }
}
