export const msToTime = (ms, type) => {
  let point: any = Math.round(ms % 100);
  let seconds: any = Math.floor((ms / 100) % 60);
  let minutes: any = Math.floor((ms / (100 * 60)) % 60);
  let hours: any = Math.floor((ms / (100 * 60 * 60)) % 24);

  hours = hours < 10 ? '0' + hours : `${hours}`;
  minutes = minutes < 10 ? '0' + minutes : `${minutes}`;
  seconds = seconds < 10 ? '0' + seconds : `${seconds}`;
  point = point < 10 ? '0' + point : `${point}`;

  // return (hours !== '00' ? hours + ':' : '') + minutes + ':' + seconds;
  if (type === 'ms') {
    return hours + ':' + minutes + ':' + seconds + '.' + point;
  }
  if (type === 'second') {
    if (seconds === '00' && minutes === '00' && hours === '00') {
      return '00:01';
    }
    return Number(hours) > 0
      ? hours + ':' + minutes + ':' + seconds
      : minutes + ':' + seconds;
  }
};
