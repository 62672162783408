import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { ChevronDown } from 'heroicons-react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import { languageArray } from '../../../arrays';

// const languageArray = [
//   { value: 'ko', label: '한국어' },
//   { value: 'en', label: '영어' },
//   { value: 'vi', label: '베트남어' },
// ];

type Props = {
  projectInfo: NewProjectInfoType;
  setprojectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
};

export default function ProjectLanguageListBox({
  setprojectInfo,
  projectInfo: { projectLanguage },
}: Props) {
  const handleOnChange = ({ value: projectLanguage }: any) =>
    setprojectInfo(prev => ({ ...prev, projectLanguage }));
  const { t } = useTranslation();

  const selectedLanguage = languageArray.find(
    lang => lang.value === projectLanguage
  )?.label;

  return (
    <Listbox value={projectLanguage} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative w-full">
          <Listbox.Button
            className={`w-full focus:border-primary flex justify-between items-center py-2.5 xl:py-3 2xl:py-4 px-4 xl:px-5 2xl:px-6 border border-grayBorder2 rounded-lg ${
              projectLanguage ? 'text-consoleDark' : 'text-[#999999]'
            } text-sm focus:outline-none ring-offset-0`}>
            <span>{t(selectedLanguage)}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDown
                className={`h-5 w-5 text-gray-400 duration-150 ${
                  open && 'rotate-180'
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="ease-in-out duration-150"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4">
            <Listbox.Options className="no-scroll absolute mt-2 max-h-72 w-full shadow-2xl overflow-auto rounded-md p-1 text-base ring-1 ring-[#e9e9e9] focus:outline-none sm:text-sm bg-white">
              {languageArray.map((lang, idx) => (
                <Listbox.Option
                  key={`type-${idx}`}
                  className={({ active }) =>
                    `relative flex items-center cursor-pointer select-none py-2.5 2xl:py-3.5 px-4 2xl:px-5 rounded-md duration-75 ease-out text-black ${
                      active && 'bg-[#F8F7FF]'
                    }`
                  }
                  value={lang}>
                  <span
                    className={`block truncate max-md:text-sm ${
                      lang.value === projectLanguage
                        ? 'font-medium text-primary'
                        : 'font-normal'
                    }`}>
                    {t(lang.label)}
                  </span>
                  {lang.value === projectLanguage && (
                    <span className="flex items-center text-primary ml-3">
                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
