/* eslint-disable */
import { useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { block } from '../../../store/block';
import {
  centiSecond,
  markerPos,
  pages as storedPages,
  segmentsItem as storedSegmentItem,
  timelinePercentage,
} from '../../../store/pages';
import { IPages, IPreviewElement } from '../../../store/pages/types';
type props = {
  item: IPreviewElement;
};
export function TitleItem({ item }: props) {
  // const [, setBlockInfo] = useRecoilState(block);
  const setSelectBlock = useSetRecoilState(block);
  
  const [pages] = useRecoilState<IPages[]>(storedPages);
  const [segmentsItems] = useRecoilState(storedSegmentItem);
  const [active, setActive] = useState<number>(0);
  const [markerX, setMarkerX] = useRecoilState(markerPos);
  const [_, setCs] = useRecoilState(centiSecond);
  const [percentage, setPercentage] = useRecoilState(timelinePercentage);
  const title = pages[0].segments[0].title.find(
    title => title.titleId === item.id
  );
  const titleIndex = pages[0].segments[0].title.findIndex(
    title => title.titleId === item.id
  );
  const timelinePercent = useMemo(() => {
    for (let i = 0; i < 101; i++) {
      switch (percentage) {
        case i:
          return 6.3 - i * 0.06;
      }
    }
  }, [percentage]);

  return (
    <ul className="content__line">
      <li>
        <div
          className={
            segmentsItems.id === item.id ? 'text__box active' : 'text__box'
          }
          onMouseDown={() => {
            setSelectBlock({
              segmentsGroup: 'title',
              groupIndex: titleIndex,
            });
          }}
          onClick={() => {
            setMarkerX(title.insertTime / timelinePercent);
            setCs(title.insertTime);
            setActive(item.id);
          }}
          style={{ width: '100%' }}>
          <button className="left__size__btn" />
          <i className="timeline__text__icon" />
          <div className="text__desc">{title.text}</div>
          <button className="right__size__btn" />
        </div>
      </li>
    </ul>
  );
}
