import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ServiceUsageInfoType } from '../../../../types_new/main/resource/types';
import { findValueByKey } from '../../../../utils/common';

const tableHeadList = [
  '순서',
  '서비스명',
  '프로젝트명',
  '크레딧 내역',
  '요청일시',
  '답변일시',
  '진행상태',
];

type Props = {
  serviceUsageData: ServiceUsageInfoType[];
  loading: boolean;
};

export default function ServiceUsageTable({
  serviceUsageData,
  loading,
}: Props) {
  const { t } = useTranslation();

  const getTextColor = (status: string) => {
    switch (status) {
      case 'complete':
        return 'text-darkSecondary';
      case 'progress':
        return 'text-[#FF9B70]';
      case 'request':
        return 'text-primary';
      default:
        return 'text-darkSecondary';
    }
  };

  const displayStatus = (status: 'complete' | 'progress' | 'request') => {
    switch (status) {
      case 'complete':
        return '완료';
      case 'progress':
        return '진행중';
      case 'request':
        return '요청';
      default:
        return '요청';
    }
  };

  return (
    <table className="w-full text-left mt-12 max-sm:hidden">
      <thead className="text-center bg-[#07102c]/5">
        <tr>
          {tableHeadList.map((tableHead, idx) => (
            <th
              key={`tableHead-${idx}`}
              scope="col"
              className="py-4 whitespace-nowrap font-normal text-sm text-[#0B1733]">
              {t(tableHead)}
            </th>
          ))}
        </tr>
      </thead>
      {loading ? (
        <SkeletonBody />
      ) : (
        <tbody className="text-center">
          {serviceUsageData?.map((data, idx) => (
            <tr
              key={`payInfo-${idx}`}
              className="border-b border-[#D4DEEA] font-medium">
              <td className="py-6">{idx + 1}</td>
              <td className="py-6">{data.serviceName}</td>
              <td className="py-6">
                {findValueByKey(data.metaData, 'projectName')}
              </td>
              <td className="py-6">{`-${findValueByKey(
                data.metaData,
                'credit'
              )}CR`}</td>
              <td className="py-6">
                {moment(data.requestDate).format('YYYY-MM-DD HH:mm')}
              </td>
              <td className="py-6">
                {moment(data.translationDate).format('YYYY-MM-DD HH:mm')}
              </td>
              <td
                className={`py-6 ${getTextColor(
                  findValueByKey(data.metaData, 'translationStatus')
                )}`}>
                {t(
                  displayStatus(
                    findValueByKey(data.metaData, 'translationStatus')
                  )
                )}
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </table>
  );
}

function SkeletonBody() {
  const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <tbody className="text-center">
      {array.map((_, idx) => (
        <tr
          key={`usageSkeleton-${idx}`}
          className="border-b border-[#D4DEEA] font-medium">
          <td className="py-6">
            <div className="mx-auto w-5 h-2 rounded-full bg-gray-400 animate-pulse" />
          </td>
          <td className="py-6">
            <div className="mx-auto w-16 h-2 rounded-full bg-gray-400 animate-pulse" />
          </td>
          <td className="py-6">
            <div className="mx-auto w-24 h-2 rounded-full bg-gray-400 animate-pulse" />
          </td>
          <td className="py-6">
            <div className="mx-auto w-16 h-2 rounded-full bg-gray-400 animate-pulse" />
          </td>
          <td className="py-6">
            <div className="mx-auto w-28 h-2 rounded-full bg-gray-400 animate-pulse" />
          </td>
          <td className="py-6">
            <div className="mx-auto w-28 h-2 rounded-full bg-gray-400 animate-pulse" />
          </td>
          <td className="py-6">
            <div className="mx-auto w-12 h-2 rounded-full bg-primary/40 animate-pulse" />
          </td>
        </tr>
      ))}
    </tbody>
  );
}
