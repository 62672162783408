import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { ChevronDown } from 'heroicons-react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { templateOptionArray } from '../../../arrays';
import { NewProjectInfoType } from '../../../types_new/console/service/types';

type Props = {
  disabled: boolean;
  projectInfo: NewProjectInfoType;
  setprojectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
};

export default function TemplateListBox({
  disabled,
  setprojectInfo,
  projectInfo: { template },
}: Props) {
  const { t } = useTranslation();
  const handleOnChange = ({ value: template }: any) =>
    setprojectInfo(prev => ({ ...prev, template }));

  const selectedTemplate = templateOptionArray.find(
    lang => lang.value === template
  )?.label;

  return (
    <Listbox value={template} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative w-full">
          <Listbox.Button
            aria-disabled={disabled}
            className={`w-full focus:border-primary flex justify-between items-center py-2.5 xl:py-3 2xl:py-4 px-4 xl:px-5 2xl:px-6 border border-grayBorder2 rounded-lg ${
              template ? 'text-consoleDark' : 'text-[#999999]'
            } text-sm focus:outline-none ring-offset-0`}>
            <span>{t(selectedTemplate)}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDown
                className={`h-5 w-5 text-gray-400 duration-150 ${
                  open && 'rotate-180'
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="ease-in-out duration-150"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4">
            <Listbox.Options className="no-scroll absolute mt-2 max-h-72 w-full shadow-2xl overflow-auto rounded-md p-1 text-base ring-1 ring-[#e9e9e9] focus:outline-none sm:text-sm bg-white">
              {templateOptionArray.map((option, idx) => (
                <Listbox.Option
                  key={`type-${idx}`}
                  className={({ active }) =>
                    `relative flex items-center cursor-pointer select-none py-2.5 2xl:py-3.5 px-4 2xl:px-5 rounded-md duration-75 ease-out text-black ${
                      active && 'bg-[#F8F7FF]'
                    }`
                  }
                  value={option}>
                  <span
                    className={`block truncate max-md:text-sm ${
                      option.value === template
                        ? 'font-medium text-primary'
                        : 'font-normal'
                    }`}>
                    {t(option.label)}
                  </span>
                  {option.value === template && (
                    <span className="flex items-center text-primary ml-3">
                      <CheckIcon className="h-4 w-4" aria-hidden="true" />
                    </span>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
