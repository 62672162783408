import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { lang } from '../../../recoil/atoms/user';
import { getMainDir } from '../../../util/common';

export default function MainBanner() {
  const language = useRecoilValue(lang);
  const isEnglish = language === 'en';
  return (
    <Link to="/production-inquiry">
      <section className="flex items-center w-full justify-between h-60 sm:h-64 lg:h-80 xl:h-[420px] bg-gradient-to-b from-[#7b37d8] to-[#6851ea]">
        <img
          className="max-2xl:hidden h-full"
          src={getMainDir('/img/main-banner/cut.png')}
          draggable={false}
          alt="inquiry banner cut"
        />
        <img
          className={`max-2xl:mx-auto ${
            isEnglish ? 'h-[80%] min-w-[40%] object-contain' : 'h-full'
          }`}
          src={
            isEnglish
              ? getMainDir('/img/main-banner/en-phrase.png')
              : getMainDir('/img/main-banner/phrase.png')
          }
          draggable={false}
          alt="inquiry banner phrase"
        />
        <img
          className="max-2xl:hidden h-full"
          src={getMainDir('/img/main-banner/camera.png')}
          draggable={false}
          alt="inquiry banner camera"
        />
      </section>
    </Link>
  );
}
