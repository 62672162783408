import { useCallback, useState } from 'react';
import { PostPayType, PostType } from '../../../types_new/main/resource/types';
import { findValueByKey } from '../../../utils/common';
import VideoModal from '../../VideoModal';
import ImageModal from '../../modals/image-modal';
import ItemCard from './item-card';
import MetahumanItemModal from './metahuman-item-modal';

type Props = {
  itemList: PostPayType[];
  creditleft: number;
  category: 'metahuman' | 'clip' | 'background';
  refreshCB: () => void;
  audioRef?: any;
};
export default function ItemGrid({
  itemList,
  creditleft,
  category,
  refreshCB,
  audioRef,
}: Props) {
  const [metahumanModal, setMetahumanModal] = useState({
    isOpen: false,
    metaData: [{ key: '', value: '' }],
  });
  const [videoModal, setVideoModal] = useState({ isOpen: false, videoURL: '' });
  const [imageModal, setImageModal] = useState({ isOpen: false, imageURL: '' });
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [audio, setAudio] = useState(null);

  const handleOnClick = (
    itemType: 'metahuman' | 'video' | 'image' | 'audio',
    metaData: PostType['metaData']
  ) => {
    switch (itemType) {
      case 'metahuman': {
        setMetahumanModal({ isOpen: true, metaData });
        break;
      }
      case 'video': {
        const videoURL = findValueByKey(metaData, 'file');
        setVideoModal({ isOpen: true, videoURL });
        break;
      }
      case 'image': {
        const imageURL = findValueByKey(metaData, 'file');
        setImageModal({ isOpen: true, imageURL });
        break;
      }
      case 'audio': {
        const audioURL = findValueByKey(metaData, 'file');
        handleAudioClick(audioURL);
        break;
      }
      default:
        break;
    }
  };

  const handleAudioClick = useCallback(
    (audioURL: string) => {
      if (isAudioPlaying) {
        audioRef.current.pause();
        setIsAudioPlaying(false);
        if (audioRef.current.src === encodeURI(audioURL)) return;
      }

      audioRef.current = new Audio(audioURL);
      audioRef.current.play();
      setIsAudioPlaying(true);
      setAudio(audioURL);

      audioRef.current.onended = () => {
        setIsAudioPlaying(false);
        setAudio('');
      };
    },
    [isAudioPlaying, audio]
  );

  return (
    <>
      <ImageModal
        isOpen={imageModal.isOpen}
        imageURL={imageModal.imageURL}
        closeModal={() => setImageModal(prev => ({ ...prev, isOpen: false }))}
      />
      <VideoModal
        isOpen={videoModal.isOpen}
        videoURL={videoModal.videoURL}
        closeModal={() => setVideoModal(prev => ({ ...prev, isOpen: false }))}
      />
      <MetahumanItemModal
        isOpen={metahumanModal.isOpen}
        metaData={metahumanModal.metaData}
        closeModal={() =>
          setMetahumanModal(prev => ({ ...prev, isOpen: false }))
        }
      />
      <ul className="grid grid-cols-2 md:grid-cols-4 gap-y-10 gap-x-3 lg:gap-x-5 xl:gap-y-12 xl:gap-x-7 mt-5 md:mt-6 xl:mt-8">
        {itemList.map((item, idx) => (
          <li key={`${item.postTitle}-${idx}`}>
            <ItemCard
              isAudioPlaying={isAudioPlaying}
              audio={audio}
              handleOnClick={handleOnClick}
              category={category}
              creditleft={creditleft}
              item={item}
              refreshCB={refreshCB}
            />
          </li>
        ))}
      </ul>
    </>
  );
}
