export default function HighestIndexIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.32636 2.20366H3.67329C2.86177 2.20366 2.20391 2.86153 2.20391 3.67305V8.32611C2.20391 9.13763 2.86177 9.7955 3.67329 9.7955H8.32636C9.13788 9.7955 9.79574 9.13763 9.79574 8.32611V3.67305C9.79574 2.86153 9.13788 2.20366 8.32636 2.20366ZM3.67329 1.71387C2.59127 1.71387 1.71411 2.59102 1.71411 3.67305V8.32611C1.71411 9.40814 2.59127 10.2853 3.67329 10.2853H8.32636C9.40838 10.2853 10.2855 9.40814 10.2855 8.32611V3.67305C10.2855 2.59102 9.40838 1.71387 8.32636 1.71387H3.67329Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.95918 0.489796H6.61224C7.34026 0.489796 7.94461 1.01924 8.06128 1.71409H8.28552C8.37811 1.71409 8.46922 1.72038 8.55846 1.73256C8.44613 0.757287 7.61762 0 6.61224 0H1.95918C0.877156 0 0 0.877156 0 1.95918V6.61224C0 7.61762 0.757287 8.44613 1.73256 8.55846C1.72038 8.46922 1.71409 8.3781 1.71409 8.28552V8.06128C1.01924 7.94461 0.489796 7.34026 0.489796 6.61224V1.95918C0.489796 1.14766 1.14766 0.489796 1.95918 0.489796Z"
        fill="white"
      />
      <rect
        x="3.42871"
        y="3.42871"
        width="8.57143"
        height="8.57143"
        rx="2"
        fill="white"
      />
    </svg>
  );
}
