import { Listbox, Transition } from '@headlessui/react';
import { ChevronDown } from 'heroicons-react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { speedOptionArray } from '../arrays';

type Props = {
  setTempo: Dispatch<SetStateAction<number>>;
  tempo: number;
};

export default function SpeedSelectListBox({ setTempo, tempo }: Props) {
  const { t } = useTranslation();
  const handleOnChange = ({ value }: number | any) => {
    setTempo(value);
  };

  const label = speedOptionArray.find(option => option.value === tempo)?.label;

  return (
    <Listbox value={tempo} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative w-32 2xl:w-36">
          <Listbox.Button className="w-full flex justify-between items-center px-4 2xl:px-5 py-2 2xl:py-2.5 border border-grayBorder2 rounded-lg text-consoleDark text-xs 2xl:text-sm focus:outline-none ring-offset-0">
            <div className="flex items-center space-x-1">
              <TempoIcon />
              <span className=" text-consoleDark">
                {tempo ? t(label) : t('속도 선택')}
              </span>
            </div>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDown
                className={`h-5 w-5 text-gray-400 duration-150 ${
                  open && 'rotate-180'
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="ease-in-out duration-150"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4">
            <Listbox.Options className="no-scroll absolute mt-1 2xl:mt-2 max-h-32 xl:max-h-40 2xl:max-h-72 w-full shadow-2xl overflow-auto rounded-md p-1 text-base ring-1 ring-[#e9e9e9] focus:outline-none sm:text-sm z-10 bg-white">
              {speedOptionArray?.map((speed, idx) => {
                const isActive = tempo === speed.value;

                return (
                  <Listbox.Option
                    key={`type-${idx}`}
                    className={({ active }) =>
                      `relative flex items-center cursor-pointer select-none px-3 2xl:px-4 py-2 2xl:py-2.5 rounded-md duration-75 ease-out text-black ${
                        active && 'bg-[#F8F7FF]'
                      }`
                    }
                    value={speed}>
                    <span
                      className={`block truncate text-xs 2xl:text-sm text-consoleDark  ${
                        isActive ? 'font-medium text-primary' : 'font-normal'
                      }`}>
                      {t(speed.label)}
                    </span>
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

function TempoIcon() {
  return (
    <svg
      width="18"
      height="10"
      viewBox="0 0 18 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M1 9V1L7.66667 5L1 9Z" stroke="black" />
      <path d="M10.3333 9V1L17 5L10.3333 9Z" stroke="black" />
    </svg>
  );
}
