export default function SimpleLogo() {
  return (
    <svg
      width="41"
      height="29"
      viewBox="0 0 41 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M38.1972 12.3111H34.0572C33.0734 5.35705 27.2118 0 20.1029 0C12.994 0 7.13233 5.35705 6.15442 12.3111H2.00853C0.895933 12.3111 0 13.2288 0 14.355C0 15.4812 0.901789 16.3989 2.00853 16.3989H6.14856C7.12647 23.3589 12.9881 28.71 20.097 28.71C27.2059 28.71 33.0675 23.3529 34.0455 16.3989H38.1855C39.2981 16.3989 40.194 15.4812 40.194 14.355C40.194 13.2288 39.2922 12.3111 38.1855 12.3111H38.1972ZM20.1029 24.5864C14.5516 24.5864 10.0485 20.004 10.0485 14.361C10.0485 8.71788 14.5516 4.12952 20.1029 4.12952C24.9632 4.12952 29.0212 7.64527 29.9523 12.3111H24.0204C22.9078 12.3111 22.0118 13.2288 22.0118 14.355C22.0118 15.4812 22.9136 16.3989 24.0204 16.3989H29.9523C29.0212 21.0647 24.9632 24.5805 20.1029 24.5805V24.5864Z"
        fill="url(#paint0_linear_340_22372)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_340_22372"
          x1="9.95481"
          y1="4.02822"
          x2="30.5457"
          y2="24.2686"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#25C5F5" />
          <stop offset="1" stopColor="#B635F5" />
        </linearGradient>
      </defs>
    </svg>
  );
}
