import moment from 'moment';
import { TemplateType } from '../../../../types_new/main/resource/types';
import { getValueByKey } from '../../../../utils/common';

const tableHeadList = ['템플릿명', '크레딧', '일시', '상태'];

type Props = {
  data: TemplateType;
};

export default function MobileTemplateTable({ data }: Props) {
  const displayStatus = (tranType: TemplateType['tranType']) => {
    switch (tranType) {
      case 'buy':
        return '구매';
      case 'sell':
        return '판매';
      default:
        return '';
    }
  };
  const credit = getValueByKey(data.metaData, 'credit');

  return (
    <div className="max-sm:flex hidden my-4 rounded-lg bg-white text-darkSecondary py-4 px-5  items-center justify-between">
      <ul className="flex flex-col space-y-3">
        {tableHeadList.map((list, idx) => (
          <li key={`list-${idx}`} className="font-normal text-sm">
            {list}
          </li>
        ))}
      </ul>

      <ul className="flex flex-col text-sm font-medium space-y-3 text-right">
        <li>{data.postTitle}</li>
        <li>{credit}</li>
        <li> {moment(data.registerDate).format('YYYY-MM-DD')}</li>
        <li> {displayStatus(data.tranType)}</li>
      </ul>
    </div>
  );
}
