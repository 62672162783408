import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getProjectList } from '../../../networks/project.service';
import { ProjectInfoType } from '../../../types_new/console/resource/types';
import { ProjectReqType } from '../../../types_new/console/service/types';
import ProjectCard from './project-card';
import ProjectCardSkeleton from './project-card-skeleton';

export default function RecentProjectsGrid({
  getProjects,
}: {
  getProjects: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<ProjectInfoType[]>([]);
  const { t } = useTranslation();

  const refershProjectList = params => {
    setLoading(true);

    getProjectList(params)
      .then(({ data }) => {
        if (data.code === '200') {
          const projects = data.body.items;
          setProjects(projects);
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    const params: ProjectReqType = {
      status: 'publish',
      paged: 1,
      postsPerPage: 6,
      order: 'DESC',
    };

    refershProjectList(params);
  }, []);

  return (
    <div className="w-full flex flex-col space-y-6 mt-6 xl:mt-8 2xl:mt-12">
      {projects.length ? (
        <h4 className="text-consoleDark text-lg">{t('생성한 프로젝트')}</h4>
      ) : null}
      <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-x-3 xl:gap-x-4 2xl:gap-x-[18px] gap-y-5 xl:gap-y-8 2xl:gap-y-10">
        {loading ? (
          <Skeleton />
        ) : (
          <>
            {projects?.map((project, idx) => (
              <li key={`project-${idx}`}>
                <ProjectCard
                  setProjects={setProjects}
                  project={project}
                  updateDuplicatedProject={getProjects}
                />
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
}

function Skeleton() {
  const skeletonArr = [1, 2, 3, 4, 5, 6];
  return (
    <>
      {skeletonArr.map((_, idx) => (
        <li key={`skeleton-${idx}`}>
          <ProjectCardSkeleton />
        </li>
      ))}
    </>
  );
}
