import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { koreanRegex } from '../constants/regex';
import { baseURL } from '../services/defaultClient';
import { getWavFilePath } from '../services/project';
import { projectLang } from '../store/pages';
import Loader from './lottie/Loader';
import SpeedSelectListBox from './speed-select-list-box';
import GptLogo from './vector/GptLogo';
import PlayIcon from './vector/PlayIcon';
import StopIcon from './vector/StopIcon';
import TranslateIcon from './vector/TranslateIcon';

type Props = {
  popupInfo: any;
  setPopupInfo: Dispatch<SetStateAction<any>>;
  scriptValue: string;
  setScriptValue: Dispatch<SetStateAction<string>>;
  tempo: number;
  setTempo: Dispatch<SetStateAction<number>>;
  openGPTModal: () => void;
  openTranslateModal: () => void;
  scriptChange: boolean;
  translatedLang: string;
  selectedHumanLang: string;
};

export default function ScriptTextarea({
  popupInfo,
  setPopupInfo,
  scriptValue,
  setScriptValue,
  tempo,
  setTempo,
  openGPTModal,
  openTranslateModal,
  translatedLang,
  scriptChange,
  selectedHumanLang,
}: Props) {
  const [isScriptPlaying, setIsScriptPlaying] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [scriptAudio, setScriptAudio] = useState<HTMLAudioElement>(null);
  const projectLanguage = useRecoilValue(projectLang);
  const { t } = useTranslation();
  const { projectId } = useParams();

  const handlePreviewClick = useCallback(async () => {
    if (isScriptPlaying) {
      scriptAudio.pause();
      setIsScriptPlaying(false);
      return;
    }

    let currentLang: string;

    if (scriptChange) {
      if (translatedLang) {
        currentLang = translatedLang;
      } else {
        currentLang = selectedHumanLang;
      }
    } else if (translatedLang) {
      currentLang = translatedLang;
    } else {
      currentLang = '';
    }

    const isScriptKorean = koreanRegex.test(popupInfo.scriptValue);

    setPreviewLoading(true);
    getWavFilePath(
      {
        projectId: +projectId,
        voiceId: String(popupInfo.voiceId),
        text:
          popupInfo.motionPreset !== ''
            ? popupInfo.motionPreset
            : popupInfo.scriptValue,
        tempo,
        targetLang: isScriptKorean ? '' : currentLang || projectLanguage,
      },
      isScriptKorean
    )
      .then(({ filePath }) => {
        const audio = new Audio(`${baseURL}${filePath}`);
        setScriptAudio(audio);
        audio.play();
        setIsScriptPlaying(true);
      })
      .catch(console.log)
      .finally(() => setPreviewLoading(false));
  }, [isScriptPlaying, projectId, popupInfo, tempo]);

  useEffect(() => {
    if (!scriptAudio) return;

    scriptAudio.onended = () => {
      setIsScriptPlaying(false);
      setScriptAudio(null);
    };
  }, [scriptAudio]);

  return (
    <div className="accordin-menu">
      <div className="accordin-menu-script">
        <form>
          <textarea
            spellCheck="false"
            maxLength={350}
            name=""
            id=""
            onChange={e => {
              if (popupInfo.motionPreset !== '') {
                if (scriptValue) setScriptValue('');
                setPopupInfo({
                  ...popupInfo,
                  scriptValue: '',
                  motionPreset: '',
                });
              } else {
                if (scriptValue) setScriptValue('');
                setPopupInfo({
                  ...popupInfo,
                  scriptValue: e.target.value,
                });
              }
            }}
            value={scriptValue || popupInfo.scriptValue}
            style={{
              color: popupInfo.motionPreset !== '' ? '#7B61FF' : '',
              resize: 'none',
              border: '1px solid #7b61ff',
              borderRadius: '8px',
              padding: '5px',
            }}
            placeholder={t('스크립트를 입력해주세요.')}
          />
          <div className="text-byte">
            <p>{popupInfo.scriptValue?.length || 0}</p>
            /350{t('자')}
          </div>
        </form>
      </div>
      <div className="script-select-cont">
        <div className="flex space-x-3 items-center">
          <SpeedSelectListBox tempo={tempo} setTempo={setTempo} />
          <button
            onClick={handlePreviewClick}
            disabled={!popupInfo.scriptValue || previewLoading}
            className={`${
              previewLoading
                ? 'bg-none absolute left-[138px]'
                : 'p-2 rounded-full bg-grayBg hover:bg-grayBg/50 duration-100 ease-out disabled:cursor-not-allowed disabled:opacity-40'
            }`}>
            {previewLoading ? (
              <Loader width={50} height={50} />
            ) : isScriptPlaying ? (
              <StopIcon />
            ) : (
              <PlayIcon />
            )}
          </button>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={openGPTModal}
            className="flex bg-white ring-[1.4px] ring-[#8d77fc] space-x-2 px-4 py-3 rounded-xl tracking-widest font-semibold text-white text-xs hover:bg-[#9f8cfd] duration-100 ease-out group">
            <GptLogo className="w-4 h-4" />
            <span className="group-hover:text-white font-semibold">
              ChatGPT
            </span>
          </button>
          <button
            onClick={openTranslateModal}
            className="flex px-4 py-3 bg-[#8d77fc] rounded-xl tracking-widest font-semibold text-white text-xs hover:bg-[#9f8cfd] duration-100 ease-out">
            <i className="mr-1">
              <TranslateIcon />
            </i>
            {t('번역')}
          </button>
        </div>
      </div>
    </div>
  );
}
