export default function OutlinedIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 9.95312C7.94883 9.95312 8.84023 9.58262 9.51289 8.91133C10.1855 8.24004 10.5547 7.34727 10.5547 6.39844V2.13281C10.5547 2.04258 10.4809 1.96875 10.3906 1.96875H9.57031C9.48008 1.96875 9.40625 2.04258 9.40625 2.13281V6.39844C9.40625 7.72461 8.32617 8.80469 7 8.80469C5.67383 8.80469 4.59375 7.72461 4.59375 6.39844V2.13281C4.59375 2.04258 4.51992 1.96875 4.42969 1.96875H3.60938C3.51914 1.96875 3.44531 2.04258 3.44531 2.13281V6.39844C3.44531 7.34727 3.81582 8.23867 4.48711 8.91133C5.1584 9.58398 6.05117 9.95312 7 9.95312Z"
        fill="#A3AAB8"
      />
      <path
        d="M2.73438 10.9922H11.2656C11.3258 10.9922 11.375 11.0387 11.375 11.0961V11.9273C11.375 11.9848 11.3258 12.0312 11.2656 12.0312H2.73438C2.67422 12.0312 2.625 11.9848 2.625 11.9273V11.0961C2.625 11.0387 2.67422 10.9922 2.73438 10.9922Z"
        fill="#A3AAB8"
      />
    </svg>
  );
}
