type Props = {
  isWhite?: boolean;
};

export default function MyTemplateIcon({ isWhite }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none">
      <path
        d="M20.25 3.75H3.75C3.35218 3.75 2.97064 3.90804 2.68934 4.18934C2.40804 4.47064 2.25 4.85218 2.25 5.25V18.75C2.25 19.1478 2.40804 19.5294 2.68934 19.8107C2.97064 20.092 3.35218 20.25 3.75 20.25H20.25C20.6478 20.25 21.0294 20.092 21.3107 19.8107C21.592 19.5294 21.75 19.1478 21.75 18.75V5.25C21.75 4.85218 21.592 4.47064 21.3107 4.18934C21.0294 3.90804 20.6478 3.75 20.25 3.75ZM17.25 5.25H20.25V6.75H17.25V5.25ZM6.75 18.75H3.75V17.25H6.75V18.75ZM6.75 6.75H3.75V5.25H6.75V6.75ZM11.25 18.75H8.25V17.25H11.25V18.75ZM11.25 6.75H8.25V5.25H11.25V6.75ZM15.75 18.75H12.75V17.25H15.75V18.75ZM15.75 6.75H12.75V5.25H15.75V6.75ZM20.25 18.75H17.25V17.25H20.25V18.75Z"
        className={`${isWhite ? 'fill-white' : 'fill-[#44505F]'}`}
      />
    </svg>
  );
}
