export default function UncoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none">
      <path
        d="M6.75 11.1211L3 7.37109L6.75 3.62109"
        className="stroke-[#363D59] group-disabled:stroke-[#363D59]/30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 15.6211V10.3711C15 8.71424 13.6569 7.37109 12 7.37109H3"
        className="stroke-[#363D59] group-disabled:stroke-[#363D59]/30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
