/* eslint-disable */
import { useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { block } from '../../../store/block';
import {
  centiSecond,
  markerPos,
  pages as storedPages,
  segmentsItem as storedSegmentItem,
  timelinePercentage,
} from '../../../store/pages';
import { IPages, IPreviewElement } from '../../../store/pages/types';
import { changeHost } from '../../../utils/common';
type props = {
  item: IPreviewElement;
};
export function BackgroundColorImageItem({ item }: props) {
  // const [, setBlockInfo] = useRecoilState(block);
  const setSelectBlock = useSetRecoilState(block);

  const pages = useRecoilValue<IPages[]>(storedPages);
  const [segmentsItems] = useRecoilState(storedSegmentItem);
  const [active, setActive] = useState(0);
  const percentage = useRecoilValue(timelinePercentage);
  const background = pages[0].segments[0].background.find(
    bg => +bg.backgroundId === +item.id
  );
  const backgroundIndex = pages[0].segments[0].background.findIndex(
    bg => +bg.backgroundId === +item.id
  );

  const timelinePercent = useMemo(() => {
    for (let i = 0; i < 101; i++) {
      switch (percentage) {
        case i:
          return 6.3 - i * 0.06;
      }
    }
  }, [percentage]);

  const tmpPage = JSON.parse(JSON.stringify(pages));
  const metahumanArr = tmpPage[0]?.segments[0]?.segment
    ?.filter((item: any) => item.mediaType === 'metahuman')
    ?.filter((e: any, index: number) => item.index === index);

  const [markerX, setMarkerX] = useRecoilState(markerPos);
  const [_, setCs] = useRecoilState(centiSecond);

  return (
    <ul className="content__line">
      <li>
        <div
          className={
            segmentsItems.id === item.id
              ? 'backgroundImg__box active'
              : 'backgroundImg__box'
          }
          style={{
            width: '100%',
            background:
              background?.color === null ? null : `#${background?.color}`,
          }}
          onClick={() => {
            setMarkerX(background.insertTime / timelinePercent);
            setCs(background.insertTime);
            setActive(item.id);
          }}
          onMouseDown={() => {
            setSelectBlock({
              segmentsGroup: 'background',
              groupIndex: backgroundIndex,
            });
          }}>
          <button className="left__size__btn" />

          {background?.previewImage ? (
            <div
              className="backgroundImg__img"
              style={{
                width: '100%',
                backgroundImage: `url(${changeHost(background?.previewImage)})`,
                display: 'flex',
                alignItems: 'center',
              }}>
              <i
                className="timeline__backgroundImg__icon"
                style={{ marginLeft: '5px' }}
              />
            </div>
          ) : null}
          <button className="right__size__btn" />
        </div>
      </li>
    </ul>
  );
}
