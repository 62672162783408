export default function DuplicateIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0476 12.5712V6.47596C11.0476 6.07182 10.8871 5.68423 10.6013 5.39846C10.3156 5.11269 9.92797 4.95215 9.52383 4.95215H3.42859C3.02446 4.95215 2.63687 5.11269 2.3511 5.39846C2.06533 5.68423 1.90479 6.07182 1.90479 6.47596V12.5712C1.90479 12.9753 2.06533 13.3629 2.3511 13.6487C2.63687 13.9345 3.02446 14.095 3.42859 14.095H9.52383C9.92797 14.095 10.3156 13.9345 10.6013 13.6487C10.8871 13.3629 11.0476 12.9753 11.0476 12.5712Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0477 11.0478H12.5715C12.9756 11.0478 13.3632 10.8873 13.649 10.6015C13.9348 10.3157 14.0953 9.92813 14.0953 9.52399V3.43104C14.0953 3.0269 13.9348 2.63931 13.649 2.35354C13.3632 2.06777 12.9756 1.90723 12.5715 1.90723H12.5692L6.47398 1.91789C6.07024 1.9185 5.68324 2.07931 5.39796 2.36501C5.11269 2.65072 4.95246 3.03796 4.95246 3.4417V4.95256M6.47627 7.23827V11.8097M8.76198 9.52399H4.19055"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
