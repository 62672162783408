import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Cookies from 'universal-cookie';
import { linkArray } from '../../arrays';
import { doGetPopupList } from '../../networks/source.service';
import { isSignedAtom } from '../../networks/store/member';
import { lang } from '../../recoil/atoms/user';
import { getMainDir } from '../../util/common';
import { scrollToTop } from '../../utils/common';
import FamilySiteListBox from '../main/family-site-list-box';
import LanguageListBox from '../main/language-list-box';
import SystemPopupModal from '../main/signup/system-popup-modal';
import TermsModal from '../main/signup/terms-modal';
import BlogIcon from '../svg/sns/BlogIcon';
import FooterFacebookIcon from '../svg/sns/FooterFacebookIcon';
import FooterInstagramIcon from '../svg/sns/FooterInstagramIcon';
import YoutubeIcon from '../svg/sns/YoutubeIcon';
import TopButtonIcon from '../svg/top-button-icon';
import OperationTerms from '../terms/OperationTerms';
import PrivacyPolicyTerms from '../terms/PrivacyPolicyTerms';
import ServiceTerms from '../terms/ServiceTerms';

const snsArray: { icon: any; link: string }[] = [
  {
    icon: <FooterFacebookIcon />,
    link: 'https://www.facebook.com/people/We-Ploonet/pfbid0KaNKwF3WtQ6WfBjFW8Zo7AZSBjHbVFgZyD9JuNaF3vi14SKsB3YuoL4Z6sowey3Yl/',
  },
  {
    icon: <BlogIcon />,
    link: 'https://blog.naver.com/saltluxmarketing',
  },
  {
    icon: <YoutubeIcon />,
    link: 'https://www.youtube.com/@ploonet_official',
  },
  {
    icon: <FooterInstagramIcon />,
    link: 'https://www.instagram.com/ploonet_official/',
  },
];

const footerArray: { title: string; terms: any }[] = [
  { title: '서비스 운영정책', terms: <ServiceTerms /> },
  { title: '서비스 이용약관', terms: <OperationTerms /> },
  { title: '개인정보 처리 방침', terms: <PrivacyPolicyTerms /> },
];

export default function LandingFooter() {
  const language = useRecoilValue(lang);
  const { t } = useTranslation();
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const [modal, setModal] = useState({
    isOpen: false,
    terms: null,
    title: '',
  });
  const [popupList, setPopupList] = useState([]);
  const [showSystemPopupModal, setShowSystemPopupModal] = useState<boolean[]>(
    []
  );
  const refPopupCheck = useRef<any>([]);

  const closeSystemPopupModal = (idx: number, popupId: string) => {
    if (refPopupCheck.current[idx].checked) {
      const cookies = new Cookies();
      const date = new Date();
      const expireDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        23,
        59,
        59
      );
      cookies.set('POPUP_' + popupId, false, {
        path: '/',
        expires: expireDate,
      });
    }

    showSystemPopupModal[idx] = false;
    setShowSystemPopupModal([...showSystemPopupModal]);
  };
  
  const closeModal = () =>
    setModal(prev => ({
      ...prev,
      isOpen: false,
    }));

  const openTermsModal = (title: string, terms: any) =>
    setModal({
      isOpen: true,
      terms,
      title,
    });

  useEffect(() => {
    const reqBody = {
      lang: language,
      postsPerPage: 100,
      paged: 1,
    };
    doGetPopupList(reqBody)
      .then(setPopupList)
      .catch(console.log)
      // .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {popupList.map((item: any, index: number) => {
        const startDateTime = item?.metaData?.find(
          (obj: any) => obj?.key === 'startDateTime'
        );
        const endDateTime = item?.metaData?.find(
          (obj: any) => obj?.key === 'endDateTime'
        );
        const popupId = item?.postId;

        const startDate = startDateTime?.value
          ? new Date(startDateTime?.value)
          : new Date();
        const endDate = endDateTime?.value
          ? new Date(endDateTime?.value)
          : new Date();
        const today = new Date();

        const popupOpen = today >= startDate && today <= endDate;
        const cookieInst = new Cookies();

        console.log('팝업-' + popupId + ':' + cookieInst.get('POPUP_' + popupId));
        return (
          <div key={index}>
            {popupOpen &&
            showSystemPopupModal[index] !== false &&
            cookieInst.get('POPUP_' + popupId) !== false ? (
              <SystemPopupModal
                closeSystemPopupModal={closeSystemPopupModal}
                index={index}
                item={item}
                refPopupCheck={refPopupCheck}
              />
            ) : null}
          </div>
        );
      })}

      <TermsModal
        isOpen={modal.isOpen}
        terms={modal.terms}
        closeModal={closeModal}
        title={t(modal.title)}
        isSignupPage={false}
      />

      <footer className="bg-black pb-10">
        <section
          style={{
            backgroundImage: `url(${getMainDir('/main/img/footer-bg.png')})`,
          }}
          className="relative w-full h-52 lg:h-64 2xl:h-80 bg-no-repeat lg:bg-cover bg-center">
          <div className="w-full h-full pt-8 lg:pt-16 2xl:pt-24 bg-gradient-to-b from-black/0 to-black/90 px-5 lg:px-20">
            <div className="max-w-screen-xl w-full flex flex-col items-start max-lg:space-y-3 lg:flex-row lg:items-center justify-between mx-auto">
              <div className="flex flex-col space-y-[10px] lg:space-y-5 2xl:space-y-6">
                <span className="text-transparent text-lg lg:text-xl 2xl:text-[26px] w-fit bg-clip-text bg-gradient-to-r from-from to-to font-medium">
                  Try a free membership
                </span>
                <h1 className="text-xl lg:text-3xl 2xl:text-4xl">
                  {t('플루닛 스튜디오 지금 바로 시작해보세요')}
                </h1>
                <span className="text-[#ddd] max-lg:font-light text-xs lg:text-sm 2xl:text-base">
                  {t(
                    '회원 가입 즉시 제공되는 무료 크레딧으로 스튜디오 서비스를 체험해 볼 수 있습니다.'
                  )}
                </span>
              </div>
              <Link to={isLoggedIn ? '/console' : '/signup'}>
                <button className="text-xs lg:text-sm font-bold border border-primary py-4 px-10 rounded-md hover:bg-primary/70 duration-100">
                  {isLoggedIn ? t('스튜디오 바로가기') : t('회원가입 바로가기')}
                </button>
              </Link>
            </div>
          </div>
        </section>
        <section className="bg-black pt-10 px-5 lg:pt-20">
          <div className="max-w-screen-2xl w-full mx-auto flex items-start justify-between">
            <div className="flex flex-col lg:flex-row lg:space-x-[74px] items-start">
              <i className="min-w-fit">
                <img src={getMainDir('/svg/en-logo.svg')} alt="main logo" />
              </i>
              <div className="flex flex-col space-y-5">
                <nav className="max-lg:hidden">
                  <ul className="flex space-x-3">
                    {linkArray.map(({ link, name }, idx) => (
                      <Link className="flex items-center" to={link} key={name}>
                        <li className="text-[13px] text-[#dfe0e5]">
                          {t(name)}
                        </li>
                        {idx < linkArray.length - 1 && (
                          <div className="divider h-[10px] w-px bg-[#5b5b5b] ml-3 -translate-y-px" />
                        )}
                      </Link>
                    ))}
                  </ul>
                </nav>
                <div className="flex flex-col space-y-1 text-[13px] text-[#a5a5a5] font-light max-lg:hidden">
                  <div className="flex space-x-2">
                    <span>{t('(주) 플루닛')}</span>
                    <span>
                      {t(
                        '서울시 송파구 올림픽로35길 123, 향군타워9층 [05510, (구)신천동 7-29]'
                      )}
                    </span>
                    <span>{t('대표이사: 이경일')}</span>
                  </div>
                  <div className="flex space-x-2">
                    <span>{t('고객센터')} 1533-6116</span>
                    <span>FAX. 02-3453-1116</span>
                    <span>E-mail. support.studio@ploonet.com</span>
                  </div>
                  <div className="flex space-x-2">
                    <span>{t('사업자등록번호')}: 451-81-02812</span>
                    {language === 'ko' && (
                      <span>통신판매사업자번호: 2022-서울강남-06647</span>
                    )}
                  </div>
                </div>
                <div className="flex flex-col space-y-1 text-[13px] text-[#a5a5a5] font-light lg:hidden">
                  <p className="col-group">
                    <span>Tel. 1533-6116</span>
                    <span> FAX. 02-3453-1116</span>
                  </p>
                  <p className="col-group">
                    <span>E-mail. ploonet@saltlux.com</span>
                  </p>
                  <p className="col-group">
                    <span>
                      {t(
                        '서울시 송파구 올림픽로35길 123, 향군타워9층 [05510, (구)신천동 7-29]'
                      )}
                    </span>
                  </p>
                </div>
                <p className="text-[#666] text-[13px] font-medium leading-6">
                  © Copyright 2022 PlOONET. All rights reserved.
                </p>
                <div className="flex items-center text-white/70 text-[13px]">
                  <ul className="flex">
                    {footerArray.map(({ title, terms }, idx) => (
                      <li key={title} className="flex items-center">
                        {idx !== 0 && (
                          <span className="w-[3px] rounded-full h-[3px] bg-tertiary mx-4 flex"></span>
                        )}
                        <button onClick={() => openTermsModal(title, terms)}>
                          {title}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end space-y-[30px] max-lg:hidden">
              <div className="space-x-5">
                {snsArray.map(({ icon, link }, idx) => (
                  <button key={`sns-${idx}`} onClick={() => window.open(link)}>
                    {icon}
                  </button>
                ))}
              </div>
              <FamilySiteListBox />
              <LanguageListBox />
            </div>
          </div>
        </section>
        <section className="bg-black flex justify-center pt-11 max-lg:hidden">
          <button
            className="rounded-full flex justify-center items-center w-40 h-40 border-[1px] border-white/20 text-white text-sm font-medium hover:bg-white/10 duration-100 group"
            onClick={scrollToTop}>
            <span className="group-hover:hidden">top</span>
            <TopButtonIcon />
          </button>
        </section>
      </footer>
    </>
  );
}
