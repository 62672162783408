import axios from 'axios';
import { createTranslateWav } from '../networks/translate.service';
import { IPages, ISegments } from '../store/pages/types';
import initAxios, { nameSpace } from './defaultClient';
// import { getForeignWav } from './translate';

const prefix = '/projects';

export type TitleStyle = {
  backgroundColor: string;
  color: string;
  fontFamily: string;
  fontSize: string;
  fontStyle: string;
  fontWeight: string;
  textAlign: string;
  textDecoration: string;
};

export type Title = {
  duration: number;
  insertTime: number;
  posX: string;
  posY: string;
  rank: number;
  style: TitleStyle;
  text: string;
  titleId: string;
};

export type Image = {
  duration: number;
  height: number;
  imageId: string;
  imagePath: string;
  imageUrl: string;
  insertTime: number;
  posX: string;
  posY: string;
  rank: number;
  width: number;
};

export type Background = {
  backgroundId: string;
  color: string;
  contentId: number;
  duration: number;
  endTime: number;
  fadeIn: number;
  fadeOut: number;
  insertTime: number;
  integratedID: number;
  previewImage: string;
  rank: number;
  sourcePath: string;
  sourceURL: string;
  startTime: number;
  type: string;
};
export type MetaHuman = {
  ID: string;
  thumbnail: string;
  mp4File: string;
  pngFile: string;
};

export type GetProjectInfoDTO = {
  code: string;
  body: {
    rails: any;
    canvasSize: boolean;
    dnjdlek: string;
    meta: boolean;
    metaHuman: MetaHuman;
    originalProjectId: string;
    pages: IPages[];
    playTime: number;
    previewNum: number;
    segments: boolean;
    voice: boolean;
    xml: boolean;
    projectName?: string;
    projectLanguage?: string;
  };
};
// 프로젝트 정보 가져오기
export const getProjectInfo = async (args: any) => {
  const axios = initAxios();
  const res = await axios.get<GetProjectInfoDTO>(
    `${nameSpace}${prefix}/${args.projectId}`
  );
  return res;
};

// 프로젝트 저장하기
interface ISaveProject {
  projectId: number;
  pages: IPages[];
  playTime: number;
  rails: any;
}

export const saveProject = async (args: ISaveProject) => {
  const axios = initAxios();
  return await axios.patch(`${nameSpace}${prefix}/`, {
    ...args,
  });
};

// 프로젝트 타이틀 변경
interface IChangeProjectTitle {
  projectId: number;
  projectName: string;
}

export const changeProjectName = async (args: IChangeProjectTitle) => {
  const axios = initAxios();
  return await axios.patch(`${nameSpace}${prefix}/title`, {
    ...args,
  });
};

// 영상 생성하기
interface ICreateVideo {
  projectId: number;
  format: string;
  display: string;
  credit: number;
  companySeq: string;
  exportVoice: string;
}

export const createVideo = async (args: ICreateVideo) => {
  const axios = initAxios();
  return await axios.post(`${nameSpace}${prefix}/produce`, {
    ...args,
  });
};

// 영상 생성 상태 확인 (롱폴링)
interface ICreateVideoStatus {
  projectId: number;
}

export const createVideoStatus = async (args: ICreateVideoStatus) => {
  const axios = initAxios();
  return await axios.get(`${nameSpace}${prefix}/status`, {
    params: {
      ...args,
    },
  });
};

// 영상 프리뷰 (프리뷰 인코딩)
interface ICreatePreview {
  projectId: number;
  page: number;
  playTime: number;
  recall: boolean;
  pages: ISegments[];
  segmentId: number;
}

export const createPreview = async (args: ICreatePreview) => {
  const axios = initAxios();
  return await axios.post(`${nameSpace}${prefix}/preview`, {
    ...args,
  });
};

// 스크립트 생성
export interface ICreateScript {
  modelId?: string | number;
  projectId?: number;
  voiceId: string;
  text: string;
  tempo: number;
  targetLang?: string;
}

export const getWavFilePath = async (
  args: ICreateScript,
  isScriptKorean: boolean
) => {
  if (isScriptKorean) {
    return await getWavFile(args);
  }

  const { data } = await createTranslateWav(args);

  if (data.code !== '200') throw new Error('something went wrong...');

  const { body } = data;
  const filePath = body.wavFilePath.replace('/var/www/html', '');

  return { body, filePath };
};

export const getWavFile = async (args: ICreateScript) => {
  const axios = initAxios();
  const { data } = await axios.post(`${nameSpace}${prefix}/wav`, {
    ...args,
  });

  if (data.code !== '200') throw new Error('Something went wrong. 😿');

  const { body } = data;
  const filePath = body.wavFilePath.replace('/var/www/html', '');

  return { body, filePath };
};

export const createScript = async (args: ICreateScript) => {
  const axios = initAxios();
  return await axios.post(`${nameSpace}${prefix}/wav`, {
    ...args,
  });
};

export interface ICreateTalkScript {
  projectId: number;
  segments: segments[];
}
interface segments {
  metahuman: {
    modelId: string;
    styleId: string;
    poseId: string;
    voiceId: string;
    postId: string;
  };
  text: string;
  tempo: 0.6 | 0.8 | 1.0 | 1.2 | 1.4;
}

export const createTalkScript = async (args: ICreateTalkScript) => {
  const axios = initAxios();
  return await axios.post(`${nameSpace}${prefix}/multi/wav`, {
    ...args,
  });
};

// PPT로 장면 추가
export const createPPTpage = async (args: any) => {
  const axios = initAxios();
  return await axios.post('/wp-json/ep/v1/node/projects/add/slide/ppt', args, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

// PDF로 장면 추가
export const createPDFpage = async (args: any) => {
  const axios = initAxios();
  return await axios.post('/wp-json/ep/v1/node/projects/add/slide/pdf', args, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getAddPaylist = async (args: any) => {
  return await axios.post('/wp-json/ep/v1/public/paylist/add', args);
};
