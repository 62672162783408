import { t } from 'i18next';
import { debounce } from 'lodash';
import QueryString from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { langArray } from '../array/languageArray';
import ScriptArrow from '../images/metahuman/icon-arrow-script.svg';
import TranslationIcon from '../images/metahuman/icon-script01.svg';
import {
  createTranslate,
  createTranslateWav,
} from '../networks/translate.service';
import { createScript } from '../services/project';
import { block } from '../store/block';
import Loader from './lottie/Loader';
import {
  LanguageValueType,
  getAudioFilePath,
  getLanguageByValue,
  isScriptKorean,
} from './ts/common';
import ArrowRightSmall from './vector/ArrowRightSmall';
import PlayIcon from './vector/PlayIcon';
import StopIcon from './vector/StopIcon';

type ModalType = {
  sceneNum: string;
  currentLangName: string;
  currentLang: string;
  targetLang?: string;
  currentValue?: any;
  targetValue?: string;
  modelId: string;
};

export default function TranslateModals({
  scriptValue,
  closeModal,
  getScriptValue,
  getTargetLang,
  selectedHumanLang,
  voiceId,
  projectLang,
  notSetLang,
  modelId,
  postId,
}) {
  const [modalInfo, setModalInfo] = useState<ModalType>({
    sceneNum: '01',
    currentLangName: t('한국어 (Korean)'),
    currentLang: 'ko',
    targetLang: t('번역할 언어를 선택해주세요.'),
    currentValue: scriptValue,
    targetValue: '',
    modelId,
  });

  const selectedBlock = useRecoilValue(block);
  const [targetLang, setTargetLang] = useState('');
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [targetPreviewLoading, setTargetPreviewLoading] = useState(false);
  const [currentValueIsPlaying, setCurrentValueIsPlaying] = useState(false);
  const [targetValueIsPlaying, setTargetValueIsPlaying] = useState(false);
  const [scriptAudio, setScriptAudio] = useState<HTMLAudioElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(scriptValue);
  const location = useLocation();
  const queryData = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }); // query
  const projectId = queryData?.projectId;

  const handleCurrentPreviewClick = useCallback(async () => {
    if (!modalInfo.currentValue) {
      return;
    }

    if (currentValueIsPlaying && scriptAudio !== null) {
      scriptAudio.pause();
      setCurrentValueIsPlaying(false);
      return;
    }

    setPreviewLoading(true);
    if (isScriptKorean(modalInfo.currentValue)) {
      await createScript({
        voiceId: String(voiceId),
        text: modalInfo.currentValue,
        tempo: 1.0,
      })
        .then(res => {
          const audio = new Audio(getAudioFilePath(res));
          setScriptAudio(audio);
          audio.play();
          setCurrentValueIsPlaying(true);
        })
        .catch(console.log)
        .finally(() => setPreviewLoading(false));
    } else {
      await createTranslateWav({
        text: modalInfo.currentValue,
        targetLang: notSetLang || selectedHumanLang || projectLang,
        voiceId: String(voiceId),
        projectId: +projectId,
        modelId: modalInfo.modelId,
        tempo: 1.0,
        postId,
      })
        .then(res => {
          const audio = new Audio(getAudioFilePath(res));
          setScriptAudio(audio);
          audio.play();
          setCurrentValueIsPlaying(true);
        })
        .catch(console.log)
        .finally(() => setPreviewLoading(false));
    }
  }, [currentValueIsPlaying, modalInfo]);

  const handleTargetPreviewClick = useCallback(async () => {
    if (!modalInfo.targetValue) {
      return;
    }
    if (targetValueIsPlaying && scriptAudio !== null) {
      scriptAudio.pause();
      setTargetValueIsPlaying(false);
      return;
    }
    setTargetPreviewLoading(true);

    if (isScriptKorean(modalInfo.targetValue)) {
      await createScript({
        voiceId: String(voiceId),
        text: modalInfo.targetValue,
        tempo: 1.0,
      })
        .then(res => {
          const audio = new Audio(getAudioFilePath(res));
          setScriptAudio(audio);
          audio.play();
          setTargetValueIsPlaying(true);
        })
        .catch(console.log)
        .finally(() => setTargetPreviewLoading(false));
    } else {
      await createTranslateWav({
        text: modalInfo.targetValue,
        targetLang,
        modelId: modalInfo.modelId,
        projectId: +projectId,
        voiceId: String(voiceId),
        tempo: 1.0,
        postId,
      })
        .then(res => {
          const audio = new Audio(getAudioFilePath(res));
          setScriptAudio(audio);
          audio.play();
          setTargetValueIsPlaying(true);
        })
        .catch(console.log)
        .finally(() => setTargetPreviewLoading(false));
    }
  }, [targetValueIsPlaying, modalInfo]);

  useEffect(() => {
    if (!textareaRef.current) return;
    textareaRef.current.value = scriptValue;

    let displayedLang;
    if (notSetLang) {
      displayedLang = getLanguageByValue(notSetLang);
    } else if (selectedHumanLang) {
      displayedLang = getLanguageByValue(selectedHumanLang);
    } else {
      displayedLang = getLanguageByValue(projectLang);
    }
    setModalInfo(prev => ({
      ...prev,
      currentLangName: displayedLang,
    }));
  }, []);

  useEffect(() => {
    if (scriptAudio !== null) {
      scriptAudio.onended = () => {
        setTargetValueIsPlaying(false);
        setCurrentValueIsPlaying(false);
        setScriptAudio(null);
      };
    }
  }, [scriptAudio]);

  const handleTranslateClick = async (attTargetLang: LanguageValueType) => {
    setLoading(true);
    setTargetLang(attTargetLang);

    await createTranslate({
      text: modalInfo.currentValue,
      voiceId: 10,
      currentLang: notSetLang || selectedHumanLang,
      targetLang: attTargetLang,
    })
      .then(({ text }) =>
        setModalInfo({
          ...modalInfo,
          targetLang: getLanguageByValue(attTargetLang),
          targetValue: text,
        })
      )
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  const translateText = debounce(e => {
    if (!targetLang) {
      return;
    }
    setLoading(true);
    createTranslate({
      text: e.target.value,
      voiceId: 10,
      currentLang: selectedHumanLang,
      targetLang,
    })
      .then(({ text }) =>
        setModalInfo(prev => ({ ...prev, targetValue: text }))
      )
      .finally(() => setLoading(false));
  }, 500);

  return (
    <div className="bg-white max-w-7xl w-full h-[440px] z-50 p-8 py-6 space-y-1 shadow-2xl rounded-xl fixed top-[30%] left-[60%] translate-x-[-60%] flex flex-col justify-between">
      <h1 className="text-left font-semibold text-xl mb-4">
        Scene
        <span className="ml-1">
          {!selectedBlock.groupIndex
            ? ''
            : selectedBlock.groupIndex < 10
            ? `0${selectedBlock.groupIndex + 1}`
            : selectedBlock.groupIndex + 1}
        </span>
      </h1>
      <div className="flex justify-between">
        <div className="relative space-y-2 w-[48%]">
          <div className="flex flex-col p-4 space-y-2">
            <span className="text-left">{modalInfo.currentLangName}</span>
            <textarea
              disabled={!targetLang}
              spellCheck="false"
              maxLength={350}
              ref={textareaRef}
              onChange={translateText}
              className={`border ${
                targetLang ? 'text-black' : '!text-zinc-400'
              } border-black rounded-xl resize-none h-40 p-3`}
            />
            <div className="flex !justify-end items-center space-x-2">
              {currentValueIsPlaying ? (
                <button
                  className="p-2 rounded-full bg-[#7b61ff] hover:opacity-50 duration-100 ease-out"
                  onClick={handleCurrentPreviewClick}>
                  <StopIcon />
                </button>
              ) : (
                <button
                  className={`p-2 rounded-full ${
                    previewLoading
                      ? 'bg-none absolute right-16'
                      : 'bg-[#ededed] hover:opacity-50 duration-100 ease-out'
                  }`}
                  onClick={previewLoading ? null : handleCurrentPreviewClick}>
                  {previewLoading ? (
                    <Loader width={50} height={50} />
                  ) : (
                    <PlayIcon />
                  )}
                </button>
              )}
              <span className="text-zinc-400 text-sm">
                {`${textareaRef.current.value?.length}/350 ${t('자')}`}
              </span>
            </div>
          </div>
        </div>
        <div className="absolute right-1/2 translate-x-1/2 bottom-1/2 translate-y-1/2">
          {loading ? <Loader width={120} height={120} /> : <ArrowRightSmall />}
        </div>
        <div className="w-[48%]">
          <div className="flex flex-col p-4 space-y-2">
            <div className="flex justify-between">
              <span className="text-left">{modalInfo.targetLang}</span>
              <LanguageSelectBox handleTranslateClick={handleTranslateClick} />
            </div>
            <textarea
              disabled={!targetLang}
              spellCheck="false"
              maxLength={350}
              className="border border-black rounded-xl resize-none h-40 p-3"
              value={modalInfo.targetValue}
              onChange={e =>
                setModalInfo(prev => ({
                  ...prev,
                  targetValue: e.target.value,
                }))
              }
            />
            <div className="flex !justify-end items-center space-x-2">
              {targetValueIsPlaying ? (
                <button
                  className="p-2 rounded-full bg-[#7b61ff] hover:opacity-50 duration-100 ease-out"
                  onClick={handleTargetPreviewClick}>
                  <StopIcon />
                </button>
              ) : (
                <button
                  className={`p-2 rounded-full ${
                    targetPreviewLoading
                      ? 'bg-none absolute right-[92px]'
                      : 'bg-[#ededed] hover:opacity-50 duration-100 ease-out'
                  }`}
                  onClick={
                    targetPreviewLoading ? null : handleTargetPreviewClick
                  }>
                  {targetPreviewLoading ? (
                    <Loader width={50} height={50} />
                  ) : (
                    <PlayIcon />
                  )}
                </button>
              )}
              <span className="text-right text-zinc-400 text-sm">
                {modalInfo.targetValue?.length}/350자
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center space-x-4">
        <button
          disabled={loading}
          onClick={() => {
            closeModal();
            scriptAudio.pause();
            setTargetValueIsPlaying(false);
            setCurrentValueIsPlaying(false);
          }}
          className={`bg-zinc-300 py-3 rounded-xl w-[20%] hover:bg-zinc-200 duration-100 ease-out tracking-widest font-medium ${
            loading && 'cursor-not-allowed'
          }`}>
          {t('닫기')}
        </button>
        <button
          onClick={() => {
            getScriptValue(modalInfo.targetValue);
            getTargetLang(targetLang);
            closeModal();
          }}
          disabled={loading || !modalInfo.targetValue}
          className={`text-white py-3 rounded-xl w-[40%] duration-100 ease-out tracking-widest font-medium ${
            loading || !modalInfo.targetValue
              ? 'bg-gray-200 cursor-not-allowed'
              : 'bg-[#8d77fc] hover:bg-[#9f8cfd]'
          }`}>
          {t('스크립트 적용')}
        </button>
      </div>
    </div>
  );
}

function LanguageSelectBox({ handleTranslateClick }) {
  const [languageSelect, setLanguageSelect] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      className={`script-select select-154 ${
        languageSelect && 'active'
      } absolute right-[50px] top-[96px]`}
      onClick={() => {
        setLanguageSelect(!languageSelect);
      }}>
      <img src={TranslationIcon} alt="" />
      <p>{t('언어 선택')}</p>
      <img
        src={ScriptArrow}
        alt="arrow for selecting script"
        className="scriptArrow"
      />
      {languageSelect && (
        <div className="script-wrap w-[130%] text-left h-60 overflow-y-auto">
          <ul>
            {langArray.map((list, idx) => (
              <li key={`list-${idx}`}>
                <button
                  className="px-2 w-full justify-start"
                  type="button"
                  onClick={() => {
                    handleTranslateClick(list.arg);
                  }}>
                  <img
                    src={list.flag}
                    alt="country flag"
                    className="countryIcon mr-2 !w-6"
                  />
                  {t(list.lang)}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
