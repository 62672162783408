import { Listbox, Transition } from '@headlessui/react';
import { ChevronDown } from 'heroicons-react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MediaParamType } from '../../../types_new/project/types';
import { stringify } from '../../../utils/common';

const array = [
  {
    label: '전체 미디어',
    value: {
      isMine: '1',
      isPurchased: '1',
    },
  },
  {
    label: '구매한 미디어',
    value: {
      isMine: '0',
      isPurchased: '1',
    },
  },
  {
    label: '기본 미디어',
    value: {
      isMine: '0',
      isPurchased: '0',
    },
  },
  {
    label: '업로드한 미디어',
    value: {
      isMine: '1',
      isPurchased: '0',
    },
  },
];

type Props = {
  loading: boolean;
  params: MediaParamType;
  setParams: Dispatch<SetStateAction<MediaParamType>>;
};

export default function SourceSelectLisbBox({
  loading,
  setParams,
  params,
}: Props) {
  const handleOnChange = ({ value }: string | any) => setParams(value);
  const { t } = useTranslation();
  const selectedMedia = array.find(
    item => stringify(item.value) === stringify(params)
  )?.label;

  const changeStatus = (label: string) => {
    switch (label) {
      case '전체 미디어':
        setParams({
          isMine: '1',
          isPurchased: '1',
        });
        break;
      case '기본 미디어':
        setParams({
          isMine: '0',
          isPurchased: '0',
        });
        break;
      case '구매한 미디어':
        setParams({
          isMine: '0',
          isPurchased: '1',
        });
        break;
      case '업로드한 미디어':
        setParams({
          isMine: '1',
          isPurchased: '0',
        });
        break;
      default:
        break;
    }
  };

  return (
    <Listbox value={params} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative w-36">
          <Listbox.Button
            aria-disabled={loading}
            className="w-full flex justify-between items-center px-[14px] text-[#666] text-sm focus:outline-none ring-offset-0">
            <span className="line-clamp-1">{t(selectedMedia)}</span>

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[14px]">
              <ChevronDown
                className={`h-5 w-5 text-gray-400 duration-150 ${
                  open && 'rotate-180'
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="ease-in-out duration-150"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4">
            <Listbox.Options className="no-scroll absolute mt-1  w-full shadow-2xl overflow-auto rounded-md p-1 text-base ring-1 ring-[#e9e9e9] focus:outline-none sm:text-sm bg-white z-20">
              {array?.map((option, idx) => {
                const isActive = params === option.value;

                return (
                  <Listbox.Option
                    key={`type-${idx}`}
                    className={({ active }) =>
                      `relative flex items-center cursor-pointer select-none px-[14px] py-2 2xl:py-2.5 rounded-md duration-75 ease-out text-black ${
                        active && 'bg-[#F8F7FF]'
                      }`
                    }
                    value={option}>
                    <span
                      className={`block truncate text-[13px] 2xl:text-sm ${
                        isActive ? 'text-primary' : 'text-[#666666]'
                      }`}>
                      {t(option.label)}
                    </span>
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
