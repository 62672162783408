import AmericaIcon from '../images/metahuman/icon-america.svg';
import ChinaIcon from '../images/metahuman/icon-china.svg';
import CzechIcon from '../images/metahuman/icon-czechsvg.svg';
import DenmarkIcon from '../images/metahuman/icon-denmark.svg';
import GermanyIcon from '../images/metahuman/icon-germany.svg';
import IndiaIcon from '../images/metahuman/icon-india.svg';
import IndonesiaIcon from '../images/metahuman/icon-indonesia.svg';
import ItalyIcon from '../images/metahuman/icon-italy.svg';
import JapanIcon from '../images/metahuman/icon-japan.svg';
import KoreaIcon from '../images/metahuman/icon-korea.svg';
import NetherlandIcon from '../images/metahuman/icon-netherland.svg';
import PhillippineIcon from '../images/metahuman/icon-phillippine.svg';
import PolandIcon from '../images/metahuman/icon-poland.svg';
import PortugalIcon from '../images/metahuman/icon-portugal.svg';
import RussiaIcon from '../images/metahuman/icon-russia.svg';
import TurkeyIcon from '../images/metahuman/icon-turkey.svg';
import FranceIcon from '../images/metahuman/lang_french.png';
import SpainIcon from '../images/metahuman/lang_spanish.png';
import VietnamIcon from '../images/metahuman/lang_vietnamese.png';

export const langArray = [
  {
    arg: 'en',
    lang: '영어 (English)',
    flag: AmericaIcon,
  },
  {
    arg: 'ko',
    lang: '한국어 (Korean)',
    flag: KoreaIcon,
  },
  {
    arg: 'zh-cn',
    lang: '중국어 (Chinese)',
    flag: ChinaIcon,
  },
  {
    arg: 'ja',
    lang: '일본어 (Japanese)',
    flag: JapanIcon,
  },
  {
    arg: 'vi',
    lang: '베트남어 (Vietnamese)',
    flag: VietnamIcon,
  },
  {
    arg: 'fr',
    lang: '프랑스어 (French)',
    flag: FranceIcon,
  },
  {
    arg: 'es',
    lang: '스페인어 (Spanish)',
    flag: SpainIcon,
  },
  {
    arg: 'cs',
    lang: '체코어 (Czech)',
    flag: CzechIcon,
  },
  {
    arg: 'da',
    lang: '덴마크어 (Danish)',
    flag: DenmarkIcon,
  },
  {
    arg: 'de',
    lang: '독일어 (German)',
    flag: GermanyIcon,
  },
  {
    arg: 'fil',
    lang: '필리핀어 (Phillippines)',
    flag: PhillippineIcon,
  },
  {
    arg: 'hi',
    lang: '힌디어 (Hindi)',
    flag: IndiaIcon,
  },
  {
    arg: 'id',
    lang: '인도네시아어 (Indonesian)',
    flag: IndonesiaIcon,
  },
  {
    arg: 'it',
    lang: '이탈리아어 (Italy)',
    flag: ItalyIcon,
  },
  {
    arg: 'nl',
    lang: '네덜란드어 (Netherlands)',
    flag: NetherlandIcon,
  },
  {
    arg: 'pl',
    lang: '폴란드어 (Polish)',
    flag: PolandIcon,
  },
  {
    arg: 'pt',
    lang: '포르투갈어 (Portuguese)',
    flag: PortugalIcon,
  },
  {
    arg: 'ru',
    lang: '러시아어 (Russian)',
    flag: RussiaIcon,
  },
  {
    arg: 'tr',
    lang: '터키어 (Turkish)',
    flag: TurkeyIcon,
  },
];
