import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import QuestionMarkIcon from '../../../svg/question-mark-icon';

type Props = {
  reason: string;
};

export default function ReasonPop({ reason }: Props) {
  return (
    <Popover className="relative flex">
      <Popover.Button className="focus:outline-none ring-offset-0 ring-0 group">
        <QuestionMarkIcon />
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-150"
        enterFrom="opacity-0  translate-y-1.5"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0  translate-y-1.5">
        <Popover.Panel className="w-40 absolute z-10 -top-14 right-1/2 translate-x-1/2 px-3 py-4 overflow-hidden rounded-lg shadow-md bg-white">
          <p className="font-medium text-xs">{reason}</p>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
