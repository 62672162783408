/* eslint-disable */
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import '../../locale/i18n';
import { block } from '../../store/block';
import {
  additionalTarget,
  autoScript as autoScriptArray,
  markerPos,
  rails,
  setSegmentsInfo,
  pages as storedPages,
  segmentsItem as storedSegmentItem,
} from '../../store/pages';
import ColorPicker from '../color-picker';
import AlignCenterIcon from '../svg/AlignCenterIcon';
import AlignLeftIcon from '../svg/AlignLeftIcon';
import AlignRightIcon from '../svg/AlignRightIcon';
import BoldIcon from '../svg/BoldIcon';
import ColorPickerIcon from '../svg/ColorPickerIcon';
import FontSizeIcon from '../svg/FontSizeIcon';
import ItalicIcon from '../svg/ItalicIcon';
import LetterSpacingIcon from '../svg/LetterSpacingIcon';
import LineHeightIcon from '../svg/LineHeightIcon';
import OutlinedIcon from '../svg/OutlinedIcon';
import OverlineIcon from '../svg/OverlineIcon';
import StrikeThroughIcon from '../svg/StrikeThroughIcon';
import UnderlineIcon from '../svg/UnderlineIcon';

const init = {
  fontFamilyDropdown: false,
  fontFamily: 'Arial',
  fontWeightDropdown: false,
  fontWeight: 'Regular',
  fontSize: 16,
  lineHeight: 1,
  letterSpacing: 0,
  posX: 0,
  posY: 0,
  color: 'red',
  textAlign: 'left',
  textDecoration: '',
  fontStyle: false,
  zIndex: 0,
  bold: false,
  text: '',
};

const TextModal = ({ active, setActive, projectInfo, disabled }: any) => {
  const { t } = useTranslation();
  const [segmentsItems, setSegmentsItems] = useRecoilState(storedSegmentItem);
  const [addTarget, setAddTarget] = useRecoilState(additionalTarget);
  const [railArray, setRailArray] = useRecoilState(rails);
  const [markerX, setMarkerX] = useRecoilState(markerPos);
  const [selectBlock, setSelectBlock] = useRecoilState(block);

  const ref = useRef(null);
  const White = {
    border: '1px solid #e9e9e9',
    backgroundColor: '#fff',
  };
  const Red = {
    backgroundColor: '#ed6c74',
  };
  const Orange = {
    backgroundColor: '#ec7e56',
  };
  const Green = {
    backgroundColor: '#87cd9d',
  };
  const Blue = {
    backgroundColor: '#4481f7',
  };
  const Purple = {
    backgroundColor: '#6f5df6',
  };
  const Yellow = {
    backgroundColor: '#fadf7b',
  };
  const Black = {
    backgroundColor: '#000',
  };
  const [pages, setPages] = useRecoilState(storedPages) as any;
  const [titleShow, setTitleShow] = useState(0);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [textInfo, setTextInfo] = useState<any>(init);
  const [explanation, setExplanation] = useState(false);
  const insertTime = useMemo(() => {
    return pages[0]?.segments[0]?.segment[selectBlock.groupIndex]?.insertTime;
  }, [markerX, pages[0]?.segments[0]?.segment[0]?.insertTime]);

  const handleTextInfoTop = (e: any) => {
    setTextInfo({
      ...textInfo,
      [e.target.name]: e.target.textContent,
      fontFamilyDropdown: false,
      fontWeightDropdown: false,
    });
  };

  // test
  useEffect(() => {
    if (Object.keys(selectBlock).length !== 0) {
      if (selectBlock.segmentsGroup === 'title') {
        const data =
          pages[0]?.segments[0][selectBlock.segmentsGroup][
            selectBlock.groupIndex
          ];
        setTextInfo({
          ...textInfo,
          posX: data?.posX,
          posY: data?.posY,
        });
      }
    }
  }, [pages]);

  const UniqueId = useMemo(() => {
    return pages[0]?.segments[0]?.segment[selectBlock.groupIndex]?.indexKey;
  }, [markerX, pages[0]?.segments[0]?.segment[0]?.insertTime]);

  const handleTextInfoMiddle = (e: any) => {
    const { name, value } = e.target;
    setTextInfo({
      ...textInfo,
      [name]: value,
    });
  };

  // 텍스트 추가
  const addTitle = (fontSize: any) => {
    const tmpPage = JSON.parse(JSON.stringify(pages));
    let duration = 200;
    let insertT = 0;
    let railIdx = 0;

    if (addTarget.name === 'marker') {
      const targetMeta = tmpPage[0]?.segments[0]?.segment?.filter(
        item =>
          item?.insertTime <= addTarget?.insertT &&
          item?.insertTime + item?.duration >= addTarget?.insertT
      )[0];

      const targetArray = [
        ...tmpPage[0]?.segments[0]?.image,
        ...tmpPage[0]?.segments[0]?.title,
        ...tmpPage[0]?.segments[0]?.segment?.filter(
          item => item.mediaType === 'video' || item.mediaType === 'audio'
        ),
      ]?.filter(item => {
        return (
          item.insertTime + item.duration - targetMeta.insertTime > 0 &&
          item.insertTime - targetMeta.insertTime < targetMeta.duration
        );
      });

      let railIndex = 0;
      targetArray?.map(item => {
        if (item.railIndex > railIndex) {
          railIndex = item.railIndex;
        }
      });

      duration = targetMeta?.duration;
      insertT = targetMeta?.insertTime;
      railIdx = targetArray.length === 0 ? 0 : railIndex + 1;
    } else {
      const targetArray = tmpPage[0]?.segments[0]?.segment?.filter(item => {
        return (
          item.insertTime + item.duration - addTarget?.insertT?.insertTime >
            0 &&
          item.insertTime - addTarget?.insertT?.insertTime <
            addTarget?.insertT?.duration
        );
      });

      const maxArray = targetArray?.reduce((pre, cur) => {
        return pre.insertTime > cur.insertTime ? pre : cur;
      });

      const targetMHArray = [
        ...tmpPage[0]?.segments[0]?.image,
        ...tmpPage[0]?.segments[0]?.title,
        ...tmpPage[0]?.segments[0]?.segment?.filter(
          item => item.mediaType === 'video' || item.mediaType === 'audio'
        ),
      ]?.filter(item => {
        return (
          item.insertTime + item.duration - maxArray.insertTime > 0 &&
          item.insertTime - maxArray.insertTime < maxArray.duration
        );
      });
      let railIndex = 0;
      targetMHArray?.map(item => {
        if (item.railIndex > railIndex) {
          railIndex = item.railIndex;
        }
      });

      duration = maxArray.length ? maxArray?.duration : 200;
      if (insertTime === undefined) {
        insertT = 0;
      } else {
        insertT = insertTime;
      }
      railIdx = targetMHArray.length === 0 ? 0 : railIndex + 1;
    }

    const id = Date.now();
    setTextInfo(init);
    setSegmentsInfo(pages, setPages, {
      pagesIndex: 0,
      title: {
        titleArray: [
          ...tmpPage[0].segments[0].title,
          {
            titleId: id,
            text: '',
            insertTime: insertT,
            duration: duration,
            rank: 0,
            style: {
              fontFamilyDropdown: false,
              fontFamily: 'Arial',
              fontWeightDropdown: false,
              fontWeight: 'Regular',
              fontSize: fontSize,
              lineHeight: 1.0,
              letterSpacing: 0,
              posX: 0,
              posY: 0,
              color: 'red',
              backgroundColor: '',
              textAlign: 'left',
              textDecoration: '',
              fontStyle: false,
              zIndex: 0,
              bold: false,
            },
            posX: 0,
            posY: 0,
            indexKey: UniqueId,
            fadeIn: 0,
            fadeOut: 0,
            zIndex: 0,
            railIndex: railIdx,
          },
        ],
      },
    });

    if (railArray?.filter(item => item.type === 'clip')?.length === railIdx) {
      setRailArray([...railArray, { type: 'clip' }]);
    }
    setSegmentsItems({ id: id, type: 'clip' });
    setSelectBlock({
      segmentsGroup: 'title',
      groupIndex: tmpPage[0]?.segments[0]?.title?.length || 0,
    });
  };

  // 텍스트 수정
  const changeTitle = (e: any, style: boolean, textContent: boolean) => {
    const tmpPage = JSON.parse(JSON.stringify(pages));
    if (style && !textContent) {
      tmpPage[0].segments[0].title[selectBlock.groupIndex] = {
        ...tmpPage[0]?.segments[0]?.title[selectBlock.groupIndex],
        style: {
          ...tmpPage[0].segments[0].title[selectBlock.groupIndex].style,
          [e.currentTarget.name]: e.currentTarget.value,
        },
      };
      setPages(tmpPage);
      setTextInfo({
        ...textInfo,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else if (!style && !textContent) {
      tmpPage[0].segments[0].title[selectBlock.groupIndex] = {
        ...tmpPage[0].segments[0].title[selectBlock.groupIndex],
        [e.currentTarget.name]: e.currentTarget.value,
      };
      setPages(tmpPage);
      setTextInfo({
        ...textInfo,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    } else {
      tmpPage[0].segments[0].title[selectBlock.groupIndex] = {
        ...tmpPage[0].segments[0].title[selectBlock.groupIndex],
        style: {
          ...tmpPage[0].segments[0].title[selectBlock.groupIndex].style,
          [e.currentTarget.name]: e.currentTarget.textContent,
        },
      };
      setPages(tmpPage);
      setTextInfo({
        ...textInfo,
        [e.currentTarget.name]: e.currentTarget.textContent,
      });
    }
  };

  const addNewElement = () => {
    setTitleShow(0);
    setTextInfo(init);
    setSelectBlock({});
  };

  useEffect(() => {
    if (selectBlock.segmentsGroup === 'title') {
      setActive(4);
      setTitleShow(1);
      setTextInfo({
        ...pages[0].segments[0][selectBlock.segmentsGroup][
          selectBlock.groupIndex
        ].style,
        text: pages[0].segments[0][selectBlock.segmentsGroup][
          selectBlock.groupIndex
        ].text,
      });
    } else {
      setTitleShow(0);
    }
  }, [selectBlock]);

  const [autoScript, setAutoScript] = useRecoilState(autoScriptArray);
  const [rail, setRail] = useRecoilState(rails);

  const goToMiddle = (textLength: number, width: any) => {
    const canvasWidthRate = projectInfo?.canvasSize?.split(':')[0];
    const canvasHeightRate = projectInfo?.canvasSize?.split(':')[1];
    const canvasWidth = (576 / canvasHeightRate) * canvasWidthRate;
    const defaultRate =
      projectInfo?.canvasSize === '9:16'
        ? 576 / 1920
        : projectInfo?.canvasSize === '10:16'
        ? 576 / 1920
        : 576 / 1080;

    console.log('canvasWidth', canvasWidth);
    console.log('ddwidth', width);
    // return ((canvasWidth / 2 - (textLength / 2) * 14) / canvasWidth) * 100;
    return ((canvasWidth / 2 - width / 2) / canvasWidth) * 100;
  };

  const scriptToText = () => {
    const clipRailNum = rail.filter(item => item.type === 'clip').length;
    const tmpAutoScript = [...autoScript];

    const sortedArray = tmpAutoScript.filter(item => {
      let result = true;

      pages[0]?.segments[0]?.title?.map(element => {
        if (element.text === item.text) {
          result = false;
        }
      });

      return result;
    });

    const titleElement = [...pages[0].segments[0].title];

    const array = [];

    let insertT = pages[0].segments[0].segment?.filter(
      item => item.mediaType === 'metahuman'
    )[0].insertTime;

    if (sortedArray.length !== 0) {
      setRail([...rail, { type: 'clip' }]);
    }

    sortedArray?.map((item: any, index: number) => {
      ref.current.innerHTML = item.text;
      ref.current.innerText = item.text;
      let width = ref.current.clientWidth + 1 + 4;

      item.segmentId;
      array.push({
        titleId: Math.ceil(Math.random() * 9999999999999),
        text: item.text,
        insertTime: Math.floor(insertT),
        duration: Math.floor((item.endTime - item.startTime) * 100),
        rank: 0,
        style: {
          fontFamilyDropdown: false,
          fontFamily: 'Arial',
          fontWeightDropdown: false,
          fontWeight: 'Bold',
          fontSize: 16,
          lineHeight: 1.0,
          letterSpacing: 0,
          posX: 0,
          posY: 0,
          color: '#FFFFFF',
          backgroundColor: '',
          textAlign: 'left',
          textDecoration: '',
          fontStyle: false,
          zIndex: 20000,
          bold: false,
        },
        posX: goToMiddle(item.text.length, width),
        posY: 90,
        metaData: { script: true },
        indexKey: UniqueId,
        fadeIn: 0,
        fadeOut: 0,
        zIndex: 20000,
        railIndex: clipRailNum,
      });
      insertT += Math.floor((item.endTime - item.startTime) * 100);
    });

    const type = {
      pagesIndex: 0,
      title: {
        titleArray: [...titleElement, ...array],
      },
    };
    setSegmentsInfo(pages, setPages, type);
  };

  const textList = [
    'Arial',
    'Bahnschrift',
    // 'Batang',
    // 'Calibri',
    // 'Cambria',
    // 'Candara',
    'Comic Sans MS',
    // 'Consolas',
    // 'Constantia',
    // 'Corbel',
    'Courier New',
    'Dotum',
    // 'Ebrima',
    // 'Franklin Gothic Medium',
    // 'Gabriola',
    // 'Gadugi',
    'Georgia',
    'Gulim',
    // 'Gungsuh',
    'Impact',
    // 'Ink Free',
    // 'Lucida Console',
    // 'Lucida Sans Unicode',
    // 'Malgun Gothic',
    // 'Marlett',
    // 'Nirmala UI',
    'Segoe UI',
    'Tahoma',
    'Times New Roman',
    'Verdana',
    // 'MaruBuri',
    // 'NanumSquare',
    // 'NanumSquareRound',
    'NanumMyeongjo',
    'NanumGothic',
    // 'NanumBarunpen',
    // 'NanumBarunGothic',
    'Nanum Brush Script',
    'Nanum Pen Script',
  ];

  useEffect(() => {
    if (Object.keys(selectBlock).length !== 0) {
      if (selectBlock.segmentsGroup === 'title') {
        const data = pages[0]?.segments[0]?.title[selectBlock.groupIndex];
        setTextInfo({
          ...textInfo,
          posX: data?.posX ? data?.posX : 0,
          posY: data?.posY ? data?.posY : 0,
        });
      }
    }
  }, [pages]);

  const changeLineHeight = () => {
    const tmpPage = JSON.parse(JSON.stringify(pages));
    tmpPage[0].segments[0].title[selectBlock.groupIndex] = {
      ...tmpPage[0]?.segments[0]?.title[selectBlock.groupIndex],
      style: {
        ...tmpPage[0].segments[0].title[selectBlock.groupIndex].style,
        lineHeight: 1,
      },
    };
    setPages(tmpPage);
  };

  return (
    <>
      <li>
        <button
          disabled={disabled}
          className={active == 4 ? 'active' : 'disabled:cursor-not-allowed'}
          onClick={() => {
            setActive(4);
            setSelectBlock({});
          }}>
          <i className="style__text__icon" />
          <span>{t('텍스트')}</span>
        </button>
        <div className="text h-full max-h-[600px]" style={{ height: '570px' }}>
          <div className="title">
            <h2>{t('텍스트')}</h2>
            <button
              className="close__btn close__gray__icon"
              onClick={() => {
                setActive(0);
              }}
            />
          </div>

          <div className="content">
            {titleShow === 0 ? (
              <div className="text__content show">
                <div className="text_group">
                  <button
                    onClick={() => {
                      setTextInfo({ ...textInfo, fontSize: 26 });
                      setTitleShow(1);
                      addTitle(26);
                    }}>
                    {t('제목 텍스트')}
                    <span>26px</span>
                  </button>
                  <button
                    onClick={() => {
                      setTextInfo({ ...textInfo, fontSize: 20 });
                      setTitleShow(1);
                      addTitle(20);
                    }}>
                    {t('부제목 텍스트')}
                    <span>20px</span>
                  </button>
                  <button
                    onClick={() => {
                      setTextInfo({ ...textInfo, fontSize: 16 });
                      setTitleShow(1);
                      addTitle(16);
                    }}>
                    {t('본문 텍스트')}
                    <span>16px</span>
                  </button>
                </div>

                <button className="script_btn" onClick={scriptToText}>
                  {t('스크립트로 자막 자동 생성')}
                  <div
                    className="script_btn_icon"
                    onClick={e => {
                      e.stopPropagation();
                      setExplanation(!explanation);
                    }}
                  />
                  {explanation && (
                    <div className="script-select-desc">
                      <span>
                        {t(
                          '스크립트에 있는 내용으로 텍스트를 자동으로 생성하고 자막으로 활용할 수 있습니다.'
                        )}
                      </span>
                    </div>
                  )}
                </button>
              </div>
            ) : titleShow === 1 ? (
              <div className="text__content show">
                <textarea
                  className="text__textarea"
                  value={textInfo.text}
                  name="text"
                  style={{
                    fontWeight:
                      textInfo?.bold === true
                        ? '800'
                        : textInfo?.fontWeight === 'Thin'
                        ? '100'
                        : textInfo.fontWeight === 'Light'
                        ? '300'
                        : textInfo?.fontWeight === 'Regular'
                        ? '400'
                        : textInfo?.fontWeight === 'Medium'
                        ? '500'
                        : textInfo?.fontWeight === 'Bold'
                        ? '700'
                        : textInfo?.fontWeight === 'Black'
                        ? '900'
                        : '',
                    color: '#111',
                    // color: textInfo?.color,
                    fontFamily: textInfo?.fontFamily,
                    fontSize: `${textInfo?.fontSize}px`,
                    fontStyle: textInfo?.fontStyle ? 'italic' : 'normal',
                    letterSpacing: `${textInfo?.letterSpacing}px`,
                    textAlign:
                      textInfo?.textAlign === 'left'
                        ? 'left'
                        : textInfo?.textAlign === 'center'
                        ? 'center'
                        : textInfo?.textAlign === 'right'
                        ? 'right'
                        : 'left',
                    textDecoration:
                      textInfo?.textDecoration === 'strikethrough'
                        ? 'line-through'
                        : textInfo?.textDecoration,
                    lineHeight: textInfo?.lineHeight,
                  }}
                  onChange={e => {
                    changeTitle(e, false, false);
                    handleTextInfoMiddle(e);
                  }}
                />
                <div className="col flex justifyBetween">
                  <div className="select__cont">
                    <span>{t('서체')}</span>
                    <div className="select select_st2 fontFamily__select">
                      <button
                        className={`select__open ${
                          textInfo.fontFamilyDropdown && 'active'
                        }`}
                        onClick={() =>
                          setTextInfo({
                            ...textInfo,
                            fontFamilyDropdown: !textInfo.fontFamilyDropdown,
                            fontWeightDropdown: false,
                          })
                        }>
                        <span className="default">{textInfo.fontFamily}</span>
                        <i className="select__black__icon" />
                      </button>
                      <ul className="select__list">
                        {textList.map((item: any, idx: number) => (
                          <li key={idx}>
                            <button
                              className="select__btn"
                              name="fontFamily"
                              onClick={e => {
                                changeTitle(e, true, true);
                                handleTextInfoTop(e);
                              }}>
                              {item}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="select__cont">
                    <span>{t('두께')}</span>
                    <div className="select select_st2 fontWeight__select">
                      <button
                        className={`select__open ${
                          textInfo.fontWeightDropdown && 'active'
                        }`}
                        onClick={() =>
                          setTextInfo({
                            ...textInfo,
                            fontWeightDropdown: !textInfo.fontWeightDropdown,
                            fontFamilyDropdown: false,
                          })
                        }>
                        <span className="default">{textInfo.fontWeight}</span>
                        <i className="select__black__icon" />
                      </button>
                      <ul className="select__list font-select__list">
                        {/* <li>
                          <button
                            className="select__btn"
                            name="fontWeight"
                            onClick={e => {
                              changeTitle(e, true, true);
                              handleTextInfoTop(e);
                            }}>
                            Thin
                          </button>
                        </li> */}
                        {/* <li>
                          <button
                            className="select__btn"
                            name="fontWeight"
                            onClick={e => {
                              changeTitle(e, true, true);
                              handleTextInfoTop(e);
                            }}>
                            Light
                          </button>
                        </li> */}
                        <li>
                          <button
                            className="select__btn"
                            name="fontWeight"
                            onClick={e => {
                              changeTitle(e, true, true);
                              handleTextInfoTop(e);
                            }}>
                            Regular
                          </button>
                        </li>
                        {/* <li>
                          <button
                            className="select__btn"
                            name="fontWeight"
                            onClick={e => {
                              changeTitle(e, true, true);
                              handleTextInfoTop(e);
                            }}>
                            Medium
                          </button>
                        </li> */}
                        <li>
                          <button
                            className="select__btn"
                            name="fontWeight"
                            onClick={e => {
                              changeTitle(e, true, true);
                              handleTextInfoTop(e);
                            }}>
                            Bold
                          </button>
                        </li>
                        {/* <li>
                          <button
                            className="select__btn"
                            name="fontWeight"
                            onClick={e => {
                              changeTitle(e, true, true);
                              handleTextInfoTop(e);
                            }}>
                            Black
                          </button>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="font__style__cont">
                    <div className="flex">
                      <div className="font__style__kind">
                        <FontSizeIcon />
                      </div>
                      <input
                        min="1"
                        type="number"
                        className="fontSize__input"
                        value={textInfo.fontSize}
                        name="fontSize"
                        onChange={e => {
                          if (
                            e.target.value.length > 0 &&
                            +e.target.value < 1
                          ) {
                            alert('숫자는 0보다 커야 합니다.');
                            return;
                          }
                          handleTextInfoMiddle(e);
                          changeTitle(e, true, false);
                        }}
                      />
                    </div>
                    <div className="flex">
                      <div className="font__style__kind">
                        <LineHeightIcon />
                      </div>
                      <input
                        min="1"
                        type="number"
                        className="lineHeight__input"
                        value={textInfo?.lineHeight}
                        name="lineHeight"
                        step="0.1"
                        onKeyDown={e => {
                          if (e.key === '-') {
                            e.preventDefault();
                            return;
                          }
                        }}
                        onChange={e => {
                          if (
                            e.target.value.length > 0 &&
                            +e.target.value < 1
                          ) {
                            alert('숫자는 0보다 커야 합니다.');
                            return;
                          }
                          handleTextInfoMiddle(e);
                          changeTitle(e, true, false);
                        }}
                        onBlur={e => {
                          if (textInfo.lineHeight < 1) {
                            setTextInfo(prev => ({
                              ...prev,
                              lineHeight: 1,
                            }));
                            changeLineHeight();
                          }
                        }}
                      />
                    </div>
                    <div className="flex">
                      <div className="font__style__kind">
                        <LetterSpacingIcon />
                      </div>
                      <input
                        min="1"
                        type="number"
                        className="letterSpacing__input"
                        value={textInfo.letterSpacing}
                        name="letterSpacing"
                        onChange={e => {
                          if (
                            e.target.value.length > 0 &&
                            +e.target.value < 1
                          ) {
                            alert('숫자는 0보다 커야 합니다.');
                            return;
                          }
                          handleTextInfoMiddle(e);
                          changeTitle(e, true, false);
                        }}
                      />
                    </div>
                    <div className="flex">
                      <div className="font__style__kind">
                        <span>{t('상하')}</span>
                      </div>
                      <input
                        type="number"
                        className="y__input"
                        value={parseFloat(textInfo.posY).toFixed(0)}
                        name="posY"
                        onChange={e => {
                          handleTextInfoMiddle(e);
                          changeTitle(e, false, false);
                        }}
                      />
                    </div>
                    <div className="flex">
                      <div className="font__style__kind">
                        <span>{t('좌우')}</span>
                      </div>
                      <input
                        type="number"
                        className="x__input"
                        value={parseFloat(textInfo.posX).toFixed(0)}
                        name="posX"
                        onChange={e => {
                          handleTextInfoMiddle(e);
                          changeTitle(e, false, false);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="color__basic">
                    <div className="flex">
                      <span>Basic</span>
                      <p className="color__number">6</p>
                    </div>
                    <div className="color__list">
                      <button
                        className={`color__btn ${
                          textInfo.color === 'white' && 'active'
                        }`}
                        style={White}
                        value="white"
                        onClick={e => {
                          handleTextInfoMiddle(e);
                          changeTitle(e, true, false);
                        }}
                        name="color"
                      />
                      <button
                        className={`color__btn ${
                          textInfo.color === 'red' && 'active'
                        }`}
                        style={Red}
                        value="red"
                        onClick={e => {
                          handleTextInfoMiddle(e);
                          changeTitle(e, true, false);
                        }}
                        name="color"
                      />
                      <button
                        className={`color__btn ${
                          textInfo.color === 'orange' && 'active'
                        }`}
                        style={Orange}
                        value="orange"
                        onClick={e => {
                          handleTextInfoMiddle(e);
                          changeTitle(e, true, false);
                        }}
                        name="color"
                      />
                      <button
                        className={`color__btn ${
                          textInfo.color === 'yellow' && 'active'
                        }`}
                        style={Yellow}
                        value="yellow"
                        onClick={e => {
                          handleTextInfoMiddle(e);
                          changeTitle(e, true, false);
                        }}
                        name="color"
                      />
                      <button
                        className={`color__btn ${
                          textInfo.color === 'green' && 'active'
                        }`}
                        style={Green}
                        value="green"
                        onClick={e => {
                          handleTextInfoMiddle(e);
                          changeTitle(e, true, false);
                        }}
                        name="color"
                      />
                      <button
                        className={`color__btn ${
                          textInfo.color === 'blue' && 'active'
                        }`}
                        style={Blue}
                        value="blue"
                        onClick={e => {
                          handleTextInfoMiddle(e);
                          changeTitle(e, true, false);
                        }}
                        name="color"
                      />
                      <button
                        className={`color__btn ${
                          textInfo.color === 'purple' && 'active'
                        }`}
                        style={Purple}
                        value="purple"
                        onClick={e => {
                          changeTitle(e, true, false);
                          handleTextInfoMiddle(e);
                        }}
                        name="color"
                      />
                      <button
                        className={`color__btn ${
                          textInfo.color === 'black' && 'active'
                        }`}
                        style={Black}
                        value="black"
                        onClick={e => {
                          changeTitle(e, true, false);
                          handleTextInfoMiddle(e);
                        }}
                        name="color"
                      />
                    </div>
                  </div>
                  {/* TODO: remove w-fit after patch */}
                  <div className="font__style__list w-fit">
                    <button
                      // TODO: remove hidden property after patch
                      className={`hidden text__align__btn ${
                        textInfo.textAlign == 'left' && 'bg-white'
                      }`}
                      onClick={e => {
                        setTextInfo({ ...textInfo, textAlign: 'left' });
                        changeTitle(e, true, false);
                      }}
                      name="textAlign"
                      value="left">
                      <AlignLeftIcon />
                    </button>
                    <button
                      // TODO: remove hidden after patch
                      className={`hidden text__align__btn ${
                        textInfo.textAlign == 'center' && 'bg-white'
                      }`}
                      onClick={e => {
                        setTextInfo({ ...textInfo, textAlign: 'center' });
                        changeTitle(e, true, false);
                      }}
                      name="textAlign"
                      value="center">
                      <AlignCenterIcon />
                    </button>
                    <button
                      // TODO: remove hidden after patch
                      className={`hidden text__align__btn ${
                        textInfo.textAlign == 'right' && 'bg-white'
                      }`}
                      onClick={e => {
                        setTextInfo({ ...textInfo, textAlign: 'right' });
                        changeTitle(e, true, false);
                      }}
                      name="textAlign"
                      value="right">
                      <AlignRightIcon />
                    </button>
                    <div className="relative">
                      <button className="text__bold__btn">
                        <OutlinedIcon />
                      </button>
                      <div className="text__line__box">
                        <button
                          className={`text__line__btn ${
                            textInfo.textDecoration === 'overline' && 'active'
                          }`}
                          onClick={e => {
                            setTextInfo({
                              ...textInfo,
                              textDecoration: 'overline',
                            });
                            changeTitle(e, true, false);
                          }}
                          type="button"
                          name="textDecoration"
                          value={
                            textInfo.textDecoration === 'overline'
                              ? ''
                              : 'overline'
                          }>
                          <OverlineIcon />
                          <span>Overline</span>
                        </button>
                        <button
                          className={`text__line__btn ${
                            textInfo.textDecoration === 'underline' && 'active'
                          }`}
                          onClick={e => {
                            setTextInfo({
                              ...textInfo,
                              textDecoration: 'underline',
                            });
                            changeTitle(e, true, false);
                          }}
                          type="button"
                          name="textDecoration"
                          value={
                            textInfo.textDecoration === 'underline'
                              ? ''
                              : 'underline'
                          }>
                          <UnderlineIcon />
                          <span>underline</span>
                        </button>
                        <button
                          className={`text__line__btn ${
                            textInfo.textDecoration === 'strikethrough' &&
                            'active'
                          }`}
                          onClick={e => {
                            setTextInfo({
                              ...textInfo,
                              textDecoration: 'strikethrough',
                            });
                            changeTitle(e, true, false);
                          }}
                          type="button"
                          name="textDecoration"
                          value={
                            textInfo.textDecoration === 'strikethrough'
                              ? ''
                              : 'strikethrough'
                          }>
                          <StrikeThroughIcon />
                          <span>Strikethrough</span>
                        </button>
                      </div>
                    </div>

                    <button
                      className={`text__bold__btn ${
                        textInfo.fontWeight === 'Bold' && 'bg-white'
                      }`}
                      onClick={e => {
                        changeTitle(e, true, false);
                        if (textInfo.fontWeight === 'Bold') {
                          setTextInfo({ ...textInfo, fontWeight: 'Regular' });
                        } else {
                          setTextInfo({ ...textInfo, fontWeight: 'Bold' });
                        }
                      }}
                      name="fontWeight"
                      value={
                        textInfo.fontWeight === 'Bold' ? 'Regular' : 'Bold'
                      }>
                      <BoldIcon />
                    </button>
                    <button
                      className={`text__italic__btn ${
                        textInfo.fontStyle && 'bg-white'
                      }`}
                      onClick={e => {
                        changeTitle(e, true, false);
                        setTextInfo({
                          ...textInfo,
                          fontStyle: !textInfo.fontStyle,
                        });
                      }}
                      value={textInfo.fontStyle ? '' : 'italic'}
                      name="fontStyle">
                      <ItalicIcon />
                    </button>
                    <button
                      className="color__picker__btn active"
                      onClick={() => setIsColorPickerOpen(prev => !prev)}>
                      <ColorPickerIcon />
                    </button>
                  </div>
                </div>
                <ColorPicker
                  isOpen={isColorPickerOpen}
                  closePicker={() => setIsColorPickerOpen(false)}
                  selectedColor={textInfo.color}
                  changeTitle={changeTitle}
                />
              </div>
            ) : (
              ''
            )}

            {/* show 컨트롤 */}
          </div>
          {Object.keys(selectBlock).length !== 0 && (
            <button
              className="edit-new mt-8"
              style={{ position: 'static' }}
              onClick={addNewElement}>
              {t('새 텍스트 추가')}
            </button>
          )}
        </div>
      </li>
      {/* 자동 자막생성시 센터를 맞추기위해 텍스트의 너비값을 얻기위한 div */}
      <div className="width-tmpbox" ref={ref}></div>
    </>
  );
};

export default TextModal;
