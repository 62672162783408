type Props = {
  className?: string;
};

export default function Loader({ className }: Props) {
  return (
    <div
      className={`loader ease-linear rounded-full border-2 border-t-2 border-grayBorder border-t-primary border-l-primary w-5 h-5 animate-spin ${className}`}
    />
  );
}
