const arr = [1, 2, 3, 4, 5, 6];

export default function MetahumanSkeleton() {
  return (
    <ul className="grid grid-cols-3 gap-y-8 gap-x-7 max-h-72 2xl:max-h-80 h-full overflow-y-auto no-scroll px-1 pt-1.5 pb-4">
      {arr.map((_, idx) => (
        <div key={idx} className="flex flex-col space-y-2 2xl:space-y-2.5">
          <div className="w-full h-32 2xl:h-36 flex justify-center items-center rounded-[10px] bg-gray-200 animate-pulse">
            <Avatar />
          </div>
          <div className="flex flex-col space-y-1">
            <div className="w-1/3 h-1.5 bg-gray-400 rounded-full animate-pulse" />
            <div className="w-2/4 h-1 bg-gray-300 animate-pulse" />
          </div>
        </div>
      ))}
    </ul>
  );
}

function Avatar() {
  return (
    <svg
      className="w-10 h-10 me-3 text-slate-400"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
    </svg>
  );
}
