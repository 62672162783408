import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getLeftCredit } from '../../../networks/paysystem.service';
import { userInfoAtom } from '../../../networks/store/member';
import CoinIcon from '../../svg/coin-icon';
import BasketIcon from '../../vector/BasketIcon';
import SidebarNavigation from '../sidebar-navigation';
import AvatarPop from './avatar-pop';
import { getMainDir } from '../../../util/common';

export default function ConsoleSideBar() {
  const { t } = useTranslation();
  const { userName } = useRecoilValue(userInfoAtom);
  const [credit, setCredit] = useState(0);

  const getCreditLeftWithResData = (result: any) => {
    let totalCredit = 0;
    result?.map((item: any) => {
      totalCredit += item.credit;
    });
    return totalCredit;
  };

  const getPayCreditLeft = () => {
    getLeftCredit()
      .then(res => {
        if (res.status === 200) {
          setCredit(getCreditLeftWithResData(res?.data?.resultValue));
        }
      })
      .catch(console.error);
  };

  useEffect(() => getPayCreditLeft(), []);

  return (
    <aside className="sticky left-0 top-0 min-w-[182px] h-screen flex flex-col items-center justify-between bg-[#F1F4F8] py-11 px-6">
      <div>
        <Link to="/">
          {/* FIXME: fix logo for console */}
          <img src={getMainDir('/svg/dark-en-logo.svg')} alt="main logo" />
        </Link>
        <SidebarNavigation />
      </div>
      <div className="flex flex-col items-center space-y-8">
        <div className="flex items-center space-x-2.5">
          <AvatarPop />
          <div className="flex flex-col space-y-0.5 text-[#44505F]">
            <span>{userName}</span>
            <div className="flex items-center space-x-[5px]">
              <CoinIcon isSmall />
              <span className="text-sm">{credit.toLocaleString()}</span>
            </div>
          </div>
        </div>
        <Link to="/store">
          <button className="flex items-center space-x-2.5 group">
            <BasketIcon />
            <span className="text-[#44505F] text-[13px] group-hover:underline underline-offset-2">
              {t('상품 구매')}
            </span>
          </button>
        </Link>
      </div>
    </aside>
  );
}
