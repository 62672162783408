type Props = {
  isBig?: boolean;
};

export default function AudioPauseIcon({ isBig }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#7b61ff"
      viewBox="0 0 24 24"
      strokeWidth={`${isBig ? '3' : '5'}`}
      stroke="#7b61ff"
      className={`${isBig ? 'w-6 h-6' : 'w-4 h-4'}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.75 5.25v13.5m-7.5-13.5v13.5"
      />
    </svg>
  );
}
