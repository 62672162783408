import { useEffect } from 'react';
import usePath from '../../hooks/use-path';

export default function ScrollToTop() {
  const { pathname } = usePath();

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    const scrollTop = setTimeout(scrollToTop, 50);
    return () => {
      clearTimeout(scrollTop);
    };
  }, [pathname]);

  return null;
}
