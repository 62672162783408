import { useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <section className="bg-darkPrimary">
      <div className="container flex items-center justify-center text-center min-h-screen px-6 py-12 mx-auto">
        <div>
          <h1 className="mt-3 md:mt-6 font-semibold text-white text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl">
            찾으시는 페이지가 없습니다.
          </h1>
          <p className="mt-4 md:mt-8 text-gray1 text-sm md:text-xl text-white/80">
            죄송합니다. 찾으시는 페이지는 삭제되었거나 존재하지 않는
            페이지입니다.
          </p>

          <div className="flex items-center justify-center mt-6 md:mt-12 gap-x-3">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center justify-center w-1/2 px-8 py-5 max-md:px-5 max-md:py-3 md:text-xl text-white bg-primary hover:bg-hoverPrimary rounded-lg gap-x-2 max-md:w-auto group">
              <LeftArrowIcon />
              <span>뒤로가기</span>
            </button>

            <button
              onClick={() => navigate('/')}
              className="flex items-center justify-center gap-x-2 w-1/2 px-8 py-5 max-md:px-5 max-md:py-3 md:text-xl tracking-wide text-white rounded-lg shrink-0 max-md:w-auto border border-white hover:border-primary hover:text-primary duration-150 ease-out">
              <span>메인으로 가기</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

function LeftArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-7 h-7 max-md:w-5 max-md:h-5 group-hover:-translate-x-1 duration-150 ease-out">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
      />
    </svg>
  );
}
