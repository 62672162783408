import { ChangeEvent } from 'react';

type Props = {
  type?: 'text' | 'number';
  placeholder?: string;
  label: string;
  maxLength?: number;
  value: any;
  className?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
};

export default function TemplatePublishInput({
  type = 'text',
  placeholder = '',
  label,
  maxLength,
  value,
  className,
  name,
  disabled,
  onChange,
}: Props) {
  return (
    <label>
      <div className="flex items-start space-x-1 mt-2">
        <p className="text-left p-2 pl-0 text-[#353333] text-sm">{label}</p>
        <div className="w-1 h-1 rounded-full bg-primary translate-y-2 -translate-x-2" />
      </div>
      <input
        type={type}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        name={name}
        onChange={onChange}
        spellCheck={false}
        disabled={disabled}
        className={`border rounded-md text-sm w-[100%] py-3 px-4 sm:py-3.5 2xl:py-4 outline-none ${className}`}
      />
    </label>
  );
}
