import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

type Props = {
  popupInfo: any;
  setPopupInfo: Dispatch<SetStateAction<any>>;
};

export default function VoiceSelectGrid({ popupInfo, setPopupInfo }: Props) {
  const [audioActive, setAudioActive] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [voices, setVoices] = useState<
    { audio: HTMLAudioElement; id: string }[]
  >([]);
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!popupInfo.voicesArray) {
      return;
    }
    const voiceArr = popupInfo.voicesArray.map(voice => ({
      audio: new Audio(voice.sourceURL),
      id: String(voice.voiceId),
    }));
    setVoices(voiceArr);
  }, [popupInfo]);

  const handleAudioClick = useCallback(
    (audioId: string) => {
      const { audio } = voices.find(voice => voice.id === audioId);
      if (isPlaying) {
        audio.pause();
        setIsPlaying(false);
        return;
      }
      audio.play();
      setIsPlaying(true);
      setAudioActive(audioId);
      audio.onended = () => {
        setIsPlaying(false);
        setAudioActive('');
      };
    },
    [isPlaying, voices]
  );

  return (
    <div className="accordin-menu style-scroll secondary-scroll">
      <ul className="accordin-menu-voice">
        {popupInfo.voicesArray.map((item: any, idx: number) => (
          <li
            className={`voice-li ${
              String(popupInfo.voiceId) === String(item.voiceId) && 'active'
            }`}
            key={idx}
            onClick={() => {
              setPopupInfo({
                ...popupInfo,
                voiceId: String(item.voiceId),
                voiceName: item.name,
                popupNum: 5,
              });
            }}>
            <h3>{item.name}</h3>
            <audio src={item.sourceURL} ref={audioRef} id={item.voiceId} />
            {isPlaying ? (
              <button
                className={
                  String(audioActive) === String(item.voiceId) && 'active'
                }
                onClick={e => {
                  e.stopPropagation();
                  handleAudioClick(String(item.voiceId));
                }}
                disabled={
                  isPlaying && String(audioActive) !== String(item.voiceId)
                }
              />
            ) : (
              <button
                onClick={e => {
                  e.stopPropagation();
                  handleAudioClick(item.voiceId);
                }}
                disabled={
                  isPlaying && String(audioActive) !== String(item.voiceId)
                }
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
