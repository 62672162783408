import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import useLanguage from '../hooks/use-lang';
import { doGetMetahumanList } from '../networks/source.service';
import { changeHost } from '../utils/common';

type Props = {
  popupInfo: any;
  setPopupInfo: Dispatch<SetStateAction<any>>;
};

export default function MetahumanSelectGrid({
  setPopupInfo,
  popupInfo,
}: Props) {
  const [metahumanList, setMetahumanList] = useState([]);
  const language = useLanguage();

  useEffect(() => {
    doGetMetahumanList({ lang: language })
      .then(setMetahumanList)
      .catch(console.log);
  }, []);

  return (
    <div className="accordin-menu menu-human secondary-scroll">
      <ul className="accordin-menu-human secondary-scroll">
        {metahumanList?.map((item: any, idx: number) => {
          const isItemFree = item.isFree;
          return (
            <li
              className="human-box"
              key={idx}
              onClick={() => {
                setPopupInfo(prev => ({
                  ...prev,
                  modelId: item.ID,
                  metaHumanName: item.name,
                  metaHumanImg: item.thumbnail,
                  styleArray: item.styles,
                  poseArray: item.poses,
                  voicesArray: item.voices,
                  postId: item.postId,
                  popupNum: 2,
                }));

                if (popupInfo.modelId !== item.ID) {
                  setPopupInfo(prev => ({
                    ...prev,
                    voiceId: '',
                    voiceName: '',
                    styleName: '',
                    styleValue: '',
                    poseId: '',
                    poseName: '',
                  }));
                }
              }}>
              <div
                className={`humanImg ${
                  item.name === popupInfo.metaHumanName && 'active'
                }`}
                style={{
                  backgroundImage: `url(${changeHost(item.thumbnail)})`,
                }}>
                <div className="badge">
                  <div className={`${isItemFree ? 'badgeFree' : 'badgeReal'}`}>
                    {isItemFree ? 'Free' : 'Real'}
                  </div>
                </div>
              </div>
              <h3>{item.name}</h3>
              <p>{item.desc}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
