export default function CutIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.8335 17.5C7.21421 17.5 8.3335 16.3807 8.3335 15C8.3335 13.6193 7.21421 12.5 5.8335 12.5C4.45278 12.5 3.3335 13.6193 3.3335 15C3.3335 16.3807 4.45278 17.5 5.8335 17.5Z"
        className="stroke-[#363D59] group-disabled:stroke-[#363D59]/30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1668 2.5L11.6668 6.25M12.5002 12.5L5.8335 2.5L12.5002 12.5ZM7.50016 12.5L10.0002 8.75L7.50016 12.5Z"
        className="stroke-[#363D59] group-disabled:stroke-[#363D59]/30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.167 17.5C15.5477 17.5 16.667 16.3807 16.667 15C16.667 13.6193 15.5477 12.5 14.167 12.5C12.7863 12.5 11.667 13.6193 11.667 15C11.667 16.3807 12.7863 17.5 14.167 17.5Z"
        className="stroke-[#363D59] group-disabled:stroke-[#363D59]/30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
