import { Dispatch, SetStateAction } from 'react';
import { useSetRecoilState } from 'recoil';
import { ICreditMinusItem } from '../networks/dto/IPaySystemDto';
import { payMinusCredit } from '../networks/paysystem.service';
import { useModalAtom } from '../recoil/atoms/store';
import { getItemCode } from '../utils/common';

type Props = {
  itemName?: string;
  price?: string;
  setCredit?: Dispatch<SetStateAction<number>>;
};

export default function useCredit({ itemName, price, setCredit }: Props) {
  const setUseModal = useSetRecoilState(useModalAtom);

  const substractCredit = () => {
    const itemCd = getItemCode();
    const body: ICreditMinusItem = {
      itemCd,
      itemName,
      itemCnt: 1,
      credit: +price,
    };

    payMinusCredit(body)
      .then(res => {
        setCredit(prev => prev - +price);
        const resultStatus = res.data?.resultStatus;
        if (resultStatus !== 200) {
          setUseModal({
            isOpen: true,
            type: 'inform',
            data: {
              title: '구매 실패',
              message: '크레딧 차감에 실패했습니다.',
            },
          });
        }
      })
      .catch(console.log);
  };

  return { substractCredit };
}
