import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import SubpageTitle from '../../components/main/landing/subpage-title';
import AgreementModal from '../../components/main/production-inquiry/agreement-modal';
import HowSection from '../../components/main/production-inquiry/how-section';
import ProductionInquiryForm from '../../components/main/production-inquiry/production-inquiry-form';
import VideoFlowSection from '../../components/main/production-inquiry/video-flow-section';
import SubpageContainer from '../../components/main/subpage-container';
import InformModal from '../../components/modals/inform-modal';
import { addInquiry } from '../../networks/public.service';
import { lang } from '../../recoil/atoms/user';
import { InformModalType } from '../../types_new/commonTypes';
import { ProductionInquiryFormType as FormType } from '../../types_new/main/landing/types';

const emailRegex =
  /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
const GRADIENT_STYLE =
  'bg-clip-text text-transparent bg-gradient-to-r from-from to-to font-bold';
const TITLE = '어떤 영상이 필요하신가요?\n지금바로 상담과 견적을 받아보세요.';
const INITIAL_STATE = {
  title: '영상제작문의',
  name: '',
  email: '',
  mobile: '',
  type: '',
  content: '',
  linkurl: '',
};

export default function ProductionInquiryPage() {
  const [form, setForm] = useState<FormType>(INITIAL_STATE);
  const [agree, setAgree] = useState(false);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [informModal, setInformModal] = useState<InformModalType>({
    isOpen: false,
    title: '',
  });
  const inquiryRef = useRef<any>(null);
  const { t } = useTranslation();
  const language = useRecoilValue(lang);

  const handleOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const scrollToInquirySection = () => {
    inquiryRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (!emailRegex.test(form.email)) {
      setInformModal({
        isOpen: true,
        title: '이메일 형식을 확인해주세요.',
        message: '',
      });
      return;
    }

    setLoading(true);
    addInquiry(form)
      .then(handleSuccess)
      .catch(handleFail)
      .finally(() => setLoading(false));

    function handleSuccess() {
      setInformModal({
        isOpen: true,
        title: '문의가 성공적으로 보내졌습니다.',
      });
      setForm(INITIAL_STATE);
    }
    function handleFail() {
      setInformModal({
        isOpen: true,
        title: '오류가 발생했습니다.',
        message: '잠시 후 다시 시도해주세요.',
      });
    }
  };

  return (
    <>
      <InformModal
        isOpen={informModal.isOpen}
        title={informModal.title}
        message={informModal.message}
        closeModal={() => setInformModal(prev => ({ ...prev, isOpen: false }))}
      />
      <AgreementModal
        isOpen={modal}
        closeModal={() => setModal(false)}
        setAgree={setAgree}
      />

      <SubpageContainer>
        <section className="text-center mt-5">
          {language === 'ko' ? (
            <h1 className="text-2xl md:text-3xl 2xl:text-4xl">
              AI영상 <strong className={GRADIENT_STYLE}>합리적인 가격</strong>
              으로
              <br />
              <strong className={GRADIENT_STYLE}>빠르게</strong> 제작해드립니다.
            </h1>
          ) : (
            <h1 className="text-4xl">
              <strong className={GRADIENT_STYLE}>Quickly </strong> AI video
              generation
              <br />
              at a <strong className={GRADIENT_STYLE}>reasonable price</strong>
            </h1>
          )}
          <p className="mt-5 md:mt-10 xl:mt-14 2xl:mt-16 text-white/60 lg:text-lg xl:text-xl 2xl:text-2xl">
            {t('장비도 없고 영상 콘텐츠 양은 늘려야')}
            <br className="md:hidden" /> {t('하는데 전문 인력도 부족하다!')}
            <br />
            {t('이제 저희에게 맡겨주세요.')}
          </p>
        </section>
        <VideoFlowSection />
        <HowSection scrollToInquirySection={scrollToInquirySection} />
        <section
          ref={inquiryRef}
          className="flex flex-col items-center justify-center mt-24 pb-10 sm:pb-16 lg:pb-20 xl:pb-24">
          <div className="flex flex-col text-center">
            <SubpageTitle badge="영상제작문의" title={TITLE} />
            <p className="mt-4 text-white/50 text-sm sm:text-base xl:text-lg">
              {t('플루닛 스튜디오의 전문 영상팀이')}{' '}
              <br className="md:hidden" />
              {t('바로 연락을 드립니다.')}
            </p>
          </div>
          <ProductionInquiryForm
            setForm={setForm}
            form={form}
            handleOnChange={handleOnChange}
            setAgree={setAgree}
            agree={agree}
            loading={loading}
            onSubmit={onSubmit}
            setModal={setModal}
          />
        </section>
      </SubpageContainer>
    </>
  );
}
