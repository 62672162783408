import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Pagination from '../../components/common/Pagination';
import SubpageTitle from '../../components/main/landing/subpage-title';
import SubpageContainer from '../../components/main/subpage-container';
import TutorialCard from '../../components/tutorial/tutorial-card';
import TutorialModal from '../../components/tutorial/tutorial-modal';
import TutorialSkeleton from '../../components/tutorial/tutorial-skeleton';
import { doGetTutorialList } from '../../networks/source.service';
import { lang } from '../../recoil/atoms/user';
import { PostType } from '../../types_new/main/resource/types';

const TITLE =
  '누구나 쉽게 영상을 만들 수 있는 방법,\n플루닛이 여러분께 알려드려요!';
const POSTS_PER_PAGE = 8;

export default function TutorialPage() {
  const [loading, setLoading] = useState(true);
  const [tutorialList, setTutorialList] = useState<PostType[]>([]);
  const [total, setTotal] = useState(0);
  const [parentPage, setParentPage] = useState(1);
  const language = useRecoilValue(lang);
  const [reqBody, setReqBody] = useState({
    lang: language,
    postsPerPage: POSTS_PER_PAGE,
    paged: 1,
  });
  const [modal, setModal] = useState({
    isOpen: false,
    videoURL: '',
    content: '',
  });
  const tutorialRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLoading(true);
    doGetTutorialList(reqBody)
      .then(({ total, posts }) => {
        setTotal(total);
        setTutorialList(posts);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [reqBody]);

  const openModal = (content: string, videoURL: string) => {
    setModal({
      isOpen: true,
      content,
      videoURL,
    });
  };

  const onPageChange = (paged: number) => {
    tutorialRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
    setReqBody(prev => ({
      ...prev,
      paged,
    }));
    setParentPage(paged);
  };

  return (
    <>
      <TutorialModal
        isOpen={modal.isOpen}
        content={modal.content}
        videoURL={modal.videoURL}
        closeModal={() => setModal(prev => ({ ...prev, isOpen: false }))}
      />
      <SubpageContainer>
        <div
          ref={tutorialRef}
          className="pb-12 md:pb-24 lg:pb-32 xl:pb-36 2xl:pb-48">
          <SubpageTitle badge="사용자 가이드" title={TITLE} />
          {loading ? (
            <TutorialSkeleton />
          ) : (
            <TutorialGrid openModal={openModal} tutorialList={tutorialList} />
          )}
          <Pagination
            onPageChange={onPageChange}
            totalItems={total}
            currentPage={reqBody.paged}
            parentPage={parentPage}
            itemsPerPage={POSTS_PER_PAGE}
          />
        </div>
      </SubpageContainer>
    </>
  );
}

type Props = {
  tutorialList: PostType[];
  openModal: (postContent: string, videoURL: string) => void;
};
function TutorialGrid({ tutorialList, openModal }: Props) {
  return (
    <ul className="grid grid-col-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-7 gap-y-10 mt-8 md:mt-10 lg:mt-14 xl:mt-16 2xl:mt-20">
      {tutorialList.map((tutorial, idx) => (
        <li key={`tutorial-${idx}`}>
          <TutorialCard openModal={openModal} tutorial={tutorial} />
        </li>
      ))}
    </ul>
  );
}
