import '../../../locale/i18n';

export default function ExportModal({
  PreviewPopupIcon,
  exportPopupToggle,
  extensionPopupToggle,
  t,
}: any) {
  return (
    <div className="preview-popup">
      <img src={PreviewPopupIcon} alt="" className="preview-icon" />
      <h3>{t('프로젝트를 영상으로 내보낼까요?')}</h3>
      <p>{t('배포한 프로젝트는 다시 편집할 수 없습니다.')}</p>
      <div className="popup-btn-cont">
        <button
          className="popupBtnLeft bg-[#bebebe] hover:bg-[#cecece] text-black"
          onClick={exportPopupToggle}>
          {t('취소')}
        </button>
        {/* <button className="popupBtnRight opacity-1" onClick={() => setPopup(4)}> */}
        <button
          className="popupBtnRight opacity-1 hover:!bg-[#917bff]"
          onClick={extensionPopupToggle}>
          {t('확인')}
        </button>
      </div>
    </div>
  );
}
