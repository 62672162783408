import { useState } from 'react';
import { isIOS, isSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import { topPhraseArray } from '../../../arrays';
import { isSignedAtom } from '../../../networks/store/member';
import { getMainDir } from '../../../util/common';
import IntersectCircle from '../../svg/IntersectCircle';
import Button from '../button';
import PhraseCard from './phrase-card';

export default function MainTopSection() {
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [activeIdx, setActiveIdx] = useState(0);
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();
  SwiperCore.use([Autoplay]);

  return (
    <section className="w-full landing-top-bg relative">
      <Circle />
      <div className="max-w-screen-xl 2xl:max-w-screen-2xl w-full mx-auto px-7 lg:px-5 pt-[74px] lg:pt-[82px] pb-12 overflow-x-hidden">
        <div className="w-full flex max-lg:flex-col-reverse items-center justify-between lg:mt-16 2xl:mt-28">
          <div className="flex flex-col max-w-full lg:max-w-[600px] w-fit">
            <h5 className="w-fit uppercase bg-clip-text text-transparent bg-gradient-to-r from-from to-to text-sm lg:text-xl 2xl:text-2xl font-bold mb-[10px]">
              Start creating videos today
            </h5>
            <Swiper
              onSwiper={setSwiper}
              onRealIndexChange={({ realIndex }: { realIndex: number }) =>
                setActiveIdx(realIndex)
              }
              loop
              speed={500}
              className="w-full max-w-full"
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              slidesPerView={1}>
              {topPhraseArray.map((phrases, idx) => (
                <SwiperSlide key={`card-${idx}`}>
                  <PhraseCard phrases={phrases} />
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="flex space-x-1.5 lg:space-x-2 mt-9 lg:mt-12 2xl:mt-20">
              {topPhraseArray.map((_, idx) => (
                <div
                  onClick={() => swiper?.slideTo(idx + 1)}
                  key={`slide-${idx}`}
                  className={`${
                    idx !== activeIdx
                      ? 'bg-tertiary/30 hover:scale-125 cursor-pointer'
                      : 'bg-tertiary'
                  } rounded-full w-2.5 2xl:w-3.5 h-2.5 2xl:h-3.5 duration-200`}
                />
              ))}
            </div>
            <div className="flex space-x-[10px] lg:space-x-5 2xl:space-x-8 mt-10 lg:mt-14 2xl:mt-24">
              <Button
                onClick={() => navigate(isLoggedIn ? '/console' : '/login')}>
                {t('스튜디오 시작하기')}
              </Button>
              <Button onClick={() => navigate('/showcase')} color="black">
                {t('스튜디오 알아보기')}
              </Button>
            </div>
          </div>
          <LandingVideo />
        </div>
        <ScrollIndicator />
      </div>
    </section>
  );
}

function Circle() {
  return (
    <div className="max-w-screen-xl 2xl:max-w-screen-2xl w-full translate-x-1/2 right-1/2 absolute">
      <IntersectCircle />
    </div>
  );
}

function LandingVideo() {
  const videoType = `${isSafari && !isIOS ? 'video/quicktime' : 'video/mp4'}`;
  const videoSource = getMainDir(
    `${
      !isSafari && !isIOS
        ? '/videos/landingvideo.webm'
        : '/videos/landingvideo.mov'
    }`
  );

  return (
    <video
      className="no-control w-full lg:w-[48%] 2xl:w-full max-w-[620px] lg:-translate-x-20 xl:-translate-x-10 lg:-translate-y-10 z-10"
      loop
      autoPlay
      disablePictureInPicture
      playsInline
      muted
      controls={false}>
      <source src={videoSource} type={videoType} />
    </video>
  );
}

function ScrollIndicator() {
  return (
    <div className="flex justify-center max-lg:hidden">
      <div className="rounded-full flex flex-col space-y-3 2xl:space-y-4 justify-center items-center w-32 h-32 2xl:w-40 2xl:h-40 border-[1px] border-white/20 text-white text-sm font-medium group z-10">
        <span className="text-xs 2xl:text-sm font-medium">Scroll</span>
        <div className="flex flex-col space-y-1 items-center">
          <div className="w-[22px] h-8 border border-white/70 rounded-full mx-auto flex justify-center">
            <div className="w-0.5 h-1 rounded-md bg-white mt-2 animate-scroll"></div>
          </div>
          <div>
            <div className="border-b border-r border-white w-2 h-2 rotate-45 animate-go" />
            <div className="border-b border-r border-white w-2 h-2 rotate-45 animate-go2" />
          </div>
        </div>
      </div>
    </div>
  );
}
