import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { PostType } from '../../../types_new/main/resource/types';
import { changeHost, findValueByKey } from '../../../utils/common';
import AudioPauseIcon from '../../svg/AudioPauseIcon';
import AudioPlayIcon from '../../svg/AudioPlayIcon';

type ListType = {
  sourceURL: string;
  voiceId: string;
  name: string;
  styleId?: string;
  thumbnail: string;
};

type Props = {
  isOpen: boolean;
  metaData: PostType['metaData'];
  closeModal: () => void;
};

export default function MetahumanItemModal({
  isOpen,
  metaData,
  closeModal,
}: Props) {
  const initialRef = useRef(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const name = findValueByKey(metaData, 'name');
  const isFree = findValueByKey(metaData, 'isFree') === '1';
  const description = findValueByKey(metaData, 'desc');
  const videoURL = findValueByKey(metaData, 'sampleMp4');
  const styleList: ListType[] = findValueByKey(metaData, 'styles');
  const poseList: ListType[] = findValueByKey(metaData, 'poses');
  const voiceList: ListType[] = findValueByKey(metaData, 'voices');

  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedAudioId, setSelectedAudioId] = useState('');

  const handleAudioClick = useCallback(
    (audioId: string) => {
      const audio = audioRef.current;
      const selectedVoice = voiceList.find(
        voice => String(voice.voiceId) === String(audioId)
      );

      if (!audio || !selectedVoice) return;

      if (audio.paused || selectedAudioId !== audioId) {
        setIsPlaying(true);
        audio.src = selectedVoice.sourceURL;
        audio.play();
        setSelectedAudioId(audioId);
      } else {
        setIsPlaying(false);
        audio.pause();
        setSelectedAudioId('');
      }
    },
    [selectedAudioId, voiceList]
  );

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.onended = () => {
        setSelectedAudioId('');
      };
    }
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        ref={initialRef}
        initialFocus={initialRef}
        as="div"
        className="relative z-30"
        onClose={() => {
          closeModal();
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed w-full h-2/3 md:h-5/6 lg:h-[90%] inset-auto bottom-0 duration-150 overflow-visible">
          <div className="flex min-h-full h-1/2 sm:h-full items-center justify-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0  translate-y-80"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0  translate-y-80">
              <Dialog.Panel className="relative text-left mx-auto w-full lg:w-2/3 xl:w-1/2 max-w-screen-lg h-full !overflow-y-auto no-scroll transform rounded-t-2xl lg:rounded-t-lg bg-white transition-all">
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  className="rounded-t-xl"
                  controls
                  controlsList="nodownload">
                  <track default kind="captions" />
                  <source src={changeHost(videoURL)} type="video/mp4" />
                </video>

                <div className="p-5 sm:p-6 md:p-8 xl:p-9 2xl:p-10 space-y-4">
                  <div className="space-y-1 sm:space-y-2 lg:space-y-3 2xl:space-y-4">
                    <span className="text-primary">메타휴먼</span>
                    <h3 className="text-black text-2xl lg:text-3xl 2xl:text-4xl font-bold">
                      {name}
                    </h3>
                    <span className="text-black">{isFree}</span>
                    <p className="text-zinc-500 max-lg:text-sm">
                      {description}
                    </p>
                  </div>
                  <div className="mb-4">
                    <h1 className="text-black font-bold mb-2 xl:mb-3 xl:text-lg">
                      스타일
                    </h1>
                    <div className="flex flex-wrap">
                      {styleList?.map(({ name, thumbnail }, idx) => (
                        <div
                          className="text-center space-y-2 mr-3"
                          key={`style-${idx}`}>
                          <img
                            className="w-14 md:w-16 xl:w-20 h-14 md:h-16 xl:h-20 rounded-lg"
                            src={changeHost(thumbnail)}
                            alt="style thumbnail"
                          />
                          <span className="block text-black font-normal max-2xl:text-sm">
                            {name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mb-4">
                    <h1 className="text-black font-bold mb-2 xl:mb-3 xl:text-lg">
                      자세
                    </h1>
                    <div className="flex flex-wrap">
                      {poseList?.map(({ name, thumbnail }, idx) => (
                        <div
                          className="flex flex-col items-center space-y-1 md:space-y-2 mr-3"
                          key={`pose-${idx}`}>
                          <img
                            className="w-14 md:w-16 xl:w-20 h-14 md:h-16 xl:h-20 rounded-lg"
                            src={changeHost(thumbnail)}
                            alt="pose thumbnail"
                          />
                          <span className="block text-black font-normal max-2xl:text-sm">
                            {name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="mb-4">
                    <audio ref={audioRef} />
                    <h1 className="text-black font-bold mb-3 text-lg">
                      보이스
                    </h1>
                    <div className="!flex space-x-4">
                      {voiceList?.map((item: any, idx: number) => (
                        <div
                          className="text-center space-y-2"
                          key={`item-${idx}`}>
                          <button
                            disabled={
                              isPlaying &&
                              String(selectedAudioId) !== String(item.voiceId)
                            }
                            className="text-black flex items-center space-x-4 p-2 px-3 lg:p-3 2xl:p-4 group border border-gray-300 rounded-lg focus:outline-none"
                            onClick={() => {
                              handleAudioClick(String(item.voiceId));
                            }}>
                            <span className="block text-black font-normal max-2xl:text-sm">
                              {item.name}
                            </span>
                            <div className="p-4 overflow-visible rounded-full bg-primary/10 group-hover:bg-primary/20 duration-100 ease-out relative">
                              <i className="absolute bottom-1/2 right-1/2 translate-x-1/2 translate-y-1/2">
                                {isPlaying &&
                                String(selectedAudioId) ===
                                  String(item.voiceId) ? (
                                  <AudioPauseIcon />
                                ) : (
                                  <AudioPlayIcon />
                                )}
                              </i>
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
