import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { termsArray } from '../../../arrays';
import CheckedBox from '../../svg/checked-box';
import UncheckedBox from '../../svg/unchecked-box';
import TermCard from './term-card';
import TermsModal from './terms-modal';

export default function TermsView() {
  const [modal, setModal] = useState({
    isOpen: false,
    terms: <></>,
    title: '',
  });
  const [checkedBox, setCheckedBox] = useState<string[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isCheckedAll = () => {
    return checkedBox.length === termsArray.length;
  };

  const isSatisfied = () => {
    const mendatoryValues = termsArray
      .filter(term => term.isMendatory)
      .map(term => term.id);

    return mendatoryValues.every(value => checkedBox.includes(value));
  };

  const closeModal = () =>
    setModal(prev => ({
      ...prev,
      isOpen: false,
    }));

  const onCheckedAll = (checked: boolean) => {
    if (checked) {
      const checkedBoxArr: string[] = [];
      termsArray.forEach(box => checkedBoxArr.push(box.id));
      setCheckedBox(checkedBoxArr);
    } else {
      setCheckedBox([]);
    }
  };

  const handleOnClick = () => {
    switch (modal.title) {
      case '서비스 이용약관':
        setCheckedBox(prev => [...prev, 'service']);
        closeModal();
        break;
      case '개인정보 수집 및 이용':
        setCheckedBox(prev => [...prev, 'personal']);
        closeModal();
        break;
      case '스팸 방지 정책':
        setCheckedBox(prev => [...prev, 'spam']);
        closeModal();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TermsModal
        title={modal.title}
        terms={modal.terms}
        onClick={handleOnClick}
        isOpen={modal.isOpen}
        closeModal={closeModal}
      />
      <section>
        <div className="relative">
          <div className="sm:hidden absolute bottom-0 w-full h-10 bg-gradient-to-t from-black/50 to-black/0" />
          <div className="flex flex-col space-y-1 max-sm:pb-10 max-sm:h-72 overflow-y-auto no-scroll">
            <div className="flex items-center py-3 px-4 2xl:py-4 2xl:px-6 bg-tertiary/10 text-xs lg:text-[13px] 2xl:text-sm">
              <input
                onChange={() => onCheckedAll(!isCheckedAll())}
                className="hidden"
                type="checkbox"
                id="all"
              />
              <label className="flex items-center cursor-pointer" htmlFor="all">
                {isCheckedAll() ? (
                  <CheckedBox className="w-4 h-4 2xl:w-5 2xl:h-5 mr-3" />
                ) : (
                  <UncheckedBox className="w-4 h-4 2xl:w-5 2xl:h-5 mr-3" />
                )}
                {t('서비스 전체 이용 약관에 동의합니다.')}
              </label>
            </div>
            <ul className="flex flex-col space-y-1">
              {termsArray.map(term => (
                <li key={term.id}>
                  <TermCard
                    term={term}
                    setCheckedBox={setCheckedBox}
                    checkedBox={checkedBox}
                    setModal={setModal}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
        <button
          onClick={() => navigate('/signup/steps/user-info')}
          disabled={!isSatisfied()}
          className={`w-full text-sm py-4 mt-5 sm:mt-7 lg:mt-10 rounded-md duration-200 ${
            isSatisfied()
              ? 'bg-primary hover:bg-primary/70'
              : 'cursor-not-allowed bg-tertiary/60'
          }`}>
          {t('동의하기')}
        </button>
      </section>
    </>
  );
}
