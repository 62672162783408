import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function BreadCrumb() {
  const { group } = useParams();
  const { t } = useTranslation();
  
  const displayName = () => {
    switch (group) {
      case 'background':
        return '배경';
      case 'clip':
        return '꾸미기';
      case 'etc':
        return '기타';
      default:
        return '';
    }
  };

  return (
    <div className="flex items-center space-x-4 mt-5 xl:mt-10 2xl:mt-12 ">
      <h4 className="text-xl 2xl:text-2xl">{t('내 미디어')}</h4>
      {group && (
        <>
          <Chevron />
          <h4 className="text-xl 2xl:text-2xl">{t(displayName())}</h4>
        </>
      )}
    </div>
  );
}

function Chevron() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-5 h-5">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m8.25 4.5 7.5 7.5-7.5 7.5"
      />
    </svg>
  );
}
