type Props = {
  className?: string;
};

export default function CheckedBox({ className }: Props) {
  return (
    <svg
      className={className || 'w-5 h-5'}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="14" height="14" rx="3" fill="#7B61FF" />
      <path
        d="M6.28887 10.3287L4 7.56583L4.57222 6.87511L6.28887 8.94727L9.97315 4.5L10.5454 5.19072L6.28887 10.3287Z"
        fill="white"
      />
    </svg>
  );
}
