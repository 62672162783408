import { SketchPicker } from 'react-color';

type Props = {
  isOpen: boolean;
  closePicker: () => void;
  selectedColor: string;
  changeTitle: any;
};

export default function ColorPicker({
  isOpen,
  closePicker,
  selectedColor,
  changeTitle,
}: Props) {
  const colorHandler = (color: any) => {
    changeTitle(
      {
        currentTarget: {
          name: 'color',
          value: color.hex,
        },
      },
      true,
      false
    );
  };

  return (
    <div
      className={`absolute bottom-0 flex flex-col items-start space-y-5 px-5 py-4 rounded-lg shadow-xl bg-white ${
        isOpen
          ? '-translate-x-[112%] opacity-100'
          : '-translate-x-[100%] opacity-0 pointer-events-none'
      } duration-200 ease-out`}>
      <div className="w-full flex justify-between items-center">
        <h1 className="text-sm font-medium">컬러 에디터</h1>
        <button
          className="close__btn close__gray__icon"
          onClick={closePicker}></button>
      </div>
      <SketchPicker color={selectedColor} onChange={colorHandler} />
    </div>
  );
}
