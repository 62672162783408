export default function LinkArrowIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_2948_138918"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25">
        <rect
          x="0.931885"
          y="0.908936"
          width="23.1818"
          height="23.1818"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_2948_138918)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.46407 6.95048L18.5472 6.95048L18.5472 19.0336L15.0699 19.0336L15.0699 12.8866L8.20271 19.7537L5.7439 17.2949L12.6111 10.4278L6.46407 10.4278L6.46407 6.95048Z"
          stroke="white"
          strokeWidth="1.15909"
          strokeLinecap="square"
        />
      </g>
    </svg>
  );
}
