import { useTranslation } from 'react-i18next';

export default function NoListCard({ text }: { text: string }) {
  const { t } = useTranslation();

  return (
    <div className="bg-white rounded-xl py-12 xl:py-16 2xl:py-20 text-center mt-5 xl:mt-8 2xl:mt-10">
      <i className="flex justify-center mb-3">
        <ExclamationIcon />
      </i>
      <span className="text-lg xl:text-xl 2xl:text-2xl text-black font-bold">
        {t(`${text} 내역이 없습니다.`)}
      </span>
    </div>
  );
}

function ExclamationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1"
      stroke="#7b61ff"
      className="w-20 h-20 xl:w-24 2xl:w-32 xl:h-24 2xl:h-32"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
      />
    </svg>
  );
}
