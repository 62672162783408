export default function AlignRightIcon() {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <line
        y1="-0.5"
        x2="13.5"
        y2="-0.5"
        transform="matrix(-1 0 0 1 13.918 1)"
        stroke="#ACAFC0"
      />
      <line
        y1="-0.5"
        x2="9"
        y2="-0.5"
        transform="matrix(-1 0 0 1 13.918 4)"
        stroke="#ACAFC0"
      />
      <line
        y1="-0.5"
        x2="13.5"
        y2="-0.5"
        transform="matrix(-1 0 0 1 13.918 7)"
        stroke="#ACAFC0"
      />
      <line
        y1="-0.5"
        x2="9"
        y2="-0.5"
        transform="matrix(-1 0 0 1 13.918 10)"
        stroke="#ACAFC0"
      />
    </svg>
  );
}
