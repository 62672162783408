import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import SubpageTitle from '../../components/main/landing/subpage-title';
import NoticeGrid from '../../components/main/notice/notice-grid';
import NoticeModal from '../../components/main/notice/notice-modal';
import NoticeSkeleton from '../../components/main/notice/notice-skeleton';
import SubpageContainer from '../../components/main/subpage-container';
import TopButtonIcon from '../../components/svg/top-button-icon';
import { doGetNoticeList } from '../../networks/source.service';
import { lang } from '../../recoil/atoms/user';
import { NoticeModalType } from '../../types_new/main/landing/types';
import { PostType } from '../../types_new/main/resource/types';

const POSTS_PER_PAGE = 9;
const TITLE =
  '플루닛 스튜디오와 함께\n다양한 이벤트와 업데이트 소식을\n지금 바로 확인하세요!';

export default function NoticePage() {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const language = useRecoilValue(lang);
  const [reqBody, setReqBody] = useState({
    lang: language,
    postsPerPage: POSTS_PER_PAGE,
    paged: 1,
  });
  const [noticeList, setNoticeList] = useState<PostType[]>([]);
  const [total, setTotal] = useState(0);
  const [noticeModal, setNoticeModal] = useState<NoticeModalType>({
    metaData: [],
    postTitle: '',
    registerDate: '',
    postContent: '',
    isOpen: false,
  });
  const isAtEnd = total === noticeList?.length;

  useEffect(() => {
    doGetNoticeList(reqBody)
      .then(({ posts, total }) => {
        setNoticeList(prev => [...prev, ...posts]);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => {
        if (loading) setLoading(false);
        setLoadingMore(false);
      });
  }, [reqBody]);

  const openModal = (notice: PostType) => {
    const { postTitle, registerDate, postContent, metaData } = notice;
    setNoticeModal({
      isOpen: true,
      postTitle,
      registerDate,
      postContent,
      metaData,
    });
  };

  const closeModal = () => {
    setNoticeModal(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  const loadMore = () => {
    setLoadingMore(true);
    setReqBody(prev => ({ ...prev, paged: prev.paged + 1 }));
  };

  return (
    <>
      <NoticeModal noticeModal={noticeModal} closeModal={closeModal} />
      <SubpageContainer>
        <div className="pb-20 md:pb-28 lg:pb-36 xl:pb-40 2xl:pb-52">
          <div className="mb-4 md:mb-16">
            <SubpageTitle badge="공지사항" title={TITLE} />
          </div>
          {loading ? (
            <NoticeSkeleton />
          ) : (
            <>
              <NoticeGrid openModal={openModal} noticeList={noticeList} />
              {loadingMore && <NoticeSkeleton />}
            </>
          )}
        </div>
        {!isAtEnd && (
          <div className="flex items-center justify-center mb-20">
            <button
              disabled={loadingMore}
              onClick={loadMore}
              className={`w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24 rounded-full duration-150 ${
                loadingMore
                  ? 'bg-tertiary/10 cursor-not-allowed'
                  : 'group bg-tertiary/20 hover:bg-tertiary/40'
              }`}>
              <span className="block group-hover:hidden">
                {loadingMore ? '...' : 'More'}
              </span>
              <i className="flex items-center justify-center rotate-180">
                <TopButtonIcon />
              </i>
            </button>
          </div>
        )}
      </SubpageContainer>
    </>
  );
}
