const envList = [
  'REACT_APP_LANGUAGE',
  'REACT_APP_PAYSYS_AUTH_USER',
  'REACT_APP_PAYSYS_AUTH_PW',
  'REACT_APP_DEVSERVER_URL',
  'REACT_APP_RELSERVER_URL',
  'REACT_APP_PAY_DEVSERVER_URL',
  'REACT_APP_PAY_RELSERVER_URL',
] as const;

export const ENV = {
  REACT_APP_LANGUAGE: process.env.REACT_APP_LANGUAGE,
  REACT_APP_PAYSYS_AUTH_USER: process.env.REACT_APP_PAYSYS_AUTH_USER,
  REACT_APP_PAYSYS_AUTH_PW: process.env.REACT_APP_PAYSYS_AUTH_PW,
  REACT_APP_DEVSERVER_URL: process.env.REACT_APP_DEVSERVER_URL,
  REACT_APP_RELSERVER_URL: process.env.REACT_APP_RELSERVER_URL,
  REACT_APP_PAY_DEVSERVER_URL: process.env.REACT_APP_PAY_DEVSERVER_URL,
  REACT_APP_PAY_RELSERVER_URL: process.env.REACT_APP_PAY_RELSERVER_URL,
} as { [key in (typeof envList)[number]]: string };

export const isDev = process.env.REACT_APP_SERVICE_MODE === 'develop';
export const isProd = process.env.REACT_APP_SERVICE_MODE === 'production';
export const isTest = process.env.REACT_APP_SERVICE_MODE === 'test';
