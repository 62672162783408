import { debounce } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoFilterType } from '../../../types_new/main/landing/types';
import InfoFilterBox from './info-filter-box';

type Props = {
  total: number;
  filterArray: InfoFilterType[];
  setFilter: Dispatch<SetStateAction<InfoFilterType>>;
  filter: InfoFilterType;
  hasInput?: boolean;
  setTitle?: Dispatch<SetStateAction<string>>;
};

export default function InfoFilterSection({
  total,
  filterArray,
  setFilter,
  filter,
  hasInput,
  setTitle,
}: Props) {
  const { t } = useTranslation();

  const handleOnChange = debounce((value: string) => {
    setTitle(value);
  }, 400);

  return (
    <div className="w-full flex justify-between items-center mt-8 md:mt-10">
      <span className="text-sm md:text-base lg:text-lg">
        {t('총')}{' '}
        <strong className="font-semibold">
          {total} {t('건')}
        </strong>
      </span>
      {hasInput && (
        <div className="flex space-x-1 w-1/2">
          <input
            type="text"
            placeholder={t('제목 검색')}
            className="p-2 text-sm w-full outline-none rounded-md"
            onChange={e => handleOnChange(e.target.value)}
          />
        </div>
      )}

      <InfoFilterBox
        filterArray={filterArray}
        setFilter={setFilter}
        filter={filter}
      />
    </div>
  );
}
