export default function YoutubeIcon() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.7">
        <rect width="30" height="30" rx="15" fill="white" />
        <g clipPath="url(#clip0_102_4556)">
          <path
            d="M24.9218 10.6737C24.8078 10.2672 24.5859 9.89919 24.2795 9.60878C23.9644 9.30943 23.5783 9.0953 23.1575 8.9866C21.5828 8.57154 15.2739 8.57154 15.2739 8.57154C12.6437 8.54162 10.0143 8.67323 7.40027 8.96564C6.9795 9.08237 6.5941 9.30128 6.27835 9.60291C5.9681 9.90142 5.74338 10.2695 5.62599 10.6728C5.34399 12.192 5.20698 13.7345 5.2168 15.2796C5.20673 16.8233 5.34341 18.3653 5.62599 19.8864C5.74086 20.288 5.96475 20.6545 6.27583 20.9505C6.58692 21.2464 6.97431 21.4603 7.40027 21.5735C8.99596 21.9877 15.2739 21.9877 15.2739 21.9877C17.9074 22.0177 20.5402 21.886 23.1575 21.5936C23.5783 21.4849 23.9644 21.2708 24.2795 20.9714C24.5857 20.6811 24.8074 20.313 24.9209 19.9065C25.2103 18.3879 25.351 16.8448 25.341 15.2989C25.3628 13.7465 25.2223 12.1961 24.9218 10.6728V10.6737ZM13.2682 18.1507V12.4094L18.5172 15.2805L13.2682 18.1507Z"
            fill="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_102_4556">
          <rect
            width="20.1242"
            height="20.1242"
            fill="white"
            transform="translate(5.2168 5.21729)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
