import { useTranslation } from 'react-i18next';
import GradientBadge from '../gradient-badge';
import WatchMoreButton from '../watch-more-button';

type Props = {
  badge: string;
  title: string;
  description: string;
  link: string;
};

export default function LandingTitle({
  badge,
  title,
  description,
  link,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center max-lg:text-center">
      <GradientBadge>{t(badge)}</GradientBadge>
      <h2 className="text-2xl lg:text-3xl 2xl:text-4xl font-bold mt-4">
        {t(title)}
      </h2>
      <p className="text-[#bbb] text-xs lg:text-base 2xl:text-lg font-light mt-4">
        {t(description)}
      </p>
      <WatchMoreButton link={link} />
    </div>
  );
}
