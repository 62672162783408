import { AxiosResponse } from 'axios';
import APIService from './APIService';
import apiConfig from './apiConfig.json';
import {
  IExportTranslateReq,
  IScriptTranslateReq,
  IScriptTranslateWavReq,
} from './dto/ITranslateDto';

// 번역 요청
const createTranslate = async (args: IScriptTranslateReq) => {
  const { data } = await APIService.post(
    apiConfig.studio.translate.createTranslate,
    args
  );
  const {
    code,
    body: { text },
  } = data;

  return { code, text };
};

const doCreateTranslate = async (args: IScriptTranslateReq) => {
  return createTranslate(args).then(({ code, text }) => {
    if (code !== '200') throw new Error('처리 중 오류가 발생했습니다.');
    return text;
  });
};

// 번역된 wav 요청
const createTranslateWav = async (args: IScriptTranslateWavReq) => {
  return await APIService.get(apiConfig.studio.translate.createTranslateWav, {
    params: { ...args },
  });
};

// 전문가 번역 요청
const requestExpertTranslate = async (args: IExportTranslateReq) => {
  return await APIService.post(
    apiConfig.studio.translate.requestExpertTranslate,
    args
  );
};

type DataType = {
  body: any;
  code: string;
};

// 전문가 번역 내역 요청
const getTranslationReqList = async (args: {
  paged: number;
  postsPerPage: number;
}) => {
  const { data }: AxiosResponse<DataType> = await APIService.get(
    apiConfig.studio.translate.getTranslationList,
    args
  );
  const { body, code } = data;
  if (code !== '200') throw new Error('network error');
  return { body } as DataType['body'];
};

export {
  createTranslate,
  createTranslateWav,
  doCreateTranslate,
  getTranslationReqList,
  requestExpertTranslate,
};
