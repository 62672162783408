export default function GuideIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM7.875 10.125V3.375H10.125V10.125H7.875ZM7.875 14.625V12.375H10.125V14.625H7.875Z"
        fill="#9AA2AB"
      />
    </svg>
  );
}
