export default function PDFIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="84"
      viewBox="0 0 72 84"
      fill="none"
    >
      <path
        d="M37.4507 38.0486L37.4767 37.9288C37.9796 35.8823 38.6126 33.3307 38.1184 31.0188C37.7889 29.195 36.4275 28.4843 35.2656 28.433C33.8955 28.373 32.6729 29.1436 32.3694 30.2653C31.7971 32.3203 32.3087 35.1288 33.2452 38.708C32.0659 41.4822 30.1843 45.5151 28.8056 47.9126C26.2389 49.2227 22.7964 51.2435 22.2848 53.7951C22.1808 54.266 22.3022 54.8654 22.5883 55.4048C22.9092 56.0042 23.4208 56.4666 24.0191 56.6892C24.2792 56.7834 24.5914 56.8605 24.9556 56.8605C26.4817 56.8605 28.953 55.6446 32.248 50.0618C32.7509 49.8991 33.2712 49.7279 33.7741 49.5566C36.1327 48.7689 38.5779 47.9469 40.7891 47.5787C43.2344 48.8716 46.0178 49.7022 47.9081 49.7022C49.7811 49.7022 50.5181 48.6062 50.7956 47.9469C51.2812 46.791 51.0471 45.3353 50.258 44.5561C49.1134 43.443 46.33 43.1519 41.9944 43.6828C39.8613 42.3984 38.4652 40.6516 37.4507 38.0486ZM27.9384 51.0551C26.7332 52.7847 25.8227 53.6495 25.3284 54.0263C25.9094 52.9731 27.0453 51.86 27.9384 51.0551ZM35.5344 30.8904C35.9853 31.6525 35.9246 33.9558 35.5777 35.1203C35.1528 33.4163 35.0921 31.0017 35.3436 30.7191C35.413 30.7277 35.4737 30.7791 35.5344 30.8904ZM35.3956 41.2082C36.3234 42.7923 37.4941 44.1537 38.7861 45.1641C36.9131 45.5836 35.2049 46.2772 33.6787 46.8937C33.3146 47.0393 32.959 47.1848 32.6122 47.3218C33.7655 45.2583 34.728 42.9207 35.3956 41.2082ZM48.8879 46.8166C48.8966 46.8338 48.9053 46.8595 48.8533 46.8937H48.8359L48.8186 46.9194C48.7492 46.9622 48.0382 47.3732 44.9773 46.183C48.4977 46.0203 48.8793 46.8081 48.8879 46.8166Z"
        fill="#F96B73"
      />
    </svg>
  );
}
