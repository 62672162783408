import { useTranslation } from 'react-i18next';
import { PhraseArrayType } from '../../../types_new/main/landing/types';

type Props = {
  phrases: PhraseArrayType;
};

export default function PhraseCard({
  phrases: { heading, text1, text2, text3 },
}: Props) {
  const { t } = useTranslation();
  return (
    <div>
      <h1 className="text-2xl sm:text-3xl lg:text-4xl 2xl:text-5xl font-black leading-normal">
        {t(heading)}
      </h1>
      <div className="flex flex-col text-xs sm:text-sm md:text-base lg:text-lg 2xl:text-2xl leading-5 lg:leading-7 2xl:leading-9 mt-[10px] lg:mt-7">
        <span>{t(text1)}</span>
        <span>{t(text2)}</span>
        <span>{t(text3)}</span>
      </div>
    </div>
  );
}
