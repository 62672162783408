/* eslint-disable operator-assignment */
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import '../../css/style.css';
import '../../locale/i18n';
import { pages as storedPages, ratio as storedRatio } from '../../store/pages';
import {
  IBackground,
  IImage,
  IPages,
  IPreviewElement,
  ISegment,
  ITitle,
} from '../../store/pages/types';
import Media from './Media';

type StripType =
  | 'metahuman'
  | 'video'
  | 'audio'
  | 'title'
  | 'image'
  | 'bgColorImage'
  | 'bgVideo'
  | 'bgaudio'
  | 'clip'
  | 'background';
type Props = {
  type: StripType;
  title: string;
  TLW: number;
  multiplier: number;
  nameColumnWidth: number;
  iconName: string;
  railArray: any;
  addRail: any;
  removeRail: any;
};

function Strip({
  type,
  title,
  TLW,
  multiplier,
  nameColumnWidth,
  iconName,
  railArray,
  addRail,
  removeRail,
}: Props) {
  const { t } = useTranslation();
  const [pages, setPages] = useRecoilState<IPages[]>(storedPages);
  const [ratio] = useRecoilState(storedRatio);
  const segments = pages[0]?.segments[0]?.segment as ISegment[];
  const images = pages[0]?.segments[0]?.image as IImage[];
  const titles = pages[0]?.segments[0]?.title as ITitle[];
  const bgs = pages[0]?.segments[0]?.background as IBackground[];
  const rawMedias: IPreviewElement[] = filterMedias(
    type,
    segments,
    images,
    titles,
    bgs
  );

  const updateItem = useCallback(
    (id: number, dx: number, dw: number, direction?: string, dy?: number) => {
      const pixelToCs = (pixel: number) => pixel * ratio; // convert pixet to ms
      const target = rawMedias.find(item => item.id === id);

      if (!target) return;

      const isLeft = direction === 'left';
      const duration = target.duration + pixelToCs(dw);
      const startTimestamp = isLeft
        ? target.startTimestamp - pixelToCs(dw)
        : target.startTimestamp + pixelToCs(dx);
      const railIndex = Math.floor((dy + railHeight / 2) / railHeight);

      const updatedTarget = {
        ...target,
        duration,
        startTimestamp,
        target: true,
        // eslint-disable-next-line no-restricted-globals
        railIndex: !isNaN(railIndex) ? railIndex : target.railIndex,
      };

      if (startTimestamp !== 0) {
        const updatedMedias = [
          ...rawMedias.filter(item => item.id !== id),
          updatedTarget,
        ];
        updatedMedias.sort((a, b) => a.startTimestamp - b.startTimestamp);

        updateRecoilState(pages, setPages, target.type, updatedMedias, id);
      } else {
        const updatedMedias = [
          updatedTarget,
          ...rawMedias.filter(item => item.id !== id),
        ];

        updateRecoilState(pages, setPages, target.type, updatedMedias, id);
      }
    },
    [pages, ratio, rawMedias, setPages, type]
  );

  const filterdRailArray = railArray?.filter(item => item.type === type);
  const railHeight = 44;

  return (
    <div
      className="strip_box flex"
      style={{
        width: TLW * multiplier + nameColumnWidth,
        height: `${railHeight * filterdRailArray.length}px`,
      }}>
      <div
        className="title"
        style={{
          position: 'sticky',
          left: 0,
          zIndex: 99999,
          backgroundColor: 'white',
          borderRight: '1px solid #e9e9e9',
        }}>
        {filterdRailArray?.map((item, idx) => {
          if (idx === 0) {
            return (
              <div
                className="title__line sticky pl-2"
                key={idx}
                style={{ background: '#EBF4F6' }}>
                <div className="timeline_icon">
                  <i className={`timeline__${iconName}__icon`} />
                </div>
                <span>{title}</span>
                {type !== 'metahuman' && (
                  <button className="button" onClick={() => addRail(type)}>
                    +
                  </button>
                )}
              </div>
            );
          }

          return (
            <div
              className="title__line sticky pl-6"
              style={{ paddingLeft: '50px' }}
              key={idx}>
              <span className="subrail">
                {`${
                  type === 'metahuman'
                    ? t('메타휴먼')
                    : type === 'clip'
                    ? t('꾸미기')
                    : t('배경')
                } ${idx}`}
              </span>
              <button
                className="button close"
                onClick={() => removeRail(type, idx)}
              />
            </div>
          );
        })}
      </div>
      <div
        className="relative bg-white mx-0.5 blockContainer"
        style={{
          width: TLW * multiplier,
          height: `${railHeight * filterdRailArray.length}px`,
        }}>
        {rawMedias?.map((media, idx, array) => {
          const totalLength = array.length;
          return (
            <Media
              zIndex={totalLength - idx}
              item={media}
              railHeight={railHeight}
              key={`${media.id}-${media.startTimestamp}-${media.duration}-${ratio}`}
              updateItem={(id, dw, dx, direction, dy) =>
                updateItem(id, dw, dx, direction, dy)
              }
            />
          );
        })}
      </div>
    </div>
  );
}

export default Strip;

export function updateRecoilState(
  pages: IPages[],
  setPages: SetterOrUpdater<IPages[]>,
  type: StripType,
  updatedMedias: IPreviewElement[],
  id?: number
) {
  const { segment, title, background, image } = pages[0].segments[0];

  if (['video', 'audio', 'metahuman', 'bgaudio'].includes(type)) {
    const storedSegments = segment as ISegment[];
    const others = storedSegments.filter(seg => seg.mediaType !== type);
    const ours = storedSegments.filter(seg => seg.mediaType === type);
    const targetElement = pages[0]?.segments[0]?.segment.filter(
      item => item.segmentId === id
    )[0];
    const updatedSegments = updatedMedias
      .filter(item => item.type === type)
      .map(media => {
        const storedSegment = ours.find(m => Number(m.segmentId) === media.id);

        return {
          ...storedSegment,
          duration: Math.floor(media.duration),
          endTime:
            Math.floor(media.startTimestamp) + Math.floor(media.duration),
          insertTime: Math.floor(media.startTimestamp),
          railIndex:
            storedSegment?.metaData?.integratedId &&
            Math.floor(media.railIndex) === 0
              ? storedSegment.railIndex
              : Math.floor(media.railIndex),
        };
      });

    const newSegments = [...others, ...updatedSegments];

    const element = newSegments.find(item => item.segmentId === id);

    if (element?.metaData?.integratedId) {
      newSegments.map(item => {
        if (item?.metaData?.integratedId === element?.metaData?.integratedId) {
          item.duration = element.duration;
          item.endTime = element.endTime;
          item.insertTime = element.insertTime;
        }
      });
    }

    const tmpBackground = JSON.parse(JSON.stringify(background));
    if (targetElement?.metaData?.pptId) {
      tmpBackground.map(item => {
        if (item?.metaData?.pptId === targetElement?.metaData?.pptId) {
          item.metaData = {
            ...item?.metaData,
            segmentIsertT: targetElement.insertTime,
            segmentDuration: targetElement.duration,
          };
          return item;
        }
      });
    }

    setPages([
      { segments: [{ segment: newSegments, title, background, image }] },
    ]);
    return [
      {
        segments: [
          { segment: newSegments, title, background: tmpBackground, image },
        ],
      },
    ];
  }

  if (['bgColorImage', 'bgVideo'].includes(type)) {
    const bgType =
      type === 'bgVideo' ? 'video' : type === 'bgaudio' ? 'audio' : 'color'; // image 추가
    const storedBgs = background as IBackground[];
    const others =
      type !== 'bgColorImage'
        ? storedBgs.filter(bgs => bgs.type !== bgType)
        : storedBgs.filter(bgs => bgs.type !== 'color' && bgs.type !== 'image');
    const ours =
      type !== 'bgColorImage'
        ? storedBgs.filter(bgs => bgs.type === bgType)
        : storedBgs.filter(bgs => bgs.type === 'color' || bgs.type === 'image');
    const updatedBgs = updatedMedias
      .filter(item => item.type === type)
      .map(media => {
        const storedBg = ours.find(m => Number(m.backgroundId) === media.id);
        return {
          ...storedBg,
          duration: Math.floor(media.duration),
          endTime:
            Math.floor(media.startTimestamp) + Math.floor(media.duration),
          insertTime: Math.floor(media.startTimestamp),
          railIndex: Math.floor(media.railIndex),
        };
      });

    const newBgs = [...others, ...updatedBgs];
    setPages([{ segments: [{ segment, title, background: newBgs, image }] }]);
    return [{ segments: [{ segment, title, background: newBgs, image }] }];
  }

  if (type === 'image') {
    const storedImages = image as IImage[];
    const newImages = updatedMedias
      .filter(item => item.type === 'image' || item.type === 'bgColorImage')
      .map(newImg => {
        const storedImage = storedImages.find(
          img => Number(img.imageId) === newImg.id
        );

        return {
          ...storedImage,
          duration: Math.floor(newImg.duration),
          insertTime: Math.floor(newImg.startTimestamp),
          endTime:
            Math.floor(newImg.startTimestamp) + Math.floor(newImg.duration),
          railIndex: Math.floor(newImg.railIndex),
        };
      });

    setPages([
      { segments: [{ segment, title, background, image: newImages }] },
    ]);
  }

  if (type === 'title') {
    const storedTitles = title as ITitle[];
    const newTitles = updatedMedias
      .filter(item => item.type === 'title')
      .map(newTitle => {
        const storedTitle = storedTitles.find(
          ttt => Number(ttt.titleId) === newTitle.id
        );
        return {
          ...storedTitle,
          duration: Math.floor(newTitle.duration),
          endTime:
            Math.floor(newTitle.startTimestamp) + Math.floor(newTitle.duration),
          insertTime: Math.floor(newTitle.startTimestamp),
          railIndex: Math.floor(newTitle.railIndex),
        };
      });

    setPages([
      { segments: [{ segment, title: newTitles, background, image }] },
    ]);
  }
}

export const filterMedias = (
  type?: StripType,
  segments?: ISegment[],
  images?: IImage[],
  titles?: ITitle[],
  bgs?: IBackground[]
) => {
  let clipArray = [];

  if (images !== undefined) {
    clipArray = clipArray.concat(
      images?.map(img => ({
        id: Number(img.imageId),
        duration: img.duration,
        insertTime: img.insertTime,
        startTimestamp: img.insertTime,
        endTimestamp: img.insertTime + img.duration,
        railIndex: img.railIndex,
        type: 'image',
      }))
    );
  }
  if (titles !== undefined) {
    clipArray = clipArray.concat(
      titles?.map(t => ({
        id: Number(t.titleId),
        duration: t.duration,
        insertTime: t.insertTime,
        startTimestamp: t.insertTime,
        endTimestamp: t.insertTime + t.duration,
        railIndex: t.railIndex,
        type: 'title',
      }))
    );
  }

  if (segments !== undefined) {
    clipArray = clipArray.concat(
      segments
        ?.filter((seg: ISegment) => seg.mediaType === 'audio')
        ?.map(seg => ({
          id: seg.segmentId,
          duration: seg.duration,
          insertTime: seg.insertTime,
          startTimestamp: seg.insertTime,
          endTimestamp: seg.insertTime + seg.duration,
          volume: seg.volume,
          fadeIn: seg?.fadeIn,
          fadeOut: seg?.fadeOut,
          railIndex: seg.railIndex,
          type: seg.mediaType,
        }))
    );
  }

  if (segments !== undefined) {
    clipArray = clipArray.concat(
      segments
        ?.filter((seg: ISegment) => seg.mediaType === 'video')
        ?.map(seg => ({
          id: Number(seg.segmentId),
          duration: seg.duration,
          insertTime: seg.insertTime,
          startTimestamp: seg.insertTime,
          endTimestamp: seg.insertTime + seg.duration,
          volume: seg.volume,
          fadeIn: seg?.fadeIn,
          fadeOut: seg?.fadeOut,
          railIndex: seg.railIndex,
          type: seg.mediaType,
        }))
    );
  }

  let backgroundArray = [];
  if (segments !== undefined) {
    backgroundArray = backgroundArray.concat(
      segments
        ?.filter((seg: ISegment) => seg.mediaType === 'bgaudio')
        ?.map(seg => ({
          id: Number(seg.segmentId),
          duration: seg.duration,
          insertTime: seg.insertTime,
          startTimestamp: seg.insertTime,
          endTimestamp: seg.insertTime + seg.duration,
          volume: seg.volume,
          fadeIn: seg?.fadeIn,
          fadeOut: seg?.fadeOut,
          railIndex: seg.railIndex,
          type: seg.mediaType,
        }))
    );
  }

  if (bgs !== undefined) {
    backgroundArray = backgroundArray.concat(
      bgs
        ?.filter(bg => bg.type === 'video')
        .map(bg => ({
          id: Number(bg.backgroundId),
          duration: bg.duration,
          insertTime: bg.insertTime,
          startTimestamp: bg.insertTime,
          endTimestamp: bg.insertTime + bg.duration,
          volume: bg.volume,
          fadeIn: bg?.fadeIn,
          fadeOut: bg?.fadeOut,
          railIndex: bg.railIndex,
          type: 'bgVideo',
        }))
    );
    backgroundArray = backgroundArray.concat(
      bgs
        ?.filter(bg => bg.type === 'color' || bg.type === 'image')
        .map((ci, index) => ({
          id: Number(ci.backgroundId),
          duration: ci.duration,
          insertTime: ci.insertTime,
          startTimestamp: ci.insertTime,
          endTimestamp: ci.insertTime + ci.duration,
          type: 'bgColorImage',
          railIndex: ci.railIndex,
          index,
        }))
    );
  }

  return ['metahuman'].includes(type)
    ? segments
        ?.filter((seg: ISegment) => seg.mediaType === type)
        ?.map(seg => ({
          id: Number(seg.segmentId),
          duration: seg.duration,
          insertTime: seg.insertTime,
          startTimestamp: seg.insertTime,
          endTimestamp: seg.insertTime + seg.duration,
          volume: seg.volume,
          fadeIn: seg?.fadeIn,
          fadeOut: seg?.fadeOut,
          railIndex: seg.railIndex,
          type,
        }))
    : type === 'clip' ||
      type === 'image' ||
      type === 'video' ||
      type === 'audio' ||
      type === 'title'
    ? clipArray
    : backgroundArray;
};
