export default function CardShadow({ pageNum }: { pageNum: number }) {
  return (
    <div className="flex items-center min-w-[12px]">
      {pageNum > 1 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="7"
          height="66"
          viewBox="0 0 7 66"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.000244141 65.1889C3.55847 63.6572 6.04974 60.1193 6.04974 55.9995V9.99953C6.04974 5.88012 3.55889 2.34247 0.00115286 0.810547C1.88098 2.6287 3.04974 5.17751 3.04974 7.99929V57.9993C3.04974 60.8215 1.88061 63.3707 0.000244141 65.1889Z"
            fill="#dddddd"
          />
        </svg>
      )}
      {pageNum > 2 && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="5"
          height="56"
          viewBox="0 0 5 56"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.000786832 55.4637C2.808 53.6929 4.67282 50.5641 4.67282 46.9997V8.99972C4.67282 5.43514 2.80776 2.30616 0.000244141 0.5354C1.02706 2.10613 1.62399 3.98333 1.62399 5.99996V50C1.62399 52.0162 1.02727 53.8931 0.000786832 55.4637Z"
            fill="#e2e2e2"
          />
        </svg>
      )}
    </div>
  );
}
