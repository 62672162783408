export default function VideoCard({ imgPath }: { imgPath: string }) {
  return (
    <div className="relative overflow-hidden w-full">
      <div className="absolute left-0 top-0 w-full h-full bg-none group-hover:bg-black/20 duration-500 z-10" />
      <img
        className="max-w-[214px] sm:max-w-xs md:max-w-sm lg:max-w-md xl:max-w-lg 2xl:max-w-xl group-hover:scale-[102%] duration-500"
        src={imgPath}
        alt="thumbnail"
      />
    </div>
  );
}
