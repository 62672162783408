import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import ProjectLanguageListBox from './project-language-list-box';

const INPUT_CLASS =
  'py-2.5 xl:py-3 2xl:py-4 px-4 xl:px-5 2xl:px-6 border border-grayBorder2 rounded-lg text-consoleDark text-sm focus:border-primary focus:outline-none ring-offset-0 duration-150';

type Props = {
  closeModal: () => void;
  projectInfo: NewProjectInfoType;
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  setStep: Dispatch<SetStateAction<number>>;
};

export default function StepOne({
  closeModal,
  projectInfo,
  setProjectInfo,
  setStep,
}: Props) {
  const { t } = useTranslation();
  const isDisabled = !projectInfo.projectName || !projectInfo.content;

  const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;

    setProjectInfo(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <section>
      <h3 className="text-base 2xl:text-lg text-left font-semibold leading-6 text-consoleDark mb-5 xl:mb-7 2xl:mb-[30px]">
        {t('새 프로젝트 만들기')}
      </h3>
      <div className="flex flex-col space-y-3 2xl:space-y-[14px]">
        <input
          className={INPUT_CLASS}
          autoComplete="off"
          maxLength={30}
          value={projectInfo.projectName}
          spellCheck={false}
          placeholder={t('프로젝트 제목을 입력해주세요.')}
          name="projectName"
          onChange={handleOnChange}
          onKeyDown={({ key }) => key === 'Enter' && !isDisabled && setStep(2)}
          type="text"
        />
        <input
          className={INPUT_CLASS}
          autoComplete="off"
          maxLength={50}
          value={projectInfo.content}
          spellCheck={false}
          placeholder={t('프로젝트에 대한 설명을 입력해주세요.')}
          name="content"
          onChange={handleOnChange}
          onKeyDown={({ key }) => key === 'Enter' && !isDisabled && setStep(2)}
          type="text"
        />
      </div>
      <div className="flex flex-col space-y-1 mt-4">
        <span className="text-consoleDark text-xs 2xl:text-sm">
          {t('프로젝트의 언어를 선택해주세요.')}
        </span>
        <ProjectLanguageListBox
          projectInfo={projectInfo}
          setprojectInfo={setProjectInfo}
        />
      </div>
      <div className="flex space-x-3 text-sm font-medium mt-4 xl:mt-5 2xl:mt-[22px]">
        <button
          onClick={closeModal}
          className="rounded-md bg-consoleGray text-consoleDark w-1/3 py-2.5 xl:py-3 2xl:py-4 focus:outline-none hover:bg-gray-200 duration-100">
          {t('닫기')}
        </button>
        <button
          onClick={() => setStep(2)}
          disabled={isDisabled}
          className={`rounded-md w-2/3 text-[#ececec] ${
            isDisabled
              ? 'bg-primary/50 cursor-not-allowed'
              : 'bg-primary hover:bg-primary/90'
          } duration-100`}>
          {t('다음')}
        </button>
      </div>
    </section>
  );
}
