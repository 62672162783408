import { atom } from 'recoil';

export interface ILoading {
  isLoading: boolean ;
  text: string;
  text1?: string;
  time: number;
}

export const TOGGLE_LOADING = atom({
  key: 'TOGGLE_LOADING_VIEW',
  default: { isLoading: false, text: '', text1: '', time: 0 } as ILoading,
});
