import axios from 'axios';
import { getStorageToken } from './store/member';

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

instance.interceptors.request.use(
  config => {
    const token = getStorageToken();

    if (config.token) {
      config.headers.Authorization = 'Bearer ' + config.token; // for Spring Boot back-end
    } else if (token) {
      config.headers.Authorization = 'Bearer ' + token; // for Spring Boot back-end
    } else {
      console.error('[api] token not found!!!!');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    return Promise.reject(err);
  }
);

export default instance;
