import moment from 'moment';
import { useState } from 'react';
import { PostType } from '../../../types_new/main/resource/types';
import { changeHost, findValueByKey } from '../../../utils/common';
import VideoModal from '../../VideoModal';

type Props = {
  post: PostType;
};

export default function ShowcaseCard({
  post: { metaData, postTitle, registerDate },
}: Props) {
  const [videoModal, setVideoModal] = useState({ isOpen: false, videoURL: '' });
  const thumbnail = `${findValueByKey(metaData, 'imgUrl')}`;
  const videoURL = String(findValueByKey(metaData, 'videoUrl'));

  const closeModal = () => {
    setVideoModal(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <>
      <VideoModal
        isOpen={videoModal.isOpen}
        videoURL={videoURL}
        closeModal={closeModal}
      />
      <article
        onClick={() => setVideoModal({ isOpen: true, videoURL })}
        className="w-full group cursor-pointer rounded-md">
        <div className="relative w-full rounded-t-md overflow-hidden">
          <div
            className="w-full h-44 2xl:h-[190px] bg-cover bg-center rounded-t-md group-hover:scale-105 duration-500"
            style={{ backgroundImage: `url(${changeHost(thumbnail)})` }}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-primary/0 group-hover:bg-primary/20 duration-500" />
        </div>
        <div className="py-3 px-6 xl:py-4 xl:px-7 2xl:py-5 2xl:px-8 bg-white flex flex-col space-y-3 text-black rounded-b-md">
          <h3 className="font-bold line-clamp-2 h-12">{postTitle}</h3>
          <span className="text-[#353333] text-sm font-medium max-lg:hidden">
            Ploonet Studio
          </span>
          <span className="text-xs font-medium text-[#666]">
            {moment(registerDate).format('YYYY. MM. DD.')}
          </span>
        </div>
      </article>
    </>
  );
}
