/* eslint-disable */
import { useMemo, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { block } from '../../../store/block';
import {
  centiSecond,
  markerPos,
  pages as storedPages,
  segmentsItem as storedSegmentItem,
  timelinePercentage,
} from '../../../store/pages';
import { IPages, IPreviewElement } from '../../../store/pages/types';
import { changeHost } from '../../../utils/common';
type props = {
  item: IPreviewElement;
};

export function ImageItem({ item }: props) {
  // const [, setBlockInfo] = useRecoilState(block);
  const setSelectBlock = useSetRecoilState(block);
  const [pages] = useRecoilState<IPages[]>(storedPages);
  const [segmentsItems] = useRecoilState(storedSegmentItem);
  const [markerX, setMarkerX] = useRecoilState(markerPos);
  const [_, setCs] = useRecoilState(centiSecond);
  const [percentage, setPercentage] = useRecoilState(timelinePercentage);
  const [active, setActive] = useState<number>(0);
  const image = pages[0]?.segments[0]?.image?.find(
    image => Number(image?.imageId) === Number(item?.id)
  );
  const imageIndex = pages[0]?.segments[0]?.image?.findIndex(
    image => Number(image?.imageId) === Number(item?.id)
  );

  const timelinePercent = useMemo(() => {
    for (let i = 0; i < 101; i++) {
      switch (percentage) {
        case i:
          return 6.3 - i * 0.06;
      }
    }
  }, [percentage]);

  return (
    <ul className="content__line !-z-10">
      <li>
        <div
          className={
            segmentsItems.id === item.id ? 'img__box active' : 'img__box'
          }
          style={{
            width: '100%',
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#363D59',
          }}
          onClick={() => {
            setCs(image.insertTime);
            setMarkerX(image.insertTime / timelinePercent);
            setActive(item.id);
          }}
          onMouseDown={() => {
            setSelectBlock({
              segmentsGroup: 'image',
              groupIndex: imageIndex,
            });
          }}>
          <button className="left__size__btn" />
          <div>
            <i className="timeline__img__icon" style={{ marginLeft: '10px' }} />
          </div>
          <div
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${changeHost(image?.imageUrl)})`,
              backgroundSize: 'auto 100%',
              backgroundRepeat: 'repeat-x',
              backgroundPosition: '0 50%',
              backgroundColor: '#363D59',
              borderRadius: '4px',
            }}
          />
          <button className="right__size__btn" />
        </div>
      </li>
    </ul>
  );
}
