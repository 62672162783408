import ConsoleHomeBanner from '../../components/console/home/console-home-banner';
import EditingProjectsGrid from '../../components/console/home/editing-projects-grid';
import TemplateGrid from '../../components/console/home/template-grid';

export default function ConsoleHomePage() {
  return (
    <section className="w-full">
      <ConsoleHomeBanner />
      <EditingProjectsGrid />
      <TemplateGrid />
    </section>
  );
}
