import moment from 'moment';
import { Dispatch, SetStateAction, useState } from 'react';
import { SourceType } from '../../../types_new/console/resource/types';
import { changeHost, findExtension } from '../../../utils/common';
import PDFIcon from '../../svg/console/pdf-icon';
import PPTIcon from '../../svg/console/ppt-icon';
import MusicSourceCard from './music-source-card';
import SourceDetailPop from './source-detail-pop';

const DIV_CLASS =
  'w-20 2xl:w-24 h-28 flex items-center justify-center bg-[#F0F2FD] hover:bg-[#e3e8ff] rounded-lg duration-100 ease-out';

type Props = {
  source: SourceType;
  setImageModal: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      imageURL: string;
    }>
  >;
  setVideoModal: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      videoURL: string;
    }>
  >;
  getResourceList: () => void;
};

export default function MediaSourceCard({
  source,
  setImageModal,
  setVideoModal,
  getResourceList,
}: Props) {
  const [audioActive, setAudioActive] = useState('');
  const filePath =
    process.env.REACT_APP_SERVER_URL +
    source.filePath.replace('/var/www/html', '');

  const getFileIcon = (extension: string) => {
    switch (extension) {
      case 'audio':
        return (
          <MusicSourceCard
            className={DIV_CLASS}
            filePath={filePath}
            audioActive={audioActive}
            setAudioActive={setAudioActive}
          />
        );
      case 'pdf':
        return (
          <div
            onClick={() => window.open(filePath)}
            className={`${DIV_CLASS} bg-[#FDF2F3] hover:bg-[#fff0f1]`}>
            <PDFIcon />
          </div>
        );
      default:
        return (
          <div
            onClick={() => window.open(filePath)}
            className={`${DIV_CLASS} bg-[#FDF2F3] hover:bg-[#fff0f1]`}>
            <PPTIcon />
          </div>
        );
    }
  };

  return (
    <>
      {source.fileName && (
        <li className="relative flex flex-col items-center text-center w-full !overflow-visible">
          <SourceDetailPop
            resourceId={source.ID}
            fileSize={source.metaData.filesize}
            date={source.date}
            getResourceList={getResourceList}
          />
          <div className="w-full flex justify-center relative mb-3 rounded-lg cursor-pointer overflow-hidden group">
            {findExtension(source.fileName) === 'video' ||
            findExtension(source.fileName) === 'image' ? (
              <div
                onClick={() => {
                  if (findExtension(source.fileName) === 'video') {
                    setVideoModal({ isOpen: true, videoURL: filePath });
                  } else {
                    setImageModal({ isOpen: true, imageURL: filePath });
                  }
                }}
                className="w-full h-28 bg-cover bg-center bg-no-repeat group-hover:scale-105 duration-300 ease-out bg-primary/10"
                style={{
                  backgroundImage: `url(${changeHost(source.previewImage)})`,
                }}
              />
            ) : (
              getFileIcon(findExtension(source.fileName))
            )}
          </div>

          <div className="flex flex-col space-y-1">
            <span className="text-sm text-consoleDark line-clamp-1">
              {source.fileName}
            </span>
            <span className="text-xs text-[#adadad]">
              {moment(source.date).format('YYYY.MM.DD HH:mm')}
            </span>
          </div>
        </li>
      )}
    </>
  );
}
