import { Popover, Transition } from '@headlessui/react';
import moment from 'moment';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { delResource } from '../../../networks/source.service';
import { useModalAtom } from '../../../recoil/atoms/store';
import { formatFileSize } from '../../../utils/common';
import BinIcon from '../../svg/console/bin-icon';
import DetailIcon from '../../svg/console/detail-icon';
import InfoIcon from '../../svg/console/info-icon';

type Props = {
  resourceId: number;
  fileSize: number;
  date: string;
  getResourceList: () => void;
};

export default function SourceDetailPop({
  resourceId,
  fileSize,
  date,
  getResourceList,
}: Props) {
  const setUseModal = useSetRecoilState(useModalAtom);
  const { t } = useTranslation();
  
  const removeResource = () => {
    setUseModal({ type: 'loader', isOpen: true });
    delResource({ resourceId })
      .then(getResourceList)
      .catch(console.log)
      .finally(() => setUseModal({ type: 'loader', isOpen: false }));
  };

  return (
    <div className="absolute top-1.5 right-1.5 z-10 duration-150">
      <Popover className="relative">
        <Popover.Button className="border border-grayBorder rounded-md focus:outline-none ring-offset-0 ring-0">
          <DetailIcon />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-150"
          enterFrom="opacity-0 scale-95 translate-x-2 -translate-y-2"
          enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
          leaveTo="opacity-0 scale-95 translate-x-2 -translate-y-2">
          <Popover.Panel className="absolute top-0 -left-[150px] w-[144px] z-20 p-4 overflow-hidden rounded-lg shadow-lg bg-white">
            {({ close }) => (
              <div className="text-xs">
                <div className="flex items-center space-x-0.5 mb-3">
                  <h5 className="text-[#444] font-medium">{t('상세정보')}</h5>
                  <InfoIcon />
                </div>
                <div className="flex justify-between mb-2.5 text-[#999]">
                  <span>{t('업로드')}</span>
                  <span>{moment(date).format('YYYY.MM.DD')}</span>
                </div>
                <div className="flex justify-between text-[#999]">
                  <span>{t('용량')}</span>
                  <span>{formatFileSize(fileSize)}</span>
                </div>
                <div className="w-full h-px bg-[#E9E9E9] my-3" />
                <button
                  onClick={() => {
                    close();
                    removeResource();
                  }}
                  className="w-full text-left py-1.5 rounded-md flex items-center space-x-2 hover:bg-primary/10 duration-100">
                  <BinIcon />
                  <span className="text-[#666]">{t('삭제하기')}</span>
                </button>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
