import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Pagination from '../../components/common/Pagination';
import SubpageTitle from '../../components/main/landing/subpage-title';
import ShowcaseCard from '../../components/main/showcase/showcase-card';
import ShowcaseSkeleton from '../../components/main/showcase/showcase-skeleton';
import SubpageContainer from '../../components/main/subpage-container';
import { doGetShowcaseList } from '../../networks/source.service';
import { lang } from '../../recoil/atoms/user';
import { PostType } from '../../types_new/main/resource/types';

const TITLE =
  '영상 제작과 편집, 망설여 지신다면?\n플루닛에서 스튜디오의 편집툴로 제작한 영상들을 먼저 만나세요.';
const POSTS_PER_PAGE = 9;

export default function ShowcasePage() {
  const [loading, setLoading] = useState(true);
  const [showcaseList, setShowcaseList] = useState<PostType[]>([]);
  const [total, setTotal] = useState(0);
  const [parentPage, setParentPage] = useState(1);
  const language = useRecoilValue(lang);
  const [reqBody, setReqBody] = useState({
    lang: language,
    postsPerPage: POSTS_PER_PAGE,
    paged: 1,
  });
  const showcaseRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLoading(true);
    doGetShowcaseList(reqBody)
      .then(({ filteredPosts, total }) => {
        setTotal(total);
        setShowcaseList(filteredPosts);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [reqBody]);

  const onPageChange = (paged: number) => {
    showcaseRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
    setReqBody(prev => ({
      ...prev,
      paged,
    }));
    setParentPage(paged);
  };

  return (
    <SubpageContainer>
      <div
        ref={showcaseRef}
        className="pb-20 md:pb-28 lg:pb-36 xl:pb-40 2xl:pb-52">
        <SubpageTitle badge="쇼케이스" title={TITLE} />
        {loading ? (
          <ShowcaseSkeleton arrLength={9} />
        ) : (
          <ShowcaseGrid showcaseList={showcaseList} />
        )}
        <Pagination
          onPageChange={onPageChange}
          totalItems={total}
          currentPage={reqBody.paged}
          parentPage={parentPage}
          itemsPerPage={POSTS_PER_PAGE}
        />
      </div>
    </SubpageContainer>
  );
}

type Props = {
  showcaseList: PostType[];
};
function ShowcaseGrid({ showcaseList }: Props) {
  return (
    <>
      {showcaseList?.length ? (
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-7 gap-y-3 md:gap-y-5 lg:gap-y-10 xl:gap-y-12 2xl:gap-y-16 mt-10 sm:mt-12 lg:mt-16 2xl:mt-[72px]">
          {showcaseList.map((post, idx) => (
            <li key={`showcase-${idx}`}>
              <ShowcaseCard post={post} />
            </li>
          ))}
        </ul>
      ) : (
        <div className="text-center mt-20 md:mt-40">
          <span className="text-base md:text-lg xl:text-xl font-light">
            포스트를 찾을 수 없습니다.
          </span>
        </div>
      )}
    </>
  );
}
