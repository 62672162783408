import { atom } from 'recoil';
import { IBlock } from './types';

// 선택한 아이템의 type과 index가 저장됨.
export const block = atom({
  key: 'block',
  default: {} as IBlock,
});

// export const setBlock = (setPage: any, value: IBlock) => {
//   setPage(value);
// };
