import { Dialog, Transition } from '@headlessui/react';
import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { passwordRegex } from '../../../../constants/regex';
import { changePassword } from '../../../../networks/member.service';
import { InformModalType } from '../../../../types_new/commonTypes';
import { UpdatePasswordReqType } from '../../../../types_new/main/service/types';
import Loader from '../../../common/loader';
import ErrorIcon from '../../../svg/error-icon';

const INITIAL_STATE = {
  passwd: '',
  newpasswd: '',
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  setInformModal: Dispatch<SetStateAction<InformModalType>>;
};

export default function PasswordModal({
  isOpen,
  closeModal,
  setInformModal,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [changeInfo, setChangeInfo] =
    useState<UpdatePasswordReqType>(INITIAL_STATE);
  const { t } = useTranslation();

  const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    setChangeInfo(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const isPasswordValid = () => {
    return passwordRegex.test(changeInfo.newpasswd);
  };

  const isPasswordSame = () => {
    const { newpasswd } = changeInfo;
    if (!confirmPassword.length) return true;

    return confirmPassword === newpasswd;
  };

  const isSatisfied = () => {
    return isPasswordValid() && isPasswordSame() && changeInfo.passwd.length;
  };

  const requestChangePassword = () => {
    if (!isSatisfied()) return;

    setLoading(true);
    changePassword(changeInfo)
      .then(({ data }) => {
        if (data.code === '200') {
          handleSuccess();
        } else {
          handleFail(data.body.message);
        }
      })
      .catch(handleFail)
      .finally(() => setLoading(false));

    function handleSuccess() {
      closeModal();
      resetState();
      setInformModal({
        isOpen: true,
        title: '비밀번호 변경 완료',
        message: '비밀번호 변경이 완료되었습니다.',
      });
    }

    function handleFail(errorPhrase: string) {
      setInformModal({
        isOpen: true,
        title: errorPhrase || '네트워크 오류',
        message: errorPhrase
          ? '비밀번호를 다시 한 번 확인해주세요.'
          : '잠시 후 다시 시도해주세요.',
      });
    }
  };

  const resetState = () => {
    setChangeInfo(INITIAL_STATE);
    setConfirmPassword('');
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => ''}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="max-sm:h-screen w-full sm:max-w-lg xl:max-w-xl 2xl:max-w-2xl transform overflow-hidden sm:rounded-lg text-left bg-white p-5 sm:p-10 shadow-lg transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl 2xl:text-2xl font-semibold text-black mb-10">
                  {t('비밀번호 변경')}
                </Dialog.Title>
                <div className="flex flex-col space-y-2 mt-3">
                  <span className="text-[#353333] text-sm">
                    {t('현재 비밀번호')}
                  </span>
                  <input
                    className="border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5"
                    type="password"
                    onKeyDown={({ key }) =>
                      key === 'Enter' && requestChangePassword()
                    }
                    name="passwd"
                    value={changeInfo.passwd}
                    onChange={handleOnChange}
                    placeholder={t('현재 비밀번호를 입력해주세요.')}
                  />
                </div>
                <div className="flex flex-col space-y-2 mt-3">
                  <span className="text-[#353333] text-sm">
                    {t('변경 비밀번호')}
                  </span>
                  <input
                    className="border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5"
                    type="password"
                    onKeyDown={({ key }) =>
                      key === 'Enter' && requestChangePassword()
                    }
                    name="newpasswd"
                    value={changeInfo.newpasswd}
                    onChange={handleOnChange}
                    placeholder={t('변경 비밀번호를 입력해주세요.')}
                  />
                </div>
                <div className="flex flex-col space-y-2 mt-3">
                  <span className="text-[#353333] text-sm">
                    {t('비밀번호 확인')}
                  </span>
                  <input
                    className="border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5"
                    type="password"
                    onKeyDown={({ key }) =>
                      key === 'Enter' && requestChangePassword()
                    }
                    value={confirmPassword}
                    onChange={({ target }) => setConfirmPassword(target.value)}
                    placeholder={t('변경 비밀번호를 입력해주세요.')}
                  />
                </div>
                <p
                  className={`mt-3 text-xs leading-[18px] ${
                    !isPasswordValid() && changeInfo.newpasswd.length
                      ? 'text-error'
                      : 'text-[#5B5B5B]'
                  }`}>
                  {t('영문, 숫자, 특수문자 포함 8~16자로 입력해주세요.')}
                </p>
                <div
                  className={`mt-1 lg:mt-2 text-error text-xs items-center space-x-1 ${
                    isPasswordSame() ? 'hidden' : 'flex'
                  }`}>
                  <ErrorIcon />
                  <span className="block">
                    {t('비밀번호가 일치하지 않습니다.')}
                  </span>
                </div>
                <div className="w-full flex space-x-2 sm:space-x-3 mt-5">
                  <button
                    className={`w-1/3 py-3 sm:py-3.5 2xl:py-4 bg-[#EEEEF0] text-[#5b5b5b] rounded-md hover:bg-opacity-90 duration-100 ${
                      loading && 'cursor-default'
                    }`}
                    disabled={loading}
                    onClick={() => {
                      resetState();
                      closeModal();
                    }}>
                    {t('닫기')}
                  </button>
                  <button
                    onClick={requestChangePassword}
                    disabled={!isSatisfied() || loading}
                    className={`w-2/3 py-3 sm:py-3.5 2xl:py-4 rounded-md flex justify-center ${
                      !isSatisfied() || loading
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                        : 'bg-primary text-white hover:bg-opacity-90'
                    } duration-100`}>
                    {loading ? <Loader /> : t('변경하기')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
