export default function ItalicIcon() {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4316 1.75H5.52539C5.46523 1.75 5.41602 1.79922 5.41602 1.85938V2.73438C5.41602 2.79453 5.46523 2.84375 5.52539 2.84375H8.00273L5.86992 10.2812H3.65234C3.59219 10.2812 3.54297 10.3305 3.54297 10.3906V11.2656C3.54297 11.3258 3.59219 11.375 3.65234 11.375H9.55859C9.61875 11.375 9.66797 11.3258 9.66797 11.2656V10.3906C9.66797 10.3305 9.61875 10.2812 9.55859 10.2812H7.00742L9.14023 2.84375H11.4316C11.4918 2.84375 11.541 2.79453 11.541 2.73438V1.85938C11.541 1.79922 11.4918 1.75 11.4316 1.75Z"
        fill="#A3AAB8"
      />
    </svg>
  );
}
