type Props = {
  isProjectPage?: boolean;
};

export default function HomeIcon({ isProjectPage }: Props) {
  return (
    <svg
      className="w-[22px] h-[22px]"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`${
          isProjectPage && 'fill-white group-hover:fill-slate-300 duration-150'
        }`}
        d="M13.7499 20.2263V15.6429C13.7499 14.9136 13.4602 14.2141 12.9445 13.6984C12.4287 13.1827 11.7293 12.8929 10.9999 12.8929C10.2706 12.8929 9.5711 13.1827 9.05538 13.6984C8.53965 14.2141 8.24992 14.9136 8.24992 15.6429V20.2263H3.66659C3.18036 20.2263 2.71404 20.0331 2.37022 19.6893C2.02641 19.3455 1.83325 18.8792 1.83325 18.393V8.43062C1.83325 8.11403 1.91523 7.80283 2.07121 7.52733C2.22719 7.25183 2.45186 7.02142 2.72334 6.85853L10.0567 2.45853C10.3416 2.28757 10.6676 2.19727 10.9999 2.19727C11.3322 2.19727 11.6582 2.28757 11.9432 2.45853L19.2765 6.85853C19.548 7.02142 19.7726 7.25183 19.9286 7.52733C20.0846 7.80283 20.1666 8.11403 20.1666 8.43062V18.393C20.1666 18.8792 19.9734 19.3455 19.6296 19.6893C19.2858 20.0331 18.8195 20.2263 18.3333 20.2263H13.7499Z"
        fill="#44505F"
      />
    </svg>
  );
}
