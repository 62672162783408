import { Dispatch, SetStateAction } from 'react';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import {
  MetahumanListType,
  MetahumanTabType,
} from '../../../types_new/main/resource/types';
import PostureCard from './posture-card';

type Props = {
  setTab: Dispatch<SetStateAction<MetahumanTabType>>;
  metahumanList: MetahumanListType[];
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  projectInfo: NewProjectInfoType;
};
export default function PostureGrid({
  setTab,
  metahumanList,
  setProjectInfo,
  projectInfo,
}: Props) {
  const { defaultMetahuman } = projectInfo;
  const allPoses = metahumanList.find(
    item => item.ID === defaultMetahuman.modelId
  )?.poses;
  const postureList = allPoses?.filter(
    item => item.styleId === defaultMetahuman.styleId
  );

  return (
    <div className="relative">
      <ul className="grid grid-cols-7 gap-4 max-h-72 2xl:max-h-80 h-full overflow-y-auto no-scroll px-1 pt-1.5 pb-4">
        {postureList?.map((item, idx) => (
          <li key={`metahuman-${idx}`}>
            <PostureCard
              setTab={setTab}
              setProjectInfo={setProjectInfo}
              projectInfo={projectInfo}
              posture={item}
            />
          </li>
        ))}
      </ul>
      <div className="absolute bottom-0 w-full h-5 bg-gradient-to-t from-white/90 to-white/0" />
    </div>
  );
}
