import { useEffect, useRef } from 'react';
import { Text, Transformer } from 'react-konva';

const TitleLayer = ({
  i,
  rect,
  textSelected,
  setTextSelected,
  setBlocks,
  setSegmentsItems,
  setType,
  setItemId,
  pages,
  initXY,
  setSegmentsInfo,
  setPages,
  textSource,
  segmentsItems,
  selectShape,
}) => {
  const textRef: any = useRef();
  const textTrRef: any = useRef();

  useEffect(() => {
    if (
      textSource?.find(item => item.titleId === segmentsItems?.id)?.posY !==
      undefined
    ) {
      if (textTrRef.current) {
        textTrRef.current.nodes([textRef.current]);
        textTrRef.current.getLayer().batchDraw();
        selectShape(null);
      }
    } else if (textTrRef.current) {
      textTrRef.current.nodes([]);
      textTrRef.current.getLayer().batchDraw();
    }
  }, [segmentsItems, textSelected]);

  return (
    <>
      <Text
        key={i}
        text={rect?.text}
        onClick={() => {
          // eslint-disable-next-line radix
          setTextSelected(parseInt(rect?.titleId));
          const titleIndex = pages[0]?.segments[0]?.title?.findIndex(
            (element: any) => element?.titleId === rect?.titleId
          );
          setBlocks({
            segmentsGroup: 'title',
            groupIndex: titleIndex,
          });
          setSegmentsItems({
            ...rect,
            id: rect?.titleId,
          });
          setType('title');
          setItemId(Number(rect?.titleId));
        }}
        onMouseDown={() => {
          // eslint-disable-next-line radix
          setTextSelected(parseInt(rect?.titleId));
          const titleIndex = pages[0]?.segments[0]?.title?.findIndex(
            (element: any) => element?.titleId === rect?.titleId
          );
          setBlocks({
            segmentsGroup: 'title',
            groupIndex: titleIndex,
          });
          setSegmentsItems({
            ...rect,
            id: rect?.titleId,
          });
          setType('title');
          setItemId(Number(rect?.titleId));
        }}
        onTap={e => {
          // eslint-disable-next-line radix
          setTextSelected(parseInt(rect?.titleId));
          setSegmentsItems({
            ...rect,
            id: rect?.titleId,
          });
        }}
        ref={textRef}
        x={rect.textSource?.posX && '' ? 33 : (initXY.width * rect?.posX) / 100}
        y={rect?.posY && '' ? 33 : (initXY.height * rect?.posY) / 100}
        border="1px solid #aaaaaa"
        fill={rect?.style?.color ? rect?.style?.color : null}
        fontFamily={rect?.style?.fontFamily ? rect?.style?.fontFamily : null}
        fontStyle={
          rect?.style?.fontStyle
            ? `italic ${
                rect?.style?.bold === true
                  ? 800
                  : rect?.style?.fontWeight === 'Thin'
                  ? 200
                  : rect?.style?.fontWeight === 'Light'
                  ? 300
                  : rect?.style?.fontWeight === ' Regular'
                  ? 400
                  : rect?.style?.fontWeight === 'Medium'
                  ? 500
                  : rect?.style?.fontWeight === 'Bold'
                  ? 600
                  : rect?.style?.fontWeight === 'Black'
                  ? 900
                  : ''
              }`
            : `normal ${
                rect?.style?.bold === true
                  ? 800
                  : rect?.style?.fontWeight === 'Thin'
                  ? 200
                  : rect?.style?.fontWeight === 'Light'
                  ? 300
                  : rect?.style?.fontWeight === ' Regular'
                  ? 400
                  : rect?.style?.fontWeight === 'Medium'
                  ? 500
                  : rect?.style?.fontWeight === 'Bold'
                  ? 600
                  : rect?.style?.fontWeight === 'Black'
                  ? 900
                  : ''
              }`
        }
        align={rect?.style?.textAlign ? rect?.style?.textAlign : null}
        textAlign={rect?.style?.textAlign ? rect?.style?.textAlign : null}
        textDecoration={
          rect?.style?.textDecoration === 'strikethrough'
            ? 'line-through'
            : rect?.style?.textDecoration === 'underline'
            ? 'underline'
            : 'overline'
        }
        fontSize={
          rect?.style?.fontSize ? Number(rect?.style?.fontSize) * 0.72 : null
        }
        letterSpacing={
          rect?.style?.letterSpacing ? Number(rect?.style?.letterSpacing) : null
        }
        lineHeight={
          rect?.style?.lineHeight ? Number(rect?.style?.lineHeight) : null
        }
        draggable
        onDragStart={() => {
          // setTest({
          //   isDragging: true,
          // });
        }}
        onDragMove={(e: any) => {
          const titleIndex = pages[0]?.segments[0]?.title?.findIndex(
            (element: any) => element?.titleId === rect?.titleId
          );
          const titleArray = { ...rect };
          titleArray.posX =
            Math.floor(((e.target.x() * 100) / initXY.width) * 100) / 100;
          titleArray.posY =
            Math.floor(((e.target.y() * 100) / initXY.height) * 100) / 100;

          const type = {
            pagesIndex: 0,
            title: {
              titleIndex,
              title: titleArray,
            },
          };
          setSegmentsInfo(pages, setPages, type);
        }}
      />
      {textSelected === rect.titleId && (
        <Transformer
          ref={textTrRef}
          rotateEnabled={false}
          resizeEnabled={false}
          padding={10}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default TitleLayer;
