import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { CreditUsageType } from '../../../../types_new/main/service/types';
import { getOrder, getValueByKey } from '../../../../utils/common';
import NoListCard from '../no-list-card';

const tableHeadList = ['순서', '상품', '크레딧', '사용일시'];

type Props = {
  creditData: CreditUsageType[];
  loading: boolean;
  paged: number;
};

export default function CreditTable({ creditData, loading, paged }: Props) {
  const { t } = useTranslation();

  return (
    <>
      {!creditData.length && !loading ? (
        <NoListCard text="크레딧" />
      ) : (
        <table className="w-full text-left mt-12 max-sm:hidden">
          <thead className="text-center bg-[#07102c]/5 ">
            <tr>
              {tableHeadList.map((tableHead, idx) => (
                <th
                  key={`tableHead-${idx}`}
                  scope="col"
                  className={`py-4 whitespace-nowrap font-bold text-sm text-[#0B1733] ${
                    tableHead === '상품' && 'text-left pl-8'
                  }`}>
                  {t(`${tableHead}`)}
                </th>
              ))}
            </tr>
          </thead>
          {loading ? (
            <Skeleton />
          ) : (
            <tbody className="text-center">
              {creditData.map(
                (
                  { template, postTitle, metaData, registerDate, tranType },
                  idx
                ) => {
                  const credit = getValueByKey(metaData, 'credit');
                  const isBought = tranType === 'buy';
                  return (
                    <tr
                      key={`payInfo-${idx}`}
                      className="border-b border-[#D4DEEA] font-medium">
                      <td className="py-6">{getOrder(idx, paged)}</td>
                      {template ? (
                        <td className="py-6 w-[65%] text-left pl-8">
                          <span>{isBought ? '구매:' : '다운로드:'}</span>{' '}
                          <span>{template?.templateNm}</span>{' '}
                          <span className="text-gray-400 text-sm">
                            {template?.templateAuthorEmail || ''}
                          </span>
                        </td>
                      ) : (
                        <td className="py-6 w-[65%] text-left pl-8">
                          <span>{postTitle}</span>{' '}
                        </td>
                      )}

                      <td className={!isBought && 'text-[#FC0001] font-bold'}>
                        {isBought ? credit : `+${credit}`}
                      </td>
                      <td className="py-6">
                        {moment(registerDate).format('YYYY-MM-DD HH:mm')}
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          )}
        </table>
      )}
    </>
  );
}

function Skeleton() {
  const array = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <tbody className="text-center">
      {array.map((_, idx) => (
        <tr
          key={`creditSkeleton-${idx}`}
          className="border-b border-[#D4DEEA] font-medium">
          <td className="py-6">
            <div className="w-8 h-2 mx-auto bg-gray-400 rounded-full animate-pulse" />
          </td>
          <td className="py-6">
            <div className="w-14 h-2 mx-auto bg-gray-400 rounded-full animate-pulse" />
          </td>
          <td className="py-6">
            <div className="w-12 h-2 mx-auto bg-gray-400 rounded-full animate-pulse" />
          </td>
          <td className="py-6">
            <div className="w-40 h-2 mx-auto bg-gray-400 rounded-full animate-pulse" />
          </td>
        </tr>
      ))}
    </tbody>
  );
}
