import { Link, Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { lang } from '../../recoil/atoms/user';
import { getMainDir } from '../../util/common';

export default function SignupLayout() {
  const language = useRecoilValue(lang);
  const url =
    language === 'ko' ? getMainDir('/svg/logo.svg') : getMainDir('/svg/en-logo.svg');
  return (
    <main
      style={{ backgroundImage: `url(${getMainDir('/img/login-bg.png')})` }}
      className="relative w-full h-full bg-cover bg-center bg-no-repeat text-[#ececec]">
      <div className="w-full h-full bg-black/60 overflow-y-auto">
        <Link to="/">
          <i className="min-w-fit fixed top-5 left-5 sm:top-7 sm:left-7 lg:top-8 lg:left-8 xl:top-10 xl:left-10 2xl:top-12 2xl:left-12 z-10">
            <img src={url} alt="main logo" />
          </i>
        </Link>
        <Outlet />
      </div>
    </main>
  );
}
