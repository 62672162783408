import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import CloseButtonIcon from '../svg/close-button-icon';

type Props = {
  imageURL: string;
  isOpen: boolean;
  closeModal: () => void;
};

export default function ImageModal({ imageURL, isOpen, closeModal }: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="relative w-full lg:max-w-xl 2xl:max-w-2xl transform text-left align-middle transition-all">
                <button
                  className="absolute right-1/2 max-lg:translate-x-1/2 lg:-right-14 -top-14 lg:-top-0 focus:outline-none z-10"
                  onClick={closeModal}>
                  <CloseButtonIcon />
                </button>
                <img
                  className="rounded-xl max-h-96 lg:max-h-[80vh] mx-auto"
                  alt="clipart sample"
                  src={imageURL}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
