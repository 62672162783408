export default function TopButtonIcon() {
  return (
    <svg
      className="hidden group-hover:block"
      width="8"
      height="42"
      viewBox="0 0 8 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.672301 5.02168L3.52463 1.95607L3.52463 42L4.47543 42L4.47543 1.95614L7.3277 5.02168L8 4.2991L4 0L-5.02669e-07 4.2991L0.672301 5.02168Z"
        fill="white"
      />
    </svg>
  );
}
