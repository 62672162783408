import APIService from './APIService';
import apiConfig from './apiConfig.json';

export type TemplateGalleryParamsType = {
  postsPerPage: number;
  paged: number;
  sort: '' | 'credit_asc' | 'credit' | 'buyCnt';
  title: string;
  freeYn: '' | 'Y' | 'N';
};

export async function getTemplateList(params: TemplateGalleryParamsType) {
  const {
    data: { body },
  } = await APIService.get(apiConfig.studio.gallery.getRequestHistory, {
    params,
  });
  const { total, posts } = body;

  return { total, posts };
}
