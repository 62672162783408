import moment from 'moment';
import { TemplateDataType } from '../../../../types_new/main/resource/types';
import { getValueByKey } from '../../../../utils/common';

const tableHeadList = ['영상 배포명', '크레딧', '일시', '상태'];

type Props = {
  data: TemplateDataType;
};

export default function MobileTemplatePublishing({ data }: Props) {
  const displayStatus = (status: TemplateDataType['postStatus']) => {
    switch (status) {
      case 'apply':
        return '신청중';
      case 'reject':
        return '반려';
      case 'agree':
        return '승인';
      default:
        return '';
    }
  };
  const credit = getValueByKey(data.metaData, 'credit');
  const status = getValueByKey(data.metaData, 'applyStatus');
  const rejectReason = getValueByKey(data.metaData, 'rejectReason');
  const isRejected = status === 'reject';

  return (
    <div className="max-sm:flex hidden my-4 rounded-lg bg-white text-darkSecondary py-4 px-5  items-center justify-between">
      <ul className="flex flex-col space-y-3">
        {tableHeadList.map((list, idx) => (
          <li key={`list-${idx}`} className="font-normal text-sm">
            {list}
          </li>
        ))}
        {isRejected && <li className="font-normal text-sm">반려사유</li>}
      </ul>

      <ul className="flex flex-col text-sm font-medium space-y-3 text-right">
        <li>{data.postTitle}</li>
        <li>{credit}</li>

        <li> {moment(data.registerDate).format('YYYY-MM-DD')}</li>
        <li className="justify-end items-center space-x-1">
          {displayStatus(status)}
        </li>
        {isRejected && <li>{rejectReason}</li>}
      </ul>
    </div>
  );
}
