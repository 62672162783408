/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getResourceList } from '../../../networks/source.service';
import { lang } from '../../../recoil/atoms/user';
import FreeBadge from '../../common/FreeBadge';
import PayBadge from '../../common/Paybadge';
import Pagination from '../../common/pagination-new';
import MusicItem from './MusicItem';

const POSTS_PER_PAGE = 20;

export default function MusicTab({
  isModalActive,
  imageActive,
  projectId,
  t,
  setBackgroundInfo,
  backgroundInfo,
  addBackground,
  dragFunction,
  dragOver,
  uploadLoading,
  deleteLoading,
  Loader,
  uploadResource,
  FileUpload,
  deleteResource,
  active,
}) {
  const [category, setCategory] = useState('기본');
  const [defaultMusicList, setDefaultMusicList] = useState([]);
  const [purchasedMusicList, setPurchasedMusicList] = useState([]);
  const [myMusicList, setMyMusicList] = useState([]);
  const [myTotal, setMyTotal] = useState(0);
  const [purchasedTotal, setPurchasedTotal] = useState(0);
  const [audioActive, setAudioActive] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const [defaultTotal, setDefaultTotal] = useState(0);
  const audioRef = useRef<HTMLAudioElement>(null);
  const language = useRecoilValue(lang);
  const categoryArray: { category: string; length?: number }[] = [
    {
      category: '기본',
      length: defaultTotal || 0,
    },
    {
      category: '구매',
      length: purchasedTotal || 0,
    },
    {
      category: '업로드',
      length: myTotal || 0,
    },
  ];

  const typeStyle = language === 'en' ? 'text-xs' : '';

  // for getting item counts
  useEffect(() => {
    if (!isModalActive || imageActive !== 4) return;
    getResourceList({
      group: 'background',
      type: 'audio',
      projectId,
      isMine: 0,
      isPurchased: 0,
      postsPerPage: POSTS_PER_PAGE,
      paged: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setDefaultTotal(total);
      })
      .catch(console.log);

    getResourceList({
      group: 'background',
      type: 'audio',
      projectId,
      isMine: 0,
      postsPerPage: POSTS_PER_PAGE,
      paged: 1,
      isPurchased: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setPurchasedTotal(total);
      })
      .catch(console.log);

    getResourceList({
      group: 'background',
      type: 'audio',
      projectId,
      postsPerPage: POSTS_PER_PAGE,
      paged: 1,
      isMine: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setMyTotal(total);
      })
      .catch(console.log);
  }, [isModalActive, imageActive]);

  useEffect(() => {
    if (!isModalActive || imageActive !== 4) return;
    switch (category) {
      case '기본':
        getResourceList({
          group: 'background',
          type: 'audio',
          projectId,
          isMine: 0,
          isPurchased: 0,
          postsPerPage: POSTS_PER_PAGE,
          paged,
          lang: language,
        })
          .then(res => setDefaultMusicList(res.data.body.resources))
          .catch(console.log);
        break;
      case '구매':
        getResourceList({
          group: 'background',
          type: 'audio',
          projectId,
          isMine: 0,
          postsPerPage: POSTS_PER_PAGE,
          paged,
          isPurchased: 1,
          lang: language,
        })
          .then(res => setPurchasedMusicList(res.data.body.resources))
          .catch(console.log);
        break;
      case '업로드':
        getResourceList({
          group: 'background',
          type: 'audio',
          projectId,
          postsPerPage: POSTS_PER_PAGE,
          paged,
          isMine: 1,
          lang: language,
        })
          .then(res => setMyMusicList(res.data.body.resources))
          .catch(console.log);
        break;
      default:
        break;
    }
  }, [paged, isModalActive, category, imageActive]);

  useEffect(() => {
    if (!isModalActive || category !== '업로드' || imageActive !== 4) return;
    getResourceList({
      group: 'background',
      type: 'audio',
      projectId,
      postsPerPage: POSTS_PER_PAGE,
      paged,
      isMine: 1,
      lang: language,
    })
      .then(res => setMyMusicList(res.data.body.resources))
      .catch(console.log);
  }, [uploadResource, deleteResource, imageActive]);

  const cbMusicItemButtonDelete = (id: any) => {
    deleteResource(id);
  };

  const cbMusicItemButtonActive = (idx, value) => {
    setBackgroundInfo({
      ...backgroundInfo,
      sourceURL: value.filePath,
      type: 'bgaudio',
    });
    addBackground(value, 'bgaudio');
  };

  const onPageChange = (page: number) => {
    setPaged(page);
    setParentPage(page);
  };

  const handleAudioClick = useCallback(
    (audioURL: string) => {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      }
      if (audioRef.current.src === encodeURI(audioURL)) return;

      audioRef.current = new Audio(audioURL);
      audioRef.current.play();
      setIsPlaying(true);
      setAudioActive(audioURL);

      audioRef.current.onended = () => {
        setIsPlaying(false);
        setAudioActive(null);
      };
    },
    [isPlaying, audioActive]
  );

  useEffect(() => {
    if (!audioActive) return;

    audioRef.current.pause();
    setAudioActive(null);
    setIsPlaying(false);
  }, [category, imageActive, paged, active]);

  return (
    <div
      className={imageActive === 4 ? 'music__content show' : 'music__content'}>
      <audio ref={audioRef} />
      <div className="button__list">
        {categoryArray.map((list, idx) => (
          <button
            key={`list-${idx}`}
            className={category === list.category && 'active'}
            onClick={() => {
              setCategory(list.category);
              setPaged(1);
            }}>
            <span className={typeStyle}>{t(list.category)}</span>
            <span className="ml-1 text-[#7b61ff]">
              {list.length > 99 ? '99+' : list.length}
            </span>
          </button>
        ))}
      </div>
      <div
        className={
          category === '기본'
            ? 'music__list list list_st2 show default h-full'
            : 'music__list list list_st2 default'
        }>
        <ul className="h-[350px]">
          {defaultMusicList?.map((value: any, idx: number) => {
            return (
              <li key={`default-${value.source}`}>
                <FreeBadge />
                {/* <!-- play__btn에 active 추가하면 스탑 아이콘 나옵니다. --> */}
                <button
                  className={`play__btn w-[22%] ${
                    audioActive === value.source && isPlaying && 'active'
                  }`}>
                  <i
                    onClick={() => handleAudioClick(value.source)}
                    className="play__purple__icon"
                  />
                  <span>{value.lengthFormatted}</span>
                </button>
                <button
                  className={
                    backgroundInfo.sourceURL === value.filePath
                      ? 'active'
                      : undefined
                  }
                  onClick={() => {
                    setBackgroundInfo({
                      ...backgroundInfo,
                      sourceURL: value.filePath,
                      type: 'bgaudio',
                    });
                    addBackground(value, 'bgaudio');
                  }}>
                  <span>{value.title}</span>
                </button>
              </li>
            );
          })}
        </ul>

        <Pagination
          isSmall
          onPageChange={onPageChange}
          totalItems={defaultTotal}
          currentPage={paged}
          parentPage={parentPage}
          itemsPerPage={POSTS_PER_PAGE}
        />
      </div>
      <div
        className={
          category === '구매'
            ? 'music__list list list_st2 show default h-full'
            : 'music__list list list_st2 default'
        }>
        <ul className="h-[350px]">
          {purchasedMusicList?.map((value: any, idx: number) => (
            <li key={`purchased-${value.source}`}>
              <PayBadge />
              {/* <!-- play__btn에 active 추가하면 스탑 아이콘 나옵니다. --> */}
              <button
                className={`play__btn w-[22%] ${
                  audioActive === value.source && isPlaying && 'active'
                }`}>
                <i
                  onClick={() => handleAudioClick(value.source)}
                  className="play__purple__icon"
                />
                <span>{value.lengthFormatted}</span>
              </button>
              <button
                className={
                  backgroundInfo.sourceURL === value.filePath
                    ? 'active'
                    : undefined
                }
                onClick={() => {
                  setBackgroundInfo({
                    ...backgroundInfo,
                    sourceURL: value.filePath,
                    type: 'bgaudio',
                  });
                  addBackground(value, 'bgaudio');
                }}>
                <span>{value.title}</span>
              </button>
            </li>
          ))}
        </ul>

        <Pagination
          isSmall
          onPageChange={onPageChange}
          totalItems={purchasedTotal}
          currentPage={paged}
          parentPage={parentPage}
          itemsPerPage={POSTS_PER_PAGE}
        />
      </div>
      <div
        className={
          category === '업로드'
            ? 'music__list list list_st2 my show'
            : 'music__list list list_st2 my'
        }>
        <div
          className="file__upload__box"
          onDragOver={event => {
            event.preventDefault();
            event.stopPropagation();
            if (!dragOver) {
              dragFunction(event, 'over', 'audio');
            }
          }}
          onDrop={event => dragFunction(event, 'drop', 'audio')}
          onDragLeave={event => dragFunction(event, 'leave', 'audio')}
          style={{ opacity: dragOver ? 0.5 : 1 }}>
          {(uploadLoading || deleteLoading) && (
            <div className="upload_loading">
              <Loader width={120} height={120} />
              <div className="upload-loding-text">
                {uploadLoading
                  ? '파일 업로드중입니다.'
                  : deleteLoading
                  ? '파일 삭제중입니다.'
                  : ''}
              </div>
            </div>
          )}
          <input
            type="file"
            id="file__upload__music"
            className="file__upload"
            accept=".mp3, .wav"
            onChange={e => {
              uploadResource(e, 'audio');
            }}
          />
          <label htmlFor="file__upload__music">
            <img src={FileUpload} alt="file_upload" />
            <h3>
              {t('파일 업로드')} ({t('최대')} 150MB)
            </h3>
            <p className="px-12">
              {t('브라우저를 클릭하거나 파일을 끌어다가 놓으세요.')}
            </p>
            <span className="text-xs text-[#adadad]">
              {t('가능 확장자')}: .mp3, .wav
            </span>
          </label>
        </div>
        <ul className="h-[200px] overflow-y-auto secondary-scroll ">
          {myMusicList?.map((value: any, idx: number) => (
            <li key={`my-${value.source}`}>
              <MusicItem
                value={value}
                idx={idx}
                sourceURL={backgroundInfo.sourceURL}
                isActive={audioActive === idx}
                handleAudioClick={() => handleAudioClick(value.source)}
                cbButtonDelete={cbMusicItemButtonDelete}
                cbButtonActive={cbMusicItemButtonActive}
              />
            </li>
          ))}
        </ul>

        <Pagination
          isSmall
          onPageChange={onPageChange}
          totalItems={myTotal}
          currentPage={paged}
          parentPage={parentPage}
          itemsPerPage={POSTS_PER_PAGE}
        />
      </div>
    </div>
  );
}
