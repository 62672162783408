import { InquiryReqType } from '../types_new/main/service/types';
import APIService from './APIService';
import apiConfig from './apiConfig.json';
import { IGetInquiryListReq } from './dto/IPublicDto';

// 제품 문의 추가 요청
const addInquiry = async (args: any) => {
  return await APIService.post(apiConfig.studio.public.addInquiry, args);
};

// 컨텍 추가 요청
const addContact = async (args: InquiryReqType) => {
  return await APIService.post(apiConfig.studio.public.addContact, args);
};

// 컨텍 목록 요청
const getContactList = async (args: IGetInquiryListReq) => {
  return await APIService.get(apiConfig.studio.public.getContactList, {
    params: { ...args },
  });
};

// 컨텍 삭제 요청
const delContact = async (postId: number) => {
  return await APIService.delete(apiConfig.studio.public.delContact, {
    params: { postId },
  });
};

export { addContact, addInquiry, delContact, getContactList };
