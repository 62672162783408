/* eslint-disable */
import '../../css/reset.css';
import '../../css/style.css';

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { doGetProjectInfo } from '../../networks/project.service';
import { createTalkScript } from '../../services/project';
import { block } from '../../store/block';
import { PROJECT_INFO } from '../../store/common/projectInfo';
import { pages } from '../../store/pages';
import { IPages } from '../../store/pages/types';
import BackgroundModal from './BackgroundModal';
import ClipartModal from './ClipartModal';
import MetahumanModal from './MetahumanModal';
import TextModal from './TextModal';
import TransformModal from './TransformModal';
import TranslateModal from './TranslateModal';

export default function Controls({
  metahuman,
  setMetahuman,
  transform,
  setTransform,

  controls,
  setControls,
}: any) {
  /**
   * 선택된 블럭(이미지, 텍스트, 메타휴먼, 동영상 등등)
   * segmentsGroup : image, segment, title, background 중 하나
   * groupIndex : 선택한 index 번역
   */
  const [selectBlock, setSelectBlock] = useRecoilState(block);
  const pageInfo = useRecoilValue<IPages[]>(pages);
  /**
   * 1 => 메타휴먼
   * 2 => 백그라운드
   * 3 => 꾸미기
   * 4 => 텍스트
   * 6 => 번역
   */
  const [active, setActive] = useState(0);
  const { projectId } = useParams();
  const setRecoilProject = useSetRecoilState(PROJECT_INFO);
  const [projectInfo, setProjectInfo] = useState<any>({});

  useEffect(() => {
    console.log('[Controls][useEffect] -------- 1');
    doGetProjectInfo(projectId)
      .then(({ data: { body } }) => {
        setRecoilProject(body);
        setProjectInfo(body);
        if (body.pages.length === 0 && pageInfo.length === 0) {
          setActive(1);
        }
      })
      .catch(console.log);
  }, []);

  useEffect(() => {
    console.log('[Controls][useEffect] -------- 2');
    if (Object.keys(selectBlock).length) {
      const tmpPage = JSON.parse(JSON.stringify(pageInfo));
      const { segmentsGroup, groupIndex } = selectBlock;
      const data =
        tmpPage[0]?.segments[0][segmentsGroup][groupIndex]?.mediaType || '';
      if (data === 'metahuman') setActive(1);
    }
  }, [selectBlock, metahuman]);

  useEffect(() => {
    if (transform) {
      setActive(5);
      setTransform(false);
    }
  }, [transform]);

  useEffect(() => {
    if (controls === 0) {
      setActive(0);
      setControls();
    }
  }, [controls]);

  return (
    <ul className="text-center">
      <MetahumanModal
        setActive={setActive}
        active={active}
        blocks={selectBlock}
        setBlocks={setSelectBlock}
        projectInfo={projectInfo}
        createTalkScript={createTalkScript}
        metahuman={metahuman}
        setMetahuman={setMetahuman}
      />
      <BackgroundModal
        disabled={!pageInfo.length}
        setActive={setActive}
        active={active}
      />
      <ClipartModal
        disabled={!pageInfo.length}
        setActive={setActive}
        active={active}
      />
      <TextModal
        disabled={!pageInfo.length}
        setActive={setActive}
        active={active}
        projectInfo={projectInfo}
      />
      <TranslateModal
        disabled={!pageInfo.length}
        setActive={setActive}
        active={active}
        projectInfo={projectInfo}
      />
      <TransformModal setActive={setActive} active={active} />
    </ul>
  );
}
