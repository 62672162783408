import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { PaymentInfoType } from '../../../../types_new/main/resource/types';

const tableHeadList = [
  '순서',
  '항목',
  '결제수단',
  '금액',
  '크레딧 내역',
  '결제 일시',
  '환불신청',
];

type Props = {
  paymentData: PaymentInfoType[];
};

export default function PaymentTable({ paymentData }: Props) {
  const { t } = useTranslation();

  return (
    <table className="w-full text-left mt-12 max-sm:hidden">
      <thead className="text-center bg-[#07102c]/5">
        <tr>
          {tableHeadList.map((tableHead, idx) => (
            <th
              key={`tableHead-${idx}`}
              scope="col"
              className="py-4 whitespace-nowrap font-normal text-sm text-[#0B1733]">
              {t(tableHead)}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="text-center">
        {paymentData.map(
          ({ payName, payMethod, sumCost, totalCredit, payDtFrom }, idx) => (
            <tr
              key={`payInfo-${idx}`}
              className="border-b border-[#D4DEEA] font-medium">
              <td className="py-6">{idx + 1}</td>
              <td className="py-6">{payName}</td>
              <td className="py-6">{payMethod}</td>
              <td className="py-6">{sumCost}</td>
              <td className="py-6">{totalCredit}</td>
              <td className="py-6">{moment(payDtFrom).format('YYYY-MM-DD')}</td>
              <td className="text-sm py-6 font-semibold">완료</td>
            </tr>
          )
        )}
      </tbody>
    </table>
  );
}
