import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { t } from 'i18next';
import debounce from 'lodash.debounce';

import {
  Dispatch,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getGptResponse } from '../../services/gpt';
import GptLogo from '../vector/GptLogo';

export interface ModalProps {
  show: boolean;
  close: () => void;
  setPopupInfo: Dispatch<any>;
}

export default function ChatGPTModal({
  show,
  close,
  setPopupInfo,
}: ModalProps) {
  const [prompt, setPrompt] = useState('');
  const [result, setResult] = useState<string | null>();
  const [loading, setLoading] = useState(false);

  const debouncedSetPrompt = useMemo(
    () => debounce((text: string) => setPrompt(text), 200),
    []
  );

  const getResult = useCallback(async () => {
    setResult(null);
    setLoading(true);
    await getGptResponse(prompt)
      .then(setResult)
      .catch(alert)
      .finally(() => setLoading(false));
  }, [prompt]);

  const applyScript = useCallback(() => {
    if (result.length <= 350) {
      setPopupInfo(prev => ({ ...prev, scriptValue: result }));
      return;
    }
    setPopupInfo(prev => ({ ...prev, scriptValue: result.slice(0, 350) }));
  }, [result]);

  useEffect(() => {
    return () => debouncedSetPrompt.cancel();
  }, []);

  return (
    <Transition show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-[999999] overflow-y-auto pointer-events-auto"
        open={show}
        onClose={close}>
        <div
          onClick={close}
          className="flex min-h-screen items-center justify-center pt-32 text-center sm:block sm:p-0">
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true">
            &#8203;
          </span>
          <Transition
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div
              onClick={e => e.stopPropagation()}
              className="relative mx-4 inline-block w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6 sm:align-middle">
              <button
                onClick={close}
                className="absolute right-4 top-4 text-gray-400 hover:text-gray-700">
                <XMarkIcon className="h-6 w-6" />
              </button>
              <h6 className="flex items-center space-x-2">
                <GptLogo className="w-6 h-6" />
                <span className="text-lg font-medium">ChatGPT</span>
              </h6>
              <div className="relative">
                <textarea
                  rows={6}
                  className="border w-full border-black rounded-xl resize-none p-3 mt-10 disabled:bg-gray-200 disabled:border-gray-500 disabled:text-gray-700"
                  placeholder={t('ex) ChatGPT가 뭐야')}
                  disabled={loading}
                  onChange={e => debouncedSetPrompt(e.target.value)}
                />
              </div>
              <button
                className="bg-[#7b61ff] w-full py-2 rounded-md text-white font-medium hover:bg-[#9f8cfd] disabled:bg-gray-200"
                disabled={!(prompt.length > 0) || loading}
                onClick={getResult}>
                {process.env.REACT_APP_LANGUAGE === 'ko' ? '입력' : 'Write'}
              </button>
              <div className="relative">
                <textarea
                  rows={6}
                  maxLength={350}
                  className="border w-full border-black rounded-xl resize-none p-3 mt-6 disabled:bg-gray-200 disabled:border-gray-500"
                  value={result ?? ''}
                  disabled={loading || !result}
                />
                {loading && (
                  <p className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 font-medium">
                    Loading...
                  </p>
                )}
              </div>
              <button
                className="bg-[#7b61ff] w-full py-2 rounded-md text-white font-medium hover:bg-[#9f8cfd] mt-4 disabled:bg-gray-200"
                disabled={!result || loading}
                onClick={() => {
                  applyScript();
                  close();
                }}>
                {t('스크립트 적용')}
              </button>
            </div>
          </Transition>
        </div>
      </Dialog>
    </Transition>
  );
}
