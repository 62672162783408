/* eslint-disable */
import { useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { block } from '../../../store/block';
import {
  centiSecond,
  markerPos,
  segments,
  selectTransition,
  pages as storedPages,
  segmentsItem as storedSegmentItem,
  timelinePercentage,
  translateLoading,
} from '../../../store/pages';
import { IPages, IPreviewElement } from '../../../store/pages/types';
import { changeHost } from '../../../utils/common';
import Loader from '../../lottie/Loader';
type props = {
  item: IPreviewElement;
  segmentsItem: any;
};

export function MetaItem({ item, segmentsItem }: props) {
  // const setBlockInfo = useSetRecoilState(block);
  const setSelectBlock = useSetRecoilState(block);

  const setMarkerX = useSetRecoilState(markerPos);
  const setCs = useSetRecoilState(centiSecond);
  const pages = useRecoilValue<IPages[]>(storedPages);
  const [segmentsItems] = useRecoilState(storedSegmentItem);
  const [active, setActive] = useState<number>(0);
  const setTransition = useSetRecoilState(selectTransition);
  const [allSegments, setAllSegments] = useRecoilState(segments);
  const loading = useRecoilValue(translateLoading);
  const percentage = useRecoilValue(timelinePercentage);

  useEffect(() => setAllSegments(pages[0]?.segments[0]), [pages]);

  const metahuman = allSegments?.segment?.find(
    seg => Number(seg.segmentId) == Number(item.id)
  );
  const metahumanIndex = allSegments?.segment?.findIndex(
    seg => Number(seg.segmentId) == Number(item.id)
  );
  const sortedIndex = allSegments?.segment
    ?.filter(item => item.mediaType === 'metahuman')
    .findIndex(seg => seg.segmentId === item.id);

  const metahumanLength = allSegments?.segment?.filter(
    item => item.mediaType === 'metahuman'
  ).length;

  const timelinePercent = useMemo(() => {
    for (let i = 0; i < 101; i++) {
      switch (percentage) {
        case i:
          return 6.3 - i * 0.06;
      }
    }
  }, [percentage]);

  return (
    <ul className="content__line">
      <li className="relative">
        <div
          className={
            segmentsItems.id === item.id
              ? 'script__box transition active'
              : 'script__box transition'
          }
          style={{ width: '100%' }}
          onClick={() => {
            setCs(metahuman.insertTime);
            setMarkerX(metahuman.insertTime / timelinePercent);
            setActive(item.id);
          }}
          onMouseDown={() => {
            setSelectBlock({
              segmentsGroup: 'segment',
              groupIndex: metahumanIndex,
            });
          }}
          onMouseUp={() => {
            setActive(item.id);
          }}
          // onMouseEnter={moveGroupedImage}
        >
          <div
            className="metahuman__img"
            style={{
              backgroundImage: `url(${changeHost(metahuman?.source)})`,
            }}
          />
          <div className="script__text">
            {loading ? (
              <>
                <div className="flex items-center">
                  <span className="text-white text-xs">번역 중</span>
                  <i>
                    <Loader width={80} height={80} />{' '}
                  </i>
                </div>
              </>
            ) : (
              metahuman?.title
            )}
          </div>

          {sortedIndex !== metahumanLength - 1 && (
            <button
              className={`absolute ${
                metahuman?.transitionType === ''
                  ? 'transition active'
                  : 'transition'
              }`}
              onClick={e => {
                e.stopPropagation();
                setTransition({ id: segmentsItem.id, type: segmentsItem.type });
              }}
            />
          )}
        </div>
      </li>
    </ul>
  );
}
