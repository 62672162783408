export default function ZIndexIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.71429 0.571429H2.28571C1.33894 0.571429 0.571429 1.33894 0.571429 2.28571V7.71429C0.571429 8.66106 1.33894 9.42857 2.28571 9.42857H7.71429C8.66106 9.42857 9.42857 8.66106 9.42857 7.71429V2.28571C9.42857 1.33894 8.66106 0.571429 7.71429 0.571429ZM2.28571 0C1.02335 0 0 1.02335 0 2.28571V7.71429C0 8.97665 1.02335 10 2.28571 10H7.71429C8.97665 10 10 8.97665 10 7.71429V2.28571C10 1.02335 8.97665 0 7.71429 0H2.28571Z"
        fill="white"
      />
      <rect x="2" y="2" width="10" height="10" rx="2" fill="white" />
    </svg>
  );
}
