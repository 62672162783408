import { useTranslation } from 'react-i18next';

export default function MembershipInfoTable() {
  const { t } = useTranslation();

  return (
    <section className="max-lg:px-5 mt-12 lg:mt-16">
      <h4 className="text-xl lg:text-2xl 2xl:text-3xl font-bold">
        {t('스튜디오 요금 상세 기준')}
      </h4>
      <div className="border border-t-0 border-primary/70 rounded-xl overflow-hidden mt-5 lg:mt-7">
        <table className="table-fixed border border-primary/70 font-medium md:font-bold w-full">
          <thead
            className="max-md:text-sm"
            style={{
              background:
                'linear-gradient(270deg, #7B61FF -0.55%, #4635B7 99.45%)',
            }}>
            <tr>
              <th className="py-4 md:py-5 xl:text-lg border-r border-primary/70 w-1/4 md:w-[23%]">
                {t('유형')}
              </th>
              <th colSpan={2} className="py-4 md:py-5 xl:text-lg">
                {t('모든 아이템들은 유효 기간 1개월')}
              </th>
            </tr>
          </thead>
          <tbody
            className="max-md:text-[13px]"
            style={{
              background:
                'linear-gradient(150deg, #0A101B 0%, rgba(10, 16, 27, 0.00) 100%)',
            }}>
            <tr className="text-center">
              <td className="py-5 md:py-6">{t('메타휴먼')}</td>
              <td className="border-l border-primary/70" colSpan={2}>
                {t('모델 별 단가 적용 (200,000 크레딧)')}
              </td>
            </tr>
            <tr className="text-center">
              <td className="py-5 md:py-6 border-t border-primary/70">
                {t('배경')}
              </td>
              <td className="py-5 md:py-6 border border-primary/70">
                {t('이미지 / 영상 / 음악')}
              </td>
              <td className="max-md:px-3 border border-primary/70" rowSpan={2}>
                {t('아이템 별 크레딧 단가는 스토어 메뉴에서 확인')}
              </td>
            </tr>
            <tr className="text-center rounded-bl-xl">
              <td className="py-5 md:py-6 border border-primary/70">
                {t('꾸미기')}
              </td>
              <td className="py-5 md:py-6 border border-primary/70">
                {t('이미지 / 영상 / 음악 / 효과음')}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
}
