export const headerArray = [
  {
    name: '홈',
    link: '/console',
    icon: 'gnb__home__icon',
  },
  {
    name: '프로젝트',
    link: '/my-projects',
    icon: 'gnb__project__icon',
  },
  {
    className: 'w-16 -right-4',
    name: '메타휴먼',
    link: '/my-metahumans',
    icon: 'gnb__metahuman__icon',
  },
  {
    name: '내 템플릿',
    link: '/my-templates',
    icon: 'gnb__file__icon',
  },
  {
    name: '미디어',
    link: '/media',
    icon: 'gnb__file__icon',
  },
];
