import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { doGetResouceBGList } from '../../../networks/source.service';
import { lang } from '../../../recoil/atoms/user';
import { PostType } from '../../../types_new/main/resource/types';
import Pagination from '../../common/Pagination';
import ItemGrid from './item-grid';
import ItemSkeleton from './item-skeleton';

const POSTS_PER_PAGE = 8;
type Props = {
  creditleft: number;
};
export default function BackgroundSection({ creditleft }: Props) {
  const [loading, setLoading] = useState(false);
  const [backgroundList, setBackgroundList] = useState<PostType[]>([]);
  const [total, setTotal] = useState(0);
  const [parentPage, setParentPage] = useState(1);
  const audioRef = useRef<HTMLAudioElement>(new Audio(''));
  const language = useRecoilValue(lang);
  const [reqBody, setReqBody] = useState({
    lang: language,
    paged: 1,
    postsPerPage: POSTS_PER_PAGE,
  });
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    doGetResouceBGList(reqBody)
      .then(({ posts, total }) => {
        setBackgroundList(posts);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [reqBody]);

  const onPageChange = (paged: number) => {
    audioRef.current.pause();
    setReqBody(prev => ({
      ...prev,
      paged,
    }));
    setParentPage(paged);
  };

  const refreshCB = () => {
    doGetResouceBGList(reqBody)
      .then(({ posts, total }) => {
        setBackgroundList(posts);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  };

  return (
    <section>
      <h3 className="text-xl lg:text-2xl 2xl:text-3xl font-semibold">
        {t('배경')}
      </h3>
      {loading ? (
        <ItemSkeleton />
      ) : (
        <ItemGrid
          itemList={backgroundList}
          creditleft={creditleft}
          category="background"
          refreshCB={refreshCB}
          audioRef={audioRef}
        />
      )}
      <Pagination
        onPageChange={onPageChange}
        totalItems={total}
        currentPage={reqBody.paged}
        parentPage={parentPage}
        itemsPerPage={POSTS_PER_PAGE}
      />
    </section>
  );
}
