export default function ButtonSection({
  confirmSaveProject,
  t,
  previewPopupToggle,
  previewNum,
  exportPopupToggle,
  exportVoicePopupToggle,
}) {
  const maxcount = process.env.REACT_APP_PREVIEW_MAX;

  return (
    <div className="col flex alignCenter">
      <button className="save__btn" onClick={confirmSaveProject}>
        <p>{t('저장하기')}</p>
        <i className="studio__save__icon" />
      </button>
      <button className="preview__btn" onClick={previewPopupToggle}>
        <p>{t('미리보기')}</p>
        <span style={{ color: previewNum === maxcount ? 'red' : '' }}>
          {`${previewNum}/${maxcount}`}
        </span>
      </button>
      <button className="export__btn" onClick={exportPopupToggle}>
        <p>{t('영상생성')}</p>
        <i className="studio__export__icon" />
      </button>
      <button className="export__btn ml10" onClick={exportVoicePopupToggle}>
        <p>{t('음성생성')}</p>
        <i className="studio__voice__icon" />
      </button>
    </div>
  );
}
