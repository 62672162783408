import { PostType } from '../../types_new/main/resource/types';
import { changeHost, findValueByKey } from '../../utils/common';

type Props = {
  tutorial: PostType;
  openModal: (postContent: string, videoURL: string) => void;
};
export default function TutorialCard({
  tutorial: { postTitle, metaData, postContent },
  openModal,
}: Props) {
  const summary = findValueByKey(metaData, 'summary');
  const thumbnail = findValueByKey(metaData, 'imgUrl');
  const videoURL = findValueByKey(metaData, 'videoUrl');

  return (
    <div
      onClick={() => openModal(postContent, videoURL || '')}
      className="bg-white flex flex-col h-[306px] md:h-80 xl:h-96 2xl:h-[400px] rounded-md shadow-md cursor-pointer hover:shadow-xl sm:hover:-translate-y-4 lg:hover:-translate-5 duration-200 ease-in-out">
      <div className="h-[60%] p-5 lg:p-8 flex flex-col space-y-3 text-darkSecondary">
        <h3 className="xl:text-lg font-bold line-clamp-1">{postTitle}</h3>
        <div className="w-full h-px bg-[#d8d8d8]" />
        <p className="text-[13px] md:text-sm text-[#353333]">{summary}</p>
      </div>
      <div
        style={{ backgroundImage: `url(${changeHost(thumbnail)})` }}
        className="h-[40%] bg-cover bg-center rounded-b-md"
      />
    </div>
  );
}
