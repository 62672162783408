type Props = {
  isProjectPage?: boolean;
};

export default function BasketIcon({ isProjectPage }: Props) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        className={`${
          isProjectPage && 'fill-white hover:fill-slate-300 duration-150'
        }`}
        d="M2.84773 6.21821C3.09795 4.2165 4.79955 2.71436 6.81684 2.71436H15.7478C17.768 2.71436 19.4711 4.22068 19.7179 6.22573L20.9838 16.5115C21.2774 18.8964 19.4167 21.0001 17.0138 21.0001H5.53113C3.12514 21.0001 1.26359 18.8913 1.56202 16.5039L2.84773 6.21821Z"
        fill="#44505F"
      />
      <path
        className={`${isProjectPage && 'fill-[#44505F]'}`}
        d="M15.2143 5.57143C15.5693 5.57143 15.8615 5.86048 15.8148 6.21243C15.7644 6.59232 15.6692 6.96479 15.5309 7.32084C15.3155 7.87547 14.9999 8.37942 14.6019 8.80392C14.2039 9.22841 13.7315 9.56514 13.2115 9.79488C12.6915 10.0246 12.1342 10.1429 11.5714 10.1429C11.0086 10.1429 10.4513 10.0246 9.93136 9.79488C9.41139 9.56514 8.93893 9.22841 8.54097 8.80392C8.143 8.37942 7.82732 7.87547 7.61194 7.32084C7.47368 6.96479 7.37849 6.59232 7.32805 6.21243C7.28133 5.86048 7.57353 5.57143 7.92857 5.57143C8.28361 5.57143 8.56523 5.86175 8.63186 6.21049C8.67007 6.41046 8.72622 6.60656 8.79979 6.79602C8.95055 7.18426 9.17153 7.53702 9.45011 7.83417C9.72868 8.13132 10.0594 8.36703 10.4234 8.52784C10.7874 8.68866 11.1775 8.77143 11.5714 8.77143C11.9654 8.77143 12.3555 8.68866 12.7195 8.52784C13.0835 8.36703 13.4142 8.13132 13.6927 7.83417C13.9713 7.53702 14.1923 7.18426 14.3431 6.79602C14.4166 6.60656 14.4728 6.41046 14.511 6.21049C14.5776 5.86175 14.8592 5.57143 15.2143 5.57143Z"
        fill="white"
      />
    </svg>
  );
}
