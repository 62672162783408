import { useEffect, useState } from 'react';
import { templateFilterArray } from '../../../../arrays';
import { getTemplateList } from '../../../../networks/template.service';
import { TemplateType } from '../../../../types_new/main/resource/types';
import { TemplateTranType } from '../../../../types_new/main/service/types';
import Pagination from '../../../common/Pagination';
import InfoFilterSection from '../info-filter-section';
import NoListCard from '../no-list-card';
import MobileTemplateTable from './mobile-template-table';
import TempalteTable from './template-table';

const POSTS_PER_PAGE = 20;

export default function TemplateInfo() {
  const [filter, setFilter] = useState<{
    label: string;
    value: TemplateTranType | any;
  }>(templateFilterArray[0]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const [templateHistory, setTemplateHistory] = useState<TemplateType[]>([]);
  const isNoResult = !loading && !total;

  useEffect(() => {
    if (!loading) setLoading(true);

    getTemplateList({
      paged,
      postsPerPage: POSTS_PER_PAGE,
      tranType: filter.value,
    })
      .then(({ posts, total }) => {
        setTemplateHistory(posts);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [filter, paged]);

  const handleOnPageChange = (page: number) => {
    setPaged(page);
    setParentPage(page);
  };

  return (
    <section className="text-darkSecondary max-w-[1278px] w-full px-5">
      <InfoFilterSection
        total={templateHistory.length}
        filterArray={templateFilterArray}
        setFilter={setFilter}
        filter={filter}
      />
      {templateHistory.map(data => (
        <MobileTemplateTable key={data.postId} data={data} />
      ))}
      {isNoResult ? (
        <NoListCard text="템플릿" />
      ) : (
        <TempalteTable templateData={templateHistory} loading={loading} />
      )}
      <Pagination
        itemsPerPage={POSTS_PER_PAGE}
        currentPage={paged}
        totalItems={total}
        onPageChange={handleOnPageChange}
        parentPage={parentPage}
      />
    </section>
  );
}
