type Props = {
  isWhiteTheme?: boolean;
  isLeft?: boolean;
};
export default function DoubleChevron({ isLeft, isWhiteTheme }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="2.5"
      stroke="white"
      className={`w-3 h-3 ${isLeft && 'rotate-180'} ${
        isWhiteTheme && 'stroke-darkSecondary'
      }`}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
}
