import { useTranslation } from 'react-i18next';
import usePath from '../../../hooks/use-path';
import { changeHost } from '../../../utils/common';

export default function SystemPopupModal({
  closeSystemPopupModal,
  index,
  item,
  refPopupCheck,
}: any) {
  const { pathname } = usePath();
  const imgUrl = item?.metaData?.find((obj: any) => obj?.key === 'imgUrl');
  const link = item?.metaData?.find((obj: any) => obj?.key === 'link');
  const { t } = useTranslation();

  if (pathname !== '/') return;

  return (
    <>
      <div className="modal-bg">
        <div
          className="!absolute !bottom-1/2 !right-1/2 !translate-x-1/2 !translate-y-1/2 w-[480px] max-sm:w-[390px]"
          style={{
            zIndex: '990' + index,
          }}>
          <div>
            <div>
              {link?.value ? (
                <a href={link?.value} target="_blank" rel="noreferrer">
                  <img
                    src={`${changeHost(imgUrl?.value)}`}
                    alt={item?.postTitle || ''}
                  />
                </a>
              ) : (
                <img
                  src={`${changeHost(imgUrl?.value)}`}
                  alt={item?.postTitle || ''}
                />
              )}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '10px',
              }}>
              <div
                style={{ display: 'inline-flex' }}
                className="cursor-pointer">
                <input
                  type="checkbox"
                  ref={element => {
                    refPopupCheck.current[index] = element;
                  }}
                  id="noShow"
                  name="noShow"
                  className="w-5 h-5 checkInput"
                />
                &nbsp;&nbsp;
                <label htmlFor="noShow">{t('오늘 하루 보지 않기')}</label>
              </div>
              <div
                className="cursor-pointer"
                onClick={() => closeSystemPopupModal(index, item?.postId)}>
                {t('닫기')}
              </div>
            </div>
          </div>
          <div
            className="max-sm:!right-1/2 max-sm:!translate-x-1/2 max-sm:!-top-10 z-40"
            style={{
              position: 'absolute',
              top: '0',
              right: '-40px',
              cursor: 'pointer',
            }}
            onClick={() => closeSystemPopupModal(index, item?.postId)}>
            <img
              src="/main/img/icon-close-btn.svg"
              alt=""
              className="close-btn-m"
            />
          </div>
        </div>
      </div>
    </>
  );
}
