import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { doGetShowcaseList } from '../../../networks/source.service';
import { lang } from '../../../recoil/atoms/user';
import { PostType } from '../../../types_new/main/resource/types';
import ShowcaseCard from '../showcase/showcase-card';
import ShowcaseSkeleton from '../showcase/showcase-skeleton';
import LandingTitle from './landing-title';

export default function ShowcaseSection() {
  const [loading, setLoading] = useState(true);
  const [showcaseList, setShowcaseList] = useState<PostType[]>([]);
  const language = useRecoilValue(lang);

  useEffect(() => {
    doGetShowcaseList({ postsPerPage: 8, lang: language })
      .then(({ filteredPosts }) => setShowcaseList(filteredPosts))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [language]);

  return (
    <section className="w-full pb-12 lg:pb-40">
      <div className="bg-gradient-to-b from-black to-black/0 w-full h-14 lg:h-40" />
      <div className="max-w-screen-xl 2xl:max-w-screen-2xl w-full mx-auto px-5">
        <LandingTitle
          badge="공개 영상"
          title="Showcase Top"
          description="더 많은 최신 영상과 인기 영상을 확인하고 싶다면 쇼케이스를 확인해보세요."
          link="/showcase"
        />
        {loading ? (
          <ShowcaseSkeleton arrLength={4} />
        ) : (
          <>
            <ShowcaseGrid showcaseList={showcaseList} />
            <ShowcaseSwiper showcaseList={showcaseList} />
          </>
        )}
      </div>
    </section>
  );
}

type Props = { showcaseList: PostType[] };
function ShowcaseGrid({ showcaseList }: Props) {
  return (
    <ul className="hidden sm:grid grid-cols-2 xl:grid-cols-4 gap-5 2xl:gap-6 mt-12">
      {showcaseList.map((post, idx) => (
        <li key={`post-${idx}`}>
          <ShowcaseCard post={post} />
        </li>
      ))}
    </ul>
  );
}

function ShowcaseSwiper({ showcaseList }: Props) {
  const [activeIdx, setActiveIdx] = useState(0);
  return (
    <div className="mt-12 sm:hidden">
      <Swiper
        onRealIndexChange={({ realIndex }: { realIndex: number }) =>
          setActiveIdx(realIndex)
        }
        speed={400}
        className="w-full"
        spaceBetween={20}
        loop
        slidesPerView={1}>
        {showcaseList.map((post, idx) => (
          <SwiperSlide className="rounded-md" key={`showcase-${idx}`}>
            <ShowcaseCard post={post} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="mt-7 flex justify-center space-x-1.5">
        {showcaseList.map((_, idx) => (
          <div
            key={`showcaseIdx-${idx}`}
            className={`w-1.5 h-1.5 rounded-full duration-200 ${
              idx === activeIdx ? 'bg-primary' : 'bg-primary/40'
            }`}
          />
        ))}
      </div>
    </div>
  );
}
