export default function LineHeightIcon() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.625 3.0625H18.375"
        stroke="#ACAFC0"
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M2.625 17.9375H18.375"
        stroke="#ACAFC0"
        strokeWidth="1.1"
        strokeLinecap="round"
      />
      <path
        d="M10.5 5.6875L6.125 15.3125"
        stroke="#ACAFC0"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.875 12.25H13.125"
        stroke="#ACAFC0"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 5.6875L14.875 15.3125"
        stroke="#ACAFC0"
        strokeWidth="1.1"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
