import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IPaySystemSubscribeData } from '../../../../networks/dto/IPaySystemDto';
import CoinIcon from '../../../svg/coin-icon';

// type Props = {
//   credit: string;
//   isPayingUser: boolean;
// };
type Props = {
  subscribeInfo: IPaySystemSubscribeData;
  paycreditleft: string;
};
export default function PaymentCard({ subscribeInfo, paycreditleft }: Props) {
  const { t } = useTranslation();
  const isPayingUser = true;
  
  if (isPayingUser) {
    return (
      <div className="w-full flex flex-wrap max-md:flex-col max-md:space-y-5 md:items-center justify-between bg-white px-7 md:px-10 py-5 md:py-8 rounded-md">
        <div className="flex max-md:flex-col max-md:space-y-1.5 md:items-center md:space-x-6">
          <div className="flex items-end space-x-2 text-primary font-semibold">
            <h2 className="text-3xl sm:text-4xl">{t(subscribeInfo?.itemName)}</h2>
            <span>{t('구독중')}</span>
          </div>
          <div className="flex max-md:flex-col md:items-center md:space-x-3.5 max-2xl:text-sm">
            <div>
              <span className="text-primary"></span>
              <span className="ml-2">
              {moment(subscribeInfo?.limitDtFrom).format('YYYY-MM-DD')} ~{' '}
              {moment(subscribeInfo?.limitDtTo).format('YYYY-MM-DD')}
              </span>
            </div>
            <div className="w-px h-2.5 bg-[#D4DEEA] max-md:hidden" />
            <span>{t('결제 예정일')}:{' '} {moment(subscribeInfo?.payDtTo).format('YYYY-MM-DD')}</span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          {/* <span className="max-sm:text-sm text-[#808080] font-semibold">
            {t('남은 크레딧')}
          </span> */}
          <div className="flex items-center space-x-1">
            <CoinIcon />
            <span className="font-semibold text-lg lg:text-xl xl:text-2xl">
              {t('{{credit}} 크레딧 지급', {
                credit: subscribeInfo?.creditPolicy?.toLocaleString(),
              })}
              {/* {paycreditleft} */}
            </span>
          </div>
        </div>
      </div>
    );
  }
}
