import { debounce } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import OrderSelectBox from './order-select-box';
import PayTypeSelectBox from './pay-type-select-box';

type Props = {
  params: any;
  setParams: Dispatch<SetStateAction<any>>;
};

export default function GallerySearchFilter({ params, setParams }: Props) {
  const handleOnChange = debounce((value: string) => {
    setParams(prev => ({ ...prev, title: value }));
  }, 650);

  return (
    <div className="flex space-x-2 items-center w-1/2">
      <OrderSelectBox setParams={setParams} params={params} />
      <PayTypeSelectBox setParams={setParams} params={params} />
      <input
        type="text"
        placeholder="제목 검색"
        className="py-2 px-3 text-sm w-full outline-none rounded-md text-black"
        onChange={e => handleOnChange(e.target.value)}
        spellCheck={false}
      />
    </div>
  );
}
