import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { signUpBreadCrumbs as breadCrumbs } from '../../arrays';
import CompleteView from '../../components/main/signup/complete-view';
import InfoInputView from '../../components/main/signup/info-input-view';
import InitialTerms from '../../components/main/signup/initial-terms';
import TermsModal from '../../components/main/signup/terms-modal';
import TermsView from '../../components/main/signup/terms-view';

export default function SignupStepsPage() {
  const [modal, setModal] = useState(true);
  const { step } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!step) return;
    const validParams = ['terms', 'user-info', 'complete'];
    if (!validParams.includes(step)) navigateToInitial();
  }, [step]);

  const navigateToInitial = () => {
    navigate('/signup');
  };

  const getTitle = () => {
    switch (step) {
      case 'terms':
        return '플루닛 스튜디오 회원가입을 위해\n아래 서비스 이용약관에 동의해주세요.';
      case 'user-info':
        return '플루닛 스튜디오 회원가입을 위해\n필요한 정보를 입력해주세요.';
      case 'complete':
        return '플루닛 스튜디오\n가입이 완료되었습니다.';
      default:
        return '';
    }
  };

  const getProgressWidth = () => {
    switch (step) {
      case 'terms':
        return '33%';
      case 'user-info':
        return '66%';
      case 'complete':
        return '100%';
      default:
        return '0%';
    }
  };

  const renderView = () => {
    switch (step) {
      case 'terms':
        return <TermsView />;
      case 'user-info':
        return <InfoInputView />;
      case 'complete':
        return <CompleteView />;
      default:
        break;
    }
  };

  return (
    <>
      <TermsModal
        onClick={() => setModal(false)}
        isOpen={modal && step === 'terms'}
        terms={<InitialTerms />}
        closeModal={navigateToInitial}
        title={t('(필수) 플루닛 회원 서비스 약관')}
      />
      <section className="w-full h-full flex items-center justify-center">
        <div className="max-w-none md:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl max-sm:h-full max-h-full overflow-y-auto w-full py-8 2xl:py-12 px-5 sm:px-8 rounded-lg bg-black/50 backdrop-blur-sm flex flex-col items-center justify-center">
          <div className="max-w-[420px] w-full">
            <h1 className="whitespace-pre-wrap text-center text-lg md:text-xl xl:text-2xl">
              {t(getTitle())}
            </h1>

            <ul className="w-full flex justify-between mt-5 sm:mt-7 lg:mt-10">
              {breadCrumbs.map(({ label, params }) => (
                <li
                  key={params}
                  className={`text-sm xl:text-base ${
                    params === step
                      ? 'text-white font-medium'
                      : 'text-grayText font-light'
                  }`}>
                  {t(label)}
                </li>
              ))}
            </ul>

            <div className="relative w-full h-0.5 bg-tertiary my-2 md:my-3 lg:my-4">
              <div
                style={{ width: `calc(${getProgressWidth()})` }}
                className="absolute left-0 h-full bg-primary duration-1000 ease-out"
              />
            </div>
            {renderView()}
          </div>
        </div>
      </section>
    </>
  );
}
