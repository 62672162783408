export default function ErrorIcon() {
  return (
    <svg
      className="w-3 h-3"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.41602 8.91699H6.58268V5.41699H5.41602V8.91699ZM5.99935 4.25033C6.16463 4.25033 6.30327 4.19433 6.41527 4.08233C6.52688 3.97071 6.58268 3.83227 6.58268 3.66699C6.58268 3.50171 6.52688 3.36308 6.41527 3.25108C6.30327 3.13946 6.16463 3.08366 5.99935 3.08366C5.83407 3.08366 5.69563 3.13946 5.58402 3.25108C5.47202 3.36308 5.41602 3.50171 5.41602 3.66699C5.41602 3.83227 5.47202 3.97071 5.58402 4.08233C5.69563 4.19433 5.83407 4.25033 5.99935 4.25033ZM5.99935 11.8337C5.1924 11.8337 4.43407 11.6804 3.72435 11.374C3.01463 11.0679 2.39727 10.6524 1.87227 10.1274C1.34727 9.60241 0.931738 8.98505 0.625682 8.27533C0.319238 7.5656 0.166016 6.80727 0.166016 6.00033C0.166016 5.19338 0.319238 4.43505 0.625682 3.72533C0.931738 3.0156 1.34727 2.39824 1.87227 1.87324C2.39727 1.34824 3.01463 0.93252 3.72435 0.626076C4.43407 0.32002 5.1924 0.166992 5.99935 0.166992C6.80629 0.166992 7.56463 0.32002 8.27435 0.626076C8.98407 0.93252 9.60143 1.34824 10.1264 1.87324C10.6514 2.39824 11.067 3.0156 11.373 3.72533C11.6795 4.43505 11.8327 5.19338 11.8327 6.00033C11.8327 6.80727 11.6795 7.5656 11.373 8.27533C11.067 8.98505 10.6514 9.60241 10.1264 10.1274C9.60143 10.6524 8.98407 11.0679 8.27435 11.374C7.56463 11.6804 6.80629 11.8337 5.99935 11.8337ZM5.99935 10.667C7.30213 10.667 8.4056 10.2149 9.30977 9.31074C10.2139 8.40658 10.666 7.3031 10.666 6.00033C10.666 4.69755 10.2139 3.59408 9.30977 2.68991C8.4056 1.78574 7.30213 1.33366 5.99935 1.33366C4.69657 1.33366 3.5931 1.78574 2.68893 2.68991C1.78477 3.59408 1.33268 4.69755 1.33268 6.00033C1.33268 7.3031 1.78477 8.40658 2.68893 9.31074C3.5931 10.2149 4.69657 10.667 5.99935 10.667Z"
        fill="#FF4747"
      />
    </svg>
  );
}
