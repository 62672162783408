import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

const TH_STYLE =
  'py-2 xl:py-[10px] 2xl:py-3 w-1/3 text-[#666666] text-[13px] leading-[22px] font-semibold text-center';
const TD_STYLE =
  'py-5 xl:py-8 w-1/3 text-[#222222] text-[13px] leading-[22px] font-normal text-center';

type Props = {
  isOpen: boolean;
  setAgree: Dispatch<SetStateAction<boolean>>;
  closeModal: () => void;
};

export default function AgreementModal({
  isOpen,
  closeModal,
  setAgree,
}: Props) {
  const { t } = useTranslation();

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[200]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full md:max-w-[560px] h-auto md:h-[580px] flex flex-col justify-between transform overflow-hidden rounded-2xl bg-white p-5 md:py-6 md:px-10 text-left align-middle shadow-xl transition-all">
                <section>
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading--[56px] text-gray-900">
                    {t('개인정보수집/이용동의')}
                  </Dialog.Title>
                  <div className="mt-5 border-y border-black pt-5 pb-6 text-black !break-words text-[13px] leading-[22px]">
                    {t(
                      '플루닛 서비스의 영상 제작 의뢰에 대한 안내 목적으로 이벤트, 홍보 관련 참여와 이에 대한 답변을 위한 정보 송신을 위해 다음의 정보를 이용하고자 합니다.'
                    )}
                  </div>
                  <table>
                    <thead className="border-b border-[#f8f7fe] !py-[13px] bg-white">
                      <tr>
                        <th className={TH_STYLE}>{t('수집/이용 목적')}</th>
                        <th className={TH_STYLE}>{t('수집항목')}</th>
                        <th className={TH_STYLE}>{t('보유 및 이용기간')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b border-[#f8f7fe]">
                        <td className={TD_STYLE}>
                          {t(
                            '제작 안내, 이벤트 행사 및 관련 정보 / 서비스안내'
                          )}
                        </td>
                        <td className={TD_STYLE}>
                          {t('이름, 이메일, 핸드폰번호')}
                        </td>
                        <td className={TD_STYLE}>{t('수집일로부터 1년')}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="text-black text-[13px] leading-[22px] mt-6">
                    {t(
                      '귀하는 개인정보 수집, 이용 및 마케팅 활용에 동의하지 않을 권리가 있으며, 이 동의여부와 회원가입은 별도로 진행하실 수 있습니다.'
                    )}
                    <br />
                    {t(
                      '다만 동의를 거부할 경우에는 거부한 내용 관련 서비스 및 이벤트 정보를 받을 수 없습니다.'
                    )}
                  </div>
                </section>
                <div className="flex space-x-4 mt-auto max-md:mt-10">
                  <button
                    className="w-full flex justify-center rounded-md border border-[#7663f6] py-[13px] text-base leading-[22px] text-[#7663f6] hover:bg-[#7663f6]/10 focus:outline-none duration-150 ease-out"
                    onClick={closeModal}>
                    {t('닫기')}
                  </button>
                  <button
                    className="w-full flex justify-center rounded-md border border-[#7663f6] bg-[#7663f6] py-[13px] text-base leading-[22px] text-white hover:bg-[#7663f6]/90 focus:outline-none duration-150 ease-out"
                    onClick={() => {
                      setAgree(true);
                      closeModal();
                    }}>
                    {t('동의하기')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
