import { useEffect, useState } from 'react';
import { Beforeunload } from 'react-beforeunload';

// recoil
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { BEFORE_UNLOAD } from '../../store/common/beforeunload';
import { TOGGLE_LOADING } from '../../store/common/loading';

// style
import '../../css/reset.css';
import '../../css/style.css';

// containers
import MainContainer from '../../containers/MainContainer';

// components
import { doGetProjectInfo } from '../../networks/project.service';
import { isSignedAtom } from '../../networks/store/member';
import { pages, projectLang } from '../../store/pages';
import { IPages } from '../../store/pages/types';
import Loading from './Loading';
import ProjectSidebar from './ProjectSidebar';
import SubHeader from './SubHeader';

const Layout = () => {
  const [isLoading, setIsLoading] = useRecoilState(TOGGLE_LOADING);
  const { projectId } = useParams();
  const beforeunload = useRecoilValue(BEFORE_UNLOAD);
  const [controls, setControls] = useState();
  const setPageInfo = useSetRecoilState<IPages[]>(pages);
  const setProjectLang = useSetRecoilState(projectLang);
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) navigate('/login');
  }, [isLoggedIn]);

  useEffect(() => {
    setPageInfo([]);
    setIsLoading({ isLoading: false, text: '', time: 0 });
    doGetProjectInfo(projectId)
      .then(res => {
        const { pages, projectLanguage } = res.data.body;
        setPageInfo(pages || []);
        setProjectLang(projectLanguage);
      })
      .catch(console.log);
  }, []);

  return (
    <>
      {!beforeunload && (
        <Beforeunload onBeforeunload={e => e.preventDefault()} />
      )}
      <ProjectSidebar />
      <SubHeader setControls={setControls} />
      <div className="studio__wrap">
        <MainContainer controls={controls} setControls={setControls} />
      </div>

      {isLoading ? <Loading onLoading={isLoading} /> : ''}
    </>
  );
};

export default Layout;
