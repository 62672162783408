import { koreanRegex } from '../constants/regex';
import APIService from './APIService';
import apiConfig from './apiConfig.json';
import apiMultipart from './apiMultipart';
import {
  IAddPayUsageReq,
  ICreatePreviewReq,
  ICreateProjectReq,
  ICreateScriptWavReq,
  ICreateTalkScriptReq,
  ICreateVideoReq,
  ICreateVideoStatusReq,
  IProjectListReq,
  ISaveProjectReq,
  ISaveTitleReq,
} from './dto/IProjectDto';
import { createTranslateWav } from './translate.service';

// 프로젝트 생성 요청
const createProject = async (args: ICreateProjectReq) => {
  return await APIService.post(apiConfig.studio.project.createProject, args);
};

// 프로젝트 목록 조회 요청
const getProjectList = async (args: IProjectListReq) => {
  return await APIService.get(apiConfig.studio.project.getProjectList, {
    params: {
      ...args,
    },
  });
};
const doGetProjectList = async (args: IProjectListReq) => {
  return getProjectList(args).then(res => {
    const projects = res.data.body.items;
    const { total } = res.data.body;
    return { projects, total };
  });
};

// 프로젝트 정보 요청
const getProjectInfo = async (projectid: string) => {
  return await APIService.get(
    apiConfig.studio.project.getProjectInfo + projectid
  );
};
const doGetProjectInfo = async (projectid: string) => {
  return getProjectInfo(projectid);
};

// 프로젝트 저장
const saveProject = async (args: ISaveProjectReq) => {
  const { data } = await APIService.patch(apiConfig.studio.project.save, args);

  const { code } = data;
  const { errorCode } = data.body;

  return { code, errorCode, data };
};

// 프로젝트 제목 저장
const saveProjectTitle = async (args: ISaveTitleReq) => {
  return await APIService.patch(apiConfig.studio.project.saveTitle, args);
};

// 영상 생성
const createVideo = async (args: ICreateVideoReq) => {
  return await APIService.post(apiConfig.studio.project.createVideo, args);
};

// 프로젝트 복사하기
const reqDuplicateProject = async (args: { projectId: number }) => {
  const { data } = await APIService.post(
    apiConfig.studio.project.duplicateProject,
    args
  );
};

// 프로젝트 복사하기
const reqDeleteProject = async (args: { projectId: number }) => {
  const { data } = await APIService.delete(
    apiConfig.studio.project.deleteProject,
    {
      params: {
        ...args,
      },
    }
  );
  const { body, code } = data;
  return { body, code };
};

// 영상 생성 상태 조회
const getCreateVideoStatus = async (args: ICreateVideoStatusReq) => {
  return await APIService.get(apiConfig.studio.project.videoStatus, {
    params: {
      ...args,
    },
  });
};

// 영상 미리보기 생성 요청
const createPreview = async (args: ICreatePreviewReq) => {
  return await APIService.post(apiConfig.studio.project.createPreview, args);
};

// 스크립트 wav 생성 요청
const createWav = async (args: ICreateScriptWavReq) => {
  return await APIService.post(apiConfig.studio.project.createWav, args);
};
const doCreateWav = async (args: ICreateScriptWavReq) => {
  const isTextKorean = koreanRegex.test(args.text);
  if (!isTextKorean) {
    return createTranslateWav(args).then(res => {
      if (res.data.code === '200') {
        const { wavFilePath, sync } = res.data.body;
        const duration = Math.floor(res.data.body.duration);
        const formattedPath = wavFilePath.substr(13);
        return { wavFilePath: formattedPath, duration, sync };
      }
      throw new Error('처리 중 오류가 발생했습니다.');
    });
  }
  return createWav(args).then(res => {
    if (res.data.code === '200') {
      const { wavFilePath, sync } = res.data.body;
      const duration = Math.floor(res.data.body.duration * 100);
      const formattedPath = wavFilePath.substr(13);
      return { wavFilePath: formattedPath, duration, sync };
    }
    throw new Error('처리 중 오류가 발생했습니다.');
  });
};

// 프로젝트 대화스크립트 wav 생성
const CreateTalkScript = async (args: ICreateTalkScriptReq) => {
  return await APIService.post(apiConfig.studio.project.createMultiWav, args);
};

// 프로젝트 PDF PPT 추가
const addDocumentFile = async (args: FormData, type: 'ppt' | 'pdf') => {
  if (type === 'ppt') {
    return await apiMultipart.post(apiConfig.studio.project.addPPT, args);
  }
  return await apiMultipart.post(apiConfig.studio.project.addPDF, args);
};

// 프로젝트 크레딧 사용 내역 추가
const addPayUsage = async (args: IAddPayUsageReq) => {
  return await APIService.post(apiConfig.studio.project.addPayUsage, args);
};

export {
  CreateTalkScript,
  addDocumentFile,
  addPayUsage,
  createPreview,
  createProject,
  createVideo,
  doCreateWav,
  doGetProjectInfo,
  doGetProjectList,
  getCreateVideoStatus,
  getProjectList,
  reqDeleteProject,
  reqDuplicateProject,
  saveProject,
  saveProjectTitle,
};
