import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

type Props = {
  isOpen: boolean;
  title: string;
  message?: string;
  closeModal: () => void;
};

export default function InformModal({
  isOpen,
  title,
  message,
  closeModal,
}: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[999999999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-sm 2xl:max-w-md flex flex-col px-5 py-7 md:p-8 space-y-5 transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg 2xl:text-xl text-center font-semibold leading-6 text-black">
                  {title}
                </Dialog.Title>
                <p className="text-sm 2xl:text-base text-[#5b5b5b] text-center">
                  {message}
                </p>
                <button
                  className="rounded-md bg-primary w-full py-2.5 2xl:py-3 focus:outline-none font-medium hover:bg-primary/90 duration-100 text-white"
                  onClick={closeModal}>
                  닫기
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
