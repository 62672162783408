import RemoveIcon from '../../vector/RemoveIcon';

const MusicItem = ({
  value,
  idx,
  sourceURL,
  isActive,
  handleAudioClick,
  cbButtonDelete,
  cbButtonActive,
}) => {
  return (
    <li className="text-center items-center group">
      <button className={`play__btn ${isActive && 'active'}`}>
        <i onClick={handleAudioClick} className="play__purple__icon" />

        <span className="group-hover:hidden w-6">{value.lengthFormatted}</span>
        <i
          onClick={e => {
            e.preventDefault();
            cbButtonDelete(value.ID);
          }}
          className="hidden text-center items-center group-hover:block w-6">
          <RemoveIcon />
        </i>
      </button>

      <button
        className={sourceURL === value.filePath ? 'active' : undefined}
        onClick={() => cbButtonActive(idx, value)}>
        <span>{value.fileName}</span>
      </button>
    </li>
  );
};

export default MusicItem;
