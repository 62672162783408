import { Dialog, Transition } from '@headlessui/react';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { addContact } from '../../../networks/public.service';
import { userInfoAtom } from '../../../networks/store/member';
import { InquiryReqType } from '../../../types_new/main/service/types';
import Loader from '../../common/loader';
import InformModal from '../../modals/inform-modal';
import InquiryCategoryListBox from './inquiry-category-list-box';

const INITIAL_STATE = {
  contactCategory: '',
  contactCompany: '',
  contactEmail: '',
  contactTel: '',
  postContent: '',
  postTitle: '',
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function InquiryModal({ isOpen, closeModal }: Props) {
  const [loading, setLoading] = useState(false);
  const [informModal, setInformModal] = useState({
    isOpen: false,
    title: '',
    message: '',
  });
  const [inquiryForm, setInquiryForm] = useState<InquiryReqType>(INITIAL_STATE);
  const userInfo = useRecoilValue(userInfoAtom);
  const { t } = useTranslation();

  useEffect(
    () =>
      setInquiryForm(prev => ({
        ...prev,
        contactEmail: userInfo.userEmail,
        contactTel: userInfo.userPhone,
      })),
    [userInfo.userEmail, userInfo.userPhone]
  );

  const handleOnChange = ({
    target,
  }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = target;
    setInquiryForm(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const requestSendInquriy = () => {
    setLoading(true);
    addContact(inquiryForm)
      .then(({ data }) => {
        if (data.body.message === 'success') {
          handleSuccess();
        } else {
          handleFail();
        }
      })
      .catch(handleFail)
      .finally(() => setLoading(false));

    function handleSuccess() {
      setInformModal({
        isOpen: true,
        title: '등록 완료',
        message: '문의글 등록이 완료되었습니다.',
      });
    }

    function handleFail() {
      setInformModal({
        isOpen: true,
        title: '등록 실패',
        message: '잠시 후 다시 시도해주세요.',
      });
    }
  };

  const isSatisfied = () => {
    const {
      contactCategory,
      contactEmail,
      contactTel,
      postTitle,
      postContent,
    } = inquiryForm;

    return !(
      !contactCategory ||
      !contactEmail ||
      !contactTel ||
      !postTitle ||
      !postContent
    );
  };

  const closeInquiryModal = () => {
    closeModal();
    setInquiryForm(prev => ({
      ...prev,
      postTitle: '',
      postContent: '',
    }));
  };

  return (
    <>
      <InformModal
        isOpen={informModal.isOpen}
        title={informModal.title}
        message={informModal.message}
        closeModal={() => {
          if (informModal.title === '등록 완료') closeInquiryModal();
          setInformModal(prev => ({
            ...prev,
            isOpen: false,
          }));
        }}
      />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={() => ''}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center sm:p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="max-sm:h-screen w-full sm:max-w-lg xl:max-w-xl 2xl:max-w-2xl transform overflow-hidden sm:rounded-lg text-left bg-white p-5 sm:p-10 shadow-lg transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-xl 2xl:text-2xl font-semibold text-black">
                    {t('문의하기')}
                  </Dialog.Title>
                  <div className="flex flex-col space-y-2 mt-8 2xl:mt-10">
                    <div className="flex items-start space-x-1">
                      <span className="text-[#353333] text-sm">
                        {t('문의유형')}
                      </span>
                      <div className="w-1 h-1 rounded-full bg-primary translate-y-1" />
                    </div>
                    <InquiryCategoryListBox
                      form={inquiryForm}
                      setForm={setInquiryForm}
                    />
                  </div>
                  <div className="flex flex-col space-y-2 mt-3">
                    <div className="flex items-start space-x-1">
                      <span className="text-[#353333] text-sm">
                        {t('이메일')}
                      </span>
                      <div className="w-1 h-1 rounded-full bg-primary translate-y-1" />
                    </div>
                    <input
                      onChange={handleOnChange}
                      className="border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5"
                      type="email"
                      autoComplete="off"
                      onKeyDown={({ key }) =>
                        key === 'Enter' && requestSendInquriy()
                      }
                      value={inquiryForm.contactEmail}
                      placeholder={t('가입하신 이메일을 입력해주세요.')}
                      name="contactEmail"
                    />
                  </div>
                  <div className="flex flex-col space-y-2 mt-3">
                    <div className="flex items-start space-x-1">
                      <span className="text-[#353333] text-sm">
                        {t('연락처')}
                      </span>
                      <div className="w-1 h-1 rounded-full bg-primary translate-y-1" />
                    </div>
                    <input
                      onChange={handleOnChange}
                      className="border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5"
                      type="tel"
                      maxLength={20}
                      autoComplete="off"
                      onKeyDown={({ key }) =>
                        key === 'Enter' && requestSendInquriy()
                      }
                      value={inquiryForm.contactTel}
                      placeholder={t('연락가능한 번호를 입력해주세요.')}
                      name="contactTel"
                    />
                  </div>
                  <div className="flex flex-col space-y-2 mt-3">
                    <span className="text-[#353333] text-sm">{t('소속')}</span>
                    <input
                      onChange={handleOnChange}
                      className="border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5"
                      type="text"
                      maxLength={40}
                      spellCheck={false}
                      autoComplete="off"
                      onKeyDown={({ key }) =>
                        key === 'Enter' && requestSendInquriy()
                      }
                      placeholder={t('기관 또는 회사명을 입력해주세요.')}
                      value={inquiryForm.contactCompany}
                      name="contactCompany"
                    />
                  </div>
                  <div className="flex flex-col space-y-2 mt-3">
                    <div className="flex items-start space-x-1">
                      <span className="text-[#353333] text-sm">
                        {t('문의제목')}
                      </span>
                      <div className="w-1 h-1 rounded-full bg-primary translate-y-1" />
                    </div>
                    <input
                      onChange={handleOnChange}
                      className="border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5"
                      type="text"
                      autoComplete="off"
                      spellCheck={false}
                      maxLength={50}
                      onKeyDown={({ key }) =>
                        key === 'Enter' && requestSendInquriy()
                      }
                      placeholder={t('제목을 입력해주세요.')}
                      value={inquiryForm.postTitle}
                      name="postTitle"
                    />
                  </div>
                  <div className="flex flex-col space-y-2 mt-3">
                    <div className="flex items-start space-x-1">
                      <span className="text-[#353333] text-sm">
                        {t('문의내용')}
                      </span>
                      <div className="w-1 h-1 rounded-full bg-primary translate-y-1" />
                    </div>
                    <div className="relative">
                      <textarea
                        className="w-full h-20 md:h-24 lg:h-28 2xl:h-32 border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5 resize-none"
                        onKeyDown={({ key }) =>
                          key === 'Enter' &&
                          setInquiryForm(prev => ({
                            ...prev,
                            postContent: '\n',
                          }))
                        }
                        spellCheck={false}
                        onChange={handleOnChange}
                        name="postContent"
                        maxLength={200}
                        placeholder={t('문의사항을 입력해주세요.')}
                      />
                      <span className="absolute bottom-3 right-3 text-[#666] text-xs 2xl:text-sm">
                        {inquiryForm.postContent.length} / 200
                      </span>
                    </div>
                  </div>
                  <div className="w-full flex space-x-2 sm:space-x-3 mt-5">
                    <button
                      className={`w-1/3 py-3 sm:py-3.5 2xl:py-4 bg-[#EEEEF0] text-[#5b5b5b] rounded-md hover:bg-opacity-90 duration-100 ${
                        loading && 'cursor-default'
                      }`}
                      disabled={loading}
                      onClick={closeInquiryModal}>
                      {t('취소')}
                    </button>
                    <button
                      onClick={requestSendInquriy}
                      disabled={!isSatisfied() || loading}
                      className={`w-2/3 py-3 sm:py-3.5 2xl:py-4 rounded-md flex justify-center ${
                        !isSatisfied() || loading
                          ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                          : 'bg-primary text-white hover:bg-opacity-90'
                      } duration-100`}>
                      {loading ? <Loader /> : t('문의하기')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
