type Props = {
  isProjectPage?: boolean;
};

export default function HumanIcon({ isProjectPage }: Props) {
  return (
    <svg
      className="w-[22px] h-[22px]"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={`${
          isProjectPage && 'fill-white group-hover:fill-slate-300 duration-150'
        }`}
        d="M11 12.1001C4.48795 12.1001 2.19995 15.4001 2.19995 17.6001V20.9001H19.8V17.6001C19.8 15.4001 17.512 12.1001 11 12.1001Z"
        fill="#44505F"
      />
      <path
        className={`${
          isProjectPage && 'fill-white group-hover:fill-slate-300 duration-150'
        }`}
        d="M11 11.0001C13.7339 11.0001 15.95 8.78391 15.95 6.0501C15.95 3.31629 13.7339 1.1001 11 1.1001C8.26624 1.1001 6.05005 3.31629 6.05005 6.0501C6.05005 8.78391 8.26624 11.0001 11 11.0001Z"
        fill="#44505F"
      />
    </svg>
  );
}
