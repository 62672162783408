import { useTranslation } from 'react-i18next';
import { PhraseArrayType } from '../../../types_new/main/landing/types';

type Props = {
  phrases: PhraseArrayType;
  idx: number;
};

export default function GuidePhraseCard({
  phrases: { heading, text1, text2, text3 },
  idx,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col space-y-3">
      <h1 className="text-2xl lg:text-3xl 2xl:text-4xl font-bold">
        {`${idx}. ${t(heading)}`}
      </h1>
      <div className="flex flex-col text-[#bbb] text-xs sm:text-sm lg:text-base 2xl:text-lg font-light">
        <span>{t(text1)}</span>
        <span>{t(text2)}</span>
        <span>{t(text3)}</span>
      </div>
    </div>
  );
}
