/* eslint-disable */
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { headerArray } from '../../array/headerArray';
import '../../css/reset.css';
import '../../css/style.css';
import '../../locale/i18n';
import AvatarPop from '../console/core/avatar-pop';
import MyTemplateIcon from '../svg/console/my-template-icon';
import SimpleLogo from '../svg/simple-logo';

export default function ProjectSidebar() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <aside className="side studio__side">
      <Link to="/">
        <h1 style={{ transform: 'rotate(-12deg)' }}>
          <SimpleLogo />
        </h1>
      </Link>
      <nav className="gnb">
        <ul>
          {headerArray.map(({ name, link, icon }, idx) => {
            const isTemplate = idx === 3;
            return (
              <li className="cursor-pointer" key={`menu-${idx}`}>
                <div className={`ballon ${name}-${idx}`}>
                  <span>{t(name)}</span>
                </div>
                <div onClick={() => navigate(link)}>
                  {isTemplate ? (
                    <MyTemplateIcon isWhite />
                  ) : (
                    <i className={icon} />
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </nav>

      <AvatarPop />
    </aside>
  );
}
