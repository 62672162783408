/* eslint-disable */
import { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  centiSecond,
  markerPos,
  pages as storedPages,
  segmentsItem as storedSegmentItem,
  timelinePercentage,
} from '../../../store/pages';
import { IPages } from '../../../store/pages/types';

export function AudioItem({
  item,
  setChangeSoundeRange,
  handleVolume,
  handleFade,
}: any) {
  const [pages, setPages] = useRecoilState<IPages[]>(storedPages);
  const [segmentsItems] = useRecoilState(storedSegmentItem);
  const [active, setActive] = useState<number>(0);
  const [markerX, setMarkerX] = useRecoilState(markerPos);
  const [_, setCs] = useRecoilState(centiSecond);
  const [percentage, setPercentage] = useRecoilState(timelinePercentage);
  const audio = pages[0].segments[0].segment.find(
    seg => seg.segmentId === item.id
  );
  const timelinePercent = useMemo(() => {
    for (let i = 0; i < 101; i++) {
      switch (percentage) {
        case i:
          return 6.3 - i * 0.06;
      }
    }
  }, [percentage]);

  return (
    <ul className="content__line -z-10">
      <li>
        <div
          className={
            segmentsItems.id === item.id
              ? 'soundEffect__box fx active'
              : 'soundEffect__box fx'
          }
          style={{ width: '100%' }}
          onClick={e => {
            setMarkerX(audio.insertTime / timelinePercent);
            setCs(audio.insertTime);
            setActive(item.id);
          }}>
          {audio?.fadeIn > 0 && <div className="fadeIn" />}
          <div
            className="fx__box"
            onMouseOver={() => {
              setChangeSoundeRange(true);
            }}
            onMouseLeave={() => {
              setChangeSoundeRange(false);
            }}>
            <i className="fx__icon" />

            <div className="fx__content left-8" style={{ marginLeft: 26 }}>
              <div className="col">
                <input
                  type="checkbox"
                  name="fx_checkbox111"
                  value="fadeIn"
                  id="fadeInCheckbox__audio__1"
                  checked={item?.fadeIn}
                  onChange={e => handleFade(item, e)}
                />
                <label htmlFor="fadeInCheckbox__audio__1">
                  <p>Fade In</p>
                  <span className="checkbox" />
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  name="fx_checkbox"
                  value="fadeOut"
                  id="fadeOutCheckbox__audio__1"
                  checked={item?.fadeOut}
                  onChange={e => {
                    handleFade(item, e);
                  }}
                />
                <label htmlFor="fadeOutCheckbox__audio__1">
                  <p>Fade Out</p>
                  <span className="checkbox" />
                </label>
              </div>
              <div className="col">
                <i className="sound__black__icon" />
                <input
                  type="range"
                  className="sound__range"
                  style={{
                    background: `linear-gradient( to right, #7b61ff 0%, #7b61ff ${
                      item.volume * 50
                    }%, #e9e9e9 ${item.volume}%, #e9e9e9 100% )`,
                  }}
                  value={item.volume * 50}
                  onChange={e => handleVolume(item, e.target.value)}
                />
              </div>
            </div>
          </div>
          <i className="timeline__audio__icon" />
          <div className="soundEffect__name">{audio?.fileName}</div>
          {audio?.fadeOut > 0 && <div className="fadeOut" />}
        </div>
      </li>
    </ul>
  );
}
