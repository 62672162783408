import moment from 'moment';
import { PostType } from '../../../types_new/main/resource/types';
import { changeHost, findValueByKey } from '../../../utils/common';

type Props = {
  notice: PostType;
  idx: number;
  activeIdx?: number;
  openModal: (notice: PostType) => void;
};

export default function NoticeCard({
  notice,
  idx,
  activeIdx,
  openModal,
}: Props) {
  const { metaData, postTitle, registerDate } = notice;
  const thumbnail = findValueByKey(metaData, 'thumbnailUrl');
  const category = findValueByKey(metaData, 'category');
  const isAtSecondCol = idx % 3 === 2;
  const isActive = idx === activeIdx;

  function displayCategory() {
    switch (category) {
      case 'news':
        return '뉴스피드';
      default:
        return '뉴스피드';
    }
  }

  return (
    <div
      onClick={() => openModal(notice)}
      className={`${
        isAtSecondCol && 'lg:translate-y-28'
      } h-80 xl:h-[390px] 2xl:h-[450px] rounded-[10px] overflow-hidden cursor-pointer duration-300 group`}>
      <div
        style={{ backgroundImage: `url(${changeHost(thumbnail)})` }}
        className={`relative w-full h-full bg-cover bg-center rounded-[10px] group-hover:md:scale-[102%] ${
          isActive && 'max-md:scale-[102%]'
        }  duration-300`}>
        <div
          className={`absolute left-0 w-full h-full bg-black/10 group-hover:md:bg-black/40 ${
            isActive && 'max-md:bg-black/50'
          } duration-300`}
        />
        <div
          className={`absolute right-1/2 bottom-1/2 translate-x-1/2 translate-y-1/2 flex items-center justify-center rounded-full w-28 xl:w-36 h-28 xl:h-36 border border-white bg-tertiary/40 opacity-0 md:group-hover:opacity-100 ${
            isActive && 'max-md:opacity-100'
          } duration-300`}>
          <span>View</span>
        </div>
        <div className="absolute h-full flex flex-col justify-between py-5 lg:py-6 px-6 xl:py-7 xl:px-8">
          <div>{displayCategory()}</div>
          <div className="flex flex-col space-y-3 lg:space-y-5 2xl:space-y-6">
            <h3 className="line-clamp-2 lg:text-xl font-medium">{postTitle}</h3>
            <span className="text-sm text-gray-200 font-medium">
              {moment(registerDate).format('YYYY.MM.DD')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
