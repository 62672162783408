export default function NewPlusIcon() {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.59009 2.0913L7.4104 2.09122C7.48332 2.09121 7.51978 2.12766 7.51979 2.20058L7.52075 11.8256C7.52076 11.8985 7.4843 11.935 7.41138 11.935L6.59107 11.935C6.51816 11.9351 6.48169 11.8986 6.48169 11.8257L6.48072 2.20068C6.48072 2.12777 6.51717 2.0913 6.59009 2.0913Z"
        fill="white"
      />
      <path
        d="M2.40686 6.49413L11.5944 6.49321C11.6673 6.4932 11.7037 6.52966 11.7037 6.60257L11.7038 7.42289C11.7038 7.4958 11.6674 7.53227 11.5945 7.53227L2.40696 7.53319C2.33405 7.5332 2.29759 7.49674 2.29758 7.42383L2.2975 6.60352C2.29749 6.5306 2.33394 6.49414 2.40686 6.49413Z"
        fill="white"
      />
    </svg>
  );
}
