import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getResourceList } from '../../../networks/source.service';
import { lang } from '../../../recoil/atoms/user';
import { changeHost } from '../../../utils/common';
import FreeBadge from '../../common/FreeBadge';
import PayBadge from '../../common/Paybadge';
import Pagination from '../../common/pagination-new';
import AddToTimeLine from '../../vector/AddToTimeline';
import PlayPreview from '../../vector/PlayPreview';

export default function VideoTab({
  isModalActive,
  imageActive,
  t,
  addclipEtc,
  active,
  uploadLoading,
  deleteLoading,
  Loader,
  dragFunction,
  dragOver,
  uploadResource,
  FileUpload,
  deleteResource,
  projectId,
  buttonActive,
  openModal,
}) {
  const [category, setCategory] = useState('기본');
  const [defaultVideoList, setDefaultVideoList] = useState([]);
  const [purchasedVideoList, setPurchasedVideoList] = useState([]);
  const [myTotal, setMyTotal] = useState(0);
  const [purchasedTotal, setPurchasedTotal] = useState(0);
  const [myVideoList, setMyVideoList] = useState([]);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const postsPerPage = 20;
  const [defaultTotal, setDefaultTotal] = useState(0);
  const language = useRecoilValue(lang);

  const categoryArray: { category: string; length?: number }[] = [
    {
      category: '기본',
      length: defaultTotal || 0,
    },
    {
      category: '구매',
      length: purchasedTotal || 0,
    },
    {
      category: '업로드',
      length: myTotal || 0,
    },
  ];

  const typeStyle = language === 'en' ? 'text-xs' : '';

  // for getting item counts
  useEffect(() => {
    if (!isModalActive || imageActive !== 2) return;
    getResourceList({
      group: 'clip',
      type: 'video',
      projectId,
      isMine: 0,
      isPurchased: 0,
      postsPerPage,
      paged: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setDefaultTotal(total);
      })
      .catch(console.log);

    getResourceList({
      group: 'clip',
      type: 'video',
      projectId,
      isMine: 0,
      postsPerPage,
      paged: 1,
      isPurchased: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setPurchasedTotal(total);
      })
      .catch(console.log);

    getResourceList({
      group: 'clip',
      type: 'video',
      projectId,
      postsPerPage,
      paged: 1,
      isMine: 1,
      lang: language,
    })
      .then(res => {
        const { total } = res.data.body;
        setMyTotal(total);
      })
      .catch(console.log);
  }, [isModalActive, imageActive]);

  useEffect(() => {
    if (!isModalActive || imageActive !== 2) return;
    switch (category) {
      case '기본':
        getResourceList({
          group: 'clip',
          type: 'video',
          projectId,
          isMine: 0,
          isPurchased: 0,
          postsPerPage,
          paged,
          lang: language,
        })
          .then(res => setDefaultVideoList(res.data.body.resources))
          .catch(console.log);
        break;
      case '구매':
        getResourceList({
          group: 'clip',
          type: 'video',
          projectId,
          isMine: 0,
          postsPerPage,
          paged,
          isPurchased: 1,
          lang: language,
        })
          .then(res => setPurchasedVideoList(res.data.body.resources))
          .catch(console.log);
        break;
      case '업로드':
        getResourceList({
          group: 'clip',
          type: 'video',
          projectId,
          postsPerPage,
          paged,
          isMine: 1,
          lang: language,
        })
          .then(res => setMyVideoList(res.data.body.resources))
          .catch(console.log);
        break;
      default:
        break;
    }
  }, [paged, isModalActive, category, imageActive]);

  useEffect(() => {
    if (!isModalActive || category !== '업로드' || imageActive !== 2) return;
    getResourceList({
      group: 'clip',
      type: 'video',
      projectId,
      postsPerPage,
      paged,
      isMine: 1,
      lang: language,
    })
      .then(res => setMyVideoList(res.data.body.resources))
      .catch(console.log);
  }, [uploadResource, deleteResource, imageActive]);

  const handleOnPage = (page: number) => {
    window.scrollTo(0, 0);
    setPaged(page);
    setParentPage(page);
  };

  return (
    <div
      className={imageActive === 2 ? 'video__content show' : 'video__content'}>
      <div className="button__list">
        {categoryArray.map((list, idx) => (
          <button
            key={`list-${idx}`}
            className={category === list.category ? 'active' : undefined}
            onClick={() => {
              setCategory(list.category);
              setPaged(1);
            }}>
            <span className={typeStyle}>{t(list.category)}</span>
            <span className="ml-1 text-[#7b61ff]">
              {list.length > 99 ? '99+' : list.length}
            </span>
          </button>
        ))}
      </div>
      <div
        className={
          category === '기본'
            ? 'video__list list default show'
            : 'video__list list default'
        }>
        <ul className="h-[350px]">
          {defaultVideoList &&
            defaultVideoList.map((video, idx) => {
              return (
                <li className="background-video" key={`video-${idx}`}>
                  <button>
                    <span className="video__time">{video.lengthFormatted}</span>
                    <div
                      className="img__bg"
                      style={{
                        backgroundImage: `url(${changeHost(
                          video.previewImage
                        )})`,
                        //! 썸네일 수정
                        display: `${active === 0 ? 'none' : 'block'}`,
                      }}
                    />
                    <FreeBadge />
                    <div className="img__bg_hov" />
                    <p className="img__text">{video.title}</p>
                  </button>
                  <div className="hov">
                    <div className="other">
                      <div className="background__btn__list">
                        <button
                          className="play-preview__btn"
                          onClick={() => {
                            openModal(video.source);
                          }}>
                          <PlayPreview />
                        </button>
                        <button
                          onClick={() => {
                            addclipEtc({
                              mediaType: 'video',
                              ...video,
                            });
                          }}
                          className="add-to-timeline__btn">
                          <AddToTimeLine />
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
        {defaultVideoList ? (
          <Pagination
            isSmall
            onPageChange={handleOnPage}
            totalItems={defaultTotal}
            currentPage={paged}
            parentPage={parentPage}
            itemsPerPage={postsPerPage}
          />
        ) : null}
      </div>

      <div
        className={
          category === '구매'
            ? 'video__list list default show'
            : 'video__list list default'
        }>
        <ul className="h-[350px]">
          {purchasedVideoList &&
            purchasedVideoList.map((video, idx) => {
              return (
                <li className="background-video" key={`video-${idx}`}>
                  <button>
                    <span className="video__time">{video.lengthFormatted}</span>
                    <div
                      className="img__bg"
                      style={{
                        backgroundImage: `url(${changeHost(
                          video.previewImage
                        )})`,
                        display: `${active === 0 ? 'none' : 'block'}`,
                      }}
                    />
                    <PayBadge />
                    <div className="img__bg_hov" />
                    <p className="img__text">{video.title}</p>
                  </button>
                  <div className="hov">
                    <div className="other">
                      <div className="background__btn__list">
                        <button
                          className="play-preview__btn"
                          onClick={() => {
                            openModal(video.source);
                          }}>
                          <PlayPreview />
                        </button>
                        <button
                          onClick={() => {
                            addclipEtc({
                              mediaType: 'video',
                              ...video,
                            });
                          }}
                          className="add-to-timeline__btn">
                          <AddToTimeLine />
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
        {purchasedVideoList ? (
          <Pagination
            isSmall
            onPageChange={handleOnPage}
            totalItems={purchasedTotal}
            currentPage={paged}
            parentPage={parentPage}
            itemsPerPage={postsPerPage}
          />
        ) : null}
      </div>

      <div
        className={
          category === '업로드'
            ? 'video__list list my show'
            : 'video__list list my'
        }>
        <div
          className="file__upload__box"
          onDragOver={event => {
            event.preventDefault();
            event.stopPropagation();
            if (!dragOver) {
              dragFunction(event, 'over', 'video');
            }
          }}
          onDrop={event => dragFunction(event, 'drop', 'video')}
          onDragLeave={event => dragFunction(event, 'leave', 'video')}
          style={{ opacity: dragOver ? 0.5 : 1 }}>
          {(uploadLoading || deleteLoading) && (
            <div className="upload_loading">
              <Loader width={120} height={120} />
              <div className="upload-loding-text">
                {uploadLoading
                  ? '파일 업로드중입니다.'
                  : deleteLoading
                  ? '파일 삭제중입니다.'
                  : ''}
              </div>
            </div>
          )}
          <input
            type="file"
            id="file__upload__video2"
            className="file__upload"
            accept=".mp4, .avi, .mov, .mxf, .wmv"
            onChange={e => {
              uploadResource(e, 'video');
            }}
          />
          <label htmlFor="file__upload__video2">
            <img src={FileUpload} alt="file_upload" />
            <h3>
              {t('파일 업로드')} ({t('최대')} 150MB)
            </h3>
            <p className="px-12">
              {t('브라우저를 클릭하거나 파일을 끌어다가 놓으세요.')}
            </p>
            <span className="text-xs text-[#adadad]">
              {t('가능 확장자')}: .mp4, .avi, .mov, .mxf, .wmv
            </span>
          </label>
        </div>
        <ul className="h-[200px] overflow-y-auto secondary-scroll">
          {myVideoList &&
            myVideoList.map((video, idx) => {
              return (
                <>
                  <li className="background-video" key={`video-${idx}`}>
                    <button
                      className="remove__btn card__remove__icon"
                      onClick={() => {
                        deleteResource(video.ID);
                      }}
                    />
                    <button
                      className={buttonActive === idx ? 'active' : undefined}>
                      <span className="video__time">
                        {video.lengthFormatted}
                      </span>
                      <div
                        className="img__bg"
                        style={{
                          backgroundImage: `url(${changeHost(
                            video.previewImage
                          )})`,
                          display: `${active === 0 ? 'none' : 'block'}`,
                        }}
                      />
                      <div className="img__bg_hov" />
                      <p className="img__text">{video.fileName}</p>
                    </button>
                    <div className="hov">
                      <div className="other">
                        <div className="background__btn__list">
                          <button
                            className="play-preview__btn"
                            onClick={() => {
                              openModal(video.source);
                            }}>
                            <PlayPreview />
                          </button>
                          <button
                            onClick={() => {
                              addclipEtc({
                                mediaType: 'video',
                                ...video,
                              });
                            }}
                            className="add-to-timeline__btn">
                            <AddToTimeLine />
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </>
              );
            })}
        </ul>
        {myVideoList ? (
          <Pagination
            isSmall
            onPageChange={handleOnPage}
            totalItems={myTotal}
            currentPage={paged}
            parentPage={parentPage}
            itemsPerPage={postsPerPage}
          />
        ) : null}
      </div>
    </div>
  );
}
