export default function OverlineIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.61134 9.3335L6.99968 5.641L8.38218 9.3335H5.61134ZM6.41634 4.0835L3.20801 12.2502H4.52051L5.17384 10.5002H8.81968L9.47884 12.2502H10.7913L7.58301 4.0835H6.41634Z"
        fill="white"
      />
      <path
        d="M11.0827 2.91667H2.91602V1.75H11.0827V2.91667Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
}
