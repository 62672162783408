const formatArray = [
  { format: 'mp4' },
  { format: 'avi' },
  { format: 'wmv' },
  { format: 'mov' },
];

export default function ExtensionPopup({
  t,
  setExtensionShow,
  setExportPopup,
  exportPopup,
  PopupSelectBtn,
  popup,
  setPopup,
  extensionPopupToggle,
}) {
  return (
    <div className="preview-popup">
      <strong>{t('확장자 선택')}</strong>
      <button
        className="close__btn close__gray__icon popupClose"
        onClick={() => setExtensionShow(false)}
      />
      <div className="popup-select-cont">
        <button
          onClick={() =>
            setExportPopup({ ...exportPopup, select: !exportPopup.select })
          }>
          {exportPopup.extension || t('확장자 선택')}
          <img
            src={PopupSelectBtn}
            alt=""
            className="popup-arrow-icon"
            style={{ transform: exportPopup.select && 'rotate(180deg)' }}
          />
        </button>
        {exportPopup.select && popup === 4 ? (
          <div className="popup-select-box">
            <ul>
              {formatArray.map(({ format }, idx) => (
                <li
                  onClick={() => {
                    setExportPopup({
                      ...exportPopup,
                      extension: format,
                      select: !exportPopup.select,
                    });
                  }}>
                  <span>{format}</span>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
      <div className="popup-btn-cont">
        <button
          className="popupBtnLeft bg-[#bebebe] hover:bg-[#cecece] text-black"
          onClick={extensionPopupToggle}>
          {t('이전')}
        </button>

        <button
          className={`popupBtnRight ${
            exportPopup.extension ? 'opacity-100' : '!cursor-not-allowed'
          } hover:!bg-[#917bff]`}
          disabled={!exportPopup.extension}
          onClick={() => {
            setPopup(5);
            setExportPopup({ ...exportPopup, select: false });
          }}>
          {t('다음')}
        </button>
      </div>
    </div>
  );
}
