export default function AlignCenterIcon() {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <line x1="0.917969" y1="0.5" x2="14.418" y2="0.5" stroke="#ACAFC0" />
      <line x1="3.16797" y1="3.5" x2="12.168" y2="3.5" stroke="#ACAFC0" />
      <line x1="0.917969" y1="6.5" x2="14.418" y2="6.5" stroke="#ACAFC0" />
      <line x1="3.16797" y1="9.5" x2="12.168" y2="9.5" stroke="#ACAFC0" />
    </svg>
  );
}
