import { RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { linkArray } from '../../arrays';
import usePath from '../../hooks/use-path';
import useScroll from '../../hooks/use-scroll';
import { IMemberLoginData } from '../../networks/dto/IMemberDto';
import { getMyLoginInfo } from '../../networks/member.service';
import {
  isSignedAtom,
  loginInfoAtom,
  removeStorageToken,
  removeStorageUserInfo,
} from '../../networks/store/member';
import { lang } from '../../recoil/atoms/user';
import { getMainDir } from '../../util/common';
import LanguageListBox from '../main/language-list-box';
import MobileMenu from '../main/mobile-menu';

export default function LandingHeader() {
  const [mobileMenu, setMobileMenu] = useState(false);
  const { isAtMain, pathname } = usePath();
  const headerRef: RefObject<HTMLElement> = useRef(null);
  const headerHeight = headerRef?.current?.clientHeight;
  const scrollPosition = useScroll();
  const isTriggered = headerHeight && scrollPosition > headerHeight;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isSignedAtom);
  const [loginInfo, setLoginInfo] = useRecoilState(loginInfoAtom);
  const language = useRecoilValue(lang);
  const isEnglish = language === 'en';
  const logoUrl =
    language === 'ko'
      ? getMainDir('/svg/logo.svg')
      : getMainDir('/svg/en-logo.svg');

  const logOut = () => {
    removeStorageUserInfo();
    removeStorageToken();
    setLoginInfo({} as IMemberLoginData);
    setIsLoggedIn(false);
  };

  useEffect(() => {
    if (loginInfo?.userToken) {
      setIsLoggedIn(true);
    }
  }, [loginInfo]);

  // isLoggedIn=true에 대한 검증
  useEffect(() => {
    if (!isLoggedIn) return;

    getMyLoginInfo().then(setLoginInfo).catch(handleCatch);

    function setLoginInfo(body: any) {
      setIsLoggedIn(true);
      sessionStorage.setItem('userInfo', JSON.stringify(body));
    }

    function handleCatch() {
      setIsLoggedIn(false);
      removeStorageUserInfo();
      removeStorageToken();
    }
  }, []);

  return (
    <>
      <MobileMenu isOpen={mobileMenu} closeMenu={() => setMobileMenu(false)} />
      <header
        ref={headerRef}
        className={`py-4 2xl:py-5 text-white max-lg:px-5 lg:pl-4 ${
          isAtMain ? 'fixed duration-300 ease-in' : 'sticky'
        } w-full top-0 z-20 ${isTriggered && isAtMain && 'bg-darkPrimary'} ${
          !isAtMain && 'bg-darkPrimary'
        }`}>
        <section className="flex items-center justify-between max-w-screen-xl 2xl:max-w-screen-2xl w-full mx-auto">
          <Link to="/">
            <i className="min-w-fit">
              <img src={logoUrl} alt="main logo" />
            </i>
          </Link>
          <div className="flex items-center space-x-6 xl:space-x-10 2xl:space-x-16">
            <ul
              className={`hidden lg:flex ${
                isEnglish
                  ? 'space-x-3 xl:space-x-6 2xl:space-x-8'
                  : 'space-x-[22px] xl:space-x-9 2xl:space-x-11'
              }`}>
              {linkArray.map(({ name, link }) => {
                const isActive = link === pathname;
                return (
                  <Link to={link} key={name}>
                    <li
                      className={`relative ${
                        isEnglish ? 'max-2xl:text-xs' : 'max-2xl:text-sm'
                      } whitespace-nowrap ${
                        isActive
                          ? 'text-white font-semibold cursor-default'
                          : 'text-white/50 font-medium hover:text-white/80'
                      } duration-150 ease-out`}>
                      {t(name)}
                      <div
                        className={`absolute -right-2 top-0.5 w-[3px] h-[3px] 2xl:w-1 2xl:h-1 rounded-full bg-white ${
                          !isActive && 'hidden'
                        }`}
                      />
                    </li>
                  </Link>
                );
              })}
            </ul>
            <div className="flex space-x-2 lg:space-x-[10px]">
              <Link to={isLoggedIn ? '/my-page/my-info' : '/signup'}>
                <button className="max-2xl:text-sm border border-primary bg-primary font-medium py-2 px-2 lg:px-3 xl:px-5 rounded-full hover:bg-primary/80 duration-100 ease-out max-lg:hidden whitespace-nowrap">
                  {isLoggedIn ? 'My Page' : t('지금가입')}
                </button>
              </Link>
              <button
                onClick={() => (isLoggedIn ? logOut() : navigate('/login'))}
                className="max-2xl:text-sm border border-secondary text-secondary font-medium py-2 px-2 lg:px-3 xl:px-5 rounded-full hover:bg-secondary/20 duration-100 ease-out max-lg:hidden whitespace-nowrap">
                {isLoggedIn ? 'Logout' : 'Login'}
              </button>
              <LanguageListBox isHeader />
              <button onClick={() => setMobileMenu(true)}>
                <BurgerIcon />
              </button>
            </div>
          </div>
        </section>
      </header>
    </>
  );
}

function BurgerIcon() {
  return (
    <svg
      className="lg:hidden"
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.0715 11.6489H25.9286M7.06836 16.4999H25.9208M14.3481 21.3508H25.9208"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
