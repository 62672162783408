import { getMainDir } from '../../util/common';
import BasketIcon from '../vector/BasketIcon';

export default function StoreButton({ t, category }) {
  return (
    <div className="flex space-x-5">
      <h2>{t(category)}</h2>
      <button
        onClick={() => window.open(getMainDir('/store'))}
        className="flex items-center space-x-2 py-1 px-[18px] bg-[#7b61ff] bg-opacity-[8%] hover:bg-[#e7e3ff] duration-100 ease-out rounded-2xl">
        <i className="mb-[3px]">
          <BasketIcon />
        </i>
        <span className="text-sm text-[#7b61ff] hover:text-[#9681ff] duration-100 ease-out font-bold">
          {t('스토어')}
          <span className="ml-2">&gt;</span>
        </span>
      </button>
    </div>
  );
}
