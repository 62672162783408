type Props = {
  isEditing?: boolean;
};

export default function ProjectCardSkeleton({ isEditing }: Props) {
  return (
    <div className="relative">
      <div className="w-full flex flex-col space-y-5">
        <div
          className={`${
            isEditing ? 'h-36 xl:h-40 2xl:h-44' : 'h-[120px]'
          } flex items-center justify-center rounded-[10px] bg-[#BFBFBF] overflow-hidden animate-pulse`}
        >
          <Avatar isEditing={isEditing} />
        </div>
        <div className="w-full flex items-center justify-between">
          <div className="w-1/3 h-1.5 rounded-full bg-gray-400 animate-pulse" />
          <div className="w-2/5 h-1 rounded-full bg-gray-300 animate-pulse" />
        </div>
      </div>
    </div>
  );
}

function Avatar({ isEditing }: Props) {
  return (
    <svg
      className={`${isEditing ? 'w-16 h-16' : 'w-12 h-12'} text-gray-200`}
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 20 20"
    >
      <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
    </svg>
  );
}
