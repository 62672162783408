import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { isSignedAtom } from '../../../networks/store/member';
import { useModalAtom } from '../../../recoil/atoms/store';

export default function EnterpriseInfo() {
  const setUseModal = useSetRecoilState(useModalAtom);
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const { t } = useTranslation();

  const openInquiryModal = () => setUseModal({ type: 'inquiry', isOpen: true });
  return (
    <div className="max-lg:px-5 mt-6 lg:mt-12">
      <div className="w-full flex max-md:flex-col items-start max-md:space-y-3 md:items-center justify-between border-2 border-primary rounded-[10px] bg-gradient-to-b from-[#0A101B] to-[#0A101B]/0 p-5 lg:p-8 2xl:p-10">
        <div className="flex max-lg:flex-col max-lg:space-y-2 lg:items-center lg:space-x-4 2xl:space-x-10">
          <h2 className="text-xl lg:text-2xl font-bold">Enterprise / Pro</h2>
          <p className="text-xs lg:text-sm 2xl:text-base text-[#C7C5C5]">
            {t(
              '원하시는 비즈니스에 맞는 서비스 제공이 필요하시다면 엔터프라이즈 요금제를 문의해 주세요.'
            )}
          </p>
        </div>
        <button
          disabled={!isLoggedIn}
          onClick={openInquiryModal}
          className="max-md:w-full py-3 2xl:py-4 px-8 2xl:px-10 bg-primary hover:bg-primary/70 disabled:bg-primary/40 disabled:text-gray-400 disabled:cursor-not-allowed rounded-md duration-150 text-xs lg:text-sm font-bold whitespace-nowrap">
          {t('제작 문의하기')}
        </button>
      </div>
    </div>
  );
}
