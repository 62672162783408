import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { nationArr } from '../../../arrays';
import { RegisterInfoType } from '../../../types_new/main/landing/types';
import ChevronDownIcon from '../../svg/chevron-down-icon';

type Props = {
  setRegisterInfo: Dispatch<SetStateAction<RegisterInfoType>>;
};

export default function NationComboBox({ setRegisterInfo }: Props) {
  const [selected, setSelected] = useState(nationArr[0]);
  const [query, setQuery] = useState('');

  const filteredNations =
    query === ''
      ? nationArr
      : nationArr.filter(person =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        );

  const getDisplayValue = (name: string, number: number) => {
    return `${name} +${number}`;
  };

  const getOptionValue = (nation: {
    code: string;
    number: string;
    name: string;
  }) => {
    return `${nation.name} +${nation.number}`;
  };

  useEffect(() => {
    setRegisterInfo(prev => ({
      ...prev,
      userCountryCode: selected.number,
    }));
  }, [selected]);

  return (
    <Combobox value={selected} onChange={setSelected}>
      <div className="relative">
        <div className="border border-[#d7d7d7] relative w-full cursor-default overflow-hidden rounded-md bg-white text-left">
          <Combobox.Input
            className="h-[44px] lg:h-[50px] w-full border-none py-2.5 pl-5 pr-10 text-xs lg:text-sm text-black focus:ring-0"
            displayValue={({ name, number }) => getDisplayValue(name, number)}
            onChange={e => setQuery(e.target.value)}
          />
          <Combobox.Button className="absolute inset-y-0 right-3 flex items-center pr-2 text-black z-10">
            <ChevronDownIcon />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-10"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-10"
          afterLeave={() => setQuery('')}>
          <Combobox.Options className="inquiry-detail z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {filteredNations.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700 text-xs lg:text-sm">
                검색된 국가가 없습니다.
              </div>
            ) : (
              filteredNations.map((nation, idx) => (
                <Combobox.Option
                  key={`nation-${idx}`}
                  className={({ active }) =>
                    `relative text-sm sm:text-base cursor-pointer select-none py-2 px-5 group ${
                      active ? 'bg-primary/90 !text-white' : 'text-black'
                    }`
                  }
                  value={nation}>
                  {({ selected }) => (
                    <div className="flex items-center space-x-1">
                      {selected && (
                        <CheckIcon className="text-primary font-bold w-4 h-4" />
                      )}
                      <span
                        className={`block truncate text-xs lg:text-sm ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}>
                        {getOptionValue(nation)}
                      </span>
                    </div>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}
