import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Pagination from '../../components/common/Pagination';
import ConsoleMediaBanner from '../../components/console/my-media/media-banner';
import TemplatesTable from '../../components/console/my-templates/templates-table';
import { getMyTempalteList } from '../../networks/template.service';
import { TemplateDataType } from '../../types_new/main/resource/types';

const POSTS_PER_PAGE = 20;

export default function ConsoleTemplatePage() {
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const [myTemplate, setMyTemplate] = useState<TemplateDataType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (!loading) setLoading(true);

    getMyTempalteList({ postsPerPage: POSTS_PER_PAGE, paged })
      .then(({ total, posts }) => {
        setTotal(total);
        setMyTemplate(posts);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [paged]);

  const handleOnPageChange = (page: number) => {
    setPaged(page);
    setParentPage(page);
  };

  return (
    <section className="w-full">
      <ConsoleMediaBanner />
      <div className="mt-14">
        <h1 className="text-xl 2xl:text-2xl">{t('내 템플릿')}</h1>
        <TemplatesTable myTemplateData={myTemplate} loading={loading} />
        <Pagination
          itemsPerPage={POSTS_PER_PAGE}
          currentPage={paged}
          totalItems={total}
          onPageChange={handleOnPageChange}
          parentPage={parentPage}
        />
      </div>
    </section>
  );
}
