import {
  TemplateApplyStatusType,
  TemplateTranType,
} from '../types_new/main/service/types';
import APIService from './APIService';
import apiConfig from './apiConfig.json';

export type TemplatePublishBodyType = {
  language: 'ko' | 'en';
  projectDistNm: string;
  projectId: string;
  projectNm: string;
  thumbnail: string;
  downloadLink: string;
  playTime: number;
  credit: number;
  description: string;
  exportVoice?: 'Y' | 'N';
};

export type TemplateHistoryParamsType = {
  postsPerPage: number;
  paged: number;
  applyStatus?: TemplateApplyStatusType;
  tranType?: TemplateTranType;
  title?: string;
};

export type TemplateBuyBodyType = {
  category: 'template';
  type: null;
  credit: string;
  postId: string;
};

export type TemplateUseType = {
  templateBuyId: string;
};

export async function requestTemplatePublish(reqBody: TemplatePublishBodyType) {
  const {
    data: { code },
  } = await APIService.post(apiConfig.studio.template.requestPublish, reqBody);

  return { code };
}

export async function getTemplateRequestHistory(
  params: TemplateHistoryParamsType
) {
  const {
    data: { body },
  } = await APIService.get(apiConfig.studio.template.getRequestHistory, {
    params,
  });
  const { total, posts } = body;

  return { total, posts };
}

export async function deleteTemplate(postId: string) {
  await APIService.delete(
    `${apiConfig.studio.template.deleteTemplate}/${postId}`
  );
}

export async function getTemplateList(params: TemplateHistoryParamsType) {
  const {
    data: { body },
  } = await APIService.get(apiConfig.studio.template.getTemplateList, {
    params,
  });
  const { total, posts } = body;

  return { total, posts };
}

export async function getMyTempalteList(params: TemplateHistoryParamsType) {
  const {
    data: { body },
  } = await APIService.get(apiConfig.studio.template.myTemplateList, {
    params,
  });
  const { total, posts } = body;

  return { total, posts };
}

export async function buyTemplate(reqBody: any) {
  const { data } = await APIService.post(
    apiConfig.studio.template.buyTemplate,
    reqBody
  );

  return data;
}

export async function useTemplate(reqBody: TemplateUseType) {
  const { data } = await APIService.post(
    apiConfig.studio.template.useTemplate,
    reqBody
  );

  const {
    code,
    body: { projectId },
  } = data;

  return { code, projectId };
}
