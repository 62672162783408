export default function StopIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="#7b61ff"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="white"
      className="w-4 h-4">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z"
      />
    </svg>
  );
}
