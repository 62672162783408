import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { createProject } from '../../../networks/project.service';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import Loader from '../../common/loader';
import TemplateListBox from './template-list-box';

type Props = {
  setInformModal: Dispatch<SetStateAction<boolean>>;
  closeModal: () => void;
  projectInfo: NewProjectInfoType;
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  setStep: Dispatch<SetStateAction<number>>;
};

export default function StepThree({
  setInformModal,
  closeModal,
  projectInfo,
  setProjectInfo,
  setStep,
}: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onClickCreateProject = () => {
    setLoading(true);

    createProject(projectInfo)
      .then(({ data }) => {
        if (data.code === '200') {
          handleSuccess(data.body.projectId);
        } else {
          handleFail();
        }
      })
      .catch(handleFail)
      .finally(() => setLoading(false));

    function handleSuccess(projectId: number) {
      closeModal();
      navigate(`/project/${projectId}`);
    }

    function handleFail() {
      setInformModal(true);
    }
  };

  return (
    <section>
      <h3 className="text-base 2xl:text-lg text-left font-semibold leading-6 text-consoleDark mb-5 xl:mb-7 2xl:mb-[30px]">
        {t('새 프로젝트 만들기')}
      </h3>
      <div className="flex flex-col space-y-2 mt-4">
        <span className="text-consoleDark text-xs 2xl:text-sm">
          {t('화면 사이즈를 선택해주세요.')}
        </span>
        <TemplateListBox
          disabled={loading}
          projectInfo={projectInfo}
          setprojectInfo={setProjectInfo}
        />
      </div>
      <div className="flex space-x-3 text-sm font-medium mt-4 xl:mt-5 2xl:mt-[22px]">
        <button
          disabled={loading}
          onClick={() => setStep(2)}
          className={`rounded-md bg-consoleGray text-consoleDark w-1/3 py-2.5 xl:py-3 2xl:py-4 focus:outline-none duration-100 ${
            loading ? 'cursor-not-allowed' : 'hover:bg-gray-200'
          }`}>
          {t('이전')}
        </button>
        <button
          disabled={loading}
          onClick={onClickCreateProject}
          className={`rounded-md w-2/3 flex justify-center text-[#ececec] items-center duration-100 ${
            loading ? 'bg-primary/50' : 'bg-primary hover:bg-primary/90'
          }`}>
          {loading ? <Loader /> : t('프로젝트 생성하기')}
        </button>
      </div>
    </section>
  );
}
