import { useTranslation } from 'react-i18next';
import { getMainDir } from '../../../util/common';

export default function ConsoleMediaBanner() {
  const { t } = useTranslation();
  return (
    <div className="w-full flex items-center justify-between rounded-[10px] py-8 px-12 bg-gradient-to-r from-[#FF33AC] to-[#FFC8AD]">
      <div className="flex flex-col space-y-1">
        <span className="text-sm text-white font-medium">
          Available metahuman
        </span>
        <span className="text-sm md:text-base 2xl:text-lg text-white font-semibold">
        {t('새롭게 출시된 하이퀄리티 메타휴먼을 지금 만나보세요!')}
        </span>
      </div>
      <button
        onClick={() => window.open(getMainDir('/store'))}
        className="bg-white rounded-full py-3 px-6 text-[#44505F] text-[13px] whitespace-nowrap hover:bg-gray-100 duration-150"
      >
        {t('스토어 바로가기')}
      </button>
    </div>
  );
}
