import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useModalAtom } from '../../recoil/atoms/store';
import { changeHost, formatDuration } from '../../utils/common';
import CoinIcon from '../svg/coin-icon';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function TemplateDetailModal({ isOpen, closeModal }: Props) {
  const useModal = useRecoilValue(useModalAtom);
  const initialRef = useRef(null);
  const preview = useModal?.data?.preview;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        ref={initialRef}
        initialFocus={initialRef}
        as="div"
        className="relative z-30"
        onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed w-full h-2/3 md:h-5/6 lg:h-[90%] inset-auto bottom-0 duration-150 overflow-visible">
          <div className="flex min-h-full h-1/2 sm:h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0  translate-y-80"
              enterTo="opacity-100 translate-y-0"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0  translate-y-80">
              <Dialog.Panel className="relative text-left mx-auto w-full lg:w-2/3 xl:w-1/2 max-w-screen-lg !overflow-y-auto no-scroll transform rounded-2xl lg:rounded-lg transition-all">
                <video
                  playsInline
                  autoPlay
                  muted
                  loop
                  className="rounded-t-xl"
                  controls
                  controlsList="nodownload">
                  <track default kind="captions" />
                  <source
                    src={`${changeHost(preview || '')}`}
                    type="video/mp4"
                  />
                </video>
                <div className="w-full bg-white p-7">
                  <div className="flex justify-between items-center">
                    <h2 className="text-xl font-semibold">
                      {useModal.data?.postTitle}
                    </h2>
                    <div className="flex items-center space-x-1">
                      <CoinIcon />
                      <span className="text-primary">
                        {useModal.data?.price}
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-1 mt-5">
                    <div className="flex">
                      <span className="w-32 font-medium">제작자</span>
                      <span>{useModal.data?.templateAuthorEmail}</span>
                    </div>
                    <div className="flex">
                      <span className="w-32 font-medium">재생새간</span>
                      <span>{formatDuration(useModal.data?.playTime)}</span>
                    </div>
                    <div className="flex">
                      <span className="w-32 font-medium">배포일</span>
                      <span>{useModal.data?.modifyDate}</span>
                    </div>

                    <div className="flex">
                      <span className="w-32 font-medium">누적 다운로드</span>
                      <span>{useModal.data?.buyCnt || 0}회</span>
                    </div>
                  </div>
                  <p className="mt-8">{useModal.data?.description}</p>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
