/**
 * get current path
 * @param {*} path
 * @returns
 */
export const getMainDir = (path = null) => {
  let currentPath = '';
  const isAppLanguageKorean = process.env.REACT_APP_LANGUAGE === 'ko';

  // eslint-disable-next-line no-unused-expressions
  isAppLanguageKorean ? (currentPath = '/main') : (currentPath = '/en/main');

  if (path) {
    const isStartingWithSlash = path.startsWith('/');
    // eslint-disable-next-line no-unused-expressions
    isStartingWithSlash ? (currentPath += path) : (currentPath += `/${path}`);
  }

  return currentPath;
};

// /**
//  *
//  * @param {*} path
//  * @returns
//  */
// export const getConsoleDir = (path = null) => {
//   let curDir = '';
//   if (process.env.REACT_APP_LANGUAGE === 'ko') {
//     curDir += '/console';
//   } else {
//     curDir += `/${process.env.REACT_APP_LANGUAGE}/console`;
//   }

//   if (path) {
//     curDir += `/${path}`;
//   }
//   return curDir;
// };

// /**
//  *
//  * @param {*} path
//  * @returns
//  */
// export const getStudioDir = (path = null) => {
//   let curDir = '';
//   if (process.env.REACT_APP_LANGUAGE === 'ko') {
//     curDir += '/studio-tmp';
//   } else {
//     curDir += `/${process.env.REACT_APP_LANGUAGE}/studio-tmp`;
//   }

//   if (path) {
//     curDir += `/${path}`;
//   }
//   return curDir;
// };

// /**
//  * 로고 이미지의 경로를 리턴.
//  * @returns
//  */
// export const getCurrentLogo = () => {
//   let curDir = '';
//   if (process.env.REACT_APP_LANGUAGE === 'ko') {
//     curDir += '/main/img';
//   } else {
//     curDir += `/${process.env.REACT_APP_LANGUAGE}/main/img`;
//   }
//   curDir += `/${process.env.REACT_APP_LANGUAGE}/logo.svg`;

//   return curDir;
// };

/**
 * wordpress api에서 받은 데이터 중 meta_key의 value를 구한다.
 */
export const getMetaValue = (item, key) => {
  const metaItem = item?.metaData?.filter(el => el?.key === key);

  return metaItem[0]?.value;
};

export const checkFileSize = file => {
  const maxByte = 1024 * 1024 * 150;
  if (maxByte < file.size) {
    return false;
  }
  return true;
};
