import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SubpageTitle from '../../components/main/landing/subpage-title';
import TemplateGrid from '../../components/main/store/template-grid';
import SubpageContainer from '../../components/main/subpage-container';
import { IMemberLoginData } from '../../networks/dto/IMemberDto';
import { refreshPaySignIn } from '../../networks/paysystem.service';
import { isSignedAtom, loginInfoAtom, removeStorageToken, removeStorageUserInfo, userInfoAtom } from '../../networks/store/member';
import { paySystemMemberAtom } from '../../networks/store/paysystem';

const TITLE = '템플릿을 이용하여\n간편하게 제작해보세요!';

export default function GalleryPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const setPaySystemMember = useSetRecoilState(paySystemMemberAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const setIsLoggedIn = useSetRecoilState(isSignedAtom);
  const setLoginInfo = useSetRecoilState(loginInfoAtom);

  /**
   * 로그아웃 처리
   */
  const doLogOut = () => {
    removeStorageToken();
    removeStorageUserInfo();
    setIsLoggedIn(false);
    setLoginInfo({} as IMemberLoginData);
    navigate('/');
  };

  /**
   * 결제시스템 token refresh
   * @param payInfo 결제시스템에서 받은 로그인 정보
   * @returns 
   */
  const refreshPaySignInCB = (payInfo) => {
    if (!payInfo) {
      console.log('[refreshPaySignInCB] refreshPaySignIn error res:' + payInfo);
      alert('다시 로그인 하십시요.');
      doLogOut();
      return;
    }
    console.log('[handleOnPay] refreshPaySignIn payInfo:' + JSON.stringify(payInfo));
    setPaySystemMember(payInfo);
  }

  useEffect(() => {
    if (isLoggedIn) {
      console.log('[handleOnPay] userInfo:' + JSON.stringify(userInfo));
      refreshPaySignIn(userInfo?.userLogin, refreshPaySignInCB);
    }
  }, []);

  return (
    <SubpageContainer>
      <div className="pb-20 md:pb-28 lg:pb-36 xl:pb-40 2xl:pb-52">
        <SubpageTitle badge="갤러리" title={t(TITLE)} />
        <TemplateGrid />
      </div>
    </SubpageContainer>
  );
}
