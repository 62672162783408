import { Dispatch, SetStateAction } from 'react';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import {
  MetahumanListType,
  MetahumanTabType,
} from '../../../types_new/main/resource/types';
import StyleCard from './style-card';

type Props = {
  setTab: Dispatch<SetStateAction<MetahumanTabType>>;
  metahumanList: MetahumanListType[];
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  projectInfo: NewProjectInfoType;
};

export default function StyleGrid({
  setTab,
  metahumanList,
  setProjectInfo,
  projectInfo,
}: Props) {
  const styleList = metahumanList.find(
    item => item.ID === projectInfo.defaultMetahuman.modelId
  )?.styles;

  return (
    <div className="relative">
      <ul className="grid grid-cols-7 gap-4 max-h-72 2xl:max-h-80 h-full overflow-y-auto no-scroll px-1 pt-1.5 pb-4">
        {styleList?.map((item, idx) => (
          <li key={`metahuman-${idx}`}>
            <StyleCard
              setTab={setTab}
              setProjectInfo={setProjectInfo}
              projectInfo={projectInfo}
              style={item}
            />
          </li>
        ))}
      </ul>
      <div className="absolute bottom-0 w-full h-5 bg-gradient-to-t from-white/90 to-white/0" />
    </div>
  );
}
