import React, { useEffect, useState } from 'react';

import { SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';

const ColorPicker: React.ReactNode | any = ({
  addBackground,
  blockColor,
}: any) => {
  const [colors, setColors] = useState('');

  useEffect(() => {
    if (blockColor) {
      setColors(blockColor);
    }
  }, [blockColor]);

  const colorHandler = (color: any) => {
    addBackground(
      { colors: `${rgbHex(color.rgb.r, color.rgb.g, color.rgb.b)}` },
      'color'
    );
    setColors(`${rgbHex(color.rgb.r, color.rgb.g, color.rgb.b)}`);
  };

  return (
    <>
      <SketchPicker
        width="250px"
        className="sketch__style"
        color={colors}
        onChange={color => colorHandler(color)}
      />
    </>
  );
};

export default ColorPicker;
