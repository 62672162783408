import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import MyPageLayout from '../../components/main/my-page-layout';
import CreditInfo from '../../components/main/my-page/credit/credit-info';
import InquiryInfo from '../../components/main/my-page/inquiry/inquiry-info';
import MyInfo from '../../components/main/my-page/my-info';
import PaymentInfo from '../../components/main/my-page/payment/payment-info';
import ServiceUsageInfo from '../../components/main/my-page/service-usage/service-usage-info';
import TemplateInfo from '../../components/main/my-page/template/template-info';
import TemplatePublishingInfo from '../../components/main/my-page/template/template-publishing-info';
import InformModal from '../../components/modals/inform-modal';
import { IPaySystemSubscribeData } from '../../networks/dto/IPaySystemDto';
import {
  getLeftCredit,
  getSubscribeList,
} from '../../networks/paysystem.service';
import { isSignedAtom, userInfoAtom } from '../../networks/store/member';
import { paySubscribeInfoAtom } from '../../networks/store/paysystem';
import { UserInfoType } from '../../types_new/main/service/types';

export const INITIAL_PAYSUBSCRIBE_INFO = {
  fkCompany: 0,
  pkPpCard: 0,
  itemCd: '',
  itemType: '',
  itemName: '',
  credit: 0,
  creditFree: 0,
  creditPolicy: 0,
  creditPolicyFree: 0,
  cost: 0,
  minusYn: '',
  payYn: '',
  custYn: '',
  freeYn: '',
  subscYn: '',
  subscTermUnit: '',
  subscTermCnt: 0,
  preUseLimitRate: 0,
  limitTermUnit: '',
  limitTermCnt: 0,
  limitDtSameYnFrom: '',
  limitDtSameYnTo: '',
  limitDtFrom: '',
  limitDtTo: '',
  payDtFrom: '',
  payDtTo: '',
  nthCnt: 0,
  creditUsedRate: 0,
};

export const INITIAL_USER_INFO: UserInfoType = {
  adminRole: '',
  membershipType: '',
  project: [],
  role: '',
  userDept: '',
  volumeTotal: '',
  credit: '0',
  lastLoginTime: '',
  usage: 0,
  userCountryCode: '',
  userEmail: '',
  userId: 0,
  userImage: '',
  userLogin: '',
  userName: '',
  userPhone: '',
  userRegistered: '',
  limitDtTo: '',
};

export default function MyPage() {
  const navigate = useNavigate();
  const { info } = useParams();
  const userInfo = useRecoilValue(userInfoAtom);
  const isSigendIn = useRecoilValue(isSignedAtom);
  const [paySubscribe, setPaySubscribe] = useRecoilState(paySubscribeInfoAtom);
  const [payCreditLeft, setPayCreditLeft] = useState(0);

  const [failModal, setFailModal] = useState({
    isOpen: false,
    message: '',
  });

  const getCreditLeftWithResData = (result: any): number => {
    let totalCredit: number = 0;
    result?.map((item: any) => {
      totalCredit += item.credit;
    });
    return totalCredit || 0;
  };

  const getPayCreditLeft = () => {
    getLeftCredit()
      .then(res => {
        if (res.status === 200) {
          setPayCreditLeft(getCreditLeftWithResData(res?.data?.resultValue));
        } else {
          console.error();
          handleFail('크레딧정보를 불러오는 도중 에러가 발생했습니다');
          setPayCreditLeft(getCreditLeftWithResData(0));
        }
      })
      .catch(err => {
        console.error('login error:' + err);
        handleFail('크레딧정보를 불러오는 도중 에러가 발생했습니다');
        setPayCreditLeft(getCreditLeftWithResData(0));
      });
  };

  const getPaySubscribe = () => {
    getSubscribeList()
      .then(res => {
        if (res.status === 200) {
          const subscribeInfo: IPaySystemSubscribeData = res?.data?.resultValue;
          setPaySubscribe(subscribeInfo);
        } else {
          handleFail('구독정보를 불러오는 도중 에러가 발생했습니다');
          setPaySubscribe(INITIAL_PAYSUBSCRIBE_INFO);
        }
      })
      .catch(() => {
        handleFail('구독정보를 불러오는 도중 에러가 발생했습니다');
        setPaySubscribe(INITIAL_PAYSUBSCRIBE_INFO);
      });
  };

  function handleFail(message?: string) {
    setFailModal({
      isOpen: true,
      message: message || '잠시 후 다시 시도해주세요.',
    });
  }

  const renderView = () => {
    switch (info) {
      case 'my-info':
        return (
          <MyInfo
            myInfo={userInfo}
            subscribeInfo={paySubscribe}
            paycreditleft={payCreditLeft.toLocaleString()}
          />
        );
      case 'payment':
        return (
          <PaymentInfo
            subscribeInfo={paySubscribe}
            paycreditleft={payCreditLeft.toLocaleString() || '0'}
          />
        );
      case 'credit':
        return (
          <CreditInfo paycreditleft={payCreditLeft.toLocaleString() || '0'} />
        );
      case 'service-usage':
        return <ServiceUsageInfo />;
      case 'inquiry':
        return <InquiryInfo />;
      case 'template':
        return <TemplateInfo />;
      case 'template-publish':
        return <TemplatePublishingInfo />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!isSigendIn) navigate('/login');
    getPayCreditLeft();
    getPaySubscribe();
  }, [isSigendIn]);

  return (
    <MyPageLayout>
      <InformModal
        isOpen={failModal.isOpen}
        title="오류"
        message={failModal.message}
        closeModal={() => setFailModal(prev => ({ ...prev, isOpen: false }))}
      />
      {renderView()}
    </MyPageLayout>
  );
}
