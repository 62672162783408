import { NewProjectInfoType } from '../../../types_new/console/service/types';
import { MetahumanListType } from '../../../types_new/main/resource/types';
import { changeHost } from '../../../utils/common';

type Props = {
  metahumanList: MetahumanListType[];
  tab: string;
  projectInfo: NewProjectInfoType;
};
export default function SelectedBadge({
  metahumanList,
  tab,
  projectInfo: { defaultMetahuman },
}: Props) {
  const selectedMetahuman = metahumanList?.find(
    item => item.ID === defaultMetahuman.modelId
  );
  const metahumanThumbnail = selectedMetahuman?.thumbnail;
  const styleName = selectedMetahuman?.styles.find(
    item => item.styleId === defaultMetahuman.styleId
  )?.name;
  const poseName = selectedMetahuman?.poses.find(
    item => item.poseId === defaultMetahuman.poseId
  )?.name;
  const voiceName = selectedMetahuman?.voices.find(
    item => String(item.voiceId) === String(defaultMetahuman.voiceId)
  )?.name;

  const getSelectedItem = () => {
    switch (tab) {
      case 'metahuman':
        return selectedMetahuman?.name;
      case 'style':
        return styleName;
      case 'posture':
        return poseName;
      case 'voice':
        return voiceName;
      default:
        return '';
    }
  };

  return (
    <>
      {getSelectedItem() && (
        <div className="flex items-center space-x-1.5 py-1 px-3 bg-primary rounded-full">
          {tab === 'metahuman' && (
            <div
              style={{
                backgroundImage: `url(${changeHost(metahumanThumbnail)})`,
              }}
              className="w-5 h-5 rounded-full bg-cover bg-center"
            />
          )}
          <span className="text-white text-xs font-semibold">
            {getSelectedItem()}
          </span>
        </div>
      )}
    </>
  );
}
