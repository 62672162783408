import React from 'react';
import { useTranslation } from 'react-i18next';
import MyPageNavigation from './mypage/my-page-navigation';

type Props = {
  children: React.ReactNode;
};
export default function MyPageLayout({ children }: Props) {
  const { t } = useTranslation();

  return (
    <section className="w-full flex flex-col items-center mx-auto py-10 md:py-14 lg:py-20 xl:py-24 2xl:py-32 duration-100">
      <h1 className="text-darkSecondary text-2xl lg:text-3xl  2xl:text-4xl font-semibold mt-5">
        {t('마이 페이지')}
      </h1>
      <MyPageNavigation />
      {children}
    </section>
  );
}
