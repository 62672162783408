import { Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import CheckedBox from '../../svg/checked-box';
import ChevronRightIcon from '../../svg/chevron-right-icon';
import UncheckedBox from '../../svg/unchecked-box';
import PersonalTerms from './personal-terms';
import ServiceTerms from './service-terms';
import SpamTerms from './spam-terms';

type Props = {
  term: { isMendatory: boolean; label: string; id: string };
  setCheckedBox: Dispatch<SetStateAction<string[]>>;
  checkedBox: string[];
  setModal: Dispatch<
    SetStateAction<{
      isOpen: boolean;
      terms: any;
      title: string;
    }>
  >;
};

export default function TermCard({
  term,
  setCheckedBox,
  checkedBox,
  setModal,
}: Props) {
  const { t } = useTranslation();
  const { isMendatory, label, id } = term;

  const onCheckedSingle = useCallback(
    (checked: boolean, box: string) => {
      if (checked) {
        setCheckedBox(prev => [...prev, box]);
      } else {
        setCheckedBox(checkedBox.filter(el => el !== box));
      }
    },
    [checkedBox]
  );

  const openModal = (id: string) => {
    let component = <></>;
    let title = '';
    switch (id) {
      case 'service': {
        component = <ServiceTerms />;
        title = '서비스 이용약관';
        break;
      }
      case 'personal': {
        component = <PersonalTerms />;
        title = '개인정보 수집 및 이용';
        break;
      }
      case 'spam': {
        component = <SpamTerms />;
        title = '스팸 방지 정책';
        break;
      }
      default:
        break;
    }
    setModal({
      isOpen: true,
      terms: component,
      title,
    });
  };

  const isChecked = (id: string) => {
    return checkedBox.includes(id);
  };

  return (
    <div className="flex items-center justify-between py-3 px-4 2xl:py-4 2xl:px-6 bg-tertiary/10 text-xs lg:text-[13px] 2xl:text-sm">
      <div>
        <input
          onChange={() => onCheckedSingle(!checkedBox.includes(id), id)}
          className="hidden"
          type="checkbox"
          id={id}
        />
        <label className="flex items-center cursor-pointer" htmlFor={id}>
          {isChecked(id) ? (
            <CheckedBox className="w-4 h-4 2xl:w-5 2xl:h-5" />
          ) : (
            <UncheckedBox className="w-4 h-4 2xl:w-5 2xl:h-5" />
          )}
          <strong
            className={`ml-3 mr-1 ${
              isMendatory ? 'text-primary font-medium' : 'font-normal'
            }`}>
            {isMendatory ? t('(필수)') : t('(선택)')}
          </strong>
          {t(label)}
        </label>
      </div>
      {isMendatory && (
        <button className="group" onClick={() => openModal(id)}>
          <ChevronRightIcon />
        </button>
      )}
    </div>
  );
}
