import { useTranslation } from 'react-i18next';
import usePath from '../../../hooks/use-path';
import GradientBadge from '../gradient-badge';

type Props = {
  badge: string;
  title: string;
};

export default function SubpageTitle({ badge, title }: Props) {
  const { isWhiteTheme, hasSwiper, isTitleCentered, pathname } = usePath();
  const { t } = useTranslation();

  return (
    <div
      className={`flex flex-col ${
        isTitleCentered ? 'sm:items-center sm:text-center' : 'items-start'
      } ${pathname === '/production-inquiry' && '!items-center'} ${
        hasSwiper && 'max-lg:px-5'
      }`}>
      <GradientBadge isWhiteTheme={isWhiteTheme}>{t(badge)}</GradientBadge>
      <h2
        className={`text-2xl lg:text-3xl 2xl:text-4xl xl:leading-10 2xl:leading-[52px] font-bold mt-4 whitespace-pre-wrap ${
          isWhiteTheme && 'text-darkSecondary'
        }`}>
        {t(title)}
      </h2>
    </div>
  );
}
