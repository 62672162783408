import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTemplateList } from '../../../networks/template.service';
import { TemplateBuyType } from '../../../types_new/main/resource/types';
import Pagination from '../../common/Pagination';
import TemplateCard from '../../main/my-page/template/template-buy-card';
import ProjectCardSkeleton from './project-card-skeleton';

const POSTS_PER_PAGE = 8;

export default function TemplateGrid() {
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);
  const [templates, setTemplates] = useState<TemplateBuyType[]>([]);
  const { t } = useTranslation();

  const onPageChange = (page: number) => {
    setPaged(page);
    setParentPage(page);
  };

  useEffect(() => {
    setLoading(true);

    getTemplateList({
      paged,
      postsPerPage: POSTS_PER_PAGE,
      tranType: 'buy'
    })
      .then(({ posts, total }) => {
        setTemplates(posts);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [paged]);

  return (
    <div className="w-full flex flex-col space-y-6 mt-6 xl:mt-8 2xl:mt-12">
      <h4 className="text-consoleDark text-lg">{t('영상 템플릿')}</h4>
      <ul className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-3 xl:gap-x-4 2xl:gap-x-[18px] gap-y-5 xl:gap-y-8 2xl:gap-y-10">
        {loading ? (
          <Skeleton />
        ) : (
          templates.map(template => (
            <TemplateCard key={template.postId} data={template} />
          ))
        )}
      </ul>
      <Pagination
        onPageChange={onPageChange}
        totalItems={total}
        currentPage={paged}
        parentPage={parentPage}
        itemsPerPage={POSTS_PER_PAGE}
      />
    </div>
  );
}

function Skeleton() {
  const skeletonArr = [1, 2, 3, 4, 5, 6, 7];
  return (
    <>
      {skeletonArr.map((_, idx) => (
        <li key={`templateSkeleton-${idx}`}>
          <ProjectCardSkeleton isEditing />
        </li>
      ))}
    </>
  );
}
