import Draggable from 'react-draggable';
import { changeHost } from '../../../utils/common';

export default function PreviewModal({
  t,
  previewShowToggle,
  previewInfo,
  exportBtn,
}) {
  return (
    <Draggable>
      <div className="preview-popup width-558 !top-[200%] !left-[25%] cursor-move">
        <strong>{t('프로젝트 미리보기')}</strong>
        <button
          className="close__btn close__gray__icon popupClose"
          onClick={previewShowToggle}
        />
        <div className="preview-img-cont">
          <video
            controlsList="nodownload"
            controls
            src={`${changeHost(previewInfo?.result)}`}
          />
        </div>
        <div className="popup-btn-cont-vedio">
          <button className="popupBtnClose" onClick={previewShowToggle}>
            {t('닫기')}
          </button>
          <button className="popupBtnSend" onClick={exportBtn}>
            {t('내보내기')}
          </button>
        </div>
      </div>
    </Draggable>
  );
}
