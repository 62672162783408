import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { consoleNavigationArray as navArray } from '../../arrays';
import usePath from '../../hooks/use-path';
import { MapType } from '../../store/block/types';
import FolderIcon from '../svg/console/folder-icon';
import GuideIcon from '../svg/console/guide-icon';
import HomeIcon from '../svg/console/home-icon';
import HumanIcon from '../svg/console/human-icon';
import MyTemplateIcon from '../svg/console/my-template-icon';
import ProjectIcon from '../svg/console/project-icon';
import { getMainDir } from '../../util/common';

const iconMap: MapType = {
  홈: <HomeIcon />,
  '내 프로젝트': <ProjectIcon />,
  '내 메타휴먼': <HumanIcon />,
  '내 템플릿': <MyTemplateIcon />,
  '내 미디어': <FolderIcon />,
};

export default function SidebarNavigation() {
  const { t } = useTranslation();
  const { pathname } = usePath();

  const isActive = (link: string) => pathname.includes(link);

  return (
    <nav className="mt-12 xl:mt-16 2xl:mt-24">
      <ul className="flex flex-col space-y-6">
        {navArray.map(({ label, link }, idx) => (
          <Link key={`consoleNav-${idx}`} to={link}>
            <li
              className={`rounded-lg flex items-center space-x-2 pl-4 py-2.5 text-sm text-[#44505F] ${
                !isActive(link) && 'opacity-50 hover:bg-[#E1E8F0]'
              } duration-150`}>
              {iconMap[label]}
              <span>{t(label)}</span>
            </li>
          </Link>
        ))}

        <li
          onClick={() => window.open(getMainDir('/tutorial'))}
          className="rounded-lg flex items-center space-x-2 pl-4 py-2.5 text-sm text-[#44505F] opacity-50 hover:bg-[#E1E8F0] cursor-pointer duration-150">
          <GuideIcon />
          <span>{t('사용자 가이드')}</span>
        </li>
      </ul>
    </nav>
  );
}
