import usePath from '../../../hooks/use-path';
import { PostType } from '../../../types_new/main/resource/types';
import NoticeCard from './notice-card';

type Props = {
  noticeList: PostType[];
  openModal: (notice: PostType) => void;
};
export default function NoticeGrid({ noticeList, openModal }: Props) {
  const { isAtMain } = usePath();

  return (
    <ul
      className={`${
        isAtMain ? 'hidden sm:grid' : 'grid gird-cols-1'
      } sm:grid-cols-2 lg:grid-cols-3 gap-7 mt-12`}>
      {noticeList.map((notice, idx) => {
        const isEven = idx % 2 === 0;
        return (
          <li
            className={`${!isAtMain && 'max-sm:w-[80%]'} ${
              isEven ? 'max-sm:ml-auto' : 'max-sm:mr-auto'
            }`}
            key={`notice-${idx}`}>
            <NoticeCard openModal={openModal} notice={notice} idx={idx + 1} />
          </li>
        );
      })}
    </ul>
  );
}
