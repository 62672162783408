import { Popover, Transition } from '@headlessui/react';
import moment from 'moment';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  reqDeleteProject,
  reqDuplicateProject,
} from '../../../networks/project.service';
import { useModalAtom } from '../../../recoil/atoms/store';
import { ProjectInfoType } from '../../../types_new/console/resource/types';
import BinIcon from '../../svg/console/bin-icon';
import CopyIcon from '../../svg/console/copy-icon';
import DetailIcon from '../../svg/console/detail-icon';
import DownloadIcon from '../../svg/console/download-icon';
import InfoIcon from '../../svg/console/info-icon';
import PencilIcon from '../../svg/console/pencil-icon';
import PlaneIcon from '../../svg/console/plane-icon';
import ShareIcon from '../../svg/console/share-icon';

const publishedProjectButtonArray = [
  {
    label: '다운받기',
    icon: <DownloadIcon />,
  },
  {
    label: '복사하기',
    icon: <CopyIcon />,
  },
  {
    label: 'SNS 공유',
    icon: <ShareIcon />,
  },
  {
    label: '영상 템플릿\n배포 신청',
    icon: <PlaneIcon />,
  },
];

const editingProjectButtonArray = [
  {
    label: '편집하기',
    icon: <PencilIcon />,
  },
  {
    label: '복사하기',
    icon: <CopyIcon />,
  },
  {
    label: '삭제하기',
    icon: <BinIcon />,
  },
];

type Props = {
  updateAfterDelete?: () => void;
  updateDuplicatedProject?: () => void;
  setProjects: Dispatch<SetStateAction<ProjectInfoType[]>>;
  projectId: number;
  status: 'pending' | 'publish';
  duration: string;
  downloadLink: string;
  projectName: string;
  pageNum: number;
  isHome?: boolean;
  completedThumbnail?: string;
  thumbnail?: string;
  exportVoice: 'N' | 'Y';
  projectContent: string;
  registerDate: string;
  producing: string;
};

export default function ProjectDetailPop({
  updateAfterDelete,
  updateDuplicatedProject,
  setProjects,
  projectId,
  projectName,
  projectContent: content,
  completedThumbnail,
  status,
  duration,
  downloadLink,
  pageNum,
  isHome,
  thumbnail,
  exportVoice,
  registerDate,
  producing,
}: Props) {
  const setUseModal = useSetRecoilState(useModalAtom);
  const navigate = useNavigate();
  const buttonArray =
    status === 'pending'
      ? editingProjectButtonArray
      : publishedProjectButtonArray;
  const { t } = useTranslation();

  const duplicateProject = () => {
    if (!updateDuplicatedProject) return;
    reqDuplicateProject({ projectId }).then(updateDuplicatedProject);
  };

  const openConfirmModal = () => {
    setUseModal({
      isOpen: true,
      type: 'confirm',
      data: {
        title: '프로젝트를 삭제하시겠습니까?',
        message: '삭제 후, 복원할 수 없습니다.',
        handleOnClick: deleteProject,
      },
    });
  };

  const openPublishModal = () => {
    setUseModal({
      isOpen: true,
      type: 'template-publish',
      exportVoice: 'N',
      data: {
        duration,
        projectName,
        downloadLink,
        projectId,
        completedThumbnail,
      },
    });
  };

  const openShareModal = () => {
    setUseModal({
      isOpen: true,
      type: 'share',
      data: {
        projectName,
        downloadLink,
        completedThumbnail,
        thumbnail,
        content,
      },
    });
  };

  const handleOnClick = (label: string) => {
    switch (label) {
      case '다운받기':
        fileDownload(downloadLink);
        break;
      case '편집하기':
        navigate(`/project/${projectId}`);
        break;
      case '삭제하기':
        openConfirmModal();
        break;
      case '복사하기':
        duplicateProject();
        break;
      case 'SNS 공유':
        openShareModal();
        break;
      case '영상 템플릿\n배포 신청':
        openPublishModal();
        break;
      default:
        break;
    }
  };

  // 파일 다운로드
  const fileDownload = (url: string) => {
    console.log('url:' + url);
    const elm = document.createElement('a');
    elm.setAttribute('href', url);
    elm.setAttribute('download', '다운로드');
    elm.setAttribute('target', '_blank');
    elm.setAttribute('rel', 'noreferrer');
    document.body.appendChild(elm);
    elm.click();
  };

  // 프로젝트 삭제
  const deleteProject = () => {
    setUseModal(prev => ({
      ...prev,
      data: { ...prev.data, loading: true },
    }));
    reqDeleteProject({
      projectId,
    })
      .then(({ body, code }) => {
        if (code !== '200') {
          handleFail(body.message);
        } else {
          handleSuccess();
        }
      })
      .catch(console.log);

    function handleSuccess() {
      if (updateAfterDelete) {
        updateAfterDelete();
      } else {
        setProjects(prev =>
          prev.filter(project => project.projectId !== projectId)
        );
      }
      setUseModal(prev => ({
        ...prev,
        isOpen: false,
      }));
    }

    function handleFail(message: string) {
      setUseModal({
        isOpen: true,
        type: 'inform',
        data: {
          title: '프로젝트 삭제 실패',
          message,
        },
      });
    }
  };

  return (
    <div
      className={`${isHome && 'absolute top-2.5 right-2.5'} z-10 duration-150`}>
      <Popover className="relative">
        <Popover.Button
          className={`focus:outline-none ring-offset-0 ring-0 ${
            !isHome && 'border border-[#c5c5c5] rounded-md'
          }`}>
          <DetailIcon />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-150"
          enterFrom="opacity-0 scale-95 translate-x-2 -translate-y-5"
          enterTo="opacity-100 scale-100 translate-x-0 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 scale-100 translate-x-0 translate-y-0"
          leaveTo="opacity-0 scale-95 translate-x-2 -translate-y-5">
          <Popover.Panel
            className={`absolute ${
              isHome
                ? '-bottom-[208px] -left-[145px]'
                : '-bottom-[214px] -left-[142px]'
            }  w-[144px] z-10 p-4 overflow-hidden rounded-lg shadow-xl bg-white`}>
            <div className="text-xs">
              <div className="flex items-center space-x-0.5 mb-3">
                <h5 className="text-[#444] font-medium">{t('상세정보')}</h5>
                <InfoIcon />
              </div>
              <div className="flex justify-between mb-2.5 text-[#999]">
                <span>{t('재생시간')}</span>
                <span>{duration}</span>
              </div>
              <div className="flex justify-between text-[#999]">
                <span>{t('장면 수')}</span>
                <span>{pageNum}</span>
              </div>
              <div className="w-full h-px bg-[#E9E9E9] my-3.5" />
              <div className="flex flex-col text-[#666]">
                {buttonArray.map(({ label, icon }) => {
                  const showButton =
                    label === '영상 템플릿\n배포 신청'
                      ? moment(registerDate).isAfter('2024-07-03')
                      : true;
                  return (
                    showButton && (
                      <button
                        key={label}
                        onClick={() => handleOnClick(label)}
                        className={`${
                          label === '영상 템플릿\n배포 신청' &&
                          exportVoice === 'Y' &&
                          'hidden'
                        } ${
                          producing === 'fail' &&
                          label === '편집하기' &&
                          'hidden'
                        } text-left py-1.5 rounded-md flex space-x-2 hover:bg-primary/10 duration-100`}>
                        {icon}
                        <span className="whitespace-pre-wrap">{t(label)}</span>
                      </button>
                    )
                  );
                })}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
}
