type Props = {
  arrLength: number;
};
export default function ShowcaseSkeleton({ arrLength }: Props) {
  const array = [];
  const isAtMain = arrLength === 4;
  for (let i = 0; i < arrLength; i++) {
    array.push(i);
  }

  return (
    <>
      <ul
        className={`hidden sm:grid  ${
          isAtMain
            ? 'grid-cols-1 sm:grid-cols-2 xl:grid-cols-4'
            : 'sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'
        } gap-5 2xl:gap-6 mt-12`}
      >
        {array.map((_, idx) => (
          <div
            key={`skeleton-${idx}`}
            className="w-full group cursor-pointer rounded-md"
          >
            <div className="relative w-full h-44 2xl:h-[190px] rounded-t-md overflow-hidden">
              <div className="w-full h-full flex justify-center items-center bg-gray-700 animate-pulse">
                <svg
                  className="w-10 h-10 text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 18"
                >
                  <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                </svg>
              </div>
            </div>
            <div className="py-5 px-8 bg-gray-200 flex flex-col space-y-5 rounded-b-md">
              <div className="h-12">
                <div className="h-2.5 w-3/4 bg-gray-500 animate-pulse rounded-full" />
              </div>
              <div className="bg-gray-400 w-1/3 h-1.5 rounded-full animate-pulse max-lg:hidden" />
              <div className="h-1.5 w-1/4 bg-gray-300 rounded-full animate-pulse" />
            </div>
          </div>
        ))}
      </ul>
      <div className="w-full group cursor-pointer rounded-md sm:hidden mt-12">
        <div className="relative w-full h-44 2xl:h-[190px] bg-rose-100/30 rounded-t-md overflow-hidden">
          <div className="w-full h-full flex justify-center items-center bg-gray-800 animate-pulse">
            <svg
              className="w-10 h-10 text-gray-600"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18"
            >
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
          </div>
        </div>
        <div className="py-5 px-8 bg-gray-200 flex flex-col space-y-5 rounded-b-md">
          <div className="h-12">
            <div className="h-3 w-2/3 bg-gray-500 animate-pulse rounded-full" />
          </div>
          <div className="bg-gray-400 w-1/3 h-2 rounded-full animate-pulse max-lg:hidden" />
          <div className="h-1.5 w-1/4 bg-gray-300 rounded-full animate-pulse" />
        </div>
      </div>
    </>
  );
}
