import { Dispatch, SetStateAction } from 'react';
import { changeHost } from '../utils/common';

type Props = {
  popupInfo: any;
  setPopupInfo: Dispatch<SetStateAction<any>>;
};

export default function StyleSelectGrid({ popupInfo, setPopupInfo }: Props) {
  return (
    <div className="accordin-menu style-scroll secondary-scroll">
      <ul className="accordin-menu-style secondary-scroll">
        {popupInfo.styleArray.map((item: any, idx: number) => (
          <li
            className="style-li"
            key={idx}
            onClick={() => {
              setPopupInfo(prev => ({
                ...prev,
                styleValue: item.styleId,
                styleName: item.name,
                popupNum: 3,
                poseId: '',
                poseName: '',
              }));
            }}>
            <div
              className={`style-box ${
                popupInfo.styleValue === item.styleId && 'active'
              }`}
              style={{
                backgroundImage: `url(${changeHost(item.thumbnail)})`,
              }}
            />
            <h3>{item.name}</h3>
          </li>
        ))}
      </ul>
    </div>
  );
}
