import { Listbox, Transition } from '@headlessui/react';
import { ChevronDown } from 'heroicons-react';
import { Dispatch, Fragment, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { projectStatusArray as statusArray } from '../../../arrays';
import { ProjectReqType } from '../../../types_new/console/service/types';

type Props = {
  status: ProjectReqType['status'];
  setParams: Dispatch<SetStateAction<ProjectReqType>>;
  loading: boolean;
  className?: string;
};

export default function ProjectStatusListBox({
  status,
  setParams,
  loading,
  className,
}: Props) {
  const { t } = useTranslation();
  const handleOnChange = ({ value }: number | any) => {
    setParams(prev => ({
      ...prev,
      status: value,
      paged: 1,
    }));
  };

  const label = statusArray.find(option => option.value === status)?.label;

  return (
    <Listbox value={status} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative w-44">
          <Listbox.Button
            aria-disabled={loading}
            className={`w-full flex justify-between items-center px-[14px] py-2 2xl:py-2.5 text-consoleDark text-[13px] 2xl:text-sm focus:outline-none ring-offset-0 ${className}`}>
            <span className="line-clamp-1">
              {t(status ? label : '전체 프로젝트')}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[14px]">
              <ChevronDown
                className={`h-5 w-5 text-gray-400 duration-150 ${
                  open && 'rotate-180'
                }`}
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            enter="ease-in-out duration-150"
            enterFrom="opacity-0 -translate-y-4"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in-out duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-4">
            <Listbox.Options className="no-scroll absolute mt-1 2xl:mt-2 max-h-32 xl:max-h-40 2xl:max-h-72 w-full shadow-2xl overflow-auto rounded-md p-1 text-base ring-1 ring-[#e9e9e9] focus:outline-none sm:text-sm bg-white z-10">
              {statusArray?.map((speed, idx) => {
                const isActive = status === speed.value;

                return (
                  <Listbox.Option
                    key={`type-${idx}`}
                    className={({ active }) =>
                      `relative flex items-center cursor-pointer select-none px-[14px] py-2 2xl:py-2.5 rounded-md duration-75 ease-out text-black ${
                        active && 'bg-[#F8F7FF]'
                      }`
                    }
                    value={speed}>
                    <span
                      className={`block truncate text-[13px] 2xl:text-sm ${
                        isActive ? 'text-primary' : 'text-[#666666]'
                      }`}>
                      {t(speed.label)}
                    </span>
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
