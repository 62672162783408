import React from 'react';

import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import ChevronNavIcon from '../../svg/console/chevron-nav-icon';

function Arrow({
  children,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      style={{
        cursor: 'pointer',
        display: disabled ? 'none' : 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        right: '1%',
        opacity: disabled ? '0' : '1',
        userSelect: 'none',
      }}>
      {children}
    </button>
  );
}

export function LeftArrow() {
  const { isFirstItemVisible, scrollPrev, visibleElements, initComplete } =
    React.useContext(VisibilityContext);
  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible)
  );

  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollPrev()}>
      <div className="absolute w-8 h-8 flex items-center justify-center bg-white hover:bg-gray-100 border border-grayBorder rounded-full shadow-xl -left-0 bottom-[64%] translate-y-1/2 z-10 group duration-150">
        <ChevronNavIcon isLeft />
      </div>
    </Arrow>
  );
}

export function RightArrow() {
  const { isLastItemVisible, scrollNext, visibleElements } =
    React.useContext(VisibilityContext);
  const [disabled, setDisabled] = React.useState(
    !visibleElements.length && isLastItemVisible
  );

  React.useEffect(() => {
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
    <Arrow disabled={disabled} onClick={() => scrollNext()}>
      <div className="absolute w-8 h-8 flex items-center justify-center bg-white hover:bg-gray-100 border border-grayBorder rounded-full shadow-xl -right-0 bottom-[64%] translate-y-1/2 z-10 group duration-150">
        <ChevronNavIcon />
      </div>
    </Arrow>
  );
}
