import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useModalAtom } from '../../recoil/atoms/store';
import { InformModalType } from '../../types_new/commonTypes';
import InquiryModal from '../main/support/inquiry-modal';
import TemplateDetailModal from '../vector/TemplateDetailModal';
import CircleLoader from './circle-loader';
import ConfirmModal from './confirm-modal';
import InformModal from './inform-modal';
import ShareModal from './share-modal';
import TemplatePublishModal from './template-publish-modal';

export type UseModalType = {
  type:
    | 'confirm'
    | 'create'
    | 'create-metahuman'
    | 'loader'
    | 'inform'
    | 'template-detail'
    | 'template-publish'
    | 'inquiry'
    | 'share'
    | 'template-cancel'
    | string;
  isOpen: boolean;
  data?: any;
  exportVoice?: 'N' | 'Y';
};

export default function ModalProvider() {
  const [useModal, setUseModal] = useRecoilState<UseModalType>(useModalAtom);
  const [informModal, setInformModal] = useState<InformModalType>({
    isOpen: false,
    title: '',
    message: '',
  });

  const closeModal = () => {
    setUseModal(prev => ({ ...prev, isOpen: false }));
    setTimeout(() => {
      setUseModal(prev => ({ ...prev, data: '' }));
    }, 300);
  };

  return (
    <>
      <InformModal
        isOpen={
          informModal.isOpen || (useModal.type === 'inform' && useModal.isOpen)
        }
        title={informModal.title || useModal.data?.title}
        message={informModal.message || useModal.data?.message}
        closeModal={() => {
          if (useModal.type === 'inform' && useModal.isOpen) {
            closeModal();
          } else {
            setInformModal(prev => ({
              ...prev,
              isOpen: false,
            }));
          }
        }}
      />
      <ConfirmModal
        isOpen={useModal.type === 'confirm' && useModal.isOpen}
        closeModal={closeModal}
        title={useModal.data?.title}
        message={useModal.data?.message}
        handleOnClick={useModal.data?.handleOnClick}
        loading={useModal.data?.loading}
      />
      <TemplateDetailModal
        isOpen={useModal.type === 'template-detail' && useModal.isOpen}
        closeModal={closeModal}
      />
      <TemplatePublishModal
        isOpen={useModal.type === 'template-publish' && useModal.isOpen}
        closeModal={closeModal}
      />
      <ShareModal
        isOpen={useModal.type === 'share' && useModal.isOpen}
        closeModal={closeModal}
      />

      <CircleLoader isOpen={useModal.type === 'loader' && useModal.isOpen} />
      <InquiryModal
        isOpen={useModal.type === 'inquiry' && useModal.isOpen}
        closeModal={closeModal}
      />
    </>
  );
}
