import { t } from 'i18next';
import { debounce } from 'lodash';
import QueryString from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { langArray } from '../array/languageArray';
import ScriptArrow from '../images/metahuman/icon-arrow-script.svg';
import TranslationIcon from '../images/metahuman/icon-script01.svg';
import {
  createTranslate,
  createTranslateWav,
} from '../networks/translate.service';
import { baseURL } from '../services/defaultClient';
import { createScript } from '../services/project';
import Loader from './lottie/Loader';
import ArrowRightSmall from './vector/ArrowRightSmall';
import PlayIcon from './vector/PlayIcon';
import StopIcon from './vector/StopIcon';

interface Props {
  sceneNum: string;
  currentLangName: string;
  currentLang: string;
  targetLang?: string;
  currentValue?: any;
  targetValue?: string;
  modelId: string;
}

export default function TalkTranslateModal({
  metaInfo,
  scriptValue,
  closeModal,
  getScriptValue,
  selectedHumanLang,
  voiceId,
  projectLang,
  notSetLang,
  modelId,
}) {
  const [modalInfo, setModalInfo] = useState<Props>({
    sceneNum: '01',
    currentLangName: t('한국어 (Korean)'),
    currentLang: 'ko',
    targetLang: t('번역할 언어를 선택해주세요.'),
    currentValue: scriptValue,
    targetValue: '',
    modelId,
  });

  const [translatedLang, setTranslatedLang] = useState('');
  const [targetLang, setTargetLang] = useState('');
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [targetPreviewLoading, setTargetPreviewLoading] = useState(false);
  const [currentValueIsPlaying, setCurrentValueIsPlaying] = useState(false);
  const [targetValueIsPlaying, setTargetValueIsPlaying] = useState(false);
  const [scriptAudio, setScriptAudio] = useState<HTMLAudioElement>(null);
  const koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
  const textareaRef = useRef<HTMLTextAreaElement>(scriptValue);
  const location = useLocation();
  const queryData = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
  }); // query
  const projectId = queryData?.projectId;

  const handleCurrentPreviewClick = useCallback(async () => {
    if (!modalInfo.currentValue) {
      return;
    }

    let currentLang: string;
    if (notSetLang) {
      currentLang = notSetLang;
    } else if (selectedHumanLang) {
      currentLang = selectedHumanLang;
    } else {
      currentLang = projectLang;
    }

    if (currentValueIsPlaying && scriptAudio !== null) {
      scriptAudio.pause();
      setCurrentValueIsPlaying(false);
      return;
    }

    setPreviewLoading(true);

    if (koreanPattern.test(modalInfo.currentValue)) {
      await createScript({
        voiceId: String(voiceId),
        text: modalInfo.currentValue,
        // tempo: scriptSpeedValue.speed,
        tempo: 1.0,
      })
        .then(res => res.data.body.wavFilePath.substr(13))
        .then(filePath => {
          const audio = new Audio(`${baseURL}${filePath}`);
          setScriptAudio(audio);
          audio.play();
          setCurrentValueIsPlaying(true);
        })
        .catch(err => console.log(err))
        .finally(() => setPreviewLoading(false));
    } else {
      await createTranslateWav({
        modelId: modalInfo.modelId,
        text: modalInfo.currentValue,
        targetLang: currentLang,
        projectId: +projectId,
        voiceId: String(voiceId),
        tempo: 1.0,
        postId: metaInfo?.postId,
      })
        .then(res => res.data.body.wavFilePath.substr(13))
        .then(filePath => {
          const audio = new Audio(`${baseURL}${filePath}`);
          setScriptAudio(audio);
          audio.play();
          setCurrentValueIsPlaying(true);
        })
        .catch(err => console.log(err))
        .finally(() => setPreviewLoading(false));
    }
  }, [currentValueIsPlaying, modalInfo]);

  const handleTargetPreviewClick = useCallback(async () => {
    if (!modalInfo.targetValue) {
      return;
    }
    if (targetValueIsPlaying && scriptAudio !== null) {
      scriptAudio.pause();
      setTargetValueIsPlaying(false);
      return;
    }
    setTargetPreviewLoading(true);
    if (koreanPattern.test(modalInfo.targetValue)) {
      await createScript({
        voiceId: String(voiceId),
        text: modalInfo.targetValue,
        // tempo: scriptSpeedValue.speed,
        tempo: 1.0,
      })
        .then(res => res.data.body.wavFilePath.substr(13))
        .then(filePath => {
          const audio = new Audio(`${baseURL}${filePath}`);
          setScriptAudio(audio);
          audio.play();
          setTargetValueIsPlaying(true);
        })
        .catch(err => console.log(err))
        .finally(() => setTargetPreviewLoading(false));
    } else {
      await createTranslateWav({
        modelId: modalInfo.modelId,
        text: modalInfo.targetValue,
        targetLang,
        projectId: +projectId,
        voiceId: String(voiceId),
        // tempo: scriptSpeedValue.speed,
        tempo: 1.0,
        postId: metaInfo?.postId,
      })
        .then(res => res.data.body.wavFilePath.substr(13))
        .then(filePath => {
          const audio = new Audio(`${baseURL}${filePath}`);
          setScriptAudio(audio);
          audio.play();
          setTargetValueIsPlaying(true);
        })
        .catch(err => console.log(err))
        .finally(() => setTargetPreviewLoading(false));
    }
  }, [targetValueIsPlaying, modalInfo]);

  useEffect(() => {
    if (scriptAudio !== null) {
      scriptAudio.onended = () => {
        setTargetValueIsPlaying(false);
        setCurrentValueIsPlaying(false);
        setScriptAudio(null);
      };
    }
  }, [scriptAudio]);

  const handleTranslateClick = async (attTargetLang: string) => {
    setLoading(true);
    setTargetLang(attTargetLang);
    let targetLang: string;
    switch (attTargetLang) {
      case 'en':
        targetLang = t('영어 (English)');
        break;
      case 'ko':
        targetLang = t('한국어 (Korean)');
        break;
      case 'ja':
        targetLang = t('일본어 (Japanese)');
        break;
      case 'zh-cn':
        targetLang = t('중국어 (Chinese)');
        break;
      case 'es':
        targetLang = t('스페인어 (Spanish)');
        break;
      case 'vi':
        targetLang = t('베트남어 (Vietnamese)');
        break;
      case 'fr':
        targetLang = t('프랑스어 (French)');
        break;
      case 'cs':
        targetLang = t('체코어 (Czech)');
        break;
      case 'da':
        targetLang = t('덴마크어 (Danish)');
        break;
      case 'de':
        targetLang = t('독일어 (German)');
        break;
      case 'fil':
        targetLang = t('필리핀어 (Phillippines)');
        break;
      case 'hi':
        targetLang = t('힌디어 (Hindi)');
        break;
      case 'id':
        targetLang = t('인도네시아어 (Indonesian)');
        break;
      case 'it':
        targetLang = t('이탈리아어 (Italy)');
        break;
      case 'nl':
        targetLang = t('네덜란드어 (Netherlands)');
        break;
      case 'pl':
        targetLang = t('폴란드어 (Polish)');
        break;
      case 'pt':
        targetLang = t('포르투갈어 (Portuguese)');
        break;
      case 'ru':
        targetLang = t('러시아어 (Russian)');
        break;
      case 'tr':
        targetLang = t('터키어 (Turkish)');
        break;
      default:
        targetLang = `${projectLang} (프로젝트 언어)`;
    }
    await createTranslate({
      text: modalInfo.currentValue,
      voiceId: 10,
      currentLang: notSetLang || selectedHumanLang,
      targetLang: attTargetLang,
    })
      .then(({ text }) =>
        setModalInfo({
          ...modalInfo,
          targetLang,
          targetValue: text,
        })
      )
      .catch(err => console.log(err))
      .finally(() => {
        setTranslatedLang(attTargetLang);
        setLoading(false);
      });
  };

  const translateText = debounce(e => {
    if (!targetLang) {
      return;
    }
    setLoading(true);
    createTranslate({
      text: e.target.value,
      voiceId: 10,
      currentLang: selectedHumanLang,
      targetLang,
    })
      .then(({ text }) =>
        setModalInfo({
          ...modalInfo,
          targetValue: text,
        })
      )
      .finally(() => setLoading(false));
  }, 500);

  useEffect(() => {
    let selectedLanguage;
    let projectLanguage;
    let notSetLangName;
    switch (selectedHumanLang) {
      case 'en':
        selectedLanguage = t('영어 (English)');
        break;
      case 'ko':
        selectedLanguage = t('한국어 (Korean)');
        break;
      case 'ja':
        selectedLanguage = t('일본어 (Japanese)');
        break;
      case 'zh-cn':
        selectedLanguage = t('중국어 (Chinese)');
        break;
      case 'es':
        selectedLanguage = t('스페인어 (Spanish)');
        break;
      case 'vi':
        selectedLanguage = t('베트남어 (Vietnamese)');
        break;
      case 'fr':
        selectedLanguage = t('프랑스어 (French)');
        break;
      case 'cs':
        selectedLanguage = t('체코어 (Czech)');
        break;
      case 'da':
        selectedLanguage = t('덴마크어 (Danish)');
        break;
      case 'de':
        selectedLanguage = t('독일어 (German)');
        break;
      case 'fil':
        selectedLanguage = t('필리핀어 (Phillippines)');
        break;
      case 'hi':
        selectedLanguage = t('힌디어 (Hindi)');
        break;
      case 'id':
        selectedLanguage = t('인도네시아어 (Indonesian)');
        break;
      case 'it':
        selectedLanguage = t('이탈리아어 (Italy)');
        break;
      case 'nl':
        selectedLanguage = t('네덜란드어 (Netherlands)');
        break;
      case 'pl':
        selectedLanguage = t('폴란드어 (Polish)');
        break;
      case 'pt':
        selectedLanguage = t('포르투갈어 (Portuguese)');
        break;
      case 'ru':
        selectedLanguage = t('러시아어 (Russian)');
        break;
      case 'tr':
        selectedLanguage = t('터키어 (Turkish)');
        break;
      default:
        selectedLanguage = `${projectLang} (프로젝트 언어)`;
    }
    switch (notSetLang) {
      case 'en':
        notSetLangName = t('영어 (English)');
        break;
      case 'ko':
        notSetLangName = t('한국어 (Korean)');
        break;
      case 'ja':
        notSetLangName = t('일본어 (Japanese)');
        break;
      case 'zh-cn':
        notSetLangName = t('중국어 (Chinese)');
        break;
      case 'es':
        notSetLangName = t('스페인어 (Spanish)');
        break;
      case 'vi':
        notSetLangName = t('베트남어 (Vietnamese)');
        break;
      case 'fr':
        notSetLangName = t('프랑스어 (French)');
        break;
      case 'cs':
        notSetLangName = t('체코어 (Czech)');
        break;
      case 'da':
        notSetLangName = t('덴마크어 (Danish)');
        break;
      case 'de':
        notSetLangName = t('독일어 (German)');
        break;
      case 'fil':
        notSetLangName = t('필리핀어 (Phillippines)');
        break;
      case 'hi':
        notSetLangName = t('힌디어 (Hindi)');
        break;
      case 'id':
        notSetLangName = t('인도네시아어 (Indonesian)');
        break;
      case 'it':
        notSetLangName = t('이탈리아어 (Italy)');
        break;
      case 'nl':
        notSetLangName = t('네덜란드어 (Netherlands)');
        break;
      case 'pl':
        notSetLangName = t('폴란드어 (Polish)');
        break;
      case 'pt':
        notSetLangName = t('포르투갈어 (Portuguese)');
        break;
      case 'ru':
        notSetLangName = t('러시아어 (Russian)');
        break;
      case 'tr':
        notSetLangName = t('터키어 (Turkish)');
        break;
      default:
        notSetLangName = `${projectLang} (프로젝트 언어)`;
    }
    switch (projectLang) {
      case 'en':
        projectLanguage = t('영어 (English)');
        break;
      case 'ko':
        projectLanguage = t('한국어 (Korean)');
        break;
      case 'ja':
        projectLanguage = t('일본어 (Japanese)');
        break;
      case 'zh-cn':
        projectLanguage = t('중국어 (Chinese)');
        break;
      case 'es':
        projectLanguage = t('스페인어 (Spanish)');
        break;
      case 'vi':
        projectLanguage = t('베트남어 (Vietnamese)');
        break;
      case 'fr':
        projectLanguage = t('프랑스어 (French)');
        break;
      case 'cs':
        projectLanguage = t('체코어 (Czech)');
        break;
      case 'da':
        projectLanguage = t('덴마크어 (Danish)');
        break;
      case 'de':
        projectLanguage = t('독일어 (German)');
        break;
      case 'fil':
        projectLanguage = t('필리핀어 (Phillippines)');
        break;
      case 'hi':
        projectLanguage = t('힌디어 (Hindi)');
        break;
      case 'id':
        projectLanguage = t('인도네시아어 (Indonesian)');
        break;
      case 'it':
        projectLanguage = t('이탈리아어 (Italy)');
        break;
      case 'nl':
        projectLanguage = t('네덜란드어 (Netherlands)');
        break;
      case 'pl':
        projectLanguage = t('폴란드어 (Polish)');
        break;
      case 'pt':
        projectLanguage = t('포르투갈어 (Portuguese)');
        break;
      case 'ru':
        projectLanguage = t('러시아어 (Russian)');
        break;
      case 'tr':
        projectLanguage = t('터키어 (Turkish)');
        break;
      default:
        projectLanguage = `${projectLang} (프로젝트 언어)`;
    }

    let displayedLang;
    if (notSetLang) {
      displayedLang = notSetLangName;
    } else if (selectedHumanLang) {
      displayedLang = selectedLanguage;
    } else {
      displayedLang = projectLanguage;
    }

    setModalInfo(prev => ({
      ...prev,
      currentLangName: displayedLang,
    }));
  }, []);

  useEffect(() => {
    if (!textareaRef.current) return;
    textareaRef.current.value = scriptValue;
  }, []);

  return (
    <div className="bg-white max-w-7xl w-full h-[440px] z-50 p-8 py-6 space-y-1 shadow-2xl rounded-xl fixed top-[30%] left-[60%] translate-x-[-60%] flex flex-col justify-between">
      <h1 className="text-left font-semibold text-xl mb-4">
        {metaInfo.metaName}
      </h1>
      <div className="flex justify-between">
        <div className="relative space-y-2 w-[48%]">
          <div className="flex flex-col p-4 space-y-2">
            <span className="text-left">{modalInfo.currentLangName}</span>
            <textarea
              disabled={!targetLang}
              spellCheck="false"
              maxLength={350}
              ref={textareaRef}
              onChange={translateText}
              className={`border ${
                targetLang ? 'text-black' : '!text-zinc-400'
              } border-black rounded-xl resize-none h-40 p-3`}
            />
            <div className="flex !justify-end items-center space-x-2">
              {currentValueIsPlaying ? (
                <button
                  className="p-2 rounded-full bg-[#7b61ff] hover:opacity-50 duration-100 ease-out"
                  onClick={handleCurrentPreviewClick}>
                  <StopIcon />
                </button>
              ) : (
                <>
                  <button
                    className={`p-2 rounded-full ${
                      previewLoading
                        ? 'bg-none absolute right-[58px]'
                        : 'bg-[#ededed] hover:opacity-50 duration-100 ease-out'
                    }`}
                    onClick={previewLoading ? null : handleCurrentPreviewClick}>
                    {previewLoading ? (
                      <Loader width={50} height={50} />
                    ) : (
                      <PlayIcon />
                    )}
                  </button>
                </>
              )}
              <span className="text-zinc-400 text-sm">
                {textareaRef.current.value?.length}/350자
              </span>
            </div>
          </div>
        </div>
        <div className="absolute right-1/2 translate-x-1/2 bottom-1/2 translate-y-1/2">
          {loading ? <Loader width={120} height={120} /> : <ArrowRightSmall />}
        </div>
        <div className="w-[48%]">
          <div className="flex flex-col p-4 space-y-2">
            <div className="flex justify-between">
              <span className="text-left">{modalInfo.targetLang}</span>
              <LanguageSelectBox handleTranslateClick={handleTranslateClick} />
            </div>
            <textarea
              disabled={!targetLang}
              spellCheck="false"
              maxLength={350}
              className="border border-black rounded-xl resize-none h-40 p-3"
              value={modalInfo.targetValue}
              onChange={e =>
                setModalInfo(prev => ({
                  ...prev,
                  targetValue: e.target.value,
                }))
              }
            />
            <div className="flex !justify-end items-center space-x-2">
              {targetValueIsPlaying ? (
                <button
                  className="p-2 rounded-full bg-[#7b61ff] hover:opacity-50 duration-100 ease-out"
                  onClick={handleTargetPreviewClick}>
                  <StopIcon />
                </button>
              ) : (
                <button
                  className={`p-2 rounded-full ${
                    targetPreviewLoading
                      ? 'bg-none absolute right-[90px]'
                      : 'bg-[#ededed] hover:opacity-50 duration-100 ease-out'
                  }`}
                  onClick={
                    targetPreviewLoading ? null : handleTargetPreviewClick
                  }>
                  {targetPreviewLoading ? (
                    <Loader width={50} height={50} />
                  ) : (
                    <PlayIcon />
                  )}
                </button>
              )}
              <span className="text-right text-zinc-400 text-sm">
                {modalInfo.targetValue?.length}/350자
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center space-x-4">
        <button
          disabled={loading}
          onClick={() => {
            closeModal();
            scriptAudio.pause();
            setTargetValueIsPlaying(false);
            setCurrentValueIsPlaying(false);
          }}
          className={`bg-zinc-300 py-3 rounded-xl w-[20%] hover:bg-zinc-200 duration-100 ease-out tracking-widest font-medium ${
            loading && 'cursor-not-allowed'
          }`}>
          {t('닫기')}
        </button>
        <button
          onClick={() => {
            getScriptValue(modalInfo.targetValue, translatedLang);
            closeModal();
          }}
          disabled={loading}
          className={`bg-[#8d77fc] text-white py-3 rounded-xl w-[40%] hover:bg-[#9f8cfd] duration-100 ease-out tracking-widest font-medium ${
            loading && 'cursor-not-allowed'
          }`}>
          {t('스크립트 적용')}
        </button>
      </div>
    </div>
  );
}

function LanguageSelectBox({ handleTranslateClick }) {
  const [languageSelect, setLanguageSelect] = useState(false);
  const { t } = useTranslation();
  return (
    <div
      className={`script-select select-154 ${
        languageSelect && 'active'
      } absolute right-[50px] top-[96px]`}
      onClick={() => {
        setLanguageSelect(!languageSelect);
      }}>
      <img src={TranslationIcon} alt="" />
      <p>{t('언어 선택')}</p>
      <img
        src={ScriptArrow}
        alt="arrow for selecting script"
        className="scriptArrow"
      />
      {languageSelect && (
        <div className="script-wrap w-[130%] text-left h-60 overflow-y-auto">
          <ul>
            {langArray.map((list, idx) => (
              <li key={`list-${idx}`}>
                <button
                  className="px-2 w-full justify-start"
                  type="button"
                  onClick={() => {
                    handleTranslateClick(list.arg);
                  }}>
                  <img
                    src={list.flag}
                    alt="country flag"
                    className="countryIcon mr-2 !w-6"
                  />
                  {t(list.lang)}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
