import moment from 'moment';
import { CreditUsageType } from '../../../../types_new/main/service/types';
import { findValueByKey } from '../../../../utils/common';

const dataList = ['상품', '크레딧 내역', '사용일시'];

type Props = {
  data: CreditUsageType;
};

export default function MobileCreditCard({ data }: Props) {
  return (
    <div className="max-sm:flex hidden my-4 rounded-lg bg-white text-darkSecondary py-4 px-5 space-y-4 items-center justify-between">
      <ul className="flex flex-col space-y-3">
        {dataList.map((list, idx) => (
          <li key={`list-${idx}`} className="font-normal text-sm">
            {list}
          </li>
        ))}
      </ul>

      <ul className="flex flex-col text-sm font-medium space-y-3 text-right">
        <li>{data.postTitle}</li>
        <li>{findValueByKey(data.metaData, 'credit')}</li>
        <li>{moment(data.registerDate).format('YYYY-MM-DD HH:mm')}</li>
      </ul>
    </div>
  );
}
