import { useEffect, useState } from 'react';
// import 'swiper/css';
import { useRecoilValue } from 'recoil';
import 'swiper/swiper.min.css';
import { doGetNoticeList } from '../../../networks/source.service';
import { lang } from '../../../recoil/atoms/user';
import { NoticeModalType } from '../../../types_new/main/landing/types';
import { PostType } from '../../../types_new/main/resource/types';
import NoticeGrid from '../notice/notice-grid';
import NoticeMobileSwiper from '../notice/notice-mobile-swiper';
import NoticeModal from '../notice/notice-modal';
import NoticeSkeleton from '../notice/notice-skeleton';
import LandingTitle from './landing-title';

export default function NoticeSection() {
  const [loading, setLoading] = useState(true);
  const [noticeList, setNoticeList] = useState<PostType[]>([]);
  const [noticeModal, setNoticeModal] = useState<NoticeModalType>({
    metaData: [],
    postTitle: '',
    registerDate: '',
    postContent: '',
    isOpen: false,
  });
  const language = useRecoilValue(lang);

  useEffect(() => {
    doGetNoticeList({ postsPerPage: 6, lang: language })
      .then(({ posts }) => setNoticeList(posts))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [language]);

  const openModal = (notice: PostType) => {
    const { postTitle, registerDate, postContent, metaData } = notice;
    setNoticeModal({
      isOpen: true,
      postTitle,
      registerDate,
      postContent,
      metaData,
    });
  };

  const closeModal = () => {
    setNoticeModal(prev => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <>
      <NoticeModal noticeModal={noticeModal} closeModal={closeModal} />
      <section className="relative w-full pb-12 lg:pb-52 pt-12 lg:pt-20">
        <div className="max-w-screen-xl w-full mx-auto sm:px-5">
          <div className="px-5">
            <LandingTitle
              badge="공지사항"
              title="다양한 이벤트와 업데이트 소식을 지금 바로 확인하세요!"
              description="매일 매일 새로운 소식을 받아요."
              link="/notice"
            />
          </div>
          {loading ? (
            <NoticeSkeleton />
          ) : (
            <>
              <NoticeGrid openModal={openModal} noticeList={noticeList} />
              <NoticeMobileSwiper
                openModal={openModal}
                noticeList={noticeList}
              />
            </>
          )}
        </div>
        <div className="absolute bottom-0 w-full bg-gradient-to-t from-black to-black/0 h-12 lg:h-24" />
      </section>
    </>
  );
}
