import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { myPageLinkArray } from '../../../arrays';

export default function MyPageNavigation() {
  const { info } = useParams();
  const { t } = useTranslation();

  const isActive = (link: string) => {
    return link === info;
  };

  return (
    <nav className="my-6 lg:my-10 w-full flex justify-center">
      <ul className="flex space-x-2.5 lg:space-x-4 overflow-x-auto no-scroll px-5">
        {myPageLinkArray.map(({ name, link }) => (
          <Link key={`my-page-${link}`} to={`/my-page/${link}`}>
            <li
              className={`text-sm 2xl:text-base whitespace-nowrap border border-primary font-medium sm:font-semibold py-2 px-3.5 sm:py-2.5 sm:px-4 2xl:py-3 2xl:px-5 rounded-full duration-150 ${
                isActive(link)
                  ? 'bg-primary text-white cursor-default'
                  : 'bg-transparent text-primary hover:bg-primary/90 hover:text-white'
              }`}>
              {t(name)}
            </li>
          </Link>
        ))}
      </ul>
    </nav>
  );
}
