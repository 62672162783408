import { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { PostType } from '../../../types_new/main/resource/types';
import NoticeCard from './notice-card';

type Props = {
  noticeList: PostType[];
  openModal: (notice: PostType) => void;
};
export default function NoticeMobileSwiper({ noticeList, openModal }: Props) {
  const [activeIdx, setActiveIdx] = useState(0);

  return (
    <div>
      <Swiper
        onRealIndexChange={({ realIndex }: { realIndex: number }) =>
          setActiveIdx(realIndex)
        }
        className="overflow-y-visible px-5 pt-6 sm:hidden"
        spaceBetween={30}
        slidesPerView={1.2}>
        {noticeList.map((notice, idx) => (
          <SwiperSlide key={`product-${idx}`}>
            <NoticeCard
              openModal={openModal}
              notice={notice}
              idx={idx}
              activeIdx={activeIdx}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="mt-7 flex justify-center space-x-1.5 sm:hidden">
        {noticeList.map((_, idx) => (
          <div
            key={`showcaseIdx-${idx}`}
            className={`w-1.5 h-1.5 rounded-full duration-200 ${
              idx === activeIdx ? 'bg-primary' : 'bg-primary/40'
            }`}
          />
        ))}
      </div>
    </div>
  );
}
