import { useEffect, useState } from 'react';
import { creditFilterArray } from '../../../../arrays';
import { getCreditUsageList } from '../../../../networks/member.service';
import { CreditUsageType } from '../../../../types_new/main/service/types';
import Pagination from '../../../common/Pagination';
import InfoFilterSection from '../info-filter-section';
import CreditCard from './credit-card';
import CreditTable from './credit-table';
import MobileCreditCard from './mobile-credit-card';

type Props = {
  paycreditleft: string;
};

export default function CreditInfo({ paycreditleft }: Props) {
  const [filter, setFilter] = useState(creditFilterArray[0]);
  const [usageData, setUsageData] = useState<CreditUsageType[]>([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [paged, setPaged] = useState(1);
  const [parentPage, setParentPage] = useState(1);

  useEffect(() => {
    setLoading(true);
    getCreditUsageList({ postsPerPage: 10, paged, category: filter.value })
      .then(({ posts, total }) => {
        setUsageData(posts);
        setTotal(total);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [paged, filter]);

  const onPageChange = (page: number) => {
    setPaged(page);
    setParentPage(page);
  };

  return (
    <section className="text-darkSecondary max-w-[1278px] w-full px-5">
      <CreditCard credit={paycreditleft} />
      <InfoFilterSection
        total={usageData.length}
        filterArray={creditFilterArray}
        setFilter={setFilter}
        filter={filter}
      />
      {loading ? (
        <MobileSkeleton />
      ) : (
        usageData.map((data, idx) => (
          <MobileCreditCard key={`mobileCredit-${idx}`} data={data} />
        ))
      )}
      <CreditTable creditData={usageData} loading={loading} paged={paged} />
      <Pagination
        onPageChange={onPageChange}
        totalItems={total}
        currentPage={paged}
        parentPage={parentPage}
        itemsPerPage={20}
      />
    </section>
  );
}

function MobileSkeleton() {
  const dataList = ['상품', '크레딧 내역', '사용일시'];
  const array = [1, 2, 3, 4, 5];

  return (
    <>
      {array.map((_, idx) => (
        <div
          key={`mobileCreditSkeleton-${idx}`}
          className="max-sm:flex hidden my-4 rounded-lg bg-white text-darkSecondary py-4 px-5 space-y-4 items-center justify-between">
          <ul className="flex flex-col space-y-3">
            {dataList.map((list, idx) => (
              <li key={`list-${idx}`} className="font-normal text-sm">
                {list}
              </li>
            ))}
          </ul>

          <ul className="flex flex-col text-sm font-medium space-y-3 text-right">
            <li>
              <div className="w-24 h-1.5 ml-auto rounded-full bg-gray-400 animate-pulse my-1" />
            </li>
            <li>
              <div className="w-14 h-1.5 ml-auto rounded-full bg-gray-400 animate-pulse my-1" />
            </li>
            <li>
              <div className="w-32 h-1.5 ml-auto rounded-full bg-gray-400 animate-pulse my-1" />
            </li>
          </ul>
        </div>
      ))}
    </>
  );
}
