type Props = {
  isWhiteTheme?: boolean;
  isLeft?: boolean;
};
export default function WhiteChevron({ isLeft, isWhiteTheme }: Props) {
  return (
    <svg
      className={`${isLeft && 'rotate-180'} ${
        isWhiteTheme && 'stroke-darkSecondary'
      }`}
      strokeWidth="0.7"
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.99451 2.67109L7.64851 6.52984L3.99451 10.3886C3.9291 10.4575 3.89247 10.5501 3.89247 10.6465C3.89247 10.7428 3.9291 10.8354 3.99451 10.9043C4.02629 10.9377 4.06421 10.9642 4.10607 10.9823C4.14792 11.0004 4.19286 11.0098 4.23826 11.0098C4.28366 11.0098 4.32861 11.0004 4.37046 10.9823C4.41232 10.9642 4.45024 10.9377 4.48201 10.9043L8.37001 6.79929C8.43827 6.7272 8.47648 6.63051 8.47648 6.52984C8.47648 6.42917 8.43827 6.33248 8.37001 6.26039L4.48276 2.15536C4.45097 2.12174 4.41295 2.09502 4.37095 2.07677C4.32896 2.05853 4.28384 2.04913 4.23826 2.04913C4.19268 2.04913 4.14756 2.05853 4.10557 2.07677C4.06358 2.09502 4.02556 2.12174 3.99376 2.15536C3.92835 2.2243 3.89172 2.31685 3.89172 2.41322C3.89172 2.5096 3.92835 2.60215 3.99376 2.67109L3.99451 2.67109Z"
        fill="white"
      />
    </svg>
  );
}
