import { Dispatch, MouseEvent, SetStateAction } from 'react';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import {
  MetahumanListType,
  MetahumanTabType,
} from '../../../types_new/main/resource/types';
import { changeHost } from '../../../utils/common';

type Props = {
  setTab: Dispatch<SetStateAction<MetahumanTabType>>;
  posture: MetahumanListType['poses'][number];
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  projectInfo: NewProjectInfoType;
};

export default function PostureCard({
  setTab,
  posture: { name, thumbnail, poseId },
  setProjectInfo,
  projectInfo: { defaultMetahuman },
}: Props) {
  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!defaultMetahuman.poseId) {
      setTab('voice');
    }
    setProjectInfo(prev => ({
      ...prev,
      defaultMetahuman: { ...prev.defaultMetahuman, poseId },
    }));
  };

  const isActive = defaultMetahuman.poseId === poseId;

  return (
    <div className="flex flex-col space-y-1">
      <div
        onClick={handleOnClick}
        style={{ backgroundImage: `url(${changeHost(thumbnail)})` }}
        className={`w-full h-20 bg-cover bg-center rounded-[10px] ${
          isActive ? 'ring ring-primary' : 'hover:opacity-70 cursor-pointer'
        } duration-100`}
      />
      <span className="text-xs font-medium text-[#5E6479]">{name}</span>
    </div>
  );
}
