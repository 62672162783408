import SubpageTitle from '../../components/main/landing/subpage-title';
import EnterpriseInfo from '../../components/main/membership/enterprise-info';
import MembershipInfoTable from '../../components/main/membership/membership-info-table';
import ProductMembershipList from '../../components/main/membership/product-membership-list';
import SubscribeMembershipList from '../../components/main/membership/subscribe-membership-list';
import SubpageContainer from '../../components/main/subpage-container';

const TITLE = '당신에게 알맞는 플루닛 플랜으로\n서비스 비용을 최적화하세요.';

export default function MembershipPage() {
  return (
    <SubpageContainer>
      <div className="pb-20 md:pb-28 lg:pb-36 xl:pb-40 2xl:pb-52">
        <SubpageTitle badge="요금" title={TITLE} />
        <ProductMembershipList />
        <div className="flex flex-col-reverse lg:flex-col">
          <EnterpriseInfo />
          <SubscribeMembershipList />
        </div>
        <MembershipInfoTable />
      </div>
    </SubpageContainer>
  );
}
