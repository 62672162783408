/* eslint-disable */

import { AxiosResponse } from 'axios';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { metahumanModalStepArray } from '../../arrays';
import { koreanRegex } from '../../constants/regex';
import '../../css/reset.css';
import '../../css/style.css';
import useLanguage from '../../hooks/use-lang';
import '../../locale/i18n';
import { doGetMetahumanList } from '../../networks/source.service';
import { createTranslateWav } from '../../networks/translate.service';
import { ICreateTalkScript, createScript } from '../../services/project';
import { block } from '../../store/block';
import { TOGGLE_LOADING } from '../../store/common/loading';
import {
  autoScript as autoScriptArray,
  centiSecond,
  projectLang,
  setSegmentsInfo,
  pages as storedPages,
} from '../../store/pages';
import { IPages, ISegments } from '../../store/pages/types';
import { ProjectInfo } from '../../types/project';
import { CreateMetahumanPopupType } from '../../types_new/project/types';
import { createUuid } from '../../utils/common';
import ListTab from '../ListTab';
import TranslateModals from '../TranslateModals';
import Loader from '../common/loader';
import ChatGPTModal from './ChatGPTModal';
import MetahumanTalkModal from './MetahumanTalkModal';

const INITIAL_STATE: CreateMetahumanPopupType = {
  popupNum: 1,
  modelId: '',
  metaHumanName: '',
  metaHumanImg: '',
  styleArray: [],
  styleValue: '',
  styleName: '',
  poseArray: [],
  poseId: '',
  poseName: '',
  postId: 0,
  voicesArray: [],
  voiceId: '',
  voiceName: '',
  scriptValue: '',
  scriptPopup: 0,
  translation: '',
  motionPreset: '',
  lockPopup: false,
  preview: '',
};
interface Props {
  setActive: Dispatch<SetStateAction<number>>;
  active: number;
  blocks: any;
  setBlocks: Dispatch<SetStateAction<any>>;
  projectInfo: ProjectInfo;
  createTalkScript: (
    arg: ICreateTalkScript
  ) => Promise<AxiosResponse<any, any>>;
  setMetahuman: Dispatch<SetStateAction<boolean>>;
  metahuman: boolean;
}
const MetahumanModal = ({
  active,
  setActive,
  blocks,
  setBlocks,
  projectInfo,
  createTalkScript,
  setMetahuman,
  metahuman,
}: Props) => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const widthCenter = projectInfo?.defaultMetahumanSize?.left;
  const defaultWidth = projectInfo?.defaultMetahumanSize?.width;
  const defaultHeight = projectInfo?.defaultMetahumanSize?.height;

  const language = useLanguage();
  const loading = useSetRecoilState(TOGGLE_LOADING);
  const [talkType, setTalkType] = useState<'monologue' | 'conversation'>(
    'monologue'
  );
  const [tempo, setTempo] = useState(null);
  const [changeMH, setChangeMH] = useState(false);
  const [changeTalk, setChangeTalk] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [talkPopup, setTalkPopup] = useState(false);
  const [popupInfo, setPopupInfo] =
    useState<CreateMetahumanPopupType>(INITIAL_STATE);
  const [metahumanList, setMetahumanList] = useState([]);
  const [pages, setPages] = useRecoilState(storedPages);
  const [_, setCs] = useRecoilState(centiSecond);
  const setAutoScript = useSetRecoilState(autoScriptArray);
  const [isScriptPlaying, setIsScriptPlaying] = useState(false);
  const [scriptAudio, setScriptAudio] = useState<HTMLAudioElement>(null);
  const selectedBlock = useRecoilValue(block);
  const [targetLang, setTargetLang] = useState('');
  const [translateLoading, setTranslateLoading] = useState(false);
  const [translatedLang, setTranslatedLang] = useState('');
  const [projectLanguage, setProjectLanguage] = useRecoilState(projectLang);
  const [scriptChange, setScriptChange] = useState(false);
  const [translateModal, setTranslateModal] = useState(false);
  const [chatGPTModal, setChatGPTModal] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [scriptValue, setScriptValue] = useState('');
  const selectedHumanLang =
    pages[0]?.segments[0]?.segment[selectedBlock?.groupIndex]?.currentLang;

  const handleAccordion = (idx: number) => {
    if (popupInfo.popupNum === idx) setPopupInfo({ ...popupInfo, popupNum: 0 });
    else setPopupInfo({ ...popupInfo, popupNum: idx });
  };

  const isCompleteDisabled =
    !popupInfo.scriptValue ||
    !String(popupInfo.voiceId) ||
    !popupInfo.styleValue ||
    !popupInfo.poseId;

  // insertTime
  const getInsertTime = () => {
    let insertT = 0;
    const tmpSegment = pages[0]?.segments[0]?.segment?.filter(
      item => item.mediaType === 'metahuman'
    );
    // +220724 : 빈 레일일 때 예외처리
    if (!tmpSegment || !tmpSegment.length) {
      return 0;
    }
    let maxInsertTime = -1;
    let maxInsertTimeId = -1;
    tmpSegment.map(item => {
      if (maxInsertTime <= item.insertTime) {
        maxInsertTime = item.insertTime;
        maxInsertTimeId = item.segmentId || item.imageId || item.titleId;
      }
    });
    tmpSegment.map(item => {
      if (
        item.segmentId === maxInsertTimeId ||
        item.imageId === maxInsertTimeId ||
        item.titleId === maxInsertTimeId
      ) {
        insertT = item.insertTime + item.duration;
      }
    });
    setCs(insertT);
    return Math.floor(insertT);
  };

  useEffect(() => {
    doGetMetahumanList({ lang: language })
      .then(setMetahumanList)
      .catch(console.log);
  }, []);

  useEffect(() => {
    const array = pages[0]?.segments[0]?.segment?.filter(
      item => item.mediaType === 'metahuman'
    );
    const tmpAutoScript = [];
    array?.map(item => {
      item?.metaData?.sync?.map(ele => {
        tmpAutoScript.push({
          ...ele,
          segmentId: item.segmentId,
        });
      });
    });
    setAutoScript(tmpAutoScript);
  }, [pages]);

  useEffect(() => {
    if (scriptAudio !== null)
      scriptAudio.onended = () => {
        setIsScriptPlaying(false);
        setScriptAudio(null);
      };
  }, [scriptAudio]);

  /**
   * 기존의 메타휴먼 수정 화면에서 완료 시 호출
   * @returns
   */
  const applyToMetahuman = () => {
    console.log('applyToMetahuman ---------------- ');
    if (popupInfo.scriptValue.length > 350) {
      alert(t('글자 제한 수를 초과했습니다!'));
      return;
    } else if (!popupInfo.scriptValue.replace(/\s/g, '').length) {
      alert(t('스크립트가 없습니다.'));
      return;
    }
    loading({
      isLoading: true,
      text: t('스크립트 추가 중입니다.'),
      time:
        popupInfo.motionPreset !== ''
          ? popupInfo.motionPreset.length
          : popupInfo.scriptValue.length,
    });

    // 스크립트가 한국어 인지 구분
    const isKoreanScript = koreanRegex.test(popupInfo.scriptValue);

    let applyLang: string; // 외국어 지정.
    if (scriptChange) {
      {
        translatedLang
          ? (applyLang = translatedLang)
          : (applyLang = selectedHumanLang);
      }
    } else {
      {
        translatedLang
          ? (applyLang = translatedLang)
          : (applyLang = projectInfo.projectLanguage);
      }
    }

    // 한글인 경우와 그렇지 않은 경우에 대한 다른 API를 이용하여 wav 파일 요청
    let createWav;
    if (isKoreanScript) {
      createWav = createScript({
        projectId: +projectId,
        voiceId: String(popupInfo.voiceId),
        text:
          popupInfo.motionPreset !== ''
            ? popupInfo.motionPreset
            : popupInfo.scriptValue,
        tempo,
      });
    } else {
      createWav = createTranslateWav({
        modelId: popupInfo.modelId,
        targetLang: applyLang,
        projectId: +projectId,
        voiceId: String(projectInfo.metaData.voiceId),
        text: popupInfo?.scriptValue,
        tempo,
        postId: popupInfo?.postId,
      });
    }

    createWav
      .then(async res => {
        console.log('========================================================');
        const tmpPage = JSON.parse(JSON.stringify(pages));
        const tmpSegments = tmpPage[0]?.segments[0];
        // const segments = pages[0]?.segments[0];
        const id = Date.now();

        // data.duration, data.wavFilePath 2값 사용
        const data = res.data.body;

        console.log('** 스크립트 수정 전 ==========================');
        console.log('[수정 전] pages>segments:' + JSON.stringify(tmpSegments));
        console.log('[수정 전] response:' + JSON.stringify(data));
        console.log('[수정 전] changeMH:' + JSON.stringify(changeMH));
        console.log('[수정 전] blocks:' + JSON.stringify(blocks));
        // when changing the script

        // 음성 api 호출 시 duration이 0 일 경우 처리
        if (!data.duration) {
          alert(t('장면의 음성파일 생성중 오류가 발생하였습니다.'));
          loading({ isLoading: false, text: '', time: 0 });
          return;
        }

        const changeDuration = isKoreanScript
          ? Math.floor(data.duration * 100)
          : Math.floor(data.duration);

        // 메타휴먼 선택하여 수정.
        if (
          changeMH &&
          blocks &&
          tmpSegments[blocks.segmentsGroup][blocks.groupIndex].mediaType ===
            'metahuman'
        ) {
          // [기존 메타휴먼 스크립트 수정] ----------------------
          console.log('스크립트수정 > 메타휴먼 시작');
          const targetMH =
            tmpSegments[blocks?.segmentsGroup][blocks?.groupIndex];
          const originDuration =
            tmpSegments[blocks.segmentsGroup][blocks.groupIndex]?.duration;

          console.log(
            '스크립트수정 > 메타휴먼 || targetMH : ' + JSON.stringify(targetMH)
          );
          console.log(
            '스크립트수정 > 메타휴먼 || 기존 duration : ' + originDuration
          );

          const getIndexKey = () => {
            if (targetMH.indexKey > 0) {
              return targetMH.indexKey;
            } else if (!targetMH.indexKey) {
              return Math.random().toString().slice(-6);
            }
          };

          // AiLab와 구글TTS에서 주는 값이 달라서 이에대한 예외처리.
          type ChangModeType = 'minus' | 'plus' | 'equal';
          const changMode: ChangModeType =
            originDuration > changeDuration
              ? 'minus'
              : originDuration < changeDuration
              ? 'plus'
              : 'equal';
          const durationDifference = Math.abs(changeDuration - originDuration);
          console.log('스크립트수정 > 메타휴먼 || 모드 : ' + changMode);
          console.log(
            '스크립트수정 > 메타휴먼 || 변경 duration : ' + changeDuration
          );
          console.log(
            '스크립트수정 > 메타휴먼 || 기존과 변경간의 차이 : ' +
              durationDifference
          );

          // 메타휴먼의 insertTime 조정
          tmpSegments?.segment?.map(item => {
            console.log('스크립트수정 > 메타휴먼 :' + JSON.stringify(item));
            // 선택한 메타휴먼보다 큰값의 insertTime을 가진 경우는 변경될 메타휴먼 조건에 해당
            // 선택한 메타휴먼 이후의 변경될 메타휴먼의 경우 조건에 해당함.
            if (
              item?.insertTime >
                tmpSegments[blocks.segmentsGroup][blocks.groupIndex]
                  ?.insertTime &&
              item.railIndex === 0
            ) {
              // 기존의 duration이 변경된 duration 보다 큰 경우
              if (changMode === 'minus') {
                // duration이 작아져서 그 만큼 insertTime을 줄인다.
                item.insertTime = item?.insertTime - durationDifference;
              } else if (changMode === 'plus') {
                // 기존의 duration이 변경된 duration 보다 작은 경우
                item.insertTime = item?.insertTime + durationDifference;
              }
              item.endTime = item.insertTime + item.duration;
            }
            return item;
          });

          // 백그라운드의 inserTime 조정
          tmpSegments?.background?.map(item => {
            console.log('스크립트수정 > 백그라운드 :' + JSON.stringify(item));
            // 선택한 메타휴먼보다 큰값의 insertTime을 가진 경우는 변경될 배경아이템 조건에 해당
            // 선택한 메타휴먼 이후의 변경될 배경아이템의 경우 조건에 해당함.
            if (
              item?.insertTime >
                tmpSegments[blocks.segmentsGroup][blocks.groupIndex]
                  ?.insertTime &&
              item.railIndex === 0
            ) {
              // 기존의 duration이 변경된 duration 보다 큰 경우
              if (changMode === 'minus') {
                // duration이 작아져서 그 만큼 insertTime을 줄인다.
                item.insertTime = item?.insertTime - durationDifference;
              } else if (changMode === 'plus') {
                // 기존의 duration이 변경된 duration 보다 작은 경우
                item.insertTime = item?.insertTime + durationDifference;
              }
              item.endTime = item.insertTime + item.duration;
            }
            return item;
          });
          console.log(
            '스크립트수정 > After Item(metahuman & background) 변경 :' +
              JSON.stringify(tmpSegments)
          );

          // temp segments에 메타휴먼을 변경된 값으로 지정.
          tmpSegments[blocks.segmentsGroup][blocks.groupIndex] = {
            ...tmpSegments[blocks.segmentsGroup][blocks.groupIndex],
            segmentId: id,
            mediaType: 'metahuman',
            contentId: popupInfo.poseId,
            wavFilePath: data.wavFilePath,
            currentLang: isKoreanScript ? 'ko' : applyLang,
            duration: changeDuration,
            endTime: targetMH.insertTime + changeDuration,
            title: popupInfo.scriptValue,
            source: popupInfo.preview,
            videoSize: '',
            rank: 0,
            indexKey: getIndexKey(),
            metaData: {
              modelId: popupInfo.modelId,
              styleId: popupInfo.styleValue,
              poseId: popupInfo.poseId,
              postId: popupInfo.postId,
              voiceId: String(popupInfo.voiceId),
              sync: data.sync,
              speed: tempo || 1.0,
              pptId:
                tmpSegments[blocks.segmentsGroup][blocks.groupIndex]?.metaData
                  ?.pptId,
            },
            fadeIn: 0,
            fadeOut: 0,
            width: changeMetahumam.width !== '' ? changeMetahumam.width : 100,
            height:
              changeMetahumam.height !== '' ? changeMetahumam.height : 100,
            posX: changeMetahumam.posX !== '' ? changeMetahumam.posX : 100,
            posY: changeMetahumam.posY !== '' ? changeMetahumam.posY : 100,
            chromakey: '',
            mute: 'N',
            volume: 1.0,
            transitionTime: 100,
            zIndex: 0,
            railIndex: 0,
          };

          // 메타휴먼 배경 수정 작업
          const backgroundIdx = tmpSegments.background?.findIndex(
            item => item?.indexKey === targetMH?.indexKeyd
          );
          console.log('찾은 backgound Index:' + backgroundIdx);
          console.log('찾은 backgound Index:' + backgroundIdx);
          if (backgroundIdx > -1) {
            tmpSegments.background[backgroundIdx] = {
              ...tmpSegments.background[backgroundIdx],
              duration: changeDuration,
              endTime: targetMH.insertTime + changeDuration,
            };
          }
          console.log(
            '스크립트수정 > Target Item(metahuman & background) 변경 :' +
              JSON.stringify(tmpSegments)
          );

          // PPT나 PDF로 동일하게 지정된 배경 수정 작업
          if (targetMH?.metaData?.pptId) {
            // alert('PPT로 생성된 스크립트는 변경하실 수 없습니다!');
            // loading({ isLoading: false, text: '', time: 0 });
            // return;
            if (
              confirm(
                t(
                  '메타휴먼 변경 시 하위의 ppt 및 pdf 배경 블록의 길이만 맞춰집니다.'
                )
              )
            ) {
              const idx = tmpPage[0]?.segments[0]?.background?.findIndex(
                item => item?.metaData?.pptId === targetMH?.metaData?.pptId
              );
              tmpPage[0].segments[0]['background'][idx] = {
                ...tmpPage[0].segments[0]['background'][idx],
                duration: changeDuration,
                endTime: targetMH.insertTime + changeDuration,
              };
              setPages(tmpPage);
              setChangeMH(false);
              resetPopupInfo();
              setShowModal(false);
              console.log(
                '스크립트수정 > Target PPT 변경 :' + JSON.stringify(tmpSegments)
              );

              //   loading({ isLoading: false, text: '', time: 0 });
              // } else {
              //   loading({ isLoading: false, text: '', time: 0 });
            }
          } else {
            setPages(tmpPage);
            setChangeMH(false);
            resetPopupInfo();
            setShowModal(false);
            // loading({ isLoading: false, text: '', time: 0 });
          }
        } else {
          // New 메타휴먼 생성
          const tSegment = {
            segmentId: id,
            mediaType: 'metahuman',
            contentId: popupInfo.poseId,
            fileId: '',
            fileName: '',
            filePath: '',

            wavFilePath: data.wavFilePath,
            currentLang: isKoreanScript ? 'ko' : applyLang,

            source: popupInfo.preview,
            title: popupInfo.scriptValue,
            videoSize: '',
            rank: 0,
            insertTime: getInsertTime(),
            indexKey: createUuid(),
            duration: changeDuration,
            endTime: getInsertTime() + changeDuration,
            startTime: 0,
            metaData: {
              modelId: popupInfo.modelId,
              styleId: popupInfo.styleValue,
              poseId: popupInfo.poseId,
              postId: popupInfo.postId,
              voiceId: String(popupInfo.voiceId),
              sync: data.sync,
              speed: tempo || 1.0,
            },
            fadeIn: 0,
            fadeOut: 0,
            width: defaultWidth,
            height: defaultHeight,
            posX: widthCenter,
            posY: 0,
            chromakey: '',
            mute: 'N',
            volume: 1.0,
            transitionType: '',
            transitionTime: 100,
            zIndex: 0,
            railIndex: 0,
          };

          if (pages.length) {
            // [기존 Pages][New 메타휴먼 스크립트 추가] ----------------------
            setSegmentsInfo(pages, setPages, {
              pagesIndex: 0,
              segment: {
                segmentArray: [...tmpPage[0]?.segments[0].segment, tSegment],
              },
            });
          } else {
            // [New Pages][New 메타휴먼 스크립트 추가] ----------------------
            const segmentArr = [tSegment];
            const segmentObj = {
              segment: segmentArr,
              title: [],
              background: [],
              image: [],
            };
            const segments: ISegments[] = [segmentObj];
            const page: IPages = { segments };
            const pages: IPages[] = [page];
            setPages(pages);
          }
          resetPopupInfo();
          setShowModal(false);
        }
        loading({ isLoading: false, text: '', time: 0 });
      })
      .catch(err => {
        loading({ isLoading: false, text: '', time: 0 });
      });
    setTranslatedLang('');
    setScriptChange(false);
  };

  // 메타휴먼 변경하기
  const [changeMetahumam, setChangeMetahumam] = useState<any>({
    explanation: false,
    width: '',
    height: '',
    posX: '',
    posY: '',
  });
  useEffect(() => {
    if (Object.keys(blocks).length !== 0 && pages) {
      const page = pages[0];
      if (
        page?.segments[0][blocks.segmentsGroup][blocks.groupIndex]
          ?.mediaType === 'metahuman'
      ) {
        console.log('MetahumanModal 수정 ---------------- ');

        setChangeMetahumam({
          ...changeMetahumam,
          width:
            page?.segments[0][blocks.segmentsGroup][blocks.groupIndex]?.width,
          height:
            page?.segments[0][blocks.segmentsGroup][blocks.groupIndex]?.height,
          posX: page?.segments[0][blocks.segmentsGroup][blocks.groupIndex]
            ?.posX,
          posY: page?.segments[0][blocks.segmentsGroup][blocks.groupIndex]
            ?.posY,
        });
      }
    }
  }, [blocks, showModal]);

  // 새 메타휴먼 추가 모달 표시
  const addNewMetahuman = () => {
    setBlocks({});
    setActive(0);
    setShowModal(true);

    const defaultMetahuman = projectInfo.defaultMetahuman;
    setHumanValue(defaultMetahuman);
  };

  // 메타휴먼 수정하기
  const editMetahuman = (e: any) => {
    const tmpPage = JSON.parse(JSON.stringify(pages));

    if (e.target.name === 'width') {
      tmpPage[0].segments[0].segment[blocks.groupIndex] = {
        ...tmpPage[0].segments[0].segment[blocks.groupIndex],
        [e.target.name]: e.target.value,
        height: e.target.value,
      };
      setPages(tmpPage);
      setChangeMetahumam({
        ...changeMetahumam,
        [e.target.name]: e.target.value,
        height: e.target.value,
      });
    } else if (e.target.name === 'height') {
      tmpPage[0].segments[0].segment[blocks.groupIndex] = {
        ...tmpPage[0].segments[0].segment[blocks.groupIndex],
        [e.target.name]: e.target.value,
        width: e.target.value,
      };
      setPages(tmpPage);
      setChangeMetahumam({
        ...changeMetahumam,
        [e.target.name]: e.target.value,
        width: e.target.value,
      });
    } else {
      tmpPage[0].segments[0].segment[blocks.groupIndex] = {
        ...tmpPage[0].segments[0].segment[blocks.groupIndex],
        [e.target.name]: e.target.value,
      };
      setPages(tmpPage);
      setChangeMetahumam({
        ...changeMetahumam,
        [e.target.name]: e.target.value,
      });
    }
    const segment = pages[0];
  };

  //빈 장면 추가
  useEffect(() => {
    if (metahuman) {
      addNewMetahuman();
      setMetahuman(false);
    }
  }, [metahuman]);

  /**
   * 메타휴먼 스크립트 수정
   */
  const changeScript = () => {
    setScriptChange(true);
    if (
      blocks &&
      pages[0]?.segments[0][blocks.segmentsGroup][blocks.groupIndex]
        .mediaType === 'metahuman'
    ) {
      if (
        pages[0]?.segments[0][blocks.segmentsGroup][blocks.groupIndex]?.metaData
          ?.integratedId
      ) {
        // PPT, PDF 같은 쌍으로 된 메타휴먼인지?
        const integratedId =
          pages[0]?.segments[0][blocks.segmentsGroup][blocks.groupIndex]
            ?.metaData?.integratedId;
        console.log('changeScript ---------------- 1');

        setChangeTalk(integratedId);
        const talkArray = pages[0]?.segments[0][blocks.segmentsGroup].filter(
          item => item?.metaData?.integratedId === integratedId
        );
        setActive(0);
        setTalkPopup(true);
        setHumanCount(talkArray.length);
      } else {
        // 단독 메타휴먼 인 경우

        console.log('changeScript ---------------- 2');
        const selectedMetahuman =
          pages[0]?.segments[0][blocks.segmentsGroup][blocks.groupIndex]
            .metaData;
        const selectedMetahumanScript =
          pages[0]?.segments[0][blocks.segmentsGroup][blocks.groupIndex].title;

        setHumanValue(selectedMetahuman, true, selectedMetahumanScript);
        setShowModal(true);
        setActive(0);
        setChangeMH(true);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(blocks).length !== 0) {
      if (
        pages[0]?.segments[0][blocks.segmentsGroup][blocks.groupIndex]
          ?.mediaType === 'metahuman'
      ) {
        const data =
          pages[0]?.segments[0][blocks.segmentsGroup][blocks.groupIndex];
        setChangeMetahumam({
          explanation: false,
          width: data.width,
          height: data.height,
          posX: data.posX,
          posY: data.posY,
        });
      }
    }
  }, [pages]);
  const [humanCount, setHumanCount] = useState(0);
  const [humanCountDropdown, setHumanCountDropdown] = useState(false);
  useEffect(() => {
    if (active === 1) {
      setTalkType('monologue');
      setHumanCount(0);
      setHumanCountDropdown(false);
    }
  }, [active]);

  useEffect(() => {
    if (!projectInfo) {
      return;
    }
    setProjectLanguage(projectInfo.projectLanguage);
  }, [projectInfo]);

  // for immediate page update
  useEffect(() => {}, [pages]);
  useEffect(() => {}, [targetLang]);

  const getScriptValue = (value: string) => {
    setScriptValue(value);
    setPopupInfo(prev => ({
      ...prev,
      scriptValue: value,
    }));
  };
  const getTargetLang = (value: string) => setTranslatedLang(value);

  const getSelectedValue = tabValue => {
    switch (tabValue) {
      case 'metahuman':
        return popupInfo.metaHumanName;
      case 'style':
        return popupInfo.styleName;
      case 'posture':
        return popupInfo.poseName;
      case 'voice':
        return popupInfo.voiceName;
      default:
        return '';
    }
  };

  const handleNext = () => {
    // 단독형모드 / 대화형모드
    const isMonoluge = talkType === 'monologue';
    isMonoluge ? setShowModal(true) : setTalkPopup(true);

    setActive(0);

    const defaultMetahuman = projectInfo.defaultMetahuman;
    // console.log('handleNext || defaultMetahuman:'+JSON.stringify(defaultMetahuman));
    setHumanValue(defaultMetahuman);
  };

  const setHumanValue = (human: any, isChanging?: boolean, script?: string) => {
    // console.log('setHumanValue || metahumanList :'+JSON.stringify(metahumanList));
    // console.log('setHumanValue || human :'+JSON.stringify(human));
    const settingHuman = metahumanList.find(item => item.ID === human?.modelId);
    console.log(
      'setHumanValue || settingHuman :' + JSON.stringify(settingHuman)
    );
    const { styleId, postId, poseId, voiceId } = projectInfo.metaData;
    const { ID, name, thumbnail, styles, poses, voices } = settingHuman;

    setPopupInfo((prev: any) => ({
      ...prev,
      scriptValue: isChanging ? script : '',
      modelId: isChanging ? human?.modelId : ID,
      metaHumanName: name,
      metaHumanImg: thumbnail,
      styleValue: isChanging ? human?.styleId : styleId,
      styleArray: styles,
      styleName: styles.find(
        item => item.styleId === (isChanging ? human?.styleId : styleId)
      )?.name,
      postId: isChanging ? human?.postId : postId,
      poseId: isChanging ? human?.poseId : poseId,
      poseArray: poses,
      poseName: poses.find(
        item => item.poseId === (isChanging ? human?.poseId : poseId)
      )?.name,
      voiceId: isChanging ? String(human?.voiceId) : String(voiceId),
      voiceName: voices.find(
        item => +item.voiceId === (isChanging ? +human?.voiceId : +voiceId)
      )?.name,
      voicesArray: voices,
      preview: poses.find(
        item =>
          item.styleId === (isChanging ? human?.styleId : styleId) &&
          item.poseId === (isChanging ? human?.poseId : poseId)
      ).preview,
      popupNum: 5,
    }));
  };

  const resetPopupInfo = () => setPopupInfo(INITIAL_STATE);

  return (
    <>
      <li>
        <button
          className={active == 1 ? 'active' : undefined}
          onClick={() => {
            setActive(1);
            setBlocks({});
          }}>
          <i className="style__metahuman__icon" />
          <span>{t('메타휴먼')}</span>
        </button>
        <div className="metahuman h-full max-h-[600px] overflow-y-auto overflow-x-hidden">
          <div className="title">
            <h2>{t('메타휴먼')}</h2>
            <button
              className="close__btn close__gray__icon"
              onClick={() => setActive(0)}
            />
          </div>
          {Object.keys(blocks).length === 0 &&
            (!metahumanList.length ? (
              <div className="h-[88%] flex items-center justify-center">
                <div className="w-20 h-20">
                  <Loader className="w-full h-full border-4 border-t-4" />
                </div>
              </div>
            ) : (
              <>
                <div className="metahuman-cont">
                  <h3>{t('대화 유형')}</h3>
                  <div className="button__list">
                    <button
                      className={
                        talkType === 'monologue'
                          ? 'active text-[16px]'
                          : 'text-[16px]'
                      }
                      onClick={() => {
                        setTalkType('monologue');
                      }}>
                      {t('독백형')}
                    </button>
                    <button
                      className={
                        talkType === 'conversation'
                          ? 'active text-[16px]'
                          : 'text-[16px]'
                      }
                      onClick={() => {
                        setTalkType('conversation');
                      }}>
                      {t('대화형')}
                    </button>
                  </div>
                  {talkType === 'conversation' && (
                    <div
                      className="translate-select-box"
                      style={{ cursor: 'pointer' }}>
                      <div
                        className="translate-select-button"
                        onClick={() => setHumanCountDropdown(prev => !prev)}>
                        <span>
                          {humanCount !== 0
                            ? humanCount + t('명')
                            : t('대화 인원 선택')}
                        </span>
                        <i
                          className="select__black__icon translate-arrow"
                          style={{
                            transform: `${
                              humanCountDropdown ? 'rotate(-180deg)' : ''
                            }`,
                          }}
                        />
                      </div>
                      {humanCountDropdown && (
                        <div className="translate-select-inner secondary-scroll">
                          <ul>
                            {metahumanList?.map((item: any, idx: number) => {
                              if (idx !== 0) {
                                return (
                                  <li
                                    key={`item-${idx}`}
                                    onClick={() => {
                                      setHumanCount(idx + 1);
                                      setHumanCountDropdown(
                                        !humanCountDropdown
                                      );
                                    }}>
                                    <span>
                                      {idx + 1}
                                      {t('명')}
                                    </span>
                                  </li>
                                );
                              }
                            })}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <button
                  disabled={talkType === 'conversation' && !humanCount}
                  className="nextBtn disabled:cursor-not-allowed disabled:bg-primary/50 duration-150 ease-out"
                  onClick={handleNext}>
                  {t('다음')}
                </button>
              </>
            ))}
          {Object.keys(blocks).length !== 0 &&
            pages[0]?.segments[0][blocks.segmentsGroup][blocks.groupIndex]
              ?.mediaType === 'metahuman' && (
              <div className="meta-edit-cont">
                <div className="edit-box border-20">
                  <h3>{t('크기')}</h3>
                  <div className="edit-input-box justify-center space-x-10">
                    <div className="edit-input-cont flex-col">
                      <span>{t('가로')}</span>
                      <input
                        name="width"
                        type="number"
                        value={parseFloat(changeMetahumam.width).toFixed(0)}
                        onChange={editMetahuman}
                      />
                    </div>
                    <div className="edit-input-cont flex-col">
                      <span>{t('세로')}</span>
                      <input
                        name="height"
                        type="number"
                        value={parseFloat(changeMetahumam.height).toFixed(0)}
                        onChange={editMetahuman}
                      />
                    </div>
                  </div>
                </div>
                <div className="edit-box">
                  <h3>{t('위치')}</h3>
                  <div className="edit-input-box justify-center space-x-10">
                    <div className="edit-input-cont flex-col">
                      <span>{t('상하')}</span>
                      <input
                        name="posY"
                        type="number"
                        value={changeMetahumam.posY}
                        onChange={editMetahuman}
                      />
                    </div>
                    <div className="edit-input-cont flex-col">
                      <span>{t('좌우')}</span>
                      <input
                        name="posX"
                        type="number"
                        value={changeMetahumam.posX}
                        onChange={editMetahuman}
                      />
                    </div>
                  </div>
                </div>
                <div className="change-cont">
                  <button
                    className="edit-change"
                    onClick={changeScript}
                    style={{ marginTop: '32px' }}>
                    {t('메타휴먼 및 스크립트 수정')}
                  </button>
                </div>
                <button
                  className="edit-new fixed top-[590px] right-32"
                  onClick={addNewMetahuman}>
                  {t('새 메타휴먼 추가')}
                </button>
              </div>
            )}
        </div>

        {chatGPTModal && (
          <ChatGPTModal
            show={chatGPTModal}
            close={() => setChatGPTModal(false)}
            setPopupInfo={setPopupInfo}
          />
        )}
        {translateModal && (
          <TranslateModals
            modelId={popupInfo.modelId}
            notSetLang={translatedLang}
            projectLang={projectInfo.projectLanguage}
            voiceId={String(popupInfo.voiceId)}
            selectedHumanLang={selectedHumanLang}
            getTargetLang={getTargetLang}
            getScriptValue={getScriptValue}
            scriptValue={popupInfo.scriptValue}
            closeModal={() => setTranslateModal(false)}
            postId={popupInfo.postId}
          />
        )}

        {showModal && (
          <section
            className={`meta-popupCont ${
              (translateModal || chatGPTModal) && 'hidden'
            }`}>
            <div className="popup-top">
              <span>{t('메타휴먼')}</span>
              <button
                className="close__btn close__gray__icon popupBtn"
                onClick={() => {
                  setShowModal(false);
                  setScriptValue('');
                  setTranslatedLang('');
                  setIsScriptPlaying(false);
                  setScriptChange(false);
                  setChangeMH(false);
                  resetPopupInfo();
                  if (!scriptAudio) {
                    return;
                  }
                  scriptAudio.pause();
                }}
              />
            </div>
            <div className="meta-popup-inner">
              <ul className="meta-ul">
                {metahumanModalStepArray.map(({ tabName, tabValue }, idx) => {
                  console.log('[metamodal][' + idx + '] tabName:' + tabName + ', tabValue:' + tabValue);
                  return (
                  <ListTab
                    onClick={() => handleAccordion(idx + 1)}
                    isDisabled={false}
                    label={tabName}
                    isActive={true}
                    idx={idx}
                    getSelectedValue={() => getSelectedValue(tabValue)}
                    metahumanImage={popupInfo.metaHumanImg}
                    tabValue={tabValue}
                    setPopupInfo={setPopupInfo}
                    popupInfo={popupInfo}
                    scriptValue={scriptValue}
                    setScriptValue={setScriptValue}
                    tempo={tempo}
                    setTempo={setTempo}
                    openGPTModal={() => setChatGPTModal(true)}
                    openTranslateModal={() => setTranslateModal(true)}
                    scriptChange={scriptChange}
                    translatedLang={translatedLang}
                    selectedHumanLang={selectedHumanLang}
                  />
                )})}
              </ul>
              <div className="meta-popup-btn">
                {translateLoading && (
                  <button className="meta-popup-complete active cursor-default bg-gray-500 z-50 rounded-lg text-white text-sm">
                    <span>{t('번역 중입니다...')}</span>
                  </button>
                )}

                <button
                  className={`${
                    !isCompleteDisabled && 'active'
                  } disabled:cursor-not-allowed meta-popup-complete`}
                  onClick={applyToMetahuman}
                  disabled={isCompleteDisabled}>
                  {t('완료')}
                </button>
              </div>
            </div>
            {/* 메타휴먼 선택 중 자물쇠 버튼 클릭시 팝업 */}
            {/* active */}
            {popupInfo.lockPopup && (
              <div className="lock-showModal active">
                <button
                  className="close__btn close__gray__icon lockBtn"
                  onClick={() => {
                    setPopupInfo({ ...popupInfo, lockPopup: false });
                  }}
                />
                <span>{t('선택하신 메타휴먼은 유료 메타휴먼입니다.')}</span>
                <p>{t('구매를 위해 스토어로 이동하시겠습니까?')}</p>
                <button className="storeGo-btn">{t('스토어 바로가기')}</button>
              </div>
            )}
            {/* 메타휴먼 선택 중 자물쇠 버튼 클릭시 팝업 */}
          </section>
        )}
        {talkPopup && (
          <MetahumanTalkModal
            setTalkPopup={setTalkPopup}
            setActive={setActive}
            humanCount={humanCount}
            popupInfo={popupInfo}
            metahumanListArray={metahumanList}
            createTalkScript={createTalkScript}
            projectId={projectId}
            widthCenter={widthCenter}
            setHumanCount={setHumanCount}
            defaultWidth={defaultWidth}
            defaultHeight={defaultHeight}
            setChangeTalk={setChangeTalk}
            changeTalk={changeTalk}
            talkHumanNum={humanCount}
          />
        )}
      </li>
    </>
  );
};
export default MetahumanModal;
