import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { metahumanModalStepArray } from '../../../arrays';
import useLanguage from '../../../hooks/use-lang';
import { doGetMetahumanList } from '../../../networks/source.service';
import { MapType } from '../../../store/block/types';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import {
  MetahumanListType,
  MetahumanTabType,
} from '../../../types_new/main/resource/types';
import CloseIcon from '../../svg/console/close-icon';
import MetahumanGrid from '../home/metahuman-grid';
import PostureGrid from '../home/posture-grid';
import SelectedBadge from '../home/selected-badge';
import StyleGrid from '../home/style-grid';
import VoiceGrid from '../home/voice-grid';

type Props = {
  closeModal: () => void;
  projectInfo: NewProjectInfoType;
  setProjectInfo: Dispatch<SetStateAction<NewProjectInfoType>>;
  setStep: Dispatch<SetStateAction<number>>;
};

export default function StepTwo({
  closeModal,
  projectInfo,
  setProjectInfo,
  setStep,
}: Props) {
  const { t } = useTranslation();
  const stepArray = metahumanModalStepArray.slice(0, -1);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState<MetahumanTabType>('metahuman');
  const [metahumanList, setMetahumanList] = useState<MetahumanListType[]>([]);
  const lang = useLanguage();
  const isDisabled =
    !projectInfo.defaultMetahuman.modelId ||
    !projectInfo.defaultMetahuman.poseId ||
    !projectInfo.defaultMetahuman.styleId ||
    !String(projectInfo.defaultMetahuman.voiceId);

  useEffect(() => {
    setLoading(true);
    doGetMetahumanList({ lang })
      .then(setMetahumanList)
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [lang]);

  const gridMap: MapType = {
    metahuman: (
      <MetahumanGrid
        loading={loading}
        setTab={setTab}
        projectInfo={projectInfo}
        setProjectInfo={setProjectInfo}
        metahumanList={metahumanList}
      />
    ),
    style: (
      <StyleGrid
        setTab={setTab}
        projectInfo={projectInfo}
        setProjectInfo={setProjectInfo}
        metahumanList={metahumanList}
      />
    ),
    posture: (
      <PostureGrid
        setTab={setTab}
        projectInfo={projectInfo}
        setProjectInfo={setProjectInfo}
        metahumanList={metahumanList}
      />
    ),
    voice: (
      <VoiceGrid
        projectInfo={projectInfo}
        setProjectInfo={setProjectInfo}
        metahumanList={metahumanList}
      />
    ),
  };

  return (
    <section className="relative">
      <button className="absolute right-[22px] top-5" onClick={closeModal}>
        <CloseIcon />
      </button>
      <h3 className="p-5 2xl:p-6 text-consoleDark font-semibold">메타휴먼</h3>
      {stepArray.map(({ tabName, tabValue }, idx) => {
        const isOpen = tabValue === tab;
        console.log('[two][' + idx + '] tabValue:' + tabValue);
        return (
          <div
            key={tabValue}
            onClick={() => setTab(tabValue)}
            className={`pl-5 2xl:pl-6 shadow-top-shadow ${
              !isOpen && 'hover:bg-gray-50 cursor-pointer'
            } duration-150`}>
            <div className="flex space-x-2.5 items-center text-sm py-5 2xl:py-6">
              <span className="text-primary">STEP{idx + 1}</span>
              <span className="text-[#222] font-semibold">{t(tabName)}</span>
              <SelectedBadge
                metahumanList={metahumanList}
                tab={tabValue}
                projectInfo={projectInfo}
              />
            </div>
            {isOpen && gridMap[tabValue]}
          </div>
        );
      })}

      <div className="flex justify-end space-x-3 text-sm font-medium py-3 px-6">
        <button
          onClick={() => setStep(1)}
          className="rounded-md bg-consoleGray text-consoleDark w-24 h-10 focus:outline-none hover:bg-gray-200 duration-100">
          {t('이전')}
        </button>
        <button
          onClick={() => setStep(3)}
          disabled={isDisabled}
          className={`rounded-md w-24 h-10 text-[#ececec] bg-primary ${
            isDisabled && 'bg-opacity-50 cursor-not-allowed'
          } duration-100`}>
          {t('다음')}
        </button>
      </div>
    </section>
  );
}
