import usePath from '../../hooks/use-path';
import DoubleChevron from '../svg/double-chevron';
import WhiteChevron from '../svg/white-chevron';

type Props = {
  isSmall?: boolean;
  itemsPerPage: number;
  currentPage: number;
  totalItems: number;
  onPageChange: (page: number) => void;
  parentPage: number;
};

export default function Pagination({
  isSmall,
  itemsPerPage,
  currentPage,
  totalItems,
  onPageChange,
  parentPage,
}: Props) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pagesToShow = 5;
  const startPage = Math.max(1, parentPage - Math.floor(pagesToShow / 2));
  const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
  const { isWhiteTheme } = usePath();
  const BUTTON_STYLE = `${
    isSmall ? 'w-7 h-7' : 'w-8 h-8 xl:w-9 xl:h-9'
  } flex items-center justify-center rounded-[10px] duration-75 ease-out hover:bg-primary/70`;

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  const renderPageButtons = () => {
    const buttons = [];
    for (let page = startPage; page <= endPage; page++) {
      buttons.push(
        <button
          className={`${
            isSmall
              ? 'w-7 h-7 rounded-lg text-sm'
              : 'w-8 h-8 xl:w-9 xl:h-9 rounded-[10px]'
          } group duration-75 ease-out ${
            isWhiteTheme && 'text-darkSecondary'
          } ${
            page === currentPage
              ? 'bg-primary text-white'
              : 'hover:bg-primary/70'
          }`}
          key={page}
          onClick={() => handlePageChange(page)}
          disabled={page === currentPage}>
          {page}
        </button>
      );
    }
    return buttons;
  };

  if (!totalItems) return;

  return (
    <div
      className={`flex w-full items-center  justify-center space-x-1 pb-5  ${
        isSmall ? 'mt-3' : 'mt-10 md:mt-12 lg:mt-14 xl:mt-[60px]'
      }`}>
      {parentPage !== 1 && (
        <>
          {totalPages > pagesToShow && (
            <button
              className={BUTTON_STYLE}
              onClick={() => handlePageChange(1)}
              disabled={parentPage === 1}>
              <DoubleChevron isWhiteTheme={isWhiteTheme} isLeft />
            </button>
          )}
          <button
            className={BUTTON_STYLE}
            onClick={() =>
              handlePageChange(Math.max(1, parentPage - pagesToShow))
            }
            disabled={parentPage === 1}>
            <WhiteChevron isWhiteTheme={isWhiteTheme} isLeft />
          </button>
        </>
      )}
      {renderPageButtons()}
      {parentPage !== totalPages && (
        <>
          <button
            className={BUTTON_STYLE}
            onClick={() =>
              handlePageChange(Math.min(totalPages, parentPage + pagesToShow))
            }>
            <WhiteChevron isWhiteTheme={isWhiteTheme} />
          </button>
          {totalPages > pagesToShow && (
            <button
              className={BUTTON_STYLE}
              onClick={() => handlePageChange(totalPages)}>
              <DoubleChevron isWhiteTheme={isWhiteTheme} />
            </button>
          )}
        </>
      )}
    </div>
  );
}
