import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { lang } from '../../../recoil/atoms/user';
import { MapType } from '../../../store/block/types';
import { NewProjectInfoType } from '../../../types_new/console/service/types';
import InformModal from '../../modals/inform-modal';
import StepOne from './step-one';
import StepThree from './step-three';
import StepTwo from './step-two';

export const INITIAL_PROJECT_STATE = {
  projectName: '',
  content: '',
  template: '16:9',
  defaultMetahuman: {
    postId: 0,
    modelId: '',
    styleId: '',
    poseId: '',
    voiceId: '',
  },
  projectLanguage: 'ko',
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

export default function ProjectModal({ isOpen, closeModal }: Props) {
  const language = useRecoilValue(lang);
  const [projectInfo, setProjectInfo] = useState<NewProjectInfoType>(
    INITIAL_PROJECT_STATE
  );
  const [step, setStep] = useState(1);
  const [informModal, setInformModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setProjectInfo(prev => ({
      ...prev,
      projectLanguage: language,
    }));
  }, [language]);

  const handleClose = () => {
    closeModal();
    setTimeout(() => {
      setStep(1);
      setProjectInfo(INITIAL_PROJECT_STATE);
    }, 300);
  };

  const panelMap: MapType = {
    1: (
      <StepOne
        setStep={setStep}
        closeModal={handleClose}
        projectInfo={projectInfo}
        setProjectInfo={setProjectInfo}
      />
    ),
    2: (
      <StepTwo
        setStep={setStep}
        closeModal={closeModal}
        projectInfo={projectInfo}
        setProjectInfo={setProjectInfo}
      />
    ),
    3: (
      <StepThree
        setInformModal={setInformModal}
        closeModal={closeModal}
        setStep={setStep}
        projectInfo={projectInfo}
        setProjectInfo={setProjectInfo}
      />
    ),
  };

  return (
    <>
      <InformModal
        isOpen={informModal}
        title={t('생성 실패')}
        message={t('프로젝트 생성에 실패하였습니다.')}
        closeModal={() => setInformModal(false)}
      />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/50" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel
                  style={{
                    maxWidth: `${step === 2 ? '728px' : '448px'}`,
                    padding: `${step === 2 ? '' : '24px'}`,
                  }}
                  className="w-full flex flex-col space-y-5 rounded-2xl bg-white text-left shadow-xl duration-100">
                  {panelMap[step]}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
