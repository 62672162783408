import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TemplateDataType } from '../../../types_new/main/resource/types';
import VideoModal from '../../VideoModal';

type Props = {
  myTemplateData: TemplateDataType[];
  loading: boolean;
};

const tableHeadList = ['템플릿명', '썸네일', '미리보기', '다운로드 일시'];

const displayStatus = (tranType: TemplateDataType['tranType']) => {
  switch (tranType) {
    case 'buy':
      return '구매';
    default:
      return '';
  }
};

const TH_CLASS = 'font-normal py-[13px] w-[12%] text-center';
const TD_CLASS = 'text-[#1A2032] w-[12%] text-center py-6';
const SKELETON_TD_CLASS = 'h-2 rounded-full bg-gray-400 mx-auto animate-pulse';

export default function TemplatesTable({ loading, myTemplateData }: Props) {
  const { t } = useTranslation();
  const [previewModal, setPreviewModal] = useState({
    isOpen: false,
    videoURL: '',
  });

  const openPreviewModal = (url: string) => {
    setPreviewModal({
      isOpen: true,
      videoURL: url,
    });
  };

  return (
    <>
      <VideoModal
        isOpen={previewModal.isOpen}
        videoURL={previewModal.videoURL}
        closeModal={() =>
          setPreviewModal(prev => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <table className="overflow-x-hidden mt-4 w-full">
        <thead className="bg-[#F1F4F8] text-sm text-[#363D59] rounded-md">
          <tr className="w-full">
            {tableHeadList.map((tableHead, idx) => (
              <th key={`tableHead-${idx}`} className={`${TH_CLASS} `}>
                {t(tableHead)}
              </th>
            ))}
          </tr>
        </thead>
        {loading ? (
          <Skeleton />
        ) : (
          <tbody>
            {myTemplateData.map(templates => {
              const { postTitle, registerDate, metaData, tranType, template } =
                templates;

              return (
                <tr
                  key={postTitle}
                  className="border-b border-[#D4DEEA] font-medium ">
                  <td className={TD_CLASS}>{template.templateNm}</td>
                  <td className={TD_CLASS}>
                    <div
                      className="w-20 h-20 bg-cover bg-center mx-auto rounded-md"
                      style={{ backgroundImage: `url(${template.thumbnail})` }}
                    />
                  </td>
                  <td className={TD_CLASS}>
                    <button
                      className="bg-primary text-white text-sm rounded-md py-1 px-2"
                      onClick={() =>
                        openPreviewModal(`${template.downloadLink}`)
                      }>
                      <span className="mt-0.5 block">미리보기</span>
                    </button>
                  </td>
                  <td className={TD_CLASS}>
                    {moment(registerDate).format('YYYY.MM.DD HH:mm:ss')}
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </>
  );
}

function Skeleton() {
  const array = [1, 2, 3, 4, 5];

  return (
    <tbody className="text-center">
      {array.map(item => (
        <tr
          key={`template-publish-skeleton-${item}`}
          className="border-b border-[#D4DEEA] font-medium">
          <td className="py-6">
            <div className={`w-16 ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="py-6">
            <div className="w-20 h-20 flex items-center justify-center bg-gray-400 rounded-md mx-auto animate-pulse">
              <svg
                className="w-10 h-10 text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20">
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
              </svg>
            </div>
          </td>
          <td className="py-6">
            <div className={`w-14 ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="py-6">
            <div className={`w-14 ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="text-sm py-6 font-semibold">
            <div className={`w-18  ${SKELETON_TD_CLASS}`} />
          </td>
          <td className="text-sm py-6 font-semibold">
            <div className={`w-8  ${SKELETON_TD_CLASS}`} />
          </td>
        </tr>
      ))}
    </tbody>
  );
}
