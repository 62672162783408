export default function MusicIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M11.25 24.375C11.25 26.4469 9.15 28.125 6.5625 28.125C3.975 28.125 1.875 26.4469 1.875 24.375C1.875 22.305 3.975 20.625 6.5625 20.625C9.15 20.625 11.25 22.305 11.25 24.375ZM28.125 20.625C28.125 22.6969 26.025 24.375 23.4375 24.375C20.85 24.375 18.75 22.6969 18.75 20.625C18.75 18.5531 20.85 16.875 23.4375 16.875C26.025 16.875 28.125 18.5531 28.125 20.625Z"
        fill="#8B9AEB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.25 20.625V3.75H28.125V20.625H26.25ZM11.25 5.625V24.375H9.375V5.625H11.25Z"
        fill="#8B9AEB"
      />
      <path
        d="M9.375 5.4468C9.37499 4.98198 9.54764 4.53372 9.85946 4.189C10.1713 3.84427 10.6 3.62766 11.0625 3.58118L26.0625 2.08118C26.3232 2.05497 26.5866 2.08371 26.8355 2.16554C27.0844 2.24736 27.3135 2.38046 27.5078 2.55625C27.7021 2.73203 27.8575 2.9466 27.9638 3.18611C28.0701 3.42562 28.125 3.68476 28.125 3.9468V5.62493L9.375 7.49993V5.4468Z"
        fill="#8B9AEB"
      />
    </svg>
  );
}
