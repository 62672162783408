import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { etcTypeArray, mediaTypeArray } from '../../../arrays';
import { addResource } from '../../../networks/source.service';
import { useModalAtom } from '../../../recoil/atoms/store';
import { GetResourcesReqType } from '../../../types_new/main/service/types';
import { MediaParamType } from '../../../types_new/project/types';
import { findExtension } from '../../../utils/common';
import { UseModalType } from '../../modals/modal-provider';
import SourceSelectLisbBox from './source-select-list-box';

type Props = {
  loading: boolean;
  setType: Dispatch<SetStateAction<GetResourcesReqType['type']>>;
  type: GetResourcesReqType['type'];
  getResourceList: () => void;
  setParams: Dispatch<SetStateAction<MediaParamType>>;
  params: MediaParamType;
};

export default function MediaFilter({
  loading,
  type,
  setType,
  getResourceList,
  setParams,
  params,
}: Props) {
  const { group } = useParams();
  const setUseModal = useSetRecoilState<UseModalType>(useModalAtom);
  const typeArray = group === 'etc' ? etcTypeArray : mediaTypeArray;
  const { t } = useTranslation();

  const uploadFile = ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
    if (!files) return;
    setUseModal({ type: 'loader', isOpen: true });

    const fileType = findExtension(files[0].name);
    const formData = new FormData();
    formData.append('resource[]', files[0]);
    formData.append('group', group as string | Blob);
    formData.append('type', fileType);

    addResource(formData)
      .then(() => getResourceList())
      .catch(console.log)
      .finally(() => setUseModal({ type: 'loader', isOpen: false }));
  };

  return (
    <div className="w-full flex items-center justify-between mt-5 pb-1 border-b border-grayBorder2">
      <ul className="flex space-x-5 2xl:space-x-8">
        {typeArray.map(({ label, value }, idx) => {
          const isActive = value === type;
          return (
            <li
              className={`max-2xl:text-sm ${
                isActive ? 'font-semibold' : 'hover:text-gray-500'
              } duration-150 ease-out`}
              key={`mediaType-${idx}`}>
              <button onClick={() => setType(value)}>{t(label)}</button>
            </li>
          );
        })}
      </ul>
      <div className="flex items-center space-x-3">
        <label
          htmlFor="file-upload"
          className="flex items-center space-x-2 py-1.5 px-2 text-sm text-[#666] cursor-pointer rounded-md hover:bg-primary/10 duration-150 ease-out">
          <PlusIcon />
          <span>{t('파일 업로드')}</span>
          <input
            onChange={uploadFile}
            id="file-upload"
            type="file"
            className="hidden"
          />
        </label>
        {group !== 'etc' && (
          <>
            <div className="w-px h-[11px] bg-grayBorder2" />
            <SourceSelectLisbBox
              loading={loading}
              params={params}
              setParams={setParams}
            />
          </>
        )}
      </div>
    </div>
  );
}

function PlusIcon() {
  return (
    <svg
      className="w-[14px] h-[14px] -translate-y-0.5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none">
      <path
        d="M7.00065 11.6667V7.00004M7.00065 7.00004V2.33337M7.00065 7.00004H11.6673M7.00065 7.00004H2.33398"
        stroke="#666666"
        strokeLinecap="round"
      />
    </svg>
  );
}
