export default function PlayPreview() {
  return (
    <>
      <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle cx="19" cy="19" r="19" fill="#D9D9D9" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5966 12.0987C15.9613 11.9322 16.3942 11.979 16.7102 12.2191L24.6049 18.2191C24.8546 18.4089 25 18.6962 25 19C25 19.3038 24.8546 19.5911 24.6049 19.7809L16.7102 25.7809C16.3942 26.021 15.9613 26.0678 15.5966 25.9013C15.2319 25.7348 15 25.3844 15 25V13C15 12.6156 15.2319 12.2652 15.5966 12.0987ZM17.1053 15.0806V22.9194L22.2623 19L17.1053 15.0806Z"
          fill="#7B61FF"
        />
      </svg>
    </>
  );
}
