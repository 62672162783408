import React from 'react';

type Props = {
  children: React.ReactNode;
  isWhiteTheme?: boolean;
};

export default function GradientBadge({ children, isWhiteTheme }: Props) {
  return (
    <div className="bg-gradient-to-r from-from to-to p-px rounded-full w-fit">
      <div
        className={`${
          isWhiteTheme
            ? 'bg-lightPrimary text-darkSecondary'
            : 'bg-darkSecondary'
        } rounded-full py-2 md:py-2.5 2xl:py-3 px-3 md:px-4 2xl:px-[18px] text-xs lg:text-sm font-bold`}
      >
        {children}
      </div>
    </div>
  );
}
