/* eslint-disable */
import { useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import {
  centiSecond,
  markerPos,
  pages as storedPages,
  segmentsItem as storedSegmentItem,
  timelinePercentage,
} from '../../../store/pages';
import { IPages, IPreviewElement } from '../../../store/pages/types';
import { changeHost } from '../../../utils/common';
type props = {
  item: IPreviewElement;
  setChangeSoundeRange: any;
  handleVolume: any;
  handleFade: any;
};

export function VideoItem({
  item,
  setChangeSoundeRange,
  handleVolume,
  handleFade,
}: props) {
  const [pages] = useRecoilState<IPages[]>(storedPages);
  const [segmentsItems] = useRecoilState(storedSegmentItem);
  const [active, setActive] = useState<number>(0);
  const [markerX, setMarkerX] = useRecoilState(markerPos);
  const [percentage, setPercentage] = useRecoilState(timelinePercentage);
  const [_, setCs] = useRecoilState(centiSecond);
  const video = pages[0].segments[0].segment.find(
    seg => seg.segmentId === item.id
  );

  const timelinePercent = useMemo(() => {
    for (let i = 0; i < 101; i++) {
      switch (percentage) {
        case i:
          return 6.3 - i * 0.06;
      }
    }
  }, [percentage]);

  return (
    <ul className="content__line">
      <li>
        <div
          className={
            segmentsItems.id === item.id
              ? 'video__box fx active'
              : 'video__box fx'
          }
          style={{
            width: '100%',
            backgroundImage: `url(${changeHost(video?.previewImage)})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'center',
          }}
          onClick={() => {
            setMarkerX(video.insertTime / timelinePercent);
            setCs(video.insertTime);
            setActive(item.id);
          }}>
          {video?.fadeIn > 0 && <div className="fadeIn" />}
          <i className="timeline__video__icon" />
          <div
            className="fx__box"
            onMouseOver={() => {
              setChangeSoundeRange(true);
            }}
            onMouseLeave={() => {
              setChangeSoundeRange(false);
            }}>
            <i className="fx__icon" />
            <div className="fx__content">
              <div className="col">
                <input
                  type="checkbox"
                  name="fx_checkbox"
                  value="fadeIn"
                  id="fadeInCheckbox__video__1"
                  checked={item?.fadeIn}
                  onClick={e => handleFade(item, e)}
                />
                <label htmlFor="fadeInCheckbox__video__1">
                  <p>Fade In</p>
                  <span className="checkbox" />
                </label>
              </div>
              <div className="col">
                <input
                  type="checkbox"
                  name="fx_checkbox"
                  value="fadeOut"
                  id="fadeOutCheckbox__video__1"
                  checked={item?.fadeOut}
                  onClick={e => handleFade(item, e)}
                />
                <label htmlFor="fadeOutCheckbox__video__1">
                  <p>Fade Out</p>
                  <span className="checkbox" />
                </label>
              </div>
              <div className="col">
                <i className="sound__black__icon" />
                <input
                  type="range"
                  className="sound__range"
                  style={{
                    background: `linear-gradient( to right, #7b61ff 0%, #7b61ff ${
                      item.volume * 50
                    }%, #e9e9e9 ${item.volume}%, #e9e9e9 100% )`,
                  }}
                  value={item.volume * 50}
                  onChange={e => handleVolume(item, e.target.value)}
                />
              </div>
            </div>
          </div>
          {video?.fadeOut > 0 && <div className="fadeOut" />}
        </div>
      </li>
    </ul>
  );
}
