export default function AudioPlayIcon() {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54508 5.91904L1.38706 9.94611C0.779587 10.2875 0 9.87412 0 9.18585V1.13171C0 0.444534 0.778462 0.0300467 1.38706 0.372544L8.54508 4.39962C8.68327 4.4761 8.79813 4.58667 8.87802 4.72009C8.95792 4.85351 9 5.00505 9 5.15933C9 5.31361 8.95792 5.46514 8.87802 5.59857C8.79813 5.73199 8.68327 5.84255 8.54508 5.91904V5.91904Z"
        fill="#7B61FF"
      />
    </svg>
  );
}
