export default function PrimaryExclamationIcon() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 2.125C8.78555 2.125 2.125 8.78555 2.125 17C2.125 25.2145 8.78555 31.875 17 31.875C25.2145 31.875 31.875 25.2145 31.875 17C31.875 8.78555 25.2145 2.125 17 2.125ZM17 29.3516C10.1801 29.3516 4.64844 23.8199 4.64844 17C4.64844 10.1801 10.1801 4.64844 17 4.64844C23.8199 4.64844 29.3516 10.1801 29.3516 17C29.3516 23.8199 23.8199 29.3516 17 29.3516Z"
        fill="#7B61FF"
      />
      <path
        d="M15.4062 22.8438C15.4063 23.2664 15.5742 23.6718 15.873 23.9707C16.1719 24.2696 16.5773 24.4375 17 24.4375C17.4227 24.4375 17.8281 24.2696 18.127 23.9707C18.4258 23.6718 18.5938 23.2664 18.5938 22.8438C18.5938 22.4211 18.4258 22.0157 18.127 21.7168C17.8281 21.4179 17.4227 21.25 17 21.25C16.5773 21.25 16.1719 21.4179 15.873 21.7168C15.5742 22.0157 15.4063 22.4211 15.4062 22.8438ZM16.2031 19.125H17.7969C17.943 19.125 18.0625 19.0055 18.0625 18.8594V9.82812C18.0625 9.68203 17.943 9.5625 17.7969 9.5625H16.2031C16.057 9.5625 15.9375 9.68203 15.9375 9.82812V18.8594C15.9375 19.0055 16.057 19.125 16.2031 19.125Z"
        fill="#7B61FF"
      />
    </svg>
  );
}
