import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Loader from '../common/loader';
import PrimaryExclamationIcon from '../svg/primary-exclamation-icon';

type Props = {
  isOpen: boolean;
  title: string;
  message: string;
  closeModal: () => void;
  handleOnClick: () => void;
  loading?: boolean;
};

export default function ConfirmModal({
  isOpen,
  title,
  message,
  closeModal,
  handleOnClick,
  loading,
}: Props) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-sm xl:max-w-md flex flex-col items-center p-6 overflow-hidden rounded-2xl bg-white shadow-xl transition-all">
                <PrimaryExclamationIcon />
                <Dialog.Title
                  as="h3"
                  className="mt-4 mb-8 2xl:mt-5 text-consoleDark font-medium">
                  <span>{title}</span>
                  {message && <p className="mt-2">{message}</p>}
                </Dialog.Title>

                <div className="w-full flex space-x-2 sm:space-x-3 text-sm 2xl:text-base font-medium">
                  <button
                    className={`focus:outline-none ring-offset-0 w-1/3 py-3 bg-[#EEEEF0] text-[#5b5b5b] rounded-md hover:bg-opacity-90 duration-100 ${
                      loading && 'cursor-default'
                    }`}
                    disabled={loading}
                    onClick={closeModal}>
                    취소
                  </button>
                  <button
                    onClick={handleOnClick}
                    disabled={loading}
                    className={`focus:outline-none ring-offset-0 w-2/3 py-3 rounded-md flex justify-center ${
                      loading
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                        : 'bg-primary text-white hover:bg-opacity-90'
                    } duration-100`}>
                    {loading ? <Loader /> : '확인'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
