import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import Loader from '../../components/common/loader';
import InformModal from '../../components/modals/inform-modal';
import CheckedBox from '../../components/svg/checked-box';
import UncheckedBox from '../../components/svg/unchecked-box';
import { IMemberLoginData } from '../../networks/dto/IMemberDto';
import { doSignIn } from '../../networks/member.service';
import { paySignIn } from '../../networks/paysystem.service';
import {
  isSignedAtom,
  loginInfoAtom,
  removeStorageToken,
} from '../../networks/store/member';
import {
  IPaySystemMemberInfo,
  paySystemMemberAtom,
} from '../../networks/store/paysystem';

const LABEL_CLASS =
  'absolute text-xs lg:text-sm text-grayText duration-200 transform -translate-y-2 scale-75 top-3 z-10 origin-[0] left-5 peer-focus:left-5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0.5 peer-focus:scale-75 peer-focus:-translate-y-2';
const INPUT_CLASS =
  'h-[44px] lg:h-[50px] pl-5 block py-2.5 pt-6 px-0 w-full text-xs lg:text-sm text-black bg-white rounded-md border border-grayBorder appearance-none focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer text-[#666666]';

export default function LoginPage() {
  const { t } = useTranslation();
  const [remembered, setRemembered] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    userLogin: '',
    userPw: '',
  });
  const [failModal, setFailModal] = useState({
    isOpen: false,
    message: '',
  });

  const setMemberInfo = useSetRecoilState<IMemberLoginData>(loginInfoAtom);
  const setIsLoggedIn = useSetRecoilState(isSignedAtom);
  const setPaySystemMember = useSetRecoilState(paySystemMemberAtom);
  const navigate = useNavigate();

  const requestLogin = async () => {
    if (remembered) {
      localStorage.setItem('rememberedId', loginInfo.userLogin);
    } else {
      localStorage.removeItem('rememberedId');
    }

    setLoading(true);

    doSignIn(loginInfo)
      .then(res => {
        const body = res?.data?.body;
        const message = res?.data?.body?.message;

        if (res.data.code === '200') {
          // 결제시스템 로그인 처리.
          paySignIn(loginInfo.userLogin)
            .then(res => {
              if (res.status === 200 && res.data.result === 1) {
                const payInfo: IPaySystemMemberInfo = {
                  paySystemCompanySeq: res.data.companySeq
                    ? res.data.companySeq?.toString()
                    : '',
                  paySystemMemberId: res.data.memberId
                    ? res.data.memberId?.toString()
                    : '',
                  paySystemExpiredDate: res.data.tokenExpiredDate
                    ? res.data.tokenExpiredDate?.toString()
                    : '',
                  paySystemToken: res.data.authToken ? res.data.authToken : '',
                  paySystemMemberSeq: res.data.memberSeq
                    ? res.data.memberSeq?.toString()
                    : '',
                };
                setPaySystemMember(payInfo);

                setMemberInfo(body);
                setIsLoggedIn(true);
                handleSuccess();
              } else {
                console.log('[login-page] paysystme sigin error');
                removeStorageToken();
                setIsLoggedIn(false);
                handleFail('결제시스템 접근 에러 발생');
              }
            })
            .catch(err => {
              console.error('login error:' + err);
              handleFail('결제시스템 접근 에러 발생');
            });
        } else {
          removeStorageToken();
          setIsLoggedIn(false);
          handleFail(message);
        }
      })
      .catch(handleFail)
      .finally(() => setLoading(false));

    function handleSuccess() {
      navigate('/');
    }

    function handleFail(message?: string) {
      setFailModal({
        isOpen: true,
        message: message || '잠시 후 다시 시도해주세요.',
      });
    }
  };

  const isSatisfied = () => {
    const { userLogin, userPw } = loginInfo;
    return userLogin.length && userPw.length;
  };

  const handleOnChange = ({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement>) => {
    setLoginInfo(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const rememberedId = localStorage.getItem('rememberedId');
    if (!rememberedId) return;

    setLoginInfo(prev => ({
      ...prev,
      userLogin: rememberedId,
    }));
    setRemembered(true);
  }, []);

  const handleEnter = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    if (key === 'Enter') requestLogin();
  };

  return (
    <>
      <InformModal
        isOpen={failModal.isOpen}
        title="로그인 오류"
        message={failModal.message}
        closeModal={() => setFailModal(prev => ({ ...prev, isOpen: false }))}
      />
      <div className="w-full h-full flex items-center justify-center">
        <div className="sm:max-w-lg max-sm:h-full w-full py-12 px-5 sm:px-8 rounded-lg bg-black/50 backdrop-blur-sm flex flex-col items-center justify-center">
          <h1 className="text-xl xl:text-2xl text-center">
            {t('플루닛 스튜디오에서1')}
            <br />
            {t('나만의 메타휴먼과 영상을')}
            <br />
            {t('제작해보세요!')}
          </h1>
          <div className="relative z-0 w-full group mt-5 lg:mt-6">
            <input
              value={loginInfo.userLogin}
              onKeyDown={handleEnter}
              maxLength={60}
              autoComplete="off"
              spellCheck={false}
              type="email"
              name="userLogin"
              id="userLogin"
              onChange={handleOnChange}
              className={`${INPUT_CLASS} peer`}
              placeholder=" "
            />
            <label
              htmlFor="userLogin"
              className={`${LABEL_CLASS} peer-focus:text-grayText`}>
              {t('이메일')}
            </label>
          </div>
          <div className="relative z-0 w-full group mt-2.5">
            <input
              onKeyDown={handleEnter}
              autoComplete="off"
              type="password"
              name="userPw"
              id="userPw"
              maxLength={16}
              onChange={handleOnChange}
              className={INPUT_CLASS}
              placeholder=" "
            />
            <label
              htmlFor="userPw"
              className={`${LABEL_CLASS} peer-focus:text-grayText`}>
              {t('비밀번호')}
            </label>
          </div>
          <div className="mt-5 flex justify-start w-full">
            <input
              onChange={() => setRemembered(prev => !prev)}
              className="hidden"
              type="checkbox"
              id="all"
            />
            <label
              className="flex items-center cursor-pointer text-sm"
              htmlFor="all">
              {remembered ? (
                <CheckedBox className="w-4 h-4 2xl:w-5 2xl:h-5 mr-3" />
              ) : (
                <UncheckedBox className="w-4 h-4 2xl:w-5 2xl:h-5 mr-3" />
              )}
              {t('아이디 저장')}
            </label>
          </div>
          <button
            onClick={requestLogin}
            disabled={!isSatisfied() || loading}
            className={`w-full flex justify-center py-3 xl:py-4 font-medium text-sm text-white rounded-md mt-5 xl:mt-10 duration-150 ${
              !isSatisfied() || loading
                ? 'cursor-not-allowed bg-tertiary/60'
                : 'bg-primary hover:bg-primary/70'
            }`}>
            {loading ? <Loader /> : t('로그인')}
          </button>
        </div>
      </div>
    </>
  );
}
