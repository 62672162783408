import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useCredit from '../../../hooks/use-credit';
import { isSignedAtom } from '../../../networks/store/member';
import { buyTemplate } from '../../../networks/template.service';
import { useModalAtom } from '../../../recoil/atoms/store';
import { TemplateType } from '../../../types_new/main/resource/types';
import { changeHost, getValueByKey } from '../../../utils/common';
import FreeBadge from '../../common/FreeBadge';
import PayBadge from '../../common/Paybadge';
import CoinIcon from '../../svg/coin-icon';
import DownIcon from '../../svg/down-icon';

type Props = {
  template: TemplateType;
  credit: number;
  setCredit: Dispatch<SetStateAction<number>>;
};

export default function TemplateCard({ template, credit, setCredit }: Props) {
  const setUseModal = useSetRecoilState(useModalAtom);
  const {
    postTitle,
    registerDate,
    metaData,
    postId,
    payDate,
    postContent,
    modifyDate,
    freeYn,
    templateAuthorEmail,
  } = template;
  const thumbnail = getValueByKey(metaData, 'thumbnail');
  const buyCnt = getValueByKey(metaData, 'buyCnt');
  const price = getValueByKey(metaData, 'credit');
  const preview = getValueByKey(metaData, 'downloadLink');
  const description = getValueByKey(metaData, 'description');
  const playTime = getValueByKey(metaData, 'playTime');
  const { substractCredit } = useCredit({
    itemName: `template-${postTitle}`,
    price,
    setCredit,
  });
  const { t } = useTranslation();
  const [bought, setBought] = useState(false);
  const isFree = freeYn === 'Y';
  const isPaid = !!payDate;
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const navigate = useNavigate();

  const openDetailModal = () => {
    setUseModal(prev => ({
      ...prev,
      isOpen: true,
      type: 'template-detail',
      data: {
        postTitle,
        preview,
        price,
        buyCnt,
        templateAuthorEmail,
        description,
        modifyDate,
        playTime,
      },
    }));
  };

  const openConfirmModal = () => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    setUseModal({
      type: 'confirm',
      isOpen: true,
      data: {
        title: postTitle,
        message: '이 템플릿을 구매하시겠습니까?',
        handleOnClick: requestBuyTemplate,
      },
    });
  };

  const requestBuyTemplate = () => {
    if (credit < +price) {
      openCreditFailModal();
      return;
    }
    substractCredit();
    buyTemplate({
      category: 'template',
      type: null,
      credit: price,
      postId,
    })
      .then(() => {
        setBought(true);
        handleSuccess();
      })
      .catch(console.log);

    function openCreditFailModal() {
      setUseModal({
        type: 'inform',
        isOpen: true,
        data: { title: '구매 실패', message: '크레딧이 부족합니다.' },
      });
    }

    function handleSuccess() {
      setUseModal({
        type: 'inform',
        isOpen: true,
        data: {
          title: '다운로드 완료!',
          message:
            '다운로드가 성공적으로 완료되었습니다. 다운로드 내역은 마이페이지 크레딧 내역에서 확인하실 수 있습니다.',
        },
      });
    }
  };

  return (
    <article className="w-full group cursor-pointer rounded-md">
      <div
        className="relative w-full rounded-t-md overflow-hidden"
        onClick={openDetailModal}>
        <div className="aspect-video rounded-t-md group-hover:scale-105 duration-500">
          <div
            className='"w-full h-full bg-no-repeat bg-center bg-contain rounded-t-md '
            style={{ backgroundImage: `url(${changeHost(thumbnail)})` }}></div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full bg-primary/0 group-hover:bg-primary/20 duration-500" />
        {isFree ? <FreeBadge /> : <PayBadge />}
      </div>
      <div className="py-3 px-6 xl:py-4 xl:px-7 2xl:py-5 2xl:px-8 bg-white flex flex-col space-y-3 text-black rounded-b-md">
        <div className="flex items-start justify-between">
          <div className="flex flex-col space-y-2">
            <h3 className="font-bold line-clamp-1">{postTitle}</h3>
          </div>

          <div className="flex items-center space-x-3">
            <div className="flex items-center space-x-1">
              <CoinIcon />
              <span className="text-[#9379ff] font-bold">{price}</span>
            </div>
            <button
              disabled={(bought || isPaid) && isLoggedIn}
              onClick={openConfirmModal}
              className="max-sm:w-full disabled:bg-black/50 disabled:cursor-not-allowed bg-black text-white text-xs leading-6 py-0.5 px-4 rounded-md hover:bg-black/70 duration-100 ease-out">
              {!isLoggedIn
                ? t('다운로드')
                : bought || isPaid
                ? t('구매함')
                : t('다운로드')}
            </button>
          </div>
        </div>
        <div className="flex space-x-1 ">
          <span className="text-[#353333] text-sm font-medium max-lg:hidden">
            제작자:
          </span>
          <span className="text-[#353333] text-sm font-medium max-lg:hidden">
            {templateAuthorEmail || '관리자'}
          </span>
        </div>
        <p className="line-clamp-2 text-sm break-all h-10">{description}</p>
        <div className="flex justify-between items-center ">
          <div className="flex items-center space-x-3">
            <span className="text-xs font-medium text-[#666]">
              {registerDate}
            </span>
            <div className="flex space-x-1">
              <DownIcon />
              <span className="text-sm">{buyCnt || '0'}</span>
            </div>
          </div>
        </div>
      </div>
    </article>
  );
}
