import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import usePath from '../../hooks/use-path';
import { isSignedAtom } from '../../networks/store/member';
import LinkArrowIcon from '../svg/link-arrow-icon';
import LandingFooter from './landing-footer';
import LandingHeader from './landing-header';

export default function LandingLayout() {
  const { isWhiteTheme } = usePath();
  const isLoggedIn = useRecoilValue(isSignedAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="text-[#ececec] bg-[#17233f]">
      <LandingHeader />
      <button
        onClick={() => (isLoggedIn ? navigate('/console') : navigate('/login'))}
        className="max-md:hidden fixed right-5 bottom-1/2 translate-y-1/2 flex flex-col items-center justify-center w-24 h-24 rounded-full bg-primary cursor-pointer z-[99]">
        <span className="text-sm">{t('스튜디오 시작하기')}</span>
        <i className="max-md:hidden group-hover:translate-x-0.5 group-hover:-translate-y-0.5 duration-150">
          <LinkArrowIcon />
        </i>
      </button>
      <main className={`grow ${isWhiteTheme && 'bg-lightPrimary'} `}>
        <Outlet />
      </main>
      <LandingFooter />
    </div>
  );
}
