export default function LowestIndexIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.041 3.91851H5.38789C4.57637 3.91851 3.91851 4.57637 3.91851 5.38789V10.041C3.91851 10.8525 4.57637 11.5103 5.38789 11.5103H10.041C10.8525 11.5103 11.5103 10.8525 11.5103 10.041V5.38789C11.5103 4.57637 10.8525 3.91851 10.041 3.91851ZM5.38789 3.42871C4.30587 3.42871 3.42871 4.30587 3.42871 5.38789V10.041C3.42871 11.123 4.30587 12.0001 5.38789 12.0001H10.041C11.123 12.0001 12.0001 11.123 12.0001 10.041V5.38789C12.0001 4.30587 11.123 3.42871 10.041 3.42871H5.38789Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.67403 2.20464H8.32709C9.0551 2.20464 9.65946 2.73408 9.77613 3.42894H10.0004C10.0929 3.42894 10.1841 3.43523 10.2733 3.44741C10.161 2.47213 9.33247 1.71484 8.32709 1.71484H3.67403C2.592 1.71484 1.71484 2.592 1.71484 3.67403V8.32709C1.71484 9.33247 2.47213 10.161 3.44741 10.2733C3.43523 10.1841 3.42894 10.0929 3.42894 10.0004V9.77613C2.73408 9.65946 2.20464 9.0551 2.20464 8.32709V3.67403C2.20464 2.86251 2.86251 2.20464 3.67403 2.20464Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 0C0.895431 0 0 0.89543 0 2V6.57143C0 7.57893 0.74496 8.41243 1.71409 8.55115V4.21409C1.71409 2.83338 2.83338 1.71409 4.21409 1.71409H8.55115C8.41243 0.74496 7.57893 0 6.57143 0H2Z"
        fill="white"
      />
    </svg>
  );
}
