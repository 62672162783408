import { useTranslation } from 'react-i18next';
import '../../locale/i18n';

export default function PrivacyPolicyTerms() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="pop-bg show"></div>
      <div className="terms-pop-wrap2 show">
        <div className="pop-close-btn">
          <img src="/main/img/icon-close-btn.svg" alt="" />
        </div>
        <div className="scroll-box pop-body">
          <p className="text-black">
            <dl>
              {t(
                '주식회사 플루닛(이하 “회사)은 정보통신망법과 개인정보보호법 규정에 따라 정보주체의 동의를 기반으로 개인정보를 수집, 이용 및 제공하고 있으며 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령, 규정 및 가이드라인을 준수하고 있습니다. 회사는 개인정보처리방침을 정하여 정보주체의 권익을 보호하며 개인정보 자기결정권을 보장합니다.'
              )}
            </dl>
            <dl>
              {t(
                '본 개인정보처리방침은 회사가 제공하는 플루닛계정 기반의 서비스 (이하 “서비스”)에 적용됩니다.'
              )}
            </dl>
            <strong>1. {t('개인정보 수집')}</strong>
            <dl>
              {t(
                '회사는 서비스 제공을 위해 필요한 최소한의 개인정보를 수집합니다. 플루닛 홈페이지에서 회원 가입 시 아래와 같은 개인정보를 수집합니다.'
              )}
            </dl>
            <u>{t('일반 개인')}</u>
            <br />
            {t('[필수] 이름, 아이디, 비밀번호, 이메일')}
            <br />
            {t('[선택] 생년월일, 성별, 주소, 휴대폰번호')}
            <br />
            <br />
            <u>{t('개인 및 법인 사업자')}</u>
            <br />
            {t('[필수] 대표자 전화번호, 담당자 번화번호')}
            <br />
            <br />
            <u>{t('본인인증 시')}</u>
            <br />
            {t(
              '이름, 성별, 생년월일, 휴대폰번호, 통신업자, 내/외국인 여부, 아이핀 번호(아이핀 이용 시), 암호화된 확인 값(CI), 중복가입확인정보(DI)'
            )}
            <br />
            <br />
            <u>{t('유료서비스 이용 시')}</u>
            <br />
            {t('신용카드 결제 시: 카드번호, 카드사명 등')}
            <br />
            {t('휴대전화번호 결제 시: 휴대전화번호, 결제승인번호 등')}
            <br />
            {t('계좌이체 시: 예금주명, 계좌번호, 계좌은행 등')}
            <br />
            <br />
            <u>{t('환불처리 시')}</u>
            <br />
            {t('계좌은행, 계좌번호, 예금주명, 결제 시 사용한 카드정보 등')}
            <br />
            <br />
            {t(
              '서비스 이용 과정 및 서비스 제공 업무 처리 과정에서 다음과 같은 정보들이 생성되거나 추가로 수집될 수 있습니다.'
            )}
            <dl>
              <dt>-</dt>
              <dd>
                {t(
                  'IP주소, 쿠키, 접속로그, 방문 일시, 서비스 이용 기록, 불량 이용 기록'
                )}
              </dd>
            </dl>
            <dl>
              {t(
                '회사는 특화된 서비스를 제공하기 위하여 일부 서비스 사용 시 플루닛계정에서 공통으로 수집하는 정보 이외에 정보주체에게 동의를 받은 후 추가적인 개인정보를 수집할 수 있습니다.'
              )}
            </dl>
            <p>
              {t(
                '개인정보를 수집하는 경우 반드시 정보주체에게 사전에 해당 사실을 알리고 동의를 구합니다. 회사는 다음과 같은 방법을 통해 개인정보를 수집합니다.'
              )}
            </p>
            <dl>
              <dt>-</dt>
              <dd>
                {t(
                  '홈페이지에서 플루닛계정 생성 과정에서 정보주체가 개인정보 수집을 동의하고 집적 정보를 입력하는 경우'
                )}
              </dd>
              <dt>-</dt>
              <dd>
                {t('제휴 서비스 또는 단체 등으로부터 개인정보를 제공받은 경우')}
              </dd>
              <dt>-</dt>
              <dd>
                {t(
                  '서비스 상담 과정에서 정보주체가 이메일, 팩스, 전화, 서면양식 등을 통해 정보를 제공하는 경우'
                )}
              </dd>
            </dl>
            <strong>2. {t('개인정보 이용')}</strong>
            <br />
            {t(
              '회사는 가입자관리, 요금정산, 서비스 제공, 개선 및 개발 등을 위해 개인정보를 이용합니다.'
            )}
            <u>{t('가입자관리')}</u>
            <br />
            {t(
              '가입자 서비스 제공, 본인 확인, 가입자 식별, 부정이용 방지, 가입의사 확인, 동일 사업자 제한, 기록보존, 고지사항 전달'
            )}
            <br />
            <br />
            <u>{t('요금정산')}</u>
            <br />
            {t('서비스 제공, 영수증 등 발송, 구매 및 요금 결제')}
            <br />
            <br />
            <u>{t('서비스 제공, 개선 및 개발')}</u>
            <br />
            {t(
              '개인화 서비스 제공, 통계학적 특성에 따른 서비스 제공, 광고성 정보 제공, 마케팅 활용, 서비스 통계, 음성명령 처리 및 음성인식 향상, 신규 서비스 개발, 문의사항 또는 불만처리'
            )}
            <br />
            <br />
            <strong>3. {t('개인정보 제공')}</strong>
            <dl>
              {t(
                '회사는 정보주체의 개인정보를 ‘개인정보 이용’에서 고지한 범위내에서 사용하며, 정보주체의 별도 동의가 있는 경우나 법령에 규정된 경우를 제외하고는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.'
              )}
            </dl>
            <strong>4. {t('개인정보 위탁처리')}</strong>
            <dl>
              {t(
                '회사는 서비스 제공을 위해 아래와 같이 개인정보를 위탁하고 있습니다. 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요 사항을 규정하며 정보주체의 개인정보를 마스킹 처리하여 제공합니다. 회사의 개인정보 위탁처리 기관 및 위탁업무 내용은 아래와 같습니다.'
              )}{' '}
            </dl>
            <table>
              <tr>
                <th>{t('업체명')}</th>
                <th>{t('위탁업무 내용')}</th>
              </tr>
              <tr>
                <td>NHNKCP, 애플, 구글</td>
                <td>전자결재서비스(P/G)-신용카드, 간편결재 등</td>
              </tr>
              <tr>
                <td>Amazon Web Services, Inc.</td>
                <td>데이터보관 및 인프라 관리</td>
              </tr>
              <tr>
                <td>(주)솔트룩스이노베이션</td>
                <td>정보시스템 운영/관리 업무</td>
              </tr>
              <tr>
                <td>(주)아이엔지피플</td>
                <td>정보시스템 구축/운영 관리 업무</td>
              </tr>
            </table>
            <strong>5. {t('개인정보 파기')}</strong>
            <dl>
              {t(
                '정보주체의 개인정보는 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 전자적 파일 형태로 저장된 경우 복구 및 재생되지 않도록 삭제하고, 그 밖에 기록물, 인쇄물, 서면 등의 경우 분쇄하거나 소각하여 파기합니다. 다만, 회사 내부 방침 또는 관계법령에 의한 정보보유 사유가 있을 시 아래의 이유로 명시한 기간 동안 보존합니다.'
              )}
            </dl>
            <u>{t('회사 내부 방침에 의한 정보보유 사유')}</u>
            <br />
            <br />
            <table>
              <tr>
                <th>{t('보관정보')}</th>
                <th>{t('보관사유')}</th>
                <th>{t('보관기간')}</th>
              </tr>
              <tr>
                <td>{t('사용중지, 탈퇴기록')}</td>
                <td>{t('환불조치 및 민원처리')}</td>
                <td>{t('중지/탈퇴일로부터 30일')}</td>
              </tr>
              <tr>
                <td>{t('서비스 ID, 성명')}</td>
                <td>{t('효율적인 재가입 신청 및 악의적 재가입 신청방지')}</td>
                <td>{t('6개월')}</td>
              </tr>
              <tr>
                <td>{t('부정 이용 기록')}</td>
                <td>{t('부정 가입 및 이용 방지')}</td>
                <td>{t('1년')}</td>
              </tr>
              <tr>
                <td>{t('지식재산권 침해신고 시 증빙서류')}</td>
                <td>{t('권리침해신고 건에 대한 조치 및 처리')}</td>
                <td>{t('6개월')}</td>
              </tr>
            </table>
            <br />
            <br />
            <u>{t('관련법령에 의한 정보보유 사유')}</u>
            <br />
            <br />
            <table>
              <tr>
                <th>{t('보관정보')}</th>
                <th>{t('근거법령')}</th>
                <th>{t('보관기간')}</th>
              </tr>
              <tr>
                <td>{t('계약 또는 청약철회 등에 기록')}</td>
                <td rowSpan={4}>
                  {t('전자상거래 등에서의 소비자보호에 관한 법률')}
                </td>
                <td>{t('5년')}</td>
              </tr>
              <tr>
                <td>{t('대금결제 및 재화 등의 공급에 관한 기록')}</td>
                <td>{t('5년')}</td>
              </tr>
              <tr>
                <td>{t('정보주체의 불만 또는 분쟁처리에 관한 기록')}</td>
                <td>{t('3년')}</td>
              </tr>
              <tr>
                <td>{t('표시/광고에 관한 기록')}</td>
                <td>{t('6개월')}</td>
              </tr>
              <tr>
                <td>
                  {t('세법이 규정하는 모든 거래에 관한 장부 및 증빙서류')}
                </td>
                <td>{t('국세기본법')}</td>
                <td>{t('5년')}</td>
              </tr>
              <tr>
                <td>{t('전자금융 거래에 관한 기록')}</td>
                <td>{t('전자금융거래법')}</td>
                <td>{t('5년')}</td>
              </tr>
              <tr>
                <td>{t('서비스 방문 기록')}</td>
                <td rowSpan={2}>{t('통신비밀보호법')}</td>
                <td>{t('3개월')}</td>
              </tr>
              <tr>
                <td>{t('통신사실 확인에 관한 기록')}</td>
                <td>{t('12개월')}</td>
              </tr>
            </table>
            <br />
            <br />
            <strong>6. {t('정보주체의 권리')}</strong>
            <br />
            {t(
              '정보주체가 플루닛계정 서비스 가입자(이하 “가입자)인 경우 서비스 내 개인 설정 기능을 통해 언제든지 자신의 개인정보를 조회하거나 수정할 수 있으며, 회사의 개인정보 수집, 이용 또는 제공에 대한 동의를 철회하거나 가입 해지를 요청할 수 있습니다. 개인정보관리책임자에게 서면, 전화, 또는 이메일로 연락하시면 지체없이 조치하겠습니다.'
            )}
            <br />
            <br />
            {t(
              '정보주체가 가입자가 아닌 경우, 회사에 정보주체의 개인정보를 위탁하는 가입자를 통하여 개인정보 조회 및 수정, 그리고 개인정보 수집, 이용 또는 제공에 대한 동의를 철회하실 수 있습니다.'
            )}
            <br />
            <br />
            {t(
              '요청된 개인정보의 오류에 대한 정정을 완료하기 전까지 해당 개인정보를 이용 및 제공하지 않습니다.'
            )}
            <br />
            <br />
            <strong>7. {t('쿠키')}</strong>
            <br />
            {t(
              '개인화된 맞춤 서비스를 제공하기 위하여 회사는 정보주체의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.'
            )}
            <br />
            <br />
            <u>{t('쿠키의 정의')}</u>
            <br />
            {t(
              '웹사이트 운영에 사용되는 서버가 정보주체의 브라우저에게 보내고 정보주체 컴퓨터 하드디스크에 저장되는 작은 텍스트 파일입니다.'
            )}
            <br />
            <br />
            <u>{t('사용목적')}</u>
            <br />
            {t(
              '쿠키는 정보주체가 웹사이트 방문 시, 웹사이트를 사용 설정한 대로 편리하게 사용할 수 있도록 돕습니다. 또한, 정보주체의 웹사이트 방문 기록 및 이용 형태들을 통해 개인화되고 맞춤화된 서비스를 제공하기 위하여 쿠키를 사용합니다.'
            )}
            <br />
            <br />
            <u>{t('쿠키 수집 거부')}</u>
            <br />
            {t(
              '쿠키에는 개인을 식별하는 정보를 저장하지 않으며, 정보주체는 쿠키 사용 여부를 선택할 수 있습니다. 정보주체는 웹 브라우저 설정을 통해 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치도록 설정하거나, 쿠키의 저장을 거부할 수도 있습니다.'
            )}
            <br />
            <br />
            <u>{t('설정 방법의 예')}</u>
            <br />
            Internet Explorer:{' '}
            {t(
              '웹 브라우저 상단의 도구 메뉴 &gt; 인터넷 옵션 &gt; 개인정보 &gt; 설정'
            )}
            Chrome:{' '}
            {t(
              '웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의 고급 설정 표시 &gt; 개인정보의 콘텐츠 설정 버튼 &gt; 쿠키'
            )}
            <br />
            <br />
            <strong>8. {t('개인정보 보호')}</strong>
            <br />
            {t(
              '정보주체의 개인정보 보호를 위하여 회사는 다음과 같은 노력을 기울입니다.'
            )}
            <br />
            <br />
            <u>{t('정보 암호화')}</u>
            <br />
            {t(
              '정보주체의 아이디(ID)와 비밀번호 같은 중요한 정보는 암호화하여 보관 및 관리합니다. 또한, 암호화통신 등을 이용하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 합니다.'
            )}
            <br />
            <br />
            <u>{t('해킹 대비')}</u>
            <br />
            {t(
              '회사는 해킹이나 컴퓨터 바이러스 등에 의해 정보주체의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최신 백신프로그램을 이용하며, 중요한 자료를 정기적으로 백업합니다. 그리고 침입차단시스템을 이용하여 외부로부터 무단 접근을 통제하고 있으며, 보안을 위한 기술적 장치를 갖추려 노력합니다.'
            )}
            <br />
            <br />
            <u>{t('개인정보 처리 직원 최소화 및 교육')}</u>
            <br />
            {t(
              '개인정보 처리 직원은 담당자에 한정시키며 이를 위한 별도의 비밀번호를 생성하여 정기적으로 갱신합니다. 담당자에 대한 체계적인 교육을 통하여 개인정보보호 관련 법률 준수를 강조하며 개인정보처리시스템에 대한 접속기록을 별도 저장 장치에 백업 보관합니다.'
            )}
            <br />
            <br />
            <strong>9. {t('개인정보보호책임자 연락처')}</strong>
            <br />
            {t(
              '서비스를 이용하면서 개인정보보호 관련 문의 및 민원 발생 시 개인정보 보호책임자 또는 담당자에게 연락해 주시기 바랍니다. 회사는 문의 및 민원사항에 대해 성실하고 신속하게 답변 드리겠습니다.'
            )}
            <br />
            <br />
            {t('개인정보 보호책임자')}
            <br />
            {t('책임자: 이동훈수석')}
            <br />
            {t('담당부서: 워크센터사업본부')} <br />
            {t('전화')}: 02)2193-1600
            <br />
            <br />
            <br />
            {t(
              '기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.'
            )}{' '}
            <br />
            <dl>
              <dt>-</dt>
              <dd>
                {t('개인정보침해신고센터')} (http://privacy.kisa.or.kr /{' '}
                {t('국번없이')} 118)
              </dd>
              <dt>-</dt>
              <dd>
                {t('대검찰정 사이버수사과')} (http://www.spo.go.kr /{' '}
                {t('국번없이')} 1301)
              </dd>
              <dt>-</dt>
              <dd>
                {t('경찰청 사이버안전국')} (http://cyberbureau.police.go.kr /{' '}
                {t('국번없이')} 182)
              </dd>
            </dl>
            <strong>10. {t('고지')}</strong>
            <br />
            {t(
              '회사는 서비스의 변경사항을 반영하기 위한 목적 등으로 본 개인정보처리방침을 수정할 수 있습니다. 개인정보처리방침이 변경되는 경우 회사는 변경 사항을 홈페이지에 게시하며, 변경된 개인정보처리방침은 게시한 날로부터 7일 후부터 효력이 발생합니다. 다만, 수집하는 개인정보, 이용목적의 변경 등과 같이 정보주체 권리의 중대한 변경이 있을 경우 최소 30일 전에 고지합니다.'
            )}
            <br />
            <br />
            {t('본 개인정보처리방침은 2022년 7월 1일부터 시행됩니다.')}
          </p>
        </div>
      </div>
    </div>
  );
}
