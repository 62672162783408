import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { ProjectInfoType } from '../../../types_new/console/resource/types';
import { changeHost } from '../../../utils/common';
import ProjectDetailPop from './project-detail-pop';
import TemplateBadge from './template-badge';

type Props = {
  updateAfterDelete?: () => void;
  updateDuplicatedProject?: () => void;
  setProjects: Dispatch<SetStateAction<ProjectInfoType[]>>;
  isEditing?: boolean;
  project: ProjectInfoType;
};
export default function ProjectCard({
  updateAfterDelete,
  updateDuplicatedProject,
  setProjects,
  isEditing,
  project: {
    completedThumbnail,
    templateBaseProjYn,
    projectId,
    projectName,
    registerDate,
    thumbnail,
    content,
    duration,
    pageNum,
    status,
    downloadLink,
    exportVoice,
    producing,
  },
}: Props) {
  const isTemplate = templateBaseProjYn === 'Y';

  return (
    <div className="relative">
      {isTemplate && <TemplateBadge />}
      <ProjectDetailPop
        completedThumbnail={completedThumbnail}
        updateAfterDelete={updateAfterDelete}
        updateDuplicatedProject={updateDuplicatedProject}
        setProjects={setProjects}
        projectId={projectId}
        status={status}
        pageNum={pageNum}
        duration={duration}
        downloadLink={downloadLink}
        projectName={projectName}
        thumbnail={thumbnail}
        exportVoice={exportVoice}
        projectContent={content}
        registerDate={registerDate}
        producing={producing}
        isHome
      />
      <div className="w-full flex flex-col space-y-2.5 ">
        <div
          className={`${
            isEditing ? 'h-36 xl:h-40 2xl:h-44' : 'h-[120px]'
          } rounded-[10px] bg-gradient-to-br from-[#9D9BA0] to-[#BFBFBF] overflow-hidden`}>
          <img
            draggable={false}
            className={`-translate-y-3 ${!isEditing && 'scale-90'}`}
            src={`${changeHost(thumbnail)}`}
            alt="project thumbnail"
          />
        </div>
        <div className="flex justify-between items-end">
          <span
            className={`${
              isEditing ? 'text-[15px]' : 'text-[13px]'
            } line-clamp-1 text-consoleDark`}>
            {projectName}
          </span>
          <span className={`${isEditing ? 'text-sm' : 'text-xs'} text-[#aaa]`}>
            {moment(registerDate).format('YYYY.MM.DD')}
          </span>
        </div>
      </div>
    </div>
  );
}
