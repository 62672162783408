import { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  emailRegex,
  numberRegex,
  passwordRegex,
} from '../../../constants/regex';
import APIService from '../../../networks/APIService';
import { IPaySystemSignUp } from '../../../networks/dto/IPaySystemDto';
import {
  checkDuplicateAccount,
  signUp,
} from '../../../networks/member.service';
import { paySignIn, paySingUp } from '../../../networks/paysystem.service';
import { isSignedAtom, loginInfoAtom } from '../../../networks/store/member';
import {
  IPaySystemMemberInfo,
  paySystemMemberAtom,
} from '../../../networks/store/paysystem';
import { RegisterInfoType } from '../../../types_new/main/landing/types';
import Loader from '../../common/loader';
import InformModal from '../../modals/inform-modal';
import ErrorIcon from '../../svg/error-icon';
import NationComboBox from './nation-combo-box';

const LABEL_CLASS =
  'absolute text-xs lg:text-sm text-grayText duration-200 transform -translate-y-2 scale-75 top-3 z-10 origin-[0] left-5 peer-focus:left-5 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0.5 peer-focus:scale-75 peer-focus:-translate-y-2';
const INPUT_CLASS =
  'h-[44px] lg:h-[50px] pl-5 block py-2.5 pt-6 px-0 w-full text-xs lg:text-sm text-black bg-white rounded-md border border-grayBorder appearance-none focus:outline-none focus:ring-0 focus:border-primary peer text-[#666666]';

export default function InfoInputView() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    title: '',
    message: '',
  });
  const [registerInfo, setRegisterInfo] = useState<RegisterInfoType>({
    userLogin: '',
    userPw: '',
    confirmPassword: '',
    userName: '',
    userCountryCode: '',
    userPhone: '',
  });
  const setMemberInfo = useSetRecoilState(loginInfoAtom);
  const setIsLoggedIn = useSetRecoilState(isSignedAtom);
  const setPaySystemMember = useSetRecoilState(paySystemMemberAtom);

  const handleOnChange = ({
    target: { value, name },
  }: ChangeEvent<HTMLInputElement>) => {
    setRegisterInfo(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const isEmailValid = () => {
    return emailRegex.test(registerInfo.userLogin);
  };

  const isPasswordValid = () => {
    return passwordRegex.test(registerInfo.userPw);
  };

  const isPasswordSame = () => {
    const { userPw, confirmPassword } = registerInfo;
    return userPw === confirmPassword;
  };

  const isSatisfied = () => {
    if (
      isPasswordSame() &&
      isPasswordValid() &&
      isEmailValid() &&
      registerInfo.userPhone.length > 10 &&
      registerInfo.userName.length
    ) {
      return true;
    }
    return false;
  };

  /**
   * 회원 가입 처리 루틴
   * 1. 스튜디오 회원 조회
   *    2. 결제시스템 등록
   *      3. 스튜디오 회원가입
   *        4. 스튜디오 토큰 저장
   *        5. 결제시스템 접근 토큰 요청
   *          6. 결제시스템 접근토큰 저장
   *          7. 회원가입 모든 절차 성공
   *        5.1 결제시스템 접근토큰 실패 모달 표시
   *        5.2 결제시스템 접근토큰 오류 모달 표시
   *      3.1 스튜디오 가입 오류 모달 표시
   *    2.1 결제시스템 등록 실패 모달 표시
   *    2.2 결제시스템 등록 오류 모달 표시
   * 1.1 회원 존재하는 경우 오류 모달 표시
   */
  const requestSignUp = async () => {
    setLoading(true);

    await checkDuplicateAccount({
      userLogin: registerInfo.userLogin,
      userPhone: registerInfo.userPhone,
    })
      .then(async result => {
        if (result === 200) {
          const reqData: IPaySystemSignUp = {
            name: registerInfo.userName,
            email: registerInfo.userLogin,
            mobile: registerInfo.userPhone,
            password: registerInfo.userPw,
          };
          await paySingUp(reqData)
            .then(async res => {
              if (res.status === 200 && res.data.result === 1) {
                // 결제시스템 등록 성공.-----------
                console.log('회원가입 시작 > 가능 > 결재시스템 등록 성공');

                // 스튜디오 회원 가입.
                await signUp(registerInfo)
                  .then(async ({ data }) => {
                    if (data.code === '200') {
                      console.log(
                        '회원가입 시작 > 가능 > 결재시스템 등록 성공 > 스튜디오 회원가입 성공'
                      );
                      // 토큰 저장  --------------
                      const userToken = data.body.userToke;
                      const userBody = data.body;
                      APIService.setToken(userToken);
                      setMemberInfo(userBody);

                      // 결제시스템 접근 토큰 요청.
                      await paySignIn(registerInfo.userLogin)
                        .then(res => {
                          if (res.status === 200 && res.data.result === 1) {
                            console.log(
                              '회원가입 시작 > 가능 > 결재시스템 등록 성공 > 스튜디오 회원가입 성공 > 결제시스템 토큰 성공'
                            );
                            const payInfo: IPaySystemMemberInfo = {
                              paySystemCompanySeq: res.data.companySeq
                                ? res.data.companySeq?.toString()
                                : '',
                              paySystemMemberId: res.data.memberId
                                ? res.data.memberId?.toString()
                                : '',
                              paySystemExpiredDate: res.data.tokenExpiredDate
                                ? res.data.tokenExpiredDate?.toString()
                                : '',
                              paySystemToken: res.data.authToken
                                ? res.data.authToken
                                : '',
                              paySystemMemberSeq: res.data.memberSeq
                                ? res.data.memberSeq?.toString()
                                : '',
                            };
                            // console.log('7. 회원가입 모든 절차 성공');
                            setPaySystemMember(payInfo);
                            handleSuccess();
                          } else {
                            // console.log('5.1 결제시스템 접근토큰 실패 모달 표시');
                            console.log(
                              '회원가입 시작 > 가능 > 결재시스템 등록 성공 > 스튜디오 회원가입 성공 > 결제시스템 토큰 실패'
                            );
                            handleFail('오류 발생');
                          }
                        })
                        .catch(err => {
                          console.error(
                            'paysystem signup > signin error :' + err
                          );
                          console.log('5.2 결제시스템 접근토큰 오류 모달 표시');
                        });
                    } else {
                      // console.log('3.1 스튜디오 가입 오류 모달 표시');
                      console.log(
                        '회원가입 시작 > 가능 > 결재시스템 등록 성공 > 스튜디오 회원가입 실패'
                      );
                      setIsLoggedIn(false);
                      handleFail(data.code);
                    }
                  })
                  .catch(openFailModal)
                  .finally(() => setLoading(false));
              } else {
                // console.error('paysystem signup error occured!!!!');
                // console.log('2.1 결제시스템 등록 실패 모달 표시');
                console.log('회원가입 시작 > 가능 > 결재시스템 등록 실패');
                handleFail('400');
              }
            })
            .catch(err => {
              console.error('signup error : ' + err);
              // console.log('2.2 결제시스템 등록 오류 모달 표시');
              console.log('회원가입 시작 > 가능 > 결재시스템 등록 오류');
              handleFail('400');
            });
        } else {
          handleFail(result);
        }
      })
      .catch(handleFail)
      .finally(() => setLoading(false));

    function handleFail(code?: string | number) {
      const resultCode = String(code);

      switch (resultCode) {
        case '404':
          setModal({
            isOpen: true,
            title: '이미 가입된 전화번호입니다.',
            message: '다른 전화번호를 사용해주세요.',
          });
          break;
        case '402':
        case '400':
          setModal({
            isOpen: true,
            title: '이미 가입된 계정입니다.',
            message: '다른 계정을 사용해주세요.',
          });
          break;
        default:
          openFailModal();
          break;
      }
    }

    function handleSuccess() {
      navigate('/signup/steps/complete');
    }

    function openFailModal() {
      setModal({
        isOpen: true,
        title: '회원가입 오류',
        message: '잠시 후 다시 시도해주세요.',
      });
    }
  };

  const handleEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') requestSignUp();
  };

  return (
    <>
      <InformModal
        isOpen={modal.isOpen}
        title={modal.title}
        message={modal.message}
        closeModal={() =>
          setModal(prev => ({
            ...prev,
            isOpen: false,
          }))
        }
      />
      <section>
        <div className="flex flex-col mt-6">
          <div className="relative z-0 w-full group mb-2.5">
            <input
              onKeyDown={handleEnter}
              maxLength={20}
              spellCheck={false}
              type="text"
              name="userName"
              id="userName"
              onChange={handleOnChange}
              className={INPUT_CLASS}
              placeholder=" "
            />
            <label htmlFor="userName" className={LABEL_CLASS}>
              {t('이름')}
            </label>
          </div>
          <NationComboBox setRegisterInfo={setRegisterInfo} />
          <div className="relative z-0 w-full group mt-2.5">
            <input
              onKeyDown={handleEnter}
              autoComplete="off"
              type="text"
              name="userPhone"
              id="userPhone"
              maxLength={20}
              value={registerInfo.userPhone}
              onChange={e => {
                if (!numberRegex.test(e.target.value)) return;
                handleOnChange(e);
              }}
              className={INPUT_CLASS}
              placeholder=" "
            />
            <label htmlFor="userPhone" className={LABEL_CLASS}>
              {t('휴대폰번호')}
            </label>
          </div>
          <div className="relative z-0 w-full group mt-5 lg:mt-6">
            <input
              onKeyDown={handleEnter}
              maxLength={60}
              autoComplete="off"
              spellCheck={false}
              type="email"
              name="userLogin"
              id="userLogin"
              onChange={handleOnChange}
              className={`${INPUT_CLASS} ${
                isEmailValid() || !registerInfo.userLogin
                  ? 'focus:border-primary'
                  : '!border-error'
              } peer`}
              placeholder=" "
            />
            <div
              className={`mt-1 lg:mt-2 text-error text-xs items-center space-x-1 ${
                isEmailValid() || !registerInfo.userLogin ? 'hidden' : 'flex'
              }`}>
              <ErrorIcon />
              <span className="block">
                {t('이메일 형식이 올바르지 않습니다.')}
              </span>
            </div>
            <label
              htmlFor="userLogin"
              className={`${LABEL_CLASS} ${
                isEmailValid() || !registerInfo.userLogin
                  ? 'peer-focus:text-grayText'
                  : '!text-error'
              }`}>
              {t('이메일')}
            </label>
          </div>
          <div className="relative z-0 w-full group mt-2.5">
            <input
              onKeyDown={handleEnter}
              autoComplete="off"
              type="password"
              name="userPw"
              id="userPw"
              maxLength={16}
              onChange={handleOnChange}
              className={`${INPUT_CLASS} ${
                isPasswordValid() || !registerInfo.userPw
                  ? 'focus:border-primary'
                  : '!border-error'
              }`}
              placeholder=" "
            />
            <label
              htmlFor="userPw"
              className={`${LABEL_CLASS} ${
                isPasswordValid() || !registerInfo.userPw
                  ? 'peer-focus:text-grayText'
                  : '!text-error'
              }`}>
              {t('비밀번호')}
            </label>
          </div>
          <div className="relative z-0 w-full group mt-2.5">
            <input
              onKeyDown={handleEnter}
              autoComplete="off"
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              maxLength={16}
              onChange={handleOnChange}
              className={`${INPUT_CLASS} ${
                isPasswordSame() || !registerInfo.confirmPassword
                  ? 'focus:border-primary'
                  : '!border-error'
              }`}
              placeholder=" "
            />
            <p
              className={`mt-1 lg:mt-2 ${
                isPasswordValid() || !registerInfo.userPw
                  ? 'text-grayText'
                  : 'text-error'
              } text-xs`}>
              {t('영문, 숫자, 특수문자 포함 8~16자로 입력해주세요.')}
            </p>
            <div
              className={`mt-1 lg:mt-2 text-error text-xs items-center space-x-1 ${
                isPasswordSame() || !registerInfo.confirmPassword
                  ? 'hidden'
                  : 'flex'
              }`}>
              <ErrorIcon />
              <span className="block">
                {t('비밀번호가 일치하지 않습니다.')}
              </span>
            </div>
            <label
              htmlFor="confirmPassword"
              className={`${LABEL_CLASS} ${
                isPasswordSame() || !registerInfo.confirmPassword
                  ? 'peer-focus:text-grayText'
                  : '!text-error'
              }`}>
              {t('비밀번호 확인')}
            </label>
          </div>
        </div>
        <div className="w-full flex space-x-4 justify-between mt-4">
          <button
            disabled={loading}
            onClick={() => navigate(-1)}
            className="w-full py-3 xl:py-4 border border-primary font-medium text-sm text-primary rounded-md hover:bg-primary/30 duration-150">
            {t('이전단계')}
          </button>
          <button
            disabled={!isSatisfied() || loading}
            onClick={requestSignUp}
            className={`w-full flex justify-center py-3 xl:py-4 font-medium text-sm text-white rounded-md duration-150 ${
              !isSatisfied() || loading
                ? 'cursor-not-allowed bg-tertiary/60'
                : 'bg-primary hover:bg-primary/70'
            }`}>
            {loading ? <Loader /> : t('회원가입')}
          </button>
        </div>
      </section>
    </>
  );
}
