import { Dialog, Transition } from '@headlessui/react';
import { Dispatch, Fragment, RefObject, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { withdrawUser } from '../../../networks/member.service';
import {
  removeStorageToken,
  removeStorageUserInfo,
} from '../../../networks/store/member';
import { InformModalType } from '../../../types_new/commonTypes';
import { WithdrawReqType } from '../../../types_new/main/service/types';
import Loader from '../../common/loader';
import WithdrawReasonSelectBox from './withdraw-reasson-select-box';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  setInformModal: Dispatch<SetStateAction<InformModalType>>;
  passwordInputRef: RefObject<HTMLInputElement>;
};

export default function WithdrawModal({
  isOpen,
  closeModal,
  setInformModal,
  passwordInputRef,
}: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [withdrawInfo, setWithdrawInfo] = useState<WithdrawReqType>({
    passwd: '',
    reason: '',
  });

  const isSatisfied = () => {
    const { passwd, reason } = withdrawInfo;
    return passwd.length && reason.length;
  };

  const requestWithdraw = () => {
    if (!isSatisfied()) return;
    passwordInputRef.current?.blur();
    setLoading(true);
    withdrawUser(withdrawInfo)
      .then(({ data }) => {
        if (data.code === '200') {
          handleSuccess();
        } else {
          handleFail(data.body.message);
        }
      })
      .catch(handleFail)
      .finally(() => setLoading(false));

    function handleSuccess() {
      // sessionStorage.removeItem('myInfo');
      removeStorageUserInfo();
      // localStorage.removeItem('userToken');
      removeStorageToken();
      setInformModal({
        isOpen: true,
        title: '회원 탈퇴 완료',
        message: '그동안 플루닛 스튜디오를 사랑해주셔서 감사합니다.',
      });
    }

    function handleFail(errorPhrase: string) {
      setWithdrawInfo(prev => ({
        ...prev,
        passwd: '',
      }));
      setInformModal({
        isOpen: true,
        title: errorPhrase || '네트워크 오류',
        message: errorPhrase
          ? '비밀번호를 다시 한 번 확인해주세요.'
          : '잠시 후 다시 시도해주세요.',
      });
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => ''}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="max-sm:h-screen w-full sm:max-w-lg xl:max-w-xl 2xl:max-w-2xl transform overflow-hidden sm:rounded-lg text-left bg-white p-5 sm:p-10 shadow-lg transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl 2xl:text-2xl font-semibold text-black">
                  {t('회원탈퇴')}
                </Dialog.Title>

                <div className="flex flex-col space-y-2 mt-8 2xl:mt-10">
                  <span className="text-[#353333] text-sm">
                    {t('탈퇴 사유')}
                  </span>
                  <WithdrawReasonSelectBox
                    setWithdrawInfo={setWithdrawInfo}
                    withdrawInfo={withdrawInfo}
                  />
                </div>
                <div className="flex flex-col space-y-2 mt-3">
                  <span className="text-[#353333] text-sm">
                    {t('비밀번호 입력')}
                  </span>
                  <input
                    ref={passwordInputRef}
                    className="border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5"
                    type="password"
                    onKeyDown={({ key }) =>
                      key === 'Enter' && requestWithdraw()
                    }
                    value={withdrawInfo.passwd}
                    onChange={({ target: { value: passwd } }) =>
                      setWithdrawInfo(prev => ({
                        ...prev,
                        passwd,
                      }))
                    }
                    placeholder={t('비밀번호를 입력해주세요.')}
                  />
                </div>
                <p className="mt-3 text-[#5B5B5B] text-xs leading-[18px]">
                  {t('탈퇴 시 크레딧은 모두 소멸되며 환불 불가합니다.')}
                  <br />
                  {t('고객정보는 1년동안 보관되며 이후 소멸됩니다.')}
                </p>

                <div className="w-full flex space-x-2 sm:space-x-3 mt-5">
                  <button
                    className={`w-1/3 py-3 sm:py-3.5 2xl:py-4 bg-[#EEEEF0] text-[#5b5b5b] rounded-md hover:bg-opacity-90 duration-100 ${
                      loading && 'cursor-default'
                    }`}
                    disabled={loading}
                    onClick={closeModal}>
                    {t('취소')}
                  </button>
                  <button
                    onClick={requestWithdraw}
                    disabled={!isSatisfied() || loading}
                    className={`w-2/3 py-3 sm:py-3.5 2xl:py-4 rounded-md flex justify-center ${
                      !isSatisfied() || loading
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                        : 'bg-primary text-white hover:bg-opacity-90'
                    } duration-100`}>
                    {loading ? <Loader /> : t('탈퇴하기')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
