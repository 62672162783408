export default function ColorPickerIcon() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_917_6646)">
        <path
          d="M10.5398 6.60156C11.2165 6.68554 11.888 6.9036 12.5158 7.26603C15.0268 8.71578 15.8872 11.9266 14.4374 14.4377C12.9877 11.9266 9.77681 11.0663 7.26578 12.516C6.63893 12.8779 6.11495 13.3496 5.7041 13.8927C5.90071 10.6932 7.81676 7.95859 10.5398 6.60156Z"
          fill="url(#paint0_linear_917_6646)"
        />
        <path
          d="M5.70419 13.8925C6.11504 13.3493 6.63902 12.8777 7.26587 12.5158C9.7769 11.066 12.9878 11.9264 14.4375 14.4374C11.538 14.4374 9.1875 16.7879 9.1875 19.6874C9.1875 20.4116 9.33415 21.1016 9.59937 21.7293C7.24151 20.1617 5.6875 17.481 5.6875 14.4374C5.6875 14.2544 5.69312 14.0727 5.70419 13.8925Z"
          fill="url(#paint1_linear_917_6646)"
        />
        <path
          d="M9.59937 21.7294C9.33415 21.1017 9.1875 20.4117 9.1875 19.6875C9.1875 16.788 11.538 14.4375 14.4375 14.4375C12.9878 16.9485 13.8481 20.1594 16.3591 21.6091C16.9869 21.9716 17.6584 22.1896 18.3352 22.2736C17.1615 22.8585 15.8379 23.1875 14.4375 23.1875C12.6486 23.1875 10.9852 22.6507 9.59937 21.7294Z"
          fill="url(#paint2_linear_917_6646)"
        />
        <path
          d="M18.3353 22.2736C17.6585 22.1896 16.987 21.9716 16.3593 21.6091C13.8482 20.1594 12.9879 16.9485 14.4376 14.4375C15.8874 16.9485 19.0982 17.8089 21.6093 16.3591C22.2361 15.9972 22.7601 15.5256 23.1709 14.9824C22.9743 18.182 21.0583 20.9166 18.3353 22.2736Z"
          fill="url(#paint3_linear_917_6646)"
        />
        <path
          d="M23.1708 14.9823C22.76 15.5255 22.236 15.9971 21.6091 16.359C19.0981 17.8088 15.8872 16.9484 14.4375 14.4374C17.337 14.4374 19.6875 12.0869 19.6875 9.18739C19.6875 8.46315 19.5409 7.77317 19.2756 7.14551C21.6335 8.71307 23.1875 11.3938 23.1875 14.4374C23.1875 14.6204 23.1819 14.8021 23.1708 14.9823Z"
          fill="url(#paint4_linear_917_6646)"
        />
        <path
          d="M19.2758 7.14562C19.541 7.77328 19.6877 8.46326 19.6877 9.1875C19.6877 12.087 17.3372 14.4375 14.4377 14.4375C15.8874 11.9265 15.0271 8.71561 12.5161 7.26587C11.8883 6.90343 11.2168 6.68538 10.54 6.6014C11.7137 6.01649 13.0373 5.6875 14.4377 5.6875C16.2266 5.6875 17.89 6.22431 19.2758 7.14562Z"
          fill="url(#paint5_linear_917_6646)"
        />
      </g>
      <g clipPath="url(#clip0_917_6646)">
        <path
          d="M18.0939 9.83838C18.3107 9.83838 18.5004 9.91038 18.663 10.0544C18.8255 10.1984 18.9068 10.3788 18.9068 10.5956C18.9068 10.7907 18.8372 11.0245 18.6978 11.297C17.6696 13.2449 16.9496 14.4093 16.5377 14.7902C16.2373 15.072 15.8998 15.213 15.5251 15.213C15.1349 15.213 14.7996 15.0697 14.5194 14.7833C14.2391 14.4968 14.099 14.1569 14.099 13.7636C14.099 13.3672 14.2414 13.039 14.5263 12.7788L17.49 10.0892C17.6727 9.92199 17.874 9.83838 18.0939 9.83838ZM13.8714 14.6416C13.9921 14.8769 14.157 15.0782 14.3661 15.2455C14.5751 15.4127 14.8082 15.5304 15.0652 15.5985L15.0698 15.9283C15.0822 16.588 14.8817 17.1253 14.4683 17.5402C14.0548 17.9552 13.5152 18.1627 12.8494 18.1627C12.4685 18.1627 12.1309 18.0907 11.8367 17.9467C11.5425 17.8027 11.3064 17.6053 11.1283 17.3544C10.9503 17.1036 10.8163 16.8202 10.7265 16.5043C10.6367 16.1885 10.5918 15.8478 10.5918 15.4824C10.6135 15.4979 10.677 15.5443 10.7823 15.6217C10.8875 15.6992 10.9835 15.7681 11.0703 15.8285C11.157 15.8888 11.2483 15.9454 11.3443 15.998C11.4403 16.0507 11.5116 16.077 11.558 16.077C11.685 16.077 11.7701 16.0197 11.8135 15.9051C11.8909 15.7007 11.98 15.5265 12.0806 15.3825C12.1813 15.2385 12.2889 15.1208 12.4035 15.0295C12.518 14.9381 12.6543 14.8646 12.8122 14.8088C12.9702 14.7531 13.1297 14.7136 13.2907 14.6904C13.4517 14.6671 13.6453 14.6509 13.8714 14.6416Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_917_6646"
          x="3.6875"
          y="4.6875"
          width="21.5"
          height="21.5"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4049 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_917_6646"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_917_6646"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_917_6646"
          x1="10.4229"
          y1="6.60156"
          x2="10.4229"
          y2="14.4377"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD305" />
          <stop offset="1" stopColor="#FDCF01" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_917_6646"
          x1="10.0625"
          y1="11.8115"
          x2="10.0625"
          y2="21.7293"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#52CF30" />
          <stop offset="1" stopColor="#3BBD1C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_917_6646"
          x1="13.7613"
          y1="14.4375"
          x2="13.7613"
          y2="23.1875"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#14ADF6" />
          <stop offset="1" stopColor="#1191F4" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_917_6646"
          x1="18.4522"
          y1="14.4375"
          x2="18.4522"
          y2="22.2736"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#CA70E1" />
          <stop offset="1" stopColor="#B452CB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_917_6646"
          x1="18.8125"
          y1="7.14551"
          x2="18.8125"
          y2="17.0633"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF645D" />
          <stop offset="1" stopColor="#FF4332" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_917_6646"
          x1="15.1139"
          y1="5.6875"
          x2="15.1139"
          y2="14.4375"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBB114" />
          <stop offset="1" stopColor="#FF9508" />
        </linearGradient>
        <clipPath id="clip0_917_6646">
          <rect
            width="8.32432"
            height="8.32432"
            fill="white"
            transform="translate(10.5918 9.83838)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
