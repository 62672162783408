import React from 'react';
import usePath from '../../hooks/use-path';

type Props = {
  children: React.ReactNode;
};
export default function SubpageContainer({ children }: Props) {
  const { isWhiteTheme, hasSwiper, isWide, pathname } = usePath();

  return (
    <div>
      <div
        className={`h-8 sm:h-12 md:h-16 lg:h-20 2xl:h-28 w-full ${
          isWhiteTheme
            ? 'bg-lightPrimary'
            : 'bg-gradient-to-b from-darkPrimary to-darkSecondary'
        }`}
      />
      <div
        className={`${
          isWide ? 'overflow-x-hidden !px-0' : 'max-w-screen-xl'
        } w-full mx-auto ${
          pathname === '/production-inquiry'
            ? ''
            : hasSwiper
            ? 'lg:px-5'
            : 'px-5'
        }`}>
        {children}
      </div>
    </div>
  );
}
