import { PostType } from '../../../types_new/main/resource/types';

type Props = {
  list: PostType[];
  paged: number;
  onPageChange: (page: number) => void;
};

export default function SupportPagination({
  list,
  paged,
  onPageChange,
}: Props) {
  const renderPageButtons = () => {
    const maxPage = Math.ceil(list.length / 10) || 1;
    const buttons = [];
    for (let page = 1; page <= maxPage; page++) {
      buttons.push(
        <button
          className={`w-8 h-8 xl:w-9 xl:h-9 group rounded-[10px] duration-75 ease-out text-darkSecondary ${
            page === paged ? 'bg-primary text-white' : 'hover:bg-primary/70'
          }`}
          key={page}
          onClick={() => onPageChange(page)}
          disabled={page === paged}>
          {page}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className="flex items-center justify-center space-x-1 mt-10 md:mt-12 lg:mt-14 xl:mt-[60px]">
      {renderPageButtons()}
    </div>
  );
}
