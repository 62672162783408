import { Dialog, Transition } from '@headlessui/react';
import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { numberRegex } from '../../../constants/regex';
import { changeMobile } from '../../../networks/member.service';
import { userInfoAtom } from '../../../networks/store/member';
import { InformModalType } from '../../../types_new/commonTypes';
import { UpdateMobileReqType } from '../../../types_new/main/service/types';
import Loader from '../../common/loader';
import ErrorIcon from '../../svg/error-icon';

const INITIAL_STATE = {
  mobile: '',
};

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  setInformModal: Dispatch<SetStateAction<InformModalType>>;
};

export default function ContactModal({
  isOpen,
  closeModal,
  setInformModal,
}: Props) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [changeInfo, setChangeInfo] =
    useState<UpdateMobileReqType>(INITIAL_STATE);
  const userInfo = useRecoilValue(userInfoAtom);
  const currentTel = userInfo.userPhone;

  const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
    setChangeInfo(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const requestMobileChange = () => {
    if (isSameNumber()) return;

    setLoading(true);
    changeMobile(changeInfo)
      .then(({ data }) => {
        if (data.code === '200') {
          handleSuccess();
        } else {
          handleFail(data.body.message);
        }
      })
      .catch(handleFail)
      .finally(() => setLoading(false));

    function handleSuccess() {
      closeModal();
      resetState();
      setInformModal({
        isOpen: true,
        title: '연락처 변경 완료',
        message: '연락처 변경이 완료되었습니다.',
      });
    }

    function handleFail(errorPhrase: string) {
      setInformModal({
        isOpen: true,
        title: errorPhrase || '네트워크 오류',
        message: '잠시 후 다시 시도해주세요.',
      });
    }
  };

  const resetState = () => setChangeInfo(INITIAL_STATE);

  const isSameNumber = () => {
    return currentTel === changeInfo.mobile;
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => ''}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="max-sm:h-screen w-full sm:max-w-lg xl:max-w-xl 2xl:max-w-2xl transform overflow-hidden sm:rounded-lg text-left bg-white p-5 sm:p-10 shadow-lg transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl 2xl:text-2xl font-semibold text-black mb-10">
                  {t('연락처 변경')}
                </Dialog.Title>
                <div className="flex flex-col space-y-2 mt-3">
                  <span className="text-[#353333] text-sm">
                    {t('휴대폰번호')}
                  </span>
                  <input
                    className="border border-[#DFE0E5] text-black ring-offset-0 focus:border-primary focus:outline-none text-sm 2xl:text-base rounded-md py-3 px-4 2xl:py-4 2xl:px-5"
                    type="tel"
                    onKeyDown={({ key }) =>
                      key === 'Enter' && requestMobileChange()
                    }
                    name="mobile"
                    maxLength={20}
                    value={changeInfo.mobile}
                    onChange={e => {
                      if (!numberRegex.test(e.target.value)) return;
                      handleOnChange(e);
                    }}
                    placeholder={t('‘-’ 없이 번호만 입력해주세요.')}
                  />
                </div>

                <div
                  className={`mt-1 lg:mt-2 text-error text-xs items-center space-x-1 ${
                    isSameNumber() ? 'flex' : 'hidden'
                  }`}>
                  <ErrorIcon />
                  <span className="block">
                    {t('현재 등록된 휴대폰번호와 동일합니다.')}
                  </span>
                </div>
                <div className="w-full flex space-x-2 sm:space-x-3 mt-5">
                  <button
                    className={`w-1/3 py-3 sm:py-3.5 2xl:py-4 bg-[#EEEEF0] text-[#5b5b5b] rounded-md hover:bg-opacity-90 duration-100 ${
                      loading && 'cursor-default'
                    }`}
                    disabled={loading}
                    onClick={() => {
                      resetState();
                      closeModal();
                    }}>
                    {t('닫기')}
                  </button>
                  <button
                    onClick={requestMobileChange}
                    disabled={
                      isSameNumber() || !changeInfo.mobile.length || loading
                    }
                    className={`w-2/3 py-3 sm:py-3.5 2xl:py-4 rounded-md flex justify-center ${
                      isSameNumber() || !changeInfo.mobile.length || loading
                        ? 'bg-gray-200 text-gray-500 cursor-not-allowed'
                        : 'bg-primary text-white hover:bg-opacity-90'
                    } duration-100`}>
                    {loading ? <Loader /> : t('변경하기')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
