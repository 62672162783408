import {
  UpdateMobileReqType,
  UpdatePasswordReqType,
  WithdrawReqType,
} from '../types_new/main/service/types';
import APIService from './APIService';
import apiConfig from './apiConfig.json';
import {
  IMemberIsAccountReq,
  IMemberLoginReq,
  IMemberSignUpReq,
} from './dto/IMemberDto';

// 스튜디오 로그인
// FIXME: handle when code is not 200
const signIn = async (reqbody: IMemberLoginReq) => {
  return await APIService.post(apiConfig.studio.member.signin, reqbody);
};

// 스튜디오 회원가입 유무 확인
const checkDuplicateAccount = async (reqbody: IMemberIsAccountReq) => {
  const { data } = await APIService.post(
    apiConfig.studio.member.isaccount,
    reqbody
  );
  const { result } = data.body;

  return result;
};

const doSignIn = async (reqbody: IMemberLoginReq) => {
  return signIn(reqbody).then(res => {
    if (res.data.code === '200') {
      const { body } = res.data;
      APIService.setToken(body?.userToken);
    }
    return res;
  });
};

// 스튜디오 회원가입
const signUp = async (reqbody: IMemberSignUpReq) => {
  return await APIService.post(apiConfig.studio.member.signup, reqbody);
};

// 로그인 사용자 정보
const getMe = async () => {
  return await APIService.get(apiConfig.studio.member.getme);
};

// 로그인 사용자 정보
const getMyLoginInfo = async () => {
  const {
    data: { body },
  } = await APIService.get(apiConfig.studio.member.getMyloginInfo);
  return body;
};

// 회원 탈퇴
const withdrawUser = async (args: WithdrawReqType) => {
  return await APIService.post(apiConfig.studio.member.withdraw, args);
};

// 비밀번호 변경
const changePassword = async (args: UpdatePasswordReqType) => {
  return await APIService.post(apiConfig.studio.member.changePassword, args);
};

// 전화번호 변경
const changeMobile = async (args: UpdateMobileReqType) => {
  return await APIService.post(apiConfig.studio.member.changeMobile, args);
};

// 크레딧 사용 내역 조회
const getCreditUsageList = async (args: {
  postsPerPage: number;
  paged: number;
  category: string;
}) => {
  const { data } = await APIService.get(
    apiConfig.studio.project.getCreditUsage,
    {
      params: {
        postType: 'paylist',
        ...args,
      },
    }
  );
  return data.body;
};

export {
  changeMobile,
  changePassword,
  checkDuplicateAccount,
  doSignIn,
  getCreditUsageList,
  getMe,
  getMyLoginInfo,
  signIn,
  signUp,
  withdrawUser,
};
