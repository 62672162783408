import { useState } from 'react';
import { SourceType } from '../../../types_new/console/resource/types';
import VideoModal from '../../VideoModal';
import ImageModal from '../../modals/image-modal';
import MediaSourceCard from './media-source-card';

type Props = {
  sources: SourceType[];
  loading: boolean;
  getResourceList: () => void;
};

export default function SourceDetailGrid({
  sources,
  loading,
  getResourceList,
}: Props) {
  const [imageModal, setImageModal] = useState({
    isOpen: false,
    imageURL: '',
  });
  const [videoModal, setVideoModal] = useState({
    isOpen: false,
    videoURL: '',
  });

  return (
    <>
      <ImageModal
        isOpen={imageModal.isOpen}
        imageURL={imageModal.imageURL}
        closeModal={() => setImageModal(prev => ({ ...prev, isOpen: false }))}
      />
      <VideoModal
        isOpen={videoModal.isOpen}
        videoURL={videoModal.videoURL}
        closeModal={() => setVideoModal(prev => ({ ...prev, isOpen: false }))}
      />
      <div>
        {loading ? (
          <SourceSkeleton />
        ) : (
          <ul className="grid grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-y-5 xl:gap-y-8 2xl:gap-y-10 gap-x-16 xl:gap-x-20 2xl:gap-x-24 mt-5">
            {sources?.map((source, idx) => (
              <MediaSourceCard
                key={`source-${idx}`}
                source={source}
                setImageModal={setImageModal}
                setVideoModal={setVideoModal}
                getResourceList={getResourceList}
              />
            ))}
          </ul>
        )}
      </div>
    </>
  );
}

function SourceSkeleton() {
  const array = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  return (
    <ul className="grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-16 xl:gap-20 2xl:gap-24 mt-5">
      {array?.map((_, idx) => (
        <li
          key={`skeleton-${idx}`}
          className="w-full flex flex-col items-center">
          <div className="w-full h-24 bg-primary/10 animate-pulse flex items-center justify-center mb-3 rounded-lg overflow-hidden group">
            <svg
              className="w-8 h-8 text-gray-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 18">
              <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
            </svg>
          </div>
          <div className="flex flex-col space-y-1 items-center">
            <div className="w-20 h-1.5 bg-gray-400 rounded-full animate-pulse" />
            <div className="w-16 h-1 bg-gray-300 rounded-full animate-pulse" />
          </div>
        </li>
      ))}
    </ul>
  );
}
